import ViewModel from '../@base/ViewModel';
import {Apis} from "../../domain/Apis";
import {VM} from "../VM";

export default class StopsViewModel extends ViewModel {
  map=[]
  constructor() {
    super();
  }

  async get(language, latitude, longitude) {
    if(!VM.dataOrigin.data.length)
      await VM.dataOrigin.get(language)
    this.noFiltered=await Apis.stops.getStops(language, latitude, longitude)
    
    if(this.noFiltered?.length && this.noFiltered[0]?.id){
      this.map={};
      for(let i = 0; i<this.noFiltered.length; i++){
        this.map[this.noFiltered[i].id]=this.noFiltered[i]
      }
    }

    return super.notify(this.noFiltered, true);
  }
}
