import ViewModel from '../@base/ViewModel';
import {Apis} from "../../domain/Apis";

export default class LocationViewModel extends ViewModel {
  constructor() {
    super();
  }

  async get() {
    if (this.data) {
      return await this.data;
    } else {
      return super.notify(await Apis.location.getPois());
    }
  }
}
