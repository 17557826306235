import React, { useState } from 'react';
import { useTranslate } from '../../../../../context/languageContext';
import { useTheme } from '../../../../../context/themeContext';
import { Collapse } from 'react-collapse';
import Input from '../../../../../components/commons/Input';
import TimeUtils from '../../../../../../utils/TimeUtils';

export default function DateStopOptions({
  exitDate,
  setExitDate,
  style
}: {
  exitDate?: string;
  setExitDate: Function;
  style?: any;
}) {
  const t = useTranslate();
  const theme = useTheme();

  const [optionSelected, setOptionSelected] = useState(0);

  const styles = {
    content: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingInline: 22,
      textAlign: 'center'
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 0.5,
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px'
    },
    input: {
      marginTop: 10,
      borderWidth: '0 0 1.5px 0',
      borderRadius: 0,
      marginInline: 5
    },
    option: {
      cursor: 'pointer',
      fontSize: 14
    }
  };

  const setDate = (data: string) => {
    //let res = data.split('-').reverse().join('-');
    setExitDate(data);
  };

  return (
    <div style={{ ...style, ...styles.content }}>
      <div
        style={{ ...styles.option, fontWeight: optionSelected === 0 ? 500 : 10 }}
        onClick={() => {
          setOptionSelected(0);
          setExitDate(undefined);
        }}>
        {t('exitNow')}
      </div>
      <div style={styles.separator} />

      <div
        style={{ ...styles.option, fontWeight: optionSelected === 1 ? 500 : 10 }}
        onClick={() => {
          setOptionSelected(1);
          setExitDate(TimeUtils.getDate(Date.now()).split('-').reverse().join('-'));
        }}>
        {t('exitAt')}
      </div>
      <Collapse isOpened={optionSelected === 1}>
        <div style={{ display: 'flex' }}>
          {/* <Input
            type={'time'}
            initialValue={exitTime.time}
            onChange={(e) => setHoursMinute(e, false)}
            style={styles.input}
          /> */}
          {optionSelected === 1 ? (
            <Input
              type={'date'}
              initialValue={exitDate ? exitDate : ''}
              onChange={(e: any) => setDate(e)}
              style={styles.input}
            />
          ) : null}
        </div>
      </Collapse>
      <div style={styles.separator} />
    </div>
  );
}
