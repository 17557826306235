import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTheme} from "../../../../../../context/themeContext";
import ButtonIcon from "../../../../../../components/commons/ButtonIcon";
import {R} from "../../../../../../R";
import {VM} from "../../../../../../../viewmodel/VM";
import RouteUtils from "../../../../../../../utils/RouteUtils";

export default function RouteFilter(props, ref) {
    const theme = useTheme();
    const filters = [
        R.drawables.general.Ic_train_bus,
        R.drawables.general.Ic_train_favs,
        R.drawables.general.Ic_walking
    ]
    //const [selected, setSelected] = useState(0)

    const [isBack, setIsBack] = useState(false)

    const scroll = useRef(null);

    let pressedScroll = null;

    const styles={

        content: {
            display:'flex',
            maxWidth: "345px",
            minWidth: "232px",
            width: "80vw",
            overflowX:'scroll'
        },
        tags: {
            width:200,
            border:null,
            borderRadius:0,
            borderColor:theme.colors.primaryMedium,
            justifyContent:'center',
            transition:'all 100ms linear'
        },
        text: {
            fontSize:'14px',
            fontWeight:600,
            color:theme.colors.black
        },
        categories:{
            display:'flex',
            alignItems:'end',
            overflowX:'scroll',
        }
    }

    /* useEffect(() => {
        if(RouteUtils.getNoWalkItineraries(VM.planner.data)?.plan?.itineraries?.length===0) {
            setSelected(2)
            props.onChange(2)
        }
    }, []); */

    const renderFilters = () => {
        let result= []
        for(let i = 0; i<filters.length;i++){
            result.push(
                <ButtonIcon
                    key={i}
                    style={{...styles.tags,...props.filter === i
                            ? {borderWidth:'0px 0px 2px 0px'}
                            : {borderWidth:'0px 0px 0px 0px'}
                    }}
                    icon={filters[i]}
                    iconColor={theme.colors.black}
                    onClick={()=>{
                        ///setSelected(i)
                        props.onChange(i)
                    }}/>
            )
        }
        return result
    }

    const onClick = () => {
        clearInterval(pressedScroll)
        if(scroll.current.offsetWidth + scroll.current.scrollLeft+1 >= scroll.current.scrollWidth)
            setIsBack(true)
        else if(scroll.current.scrollLeft===0)
            setIsBack(false)
        else setIsBack(null)
    }

    const moveLeft = () => {
        pressedScroll = setInterval(() => {
            if(scroll.current.scrollLeft===0) {
                clearInterval(pressedScroll)
                setIsBack(false)

            }
            scroll.current.scrollTo(scroll.current.scrollLeft - 5, 0)
        }, 40)

    }

    const moveRight = () => {
        pressedScroll = setInterval(() => {
            if(!(scroll.current.offsetWidth + scroll.current.scrollLeft+1 <= scroll.current.scrollWidth)) {
                clearInterval(pressedScroll)
                setIsBack(true)
            }
            scroll.current.scrollTo(scroll.current.scrollLeft + 5, 0)
        }, 40)


    }

    return (
        <div style={props.style}>
                <div style={styles.content}>
                    {filters.length>3
                        ?< ButtonIcon
                            style={{marginRight:'10px'}}
                            disabled={isBack===false}
                            icon={R.drawables.commons.leftArrow}
                            iconColor={theme.colors.black}
                            borderColor={theme.colors.border}
                            onMouseDown={moveLeft}
                            onMouseLeave={onClick}
                            onClick={onClick}
                            size={'37.5px'}
                            iconSize={'15px'}/>
                        :null
                    }
                    <div ref={scroll} style={styles.categories}>
                        {renderFilters()}
                    </div>
                    {filters.length>3
                        ?< ButtonIcon
                            style={{marginLeft:'10px'}}
                            disabled={isBack===true}
                            icon={R.drawables.commons.rightArrow}
                            iconColor={theme.colors.black}
                            borderColor={theme.colors.border}
                            onMouseDown={moveRight}
                            onMouseLeave={onClick}
                            onClick={onClick}
                            size={'37.5px'}
                            iconSize={'15px'}/>
                        :null
                    }
                </div>
            </div>
    );
}
