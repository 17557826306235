import { useEffect, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { VM } from '../../../viewmodel/VM';
import Modal from '../../components/commons/Modal';
import { useTranslate } from '../../context/languageContext';
import { useResponsive } from '../../context/responsiveContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';
import AccountScreen from '../accountscreen/AccountScreen';
import { MemoExploreScreen } from '../explorescreen/ExploreScreen';
import ElementBar from './ElementBar';
import LoginRegister from './LoginRegister';
import OpenData from './OpenData';
import GeneralUtils from "../../../utils/GeneralUtils";
import { useSelector } from 'react-redux';
import { dateNewsState } from '../../../redux/slices/newsSlice';
import { notificationsCheckMessagesInformation } from '../../../redux/slices/notificationSlice';

export default function MenuBar(props, ref) {
  const theme = useTheme();
  const t = useTranslate();

  // const AppWithIcon = R.drawables.commons.appIcon;
  const AppWithIcon = R.drawables.commons.appIconShort;
  const AppWithIconLong = R.drawables.commons.appIconLong;
  const Vasco = R.drawables.providers.gobiernoVasco;
  const Financiado = R.drawables.providers.financiado2;
  const Plan = R.drawables.providers.plan2;
  const newsDateInfo = useSelector(dateNewsState);
  const checkNotifications = useSelector(notificationsCheckMessagesInformation);

  let timeout = null

  const [open, setOpen] = useState(false);
  const responsive = useResponsive();

  const [opened, setOpened] = useState(false);
  const [child, setChild] = useState();

  const [mouseOver, setMouseOver] = useState(false);
  const [textVisible, setTextVisible] = useState(!responsive.isBig || mouseOver);

  useEffect(() => {
    VM.screenSelected.listen(() => renderScreen());
  }, []);


  useEffect(() => {
    try {
      let path = GeneralUtils.getSectionPath()
      switch (path) {
        case 'account': {
          setChild(<AccountScreen setOpened={setOpen} />);
          setOpened(true);
          break;
        }
        case 'explore':
          setOpened(false);
          setChild('explore');
          VM.screenSelected.setSelected('explore')
          break;
        case 'favorites':
          setOpened(false);
          setChild('favorites');
          VM.screenSelected.setSelected('favorites')
          break;
        case 'lines':
          setOpened(false);
          setChild('lines');
          VM.screenSelected.setSelected('lines')
          break;
        case 'transit':
          setOpened(false);
          setChild('transit_news');
          VM.screenSelected.setSelected('transit_news');
          VM.screenSelected.setSection('transit');
          break;
        case 'news':
          setOpened(false);
          setChild('transit_news');
          VM.screenSelected.setSelected('transit_news');
          VM.screenSelected.setSection('news');
          break;
        case 'transit_news':
          setOpened(false);
          setChild('transit_news');
          VM.screenSelected.setSelected('transit_news');
          break;
        default:
          setOpened(false);
          break;
      }

    } catch (e) {
    }
  }, [window.location]);

  const renderScreen = () => {
    let url = GeneralUtils.getBaseUrl()
    switch (VM.screenSelected.data) {
      case 'explore':
        setOpened(false);
        setChild('explore');
        window.history.pushState('explore', 'explore', url+'/'+GeneralUtils.getParams('explore'))
        break;
      case 'favorites':
        setOpened(false);
        setChild('lines');
        window.history.pushState('favorites', 'favorites', url+'/#/favorites'+GeneralUtils.getPathWithoutSection('favorites'));
        break;
      case 'lines':
        setOpened(false);
        setChild('favorites');
        window.history.pushState('lines', 'lines', url+'/#/lines');
        break;
      case 'transit':
        setOpened(false);
        setChild('transit_news');
        window.history.pushState('transit_news', 'transit_news', url+'/#/transit_news'+GeneralUtils.getPathWithoutSection('transit_news'));
        break;
      case 'news':
        setOpened(false);
        setChild('transit_news');
        window.history.pushState('transit_news', 'transit_news', url+'/#/transit_news'+GeneralUtils.getPathWithoutSection('transit_news'));
        break;
      case 'transit_news':
        setOpened(false);
        setChild('transit_news');
        window.history.pushState('transit_news', 'transit_news', url+'/#/transit_news'+GeneralUtils.getPathWithoutSection('transit_news'));
        break;
      case 'account':
        setChild(<AccountScreen setOpened={setOpen} />);
        setOpened(true);
        window.history.pushState('account', 'account', url+'/#/account');
        break;
      default:
        setOpened(false);
        break;
    }
  };

  const styles = {
    content: {
      backgroundColor: theme.colors.mainDark,
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      width: responsive.isBig && !mouseOver ? '50px' : '240px',
      height: '100%',
      minHeight: '600px',
      left: open || responsive.isBig ? 0 : '-280px',
      boxShadow: '0px 0px 30px rgba(24, 27, 29, 0.15)',
      transition: 'all 300ms linear',
      zIndex: 7,
      paddingInline: '15px',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      height: '10%',
      width: '100%'
    },
    background: {
      backgroundColor: theme.colors.black,
      position: 'fixed',
      width: '120%',
      height: '100%',
      transition: 'all 300ms linear',
      zIndex: open || (responsive.isBig && mouseOver) ? 7 : 4,
      opacity: open || (responsive.isBig && mouseOver) ? 0.2 : 0
    },

    closeIcon: {
      marginLeft: 'auto',
      cursor: 'pointer'
    },
    openData: {
      position: 'relative',
      left: !responsive.isBig || mouseOver ? 0 : '-280px',
      margin:2.5,
      transition: 'all 300ms linear',
    },
    openData2: {
      position: 'relative',
      width:230,
      left: !responsive.isBig || mouseOver ? 0 : '-280px',
      margin:2.5,
      transition: 'all 300ms linear',
    }
  };

  return (
    <div>
      <div
        style={styles.background}
        onMouseOver={()=> {
          clearTimeout(timeout);
          setTimeout(()=>{
            setMouseOver(false)
          },500)
        }}
        onTransitionEnd={() => setTextVisible(!responsive.isBig || mouseOver)}
        onClick={() => setOpen(false)}
      />

      <div
        onMouseOver={() => {
          clearTimeout(timeout);
          timeout = setTimeout(()=>{
            if(timeout)
              setMouseOver(true)
            clearTimeout(timeout);
          },750)
        }}
        onMouseLeave={()=> {
          clearTimeout(timeout);
          setTimeout(()=>{
            setMouseOver(false)
          },500)
        }}
        style={styles.content}>
        <div style={styles.header}>
          {!responsive.isBig || mouseOver ? (
            <AppWithIconLong
              style={{
                width: '50%',
                fill: theme.colors.white,
                paddingLeft: '10px',
                paddingRight: '16px',
                paddingTop: '16px'
              }}
            />
          ) : (
            <AppWithIcon
              style={{
                width: '40px',
                fill: theme.colors.white,
                paddingLeft: '10px',
                marginRight: '10px',
                paddingTop: '16px'
              }}
            />
          )}

          {!responsive.isBig ? (
            <IoCloseSharp
              onClick={() => setOpen(false)}
              style={styles.closeIcon}
              size={'17.5px'}
              color={theme.colors.white}
            />
          ) : null}
        </div>
        {/* <div style={{ width: '100%' }}>
          <Vasco style={styles.openData}/>
        </div> */}
        <div style={{ height: '45%', width: '100%' }}>
          <ElementBar
            id={'explore'}
            mouseOver={mouseOver}
            onClick={() => VM.screenSelected.setSelected('explore')}
            selected={VM.screenSelected.data}
            icon={R.drawables.map.location}
            textVisible={textVisible}
            text={t('explore')}
          />
          <ElementBar
            id={'favorites'}
            mouseOver={mouseOver}
            onClick={() => {VM.screenSelected.setSelected('favorites'); VM.plannerSegments.clear()}}
            selected={VM.screenSelected.data}
            icon={R.drawables.account.favorite}
            textVisible={textVisible}
            text={t('saves')}
          />
          <ElementBar
            id={'lines'}
            mouseOver={mouseOver}
            onClick={() => {VM.screenSelected.setSelected('lines'); VM.plannerSegments.clear()}}
            selected={VM.screenSelected.data}
            icon={R.drawables.transport.Ic_Combinado}
            textVisible={textVisible}
            text={t('transit_lines')}
          />
          {/* <ElementBar
            id={'transit'}
            mouseOver={mouseOver}
            onClick={() => VM.screenSelected.setSelected('transit')}
            selected={VM.screenSelected.data}
            icon={R.drawables.map.circulation}
            textVisible={textVisible}
            text={t('incidence')}
          /> */}
          <ElementBar
            id={'transit_news'}
            mouseOver={mouseOver}
            onClick={() => {VM.screenSelected.setSelected('news'); VM.plannerSegments.clear()}}
            selected={VM.screenSelected.data}
            icon={R.drawables.general.Ic_Noticias}
            textVisible={textVisible}
            text={t('transit_news')}
            showNoti={newsDateInfo}
          />
          <ElementBar
            id={'account'}
            mouseOver={mouseOver}
            onClick={() => {VM.screenSelected.setSelected('account'); VM.plannerSegments.clear()}}
            selected={VM.screenSelected.data}
            icon={R.drawables.general.Ic_user}
            textVisible={textVisible}
            text={t('myAccount')}
            showNoti={checkNotifications}
          />
        </div>

        <div
          style={{
            height: '15%',
            width: '100%',
            alignItems: 'center',
            display: 'grid',
            overflow: 'hidden'
          }}>
          <LoginRegister mouseOver={mouseOver} />
        </div>

        <div style={{ height: '40%', width: '100%' }}>
          <OpenData style={styles.openData} />
        </div>
        <div style={{ width: '100%' }}>
          <img src={Plan} style={styles.openData2} />
          <img src={Financiado} style={styles.openData2} />
          <Vasco style={styles.openData2} />
        </div>
      </div>
      <Modal
        showClose={false}
        open={opened}
        setOpen={setOpened}
        child={child}
        onClose={() => VM.screenSelected.setSelected('explore')}
      />

      <MemoExploreScreen setOpen={setOpen} />
    </div>
  );
}
