import React, { CSSProperties } from 'react';
import { useTheme } from '../../../../context/themeContext';
import { R } from '../../../../R';

interface FileEntrySendingProps {
  fileName: string;
  fileType: string;
  localeBlob: any;
  setLocaleInputData?: Function; //modificar info del archivo
  setFileData: Function; //modificar nombre archivo
  setTypeFile: Function;
  style?: CSSProperties;
  notClose?: boolean;
  admin?: boolean;
  onClick?: Function;
}

export default function FileEntrySending(props: FileEntrySendingProps) {
  const theme = useTheme();
  const Close = R.drawables.general.Ic_close;

  function getFileIcon() {
    const GalleryIcon = R.drawables.general.Ic_gallery;
    const validFileDocument = [
      'application/pdf',
      'application/doc',
      'application/docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
      '.docx',
    ]
    if (validFileDocument.includes(props.fileType)) {
      const DocIcon = R.drawables.general.Ic_document;
      return <DocIcon style={{...props.admin ? {fill: theme.colors.white} : {}}} />;
    }

    return <GalleryIcon style={{...props.admin ? {fill: theme.colors.white} : {}}} />;
  }

  return (
    <div
      onClick={() => props.onClick?.()}
      style={{...{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-start',
        marginBottom: 12,
        marginLeft: 8,
        //marginTop: -24,
        padding: 8,
        background: 'white',
        //position: 'absolute',
        borderRadius: 8,
        border: `2px solid ${theme.colors.border}`,
        fontSize: 14,
        maxWidth: 180,
        alignItems: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
      }, ...props.style, ...props.admin ? {background: theme.colors.primaryMedium} : {}}}>
      <div style={{ flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'left' }}>
        {props.fileName}
      </div>
      <div
        style={{ borderLeft: `2px solid ${theme.colors.border}`, marginLeft: 4, paddingLeft: 4 }}>
        {getFileIcon()}
      </div>
      {props.notClose ? null : <div
        style={{ borderLeft: `2px solid ${theme.colors.border}`, marginLeft: 4, paddingLeft: 4 }}>
        <Close
          style={{ fill: theme.colors.contextualError }}
          onClick={() => {
            props.setLocaleInputData?.(undefined);
            props.setFileData('');
            props.setTypeFile('');
          }}
        />
      </div>}
    </div>
  );
}
