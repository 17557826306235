import React, { useEffect, useRef, useState } from 'react';

export default function useDebounce() {

  const controllerRef = useRef() as any;

  useEffect(() => {
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  const debounce = <T extends any[]>(func: (...args: T) => void, delay: number) => {
    let timer: ReturnType<typeof setTimeout> | undefined;

    return function (this: any, ...args: T) {
      clearTimeout(timer);
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      const controller = new AbortController();
      controllerRef.current = controller;

      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  return {
    debounce
  };
}

export function useDebounceTerm(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}