import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { RootState } from '../../../../../redux/store';
import { useTranslate } from '../../../../context/languageContext';
import { R } from '../../../../R';

export default function CustomizedSnackbarModalWidget(props: any) {
  const t = useTranslate();
  // const Circled_Info = R.drawables.general.Ic_Circled_Info;
  const Check = R.drawables.general.Ic_check;
  const Close = R.drawables.commons.close;
  const dispatch = useDispatch();
  const toggleVisibility = useSelector(
    (state: RootState) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );
  const text = useSelector(
    (state: any) =>
      state.eventsModalstoggleVisibilitySlice.ModalWidget.CustomizedSnackbarModalWidget.text
  );
  const background = useSelector(
    (state: any) =>
      state.eventsModalstoggleVisibilitySlice.ModalWidget.CustomizedSnackbarModalWidget.background
  );
  const status = useSelector(
    (state: any) =>
      state.eventsModalstoggleVisibilitySlice.ModalWidget.CustomizedSnackbarModalWidget.status
  );

  // const stopId = useSelector(
  //   (state: any) => state.eventsModalstoggleVisibilitySlice.ModalWidget.ReportStopModalWidget.stop
  // );

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: false
        })
      );
    }, 5000);
  }, []);

  return (
    <div className="copied-snackbar" style={{ backgroundColor: background }}>
      <p className={status === 'error' ? 'icon-close' : 'icon-check'}>
        {status === 'error' ? <Close fill="white" /> : <Check />}
      </p>
      <p>{text}</p>
    </div>
  );
}
