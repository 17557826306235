import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import RecoveryPassTokenModalWidget from '../../components/widget/Modals/RecoveryPassTokenModalWidget';

export default function RecoveryScreen() {
  // const dispatch = useAppDispatch();
  const toggleVisibility = useSelector(
    (state: RootState) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );
  let [params] = useSearchParams();

  useEffect(() => {
    if (params.get('token')) {
    }
  });
  return <RecoveryPassTokenModalWidget />;
}
