import React, { useEffect } from 'react';
import { useTranslate } from '../../../context/languageContext';
import { agencyInformation } from '../../../../redux/slices/agencySlice';
import { useSelector } from 'react-redux';
import { VM } from '../../../../viewmodel/VM';
import { R } from '../../../R';
import { useTheme } from '../../../context/themeContext';
import SubscribeNews from '../../newscreen/SubscribeNews';
import { useSetModalChild, useSetModalOpened } from '../../../context/modalContext';
import OperatorNewsModalFilter from './OperatorNewsModalFilter';
import { filtersNewsAlertsState } from '../../../../redux/slices/newsSlice';

export default function OperatorsNewsTransitSelector({
  agencySelected,
  setAgencySelected
}: {
  agencySelected?: number;
  setAgencySelected: Function;
}) {
  const agencyState = useSelector(agencyInformation);
  const t = useTranslate();
  const theme = useTheme();
  const RightArrow = R.drawables.commons.rightArrow;
  const setOpened = useSetModalOpened();
  const setChild = useSetModalChild();
  const filtersSelectedAgencies: Array<number> = useSelector(filtersNewsAlertsState);

  let copyAgenciesFilters = JSON.parse(JSON.stringify(agencyState.agenciesFilters));
  let agenciesTransport: Array<any> = [];
  copyAgenciesFilters.forEach((transportModeAg: any) => {
    if (transportModeAg.id !== 5) {
      transportModeAg.agencies.shift();
      let filterDuplicates: Array<number> = [];

      agenciesTransport.forEach((agency: any) => {
        let indexFound = transportModeAg.agencies.findIndex(
          (transAg: any) => transAg.id === agency.id
        );
        if (indexFound !== -1) {
          filterDuplicates.push(indexFound);
        }
      });
      if (filterDuplicates?.length) {
        filterDuplicates.forEach((indexToDel: number) => {
          transportModeAg.agencies.splice(indexToDel, 1);
        });
      }

      agenciesTransport = agenciesTransport.concat(transportModeAg.agencies);
    }
  });

  function countAgencyIds() {
    let agencyIds: Array<number> = [];
    filtersSelectedAgencies.forEach((origin) => {
      let obtainDataOrigin: any = agencyState.dataOrigin.find((dataOri: any) => dataOri.id === origin);

      if (obtainDataOrigin) {
        let isAdded = agencyIds.indexOf(obtainDataOrigin?.agencyId);
        if (isAdded === -1) {
          agencyIds.push(obtainDataOrigin?.agencyId);
        }
      }
    });
    
    return agencyIds.length;
  }

  useEffect(() => {
    async function obatainIcons() {
      agenciesTransport.forEach(async (agency) => {
        let icon = await VM.icons.getIcon(agency?.icons?.iconId);
      });
    }
    agenciesTransport?.length && obatainIcons();
  }, [agenciesTransport]);

  return (
    <div
      style={{
        zIndex: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: 16,
        paddingLeft: 16,
        marginTop: 16,
        marginBottom: 8,
        backgroundColor: 'white',
        borderRadius: 8,
        cursor: 'pointer',
      }}
      onClick={(e) => {
        setChild(<OperatorNewsModalFilter />);
        setOpened(true);
      }}>
      <label
        style={{
          ...{ fontSize: 16, fontWeight: 700, cursor: 'pointer', },
          ...(filtersSelectedAgencies.length ? { color: theme.colors.primaryMedium } : {})
        }}>{`${t('filters')} ${
        filtersSelectedAgencies.length ? `(${filtersSelectedAgencies.length})` : ''
      }`}</label>
      <RightArrow
        style={{ marginLeft: 'auto', minWidth: '10px', cursor: 'pointer' }}
        fill={
          filtersSelectedAgencies.length ? theme.colors.primaryMedium : theme.colors.neutralBlack
        }
      />
    </div>
  );
}
