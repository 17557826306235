import { useSelector } from 'react-redux';
import { useTranslate } from '../../../../../context/languageContext';
import { R } from '../../../../../R';
import RouteDetailStopCol from './RouteDetailStopCol';
import { VM } from '../../../../../../viewmodel/VM';
import React from 'react';
import LineIcon from '../../searcherOption/stopDetail/LineIcon';
import { agencyInformation } from '../../../../../../redux/slices/agencySlice';

export default function RouteDetailSynoptic(props: any) {
  const t = useTranslate();
  const dataOriginAg = useSelector(agencyInformation).dataOrigin;

  const ArrowRight = R.drawables.general.Ic_arrowright;
  const Link = R.drawables.general.Ic_Link;
  const plan = useSelector(
    (state: any) => state.eventsModalstoggleVisibilitySlice.ModalWidget.RouteDetailModalWidget.plan
  );

  const getMoreInformation = () => {
    let res = [] as any;
    let add = [] as any;
    for (let element in plan.lineSynoptic) {
      if (
        plan.lineSynoptic[element].mode !== 'WALK' &&
        add.indexOf(plan.lineSynoptic[element].name) < 0
      ) {
        let agencyId = plan.lineSynoptic[element]?.agencyId;
        let dataOrigin: any = dataOriginAg.find((element: any) => {
          return element.gtfsAgency.find(
            (agency: any) => parseInt(agency.id) === parseInt(agencyId)
          );
        });

        let agency = dataOrigin?.gtfsAgency?.find(
          (element: any) => parseInt(element.id) === parseInt(agencyId)
        );
        res.push(
          <div key={element} className={'padding10'}>
            <div className={'secondaryText'} style={{ fontWeight: 600 }}>
              {'-' + plan.lineSynoptic[element].name}
            </div>
            {plan.lineSynoptic[element].web && agency ? <div className="row" style={{ alignItems: 'center' }}>
              <a
                className={'secondaryText margin5'}
                href={agency?.url}
                target="_blank">
                {t('website')}
              </a>
              <Link style={{ width: 20, marginLeft: 8 }} />
            </div> : null}

            {plan.lineSynoptic[element]?.fareUrl && agency ? (
              <div className="row" style={{ alignItems: 'center' }}>
                <a
                  className={'secondaryText margin5'}
                  href={agency?.fareUrl}
                  target="_blank">
                  {t('fares')}
                </a>
                <Link style={{ width: 20, marginLeft: 8 }} />
              </div>
            ) : null}
            {plan.lineSynoptic[element]?.reserva_message &&
            plan.lineSynoptic[element]?.reserva_link && agency ? (
              <div className={'secondaryText margin5 margin-top-20'}>
                <div className={'secondaryText'}>{agency?.message}</div>
                <a
                  className={'secondaryText'}
                  href={agency?.link}
                  target="_blank">
                  {agency?.link}
                </a>
              </div>
            ) : null}
            <div className={'secondaryText margin5'}>{plan.lineSynoptic[element].telf}</div>
          </div>
        );
      }
      add.push(plan.lineSynoptic[element].name);
    }
    res.unshift(
      <div key={'moreInf'} className={'secondaryText margin-top-20'} style={{ fontWeight: 'bold' }}>
        {t('moreInf')}
      </div>
    );
    res.push(
      <div key={'moveInformative'} className={'paramText padding10'}>
        {t('moveInformative')}
      </div>
    );

    return res;
  };

  const renderSynoptic = () => {
    let res = [];
    let trans = false;
    let inter = 0;
    for (let mode in plan.lineSynoptic) {
      if (parseInt(mode) > 0)
        if (
          plan.lineSynoptic[mode].mode === plan.lineSynoptic[parseInt(mode) - 1].mode &&
          plan.lineSynoptic[parseInt(mode)].mode != 'WALK'
        )
          trans = true;
        else trans = false;

      if (trans)
        res.push(
          <div key={'trans' + mode}>
            <div
              className={'secondaryText row'}
              style={{ alignItems: 'initial', textAlign: 'left' }}>
              <div className={'secondaryText'}>{plan.lineSynoptic[parseInt(mode) - 1].endTime}</div>
              <div className={'columnAlign'}>
                <div
                  className={'point'}
                  style={{
                    border: `3px solid ${
                      plan.lineSynoptic[parseInt(mode) - 1].routeColor === '#FFFFFF'
                        ? plan.lineSynoptic[mode].routeTextColor
                        : plan.lineSynoptic[parseInt(mode) - 1].routeColor
                    }`
                  }}
                />
                <div className={'line'} />
              </div>
              <div className={'columnAlign'} style={{ alignItems: 'initial' }}>
                <div className={'primaryText'}>
                  {plan.lineSynoptic[parseInt(mode) - 1]?.to?.name}
                </div>
                <div className={'secondaryText darkGray'} style={{ marginBlock: 'auto' }}>
                  {t('transbord')}
                </div>
              </div>
            </div>
          </div>
        );
      if (
        (plan.lineSynoptic[parseInt(mode)]?.mode === 'WALK' &&
          plan.lineSynoptic[parseInt(mode) - 1]?.mode === 'WALK') ||
        (plan.lineSynoptic[mode]?.to.lat.toFixed(5) ===
          VM.plannerSegments.data[inter + 1]?.coords.latitude.toFixed(5) &&
          plan.lineSynoptic[mode]?.to.lon.toFixed(5) ===
            VM.plannerSegments.data[inter + 1]?.coords.longitude.toFixed(5))
      )
        inter = inter + 1;

      if (plan.lineSynoptic[mode].mode === 'WALK')
        //Push Walk
        res.push(
          <div key={mode}>
            <div
              className={'secondaryText row'}
              style={{ alignItems: 'initial', textAlign: 'left' }}>
              <div className={'secondaryText'}>{plan.lineSynoptic[mode].startTime}</div>
              <div className={'columnAlign'}>
                {mode == '0' && parseInt(mode) - 1 < 0 ? (
                  <div className={'origin'} style={{ border: `5px solid ${'black'}` }} />
                ) : parseInt(mode) < plan.lineSynoptic?.length &&
                  plan.lineSynoptic[parseInt(mode) - 1]?.mode === 'WALK' &&
                  ((plan.lineSynoptic[parseInt(mode)]?.mode === 'WALK' &&
                    plan.lineSynoptic[parseInt(mode) - 1]?.mode === 'WALK') ||
                    (plan.lineSynoptic[mode]?.to.lat.toFixed(5) ===
                      VM.plannerSegments.data[inter]?.coords.latitude.toFixed(5) &&
                      plan.lineSynoptic[mode]?.to.lon.toFixed(5) ===
                        VM.plannerSegments.data[inter]?.coords.longitude.toFixed(5))) ? (
                  <div className={'interPoint'}>{inter}</div>
                ) : (
                  <div
                    className={'point'}
                    style={{
                      border: `3px solid ${
                        plan.lineSynoptic[parseInt(mode) - 1].routeColor === '#FFFFFF'
                          ? plan.lineSynoptic[parseInt(mode) - 1].routeTextColor
                          : plan.lineSynoptic[parseInt(mode) - 1].routeColor ?? 'black'
                      }`
                    }}
                  />
                )}
                <div className={'dashed'} />
              </div>
              <div className={'columnAlign'} style={{ alignItems: 'initial' }}>
                <div className={'primaryText'}>
                  {parseInt(mode) < plan.lineSynoptic?.length &&
                  plan.lineSynoptic[parseInt(mode) - 1]?.mode === 'WALK' &&
                  ((plan.lineSynoptic[parseInt(mode)]?.mode === 'WALK' &&
                    plan.lineSynoptic[parseInt(mode) - 1]?.mode === 'WALK') ||
                    (plan.lineSynoptic[mode]?.to.lat.toFixed(5) ===
                      VM.plannerSegments.data[inter]?.coords.latitude.toFixed(5) &&
                      plan.lineSynoptic[mode]?.to.lon.toFixed(5) ===
                        VM.plannerSegments.data[inter]?.coords.longitude.toFixed(5)))
                    ? t('intermediateStop') + inter
                    : parseInt(mode) === 0
                    ? VM.plannerSegments.data['origin' as any]?.name
                    : plan.lineSynoptic[mode]?.from?.name}
                </div>
                <div className={'secondaryText'} style={{ marginBlock: 'auto' }}>{`${
                  plan.lineSynoptic[mode].distance
                } · ${plan.lineSynoptic[mode].time} ${t('minutes')}`}</div>
              </div>
            </div>
          </div>
        );
      //Push Transport
      else
        res.push(
          <div key={mode}>
            <div className={'secondaryText row'} style={{ alignItems: 'initial' }}>
              <div className={'secondaryText'}>{plan.lineSynoptic[mode].startTime}</div>
              <div className={'columnAlign'}>
                {mode == '0' ? (
                  <div className={'origin'} style={{ border: `5px solid ${'black'}` }} />
                ) : (
                  <div
                    className={'point'}
                    style={{
                      border: `3px solid ${
                        plan.lineSynoptic[mode].routeColor === '#FFFFFF'
                          ? plan.lineSynoptic[mode].routeTextColor
                          : plan.lineSynoptic[mode].routeColor
                      }`,
                      backgroundColor: 'white'
                    }}></div>
                )}
                <div
                  className={'line'}
                  style={{
                    border: `1px solid ${
                      plan.lineSynoptic[mode].routeColor === '#FFFFFF'
                        ? plan.lineSynoptic[mode].routeTextColor
                        : plan.lineSynoptic[mode].routeColor
                    }`,
                    backgroundColor:
                      plan.lineSynoptic[mode].routeColor === '#FFFFFF'
                        ? plan.lineSynoptic[mode].routeTextColor
                        : plan.lineSynoptic[mode].routeColor
                  }}
                />
              </div>
              <div className={'columnAlign'} style={{ alignItems: 'initial' }}>
                <div className={'row'} style={{ alignItems: 'initial' }}>
                  <div
                    className={'transportPoint'}
                    style={{
                      ...{
                        flexShrink: 1,
                        border: `3px solid ${
                          plan.lineSynoptic[mode].routeColor === '#FFFFFF'
                            ? plan.lineSynoptic[mode].routeTextColor
                            : plan.lineSynoptic[mode].routeColor
                        }`,
                        backgroundColor:
                          plan.lineSynoptic[mode].routeColor === '#FFFFFF'
                            ? plan.lineSynoptic[mode].routeTextColor
                            : plan.lineSynoptic[mode].routeColor,
                        color:
                          plan.lineSynoptic[mode].routeTextColor &&
                          plan.lineSynoptic[mode].routeColor !== '#FFFFFF'
                            ? plan.lineSynoptic[mode].routeTextColor
                            : 'white'
                      },
                      ...(plan.lineSynoptic[mode].routeTextColor &&
                      plan.lineSynoptic[mode].routeColor !== '#FFFFFF'
                        ? { textShadow: 'black 0px 0px 1px' }
                        : {})
                    }}>
                    {plan.lineSynoptic[mode].tripShortName}
                  </div>
                  {/* <LineIcon
                    color={plan.lineSynoptic[mode].routeColor}
                    textColor={plan.lineSynoptic[mode].routeTextColor}
                    text={plan.lineSynoptic[mode].tripShortName}
                    style={{
                      height: null,
                      marginRight: 4,
                      textAlign: 'center',
                      marginLeft: 5,
                      minHeight: 15,
                      minWidth: 15,
                      padding: 0,
                      paddingLeft: 2,
                      paddingRight: 2,
                      marginInline: 5,
                      fontWeight: 500
                    }}
                  /> */}
                  <div className={'primaryText'}>{plan.lineSynoptic[mode]?.from?.name}</div>
                </div>
                <div className={'row'}>
                  <ArrowRight className={'marginRight5'} style={{ width: 16 }} />
                  <div className={'primaryText'}>{plan.lineSynoptic[mode].headSign}</div>
                </div>
                <div className={'margin-top-20 marginBottom20'}>
                  <RouteDetailStopCol
                    time={plan.lineSynoptic[mode].time}
                    stops={plan.lineSynoptic[mode].intermediateStops}
                  />
                </div>
              </div>
            </div>
          </div>
        );
    }

    res.push(
      <div key={'fin'}>
        <div className={'secondaryText row'} style={{ alignItems: 'initial', textAlign: 'left' }}>
          <div className={'secondaryText'}>
            {plan.lineSynoptic[Object.keys(plan.lineSynoptic).length - 1].endTime}
          </div>
          <div className={'columnAlign'}>
            <div className={'origin'} style={{ border: `5px solid ${'black'}` }} />
          </div>
          <div className={'primaryText'}>{VM.plannerSegments.data['destination' as any]?.name}</div>
        </div>
      </div>
    );

    return res;
  };

  return (
    <div
      className={'paddingVertical10'}
      style={{ maxHeight: window.innerHeight - 255, overflow: 'auto' }}>
      {renderSynoptic()}
      {getMoreInformation()}
    </div>
  );
}
