import Api from '../@base/Api';
import {VM} from "../../viewmodel/VM";
import PlanUtils from "../../utils/PlanUtils";
import TimeUtils from "../../utils/TimeUtils";

export default class PlannerApi extends Api {
    constructor(url) {
        super(url);
    }

    plan(walk, favoritePlan) {
        let times=''
        if(VM.plannerTime.getTimes().length)
            times=`&times=${VM.plannerTime.getTimes()}`
        let intermediateOption=''
        if(PlanUtils.getIntermediates())
            intermediateOption=`&intermediatePlaces=${PlanUtils.getIntermediates()}`
        let modes='TRANSIT,WALK'
        if(VM.plannerConfig.data.modes.length) {
            let aux = JSON.parse(JSON.stringify(VM.plannerConfig.data.modes));
            if(aux.indexOf('WALK')<0)
                aux.push('WALK')
            modes = aux.toString();   
        }
        let walkDistance = '';
        if (VM.plannerConfig.data.maxWalkDistance && !walk /* && !favoritePlan */) {
            let distance = (VM.plannerConfig.data.maxWalkDistance * 60 * 1.17).toFixed(6);
            walkDistance  = `&maxWalkDistance=${distance}`;
        }
        return super.get(`/plan/?from=${PlanUtils.getOrigin()}&to=${PlanUtils.getDestination()}&arriveBy=${VM.plannerTime.data.arriveBy}&time=${VM.plannerTime.data.exitNow ? TimeUtils.getTimePM_AM(TimeUtils.getTime(Date.now())) : VM.plannerTime.data.time}&mode=${walk ? 'WALK' : modes}&date=${TimeUtils.getPlanDate(VM.plannerTime.data.date)}&deviceType=${process.env.REACT_APP_typeDeviceId}&favoritePlan=${favoritePlan ?? false}&wheelchair=${VM.plannerConfig.data.accessible}&toPlaceId=${VM.plannerSegments.toPlaceId}&fromPlaceId=${VM.plannerSegments.fromPlaceId}&intermediatePlacesId=${VM.plannerSegments.intermediatePlacesId}`+walkDistance+times+intermediateOption);
    }
}
