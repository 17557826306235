import TimeUtils from '../../../../utils/TimeUtils';
import { useLanguage, useTranslate } from '../../../context/languageContext';

export default function SystemNotificationElement(props: any) {
  const t = useTranslate();
  const language = useLanguage();

  const getTimeMessage = (time: any) => {
    let date = new Date(time);
    const difference = Math.floor(new Date().getTime() - new Date(date).getTime());
    let minutesDifference = Math.floor(difference / 60000);
    let message = TimeUtils.getDate(new Date(time), language);

    if (language === 'en') {
      if (minutesDifference === 0) {
        message = ` 1 ${t('date_minute')} ${t('date_difference')}`;
      } else if (minutesDifference < 60) {
        message = ` ${minutesDifference} ${t('date_minute')} ${t('date_difference')}`;
      } else if (minutesDifference < 1440) {
        message = ` ${Math.floor(minutesDifference / 60)} ${t('date_hours')} ${t(
          'date_difference'
        )}`;
      } else {
        let daysDifference = Math.round(difference / (1000 * 60 * 60 * 24));
        if (daysDifference === 1) {
          message = `${t('date_day')} ${t('date_difference')}`;
        } else if (daysDifference < 7) {
          message = `${daysDifference} ${t('date_days')} ${t('date_difference')}`;
        } else if (daysDifference < 14) {
          message = `${t('date_week')} ${t('date_difference')}`;
        } else if (daysDifference < 14) {
          message = `${Math.floor(daysDifference / 7)} ${t('date_weeks')} ${t('date_difference')}`;
        } else if (daysDifference < 60) {
          message = ` ${t('date_month')} ${t('date_difference')}`;
        }
      }
    } else {
      if (minutesDifference === 0) {
        message = `${t('date_difference')} 1 ${t('date_minute')}`;
      } else if (minutesDifference < 60) {
        message = `${t('date_difference')} ${minutesDifference} ${t('date_minute')}`;
      } else if (minutesDifference < 1440) {
        message = `${t('date_difference')} ${Math.floor(minutesDifference / 60)} ${t(
          'date_hours'
        )}`;
      } else {
        let daysDifference = Math.round(difference / (1000 * 60 * 60 * 24));
        if (daysDifference === 1) {
          message = `${t('date_difference')} ${t('date_day')}`;
        } else if (daysDifference < 7) {
          message = `${t('date_difference')} ${daysDifference} ${t('date_days')}`;
        } else if (daysDifference < 14) {
          message = `${t('date_difference')} ${t('date_week')}`;
        } else if (daysDifference < 14) {
          message = `${t('date_difference')} ${Math.floor(daysDifference / 7)} ${t('date_weeks')}`;
        } else if (daysDifference < 60) {
          message = `${t('date_difference')} ${t('date_month')}`;
        }
      }
    }

    return message;
  };

  return (
    <div className={'account-system-element'}>
      <div className={'cornerText'}>{t('systemNotifications')}</div>
      <div className={'row'}>
        <div className={'subtitle'}>{props.title}</div>
        <div className={'cornerText'} style={{ marginLeft: 'auto' }}>
          {getTimeMessage(props.sentAt)}
        </div>
      </div>
      <div className={'description'} style={{ marginTop: 10 }}>
        {props.messageBody}
      </div>
    </div>
  );
}
