import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  favoritesInformation,
  filterButton
} from '../../../../../../../redux/slices/favoritesSlice';
import { filtersInformation, filtersSlice } from '../../../../../../../redux/slices/filtersSlice';
import FilterUtils from '../../../../../../../utils/FilterUtils';
import { VM } from '../../../../../../../viewmodel/VM';
import { useLanguage, useTranslate } from '../../../../../../context/languageContext';
import { useTheme } from '../../../../../../context/themeContext';
import TransportButton from '../atoms/TransportButton';

export default function FiltersTransportGroup(props) {
  const theme = useTheme();
  const [transportModes, setTransportModes] = useState(null);
  const [renderModes, setRenderModes] = useState(null);
  const filters = useSelector(filtersInformation);
  const favorites = useSelector(favoritesInformation);
  const activeFilterButton = useSelector(filterButton);

  useEffect(() => {
    FilterUtils.filterByTransport(
      filters.modes,
      filters.operators,
      favorites.agencyId,
      activeFilterButton
    );
  }, [filters]);

  const t = useTranslate();
  const language = useLanguage();
  const dispatch = useDispatch();

  useEffect(() => {
    VM.modes?.listen(() => setTransportModes(VM.modes?.data) ?? []);
  }, []);

  useEffect(() => {
    async function fetchData() {
      setRenderModes(await getRenderModes());
    }
    fetchData();
  }, [transportModes, filters]);

  useEffect(() => {
    if (VM.modes.data) setTransportModes(VM.modes?.data);
  }, [language]);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
    emptyContent: {
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      margin: 30
    },
    label: {
      fontSize: 20,
      color: theme.colors.neutralDarkgray
    }
  };
  const changeSelection = (value) => {
    let updateFiltersModes = JSON.parse(JSON.stringify(filters.modes));
    if (updateFiltersModes.indexOf(value) < 0) updateFiltersModes.push(value);
    else updateFiltersModes.splice(updateFiltersModes.indexOf(value), 1);
    dispatch(filtersSlice.actions.updateModes(updateFiltersModes));
  };

  const getRenderModes = async () => {
    let res = [];
    for (let mode in transportModes) {
      res.push(
        <TransportButton
          key={transportModes[mode].id}
          id={transportModes[mode].id}
          name={transportModes[mode].label}
          icon={await VM.icons.getIcon(transportModes[mode].iconId)}
          style={{ width: '45px', margin: 10 }}
          selected={filters.modes.indexOf(transportModes[mode].id) >= 0}
          onClick={(value) => {
            changeSelection(value);
          }}
        />
      );
    }
    return res;
  };

  return <div style={{ ...styles.container, ...props.style }}>{renderModes}</div>;
}
