import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchNominatimMutation } from '../../../../../redux/login/search/SearchServiceApi';
import { searchInformation, searchSlice } from '../../../../../redux/slices/searchSlice';
import { useLanguage, useTranslate } from '../../../../context/languageContext';
import Button from '../../../../components/commons/Button';
import { ClipLoader } from 'react-spinners';

interface LoadMoreOtherResultsProps {
  searching: boolean;
  text?: string;
}

export default function LoadMoreOtherResults(props: LoadMoreOtherResultsProps) {
  const dispatch = useDispatch();
  const [searchOthers] = useSearchNominatimMutation();
  const [numPage, setNumPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const others = useSelector(searchInformation).others as any;
  const language = useLanguage();
  const t = useTranslate();

  async function obtainMoreOtherResults(mounted: boolean) {
    setLoading(true);
    searchOthers({ locale: language, txt: props.text, numPage: numPage }).then((data: any) => {
      setLoading(false);
      let copyRes = JSON.parse(JSON.stringify(others));
      let result = data?.data ?? [];
      if (result?.length > 0 && !props.searching && mounted) {
        copyRes = copyRes.concat(result);
        dispatch(searchSlice.actions.updateOthers(copyRes));
      } else {
        setEndReached(true);
      }
    });
  }

  useEffect(() => {
    let mounted = true;

    numPage > 1 ? obtainMoreOtherResults(mounted) : null;

    return () => {
      mounted = false;
    };
  }, [numPage, language, props.text]);

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        justifyContent: loading ? 'center' : 'flex-end',
        alignItems: 'center',
        marginTop: 12,
        paddingRight: 16
      }}>
      {endReached || others?.length < 5 ? null : (
        <>
          {loading ? (
            <ClipLoader />
          ) : (
            <Button text={t('searchMoreResults')} icon={null} onClick={() => setNumPage(numPage + 1)} />
          )}
        </>
      )}
    </div>
  );
}
