import React from 'react'
import { useGetMessagesMutation } from '../redux/login/services/MessagesServiceApi';
import { useDispatch } from 'react-redux';
import { useLanguage } from '../view/context/languageContext';
import { notificationSlice } from '../redux/slices/notificationSlice';

export default function useNotificationsUtils() {
  const dispatch = useDispatch();
  const language = useLanguage();
  const [getMessages] = useGetMessagesMutation();
    //{ locale: language, pageNumber: 1, elementsPerPage: elementsPerPage }


  async function checkReadedMessages() {
    const result: any = await getMessages({ locale: language, pageNumber: 1, elementsPerPage: 10 });
    if (result?.data) {
        const messageChatData = result?.data;
        if (messageChatData?.chats?.length) {
            let chats = messageChatData?.chats;
            let checked = false;
            let i = 0;
            while(!checked && i < chats?.length ) {
                //infoMessages[element].numMessages > 1 && infoMessages[element].unreaded > 0
                if (chats[i]?.numMessages > 1 && chats[i]?.unreaded > 0) {
                    checked = true;
                }
                i++;
            }
            dispatch(notificationSlice.actions.updateCheckMessages(checked));
        }
    }
    
  }

  return {
    checkReadedMessages,
  };
}
