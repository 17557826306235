import ViewModel from '../@base/ViewModel';
import {Apis} from "../../domain/Apis";

export default class CategoriesViewModel extends ViewModel {
  constructor() {
    super();
  }

  async get(language) {
    return super.notify(await Apis.categories.getCategories(language));
  }
}
