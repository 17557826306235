import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from '../../store';
import GeneralUtils from '../../../utils/GeneralUtils';

export const stopsServiceApi = createApi({
  reducerPath: 'stopsServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GeneralUtils.getEnvironment(window.location.origin),
    prepareHeaders: (headers, {getState, endpoint}) => {
      const token = (getState() as RootState).authSlice.accountInformation.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
    },
  }),
  endpoints: builder => ({
    getStopById: builder.mutation<any, any>({
      query: ({id, locale}) => ({
        url: `agency/stops/${id}?locale=${locale}`,
        method: 'GET',
      }),
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {}
      },
    }),
    getStopsByDistance: builder.mutation<any, any>({
      query: ({locale}) => ({
        url: `agency/stops/cache/?locale=${locale}`,
        method: 'GET',
        /* headers: {
          timeout: '20000',
        }, */
      }),
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {}
      },
    }),
    getStopLines: builder.mutation<any, any>({
      query: ({id}) => ({
        url: `agency/stops/${id}/lines`,
        method: 'GET',
      }),
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {}
      },
    }),
    getStopsByText: builder.mutation<any, any>({
      query: ({locale, text}) => ({
        url: `agency/stops?txt=${text}&locale=${locale}`,
        method: 'GET',
      }),
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {}
      },
    }),
    getStopLineTimes: builder.mutation<any, any>({
      query: ({id, locale, date}) => ({
        url: `agency/stops/${id}/linestimes?locale=${locale}${
          date ? `&date=${date}` : ''
        }`,
        method: 'GET',
      }),
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {}
      },
    }),
    getStopRealLineTimes: builder.mutation<any, any>({
      query: ({id, locale}) => ({
        url: `agency/stops/${id}/linestimes/updates?locale=${locale}`,
        method: 'GET',
      }),
    }),
    getLinesWithHeadsigns: builder.mutation<any, any>({
      query: ({stopId, language}) => ({
        url: `agency/stops/${stopId}/lineswithheadsigns?language=${language}`,
      }),
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        console.log('starting!');
        try {
          const {data} = await queryFulfilled;
          console.log('success!', data);
        } catch (err) {
          console.log('error... ', err);
        } finally {
        }
      },
    }),
  }),
});
//getLinesWithHeadsigns
export const {
  useGetStopByIdMutation,
  useGetStopLineTimesMutation,
  useGetStopLinesMutation,
  useGetStopsByDistanceMutation,
  useGetStopsByTextMutation,
  useGetLinesWithHeadsignsMutation,
  useGetStopRealLineTimesMutation,
} = stopsServiceApi;
