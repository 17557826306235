import {VM} from "../viewmodel/VM";

export default class PlanUtils {
    static getIntermediates(){
        let res=''
        for(let stop in VM.plannerSegments.data){
            if(stop!=="origin" && stop!=='destination' && VM.plannerSegments.data[stop]?.coords){
                res+=VM.plannerSegments.data[stop].coords.latitude+','+VM.plannerSegments.data[stop].coords.longitude+','
            }
        }
        res=res.slice(0, -1);
        return res
    }

    static getOrigin(){
        return VM.plannerSegments.data['origin']?.coords.latitude+','+VM.plannerSegments.data['origin']?.coords.longitude
    }

    static getDestination(){
        return VM.plannerSegments.data['destination']?.coords.latitude+','+VM.plannerSegments.data['destination']?.coords.longitude

    }

    static getPreviewCoords(){
        let coords=[];
        for(let stop in VM.plannerSegments.data) {
            if (VM.plannerSegments?.data[stop] && stop == 'origin')
                coords.unshift({
                    lat: VM.plannerSegments.data[stop]?.coords.latitude,
                    lng: VM.plannerSegments.data[stop]?.coords.longitude
                })
            if (stop !== "origin" && VM.plannerSegments.data[stop]?.coords) {
                if (VM.plannerSegments?.data[stop])
                    coords.push({
                        lat: VM.plannerSegments.data[stop]?.coords.latitude,
                        lng: VM.plannerSegments.data[stop]?.coords.longitude
                    })
            }
        }
        return coords;
    }

    static getMidPoint(point1, point2){
        if (parseFloat(point2?.longitude)<parseFloat(point1?.longitude))
            return {lat:(parseFloat(point1?.latitude)+parseFloat(point2?.latitude))/2,lng:(parseFloat(point1?.longitude)+parseFloat(point2?.longitude)*3)/4}
        else return {lat:(parseFloat(point1?.latitude)+parseFloat(point2?.latitude))/2,lng:(parseFloat(point1?.longitude)*3+parseFloat(point2?.longitude))/4}
    }

    static ss(point1, point2){
        let one = Math.abs(parseFloat(point1?.latitude)-parseFloat(point2?.latitude))
        let two = Math.abs(parseFloat(point1?.longitude)-parseFloat(point2?.longitude))
        let bigger = 0
        if(one>two)
            bigger=one
        else bigger=two
        return bigger
    }

    static getMidZoom(point1, point2) {
        let longitude1=point1?.longitude
        let longitude2=point2?.longitude
        let latitude1=point1?.latitude
        let latitude2=point2?.latitude
        let theta = longitude1 - longitude2;
        let distance = 60 * 1.1515 * (180/Math.PI) * Math.acos(
            Math.sin(latitude1 * (Math.PI/180)) * Math.sin(latitude2 * (Math.PI/180)) +
            Math.cos(latitude1 * (Math.PI/180)) * Math.cos(latitude2 * (Math.PI/180)) * Math.cos(theta * (Math.PI/180))
        );
        let res = distance * 1.609344;
        if(res>=0 && res<0.4) return 18
        if(res>=0.4 && res<1) return 16
        if(res>=1 && res<3) return 15
        if(res>=3 && res<5) return 14
        if(res>=5 && res<10) return 13
        if(res>=10 && res<25) return 12
        if(res>=25 && res<50) return 11
        if(res>=50 && res<100) return 10
        if(res>=100) return 9

        return res
    }
}

