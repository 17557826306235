import { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteFavoriteMutation } from '../../../redux/login/favorite/FavoriteServiceApi';
import { useSharePlanMutation } from '../../../redux/login/share/ShareServiceApi';
// import { useSharePlanQuery } from '../../../redux/login/share/ShareServiceApi';
import { eventsModalstoggleVisibilitySlice } from '../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { favoritesInformation, favoritesSlice } from '../../../redux/slices/favoritesSlice';
import { planInformation, shareSlice } from '../../../redux/slices/shareSlice';
import { VM } from '../../../viewmodel/VM';
import { useTranslate } from '../../context/languageContext';
import { useResponsive } from '../../context/responsiveContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';

export default function FavoritePlan(props: any) {
  const ArrowDown = R.drawables.general.Ic_ChevronDownSmall;
  const ArrowUp = R.drawables.general.Ic_ChevronUpSmall;
  const Remove = R.drawables.general.Ic_delete;

  const [removeFavorite] = useDeleteFavoriteMutation();
  const favorites = useSelector(favoritesInformation);
  const planToShare = useSelector(planInformation) as any;
  const dispatch = useDispatch();
  const t = useTranslate();
  const [opened, setOpened] = useState(false);
  const content = useRef(null as any);
  const theme = useTheme();
  const responsive = useResponsive();
  // const { data: sharePlanData, error: sharePlanError } = useSharePlanQuery(props.plan.id ?? null);
  const [sharePlan] = useSharePlanMutation();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickComponent);
  }, []);

  useEffect(() => () => document.removeEventListener('mousedown', handleClickComponent), []);

  const handleClickComponent = (event: any) => {
    if (content && !content.current?.contains(event.target)) {
      setOpened(false);
    }
  };

  const getValue = (point: any) => {
    if (point?.poiId) return VM.pois.map[point.poiId]?.name;
    else if (point?.stopId) return VM.stops.map[point.stopId]?.name;
    else if (point.favoriteAddressId) {
      let result = null as any;
      result = favorites.directions.filter((id: any) => id.id == point.favoriteAddressId);
      return result[0]?.alias
        ? result[0]?.alias
        : point.latitude.toFixed(6) + ', ' + point.longitude.toFixed(6);
    } else return point.address;
  };

  function truncate(str: string) {
    if (str !== undefined && str !== null) {
      return str.length > (responsive.isBig ? 40 : 20)
        ? str.substring(0, responsive.isBig ? 37 : 17) + '...'
        : str;
    }
  }

  const openPlan = () => {
    let planPoints={} as any
    VM.plannerSegments.clear();
    async function getFromApi() {
      await sharePlan({ planId: props.plan.id }).then((data) => {
        if ('data' in data) {
          planPoints = data?.data?.planPoints;
        }
      });
      for (let point in planPoints) {
        if (parseInt(point) === 0) {
          VM.plannerSegments.set('origin', {
            name: planPoints[point]?.address ?? t('origin'),
            coords: {
              latitude: planPoints[point].latitude,
              longitude: planPoints[point].longitude
            }
          });
        } else if (parseInt(point) === planPoints.length - 1) {
          VM.plannerSegments.set('destination', {
            name: planPoints[point]?.address ??  t('destination'),
            coords: {
              latitude: planPoints[point].latitude,
              longitude: planPoints[point].longitude
            }
          });
        } else {
          VM.plannerSegments.set(parseInt(point) - 1, {
            name:planPoints[point]?.address ??
                planPoints[point].latitude.toFixed(6) + ', ' + planPoints[point].longitude.toFixed(6),
            coords: {
              latitude: planPoints[point].latitude,
              longitude: planPoints[point].longitude
            }
          });
        }
      }
    }
    getFromApi();
    VM.screenSelected.setSelected('explore');
  };

  const renderContent = () => {
    let res = [];
    for (let element in props.plan.planPoints) {
      getValue(props.plan.planPoints[element]);
      if (props.plan.planPoints[element].order == 1)
        res.push(
          <div className={'row'} key={element}>
            <div className={'originPoint'} />
            <div className={'primaryText marginLeft5'}>{t('origin')}</div>
            <div className={'cornerText marginLeft5 bold'} style={{ fontSize: 13 }}>
              {getValue(props.plan.planPoints[element])}
            </div>
            {opened ? <ArrowUp className={'close'} /> : <ArrowDown className={'close'} />}
          </div>
        );
      else
        res.push(
          <div className={'row'} key={element}>
            <div
              className={
                props.plan.planPoints[element].order === props.plan.planPoints.length
                  ? 'destPoint'
                  : 'intermediatePoint'
              }>
              {props.plan.planPoints[element].order - 1}
            </div>
            <div className={'primaryText marginLeft5'}>
              {props.plan.planPoints[element].order === props.plan.planPoints.length
                ? t('destination')
                : t('stop')}
            </div>
            <div className={'cornerText marginLeft5 bold'} style={{ fontSize: 13 }}>
              {truncate(getValue(props.plan.planPoints[element]))}
            </div>
          </div>
        );
    }
    return (
      <div
        className={opened ? 'favorite-station-body-c' : 'favorite-station-body-o'}
        onDoubleClick={openPlan}
        onClick={() => setOpened(!opened)}>
        {res}
      </div>
    );
  };

  const removeFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    for (let plan in updateFavorites.plans) {
      if (updateFavorites.plans[plan].id === props.plan.id) {
        updateFavorites.plans.splice(plan, 1);
        removeFavorite({
          stops: [],
          pois: [],
          plans: [props.plan.id],
          directions: []
        });
        dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#00763D',
            text: t('planRemoved')
          })
        );
      }
    }
  };

  return (
    <div
      className={'favorite-station-container'}
      style={{ maxHeight: window.innerHeight - 165 }}
      ref={content}>
      {renderContent()}

      <Collapse isOpened={opened}>
        <div className={'favorite-station-footer row'}>
          <div
            className={'row'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              removeFav();
            }}>
            <Remove style={{ width: 20, marginRight: 5, fill: theme.colors.neutral }} />
            <div className={'primaryText'} style={{ color: theme.colors.neutral }}>
              {t('delete')}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
