import React, { useRef } from 'react';
import { MapContainer, Pane, TileLayer, ZoomControl } from 'react-leaflet';
import LeafLetMap from './LeafletMap';

export function MapLeafLetContainer(props, ref) {
  const mapRef = useRef();

  React.useImperativeHandle(ref, () => ({
    flyTo,
    getMapData
  }));

  const flyTo = (location, zoom) => {
    mapRef.current.flyTo(location, zoom);
  };

  const getMapData = () => {
      return {coords:mapRef.current._lastCenter,zoom:mapRef.current._zoom,}
  };

  return (
    <MapContainer
      ref={mapRef}
      center={JSON.parse(localStorage.getItem('mapData'))?.coords ??{ lat: 43.047286, lng: -2.32345 }}
      preferCanvas={true}
      minZoom={10}
      maxZoom={18}
      zoom={JSON.parse(localStorage.getItem('mapData'))?.zoom ?? 10}
      style={{ width: '100%', height: '100%', position: 'fixed', zIndex: '4' }}
      zoomControl={false}
      dragging={true}
      scrollWheelZoom={true}
      maxBounds={[
        [43.515477473, -3.77362669871015],
        [42.4342400592583, -1.220319103045]
      ]}
      boundsOptions={{ padding: [50, 50] }}
      attributionControl={false}>

      <Pane name="euskadi" style={{ zIndex: 5 }}>
        <TileLayer
          url={parseInt(localStorage.getItem('mapType')) ? "https://www.geo.euskadi.eus/geoeuskadi/rest/services/U11/WMTS_ORTO/MapServer/tile/{z}/{y}/{x}" : "https://www.geo.euskadi.eus/geoeuskadi/rest/services/U11/WMTS_KARTO/MapServer/tile/{z}/{y}/{x}"}
          //url={'https://garraioak.ejgv.euskadi.ejiedes.eus/ad73dResourceWar/api/v1/maps/{z}/{x}/{y}.png'}
          pane={'euskadi'}
        />
        <ZoomControl position="bottomright" />
      </Pane>
      <Pane name="oms" style={{ zIndex: 4 }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" pane={'oms'} />
      </Pane>
      <LeafLetMap
        markerSelected={props.markerSelected}
        onClickMarker={props.onClickMarker}
        onClick={props.onClick}
        onDblClick={props.onDblClick}
      />
    </MapContainer>
  );
}

MapLeafLetContainer = React.forwardRef(MapLeafLetContainer);
