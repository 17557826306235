import { useDispatch, useSelector } from 'react-redux';
import { useDeleteFavoriteMutation } from '../../../../../../redux/login/favorite/FavoriteServiceApi';
import { accountInformation } from '../../../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import {
  favoritesInformation,
  favoritesSlice
} from '../../../../../../redux/slices/favoritesSlice';
import GeneralUtils from '../../../../../../utils/GeneralUtils';
import RouteUtils from '../../../../../../utils/RouteUtils';
import { VM } from '../../../../../../viewmodel/VM';
import Button from '../../../../../components/commons/Button';
import ButtonIcon from '../../../../../components/commons/ButtonIcon';
import DefaultModal from '../../../../../components/widget/Modals/DefaultModal';
import { useTranslate } from '../../../../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../../../../context/modalContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';

export default function DirectionMidInfo(props) {
  const theme = useTheme();
  const t = useTranslate();

  const dispatch = useDispatch();
  const favorites = useSelector(favoritesInformation);
  const [removeFavorite] = useDeleteFavoriteMutation();
  const setChild = useSetModalChild();
  const setOpen = useSetModalOpened();
  const account = useSelector(accountInformation);

  const getIsFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    for (let dire in updateFavorites.directions) {
      if (updateFavorites.directions[dire]?.direction === props.direction?.direction) {
        return updateFavorites.directions[dire].id;
      }
    }
    return null;
  };

  const getIndexFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    for (let dire in updateFavorites.directions) {
      if (updateFavorites.directions[dire]?.direction === props.direction?.direction) {
        return dire;
      }
    }
    return null;
  };

  const updateFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    if (!getIsFav()) {
      if (account.idUserType === 1) {
        setOpen(true);
        setChild(
          <DefaultModal
            title={t('loginToFav')}
            description={t('loginToFavBody')}
            alter={true}
            firstButton={t('createAccount')}
            secondButton={t('login')}
            onClickFirst={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                  true
                )
              );
            }}
            onClickSecond={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
              );
            }}
          />
        );
      } else {
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAddDirectionModalWidget({
            showModal: true,
            direction: props.direction,
            type: 1
          })
        );
      }
    } else {
      setOpen(true);
      setChild(
        <DefaultModal
          title={t('deleteFavConfirm')}
          onClickSecond={() => {
            updateFavorites.directions.splice(getIndexFav(), 1);
            removeFavorite({
              stops: [],
              pois: [],
              plans: [],
              directions: [getIsFav()]
            });
            props.onDelete(props.direction);
            dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
                {
                  showModal: true,
                  background: '#00763D',
                  text: t('directionRemoved')
                }
              )
            );
          }}
        />
      );
    }
  };

  const styles = {
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center'
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginTop: 10,
      marginBottom: 10,
      width: '100%'
    }
  };

  const goThere = () => {
    let coords = { latitude: props.direction.latitude, longitude: props.direction.longitude };
    let name = props.direction?.alias ?? `${props.direction?.name}. ${props.direction?.direction}`;
    let info = RouteUtils.getTypeId(props.direction, favorites);
    let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };
    VM.plannerSegments.set('destination', res);
  };

  const onShare = () => {
    let path = window.location.href.split('/');
    path.pop();
    path = path.join('/');
    let copy = `${path[path.length - 1] === '#' ? '' : '/#'}/direction?${GeneralUtils.convertToHex(
      `coords=${props.direction.latitude},${props.direction.longitude}`
    )}`;
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityShareModalWidget({
        showModal: true,
        shareLink: path + copy,
        title: props.direction.direction
      })
    );
    // setTimeout(ReactTooltip.hide, 2000);
    // navigator.clipboard.writeText(path + copy);
  };

  return (
    <div>
      <div style={styles.separator} />
      <div style={{ ...styles.row }}>
        <Button
          icon={R.drawables.map.goThere}
          text={t('goThere')}
          iconRight={true}
          textColor={theme.colors.darkGray}
          iconSize={10}
          iconColor={theme.colors.darkGray}
          borderColor={theme.colors.border}
          onClick={goThere}
        />
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          {/* <ReactTooltip event="focusin" effect={'solid'} id="main" /> */}
          <ButtonIcon
            style={{ marginLeft: 10 }}
            onClick={() => onShare()}
            icon={R.drawables.commons.share}
            iconSize={15}
            dataTip={t('copyUrl')}
            dataFor={'main'}
            iconColor={theme.colors.darkGray}
            borderColor={theme.colors.border}
            background={theme.colors.white}
          />
          <ButtonIcon
            onClick={() => updateFav()}
            style={{ marginLeft: 10 }}
            iconColor={theme.colors.darkGray}
            iconSize={15}
            icon={
              !getIsFav() ? R.drawables.general.Ic_favorite : R.drawables.general.Ic_favorite_blue
            }
            background={theme.colors.white}
          />
        </div>
      </div>
    </div>
  );
}
