import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface planInformation {
  planId: string;
}

const initialState = {
  planInformation: {
    planId: null
  }
};

export const shareSlice = createSlice({
  name: 'shareSlice',
  initialState,
  reducers: {
    sharePlan: (state, action) => {
      state.planInformation.planId = action.payload;
    }
  }
});

export const planInformation = (state: RootState) => state.shareSlice.planInformation;
