import React, { Component } from 'react';
import { Apis } from '../../../domain/Apis';
import ViewModel from '../../@base/ViewModel';
import FilterUtils from '../../../utils/FilterUtils';
import {VM} from "../../VM";

export default class TransportFiltersViewModel extends ViewModel {
  async get(locale) {
    let modes = await Apis.transportModes.getAllTransportModes(locale).catch(e=>[]);
    return super.notify(modes, true)
  }

  set(values) {
    let array = Array.from(values.values());
    localStorage.setItem('transportFilters', JSON.stringify(array));
    if(VM.operatorsFilters.data.agencies)
      FilterUtils.filterByTransport(Object.fromEntries(values), Object.fromEntries(VM.operatorsFilters.data.agencies))
    return super.notify({modes: values});
  }
}
