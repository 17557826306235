import Api from '../@base/Api';

export default class NewsApi extends Api {
    constructor(url) {
        super(url);
    }

    getAllNewsByPagination(
        locale,
        transportmode,
        agency,
        platform,
        pagenumber,
        elementsperpage,) {
        return super.get(`/notice/?locale=${locale}${transportmode ? '&transportmode='+transportmode : ""}${agency ? '&agency='+agency : ""}${platform ?  '&platform='+platform : ""}&pagenumber=${pagenumber ?? ""}&elementsperpage=${elementsperpage ?? ""}`);
    }

    getArticle(locale, id) {
        return super.get(`/notice/${id}?locale=${locale}`)
    }

    getTransportsWithNews(locale){
        return super.get(`/transportmodeAgencyWithNews?locale=${locale}`)
    }

}
