import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountInformation } from '../../../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import {
  favoritesInformation,
  favoritesSlice,
  filterButton
} from '../../../../../../redux/slices/favoritesSlice';
import { filtersInformation } from '../../../../../../redux/slices/filtersSlice';
import FilterUtils from '../../../../../../utils/FilterUtils';
import { VM } from '../../../../../../viewmodel/VM';
import ButtonIcon from '../../../../../components/commons/ButtonIcon';
import DefaultModal from '../../../../../components/widget/Modals/DefaultModal';
import { useTranslate } from '../../../../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../../../../context/modalContext';
import { R } from '../../../../../R';
import GeneralUtils from '../../../../../../utils/GeneralUtils';

export default function FilterButton(props: any) {
  const activeFilterButton = useSelector(filterButton);
  const account = useSelector(accountInformation);
  const setChild = useSetModalChild();
  const setOpen = useSetModalOpened();
  const t = useTranslate();
  const dispatch = useDispatch();
  const filters = useSelector(filtersInformation);
  const favorites = useSelector(favoritesInformation);

  useEffect(() => {
    if (!favorites.agencyId?.length) dispatch(favoritesSlice.actions.setFilterButton(false));
    FilterUtils.filterByTransport(
      filters.modes,
      filters.operators,
      favorites.agencyId,
      activeFilterButton
    );
  }, [favorites.agencyId, filters]);

  const getTransportIcon = () => {
    if (!favorites.agencyId?.length) {
      return R.drawables.general.Ic_myTransportNo;
    } else if (activeFilterButton) {
      return R.drawables.general.Ic_myTransportSel;
    } else return R.drawables.general.Ic_myTransportYes;
  };

  const onClick = () => {
    if (account.idUserType === 1) {
      setOpen(true);
      setChild(
        <DefaultModal
          title={t('login2')}
          description={t('loginToFavBody')}
          alter={true}
          firstButton={t('createAccount')}
          secondButton={t('login')}
          onClickFirst={() => {
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                true
              )
            );
          }}
          onClickSecond={() => {
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
            );
          }}
        />
      );
    } else if (!favorites.agencyId?.length) {
      setOpen(true);
      setChild(
        <DefaultModal
          title={t('noTransportSaved')}
          description={t('noTransportSavedBody')}
          alter={true}
          firstButton={t('close')}
          secondButton={t('goToFav')}
          onClickSecond={() => {
            VM.screenSelected.setSection(3);
            VM.screenSelected.setSelected('favorites');
            GeneralUtils.setLastSectionPath('operators',1);
          }}
        />
      );
    } else {
      dispatch(favoritesSlice.actions.setFilterButton(!activeFilterButton));
      FilterUtils.filterByTransport(
        filters.modes,
        filters.operators,
        favorites.agencyId,
        !activeFilterButton
      );
    }
  };

  return (
    <div className="filterButton">
      <ButtonIcon icon={getTransportIcon()} selected={activeFilterButton} onClick={onClick} />
    </div>
  );
}
