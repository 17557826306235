import { useDispatch } from 'react-redux';
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useTranslate } from '../../../context/languageContext';
import { R } from '../../../R';

export default function ShareScreen(props: any) {
  const t = useTranslate();
  const Apple = R.drawables.providers.AppleStoreBlack;
  const Google = R.drawables.providers.GooglePlayBlack;
  const dispatch = useDispatch();
  const linkToCopy = window.location.href;

  const copyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(linkToCopy);
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCopiedModalWidget());
    } else {
      console.log('Your browser is not supported');
    }
  };

  return (
    <div className="content account-child">
      <div className="account-info-container">
        <div className="titleAccount">{t('share')}</div>
        <div
          className="account-info-section share-link"
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <div className="account-info-email">
            <label>{t('linkToShare')}</label>
            <input value={linkToCopy} disabled></input>
          </div>
          <button className="btn-primary btn-height-40 " onClick={copyLink}>
            {t('copyLink')}
          </button>
        </div>
      </div>

      <div className="download-app">
        <h3>{t('shareVia')}</h3>
        <div className="social-share">
          <FacebookShareButton url={linkToCopy}>
            <FacebookIcon size={48} borderRadius={8} />
          </FacebookShareButton>
          <TwitterShareButton url={linkToCopy}>
            <TwitterIcon size={48} borderRadius={8} />
          </TwitterShareButton>
          <TelegramShareButton url={linkToCopy}>
            <TelegramIcon size={48} borderRadius={8} />
          </TelegramShareButton>
          <WhatsappShareButton url={linkToCopy} title={t('appName')}>
            <WhatsappIcon size={48} borderRadius={8} />
          </WhatsappShareButton>
        </div>
      </div>

      <div className="download-app">
        <h3>{t('downloadMobileApp')}</h3>
        <div className="download-app-row">
          <p>{t('goToAppleStore')}</p>
          <Apple onClick={()=>{window.open('https://apps.apple.com/us/app/moveuskadi/id1665662284')}}/>
        </div>
        <div className="download-app-row">
          <p>{t('goToGooglePlay')}</p>
          <Google onClick={()=>{window.open('https://play.google.com/store/apps/details?id=com.ejie.moveuskadi')}}/>
        </div>
      </div>
    </div>
  );
}
