import React, {useEffect, useRef, useState} from "react";
import {R} from "../../../../../../R";
import {useLanguage, useTranslate} from "../../../../../../context/languageContext";
import {Collapse} from "react-collapse/lib/Collapse";
import {useTheme} from "../../../../../../context/themeContext";
import ExitNow from "./time/ExitNow";
import Preferences from "./preferences/Preferences";
import {VM} from "../../../../../../../viewmodel/VM";
import TimeUtils from "../../../../../../../utils/TimeUtils";

export default function PlannerConfig(props, ref) {
    const t = useTranslate()
    const theme = useTheme()
    const language = useLanguage()
    const Up = R.drawables.general.Ic_ChevronUpSmall
    const Down = R.drawables.general.Ic_ChevronDownSmall
    const [selected, setSelected] = useState()
    const [exitNowText, setExitNowText] = useState(t("exitNow"))
    const content = useRef(null)

    useEffect(() => {
        let time = TimeUtils.convertTime12to24(VM.plannerTime.data.time)
        let date = VM.plannerTime.data.date
        if(VM.plannerTime.data.exitNow)
            setExitNowText(t("exitNow"))
        else if(VM.plannerTime.data.arriveBy)
            setExitNowText(t("arriveAt") + ' ' + getFormattedDate({ time: time, date: date }))
        else
            setExitNowText(t("exitAtText") + ' ' + getFormattedDate({ time: time, date: date }))
        document.addEventListener('mousedown', handleClickComponent)
    },[]);

    useEffect( () => () =>document.removeEventListener('mousedown', handleClickComponent),[])

    const handleClickComponent = (event) => {
        if (content && !content.current?.contains(event.target)) {
            setSelected(null);
        }
    }

    const styles={
        content: {
            paddingTop:10,
            paddingInline:10,
            background: theme.colors.white,
        },
        collapse:{
            width:'85.7vw',
            maxWidth: "369px",
            minWidth: "255px",
            position:'fixed',
            background: theme.colors.white,
            zIndex:1,
            marginLeft:-22,
            boxShadow: "0px 4px 4px rgba(28, 31, 32, 0.1)",
            borderRadius: "0 0 12px 12px",
            backgroundColor: theme.colors.white,
        },
        row: {
            display:'flex',
        },
        option: {
            display:'flex',
            cursor:'pointer'
        },
        icon: {
            cursor:'pointer',
            height:15,
        },
        exitNow:{
            fontSize:14,
            fontWeight:600,
        },
        preferences : {
            fontSize:14,
            fontWeight:600,
            marginLeft:'auto'
        }

    }

    const select = (id) => {
        if (selected === id)
            setSelected(null)
        else setSelected(id);
    }

    const onChangeSelected = (selected, arriveTime, exitTime ) => {
        switch(selected){
            case 0: setExitNowText(t("exitNow")); break
            case 1: setExitNowText(t("exitAtText") + ' ' + getFormattedDate(exitTime)); break
            case 2: setExitNowText(t("arriveAt") + ' ' + getFormattedDate(arriveTime)); break
            default: break;
        }
    }

    const getFormattedDate = (date)  => {
        let day;
        let month;
        let data = new Date(date.date.toString().split('-').reverse().join('-'))
        switch (data.getDay()){
            case 1: day=t('monday'); break;
            case 2: day=t('tuesday'); break;
            case 3: day=t('wednesday'); break;
            case 4: day=t('thursday'); break;
            case 5: day=t('friday'); break;
            case 6: day=t('saturday'); break;
            case 0: day=t('sunday'); break;
            default: day=t('-'); break;
        }
        switch (data.getMonth()){
            case 0: month=t('january'); break;
            case 1: month=t('february'); break;
            case 2: month=t('march'); break;
            case 3: month=t('april'); break;
            case 4: month=t('may'); break;
            case 5: month=t('june'); break;
            case 6: month=t('july'); break;
            case 7: month=t('august'); break;
            case 8: month=t('september'); break;
            case 9: month=t('october'); break;
            case 10: month=t('november'); break;
            case 11: month=t('december'); break;
            default: month=t('-'); break;
        }
        let res = language==='eu'
            ? `${day}, ${month} ${data.getDate()}, ${date?.time ? date?.time : ''}`
            : `${day}, ${data.getDate()}  ${month}, ${date?.time ? date?.time : '' }`
        return res
    }

    return (
        <div style={{...props.style, ...styles.content}} ref={content}>
            <div style={styles.row}>
                <div style={styles.option} onClick={()=>select(0)}>
                    <div style={styles.exitNow}>{exitNowText}</div>
                    {selected === 0
                        ? <Up/>
                        : <Down/>
                    }
                </div>

                <div style={{...styles.option, marginLeft:'auto'}} onClick={()=>select(1)}>
                    <div style={styles.preferences}>{t("preferences")}</div>
                    {selected === 1
                        ? <Up/>
                        : <Down/>
                    }
                </div>
            </div>

            <div style={styles.collapse}>
                <Collapse isOpened={selected===0}>
                    <ExitNow onChangeSelected={(selected, exitTime, arriveTime)=>
                        onChangeSelected(selected, exitTime, arriveTime)}/>
                </Collapse>
            </div>

            <div style={styles.collapse}>
                <Collapse isOpened={selected === 1}>
                    <Preferences/>
                </Collapse>
            </div>

        </div>
    );
}
