import { useDispatch } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../redux/slices/eventsModalstoggleVisibilitySlice';
import {useTranslate} from "../../context/languageContext";

export default function LinkLogin(props: any) {
  const t = useTranslate()
  const styles = {
    p: {
      color: '#FFFFFF',
      fontFamily: 'Open Sans',
      fontSize: 12
    },
    link: {
      color: '#FFFFFF',
      textDecoration: 'none',
      fontWeight: '600'
    }
  } as const;
  const dispatch = useDispatch();

  return (
    <div style={props.style}>
      <p style={styles.p}>
          {t('alreadyHaveAccount')}.{' '}
        <a
          style={styles.link}
          onClick={() => {
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                false
              )
            );
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
            );
          }}>
            {t('login')}
        </a>
      </p>
    </div>
  );
}
