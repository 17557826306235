import ViewModel from '../@base/ViewModel';
import {Apis} from "../../domain/Apis";

export default class AgenciesViewModel extends ViewModel {
    constructor() {
        super();
    }

    async get(language) {
        return super.notify(await Apis.agency.getAllAgencies(language));
    }
}
