import { useState } from 'react';
import { VM } from '../../../viewmodel/VM';
import { useTranslate } from '../../context/languageContext';
import { useResponsive } from '../../context/responsiveContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';
import AccountInfo from './AccountFeaturesSections/AccountInfoScreen';
import AccountSection from './AccountSection';

export default function AccountScreen(props: any, ref: any) {
  const theme = useTheme();
  const t = useTranslate();
  const [child, setChild] = useState(<AccountInfo props={props} />);
  const Close = R.drawables.general.Ic_close;
  const responsive = useResponsive();

  // React.useImperativeHandle(ref, () => ({
  //   onClick: onClick
  // }));

  const styles = {
    close: {
      cursor: 'pointer',
      position: 'absolute',
      top: 'auto',
      right: '2%',
      width: 24,
      height: 24
    },
    body: {
      display: 'flex',
      flex: 4,
      height: '100%',
      background: theme.colors.background,
      borderRadius: '0px 0px 24px 24px'
    },
    menu: {
      display: 'flex'
    },
    account: {
      width: '100%',
      height: '100%',
      minWidth: 130,
      minHeight: 470,
      maxHeight: 470
    }
  };

  const onClick = (e: any) => {
    
  };

  return (
    <div
      className="account-container"
      style={{
        maxWidth: !responsive.isBig ? 600 : 1120
      }}>
      <div
        className="account-header"
        style={{
          paddingTop: !responsive.isBig ? 20 : '2%',
          paddingRight: !responsive.isBig ? 20 : '2%',
          paddingLeft: !responsive.isBig ? 20 : '2%',
          paddingBottom: !responsive.isBig ? 20 : '2%'
        }}>
        <p>{t('account')}</p>
        <Close
          onClick={() => {
            VM.screenSelected.setSelected('explore');
          }}
          fill={theme.colors.white}
          style={styles.close}
        />
      </div>
      <div style={styles.body}>
        <div style={styles.menu}>
          {/* <UserData setChild={setChild} setOpen={props.setOpen} /> */}
          <AccountSection setChild={setChild} />
        </div>
        {child}
      </div>
    </div>
  );
}

// AccountScreen = React.forwardRef(AccountScreen);
