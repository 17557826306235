import Api from '../@base/Api';

export default class SearchApi extends Api {
    constructor(url) {
        super(url);
    }

    getPois(language, txt) {
        return super.get(`/search/?locale=${language}&txt=${txt}`);
    }

    getStops(language, txt) {
        return super.get(`/agency/stops?txt=${txt}`);
    }
}
