import ViewModel from '../@base/ViewModel';

export default class RecentsViewModel extends ViewModel {
  constructor() {
    super();
  }

  get() {
    let recents = localStorage.getItem("recents")
    if(recents)
      super.notify(JSON.parse(recents));
    else this.data= {}
  }

  add(recent) {
    let save;
    let i=0;
    if(Object.keys(this.data).length===5) {
      for (let element in this.data) {
        if(i===1){
          save = this.data[element]
          this.data = {}
          break;
        }
        i++
      }
    }
    this.data[recent.id]=recent
    if(save)
      this.data[save.id]=save
    localStorage.setItem("recents", JSON.stringify(this.data))
  }
}

