import React, {createContext, useState, useContext} from 'react';

const ThemeContext = createContext();
const SetThemeContext = createContext();

const colors = {
    light: require('../../res/values/colors/light').colors,
    dark: require('../../res/values/colors/dark').colors,
};

export function ThemeProvider({children}) {

    const [theme, setTheme] = useState({
        colors: colors.light
    });

    function changeTheme(theme) {
        if(theme === "light")
            setTheme({colors: colors.light})
        else
            setTheme({colors: colors.dark})
    }

    return (
        <ThemeContext.Provider value={theme}>
            <SetThemeContext.Provider value={changeTheme}>
                {children}
            </SetThemeContext.Provider>
        </ThemeContext.Provider>
    );
}

export function useTheme() {
    return useContext(ThemeContext);
}

export function useSetTheme() {
    return useContext(SetThemeContext);
}
