import {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useUpdateUserMutation,
  useUserMeQuery
} from '../../../../redux/login/services/authServiceApi';
import { accountInformation, authSlice } from '../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import Button from '../../../components/commons/Button';
import ButtonNext from '../../../components/commons/buttons/ButtonNext';
import AccountAnonymousInlineComponent from '../../../components/widget/Inline/AccountAnonymousInlineComponent';
import { useTranslate } from '../../../context/languageContext';
import { useSetLoading } from '../../../context/loadingContext';

export default function AccountInfoScreen(props: any) {
  const t = useTranslate();
  const account = useSelector(accountInformation);
  const dispatch = useDispatch();

  const setLoading = useSetLoading();
  const [updatedAlias, setUpdatedAlias] = useState(account.alias ? account.alias : '') as any;
  const [updatedGender, setUpdatedGender] = useState(account.idGender ?? '') as any;
  const [updatedAge, setUpdatedAge] = useState(account.rangoEdad ?? '') as any;
  const [updateUser, { error }] = useUpdateUserMutation();
  // const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setUpdatedAlias(account.alias);
    setUpdatedAge(account.rangoEdad);
    setUpdatedGender(account.idGender);
  }, [account]);

  const onChangeAlias = (e: any) => {
    setUpdatedAlias(e.target.value);
  };

  const onChangeGender = (e: any) => {
    setUpdatedGender(e.target.value);
  };
  const onChangeAge = (e: any) => {
    setUpdatedAge(e.target.value);
  };

  const onCancel = (e: any) => {
    e.preventDefault();
    setUpdatedAlias(account.alias ? account.alias : '');
    setUpdatedGender(account.idGender ?? '');
    setUpdatedAge(account.rangoEdad ?? '');
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
        showModal: true,
        background: '#00763D',
        text: t('resetData')
      })
    );
  };

  const removeAccount = (e: any) => {
    e.preventDefault();
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityRemoveAccountModalWidget(true)
    );
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    UpdateUserMutation();
  };

  function handlerOnUpdate() {
    dispatch(authSlice.actions.updateAlias(updatedAlias));
    dispatch(authSlice.actions.updateIdGender(parseInt(updatedGender)));
    dispatch(authSlice.actions.updateRangoEdad(updatedAge));
  }

  async function UpdateUserMutation() {
    setLoading(true);
    const result: any = await updateUser(account);
    setLoading(false);

    //TODO: cambiar condicion - si han cambiado datos??
    if (result && !result.error) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#00763D',
          text: t('accountDataSaved')
        })
      );
      dispatch(authSlice.actions.setUserMe(result.data));
    } else if (result && result.error && result.error.status == 400) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#A31610',
          text: t('noInternetConnection'),
          status: 'error'
        })
      );
    } else {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#A31610',
          text: t('serviceError'),
          status: 'error'
        })
      );
    }
  }

  useUserMeQuery(null, {
    refetchOnMountOrArgChange: true
  });

  return (
    <div className="content account-child">
      <div className="titleAccount">{t('manageAccount')}</div>
      {account.idUserType === 1 ? (
        <AccountAnonymousInlineComponent />
      ) : (
        <form className="account-info-container" onSubmit={onSubmit}>
          <div className="account-info-section account-info-general">
            <h4>{t('infoPersonal')}</h4>

            {/* NAME INPUT */}

            <label>{t('aliasColon')}</label>
            <input value={updatedAlias} onChange={onChangeAlias} required />

            {/* GENDER SELECT */}

            <label>{t('gender')}</label>
            <div className="content-select">
              <select value={updatedGender} onChange={onChangeGender}>
                <option value="1">{t('woman')}</option>
                <option value="2">{t('man')}</option>
                <option value="3">{t('noBinary')}</option>
                <option value="4">{t('another')}</option> 
              </select>
              <i></i>
            </div>

            {/* AGE SELECT */}

            <label>{t('ageComa')}</label>
            <div className="content-select">
              <select value={updatedAge} onChange={onChangeAge}>
                <option value="entre 0 y 19">{t('ageUnder19')}</option>
                <option value="entre 20 y 45">{t('age20to45')}</option>
                <option value="entre 45 y 65">{t('age45to65')}</option>
                <option value="entre 65 y más">{t('ageOver65')}</option>
              </select>
              <i></i>
            </div>
          </div>

          {/* <EMAIL INPUT /> */}

          <div className="account-info-section">
            <h4>{t('emailLong')}</h4>

            <div className="account-info-email">
              <label>{t('emailLong')}</label>
              <input value={account.email ?? ''} disabled></input>
              {/* <Button text={t('change')} textColor="#61686B" fontSize="14px" fontWeight="600" /> */}
            </div>
          </div>

          <div className="account-info-section">
            <h4>{t('password')}</h4>
            <Button
              text={t('changePass')}
              textColor="#61686B"
              fontSize="14px"
              fontWeight="600"
              onClick={(e: any) => {
                e.preventDefault();
                dispatch(
                  eventsModalstoggleVisibilitySlice.actions.toggleVisibilityChangePassModalWidget(
                    true
                  )
                );
              }}
            />
          </div>
          {/* {openModal ? (
            <div
              style={{
                // width: '100%',
                // height: '100%',
                position: 'absolute',
                bottom: 0,
                left: '9.5%',
                // background: 'red',
                zIndex: 10,
                boxShadow: '0px 4px 4px rgba(28, 31, 32, 0.1)'
              }}>
              <DefaultModal
                title={t('removeAccount')}
                description={t('removeAccountNotice')}
                // firstButton={t('cancel')}
                // secondButton={t('accept')}
              />
            </div>
          ) : null} */}

          <div className="account-info-section account-info-section-row">
            {/* <Button text={'Actualizar'} textColor="#61686B" fontSize="14px" fontWeight="600" /> */}
            <ButtonNext
              className="btn-primary btn-height-40"
              dispatch={handlerOnUpdate}
              title={t('update')}
            />
            <Button
              text={t('undo')}
              className="mg-left"
              textColor="#61686B"
              fontSize="14px"
              fontWeight="600"
              onClick={onCancel}
            />
            <Button
              text={t('removeAccount')}
              className="mg-left"
              textColor="#61686B"
              fontSize="14px"
              fontWeight="600"
              onClick={removeAccount}
            />
          </div>
        </form>
      )}
    </div>
  );
}
