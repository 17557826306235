import { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse/lib/Collapse';
import { useDispatch } from 'react-redux';
import {
  useSearchNominatimMutation,
  useSearchNoraMutation,
  useSearchPoisMutation,
  useSearchStopsMutation
} from '../../../../../../redux/login/search/SearchServiceApi';
import { searchSlice } from '../../../../../../redux/slices/searchSlice';
import { VM } from '../../../../../../viewmodel/VM';
import ButtonIcon from '../../../../../components/commons/ButtonIcon';
import Input from '../../../../../components/commons/Input';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import useDebounce, { useDebounceTerm } from '../../searcherOption/searcherModal/useDebounce';

export default function PlannerSynoptic(props, ref) {
  const theme = useTheme();
  const language = useLanguage();
  const responsive = useResponsive();
  const t = useTranslate();

  const [searchNora] = useSearchNoraMutation();
  const [searchPois] = useSearchPoisMutation();
  const [searchStops] = useSearchStopsMutation();
  const [searchOthers] = useSearchNominatimMutation();

  const dispatch = useDispatch();
  const { debounce } = useDebounce();

  const [extraStop, setExtraStop] = useState(VM.plannerSegments.data.length);
  let inputRef = useRef({});

  useEffect(() => {
    VM.plannerSegments.listen(() => setInputs());
    setInputs();
  }, []);

  const styles = {
    circle: {
      backgroundColor: VM.plannerSegments.data['origin']?.location
        ? '#8FA8FF'
        : theme.colors.mediumGray,
      width: 18,
      height: 18,
      maxHeight: 18,
      marginRight: 5,
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    circleD: {
      backgroundColor: VM.plannerSegments.data['destination']?.location ? '#8FA8FF' : '#FF8883'
    },
    circle2: {
      backgroundColor: VM.plannerSegments.data['origin']?.location ? '#214BE1' : theme.colors.black,
      borderColor: 'white',
      color: theme.colors.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid white',
      width: 7,
      height: 7,
      borderRadius: 10
    },
    circle2D: {
      backgroundColor: VM.plannerSegments.data['destination']?.location ? '#214BE1' : '#A31610'
    },
    line: {
      border: 'none',
      borderLeft: `2px dotted ${theme.colors.mediumGray}`,
      color: '#fff',
      backgroundColor: '#fff',
      height: '100%',
      width: 1,
      marginRight: 4
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    input: {
      margin: 5
    }
  };

  const debouncedSearch = debounce(async (text) => {
    props.setIsSearching1(true);
    props.setIsSearching2(true);
    props.setIsSearching3(true);
    props.setIsSearching4(true);
    /* await searchNora({ locale: language, txt: text }).then((data) =>{
      props.setIsSearching3(false)
      dispatch(searchSlice.actions.updateDirections(data.data))
    }); */
    await searchStops({ locale: language, txt: text })
      .then((data) => {
        dispatch(
          searchSlice.actions.updateStops(
            data.data?.map((stop) => ({
              ...stop,
              ...{ name: stop?.stopMunicipality ? `${stop?.name} (${stop?.stopMunicipality})` : stop?.name }
            }))
          )
        );
      })
      .finally(() => props.setIsSearching1(false));
    /* await searchPois({ locale: language, txt: text }).then((data) =>{
      props.setIsSearching2(false)
      dispatch(searchSlice.actions.updatePois(data.data))
    }); */
    await searchOthers({ locale: language, txt: text })
      .then((data) => {
        dispatch(searchSlice.actions.updateOthers(data.data));
      })
      .finally(() => props.setIsSearching4(false));
  }, 1500);

  const search = (e) => {
    if (e.length) {
      debouncedSearch(e);
    } else {
      //props.setInputChange(e);
      dispatch(searchSlice.actions.updateDirections([]));
      dispatch(searchSlice.actions.updateStops([]));
      dispatch(searchSlice.actions.updatePois([]));
      dispatch(searchSlice.actions.updateOthers([]));
    }
  };

  const debouncedSearchTerm = useDebounceTerm(props.inputChange, 500);

  useEffect(() => {
    search(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const swap = () => {
    let origin = inputRef.current['origin'].getValue();
    let destination = inputRef.current['destination'].getValue();
    inputRef.current['origin'].setValue(destination);
    inputRef.current['destination'].setValue(origin);
    props.setIsFocused(false);
    VM.plannerSegments.swap();
  };

  const setInputs = () => {
    for (let segment in VM.plannerSegments.data) {
      inputRef.current[segment]?.setValue(
        VM.plannerSegments?.data[segment]?.alias ?? VM.plannerSegments?.data[segment]?.name
      );
    }
  };

  const renderInputs = () => {
    let res = [];
    for (let i = 0; i < extraStop; i++)
      res.push(
        <div key={'divInputCoords_' + i} style={{ ...styles.row, alignItems: 'center' }}>
          <Input
            key={'inputCoords_' + i}
            id={i}
            ref={(ref) => (inputRef.current[i] = ref)}
            style={styles.input}
            background={theme.colors.border}
            onChange={(e) => {
              //search(e);
              props.setInputChange(e);
            }}
            onClear={() => {
              VM.plannerSegments.set(i, undefined, true);
              props.setIsFocused(false);
              VM.plannerTime.deleteTimes(i);
              inputRef.current[i].setValue('');
            }}
            onFocus={(e) => {
              props.setInputSelected(inputRef.current[i]);
              props.setIsFocused(true);
              /* if(props?.inputChange!==e)
                search(e); */
            }}
            placeholder={t('searchADirection')}
          />
          <ButtonIcon
            iconColor={theme.colors.black}
            background={theme.colors.border}
            icon={R.drawables.general.Ic_close}
            onClick={() => {
              inputRef.current[i].setValue('');
              props.setIsFocused(false);
              VM.plannerTime.deleteTimes(i);
              VM.plannerSegments.delete(i);
              setExtraStop(extraStop - 1);
            }}
          />
        </div>
      );
    return res;
  };

  const renderPoints = () => {
    let res = [];
    for (let i = 0; i < extraStop; i++) {
      res.push(
        <div key={'line_' + i} style={{ ...styles.line, flex: 1, flexDirection: 'column' }} />
      );
      res.push(
        <div key={'circle' + i} style={{ ...styles.circle, backgroundColor: '#A31610' }}>
          <div style={{ color: theme.colors.white }}>{i + 1}</div>
        </div>
      );
    }
    return res;
  };

  const renderStop = () => {
    return (
      <div style={styles.row}>
        {!responsive.isBig ? (
          <ButtonIcon
            style={{ margin: 7, marginRight: '10px' }}
            icon={R.drawables.commons.bar}
            size={'37.5px'}
            iconSize={'15px'}
            onClick={() => props.setOpen(true)}
            background={theme.colors.mediumDark}
          />
        ) : null}
        <div style={styles.column}>
          <div style={{ flex: 0.5 }} />
          <div style={{ ...styles.circle, flex: 2 }}>
            <div style={styles.circle2} />
          </div>
          {renderPoints()}
          <div style={{ ...styles.line, flex: 1 }} />
          <div style={{ ...styles.circle, ...styles.circleD }}>
            <div style={{ ...styles.circle2, ...styles.circle2D }} />
          </div>
          <div style={{ flex: 0.5 }} />
        </div>
        <div style={{ width: '100%' }}>
          <div style={{ ...styles.row, alignItems: 'center' }}>
            <Input
              ref={(ref) => (inputRef.current['origin'] = ref)}
              id={'origin'}
              background={theme.colors.border}
              style={styles.input}
              onChange={(e) => {
                //search(e);
                props.setInputChange(e);
              }}
              onClear={() => {
                VM.plannerSegments.delete('origin');
                props.setIsFocused(false);
              }}
              onFocus={(e) => {
                props.setInputSelected(inputRef.current['origin']);
                props.setIsFocused(true);
                // if(props?.inputChange!==e)
                //  search(e);
              }}
              placeholder={t('origin')}
            />
            <ButtonIcon
              iconColor={theme.colors.black}
              background={theme.colors.border}
              icon={R.drawables.general.Ic_sentido}
              onClick={() => swap()}
            />
          </div>
          <Collapse isOpened={true}>{renderInputs()}</Collapse>
          <div style={{ ...styles.row, alignItems: 'center' }}>
            <Input
              ref={(ref) => (inputRef.current['destination'] = ref)}
              id={'destination'}
              style={styles.input}
              background={theme.colors.border}
              onChange={(e) => {
                //search(e);
                props.setInputChange(e);
              }}
              onClear={() => {
                VM.plannerSegments.delete('destination');
                props.setIsFocused(false);
              }}
              onFocus={(e) => {
                props.setInputSelected(inputRef.current['destination']);
                props.setIsFocused(true);
                // if(props?.inputChange!==e)
                //  search(e);
              }}
              placeholder={t('destination')}
            />
            <ButtonIcon
              iconColor={extraStop >= 3 ? theme.colors.neutral : theme.colors.black}
              background={theme.colors.border}
              icon={R.drawables.general.Ic_add}
              disabled={extraStop >= 3}
              onClick={() => {
                VM.plannerSegments.set(extraStop);
                props.setIsFocused(false);
                setExtraStop(extraStop + 1);
                props.setThereIntermediates(false);
                VM.planner.clear();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return <div style={{ ...styles.content, ...props.style }}>{renderStop()}</div>;
}
