import { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse/lib/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { favoritesInformation } from '../../../../../../redux/slices/favoritesSlice';
import RouteUtils from '../../../../../../utils/RouteUtils';
import { VM } from '../../../../../../viewmodel/VM';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import RouteCard from './routeCard/RouteCard';
import RouteFilter from './routeFilter/RouteFilter';
import { agencyInformation } from '../../../../../../redux/slices/agencySlice';

export default function RouteResults(props, ref) {
  const theme = useTheme();
  const responsive = useResponsive();
  const t = useTranslate();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(0);
  const [opened, setOpened] = useState(false);
  const [filter, setFilter] = useState(0);
  const [mainCardText, setMainCardText] = useState(t('fastest_route'));
  const favorites = useSelector(favoritesInformation);
  const language = useLanguage();
  const [renderCards, setRenderCards] = useState();
  const allStops = VM.stops.noMap;
  const dataOrigin = useSelector(agencyInformation).dataOrigin;

  useEffect(() => {
    async function fetchData() {
      setRenderCards(await getRenderCards());
    }
    fetchData();
  }, [props.cards, selected, filter, mainCardText]);

  useEffect(() => {
    if (props.cards) {
      if (RouteUtils.getNoWalkItineraries(props.cards)?.plan?.itineraries?.length === 0 &&
      RouteUtils.getWalkItineraries(props.cards)?.plan?.itineraries?.length) {
        setFilter(2)
        setSelected(2)
      } else {
        setFilter(0)
        setSelected(0);
      }
    }
  }, [props.cards]);

  //Reseteo del seleccionado al cambiar filtro
  useEffect(() => {
    async function fetchData() {
      let cards = props.cards;
      switch (filter) {
        case 0:
          cards = RouteUtils.getNoWalkItineraries(cards);
          break;
        case 1:
          cards = RouteUtils.getFavoriteItineraries(cards, favorites);
          break;
        case 2:
          cards = RouteUtils.getWalkItineraries(cards);
          break;
        default:
          break;
      }

      let routesInfo = await RouteUtils.getRoutesInfo(cards, allStops, dataOrigin);
      VM.planner.setSelected(parseInt(routesInfo.suggested[0]?.id));
    }
    fetchData();
  }, [filter]);

  useEffect(() => {
    VM.planner.listen(() => setSelected(VM.planner.selected));
    VM.plannerConfig.listen((data) => {
      if (data.order === 'fare') {
        setMainCardText(t('eco_route'));
      } else if (data.order === 'walkTimeTrunc') {
        setMainCardText(t('walk_route'));
      } else {
        setMainCardText(t('fastest_route'));
      } 
    });
    setOpened(true);
  }, [language]);

  useEffect(() => {
    if (VM.plannerConfig.data.order === 'fare') {
      setMainCardText(t('eco_route'));
    } else if (VM.plannerConfig.data.order === 'walkTimeTrunc') {
      setMainCardText(t('walk_route'));
    } else {
      setMainCardText(t('fastest_route'));
    }
  }, [language]);

  const styles = {
    content: {
      position: 'relative',
      marginLeft: !responsive.isBig ? '25px' : '105px',
      marginTop: '10px',
      borderRadius: '12px',
      background: theme.colors.border,
      boxShadow: '0px 4px 4px rgba(28, 31, 32, 0.1)',
      textAlign: 'left'
    },
    bar: {
      paddingInline: '12px',
      maxWidth: '345px',
      minWidth: '232px',
      background: theme.colors.white,
      borderRadius: '10px 10px 0px 0px',
      paddingTop: 10
    },
    results: {
      padding: '12px',
      maxWidth: '345px',
      minWidth: '232px',
      maxHeight: '50vh',
      borderRadius: 12,
      paddingBottom: 10,
      paddingTop: 10,
      overflow: 'auto',
      background: theme.colors.border
    },
    notFound: {
      fontSize: 13,
      fontWeight: 600,
      color: theme.colors.mainText
    },
    subNotFound: {
      maxWidth: '345px',
      minWidth: '232px',
      width: '80vw',
      color: theme.colors.textSecondary,
      fontSize: 13,
      marginTop: 10
    }
  };

  const getRenderCards = async () => {
    let res = [];
    let cards = props.cards;
    switch (filter) {
      case 0:
        cards = RouteUtils.getNoWalkItineraries(cards);
        break;
      case 1:
        cards = RouteUtils.getFavoriteItineraries(cards);
        break;
      case 2:
        cards = RouteUtils.getWalkItineraries(cards);
        break;
      default:
        break;
    }
    if (!cards.plan?.itineraries || cards.plan?.itineraries.length === 0) {
      res.push(
        <div key={'not_found'}>
          <div style={styles.notFound}>{t('notFound')}</div>
          <div style={styles.subNotFound}>{t('filterNoResult')}</div>
        </div>
      );
      return res;
    }
    let routesInfo = await RouteUtils.getRoutesInfo(cards, allStops, dataOrigin);
    if (routesInfo.suggested)
      res.push(
        <RouteCard
          selected={selected === routesInfo.suggested[0].id}
          key={'route_card_f'}
          onClick={() => {
            if (selected === routesInfo.suggested[0].id)
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityRouteDetailModalWidget({
                  showModal: true,
                  plan: routesInfo.suggested[0]
                })
              );
            else VM.planner.setSelected(routesInfo.suggested[0].id);
          }}
          title={mainCardText}
          info={routesInfo.suggested[0]}
        />
      );

    for (let card in routesInfo.otherSuggestions) {
      res.push(
        <RouteCard
          selected={selected === routesInfo.otherSuggestions[card].id}
          key={'route_card' + card}
          onClick={() => {
            if (selected === routesInfo.otherSuggestions[card].id)
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityRouteDetailModalWidget({
                  showModal: true,
                  plan: routesInfo.otherSuggestions[parseInt(card)]
                })
              );
            else VM.planner.setSelected(parseInt(routesInfo.otherSuggestions[card]?.id));
          }}
          info={routesInfo.otherSuggestions[card]}
          style={{ marginTop: 10 }}
        />
      );
    }
    return res;
  };

  return (
    <div style={props.style}>
      <div style={styles.content}>
        <div style={styles.bar}>
          <RouteFilter
            filter={filter}
            onChange={(filter) => setFilter(filter)}
          />
        </div>
        <div style={styles.results}>
          <Collapse isOpened={opened}>{renderCards}</Collapse>
        </div>
      </div>
    </div>
  );
}
