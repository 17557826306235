import React, {useEffect, useRef, useState} from "react";
import {useTheme} from "../../../../context/themeContext";
import {useTranslate} from "../../../../context/languageContext";
import ButtonIcon from "../../../../components/commons/ButtonIcon";
import {R} from "../../../../R";
import Button from "../../../../components/commons/Button";
import {eventsModalstoggleVisibilitySlice} from "../../../../../redux/slices/eventsModalstoggleVisibilitySlice";
import {useDispatch, useSelector} from "react-redux";
import {favoritesInformation, favoritesSlice} from "../../../../../redux/slices/favoritesSlice";
import DefaultModal from "../../../../components/widget/Modals/DefaultModal";
import {useSetModalChild, useSetModalOpened} from "../../../../context/modalContext";
import {accountInformation} from "../../../../../redux/slices/authSlice";
import {VM} from "../../../../../viewmodel/VM";
import RouteUtils from "../../../../../utils/RouteUtils";

export default function MyDirections(props) {
    const theme = useTheme();
    const t = useTranslate();
    const dispatch = useDispatch()

    const [isBack, setIsBack] = useState(false)

    const setChild = useSetModalChild()
    const setOpen = useSetModalOpened()
    const account = useSelector(accountInformation);

    const favorites = useSelector(favoritesInformation)

    const scroll = useRef(null);
    let pressedScroll = null;

    const styles={

        content: {
            display:'flex',
            alignContent:'center',
            justifyContent:'center',
            alignItems:'center',
            marginTop:'8px'
        },
        tags: {
            marginRight: '6px',
            fontWeight:700,
        },
        plus: {
            marginLeft: 'auto',
        },
        text: {
            fontSize:'14px',
            fontWeight:600,
            color:theme.colors.black,
        },
        directions:{
            display:'flex',
            maxWidth:300,
            overflowY:'hidden',
            overflowX:'scroll'
        }
    }

    const onClick = () => {
        clearInterval(pressedScroll)
        if(scroll.current.offsetWidth + scroll.current.scrollLeft+1 >= scroll.current.scrollWidth)
            setIsBack(true)
        else if(scroll.current.scrollLeft===0)
            setIsBack(false)
        else setIsBack(null)
    }

    const moveLeft = () => {
        pressedScroll = setInterval(() => {
            if(scroll.current.scrollLeft===0) {
                clearInterval(pressedScroll)
                setIsBack(false)

            }
            scroll.current.scrollTo(scroll.current.scrollLeft - 5, 0)
        }, 40)
    }

    const moveRight = () => {
        pressedScroll = setInterval(() => {
            if(!(scroll.current?.offsetWidth + scroll.current?.scrollLeft+1 <= scroll.current?.scrollWidth)) {
                clearInterval(pressedScroll)
                setIsBack(true)
            }
            scroll.current.scrollTo(scroll.current?.scrollLeft + 5, 0)
        }, 40)
    }

    const addDirection = (id) =>{
        if(account.idUserType===1) {
            setOpen(true)
            setChild(<DefaultModal title={t('loginToFav')}
                                   description={t('loginToFavBody')}
                                   alter={true}
                                   firstButton={t('createAccount')}
                                   secondButton={t('login')}
                                   onClickFirst={() => {dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(true))}}
                                   onClickSecond={() => {dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true))}}
            />)
        }
        else {
            dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAddDirectionModalWidget({
                showModal: true,
                direction: {},
                type: id,
                edit: false
            }))
        }
    }

    const renderDirection = () => {
        let res = []
        let home;
        let work;
        for(let favorite in favorites.directions){
            if(favorites.directions[favorite].typeId==2)
                home = favorites.directions[favorite]
            else if(favorites.directions[favorite].typeId==3)
                work = favorites.directions[favorite]
            else res.push(<Button
                key={favorite}
                style={styles.tags}
                text={favorites.directions[favorite].alias}
                icon={R.drawables.general.Ic_location}
                iconColor={theme.colors.black}
                textColor={theme.colors.black}
                background={theme.colors.white}
                onClick={()=> {
                    if(Object.keys(VM.plannerSegments.data).length!==0) {
                        let copy = JSON.parse(JSON.stringify(favorites.directions[favorite]))
                        let coords = { latitude: copy.latitude, longitude: copy.longitude };
                        let name = copy?.alias ?? copy?.name;
                        let info = RouteUtils.getTypeId(copy, favorites);
                        let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };
                        VM.plannerSegments.add(res)
                    }
                    else props.onClickMarker(favorites.directions[favorite])
                }}
                size={'30px'}
                iconSize={'15px'}/>)
        }
        res.unshift(<Button
            key={'work'}
            style={styles.tags}
            text={work ? t('work') : t('addWork')}
            icon={R.drawables.general.Ic_WorkSmall}
            iconColor={work ? theme.colors.black : theme.colors.darkGray}
            textColor={work ? theme.colors.black : theme.colors.darkGray}
            background={work ? theme.colors.white : theme.colors.background}
            onClick={()=>{
                if(work){
                    if(Object.keys(VM.plannerSegments.data).length!==0) {
                        let copy = JSON.parse(JSON.stringify(work))
                        let coords = { latitude: copy.latitude, longitude: copy.longitude };
                        let name = copy?.alias ?? copy?.name;
                        let info = RouteUtils.getTypeId(copy, favorites);
                        let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };
                        VM.plannerSegments.add(res)
                    }
                    else props.onClickMarker(work)
                }
                else {
                    addDirection(3)
                }
            }}
            size={'30px'}
            iconSize={'15px'}/>)
        res.unshift(<Button
            key={'home'}
            style={styles.tags}
            text={home ? t('home') : t('addHome') }
            icon={R.drawables.general.Ic_home}
            iconColor={home ? theme.colors.black : theme.colors.darkGray}
            textColor={home ? theme.colors.black : theme.colors.darkGray}
            background={home ? theme.colors.white : theme.colors.background}
            onClick={()=>{
                if(home) {
                    if(Object.keys(VM.plannerSegments.data).length!==0) {
                        let copy = JSON.parse(JSON.stringify(home))
                        let coords = { latitude: copy.latitude, longitude: copy.longitude };
                        let name = copy?.alias ?? copy?.name;
                        let info = RouteUtils.getTypeId(copy, favorites);
                        let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };
                        VM.plannerSegments.add(res)
                    }
                    else props.onClickMarker(home)
                }
                else {
                    addDirection(2)
                }
            }}
            size={'30px'}
            iconSize={'15px'}/>)
        return res
    }

    const getLengthDir = () => {
        let res = 0
        let home=0;
        let work=0;
        for(let favorite in favorites.directions){
            if(favorites.directions[favorite].typeId==2)
                home = 1
            else if(favorites.directions[favorite].typeId==3)
                work = 1
            else res++
        }

        return res+2
    }

    return (
        <div style={props.style}>
            <div style={styles.text}>{t('myDirections')}</div>
            <div style={styles.content}>
                {getLengthDir()>2
                    ?< ButtonIcon
                        style={{marginRight:'10px'}}
                        disabled={isBack===false}
                        icon={R.drawables.commons.leftArrow}
                        iconColor={theme.colors.black}
                        borderColor={theme.colors.border}
                        onMouseDown={moveLeft}
                        onMouseLeave={onClick}
                        onClick={onClick}
                        size={'30px'}
                        iconSize={'13px'}/>
                    :null
                }
                <div ref={scroll} style={styles.directions}>
                    {renderDirection()}
                </div>
                {getLengthDir()>2
                    ?< ButtonIcon
                        style={{marginLeft:'10px', marginRight:10}}
                        disabled={isBack===true}
                        icon={R.drawables.commons.rightArrow}
                        iconColor={theme.colors.black}
                        borderColor={theme.colors.border}
                        onMouseDown={moveRight}
                        onMouseLeave={onClick}
                        onClick={onClick}
                        size={'30px'}
                        iconSize={'13px'}/>
                    :null
                }
                <ButtonIcon
                    onClick={()=> {
                        addDirection(1)
                    }}
                    disabled={account.idUserType===1}
                    style={styles.plus}
                    size={'30px'}
                    iconColor={theme.colors.mediumGray}
                    icon={R.drawables.general.Ic_add}/>
            </div>

        </div>
    );
}
