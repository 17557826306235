import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  usePostLineReportMutation,
  usePostPlanningReportMutation,
  usePostStopReportMutation
} from '../../../redux/login/services/MessagesServiceApi';
import { eventsModalstoggleVisibilitySlice } from '../../../redux/slices/eventsModalstoggleVisibilitySlice';
import SimpleModal from '../../components/commons/Modals/SimpleModal';
import TitleH1 from '../../components/commons/Titles/TitleH1';
import { useTranslate } from '../../context/languageContext';
import { useResponsive } from '../../context/responsiveContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';
import AddFileSelector from '../accountscreen/support/components/AddFileSelector';
import FileEntrySending from '../accountscreen/support/components/FileEntrySending';
import { useDownloadFiles } from '../../../utils/FileUtils';

export default function ReportModalWidget(props: any) {
  const responsive = useResponsive();
  const theme = useTheme();
  const t = useTranslate();
  const Close = R.drawables.commons.close;
  const [comment, setComment] = useState('');
  const [title, setTitle] = useState('');
  const [selected, setSelected] = useState(false);
  const [PostStopReport] = usePostStopReportMutation();
  const [PostPlanningReport] = usePostPlanningReportMutation();
  const [PostLineReport] = usePostLineReportMutation();
  const [fileType, setFileType] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileInputData, setFileInputData] = useState<any>();
  const [fileUploadData, setFileUploadData] = useState<any>();
  const { uploadFile } = useDownloadFiles();

  const [countSymbolsComment, setCountSymbolsComment] = useState(0);
  const [countSymbolsTitle, setCountSymbolsTitle] = useState(0);
  const dispatch = useDispatch();
  const element = useSelector(
    (state: any) => state.eventsModalstoggleVisibilitySlice.ModalWidget.ReportModalWidget.element
  );
  const type = useSelector(
      (state: any) => state.eventsModalstoggleVisibilitySlice.ModalWidget.ReportModalWidget.type
  );

  const [suggestion, setSuggestion] = useState({
    12: false,
    13: false,
    14: false,
    15: false,
    16: false,
    17: false
  });

  const onChangeCheck = (e: any) => {
    const { name, checked } = e.target;
    setSelected(false);
    setSuggestion({
      12: false,
      13: false,
      14: false,
      15: false,
      16: false,
      17: false
    });
    setSuggestion((state) => ({
      ...state,
      [name]: checked
    }));
    setSelected(checked);
  };

  const onChangeComment = (e: any) => {
    setComment(e.target.value);
    setCountSymbolsComment(e.target.value.length);
  };

  const onChangeTitle = (e: any) => {
    setTitle(e.target.value);
    setCountSymbolsTitle(e.target.value.length);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    let categoryId = '';
    Object.entries(suggestion).forEach((el) => {
      if (el[1] === true) {
        return (categoryId = el[0]);
      }
    });
    const locale = localStorage.getItem('language');
    if (categoryId !== '') {

      let fileId: any = null;
      if (fileUploadData) {
        fileId = await uploadFile(fileUploadData);
        if (fileId?.data) {
          fileId = fileId?.data;
        }
      }

      if(type === 'stop')
        PostStopReport({ idStop: element, categoryId: categoryId, body: comment, locale: locale, title: title, fileId: fileId })
          .unwrap()
          .then((payload) => {
            onCancel();
            closeModal();
          })
          .catch((error) => console.error('rejected', error));

      else if(type === 'line')
        PostLineReport({ categoryId: categoryId, body: comment, locale: locale, idLine: element, title:title, fileId: fileId })
            .unwrap()
            .then((payload) => {
              onCancel();
              closeModal();
            })
            .catch((error) => console.error('rejected', error));
      else if(type == 'plan')
        PostPlanningReport({
          categoryId: categoryId,
          body: comment,
          locale: locale,
          idPlanning: element,
          title: title,
          fileId: fileId
        })
            .unwrap()
            .then((payload) => {
              onCancel();
              closeModal();
            })
            .catch((error) => console.error('rejected', error));
    }
  };

  const onCancel = () => {
    setSelected(false);
    setComment('');
    setSuggestion({
      12: false,
      13: false,
      14: false,
      15: false,
      16: false,
      17: false
    });
  };

  const closeModal = () => {
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityReportModalWidget({
        showModal: false
      })
    );
  };

  const styles = {
    container: {
      backgroundColor: '#fff',
      alignItems: 'flex-start',
      maxWidth: 400,
      width: '100%',
      borderRadius: '16px'
    },
    close: {
      position: 'absolute',
      right: '4%',
      top: '4%',
      cursor: 'pointer',
      height: 10.65,
      width: 10.71
    },
    header: {
      color: theme.colors.neutralBlack,
      width: '100%',
      paddingTop: 40,
      padding: responsive.isBig ? '40px 24px 0px 24px' : '20px 24px 0px 24px'
    },
    title: {
      width: '100%',
      margin: 0,
      fontSize: 22,
      fontWeight: '700',
      textAlign: 'left'
    },
    body: {
      margin: 24,
      width: '100%',
      background: 'blue',
      textAlign: 'left'
    },
    form: {
      display: 'flex',

      flexDirection: 'column',
      width: '100%'
    },
    footer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 24,
      margin: '0px 24px 0px 24px'
    },
    overlay: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10,
      backgroundColor: '#181B1D',
      opacity: 0.2
    },
    option: {
      padding: responsive.isBig ? '16px 0px 19px 0px' : '8px 0px 9px 0px'
    }
  } as const;

  return (
    <>
      <div style={styles.overlay}></div>
      <SimpleModal
        style={styles.container}
        header={
          <div style={styles.header}>
            <Close style={styles.close} fill={theme.colors.neutralBlack} onClick={closeModal} />
            <TitleH1 style={styles.title} title={t('reportIncidence')} />
          </div>
        }
        body={
          <form onSubmit={onSubmit}>
            <div className="support-container-stop-line">
              <p className="support-notice-stop-line">
                {
                  type === "stop"
                      ? t('reportStopNotice')
                      :type==="line"
                        ? t('reportLineNotice')
                        : type==="plan"
                              ? t('reportPlanningNotice')
                              : null
                }
              </p>
              {/* RADIO INPUTS */}

              <label className="support-widget" style={styles.option}>
                {t('stopIncidence')}
                <input type="checkbox" onChange={onChangeCheck} name="12" checked={suggestion[12]} />
                <span></span>
              </label>

              <label className="support-widget" style={styles.option}>
                {t('lineIncidence')}
                <input type="checkbox" onChange={onChangeCheck} name="13" checked={suggestion[13]} />
                <span></span>
              </label>

              <label className="support-widget" style={styles.option}>
                {t('planIncidence')}
                <input type="checkbox" onChange={onChangeCheck} name="14" checked={suggestion[14]} />
                <span></span>
              </label>

              <label className="support-widget" style={styles.option}>
                {t('operatorIncidence')}
                <input type="checkbox" onChange={onChangeCheck} name="15" checked={suggestion[15]} />
                <span></span>
              </label>

              <label className="support-widget" style={styles.option}>
                {t('systemIncidence')}
                <input type="checkbox" onChange={onChangeCheck} name="16" checked={suggestion[16]} />
                <span></span>
              </label>

              <label className="support-widget" style={styles.option}>
                {t('other')}
                <input type="checkbox" onChange={onChangeCheck} name="17" checked={suggestion[17]} />
                <span></span>
              </label>

              <div className="support-title-container">
                <label className="support-title">
                  <textarea
                      maxLength={75}
                      onChange={onChangeTitle}
                      name="title"
                      value={title}
                      placeholder={t('leaveTitle')}
                      required={true}
                  />
                  <span>{countSymbolsTitle}/75</span>
                </label>
              </div>

              <div className="support-comment-container">
                <label className="support-comment">
                  <textarea
                    maxLength={2048}
                    onChange={onChangeComment}
                    name="comment"
                    value={comment}
                    placeholder={t('leaveComment')}
                    required={true}
                  />
                  <span>{countSymbolsComment}/2048</span>
                </label>
              </div>

              <div className="support-title-container" style={{ marginBottom: 8 }}>
            <label className="label" style={{ fontSize: 14, marginBottom: 8 }}>
              {fileInputData ? t('modify_file') : t('add_file')}
            </label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                rowGap: 4
              }}>
              <AddFileSelector
                setTypeFile={setFileType}
                setFileData={setFileName}
                localeInputData={fileInputData}
                setLocaleInputData={setFileInputData}
                toUploadFile={setFileUploadData}
                style={{ padding: 8 }}
              />
              {fileInputData ? (
                <FileEntrySending
                  fileName={fileName}
                  fileType={fileType}
                  localeBlob={fileInputData}
                  setFileData={setFileName}
                  setTypeFile={setFileType}
                  setLocaleInputData={(value: any) => {
                    setFileInputData(value);
                    setFileUploadData(value);
                  }}
                />
              ) : null}
            </div>
          </div>

              <button className="btn-primary btn-wide" disabled={!selected}>
                {t('confirm')}
              </button>
            </div>
          </form>
        }
      />
    </>
  );
}
