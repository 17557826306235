import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import GeneralUtils from '../../../utils/GeneralUtils';
import { RootState } from '../../store';

export const agencyServiceApi = createApi({
  reducerPath: 'agencyServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GeneralUtils.getEnvironment(window.location.origin),
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).authSlice.accountInformation.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    }
  }),
  endpoints: builder => ({
    getAgencies: builder.mutation<any, any>({
      query: ({locale}) => ({
        url: `/agency/?locale=${locale}`,
        method: 'GET',
      }),
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {
        }
      },
    }),
    GetAgencies: builder.query<any, any>({
      query: ({locale}) => `/agency/?locale=${locale}`
    }),
    getAgencyById: builder.mutation<any, any>({
      query: ({id, locale}) => ({
        url: `/agency/${id}?locale=${locale}`,
        method: 'GET',
      }),
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {
        }
      },
    }),
    getDataOrigin: builder.mutation<any, any>({
      query: ({locale}) => ({
        url: `agency/dataorigin/?locale=${locale}`,
        method: 'GET',
      }),
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {
        }
      },
    }),
    GetDataOrigin: builder.query<any, void>({
      query: () => 'agency/dataorigin'
    }),
    getDataOriginByAgencyId: builder.mutation<any, any>({
      query: ({id}) => ({
        url: `agency/${id}/dataorigin/`,
        method: 'GET',
      }),
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {
        }
      },
    }),
    getTransportModes: builder.mutation<any, any>({
      query: ({locale}) => ({
        url: `/transportmode?locale=${locale}`,
        method: 'GET',
      }),
      async onQueryStarted(id, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (err) {
          
        }
      },
    }),
  }),
});

export const {
  useGetAgenciesMutation,
  useGetAgencyByIdMutation,
  useGetDataOriginByAgencyIdMutation,
  useGetDataOriginMutation,
  useGetTransportModesMutation,
  useLazyGetAgenciesQuery,
  useLazyGetDataOriginQuery,
} = agencyServiceApi;