import { CSSProperties, useState } from 'react';
import { useResponsive } from '../../context/responsiveContext';
import { useTheme } from '../../context/themeContext';

interface ElementBarProps {
  icon: any;
  mouseOver: boolean;
  selected: string;
  id: string;
  textVisible: boolean;
  /**
   * @param showNoti indica la posibilidad de mostrar un icono rojo al lado del icono principal,
   * para de esta manera indicar que ha llegado una notificación 
   * */ 
  showNoti?: boolean;
  style?: CSSProperties | undefined;
  onClick: Function;
  text: string;
}

export default function ElementBar(props: ElementBarProps) {
  const theme = useTheme();
  const Icon = props.icon;
  const [isOver, setIsOver] = useState(false);
  const responsive = useResponsive();
  const styles: any = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: !responsive.isBig || props.mouseOver ? 'left' : 'center',
      height: '7%',
      width: '100%',
      color: 'white',
      borderRadius: '24px',
      marginTop: '6px',
      transition: 'all 300ms linear',
      cursor: 'pointer',
      backgroundColor: isOver || props.selected === props.id ? theme.colors.mediumDark : null
    },

    textBar: {
      fontWeight: 600,
      transition: 'all 100ms linear',
      color: isOver || props.selected === props.id ? theme.colors.white : theme.colors.lightShort,
      fontSize: !responsive.isBig || (props.mouseOver && props.textVisible) ? 12 : 0,
      marginLeft: props.showNoti ? 2 : 0,
    },

        img: {
            width:'15px',
            height:'15px',
            transition: "all 300ms linear",
            fill: isOver || props.selected===props.id ? theme.colors.white :theme.colors.lightShort,
            padding:'16px'
        }
    }
    return (
        <div style={props.style}>
            <div style={styles.container}
                 onMouseOver={()=> setIsOver(true)}
                 onMouseLeave={()=>setIsOver(false)}
                 onClick={()=>props.onClick()}>
                <Icon style={styles.img}/>
                {props.showNoti ? <div style={{backgroundColor: 'red', width: 8, height: 8, borderRadius: 4, marginLeft: -14, marginTop: -16}}/> : null}
                <h1 style={styles.textBar}>{props.text}</h1>
            </div>
        </div>
    );
}
