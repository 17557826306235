interface definitionInterface {
  onCheck: Function;
  idKey?: string;
  isChecked?: boolean | number;
}
export default function SwitchComponent({ onCheck, idKey, isChecked }: definitionInterface) {
  return (
    <div className="switch">
      <input
        type="checkbox"
        onClick={(e: any) =>
          onCheck(idKey ? { [idKey]: e.target.checked == true ? 1 : 0 } : e.target.checked)
        }
        defaultChecked={isChecked ?? isChecked === 1 ? true : isChecked === 0 ? false : isChecked}
      />
    </div>
  );
}
