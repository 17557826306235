import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface httpStatusCodes {
  payload: { code: any; boolean: boolean };
}
export interface modalWidget {
  AddDirectionModalWidget: any;
  LoginModalWidget: boolean;
  PersonalInformationModalWidget: boolean;
  RecoveryPassModalWidget: boolean;
  RemoveAccountModalWidget: boolean;
  SingUpModalWidget: boolean;
  AlertToSaveModalWidget: boolean;
  SuccessModalWidget: boolean;
  RecoveryEmailSentModalWidget: boolean;
  AccessDeniedModalWidget: boolean;
  ServerNotAvailableModalWidget: boolean;
  ChangePassModalWidget: boolean;
  LoadingWidget: boolean;
  AnonymousAccessDeniedModalWidget: boolean;

  ReportModalWidget : any;

  RouteDetailModalWidget: any;
  CopiedModalWidget: boolean;
  AlertRemovedModalWidget: boolean;
  ShareModalWidget: any;
  CustomizedSnackbarModalWidget: any;

  // ReportRouteModalWidget: boolean;
}

interface ApplicationState {
  ModalWidget: modalWidget;
}

// Define the initial state using that type
const initialState: ApplicationState = {
  ModalWidget: {
    AddDirectionModalWidget: { showModal: false, direction: {}, type: 1, edit: false },
    LoginModalWidget: false,
    PersonalInformationModalWidget: false,
    RecoveryPassModalWidget: false,
    RemoveAccountModalWidget: false,
    SingUpModalWidget: false,
    AlertToSaveModalWidget: false,
    SuccessModalWidget: false,
    RecoveryEmailSentModalWidget: false,
    AccessDeniedModalWidget: false,
    ServerNotAvailableModalWidget: false,
    ChangePassModalWidget: false,
    LoadingWidget: false,
    AnonymousAccessDeniedModalWidget: false,

    ReportModalWidget: { showModal: false, element: {}, type:null },

    RouteDetailModalWidget: { showModal: false, plan: {} },
    CopiedModalWidget: false,
    AlertRemovedModalWidget: false,
    ShareModalWidget: { showModal: false, shareLink: '', title: '' },
    CustomizedSnackbarModalWidget: {
      showModal: false,
      background: '#00763D',
      text: 'Success',
      status: 'success'
    }
    // ReportRouteModalWidget: false
  }
};

export const eventsModalstoggleVisibilitySlice = createSlice({
  name: 'eventsModalstoggleVisibilitySlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleVisibilityAddDirectionModalWidget: (state, action?) => {
      state.ModalWidget.AddDirectionModalWidget = action.payload;
    },

    toggleVisibilityLoginModalWidget: (state, action?) => {
      state.ModalWidget.LoginModalWidget = action.payload;
    },

    toggleVisibilityPersonalInformationModalWidget: (state, action?) => {
      state.ModalWidget.PersonalInformationModalWidget = action.payload;
    },

    toggleVisibilityRecoveryPassModalWidget: (state, action?) => {
      state.ModalWidget.RecoveryPassModalWidget = action.payload;
    },
    toggleVisibilityRemoveAccountModalWidget: (state, action?) => {
      state.ModalWidget.RemoveAccountModalWidget = action.payload;
    },
    toggleVisibilitySingUpModalWidget: (state, action?) => {
      state.ModalWidget.SingUpModalWidget = action.payload;
    },
    toggleVisibilityChangePassModalWidget: (state, action?) => {
      state.ModalWidget.ChangePassModalWidget = action.payload;
    },
    toggleVisibilityAlertToSaveModalWidget: (state) => {
      state.ModalWidget.AlertToSaveModalWidget = !state.ModalWidget.AlertToSaveModalWidget;
    },
    toggleVisibilitySuccessModalWidget: (state, action?) => {
      state.ModalWidget.SuccessModalWidget = action.payload;
    },
    toggleVisibilityRecoveryEmailSentModalWidget: (state, action?) => {
      state.ModalWidget.RecoveryEmailSentModalWidget = action.payload;
    },
    toggleVisibilityAccessDeniedModalWidget: (state, action?) => {
      state.ModalWidget.AccessDeniedModalWidget = action.payload;
    },
    toggleVisibilityServerNotAvailableModalWidget: (state, action?) => {
      state.ModalWidget.ServerNotAvailableModalWidget = action.payload;
    },
    toggleVisibilityLoadingWidget: (state, action?) => {
      state.ModalWidget.LoadingWidget = action.payload;
    },
    toggleVisibilityAnonymousAccessDeniedModalWidget: (state, action?) => {
      state.ModalWidget.AnonymousAccessDeniedModalWidget = action.payload;
    },
    toggleVisibilityReportModalWidget: (state, action?) => {
      state.ModalWidget.ReportModalWidget.showModal = action.payload.showModal;
      state.ModalWidget.ReportModalWidget.element = action.payload.element;
      state.ModalWidget.ReportModalWidget.type = action.payload.type;
    },
    toggleVisibilityRouteDetailModalWidget: (state, action?) => {
      state.ModalWidget.RouteDetailModalWidget.showModal = action.payload.showModal;
      state.ModalWidget.RouteDetailModalWidget.plan = action.payload.plan;
    },
    toggleVisibilityCustomizedSnackbarModalWidget: (state, action?) => {
      state.ModalWidget.CustomizedSnackbarModalWidget.showModal = action.payload.showModal;
      state.ModalWidget.CustomizedSnackbarModalWidget.background = action.payload.background;
      state.ModalWidget.CustomizedSnackbarModalWidget.text = action.payload.text;
      state.ModalWidget.CustomizedSnackbarModalWidget.status = action.payload.status;
    },
    toggleVisibilityCopiedModalWidget: (state) => {
      state.ModalWidget.CopiedModalWidget = !state.ModalWidget.CopiedModalWidget;
    },
    toggleVisibilityAlertRemovedModalWidget: (state) => {
      state.ModalWidget.AlertRemovedModalWidget = !state.ModalWidget.AlertRemovedModalWidget;
    },
    toggleVisibilityShareModalWidget: (state, action?) => {
      state.ModalWidget.ShareModalWidget.showModal = action.payload.showModal;
      state.ModalWidget.ShareModalWidget.shareLink = action.payload.shareLink;
      state.ModalWidget.ShareModalWidget.title = action.payload.title;
    },
    toggleVisibilityStatusCodesModalWidget: (state, action: httpStatusCodes) => {
      switch (action.payload.code) {
        case 200:
          state.ModalWidget.SuccessModalWidget = action.payload.boolean;
          break;
        case 400:
          state.ModalWidget.ServerNotAvailableModalWidget = action.payload.boolean;
          break;
        case 401:
          state.ModalWidget.AccessDeniedModalWidget = action.payload.boolean;
          break;
        case 403:
          state.ModalWidget.AccessDeniedModalWidget = action.payload.boolean;
          break;
        case 404:
          state.ModalWidget.ServerNotAvailableModalWidget = action.payload.boolean;
          break;
        case 500:
          state.ModalWidget.ServerNotAvailableModalWidget = action.payload.boolean;
          break;
        case 'FETCH_ERROR':
          state.ModalWidget.ServerNotAvailableModalWidget = action.payload.boolean;
          break;
        default:
          break;
      }
    }
  }
});
