import ViewModel from '../@base/ViewModel';
import {Apis} from "../../domain/Apis";

export default class SearchViewModel extends ViewModel {
  constructor() {
    super();
  }

  async search(language, txt) {
    let stopsData = await Apis.search.getStops(language, txt).catch(e=>[])
    let poisData = await Apis.search.getPois(language, txt).catch(e=>[])
    return super.notify({pois:poisData, stops:stopsData});
  }
}

