import ViewModel from '../@base/ViewModel';
import {Apis} from "../../domain/Apis";
import RouteUtils from "../../utils/RouteUtils";
import {VM} from "../VM";
import PlanUtils from "../../utils/PlanUtils";
import GeneralUtils from "../../utils/GeneralUtils";

export default class PlannerViewModel extends ViewModel {
  constructor() {
    super();
    this.selected=0;
    this.accessible=false;
  }

  async plan(favoriteAgencies) {
    try {
      this.data = await Apis.planner.plan();

      if (this.data.length > 1) {
        this.data = RouteUtils.joinPlan(this.data);
        if(RouteUtils.getWalkItineraries(this.data)?.plan?.itineraries?.length===0){
          let aux = await Apis.planner.plan(true);
          let auxJoined = RouteUtils.joinPlan(aux);
          if(RouteUtils.getWalkItineraries(this.data)?.plan?.itineraries?.length===0)
            this.data= RouteUtils.joinPlans(this.data, auxJoined)
        }

        if (favoriteAgencies) {
          let aux = await Apis.planner.plan(false, true);
          if (aux[0] && !aux[0]?.error && aux[0]?.plan && aux[0]?.plan?.itineraries && aux[0]?.plan?.itineraries?.length > 0 && RouteUtils.getFavoriteItineraries(this.data)?.plan?.itineraries?.length === 0) {
            let auxJoined = RouteUtils.joinPlan(aux);
            let planWithoutWalk = RouteUtils.getNoWalkItineraries(auxJoined);
            let favPlan = RouteUtils.checkFavAgencyPlan(planWithoutWalk);
            this.data= RouteUtils.joinPlans(this.data, favPlan);
          }
        }

      } else if (this.data && this.data[0] && !this.data[0]?.error && !this.data?.error) {
         this.data = this.data[0]
      }
      
      if (this.data?.error) {
        this.data = await Apis.planner.plan(true);
        if (this.data.length > 1) {
          this.data = RouteUtils.joinPlan(this.data);
        } else {
          this.data = this.data[0];
        }
        return super.notify(this.data);
      }

      if (!this.data?.error)
        this.data.plan.itineraries = RouteUtils.sortRoutes(this.data, VM.plannerConfig.data.order, VM.plannerTime.data.arriveBy && VM.plannerConfig.data.order === 'duration' ? 'desc' : 'asc')

      if(RouteUtils.getWalkItineraries(this.data)?.plan?.itineraries?.length===0){
        let aux = await Apis.planner.plan(true);
        if(RouteUtils.getWalkItineraries(this.data)?.plan?.itineraries?.length===0)
          this.data= RouteUtils.joinPlans(this.data, aux[0])
      }
      if (favoriteAgencies) {
        let aux = await Apis.planner.plan(false, true);
        if (aux[0] && !aux[0]?.error && aux[0]?.plan && aux[0]?.plan?.itineraries && aux[0]?.plan?.itineraries?.length > 0 && RouteUtils.getFavoriteItineraries(this.data)?.plan?.itineraries?.length === 0) {
          let planWithoutWalk = RouteUtils.getNoWalkItineraries(aux[0]);
          let favPlan = RouteUtils.checkFavAgencyPlan(planWithoutWalk);
          this.data= RouteUtils.joinPlans(this.data, favPlan);
        }
      }
      this.data.plan.itineraries = RouteUtils.sortRoutes(this.data, VM.plannerConfig.data.order, VM.plannerTime.data.arriveBy && VM.plannerConfig.data.order === 'duration' ? 'desc' : 'asc')

      let copy = `${GeneralUtils.getBaseUrl()}/#/plan?${GeneralUtils.convertToHex(
          `id=${this.data?.idPlanning}`
      )}`;
      window.history.pushState('a','a', copy)
      return super.notify(this.data);
    }
    catch (e) {
      this.data=e;
      return super.notify(this.data);
    }

  }

  clear() {
    this.data=[];
    super.notify(this.data)
  }

  setSelected(id){
    this.selected=id
    this.notify(this.data)
  }

}
