import React from 'react';
import { useTheme } from '../../../../../../context/themeContext';

export default function Separator(props) {
  const theme = useTheme();

  const styles = {
    separator: {
        borderBottomColor: theme.colors.neutralBorder,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        width: '100%',
        alignSelf: 'center',
        marginTop: props.margin ?? '8px'
      },
  }

  return (
    <div style={{...styles.separator, ...props.style}}/>
  )
}
