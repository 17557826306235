import React, { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse/lib/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import {
  useSearchNominatimMutation,
  useSearchNoraMutation,
  useSearchPoisMutation,
  useSearchStopsMutation
} from '../../../../../../redux/login/search/SearchServiceApi';
import { favoritesInformation } from '../../../../../../redux/slices/favoritesSlice';
import { searchSlice } from '../../../../../../redux/slices/searchSlice';
import RouteUtils from '../../../../../../utils/RouteUtils';
import { VM } from '../../../../../../viewmodel/VM';
import ButtonIcon from '../../../../../components/commons/ButtonIcon';
import Input from '../../../../../components/commons/Input';
import SecurityCollapse from '../../../../../components/commons/SecurityCollapse';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../../../../context/modalContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import DirectionsList from '../../searcher/DirectionsList';
import MyDirections from '../../searcher/MyDirections';
import OthersList from '../../searcher/OthersList';
import PoisList from '../../searcher/PoisList';
import Recents from '../../searcher/Recents';
import SearchCategory from '../../searcher/SearchCategory';
import StopsList from '../../searcher/StopsList';
import GeneralUtils from "../../../../../../utils/GeneralUtils";
import { useGetLinesWithHeadsignsMutation } from '../../../../../../redux/login/line/lineServiceApi';
import useDebounce, {useDebounceTerm} from './useDebounce';
import EmptySearch from './EmptySearch';
import LanguageSelector from '../../../../accountscreen/language/LanguageSelector';

export default function SearcherModal(props, ref) {
  const theme = useTheme();
  const t = useTranslate();
  const language = useLanguage();
  const responsive = useResponsive();
  const [isFocused, setIsFocused] = useState(false);
  const [inputChange, setInputChange] = useState('');
  const [searching1, setIsSearching1] = useState(false);
  const [searching2, setIsSearching2] = useState(false);
  const [searching3, setIsSearching3] = useState(false);
  const [searching4, setIsSearching4] = useState(false);
  const setModalOpened = useSetModalOpened();
  const setModalChild = useSetModalChild();
  const favorites = useSelector(favoritesInformation);

  const content = useRef(null);
  const input = useRef(null);

  const [searchNora] = useSearchNoraMutation();
  const [searchPois] = useSearchPoisMutation();
  const [searchStops] = useSearchStopsMutation();
  const [searchOthers] = useSearchNominatimMutation();
  const [getLinesWithHeadsigns] = useGetLinesWithHeadsignsMutation();

  const dispatch = useDispatch();
  const {debounce} = useDebounce();

  useEffect(() => {
    input.current.setValue(props?.markerSelected?.name ==='container' ? t('container') : props?.markerSelected?.name);
    props.markerSelected?.name ? setInputChange(props?.markerSelected?.name) : setInputChange('');
  }, [props.markerSelected]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickComponent);
  }, []);

  useEffect(() => () => document.removeEventListener('mousedown', handleClickComponent), []);

  const styles = {
    content: {
      position: 'relative',
      padding: '12px',
      marginLeft: !responsive.isBig ? '25px' : '105px',
      marginTop: '25px',
      maxWidth: '345px',
      minWidth: '232px',
      width: '80vw',
      background: theme.colors.white,
      boxShadow: '0px 4px 4px rgba(28, 31, 32, 0.1)',
      borderRadius: '12px',
      textAlign: 'left' 
    },
    row: {
      display: 'flex',
      height: 37.5
    },
    tags: {
      marginRight: '10px'
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 0.5,
      width: '100%',
      marginTop: '15px',
      marginBottom: '15px'
    },
    mainText: {
      fontSize: 13,
      fontWeight: 600,
      color: theme.colors.mainText,
      marginTop: 10
    },
    secondaryText: {
      color: theme.colors.textSecondary,
      fontSize: 13,
      marginTop: 10
    },
    search: {
      marginTop: 15,
      maxHeight: window.innerHeight - 130,
      overflow: 'auto'
    }
  };

  const handleClickComponent = (event) => {
    if (content && !content.current?.contains(event.target)) {
      setIsFocused(false);
      props.setShowSecond(true);
    }
  };

  const resultClick = (poi) => {
    dispatch(searchSlice.actions.resetAll([]));
    setIsFocused(false);
    input.current.setValue(poi.name);
    if (poi.lat || poi.lon) {
      poi.latitude = poi.lat;
      poi.longitude = poi.lon;
    }
    VM.recents.add(poi);
    props.onClickMarker(poi, null, 17.5);
    props.setShowSecond(true);
  };

  const goThere = () => {
    if (props.markerSelected) {
      let coords = {
        latitude: props.markerSelected.latitude,
        longitude: props.markerSelected.longitude
      };
      let name = props.markerSelected.name;
      let info = RouteUtils.getTypeId(props.markerSelected, favorites);
      let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };
      VM.plannerSegments.set('destination', res);
    } else {
      setModalChild(<div>{t('notFound')}</div>);
      setModalOpened(true);
    }
  };

  const debouncedSearch = debounce(async (text) => {
    setIsSearching1(true);
    setIsSearching2(true);
    setIsSearching3(true);
    setIsSearching4(true);
    //setInputChange(text);
    searchStops({ locale: language, txt: text }).then(async (data) => {
      if (data?.data) {
        let stopsData = JSON.parse(JSON.stringify(data.data))?.map((stop) => ({...stop, ...{name: stop?.stopMunicipality ? `${stop?.name} (${stop?.stopMunicipality})` : stop?.name }}));

        let stopsCheckHeadSigns = [];
        let obtainHeadSigns = false;

        //recorrido en el que se comprueba para cada parada si alguna de sus líneas pasa por alguna otra parada de las buscadas
        for (const stop of stopsData) {
          let linesRepeated = [];
          let linesNotRepeated = [];
          if (stop?.lines) {
            for (const lineOfStop of stop?.lines) {
              //comprueba si el id de la línea es el mismo que en el caso de las otras paradas
              if (
                stopsData?.find((element) => {
                  if (stop?.id === element?.id) {
                    return false;
                  }

                  return element?.lines?.find(
                    (line) => line.id === lineOfStop.id,
                  );
                })
              ) {
                linesRepeated.push({id: lineOfStop?.id});
                obtainHeadSigns = true;
              } else {
                linesNotRepeated.push({id: lineOfStop?.id});
              }
            }
          }

          stopsCheckHeadSigns.push({
            ...stop,
            ...{linesRepeated: linesRepeated, linesNotRepeated: linesNotRepeated},
          });
        }
          stopsData = stopsCheckHeadSigns;

        //se llama a obtener los headsigns en caso de haber líneas repetidas
        if (obtainHeadSigns) {
          for (const stop of stopsData) {
            if (stop?.linesRepeated?.length) {
              //realizar petición y modificar el array de las repetidas para que se muestre el sentido
              
              const stopWithHeadsign = await getLinesWithHeadsigns({stopId: stop.id, language: language})

              if (stopWithHeadsign.data) {
                //se recorren la líneas con su sentido y se comprueba aquellas que están repetidas
                stopWithHeadsign.data.forEach((lineHeadsigns) => {
                  let indexRep = stop?.linesRepeated?.findIndex((lineRep) => lineRep.id === lineHeadsigns.id);
                  if (indexRep !== -1 && lineHeadsigns?.headsigns?.length) {
                    //en caso de ser repetida comprobar el número de headsigns
                    if (lineHeadsigns?.headsigns?.length > 1) {
                      //en este caso la línea no podrá mostrar el sentido por lo que
                      //se deberá añadir a la lista de líneas no repetidas y eliminarla de 
                      //las repetidas
                      let lineDeleted = {...stop?.linesRepeated[indexRep]} 
                      stop?.linesRepeated.splice(indexRep, 1);
                      stop.linesNotRepeated.push(lineDeleted);
                    } else {
                      //en este caso añadir el headsign de la línea
                      stop.linesRepeated[indexRep].headsign = lineHeadsigns?.headsigns[0];
                    }
                  }
                })
              }
            }
          }
        }

        setIsSearching1(false);
        dispatch(searchSlice.actions.updateStops(stopsData));
      } else {
        setIsSearching1(false);
        dispatch(searchSlice.actions.updateStops([]));
      }
    });
    /* searchPois({ locale: language, txt: text }).then((data) => {
      setIsSearching2(false);
      dispatch(searchSlice.actions.updatePois(data.data));
    });
    searchNora({ locale: language, txt: text })
      .then((data) => {
        setIsSearching3(false);
        dispatch(searchSlice.actions.updateDirections(data.data));
      })
      .catch(() => {
        setIsSearching3(false);
        dispatch(searchSlice.actions.updateDirections({}));
      }); */
    searchOthers({ locale: language, txt: text }).then((data) => {
      setIsSearching4(false);
      dispatch(searchSlice.actions.updateOthers(data.data));
    });
  }, 1500);

  const search = (e) => {
    if (inputChange?.length && isFocused) {
      setIsSearching1(true);
      setIsSearching4(true);
      debouncedSearch(e);
    } else {
      //setInputChange(e);
      setIsSearching1(false);
      setIsSearching4(false);
      dispatch(searchSlice.actions.updateDirections([]));
      dispatch(searchSlice.actions.updateStops([]));
      dispatch(searchSlice.actions.updatePois([]));
      dispatch(searchSlice.actions.updateOthers([]));
    }
  };

  const debouncedSearchTerm = useDebounceTerm(inputChange, 500);
  useEffect(() => {
    search(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <div ref={content} style={styles.content}>
      <div style={styles.row}>
        {!responsive.isBig ? (
          <ButtonIcon
            style={{ marginRight: '10px' }}
            icon={R.drawables.commons.bar}
            onClick={() => props.setOpen(true)}
            size={'37.5px'}
            iconSize={'15px'}
            background={theme.colors.mediumDark}
          />
        ) : null}
        {responsive.isMobile ? <LanguageSelector style={{marginRight: '8px'}}/> : null}
        <Input
          ref={input}
          background={inputChange?.length ? theme.colors.border : theme.colors.white}
          onFocus={(e) => {
            setIsFocused(true);
            props.setShowSecond(false);
          }}
          onChange={(e) => {
            props.setShowSecond(false);
            //search(e);
            setInputChange(e);
          }}
          onClear={() => {
            GeneralUtils.resetPath();
            props.setMarkerSelected(null);
            setIsFocused(false);
            props.setShowSecond(true);
            //search('');
            setInputChange('');
            dispatch(searchSlice.actions.resetAll([]));
          }}
          placeholder={t('whereDoYouGo')}
        />

        {inputChange?.length ? (
          <ButtonIcon
            style={{ marginLeft: '10px' }}
            icon={R.drawables.map.goThere}
            onClick={() => goThere()}
            iconColor={theme.colors.mediumGray}
            size={'37.5px'}
            iconSize={'15px'}
            borderColor={theme.colors.border}
          />
        ) : null}
      </div>
      {inputChange?.length === 0 || !isFocused ? (
        <div>
          {props?.markerSelected ? null : (
            <MyDirections
              onClickMarker={props.onClickMarker}
              setMarkerSelected={props.setMarkerSelected}
              style={{ marginTop: 15 }}
            />
          )}
          <Collapse isOpened={isFocused && inputChange?.length === 0}>
            <SecurityCollapse />
            <div style={styles.separator} />
            {/*<SearchCategory/>*/}
            <Recents style={{ marginTop: 15 }} resultClick={resultClick} />
          </Collapse>
        </div>
      ) : null}

      {inputChange?.length !== 0 && isFocused ? (
        <div style={styles.search}>
          <OthersList
              searching={searching4}
              resultClick={resultClick}
              inputChange={inputChange}
              style={{ marginTop: 15, marginRight: 10 }}
          />
          <StopsList text={inputChange} searching={searching1} resultClick={resultClick} style={{ marginRight: 10 }} />
          {!searching4 && !searching1 && debouncedSearchTerm ? (<EmptySearch/>) : null}
          {/*<PoisList
            searching={searching2}
            resultClick={resultClick}
            style={{ marginTop: 15, marginRight: 10 }}
          />*/}
          {/*<DirectionsList
            searching={searching3}
            resultClick={resultClick}
            style={{ marginTop: 15, marginRight: 10 }}
          />*/}
        </div>
      ) : null}
    </div>
  );
}

SearcherModal = React.forwardRef(SearcherModal);
