import {useTranslate} from "../../../../../context/languageContext";
import {useTheme} from "../../../../../context/themeContext";
import {useResponsive} from "../../../../../context/responsiveContext";
import { Collapse } from "react-collapse";
import FiltersView from "./FiltersView";
import React, {useState, useEffect} from 'react';
import { R } from "../../../../../R";
import ConfigAllFilters from "./moreFilters/ConfigAllFilters";
import Separator from "./atoms/Separator";
import FilterUtils from "../../../../../../utils/FilterUtils";
import SecurityCollapse from "../../../../../components/commons/SecurityCollapse";
import {useSelector} from "react-redux";
import {filtersInformation} from "../../../../../../redux/slices/filtersSlice";

export default function Filters() {
    const theme = useTheme();
    const t = useTranslate();
    const responsive = useResponsive();
    const [isOpened, setIsOpened] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const filters = useSelector(filtersInformation)

    const IconUp = R.drawables.general.Ic_ChevronUpSmall;
    const IconDown = R.drawables.general.Ic_ChevronDownSmall;

    const styles={
        content: {
            position:'relative',
            flexDirection: "column",
            padding: "12px",
            marginLeft: !responsive.isBig ?'25px' :'105px',
            marginTop: '10px',
            width: "80vw",
            maxWidth: "345px",
            maxHeight:"68vh",
            minWidth: "232px",
            background: theme.colors.white,
            boxShadow: "0px 4px 4px rgba(28, 31, 32, 0.1)",
            borderRadius: "12px",
            alignItems:'center'
        },

        text: {
            textAlign:'left',
            fontWeight: 700,
            fontSize: "18px",
            color: FilterUtils.checkSelectedAllFilters(filters.modes, filters.operators, filters.motorized, filters.categories )
                  ? theme.colors.primaryMedium
                  : theme.colors.neutralBlack
        },
        icon: {
            fontSize: "20px",
            marginLeft:'auto',
        },
        row: {
            maxHeight:30,
            display: "flex",
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: "80vw",
            alignItems:'center',
            maxWidth: "345px",
            minWidth: "232px",
        }
    }

    const setOpenedFilters = () => {
        setIsOpened(!isOpened);
        setViewModal(!viewModal);
    }

    return (
        <div style={styles.content}>
            {!viewModal
             ?(<><div style={styles.row}>
                    <div style={styles.text}>
                        {`${t('filters')}  ${FilterUtils.countAllFilters(filters.modes, filters.operators, filters.motorized, filters.categories) === 0 
                            ? '' 
                            : '(' + FilterUtils.countAllFilters(filters.modes, filters.operators, filters.motorized, filters.categories) + ')'}`}
                    </div>
                    {isOpened
                        ?<IconUp style={{cursor:'pointer'}} onClick={() => setIsOpened(!isOpened)}/>
                        :<IconDown style={{cursor:'pointer'}} onClick={() => setIsOpened(!isOpened)}/>
                    }
                </div>
                <Collapse isOpened={isOpened}>
                    <SecurityCollapse/>
                    <Separator style={{marginBottom:10, marginTop:10}}/>
                    <FiltersView setOpenedFilters={() => setOpenedFilters()} />
                </Collapse></>)
             :<ConfigAllFilters setIsOpened={() => setOpenedFilters()}/>
            }
        </div>
    );

}
