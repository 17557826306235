import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
  useGetChatMessagesMutation,
  useGetTimeChatMessagesQuery,
  useReadMessageMutation
} from '../../../../redux/login/services/MessagesServiceApi';
import { IMessageChat, IUserChatMessage } from '../../../../types/AccountInterfaces';
import { useTheme } from '../../../context/themeContext';
import Button from '../../../components/commons/Button';
import TimeHookUtils from '../../../../utils/TimeHookUtils';
import InputChatComment from './components/InputChatComment';
import CommentStateElement from './CommentStateElement';
import FileEntrySending from './components/FileEntrySending';
import { useDownloadFiles } from '../../../../utils/FileUtils';
import useNotificationsUtils from '../../../../utils/NotificationsUtils';

interface ChatContentScreenProps {
  messageId: number;
  setRenderChatMessages: Function;
  messagesUser: Array<IMessageChat>;
}

export default function ChatContentScreen(props: ChatContentScreenProps) {
  const [messagesOfChat, setMessagesOfChat] = useState<Array<IUserChatMessage>>([]);
  const [firstMessage, setFirstMessage] = useState<IUserChatMessage>();
  const theme = useTheme();
  const scrollRef: MutableRefObject<HTMLDivElement | null | undefined> | undefined = useRef();
  const { getTimeMessage } = TimeHookUtils();
  const {downloadFileData} = useDownloadFiles();
  const [readMessage] = useReadMessageMutation();
  const { data: messageChatData, refetch } = useGetTimeChatMessagesQuery(
    { messageId: props.messageId },
    {
      pollingInterval: 30000
    }
  );
  const foundMessage = props.messagesUser?.find((element) => element.id === props.messageId);
  const {checkReadedMessages} = useNotificationsUtils();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    async function readNewResponses() {
      await readMessage(props.messageId).then(async () => {
        await checkReadedMessages();
      } );
      
    }
    readNewResponses();
  }, [props.messageId]);

  useEffect(() => {
    async function obtainMessages() {
      if (messageChatData) {
        let modifyList = JSON.parse(JSON.stringify(messageChatData));
        setFirstMessage(modifyList.shift());
        setMessagesOfChat(modifyList.reverse());
        setTimeout(() => {
          scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }, 300);
      }
    }
    obtainMessages();
  }, [messageChatData]);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
      <div
        className={'account-child'}
        style={{
          maxHeight: 414,
          minHeight: 414,
          display: 'flex',
          flexDirection: 'column-reverse',
          flex: 1
        }}>
        <div ref={scrollRef as any} />
        {messagesOfChat.map((element: IUserChatMessage) => (
          <div
            key={element.createdAt + ' ' + element.chatOrder}
            className="message-chat"
            style={
              element.adminUser
                ? {
                    backgroundColor: theme.colors.primaryMedium,
                    color: 'white',
                    alignSelf: 'flex-start'
                  }
                : {}
            }>
            <div
              style={{
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                display: 'flex',
                flex: 1
              }}>
              <div />
              <div style={{ textAlign: 'right', fontSize: 12 }}>
                {getTimeMessage(element.createdAt)}
              </div>
            </div>
            <div style={{ fontSize: 14, alignSelf: 'flex-start' }}>{element.messageBody}</div>
            {element.fileId && element?.fileName && element?.fileExtension ? <FileEntrySending
              fileName={element?.fileName}
              fileType={element?.fileExtension}
              localeBlob={''}
              setFileData={() => {}}
              setTypeFile={() => {}}
              notClose={true}
              admin={element.adminUser}
              style={{marginLeft: 0, marginTop: 8, cursor: 'pointer'}}
              onClick={async () => {
                //descargar archivo, si es una imagen se podría previsualizar y si es un documento se descarga directamente 
                if (element.fileId && element?.fileExtension && element?.fileName) {
                  await downloadFileData(element.fileId, element?.fileExtension, element?.fileName);
                  
                }
                
              }}
            /> : null}
          </div>
        ))}
        {foundMessage ? (
          <CommentStateElement
            key={foundMessage.id}
            id={foundMessage.id}
            title={foundMessage.title}
            type={foundMessage.categoryText}
            sentAt={firstMessage?.createdAt ? firstMessage?.createdAt : foundMessage?.lastMessageDate}
            messageBody={foundMessage.messageBody}
            fileId={foundMessage?.fileId}
            fileExtension={foundMessage?.fileExtension}
            fileName={foundMessage?.fileName}
            reply={false}
            replyAt={foundMessage?.replyAt}
            style={{
              marginInline: 0,
              width: '90%',
              padding: 8,
              marginLeft: 8,
              alignSelf: 'flex-end'
            }}
            setRenderChatMessages={() => {}}
          />
        ) : null}
      </div>
      <div style={{ padding: 8 }}>
        <InputChatComment messageId={props.messageId} setMessagesOfChat={setMessagesOfChat} />
      </div>
    </div>
  );
}
