import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useInitRecoveryPwdMutation } from '../../../../redux/login/services/authServiceApi';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useTranslate } from '../../../context/languageContext';
import { useSetLoading } from '../../../context/loadingContext';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import ButtonNext from '../../commons/buttons/ButtonNext';
import SimpleModal from '../../commons/Modals/SimpleModal';
import TitleH1 from '../../commons/Titles/TitleH1';
import InputEmail from '../../inputs/InputEmail';

export default function RecoveryPassModalWidget(props: any) {
  const theme = useTheme();
  const t = useTranslate();
  const Close = R.drawables.commons.close;
  const styles = {
    header: {
      padding: '0 6%',
      paddingTop: '11.6%'
    },
    h1: {
      color: '#1C1F20',
      fontFamily: 'Open Sans',
      fontWeight: '700',
      fontSize: '22px'
    },
    body: {
      padding: '0 6% 6% 6%',
      width: '100%'
    },
    form: {
      display: 'flex',
      flexDirection: 'column'
    },
    close: {
      marginLeft: 'auto',
      marginRight: '20px',
      marginTop: '20px',
      cursor: 'pointer',
      position: 'absolute',
      right: 0,
      top: 0,
      height: 10.65,
      width: 10.71
    },
    p: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: ' 140%',
      color: '#1C1F20'
    },
    label: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: ' 150%',
      display: 'flex',
      alignItems: 'flex-end',
      color: '#A4A8AA'
    },
    margin: {
      marginTop: 20
    }
  } as const;

  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const setLoading = useSetLoading();

  const [initRecoveryPwd, { error }] = useInitRecoveryPwdMutation();

  async function sendEmail() {
    setLoading(true);

    const result: any = await initRecoveryPwd({
      email: email
    });
    if (result) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityRecoveryPassModalWidget(false)
      );
    }

    if (result && !result.error) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#00763D',
          text: 'Revisa tu correo electrónico'
        })
      );
    } else if (result && result.error && result.error.status == 400) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#A31610',
          text: t('noInternetConnection'),
          status: 'error'
        })
      );
    } else if (result && result.error && result.error.status == 401) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#A31610',
          text: t('accountNotRegistered'),
          status: 'error'
        })
      );
    } else {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#A31610',
          text: t('serviceError'),
          status: 'error'
        })
      );
    }
    setLoading(false);
  }

  return (
    <SimpleModal
      background="#ffffff"
      style={{
        maxWidth: 400,
        display: 'flex',
        width: '100%'
      }}
      header={
        <div style={styles.header}>
          <Close
            style={styles.close}
            fill={theme.colors.black}
            onClick={() =>
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityRecoveryPassModalWidget(
                  false
                )
              )
            }
          />
          <TitleH1 title={t('needANewPass')} color="#1C1F20" />
          <p style={styles.p}>{t('introduceYourEmail')}</p>
        </div>
      }
      body={
        <div style={styles.body}>
          <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
            <InputEmail
              placeholderText={t('emailLong')}
              email={email}
              setEmail={setEmail}
              background="#fff"
              className={'password-recovery-input'}
              borderColor={'#E9EAEA'}
              labelTextColor={'#A4A8AA'}
            />
            <ButtonNext
              title={t('send')}
              disabled={email.length >= 1 ? false : true}
              className="btn-primary"
              dispatch={sendEmail}
            />
          </form>
        </div>
      }
    />
  );
}
