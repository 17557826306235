import ViewModel from '../@base/ViewModel';

export default class PlannerConfigViewModel extends ViewModel {
    constructor() {
        super();
        this.data= {
            accessible: false,
            order: 'time',
            maxWalkDistance: null,
            modes:[],
            favoritePlan: false,
        }
    }

    setAccessible(bool){
        this.data.accessible=bool;
        this.notify(this.data)
    }

    setOrder(order){
        this.data.order=order;
        this.notify(this.data)
    }

    setFavoritePlan(fav){
        this.data.favoritePlan = fav;
        this.notify(this.data)
    }

    updateModes(mode){
        if(this.data.modes.indexOf(mode)<0)
            this.data.modes.push(mode,)
        else
            this.data.modes.splice(this.data.modes.indexOf(mode),1)
        this.notify(this.data)
    }

    setMaxWalkDistance(distance) {
        this.data.maxWalkDistance = distance;
        this.notify(this.data);
    }
}   
