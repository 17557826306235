import { useTranslate } from '../../context/languageContext';

export default function InputEmail({
  email,
  setEmail,
  background,
  className,
  borderColor,
  labelTextColor,
  placeholderText
}: {
  email: string | null | undefined;
  setEmail: any;
  background?: string;
  className?: string;
  borderColor?: string;
  labelTextColor?: string;
  placeholderText?: string;
}) {
  const styles = {
    input: {
      background: background ? background : '#005891',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '150%',
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      // border: '0',
      borderBottomWidth: '1px',
      borderBottomColor: borderColor ? borderColor : '#0078B2',
      // borderBottom: '1px solid #0078B2',
      // borderBottomColor: borderColor ? borderColor : '#0078B2',
      padding: '1.5% 0'
      // marginBottom: '9%'
      // color: '#fff'
    },
    label: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: ' 150%',
      display: 'flex',
      alignItems: ' flex-end',
      color: labelTextColor ? labelTextColor : '#A3D4EA'
    }
  } as const;
  const t = useTranslate();

  return (
    <>
      <label className={email && email.length ? '' : 'visibilityHidden'} style={styles.label}>
        {placeholderText ? placeholderText : t('email')}
      </label>
      <input
        className={className}
        style={styles.input}
        type="email"
        name="email"
        placeholder={placeholderText ? placeholderText : t('email')}
        value={email ? email : ''}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
    </>
  );
}
