import React, { useEffect, useState } from 'react';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import CheckBox from '../../../components/commons/CheckBox';
import { ClipLoader } from 'react-spinners';
import Button from '../../../components/commons/Button';
import { VM } from '../../../../viewmodel/VM';
import { useTranslate } from '../../../context/languageContext';
import { R } from '../../../R';
import { useTheme } from '../../../context/themeContext';
import { useSetModalChild, useSetModalOpened } from '../../../context/modalContext';
import { useSelector } from 'react-redux';
import { agencyInformation } from '../../../../redux/slices/agencySlice';
import { filtersNewsAlertsState, newsSlice } from '../../../../redux/slices/newsSlice';
import { useDispatch } from 'react-redux';
import {
  favFilterNewsAlertsState,
  favoritesInformation,
  favoritesSlice,
  selectAllFilterNewsAlertsState
} from '../../../../redux/slices/favoritesSlice';
import { accountInformation } from '../../../../redux/slices/authSlice';
import { useEditFiltersNewsAlertsMutation } from '../../../../redux/login/favorite/FavoriteServiceApi';
import { useUpdateDateNewsMutation } from '../../../../redux/login/news/NewsServiceApi';

export default function OperatorNewsModalFilter() {
  const Close = R.drawables.general.Ic_close;
  const t = useTranslate();
  const [renderOperators, setRenderOperators] = useState(null as any);
  const setOpened = useSetModalOpened();
  const setChild = useSetModalChild();
  const theme = useTheme();
  const filtersSelectedAgencies = useSelector(filtersNewsAlertsState);
  const [selected, setSelected] = useState<Array<number>>(filtersSelectedAgencies);
  const agencyState = useSelector(agencyInformation);
  const dispatch = useDispatch();
  const favAgencies = useSelector(favoritesInformation).agencyId;
  const favFilterNewsAlerts = useSelector(favFilterNewsAlertsState);
  const selectAllFilterNewsAlerts = useSelector(selectAllFilterNewsAlertsState);
  const [favFilterSelector, setFavFilterSelector] = useState(favFilterNewsAlerts);
  const [selectAllFilterNewsAlertsSelector, setSelectAllFilterNewsAlertsSelector] = useState(selectAllFilterNewsAlerts);
  const account = useSelector(accountInformation);
  const agencyFilters = agencyState.agenciesFilters;
  const [editFiltersNewsAlerts] = useEditFiltersNewsAlertsMutation();
  const [editNewsDate] = useUpdateDateNewsMutation();

  let copyAgenciesFilters = JSON.parse(JSON.stringify(agencyState.agenciesFilters));
  let agenciesTransport: Array<any> = [];
  copyAgenciesFilters.forEach((transportModeAg: any) => {
    if (transportModeAg.id !== 5) {
      transportModeAg.agencies.shift();
      let filterDuplicates: Array<number> = [];

      agenciesTransport.forEach((agency: any) => {
        let indexFound = transportModeAg.agencies.findIndex(
          (transAg: any) => transAg.id === agency.id
        );
        if (indexFound !== -1) {
          filterDuplicates.push(indexFound);
        }
      });
      if (filterDuplicates?.length) {
        filterDuplicates.forEach((indexToDel: number) => {
          transportModeAg.agencies.splice(indexToDel, 1);
        });
      }

      let filteredAgenciesWithNewsOrAlerts = transportModeAg.agencies.filter((agency: any) =>
        agencyState.dataOrigin?.find(
          (ori: any) =>
            agency?.id === ori?.agencyId &&
            (ori?.driverId === 2 || ori?.driverId === 4 || ori?.driverId === 6)
        )
      );

      agenciesTransport = agenciesTransport.concat(filteredAgenciesWithNewsOrAlerts);
    }
  });
  agenciesTransport.sort(function (a, b) {
    let nameA = a?.longName?.toLowerCase();
    let nameB = b?.longName?.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    async function fetchData() {
      setRenderOperators(await getRenderOperators());
    }
    fetchData();
  }, [selected]);

  const changeAllCheckbox = (value: boolean) => {
    let res: Array<number> = [];
    if (value) {
      res = agenciesTransport.map((agency) => agency.id);
    }
    return res;
  };

  const getRenderOperators = async () => {
    let res = [];
    for (let agency in agenciesTransport) {
      let icon = await VM.icons.getIcon(agenciesTransport[agency].id, 'agency');
      let isSelected =
        selected.find((sel: number) => sel === agenciesTransport[agency].id) !== undefined;
      res.push(
        <div key={agency}>
          <div className={'row'}>
            <ButtonIcon style={{ marginRight: 5 }} iconSize={20} size={30} icon={icon} />
            <div className={'primaryText'} style={{ textTransform: 'capitalize' }}>
              {agenciesTransport[agency]?.longName}
            </div>

            <div className={'close'} style={{ cursor: 'auto' }}>
              <CheckBox
                onSelected={() => {
                  let aux = JSON.parse(JSON.stringify(selected));
                  aux.push(agenciesTransport[agency].id);
                  setSelected(aux);
                }}
                onUnselected={() => {
                  let aux: Array<number> = JSON.parse(JSON.stringify(selected));
                  let foundIndex = selected.findIndex(
                    (sel: number) => sel === agenciesTransport[agency].id
                  );
                  aux.splice(foundIndex, 1);
                  setSelected(aux);
                  if (selectAllFilterNewsAlertsSelector) {
                    setSelectAllFilterNewsAlertsSelector(false);
                  }
                }}
                checked={isSelected}
              />
            </div>
          </div>
          <div className={'divider'} />
        </div>
      );
    }
    return res;
  };

  async function onSaveSelected() {
    dispatch(
      newsSlice.actions.updateFilterAgenciesNewsAlerts(JSON.parse(JSON.stringify(selected)))
    );
    if (favFilterSelector) {
      dispatch(favoritesSlice.actions.updateFilterNewsAlerts(true));
    } else {
      dispatch(favoritesSlice.actions.updateFilterNewsAlerts(false));
    }

    if (selectAllFilterNewsAlertsSelector) {
      dispatch(favoritesSlice.actions.updateSelectAllFilterNewsAlerts(true));
    } else {
      dispatch(favoritesSlice.actions.updateSelectAllFilterNewsAlerts(false));
    }
    const result: any = await editFiltersNewsAlerts({
      favSelected: favFilterSelector,
      allSelected: selectAllFilterNewsAlertsSelector,
      agencies: selected,
    });

    await editNewsDate({}).then(() => {
      dispatch(newsSlice.actions.updateDateNews(false));
    });

    setOpened(false);
    setChild(null);
  }

  const changeFavCheckbox = (value: boolean) => {
    let res: Array<number> = [];

    const agencyIdsFav: Array<number> = [];
    
    favAgencies.forEach((originId: number) => {
      const agency: any = agencyState.dataOrigin.find((element: any) => element.id === originId);
      if (agency && !agency?.groupId) {
        const agencyIsAdded = agencyIdsFav.find((element: number) => element === agency?.agencyId);

        const existsAgency = agenciesTransport.find((element) => element.id === agency?.agencyId);

        if (!agencyIsAdded && existsAgency) {
          agencyIdsFav.push(agency?.agencyId);
        }
      } else if (agency && agency?.groupId) {
        let mode: any = agency.transportModes[0]?.transportModeId;
        //seleccionamos el modo
        if (mode) {
          let modeAgenciesFiltered: any = agencyFilters.find((element: any) => element.id === mode);
          
          if (modeAgenciesFiltered) {
            let foundAgency = modeAgenciesFiltered?.agencies?.find((element: any) => {
              if (element?.agencyIdsGroup?.length ) {
                return element?.agencyIdsGroup?.indexOf(originId);
              } else {
                return null;
              }
            });

            if (foundAgency) {
              const agencyIsAdded = agencyIdsFav.indexOf(foundAgency?.agencyOriginId);

              if (agencyIsAdded === -1) {
                agencyIdsFav.push(foundAgency?.agencyOriginId);
              }
            }
          }

        }
      }
    });

    
    const copySel: Array<number> = JSON.parse(JSON.stringify(selected));
    agencyIdsFav.forEach((sel: number) => {
      let isAddedIndex = copySel.findIndex((element) => element === sel);
      if (isAddedIndex === -1 && value) {
        copySel.push(sel);
      }

      if (isAddedIndex !== -1 && !value) {
        copySel.splice(isAddedIndex, 1);
      }
    });

    res = copySel;

    return res;
  };

  useEffect(() => {
    if (favFilterSelector && !selectAllFilterNewsAlertsSelector) {
      setSelected(changeFavCheckbox(true));
    }
  }, [favFilterSelector, selectAllFilterNewsAlertsSelector]);
  
  useEffect(() => {
    if (selectAllFilterNewsAlertsSelector ) {
      setSelected(changeAllCheckbox(true));
    }
  }, [selectAllFilterNewsAlertsSelector]);

  return (
    <div className={'subs-news'}>
      <div className={'subs-news-header'}>
        <Close
          className={'close'}
          onClick={() => {
            setOpened(false);
            setChild(null);
          }}
        />
        <div className={'title marginBottom10'}>{t('filters_newsTransit_operators')}</div>
        <div className={'description'}>{t('filters_newsTransit_operators_desc')}</div>
      </div>
      <div className={'divider'} />
      <div className={'subs-news-body'}>
        {account?.alias ? (
          <>
            <div className={'row'}>
              <div className={'primaryText'}>{t('selectAllFavorites')}</div>
              <div className={'close'} style={{ cursor: 'auto' }}>
                <CheckBox
                  onSelected={() => {
                    setFavFilterSelector(true);
                  }}
                  onUnselected={() => {
                    if (!selectAllFilterNewsAlertsSelector) {
                      setSelected(changeFavCheckbox(false));
                    }
                    setFavFilterSelector(false);
                  }}
                  checked={favFilterSelector}
                />
              </div>
            </div>
            <div className={'divider'} />{' '}
          </>
        ) : null}
        <div className={'row'}>
          <div className={'primaryText'}>{t('selectAll')}</div>
          <div className={'close'} style={{ cursor: 'auto' }}>
            <CheckBox
              onSelected={() => {setSelectAllFilterNewsAlertsSelector(true);}}
              onUnselected={() =>{setSelectAllFilterNewsAlertsSelector(false); setSelected(changeAllCheckbox(false));}}
              checked={selectAllFilterNewsAlertsSelector}
            />
          </div>
        </div>
        <div className={'divider'} />
        {renderOperators ? (
          renderOperators
        ) : (
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <ClipLoader />
          </div>
        )}
      </div>
      <Button
        className={'paddingVertical10 paddingInLine20'}
        textColor={theme.colors.white}
        background={theme.colors.mediumDark}
        onClick={() => onSaveSelected()}
        style={{ width: '94%' }}
        text={t('save')}
      />
    </div>
  );
}
