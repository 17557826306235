import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface vehiclesInformation {
  vehicles: Array<any>;
}

const initialState = {
  vehiclesInformation: {
    vehicles: []
  }
};

export const vehicleSlice = createSlice({
  name: 'vehicleSlice',
  initialState,
  reducers: {
    updateLineVehicles: (state, action) => {
      state.vehiclesInformation.vehicles = action.payload;
    }
  }
});

export const vehiclesInformation = (state: RootState) => state.vehicleSlice.vehiclesInformation;
