import React from 'react';
import { useSelector } from 'react-redux';
import { searchInformation } from '../../../../../../redux/slices/searchSlice';
import { useTranslate } from '../../../../../context/languageContext';

export default function EmptySearch() {
  const searchInfo = useSelector(searchInformation);
  const t = useTranslate();

  return searchInfo.stops.length === 0 && searchInfo.others.length === 0 ? (
    <div>{t('searchEmptyResults')}</div>
  ) : null;
}
