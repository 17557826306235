import React, {useEffect, useRef, useState} from "react";

export default function CheckBox(props: any) {

    const onChange = (value: boolean) => {
        if(value)
            props?.onSelected()
        else props?.onUnselected()
    }

    return (
        <div >
            <input checked={props?.checked} className={'checkbox'} onChange={(value)=>onChange(value.target.checked)} style={{border:'3px solid #F46A06'}} type={'checkbox'}/>
        </div>
    );
}
