import { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useDispatch, useSelector } from 'react-redux';
import { Apis } from '../../../domain/Apis';
import { useDeleteFavoriteMutation } from '../../../redux/login/favorite/FavoriteServiceApi';
import {
  useAddSubscriptionsMutation,
  useDeleteSubscriptionsMutation
} from '../../../redux/login/subscriptions/SubscriptionsServiceApi';
import { eventsModalstoggleVisibilitySlice } from '../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { favoritesInformation, favoritesSlice } from '../../../redux/slices/favoritesSlice';
import {
  subscriptionsInformation,
  subscriptionsSlice
} from '../../../redux/slices/subscriptionsSlice';
import { VM } from '../../../viewmodel/VM';
import DefaultModal from '../../components/widget/Modals/DefaultModal';
import { useLanguage, useTranslate } from '../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../context/modalContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';
import LineIcon from '../explorescreen/mapOptions/searcherOption/stopDetail/LineIcon';
import { ILine } from '../../../types/LineInterfaces';
import { lineInformation, lineSlice, linesState } from '../../../redux/slices/lineSlice';
import { agencyInformation } from '../../../redux/slices/agencySlice';

export default function FavoriteStop(props: any) {
  const ArrowDown = R.drawables.general.Ic_ChevronDownSmall;
  const ArrowUp = R.drawables.general.Ic_ChevronUpSmall;
  const Remove = R.drawables.general.Ic_delete;
  const Alert = R.drawables.general.Ic_Alerta;

  const [removeFavorite] = useDeleteFavoriteMutation();
  const [removeSubscription] = useDeleteSubscriptionsMutation();
  const [addSubscription] = useAddSubscriptionsMutation();
  const favorites = useSelector(favoritesInformation);
  const allLines = useSelector(linesState);
  const subscriptions: any = useSelector(subscriptionsInformation);
  const transportModes = useSelector(agencyInformation).transportModes as Array<any>;
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const setChild = useSetModalChild();
  const setOpen = useSetModalOpened();
  const [lines, setLines] = useState<Array<any>>([]);
  const [iconType, setIconType] = useState();
  const content = useRef(null as any);
  const language = useLanguage();
  const t = useTranslate();

  const theme = useTheme();


  useEffect(() => {
    async function fetchData() {
      setLines(await Apis.stops.getStopLines(language, props.stop.id));
      setIconType(await VM.icons.getIcon(props.stop.agencyOriginId, 'agency'));
    }
    fetchData();
  }, [props.stop]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickComponent);
  }, []);

  useEffect(() => () => document.removeEventListener('mousedown', handleClickComponent), []);

  const handleClickComponent = (event: any) => {
    if (content && !content.current?.contains(event.target)) {
      setOpened(false);
    }
  };

  const renderLines = () => {
    let res = [];
    for (let line in lines) {
      let dataOri = VM.dataOrigin?.data[lines[line]?.agencyOriginId];
      if (dataOri && !(dataOri?.label && dataOri?.label?.toLowerCase()?.includes('renfe media distancia'))) {
        let lineFound: ILine | undefined = allLines.find((element: ILine) => String(element.id) === String(lines[line]?.id));

        res.push(
          <LineIcon
            key={line}
            style={{ margin: 2 }}
            text={lineFound && (lineFound as ILine)?.code}
            color={lineFound && (lineFound as ILine)?.routeColor ? '#' + (lineFound as ILine)?.routeColor : theme.colors.main}
            textColor={ '#' + lines[line]['routeTextColor']}
          />
        );
      }
    }
    return <div className="row" style={{flexWrap: 'wrap'}}>{res}</div>;
  };

  const removeFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    updateFavorites.stops.splice(updateFavorites.stops.indexOf(props.stop.id), 1);
    removeFavorite({
      stops: [props.stop.id],
      pois: [],
      plans: [],
      directions: []
    });
    dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
        showModal: true,
        background: '#00763D',
        text: t('stopRemoved')
      })
    );
  };

  const updateSubs = () => {
    let updateSubscriptions = JSON.parse(JSON.stringify(subscriptions));
    if (subscriptions.stops.indexOf(props.stop.id) >= 0) {
      updateSubscriptions.stops.splice(updateSubscriptions.stops.indexOf(props.stop.id), 1);
      removeSubscription({
        stops: [props.stop.id],
        lines: [],
        agencyNotices: []
      });
      dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAlertRemovedModalWidget());
    } else {
      setOpen(true);
      setChild(
        <DefaultModal
          title={t('saveAlert')}
          description={t('saveLineAlertDescription')}
          secondButton={t('activateAlerts')}
          firstButton={t('noThanks')}
          onClickFirst={() => {}}
          onClickSecond={() => {
            updateSubscriptions.stops.push(props.stop.id);
            addSubscription({
              stops: [props.stop.id],
              lines: [],
              agencyNotices: []
            });
            dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
          }}
        />
      );
    }
  };

  function stationType() {
    console.log(transportModes);
    
    if (language === 'en') {
      return (
        transportModes.find(({id})=> String(id) === String(props.stop?.transportMode))?.label +
        (props.stop.transportMode == 3 || props.stop.transportMode == 7
          ? t('stationOf')
          : props.stop.transportMode == 6 ||
            props.stop.transportMode == 8 ||
            props.stop.transportMode == 9
          ? ''
          : t('stopOf'))
      );
    }
    else {
      return (
        (props.stop.transportMode == 3 || props.stop.transportMode == 7
          ? t('stationOf')
          : props.stop.transportMode == 6 ||
            props.stop.transportMode == 8 ||
            props.stop.transportMode == 9
          ? ''
          : t('stopOf')) + transportModes.find(({id})=> String(id) === String(props.stop?.transportMode))?.label
      );
    }
  }

  return (
    <div
      className={'favorite-station-container'}
      style={{ maxHeight: window.innerHeight - 165 }}
      ref={content}>
      <div
        className={opened ? 'favorite-station-body-c' : 'favorite-station-body-o'}
        onClick={() => {
          setOpened(!opened);
        }}
        onDoubleClick={() => {
          dispatch(lineSlice.actions.updateFilteredLine(null));
          dispatch(favoritesSlice.actions.setClickFav({ id: props.stop.id, type: 'stop' }));
        }}>
        <div className={'row'}>
          <img className={'agency-icon-box'} src={iconType} />
          <div className={'iconText'}>{stationType()}</div>
          {opened ? <ArrowUp className={'close'} /> : <ArrowDown className={'close'} />}
        </div>
        <div className={'subtitle marginTop5'}>{props.stop.name}</div>
        {renderLines()}
      </div>

      <Collapse isOpened={opened}>
        <div className={'favorite-station-footer row'}>
          <div
            className={'row'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              removeFav();
            }}>
            <Remove style={{ width: 20, marginRight: 5, fill: theme.colors.neutral }} />
            <div className={'primaryText'} style={{ color: theme.colors.neutral }}>
              {t('delete')}
            </div>
          </div>

          <div className={'primaryText close'} onClick={updateSubs}>
            <Alert style={{ width: 20, marginRight: 5 }} />
            {subscriptions.stops.indexOf(props.stop.id) < 0 ? t('createAlert') : t('removeAlert')}
          </div>
        </div>
      </Collapse>
    </div>
  );
}
