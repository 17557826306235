import { Apis } from './domain/Apis';
import { LanguageProvider } from './view/context/languageContext';
import { LoadingProvider } from './view/context/loadingContext';
import { ModalProvider } from './view/context/modalContext';
import { ResponsiveProvider } from './view/context/responsiveContext';
import { ThemeProvider } from './view/context/themeContext';
import { R } from './view/R';
import MainScreen from './view/screens/MainScreen';
import { VM } from './viewmodel/VM';
import {HashRouter, Route, Routes, Switch} from "react-router-dom";
import AppInitRecoveryPwd from "./view/screens/RecoveryScreen/AppInitRecoveryPwd";
import AppInitActiveAccount from "./view/screens/activateAccountScreen/AppInitActiveAccount";
import AgencyPreload from './view/context/AgencyPreload';

function App() {
  R.initialize();
  Apis.initialize();
  VM.initialize();
  return (
    <div className="App">
      <ResponsiveProvider>
        <ThemeProvider>
          <LanguageProvider>
            <LoadingProvider>
              <ModalProvider>
                <AgencyPreload/>
                <Routes>
                  <Route path='/init-recovery-pwd' element={<AppInitRecoveryPwd/>}/>
                  <Route path='/activate-account' element={<AppInitActiveAccount/>}/>
                  <Route exact path='/' element={<MainScreen/>}/>
                  <Route path="*" element={<MainScreen/>} />
                </Routes>
              </ModalProvider>
            </LoadingProvider>
          </LanguageProvider>
        </ThemeProvider>
      </ResponsiveProvider>
    </div>
  );
}

export default App;
