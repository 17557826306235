import ViewModel from '../@base/ViewModel';
import {Apis} from "../../domain/Apis";
import { store } from '../../redux/store';
import { alertsSlice } from '../../redux/slices/alertsSlice';
export default class AlertsViewModel extends ViewModel {
  constructor() {
    super();
  }

  async get(language) {
    //updateAlertsTransit
    const result = await Apis.alert.get(language);
    store.dispatch(alertsSlice.actions.updateAlertsTransit(result));
    return super.notify(result);
  }

  async getDetail(language, id) {
    return super.notify(await Apis.alert.getDetail(language, id));
  }
}
