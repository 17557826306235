import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useSearchNoraMutation } from '../../../../../../redux/login/search/SearchServiceApi';
import { searchSlice } from '../../../../../../redux/slices/searchSlice';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import DirectionMidInfo from './DirectionMidInfo';
import { Apis } from '../../../../../../domain/Apis';
import { VM } from '../../../../../../viewmodel/VM';
import LineIcon from '../stopDetail/LineIcon';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import GeneralUtils from '../../../../../../utils/GeneralUtils';
import { useGetLinesWithHeadsignsMutation } from '../../../../../../redux/login/line/lineServiceApi';
import { useSelector } from 'react-redux';
import { agencyInformation } from '../../../../../../redux/slices/agencySlice';

export default function ClusterDetail(props) {
  const theme = useTheme();
  const CloseIcon = R.drawables.general.Ic_close;
  const responsive = useResponsive();
  const t = useTranslate();
  const Icon = R.drawables.general.Ic_location;
  const language = useLanguage();
  const dispatch = useDispatch();
  const [getLinesWithHeadsigns] = useGetLinesWithHeadsignsMutation();
  const dataOrigin = useSelector(agencyInformation).dataOrigin;

  const [renderCluster, setRenderCluster] = useState();

  useEffect(() => {
    setRenderCluster(null);
    const fetchData = async () => {
      setRenderCluster(await getRenderCluster());
    };
    fetchData();
    let copy = `${GeneralUtils.getBaseUrl()}/#/container?${GeneralUtils.convertToHex(
      `id=${props.cluster.id}&type=${props.cluster.points[0]?.agencyOriginId ? 'stop' : 'poi'}`
    )}`;
    window.history.pushState('a', 'a', copy);
  }, [props.cluster]);

  const getRenderCluster = async () => {
    let stops = [];
    let pois = [];
    let stopsData = props.cluster.points?.filter((element) => element?.agencyOriginId);

    let stopsFormatedData = await Promise.all(
      stopsData.map(async (element) => {
        let linesHead = await getLinesWithHeadsigns({stopId: element.id, language: language})
        linesHead = linesHead?.data ?? [];
        linesHead = JSON.parse(JSON.stringify(linesHead))
        let lines = await Apis.stops.getStopLines(language, element.id)
        lines.forEach((line) => {
          if (!linesHead.find((lineH) => lineH.id === line.id)) {
            line.headsigns = [];
            linesHead.push(line)
          }
        })
        
        let linestimes = /* await Apis.stops.getStopLinestimes(language, element.id); */ [];

        return { ...element, ...{ lines: linesHead, linestimes: linestimes} };
      })
    );

    let stopsCheckHeadSigns = [];

    //recorrido en el que se comprueba para cada parada si alguna de sus líneas pasa por alguna otra parada de las buscadas
    for (const stop of stopsFormatedData) {
      let linesRepeated = [];
      let linesNotRepeated = [];
      if (stop?.lines) {
        for (const lineOfStop of stop?.lines) {
          //comprueba si el id de la línea es el mismo que en el caso de las otras paradas
          if (
            lineOfStop?.headsigns?.length === 1 && stopsFormatedData?.find((element) => {
              if (stop?.id === element?.id) {
                return false;
              }

              return element?.lines?.find((line) => line.id === lineOfStop.id);
            })
          ) {
            linesRepeated.push({id: lineOfStop?.id, headsign: lineOfStop?.headsigns[0]});
          } else {
            linesNotRepeated.push({id: lineOfStop?.id});
          }
        }
      }

      stopsCheckHeadSigns.push({
        ...stop,
        ...{ linesRepeated: linesRepeated, linesNotRepeated: linesNotRepeated }
      });
    }
    stopsFormatedData = stopsCheckHeadSigns;

    for (let element in props.cluster.points) {
      if (props.cluster.points[element]?.agencyOriginId) {
        let icon = await VM.icons.getIcon(
          props.cluster.points[element].agencyOriginId,
          'dataOrigin'
        );
        let linesNotRepeated = stopsFormatedData.find(
          (stop) => stop.id === props.cluster.points[element].id
        )?.linesNotRepeated;
        let linesRepeated = stopsFormatedData.find(
          (stop) => stop.id === props.cluster.points[element].id
        )?.linesRepeated;
        let lines = stopsFormatedData.find(
          (stop) => stop.id === props.cluster.points[element].id
        )?.lines;
        let linestimes = stopsFormatedData.find(
          (stop) => stop.id === props.cluster.points[element].id
        )?.linestimes;

        let linesDiv = [];
        stops.push(
          <div
            key={'stops_' + element}
            onClick={() => props.setMarkerSelected(props.cluster.points[element])}>
            <div style={{ ...styles.row, marginTop: 10, cursor: 'pointer' }}>
              <img style={{ ...styles.icon }} src={icon} />
              <div>
                <div style={{ ...styles.secondaryText, color: 'black' }}>
                  {props.cluster.points[element].name}
                </div>
                <div style={styles.secondaryText}>{props.cluster.points[element].name}</div>
              </div>
            </div>
          </div>
        );

        for (let line of linesNotRepeated) {
          let tripId;
          /* for (let linestime in linestimes) {
            if (linestimes[linestime].lineId === linesNotRepeated[line]) {
              tripId = linestimes[linestime].tripId;
              break;
            }
          } */
          let dataOri = VM.lines.data[line.id]?.agencyOriginId ? dataOrigin.find((element) => element.id === VM.lines.data[line.id]?.agencyOriginId) : undefined;
          if (dataOri && !(dataOri?.label && dataOri?.label?.toLowerCase()?.includes('renfe media distancia'))) {
            linesDiv.push(
              <LineIcon
                key={element + 'line_' + line.id}
                style={{ marginRight: 10, padding: 5, marginTop: 5 }}
                text={VM.lines.data[line.id]?.code}
                textColor={'#' + VM.lines.data[line.id]?.routeTextColor}
                onClick={() => {
                  //PASS HEADSIGN or tripId
                  /* let selectedLine = lines[line].id;
                  VM.lines.setSelected(lines[line].id, tripId, lines[line].code);
  
                  dispatch(
                    eventsModalstoggleVisibilitySlice.actions.toggleVisibilityReportLineModalWidget({
                      line: selectedLine
                    })
                  ); */
                }}
                color={VM.lines.data[line.id]?.routeColor ? '#' + VM.lines.data[line.id]?.routeColor : theme.colors.main}
              />
            );
          }
        }

        stops.push(
          <div key={'linesDiv' + element} style={{ ...styles.row, marginLeft: 35 }}>
            {linesDiv}
          </div>
        );

        //caso de paradas con sentido por estar repetidas
        let linesRepeatedDiv = [];

        for (let line of linesRepeated) {
          let tripId;
          /* for (let linestime in linestimes) {
            if (linestimes[linestime].lineId === linesRepeated[line]) {
              tripId = linestimes[linestime].tripId;
              break;
            }
          } */

          linesRepeatedDiv.push(
            <div key={element + 'line_' + line.id} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
              <LineIcon
                style={{ marginRight: 10}}
                text={VM.lines.data[line.id]?.code}
                textColor={'#' + VM.lines.data[line.id]?.routeTextColor}
                onClick={() => {
                  //PASS HEADSIGN or tripId
                  /* let selectedLine = lines[line].id;
                  VM.lines.setSelected(lines[line].id, tripId, lines[line].code);

                  dispatch(
                    eventsModalstoggleVisibilitySlice.actions.toggleVisibilityReportLineModalWidget({
                      line: selectedLine
                    })
                  ); */
                }}
                color={VM.lines.data[line.id]?.routeColor ? '#' + VM.lines.data[line.id]?.routeColor : theme.colors.main}
              />
              <label style={{...styles.headsign, ...{wordWrap: 'break-word', textTransform: 'capitalize'}}}>
                {line.headsign}
              </label>
            </div>
          );
        }

        stops.push(
          <div key={'linesRepeatedDiv' + element} style={{ ...styles.row, marginLeft: 35 }}>
            {linesRepeatedDiv}
          </div>
        );  

        stops.push(<div key={'separator' + element} style={styles.separator} />);
      } else if (props.cluster.points[element]?.poiOriginId) {
        let icon = await VM.icons.getIcon(props.cluster.points[element].categoryId, 'category');
        pois.push(
          <div
            key={'pois_' + element}
            onClick={() => props.setMarkerSelected(props.cluster.points[element])}>
            <div style={{ ...styles.row, marginTop: 10, cursor: 'pointer' }}>
              <img style={{ ...styles.icon }} src={icon} />
              <div>
                <div style={{ ...styles.secondaryText, color: 'black' }}>
                  {props.cluster.points[element].name}
                </div>
                <div style={styles.secondaryText}>{props.cluster.points[element].address}</div>
              </div>
            </div>
          </div>
        );
        pois.push(<div key={'separator' + element} style={styles.separator} />);
      }
    }
    return (
      <div>
        {stops.length ? <div style={styles.mainText}>{t('stops')}</div> : null}
        {stops}
        {pois.length ? <div style={styles.mainText}>{t('pois')}</div> : null}
        {pois}
      </div>
    );
  };

  const styles = {
    content: {
      position: 'absolute',
      display: 'flex',
      padding: 12,
      marginInline: !responsive.isBig ? '25px' : '105px',
      marginTop: '10px',
      width: '80vw',
      maxWidth: '345px',
      minWidth: '232px',
      flexDirection: 'column',
      background: theme.colors.white,
      boxShadow: '0px 4px 4px rgba(28, 31, 32, 0.1)',
      borderRadius: '12px'
    },
    subContent: {
      paddingInline: '12px',
      textAlign: 'left',
      alignContent: 'center'
    },
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      flexWrap: 'wrap',
      alignContent: 'center'
    },
    separator: {
      borderBottomColor: theme.colors.neutralBorder,
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      width: '100%',
      alignSelf: 'center',
      marginTop: props.margin ?? '8px'
    },
    icon: {
      marginRight: 10,
      padding: 5,
      width: 15,
      height: 15,
      borderRadius: 5,
      fill: theme.colors.black,
      background: theme.colors.border
    },
    secondaryText: {
      color: theme.colors.textSecondary,
      fontSize: 13,
      fontWeight: 500
    },
    mainText: {
      color: theme.colors.textPrimary,
      fontSize: 15,
      fontWeight: 600,
      marginTop: 5
    },
    headsign: {
      fontSize: 13,
      fontWeight: 500,
      color: theme.colors.textSecondary,
    }
  };

  return (
    <div style={styles.content}>
      <CloseIcon
        style={{ cursor: 'pointer', marginLeft: 'auto' }}
        fill={theme.colors.black}
        onClick={() => props.onClose()}
      />

      <div style={styles.subContent}>
        <div style={styles.row}>
          <Icon style={{ ...styles.icon }} />
          <div style={styles.secondaryText}>{t('markersGroup')}</div>
        </div>
        <div>
          {renderCluster ?? (
            <div style={{ marginTop: 10 }}>
              <ClipLoader size={20} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
