import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountInformation, authSlice } from '../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';

import { useTranslate } from '../../../context/languageContext';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import ButtonNext from '../../commons/buttons/ButtonNext';
import SimpleModal from '../../commons/Modals/SimpleModal';
import TitleH1 from '../../commons/Titles/TitleH1';
import InputName from '../../inputs/InputName';
import LinkLogin from '../../links/LinkLogin';

export default function PersonalInformationModalWidget(props: any) {
  const theme = useTheme();
  const t = useTranslate();
  const Close = R.drawables.commons.close;
  const styles = {
    header: {
      width: '100%',
      textAlign: 'left',
      paddingTop: '10.3%'
    },
    close: {
      marginLeft: 'auto',
      marginRight: '20px',
      marginTop: '20px',
      position: 'absolute',
      right: 0,
      top: 0,
      height: 10.65,
      width: 10.71
    },
    title: {
      fontSize: '22px',
      margin: 0,
      color: '#ffffff',
      paddingBottom: '1.3%'
    },
    p: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: ' 140%',
      color: ' #FFFFFF',
      textAlign: 'left',
      margin: 0
    },
    body: {
      width: '100%'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '11.3%'
    },
    label: {
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '20px',
      color: '#FFFFFF',
      paddingLeft: '25px'
    },
    container: {
      display: 'flex',
      flexDirection: 'column'
    },
    footer: {
      marginBottom: '9.7%'
    },
    overlay: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 5,
      backgroundColor: '#181B1D',
      opacity: 0.2
    }
  } as const;

  const dispatch = useDispatch();
  const account = useSelector(accountInformation);

  useEffect(() => {
    dispatch(authSlice.actions.updateRangoEdad(''));
    dispatch(authSlice.actions.updateIdGender(''));
    dispatch(authSlice.actions.updateEmail(''));
    dispatch(authSlice.actions.updateAlias(''));
  }, []);
  return (
    <>
      <div
        style={styles.overlay}
        onClick={() => {
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
              false
            )
          );
          dispatch(authSlice.actions.updateRangoEdad(''));
          dispatch(authSlice.actions.updateIdGender(''));
          dispatch(authSlice.actions.updateEmail(''));
          dispatch(authSlice.actions.updateAlias(''));
        }}
      />
      <SimpleModal
        style={{
          width: '65%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        header={
          <div style={styles.header}>
            <Close
              style={styles.close}
              fill={theme.colors.white}
              onClick={() => {
                dispatch(
                  eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                    false
                  )
                );
                dispatch(authSlice.actions.updateRangoEdad(''));
                dispatch(authSlice.actions.updateIdGender(''));
                dispatch(authSlice.actions.updateEmail(''));
                dispatch(authSlice.actions.updateAlias(''));
              }}
            />
            <TitleH1 style={styles.title} title={t('personalInfo')} />
            <p style={styles.p}>{t('meetABit')}</p>
          </div>
        }
        body={
          <div style={styles.body}>
            <form
              style={styles.form}
              onSubmit={(e) => {
                dispatch(
                  eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                    false
                  )
                );
                dispatch(
                  eventsModalstoggleVisibilitySlice.actions.toggleVisibilitySingUpModalWidget(true)
                );
              }}>
              <div style={styles.container}>
                <InputName
                  className="modal-input"
                  placeholder={t('alias')}
                  value={account.alias}
                  setValue={(e: any) => {
                    dispatch(authSlice.actions.updateAlias(e));
                  }}
                />
              </div>
              <div style={styles.container} className="container-selector">
                <select
                  name="select"
                  className="selector"
                  value={account.rangoEdad ?? ''}
                  onChange={(e) => {
                    dispatch(authSlice.actions.updateRangoEdad(e.target.value));
                  }}>
                  <option disabled value="">
                    {t('age')}
                  </option>
                  <option value="entre 0 y 19">{t('ageUnder19')}</option>
                  <option value="entre 20 y 45">{t('age20to45')}</option>
                  <option value="entre 45 y 65">{t('age45to65')}</option>
                  <option value="entre 65 y más">{t('ageOver65')}</option>
                </select>
              </div>
              <div style={styles.container} className="container-selector pd-bottom-60">
                <select
                  name="select"
                  onChange={(e) => {
                    dispatch(authSlice.actions.updateIdGender(parseInt(e.target.value)));
                  }}
                  defaultValue={account.idGender ?? ''}
                  className="selector">
                  <option disabled value="">
                    {t('gender')}
                  </option>
                  <option value="1">{t('woman')}</option>
                  <option value="2">{t('man')}</option>
                  <option value="3">{t('noBinary')}</option>
                  <option value="4">{t('another')}</option>
                </select>
              </div>
              <div style={styles.container}>
                <ButtonNext
                  disabled={!account.alias || !account.rangoEdad || !account.idGender}
                  className="btn-secondary"
                />
              </div>
            </form>
          </div>
        }
        footer={
          <div style={styles.footer}>
            <LinkLogin />
          </div>
        }
      />
    </>
  );
}
