import React, {useRef} from 'react'
import {Marker, Popup, Tooltip} from 'react-leaflet'
import L from 'leaflet'

export default function LeafLetMarker(props) {
  const size = {x: props.x ?? 25, y: props.y ?? 25 };

  const createIcon = url => {
    return new L.icon({
        iconUrl: url,
        zIndex:props.zIndex,
        options: {
          objectFit:'cover',
        },
        iconSize: size,
        iconAnchor: props.iconAnchor ?? [10, 30],
    })
  }

  return (
    !props.isDisabled && props.position?.lat && props.position?.lng && props.url
    ?<Marker
        position={props.position}
        draggable={props.draggable}
        icon={createIcon(props.url)}
        //Revisar añadir onClick y onDrag
        eventHandlers={{
            click: (event) => {setTimeout(()=>event.target.openPopup(),500);props?.onClick()},
            dragend: props?.onDragEnd}}>
            {props.title && !props.selected
                ? <Tooltip direction="top" offset={[5, -25]}>{props.title}</Tooltip>
                : null
            }
            {props.title && props.selected
                ? <Popup offset={[5, -20]} closeButton={false}>{props.title}</Popup>
                : null
            }
    </Marker>
    :null
  )
}
