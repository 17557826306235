import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { searchInformation } from '../../../../../redux/slices/searchSlice';
import { VM } from '../../../../../viewmodel/VM';
import ButtonIcon from '../../../../components/commons/ButtonIcon';
import { useTranslate } from '../../../../context/languageContext';
import { useTheme } from '../../../../context/themeContext';
import { R } from '../../../../R';
import LineIcon from '../searcherOption/stopDetail/LineIcon';
import { agencyInformation } from '../../../../../redux/slices/agencySlice';
import LoadMoreStopsResults from './LoadMoreStopsResults';

export default function StopsList(props: any, ref: any) {
  const theme = useTheme();
  const t = useTranslate();
  const [renderStops, setRenderStops] = useState<any>([]);
  const dataOrigin = useSelector(agencyInformation).dataOrigin;

  const stops: any = useSelector(searchInformation).stops;

  useEffect(() => {
    async function fetchData() {
      setRenderStops(await getRenderStops());
    }
    fetchData();
  }, [stops]);

  const styles: any = {
    stops: {
      display: 'flex',
      cursor: 'pointer'
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 0.5,
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px'
    },
    button: {
      marginRight: '10px'
    },
    text: {
      fontSize: '14px',
      fontWeight: 600,
      color: theme.colors.black
    },
    subText: {
      fontSize: '13px',
      color: theme.colors.mediumGray
    },
    row: {
      display: 'flex',
      flexDirection: 'column'
    },
    rowWrap: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center',
      flexWrap: 'wrap'
    },
    headsign: {
      fontSize: 13,
      fontWeight: 500,
      color: theme.colors.textSecondary,
      wordWrap: 'break-word',
      textTransform: 'capitalize'
    }
  };

  const renderLines = (lines: any) => {
    let res = [];
    for (let line of lines) {
      if (!VM.lines.data[line.id]?.code) {
      } else {
        let dataOri: any = dataOrigin.find(
          (element: any) => element.id === VM.lines.data[line.id]?.agencyOriginId
        );
        if (
          dataOri &&
          !(
            (
              dataOri?.label && dataOri?.label?.toLowerCase()?.includes('renfe media distancia')
            ) /*  || dataOri?.label === 'Origen GTFS de RENFE Cercanias' */
          )
        ) {
          res.push(
            <LineIcon
              key={line.id}
              style={{ marginRight: 10, padding: 5, marginTop: 5 }}
              text={VM.lines.data[line.id]?.code}
              textColor={'#' + VM.lines.data[line.id]?.routeTextColor}
              onClick={() => {}}
              color={
                VM.lines.data[line.id]?.routeColor
                  ? '#' + VM.lines.data[line.id]?.routeColor
                  : theme.colors.main
              }
            />
          );
        }
      }
    }

    return res;
  };

  const renderLinesWithHeadSign = (lines: any) => {
    let res = [];
    for (let line of lines) {
      if (!VM.lines.data[line.id]?.code) {
      } else {
        res.push(
          <div
            key={line.id}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
            <LineIcon
              style={{ marginRight: 10 }}
              text={VM.lines.data[line.id]?.code}
              textColor={'#' + VM.lines.data[line.id]?.routeTextColor}
              onClick={() => {}}
              color={
                VM.lines.data[line.id]?.routeColor
                  ? '#' + VM.lines.data[line.id]?.routeColor
                  : theme.colors.main
              }
            />
            <label style={styles.headsign}>{line.headsign}</label>
          </div>
        );
      }
    }

    return res;
  };

  const getRenderStops = async () => {
    let result = [];
    for (let stop in stops) {
      let filtered = JSON.parse(JSON.stringify(Array.from(VM.dataOrigin.noMap))).find(
        ({ id }: { id: number }) => id === stops[stop]?.agencyOriginId
      );

      let icon;
      if (VM.dataOrigin.data[VM.stops.map[stops[stop]?.id]?.agencyOriginId]?.icon)
        icon = await VM.icons.getIcon(
          VM.dataOrigin.data[VM.stops.map[stops[stop]?.id]?.agencyOriginId]?.icon
        );
      else {
        let origin: any = dataOrigin?.find((element: any) => element.id === stops[stop]?.agencyOriginId)
        if (origin) {
          icon = await VM.icons.getIcon(origin?.agencyId, 'agency');
        } else {
          icon = await VM.icons.getIcon(filtered?.agencyId, 'agency');
        }
        
      }
      result.push(
        <div
          style={styles.stops}
          key={stop}
          onClick={() => {
            props.resultClick(stops[stop]);
            setRenderStops([]);
          }}>
          <ButtonIcon
            style={styles.button}
            icon={icon ?? R.drawables.general.Ic_Circled_Question}
            iconSize={25}
            background={theme.colors.border}
            size={'35px'}
          />
          <div style={styles.row}>
            <div style={styles.text}>{stops[stop].name}</div>
            {/*<div style={styles.subText}>{stops[stop].desc}</div>*/}
            {stops[stop]?.linesNotRepeated && (
              <div style={styles.rowWrap}>{renderLines(stops[stop].linesNotRepeated)}</div>
            )}
            {stops[stop]?.linesRepeated && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {renderLinesWithHeadSign(stops[stop].linesRepeated)}
              </div>
            )}
          </div>
        </div>
      );
      result.push(<div key={`separator${stop}`} style={styles.separator} />);
    }
    result.pop();
    return result;
  };

  return (
    <div style={props.style}>
      {props.searching ? (
        <div key={'title'} style={{ ...styles.text, marginBottom: 15 }}>
          {t('stopsAndStations')}
        </div>
      ) : renderStops?.length ? (
        <div key={'title'} style={{ ...styles.text, marginBottom: 15 }}>
          {t('stopsAndStations')}
        </div>
      ) : null}
      {props.searching ? <ClipLoader /> : renderStops?.length ? renderStops : null}
      {!props.searching && renderStops?.length ? (
        <LoadMoreStopsResults text={props.text} searching={props.searching} />
      ) : null}
    </div>
  );
}
