import ViewModel from '../@base/ViewModel';
import {Apis} from "../../domain/Apis";

export default class PoisViewModel extends ViewModel {
  map;
  constructor() {
    super();
  }

  async get(language, latitude, longitude) {
    this.noFiltered=await Apis.pois.getPois(language, latitude, longitude)
    if(this.noFiltered?.length && this.noFiltered[0]?.id){
      this.map={};
      for(let i = 0; i<this.noFiltered.length; i++){
        this.map[this.noFiltered[i].id]=this.noFiltered[i]
      }
    }
    return super.notify(this.noFiltered, true);
  }
}
