import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  favoritesInformation,
  filterButton
} from '../../../../../../../redux/slices/favoritesSlice';
import { filtersInformation, filtersSlice } from '../../../../../../../redux/slices/filtersSlice';
import FilterUtils from '../../../../../../../utils/FilterUtils';
import { VM } from '../../../../../../../viewmodel/VM';
import TransportButton from '../atoms/TransportButton';
import {markersInformation, markersSlice} from "../../../../../../../redux/slices/markersSlice";
import { agencyInformation } from '../../../../../../../redux/slices/agencySlice';

export default function OperatorsGroupFilters(props) {
  const [renderMyTransportGroup, setRenderMyTransportGroup] = useState(null);
  const agencyIds = useSelector(filtersInformation).operators;
  const [selected, setSelected] = useState(JSON.parse(JSON.stringify(agencyIds)));
  const dispatch = useDispatch();
  const filters = useSelector(filtersInformation);
  const agencyInfo = useSelector(agencyInformation);
  const agencyFilters  = [...agencyInfo.agenciesFilters];

  let all = [];
  useEffect(() => {
    async function fetchData() {
      setSelected(JSON.parse(JSON.stringify(agencyIds)));
    }
    fetchData();
  }, [agencyIds]);

  useEffect(() => {
    async function fetchData() {
      setRenderMyTransportGroup(await getRenderMyTransportGroup());
    }
    fetchData();
  }, [selected]);

  const addRemoveSelected = (agencyId) => {
    let aux = JSON.parse(JSON.stringify(selected));
    let transportSelected = JSON.parse(JSON.stringify(filters.operators));
    if (aux.indexOf(agencyId) < 0) {
      aux.push(agencyId);
      dispatch(
        filtersSlice.actions.updateOperators(
          transportSelected.concat(aux.filter((x) => !transportSelected.includes(x)))
        )
      );
    } else {
      aux.splice(aux.indexOf(agencyId), 1);
      dispatch(
        filtersSlice.actions.updateOperators(
            transportSelected.filter((x) => aux.includes(x)))
      );
    }

    setSelected(aux);
  };

  const addRemoveAll = () => {
    let aux = JSON.parse(JSON.stringify(selected));
    let transportSelected = JSON.parse(JSON.stringify(filters.operators));

    if (JSON.stringify(aux.sort()) != JSON.stringify(all.sort())) {
      aux = all;
      dispatch(
        filtersSlice.actions.updateOperators(
          transportSelected.concat(aux.filter((x) => !transportSelected.includes(x)))
        )
      );
    } else {
      aux = [];
      dispatch(
        filtersSlice.actions.updateOperators(
            transportSelected.filter((x) => !selected.includes(x)))
      );
    }
    setSelected(aux);
  };

  function setSelectedFilter(mode, agencyId) {
    let copySelected = [...selected];
    let indexFind = selected.findIndex((element) => String(element) === String(agencyId));

    //gestión en caso de pertenecer a un grupo
    let agenciesByMode = agencyFilters.find(
      (element) => String(element.id) === String(mode)
    );

    let agencyGrouped = agenciesByMode?.agencies?.find(
      (element) =>
        String(element?.agencyOriginId) === String(agencyId) && element?.agencyIdsGroup?.length > 0
    );

    if (agencyGrouped) {
      if (indexFind === -1) {
        //añadir
        agencyGrouped.agencyIdsGroup.forEach((element) => {
          copySelected.push(element);
        });
      } else {
        //eliminar
        agencyGrouped.agencyIdsGroup.forEach((element) => {
          let indexAgencyOfGroup = copySelected.findIndex(
            (agencyGroupId) => String(agencyGroupId) === String(element)
          );
          if (indexAgencyOfGroup !== -1) {
            copySelected.splice(indexAgencyOfGroup, 1);
          }
        });
      }
    } else {
      if (indexFind === -1) {
        //añadir
        copySelected.push(agencyId);
      } else {
        //eliminar
        copySelected.splice(indexFind, 1);
      }
    }
    //props.setSelectedTransports(copySelected);
    setSelected(copySelected);
    dispatch(
      filtersSlice.actions.updateOperators(
        copySelected)
    );
  }

  //todas las agencias de un modo
  function setSelectedAllMode(modeId, isSelected) {

    if (isSelected) {
      let copyFavoriteAgencies = [...selected];

      let foundMode = agencyFilters.find(
        (element) => String(element.id) === String(modeId)
      );
      let allAgencies = foundMode?.agencies
        ? JSON.parse(JSON.stringify(foundMode?.agencies))
        : [];
      //borrado de las agencias incluidas
      for (let i = 0; i < allAgencies.length; i++) {
        let indexAgency = copyFavoriteAgencies.findIndex(
          (element) => String(element) === String(allAgencies[i]?.agencyOriginId)
        );

        //gestión en caso de ser un grupo
        if (allAgencies[i]?.agencyIdsGroup?.length > 0) {
          //se recorre el array de ids de la agencia del grupo y se elimina cada uno de los que haya
          allAgencies[i]?.agencyIdsGroup.forEach((element) => {
            let indexInAgencyFavsGrouped = copyFavoriteAgencies.findIndex(
              (agencyIdFav) => agencyIdFav === element
            );
            if (indexInAgencyFavsGrouped !== -1) {
              copyFavoriteAgencies.splice(indexInAgencyFavsGrouped, 1);
            }
          });
        } else {
          if (indexAgency !== -1) {
            copyFavoriteAgencies.splice(indexAgency, 1);
          }
        }
      }

      setSelected(copyFavoriteAgencies);
      dispatch(
        filtersSlice.actions.updateOperators(
          copyFavoriteAgencies)
      );
    } else {
      //si no lo encuentra obtiene todas las agencias de ese modo
      let newsAgencies = [...selected];
      let foundMode = agencyFilters.find(
        (element) => element.id === modeId
      );
      let allAgencies = foundMode?.agencies
        ? JSON.parse(JSON.stringify(foundMode?.agencies))
        : [];
      allAgencies.shift();
      for (let i = 0; i < allAgencies.length; i++) {
        //gestión en caso de ser un grupo
        if (allAgencies[i]?.agencyIdsGroup?.length > 0) {
          allAgencies[i]?.agencyIdsGroup.forEach((element) => {
            //en caso de no encontrarse añadido
            if (!selected.find((agencyId) => agencyId === element)) {
              newsAgencies.push(element);
            }
          });
        } else {
          if (
            !selected.find(
              (agencyId) => agencyId === allAgencies[i].agencyOriginId
            )
          ) {
            newsAgencies.push(allAgencies[i]?.agencyOriginId);
          }
        }
      }
      setSelected(newsAgencies);
      dispatch(
        filtersSlice.actions.updateOperators(
          newsAgencies)
      );
    }
  }

  const getRenderMyTransportGroup = async () => {
    let res = [];
    let agencies = [];
    all = [];

    let agenciesByMode = JSON.parse(
      JSON.stringify(
        agencyFilters.find((element) => element?.id === props.mode.id)
      )
    );
    let filtered = agenciesByMode?.agencies;

    for (let operator in filtered) {
      if (filtered[operator].id !== 0) {
        let agencyIcon = await VM.icons.getIcon(filtered[operator].agencyId, 'agency');
        if(!agencyIcon)
          agencyIcon = await VM.icons.getIcon(filtered[operator].icon);

        let isGrouped = filtered[operator]?.agencyIdsGroup?.length > 0;
        let hasSelected;

        if (isGrouped) {
          //busca si se ha seleccionado algun elemento sino lo pondrá a deseleccionado
          hasSelected =
            filtered[operator]?.agencyIdsGroup?.find((idAgencyOfGroup) =>
              selected?.find((agency) => agency === idAgencyOfGroup)
            ) !== undefined;
        } else {
          hasSelected = true;
        }

        if (
          selected?.find(
            (agency) => agency === filtered[operator].agencyOriginId
          ) &&
          hasSelected
        ) {
        } else {
          hasSelected = false;
        }

        agencies.push(
          <TransportButton
            key={'operator_' + operator}
            selected={hasSelected}
            style={{ width: '45px', marginInline: 8 }}
            icon={agencyIcon}
            onClick={() => setSelectedFilter(props.mode.id, filtered[operator].agencyOriginId)}
            name={filtered[operator]?.shortName}
          />
        );
        agencies.sort(function (a, b) {
          if (a.props.name.toLowerCase() > b.props.name.toLowerCase()) return 1;
          if (a.props.name.toLowerCase() < b.props.name.toLowerCase()) return -1;
          return 0;
        });
      }
    }

    let transportIcon = await VM.icons.getIcon(props?.mode?.iconId);

    //comprobar seleccionado del modo de transporte
    let areAllSelected = false;
    let copyAgencies = JSON.parse(JSON.stringify(agenciesByMode?.agencies));
    copyAgencies.shift();
    let elementsFiltered = copyAgencies?.filter((element) =>
        selected.find((agency) => String(agency) === String(element?.agencyOriginId))
    );

    if (elementsFiltered.length === copyAgencies?.length &&
        elementsFiltered.length !== 0) {
            areAllSelected = true;  
    }

    res.push(
      <div
        className={'row'}
        style={{ alignItems: 'normal', flexWrap: 'wrap' }}
        key={'mode_' + props?.mode?.id}>
        <TransportButton
          style={{ width: '45px', marginInline: 8 }}
          icon={transportIcon}
          selected={areAllSelected}
          onClick={() => setSelectedAllMode(props?.mode?.id, areAllSelected)}
          name={props?.mode?.label}
        />
        {agencies}
      </div>
    );

    res.push(<div key={'divider'} className={'divider'} />);
    return res;
  };

  return <div>{renderMyTransportGroup}</div>;
}
