import Api from '../@base/Api';

export default class CategoriesApi extends Api {
    constructor(url) {
        super(url);
    }

    getCategories(language) {
        return super.get(`/poi/category?locale=${language}`);
    }

    getCategory(language, id) {
        return super.get(`/poi/category/${id}?locale=${language}`);
    }

}
