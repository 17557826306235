import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {VM} from "../../viewmodel/VM";
import {useDispatch, useSelector} from "react-redux";
import {accountInformation, authSlice} from "../../redux/slices/authSlice";
import {useGetSystemMessagesMutation} from "../../redux/login/services/MessagesServiceApi";
import {useUpdateLocaleMutation} from "../../redux/login/services/authServiceApi";

/**
 * Contexto para manejar literales en toda la aplicación
 */

const LanguageContext = createContext();
const SetLanguageContext = createContext();
const TranslateContext = createContext();

const strings = {
    es: require('../../res/values/strings/es').strings,
    en: require('../../res/values/strings/en').strings,
    eu: require('../../res/values/strings/eu').strings,
    fr: require('../../res/values/strings/fr').strings,
};

let first=true;

function getDefault() {
    if(localStorage.getItem("language"))
        return localStorage.getItem("language")
    /* switch(window.navigator.language.substring(0,2)){
        case 'es' : return 'es';
        case 'en' : return 'en';
        case 'eu' : return 'eu';
        case 'fr' : return 'fr';
        default: return 'en'; ;
    } */
    return 'eu';
    
}

function translate(key, language) {
    return strings[language][key] || `${language}:${key}`;
}

function getLanguageId(language) {
    switch (language) {
        case 'es': return 1;
        case 'eu': return 2;
        case 'en': return 3;
        case 'fr': return 4;
        default : return 1
    }
}

export function LanguageProvider({children}) {
    const accountInfo = useSelector(accountInformation);
    const [language, setLanguage] = useState(accountInfo.languageLocale ?? getDefault());
    const dispatch = useDispatch()
    const [updateLocal] = useUpdateLocaleMutation();
    const t = useCallback(key => translate(key, language), [language]);

    useEffect(() => {
        async function fetchData() {
            await updateLocal({
                "languageId": getLanguageId(language)
            })
        }
        if(accountInfo.languageLocale && !first) {
            fetchData()
            dispatch(authSlice.actions.updateLocale({
                "languageLocale": language,
                "locale": language,
                "languageId": getLanguageId(language)
            }))
        }
        if(!first)
            localStorage.removeItem("recents")
        first=false
        localStorage.setItem("language", language)
    },[language]);

    return (
        <LanguageContext.Provider value={language}>
            <SetLanguageContext.Provider value={setLanguage}>
                <TranslateContext.Provider value={t}>
                    {children}
                </TranslateContext.Provider>
            </SetLanguageContext.Provider>
        </LanguageContext.Provider>
    );
}

export function useLanguage() {
    return useContext(LanguageContext);
}

export function useSetLanguage() {
    return useContext(SetLanguageContext);
}

export function useTranslate() {
    return useContext(TranslateContext);
}
