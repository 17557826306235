export default function TitleH1(props: any) {
  const styles = {
    h1: {
      color: props.color ? props.color : '#FFFFFF',
      fontFamily: 'Open Sans',
      marginRight: 'auto',
      fontWeight: '700',
      fontSize: '22px'
    }
  } as const;

  return (
    <h1 style={props.style ? props.style : styles.h1}>
      {props.title ? props.title : 'Lorem Ipsum is simply dummy text'}
    </h1>
  );
}
