import React, { useState } from 'react';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import { Collapse } from 'react-collapse/lib/Collapse';
import { useTranslate } from '../../../../../context/languageContext';
import LinesNearMarker from '../linesNear/LinesNearMarker';

export default function StationCollapse(props, ref) {
  const theme = useTheme();
  const Up = R.drawables.commons.up;
  const Down = R.drawables.commons.down;
  const Phone = R.drawables.commons.phone;
  const Web = R.drawables.commons.web;
  const [open, setOpen] = useState(false);
  const t = useTranslate();

  const styles = {
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center'
    },
    icon: {
      marginRight: 7.5,
      width: '15px'
    },
    mainText: {
      fontSize: 13,
      fontWeight: 600,
      color: theme.colors.mainText,
      marginTop: 10
    },
    secondaryText: {
      color: theme.colors.textSecondary,
      fontSize: 13,
      marginTop: 10,
      marginLeft: 'auto',
      fontWeight: 600
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginBottom: 10,
      width: '100%'
    }
  };
  return (
    <div style={{ marginTop: 10 }}>
      <div style={styles.separator} />

      <div style={{ ...styles.row, marginBottom: 10 }}>
        <div style={{ ...styles.row, cursor: 'pointer' }} onClick={() => setOpen(!open)}>
          {open ? (
            <Up style={styles.icon} fill={theme.colors.black} onClick={() => setOpen(!open)} />
          ) : (
            <Down style={styles.icon} fill={theme.colors.black} onClick={() => setOpen(!open)} />
          )}
          {open ? (
            <div style={{ ...styles.secondaryText, marginTop: 0 }}>{t('lessInfo')}</div>
          ) : (
            <div style={{ ...styles.secondaryText, marginTop: 0 }}>{t('moreInfo')}</div>
          )}
        </div>
      </div>
      <Collapse isOpened={open}>
        <div style={styles.separator} />
        {props.station?.contactPhone ? (
          <div style={styles.row}>
            <Phone
              style={{ ...styles.icon, width: 20, marginTop: 10 }}
              fill={theme.colors.black}
              onClick={() => setOpen(!open)}
            />
            <div style={styles.mainText}>{t('phone')}</div>
            <div style={styles.secondaryText}>{props.station.contactPhone}</div>
          </div>
        ) : null}
        {props.web ? (
          <div style={styles.row}>
            <Web
              style={{ ...styles.icon, width: 20, marginTop: 10 }}
              fill={theme.colors.black}
              onClick={() => setOpen(!open)}
            />
            <div style={styles.mainText}>{t('website')}</div>
            <a href={props.web} target="_blank" style={styles.secondaryText}>
              {props.web}
            </a>
          </div>
        ) : null}
        {/* {props.station ? (
          <div style={{ flex: 1, overflowY: 'scroll', marginTop: 8 }}>
            <LinesNearMarker
              latitude={props.station.latitude}
              longitude={props.station.longitude}
              title={t('lines_near_marker')}
            />
          </div>
        ) : null} */}
      </Collapse>
    </div>
  );
}
