import { createContext, useContext, useState } from 'react';

const LoadingContext = createContext();
const SetLoadingContext = createContext();

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={loading}>
      <SetLoadingContext.Provider value={setLoading}>{children}</SetLoadingContext.Provider>
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  return useContext(LoadingContext);
}

export function useSetLoading() {
  return useContext(SetLoadingContext);
}
