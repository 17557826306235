import { useEffect, useState } from 'react';

export default function useWindowSize() {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [screenSizeFull, setScreenSizeFull] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setScreenSizeFull({
        width: window.screen.width,
        height: window.screen.height,
      });
    };

    updateScreenSize();

    window.addEventListener('resize', updateScreenSize);

    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return {
    screenSize,
    screenSizeFull,
  };
}
