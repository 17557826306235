import { useDispatch, useSelector } from 'react-redux';
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation
} from '../../../../../../redux/login/favorite/FavoriteServiceApi';
import { accountInformation } from '../../../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import {
  favoritesInformation,
  favoritesSlice
} from '../../../../../../redux/slices/favoritesSlice';
import GeneralUtils from '../../../../../../utils/GeneralUtils';
import RouteUtils from '../../../../../../utils/RouteUtils';
import { VM } from '../../../../../../viewmodel/VM';
import Button from '../../../../../components/commons/Button';
import ButtonIcon from '../../../../../components/commons/ButtonIcon';
import DefaultModal from '../../../../../components/widget/Modals/DefaultModal';
import { useTranslate } from '../../../../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../../../../context/modalContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import {subscriptionsInformation, subscriptionsSlice} from "../../../../../../redux/slices/subscriptionsSlice";
import {
  useAddSubscriptionsMutation,
  useDeleteSubscriptionsMutation
} from "../../../../../../redux/login/subscriptions/SubscriptionsServiceApi";

export default function StationMidInfo(props) {
  const theme = useTheme();
  const t = useTranslate();

  const dispatch = useDispatch();
  const favorites = useSelector(favoritesInformation);

  const [addFavorite] = useAddFavoriteMutation();
  const [removeFavorite] = useDeleteFavoriteMutation();
  const [addSubscription] = useAddSubscriptionsMutation();
  const [deleteSubscription] = useDeleteSubscriptionsMutation();
  const setChild = useSetModalChild();
  const setOpen = useSetModalOpened();
  const account = useSelector(accountInformation);

  const subscriptions = useSelector(subscriptionsInformation);

  const styles = {
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center'
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginTop: 10,
      marginBottom: 10,
      width: '100%'
    }
  };

  const goThere = () => {
    let coords = { latitude: props.station.latitude, longitude: props.station.longitude };
    let name = props.station?.alias ?? props.station?.name;
    let info = RouteUtils.getTypeId(props.station, favorites);
    let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };
    VM.plannerSegments.set('destination', res);
  };

  const onShare = () => {
    let path = window.location.href.split('/');
    path.pop();
    path = path.join('/');
    let copy = `${path[path.length - 1] === '#' ? '' : '/#'}/station?${GeneralUtils.convertToHex(`id=${props.station.id}`
    )}`;
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityShareModalWidget({
        showModal: true,
        shareLink: path + copy,
        title: props.station.name
      })
    );
    // setTimeout(ReactTooltip.hide, 2000);
    // navigator.clipboard.writeText(path + copy);
  };

  const updateFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    if (updateFavorites.stops.indexOf(props.station.id) < 0) {
      if (account.idUserType === 1) {
        setOpen(true);
        setChild(
            <DefaultModal
                title={t('loginToFav')}
                description={t('loginToFavBody')}
                alter={true}
                firstButton={t('createAccount')}
                secondButton={t('login')}
                onClickFirst={() => {
                  dispatch(
                      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                          true
                      )
                  );
                }}
                onClickSecond={() => {
                  dispatch(
                      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
                  );
                }}
            />
        );
      } else {
        updateFavorites.stops.push(props.station.id);
        addFavorite({
          stops: [props.station.id],
          pois: [],
          plans: [],
          directions: []
        });
        dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
        dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
              showModal: true,
              background: '#00763D',
              text: t('stopSaved2')
            })
        );
      }
    } else if (updateFavorites.stops.indexOf(props.station.id) >= 0) {
      setOpen(true);
      setChild(
          <DefaultModal
              title={t('deleteFavConfirm')}
              onClickSecond={() => {
                updateFavorites.stops.splice(updateFavorites.stops.indexOf(props.station.id), 1);
                removeFavorite({
                  stops: [props.station.id],
                  pois: [],
                  plans: [],
                  directions: []
                });
                dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
                dispatch(
                    eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
                        {
                          showModal: true,
                          background: '#00763D',
                          text: t('stopRemoved')
                        }
                    )
                );
              }}
          />
      );
    } else {
      setOpen(true);
      setChild(
          <DefaultModal
              title={t('deleteFavConfirm')}
              onClickSecond={() => {
                updateFavorites.stops.splice(updateFavorites.stops.indexOf(props.stop.id), 1);
                removeFavorite({
                  stops: [props.station.id],
                  pois: [],
                  plans: [],
                  directions: []
                });
                dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
                dispatch(
                    eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
                        {
                          showModal: true,
                          background: '#00763D',
                          text: t('stopRemoved')
                        }
                    )
                );
              }}
          />
      );
    }
  };

  return (
    <div>
      <div style={styles.separator} />
      <div style={{ ...styles.row }}>
        <Button
          icon={R.drawables.map.goThere}
          text={t('goThere')}
          iconRight={true}
          textColor={theme.colors.darkGray}
          iconSize={10}
          iconColor={theme.colors.darkGray}
          borderColor={theme.colors.border}
          onClick={goThere}
        />
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          {/* <ReactTooltip event="focusin" effect={'solid'} id="main" /> */}
          <ButtonIcon
            style={{ marginLeft: 10 }}
            onClick={() => onShare()}
            icon={R.drawables.commons.share}
            iconSize={15}
            dataTip={t('copyUrl')}
            dataFor={'main'}
            iconColor={theme.colors.darkGray}
            borderColor={theme.colors.border}
            background={theme.colors.white}
          />

          <ButtonIcon
              onClick={() => updateFav()}
              style={{ marginLeft: 10 }}
              iconColor={theme.colors.darkGray}
              iconSize={15}
              icon={
                favorites.stops.indexOf(props?.station?.id) <= 0
                    ? R.drawables.general.Ic_favorite
                    : R.drawables.general.Ic_favorite_blue
              }
              background={theme.colors.white}
          />
        </div>
      </div>
    </div>
  );
}
