import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAddFavoriteMutation, useEditFiltersNewsAlertsMutation } from '../../../../redux/login/favorite/FavoriteServiceApi';
import { accountInformation } from '../../../../redux/slices/authSlice';
import { favFilterNewsAlertsState, favoritesInformation, favoritesSlice, selectAllFilterNewsAlertsState } from '../../../../redux/slices/favoritesSlice';
import { VM } from '../../../../viewmodel/VM';
import Button from '../../../components/commons/Button';
import AccountAnonymousInlineComponent from '../../../components/widget/Inline/AccountAnonymousInlineComponent';
import {useLanguage, useTranslate} from '../../../context/languageContext';
import AccountInfoScreen from './AccountInfoScreen';
import MyTransportGroup from './MyTransportGroup';
import {useTheme} from "../../../context/themeContext";
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { agencyInformation } from '../../../../redux/slices/agencySlice';
import { useFavoriteUtils } from '../../../../utils/FavoriteUtils';

export default function MyTransportScreen(props: any) {
  const t = useTranslate();
  const [renderMyTransport, setRenderMyTransport] = useState(null as any);
  const [transportSelected, setTransportSelected] = useState([]);
  const favorites = useSelector(favoritesInformation);
  const [addFavorite] = useAddFavoriteMutation();
  const account = useSelector(accountInformation);
  const theme = useTheme()
  const dispatch = useDispatch();
  const agencyInfo = useSelector(agencyInformation);
  const favFilterNewsAlerts = useSelector(favFilterNewsAlertsState);
  const allSelectedFilterNewsAlerts = useSelector(selectAllFilterNewsAlertsState);
  const {selectAgenciesToFilterByFav} = useFavoriteUtils();
  const [editFiltersNewsAlerts] = useEditFiltersNewsAlertsMutation();

  useEffect(() => {
    let areNotDeleted = favorites.agencyId.filter((element: any) =>
      agencyInfo.dataOrigin.find(
        (origin: any) => String(origin.id) === String(element),
      ),
    );

    setTransportSelected(areNotDeleted);
  }, [favorites.agencyId, agencyInfo.dataOrigin]);

  useEffect(() => {
    async function fetchData() {
      setRenderMyTransport(await getRenderMyTransport());
    }
    fetchData();
  }, [transportSelected]);

  const getRenderMyTransport = async () => {
    let res = [];
    if (!Object.keys(VM.dataOrigin.data)?.length) await VM.dataOrigin.get(useLanguage());
    if (!Object.keys(VM.agencies.data)?.length) await VM.agencies.get('es');
    let transports = VM.modes?.data as any;
    for (let mode in transports) {
      res.push(
        <MyTransportGroup
          key={mode}
          mode={transports[mode]}
          setTransportSelected={setTransportSelected}
          transportSelected={transportSelected}
        />
      );
    }
    return res;
  };

  const updateAgenciesFav = async () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    updateFavorites.agencyId = transportSelected;
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true)
    );
    await addFavorite({
      stops: [],
      pois: [],
      plans: [],
      directions: [],
      agencyId: transportSelected
    });
    if (favFilterNewsAlerts && !allSelectedFilterNewsAlerts && transportSelected?.length) {
      //gestión caso de añadir o borrar una agencia de favoritos
      await editFiltersNewsAlerts({
        favSelected: true,
        allSelected: false,
        agencies: selectAgenciesToFilterByFav(true),
      });
    }
    dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false)
    );
  };

  return (
    <div /* className="account-child content" */ style={{overflowY: 'scroll', backgroundColor: 'white'}} >
      <div className="titleAccount">{t('myTransport')}</div>
      {account.idUserType === 1 ? (
        <AccountAnonymousInlineComponent />
      ) : (
        <div className="content-myTransport">
          <div className={'column'}>
            <div className="description marginBottom10">{t('descriptionMyTransport')}</div>
            {renderMyTransport}
            <div className={'row'}>
              <Button
                className={'paddingInLine20 paddingVertical10'}
                textColor={theme.colors.white}
                background={theme.colors.mediumDark}
                text={t('save')}
                onClick={() => updateAgenciesFav()}
              />
              <Button
                text={t('remove')}
                onClick={() => {
                  setTransportSelected([]);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
