import React, {useState} from "react";
import {useTheme} from "../../context/themeContext";
import {R} from "../../R";
import {Collapse} from "react-collapse/lib/Collapse";

export default function Alert(props, ref) {
    const theme = useTheme();
    const AlertIcon = R.drawables.commons.exclaimation
    const Up = R.drawables.commons.up
    const Down = R.drawables.commons.down

    const [open, setOpen] = useState(false)

    const styles={
        content: {
            background: theme.colors.contextualWarningLight,
            borderRadius: "10px",
            alignItems:'center',
            textAlign:'left',
            fontSize: '13px',
            fontWeight:800,
            padding:'10px',
        },
        header: {
            display:'flex',
            alignItems: 'center',
            textTransform:'capitalize',
            cursor:'pointer'
        },
        arrow:{
            fill:theme.colors.black,
            marginLeft:'auto',
            paddingLeft:5,
            width:40
        }
    }

    return (
        <div style={props.style}>
            <div style={styles.content}>
                <div style={styles.header} onClick={()=>setOpen(!open)}>
                    <AlertIcon style={{marginRight:5, width:40}}/>
                    {props.title}
                    {open
                        ?<Up style={styles.arrow} />
                        :<Down style={styles.arrow}/>
                    }
                </div>
                <Collapse isOpened={open}>
                    <div dangerouslySetInnerHTML={{ __html: props?.description }} />
                </Collapse>
            </div>
        </div>
    );
}
