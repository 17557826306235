import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filtersInformation, filtersSlice } from '../../../../../../../redux/slices/filtersSlice';
import FilterUtils from '../../../../../../../utils/FilterUtils';
import { VM } from '../../../../../../../viewmodel/VM';
import Button from '../../../../../../components/commons/Button';
import { useLanguage, useTranslate } from '../../../../../../context/languageContext';
import { useTheme } from '../../../../../../context/themeContext';
import { R } from '../../../../../../R';
import CheckBox from '../../../../../../components/commons/CheckBox';
import { markersInformation, markersSlice } from '../../../../../../../redux/slices/markersSlice';

export default function PoisGroupFilters() {
  const [categories, setCategories] = useState([]);
  const theme = useTheme();
  const t = useTranslate();
  const language = useLanguage();
  const filterCategories = useSelector(filtersInformation).categories;
  const dispatch = useDispatch();
  const markersInfo = useSelector(markersInformation);
  const loadMarkersPois = markersInfo.loadedTypes.pois;

  const [renderPoisGroup, setRenderPoisGroup] = useState();

  useEffect(() => {
    async function fetchData() {
      setCategories(await VM.categories.get(language));
    }
    fetchData();
  }, [language]);

  useEffect(() => {
    async function fetchData() {
      setRenderPoisGroup(await getRenderPoisGroup());
    }
    fetchData();
  }, [filterCategories, categories]);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    content: {
      display: 'flex',
      alignContent: 'flex-start',
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      //alignContent: 'center',
    },
    label: {
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 8,
    }
  };

  const changeSelected = (value) => {
    let updateFiltersCategories = JSON.parse(JSON.stringify(filterCategories));
    if (updateFiltersCategories.indexOf(value) < 0) updateFiltersCategories.push(value);
    else updateFiltersCategories.splice(updateFiltersCategories.indexOf(value), 1);
    dispatch(filtersSlice.actions.updateCategories(updateFiltersCategories));

    FilterUtils.filterByCategories(updateFiltersCategories);
  };

  const getRenderPoisGroup = async () => {
    let res = [];
    for (let category in categories) {
      let icon = await VM.icons.getIcon(categories[category].iconId);
      res.push(
        <Button
          style={{ margin: 2.5 }}
          key={categories[category].id}
          selected={filterCategories.indexOf(categories[category].id) >= 0}
          text={categories[category].name}
          icon={icon ?? R.drawables.general.Ic_close}
          iconColor={theme.colors.black}
          textColor={theme.colors.black}
          borderColor={theme.colors.border}
          onClick={() => changeSelected(categories[category].id)}
          iconSize={'13px'}
        />
      );
    }
    return res;
  };

  return (
    <div style={styles.content}>
      <div style={styles.row}>
        <CheckBox
          checked={!loadMarkersPois}
          onSelected={() => dispatch(markersSlice.actions.updateLoadedTypePois(!loadMarkersPois))}
          onUnselected={() => dispatch(markersSlice.actions.updateLoadedTypePois(!loadMarkersPois))}
        />
        <label style={styles.label}>{t('visualizer_filter_markers_pois')}</label>
      </div>
      
      <div style={styles.container}>{renderPoisGroup}</div>
    </div>
  );
}
