import { useDispatch } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useTranslate } from '../../../context/languageContext';
import { R } from '../../../R';

export default function AccountAnonymousInlineComponent() {
  const IconInfo = R.drawables.general.Ic_Circled_Info;
  const dispatch = useDispatch();
  const t = useTranslate();
  const goToLogIn = () => {
    dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true));
  };
  const goToRegister = () => {
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(true)
    );
  };

  return (
    <div className="account-info-anonymous">
      <div className="account-anonymous-notification">
        <IconInfo className="icon-info" />
        <p>{t('loginToFavBody')}</p>
      </div>
      <div className="account-anonymous-buttons">
        <a onClick={() => goToLogIn()}>{t('login')}</a>
        <a onClick={() => goToRegister()}>{t('createAccount')}</a>
      </div>
    </div>
  );
}
