import React, { useEffect, useState } from 'react';
import Dropdown from '../../../components/commons/dropdown/Dropdown';
import { VM } from '../../../../viewmodel/VM';
import { useSelector } from 'react-redux';
import { agencyInformation } from '../../../../redux/slices/agencySlice';
import { useTranslate } from '../../../context/languageContext';

export default function AgencyLinesSelector({
  agencySelected,
  setAgencySelected
}: {
  agencySelected?: number;
  setAgencySelected: Function;
}) {
  const [agenciesTransportFilt, setAgenciesTransportFilt] = useState<Array<any>>([]);
  const agencyState = useSelector(agencyInformation);
  const t = useTranslate();
  

  useEffect(() => {
    let copyAgenciesFilters = JSON.parse(JSON.stringify(agencyState.agenciesFilters));
    let agenciesTransport: Array<any> = [];
    copyAgenciesFilters.forEach((transportModeAg: any) => {
      if (transportModeAg.id !== 5) {
        transportModeAg.agencies.shift();
        let filterDuplicates: Array<number> = [];
  
        agenciesTransport.forEach((agency: any) => {
          let indexFound = transportModeAg.agencies.findIndex(
            (transAg: any) => transAg.id === agency.id
          );
          if (indexFound !== -1) {
            filterDuplicates.push(indexFound);
          }
        });
        if (filterDuplicates?.length) {
          filterDuplicates.forEach((indexToDel: number) => {
            transportModeAg.agencies.splice(indexToDel, 1);
          });
        }
  
        agenciesTransport = agenciesTransport.concat(transportModeAg.agencies);
      }
    });
  
    let foundMediaDistancia = agenciesTransport.findIndex((agTransp: any) => {
      let dataOri: any = agencyState.dataOrigin.find((element: any) => element.id === agTransp.agencyOriginId);
      if (dataOri && (dataOri?.label && dataOri?.label?.toLowerCase()?.includes('renfe media distancia'))) {
        return true;
      }
      return false;
    })
    if (foundMediaDistancia !== -1) {
      agenciesTransport.splice(foundMediaDistancia, 1);
    }
    setAgenciesTransportFilt(agenciesTransport);
  }, [agencyState.agenciesFilters,agencyState.dataOrigin])
  
  
  useEffect(() => {
    async function obatainIcons() {
      agenciesTransportFilt.forEach(async (agency) => {
            let icon = await VM.icons.getIcon(agency?.icon);
        })
    }
    agenciesTransportFilt?.length && obatainIcons();
  }, [agenciesTransportFilt]);

  const selectorOperatorDiv = document.getElementById("selectorOperator");

  return (
    <div style={{ zIndex: 10, width: '100%', marginBottom: 8, backgroundColor: 'white', borderRadius: 8 }} id={'selectorOperator'}>
      <Dropdown
        placeholder={t('select_operator_placeholder')}
        styleCollapse={selectorOperatorDiv?.offsetWidth ? {minWidth: selectorOperatorDiv?.offsetWidth - 18} : {}}
        items={agenciesTransportFilt
          .sort(function (a, b) {
            let nameA = a?.longName?.toLowerCase();
            let nameB = b?.longName?.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          .map((element: any) => {
            //let icon = await VM.icons.getIcon(element?.icons?.iconId);
            return {
              value: element.id,
              label: element.longName,
              selected: element.id === agencySelected,
              iconId: element?.icon,
              iconType: 'dynamic',
              onChange: () => {
                setAgencySelected(element?.agencyOriginId); 
                //setShowMenuCollapse(false);
              }
            };
          })}
      />
    </div>
  );
}
