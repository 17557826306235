import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';

export default function SimpleModal(props: any) {
  const theme = useTheme();

  const Close = R.drawables.commons.close;

  const styles = {
    content: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      // width: 'max-content',
      height: 'auto',
      transition: 'all 100ms linear 0s',
      background: props.background ? props.background : '#005891',
      flexDirection: 'column',
      boxSizing: 'border-box',
      maxWidth: '575px',
      minWidth: '300px',
      // height: '33%',

      // minHeight: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '16px',
      // padding: '0px 80px 40px',
      boxShadow: '0px 0px 30px rgba(24, 27, 29, 0.15)',
      zIndex: '999'
    },
    header: {
      borderRadius: '16px',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: 'auto',
      padding: '40px 0'
    },
    body: { borderRadius: '16px', margin: 'auto', width: '100%' },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    footer: {
      marginTop: 'auto',
      borderRadius: '16px',
      margin: 'auto 0px 0px',
      display: 'flex',
      marginBottom: 'auto',
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center'
    },
    forgotPassword: {
      width: 'auto',
      marginLeft: 'auto'
    },

    background: {
      // backgroundColor: theme.colors.black,
      // position: 'fixed',
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
      // width: '100%',
      // height: '100%',
      // transition: 'all 300ms linear',
      // zIndex: 1,
      // opacity: props.open ? 0.5 : 0
    },
    row: {
      // marginBottom: '30px',
      // display: 'flex',
      // alignItems: 'center'
    },
    text: {
      // fontSize: '16px',
      // fontWeight: 700
    },
    icon: {
      // cursor: 'pointer',
      // width: '18px'
    }
  } as const;

  return (
    <div style={{ ...styles.content, ...props.style }}>
      <div style={props.style ? props.style : styles.header}>{props.header}</div>
      <div style={props.style ? props.style : styles.body}>{props.body}</div>
      <div style={props.style ? props.style : styles.footer}>{props.footer}</div>
    </div>
  );
}
