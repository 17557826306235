import React, {useCallback, useEffect, useState} from "react";
import {useTheme} from "../../../../context/themeContext";
import {useLanguage, useTranslate} from "../../../../context/languageContext";
import ButtonIcon from "../../../../components/commons/ButtonIcon";
import {R} from "../../../../R";
import {VM} from "../../../../../viewmodel/VM";
import LineIcon from "../searcherOption/stopDetail/LineIcon";
import { useSelector } from "react-redux";
import { agencyInformation } from "../../../../../redux/slices/agencySlice";

export default function Recents(props, ref) {
    const theme = useTheme();
    const language = useLanguage()
    const t = useTranslate();
    const [recents, setRecents] = useState({})
    const [renderRecents, setRenderRecents] = useState();
    const dataOrigin = useSelector(agencyInformation).dataOrigin;


    useEffect(() => {
        VM.recents.get();
        setRecents(VM.recents.data)
    },[language]);

    useEffect(() => {
        async function fetchData() {
            setRenderRecents(await getRenderRecents())
        }
        fetchData()
    },[recents, props.resultClick]);

    useEffect(() => {
        VM.recents.listen(()=>setRecents(VM.recents.data));
    },[]);

    const styles={
        recents: {
            display:'flex',
            cursor: 'pointer'
        },
        separator:{
            backgroundColor: theme.colors.border,
            height: 0.5,
            width: '100%',
            marginTop:'10px',
            marginBottom:'10px'
        },
        button: {
            marginRight: '10px',
        },
        text: {
            fontSize:'14px',
            fontWeight:600,
            color:theme.colors.black,
        },
        subText: {
            fontSize:'13px',
            color:theme.colors.mediumGray
        },
        row:{
            display:'flex',
            flexDirection:'column',
        },
        rowWrap: {
          display: 'flex',
          transition: 'all 300ms linear',
          alignItems: 'center',
          alignContent: 'center',
          flexWrap: 'wrap'
        }
    }

    const renderLines = (lines) => {
        let res = [];
        for (let line in lines) {
          let dataOri = VM.dataOrigin?.data[lines[line]?.agencyOriginId]; 
          if (dataOri && !(dataOri?.label && dataOri?.label?.toLowerCase()?.includes('renfe media distancia'))) {
            res.push(
                <LineIcon
                  key={line}
                  style={{ marginRight: 10, padding: 5, marginTop: 5 }}
                  text={VM.lines.data[lines[line].id]?.code}
                  textColor={'#' + VM.lines.data[lines[line].id]?.routeTextColor}
                  onClick={() => {
                  }}
                  color={VM.lines.data[lines[line].id]?.routeColor ? '#' + VM.lines.data[lines[line].id]?.routeColor : theme.colors.main}
                />
            );
          }
        }
    
        return res;
      };

    const getRenderRecents = async () => {
        let result = []
        for (let recent in recents) {
            let icon;
            if (recents[recent]?.agencyOriginId && VM.dataOrigin.noMap?.length) {
                let filtered = JSON.parse(JSON.stringify(Array.from(VM.dataOrigin.noMap))).find(
                    ({id}) => id === recents[recent]?.agencyOriginId
                );
                if (filtered) {
                    icon = await VM.icons.getIcon(filtered?.agencyId, 'agency');
                } else {
                    let origin = dataOrigin.find((element) => element.id === recents[recent]?.agencyOriginId);
                    if (origin) {
                        icon = await VM.icons.getIcon(origin?.agencyId, 'agency');
                    }
                }
                
            }
            else if (recents[recent]?.categoryId)
                icon = await VM.icons.getIcon(recents[recent].categoryId, "category")

            result.push(
                <div key={recent} style={styles.recents} onClick={() => props.resultClick(recents[recent])}>
                    <ButtonIcon
                        style={styles.button}
                        icon={icon ?? R.drawables.general.Ic_location}
                        iconColor={theme.colors.black}
                        background={theme.colors.border}
                        iconSize={25}
                        size={'35px'}/>
                    <div style={styles.row}>
                        <div style={styles.text}>{recents[recent]?.name}</div>
                        {/*<div style={styles.subText}>{recents[recent]?.desc}</div>*/}
                        {recents[recent]?.lines && <div style={styles.rowWrap}>{renderLines(recents[recent]?.lines)}</div>}
                    </div>

                </div>
            )
            result.push(<div key={`separator${recent}`} style={styles.separator}/>);
        }
        result.pop();
        return result;
    }

    return (
        recents.constructor===Object && Object.keys(recents).length!==0
        ?<div style={props.style}>
            <div style={{...styles.text,marginBottom:15}}>{t('recents')}</div>
            {renderRecents}
        </div>
            :null
    );
}
