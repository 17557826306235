import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {eventsModalstoggleVisibilitySlice} from "../../slices/eventsModalstoggleVisibilitySlice";
import {StatusCodesServer} from "../../hooks";
import {RootState} from "../../store";
import GeneralUtils from "../../../utils/GeneralUtils";

export const SearchServiceApi = createApi({
    reducerPath: 'SearchServiceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: GeneralUtils.getEnvironment(window.location.origin),
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).authSlice.accountInformation.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: builder => ({
        searchNora: builder.mutation<any, any>({
            query: ({locale, txt}) => ({
                url: `search/nora?locale=${locale}&txt=${txt}`,
                method: 'GET',
                headers: {
                    authorization:
                        'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
                }
            }),

            async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
                try {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true)
                    );
                    await queryFulfilled;
                    StatusCodesServer(dispatch, 200);
                } catch (err: any) {
                    StatusCodesServer(dispatch, err.error.status);
                } finally {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false)
                    );
                }
            }
        }),
        searchStops: builder.mutation<any, any>({
            query: ({locale, txt, numPage}) => ({
                url: `/agency/stops?locale=${locale}&txt=${txt}&numPage=${numPage ?? 1}`,
                method: 'GET',
                headers: {
                    authorization:
                        'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
                }
            }),

            async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
                try {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true)
                    );
                    await queryFulfilled;
                    StatusCodesServer(dispatch, 200);
                } catch (err: any) {
                    StatusCodesServer(dispatch, err.error.status);
                } finally {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false)
                    );
                }
            }
        }),
        searchPois: builder.mutation<any, any>({
            query: ({locale, txt}) => ({
                url: `/search/?locale=${locale}&txt=${txt}`,
                method: 'GET',
                headers: {
                    authorization:
                        'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
                }
            }),

            async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
                try {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true)
                    );
                    await queryFulfilled;
                    StatusCodesServer(dispatch, 200);
                } catch (err: any) {
                    StatusCodesServer(dispatch, err.error.status);
                } finally {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false)
                    );
                }
            }
        }),
        searchNominatim: builder.mutation<any, any>({
            query: ({locale, txt, numPage}) => ({
                url: `search/nominatim?locale=${locale}&txt=${txt}&elementsPerPage=5&numPage=${numPage ?? 1}`,
                method: 'GET',
                headers: {
                    authorization:
                        'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
                }
            }),
            async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
                try {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true)
                    );
                    await queryFulfilled;
                    StatusCodesServer(dispatch, 200);
                } catch (err: any) {
                    StatusCodesServer(dispatch, err.error.status);
                } finally {
                    dispatch(
                        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false)
                    );
                }
            }
        }),
    }),
});

export const {
    useSearchNoraMutation,
    useSearchNominatimMutation,
    useSearchPoisMutation,
    useSearchStopsMutation
} = SearchServiceApi;
