import { useEffect, useState } from 'react';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import VehicleMidInfo from './VehicleMidInfo';
import {Apis} from "../../../../../../domain/Apis";
import {VM} from "../../../../../../viewmodel/VM";
import {useGetVehicleByIdMutation} from "../../../../../../redux/login/explore/GbfsServiceApi";
import VehicleCollapse from "./VehicleCollapse";

export default function VehicleDetail(props) {
  const theme = useTheme();
  const CloseIcon = R.drawables.general.Ic_close;
  const responsive = useResponsive();
  const language = useLanguage()
  const t = useTranslate()
  const [iconType, setIconType] = useState();
  const [vehicle, setVehicle] = useState();
  const [type, setType] = useState();
  const [useGetVehiclesById] = useGetVehicleByIdMutation();

  useEffect(() => {
    async function fetchData() {
      let dataOrigin = VM.dataOrigin.data
      if(!dataOrigin.length)
        dataOrigin = await VM.dataOrigin.get(language)

      setType(await Apis.agency?.getAgency(language, dataOrigin[props.vehicle.agencyOriginId].agencyId));
      useGetVehiclesById({id:props.vehicle.id, locale:language}).then((data) => {
        setVehicle(data.data)
      })

      setIconType(await VM.icons.getIcon(props.station.agencyOriginId, 'dataOrigin'));
    }
    fetchData();
  }, [props.station, language]);
  const styles = {
    content: {
      position: 'absolute',
      display: 'flex',
      padding: 12,
      marginInline: !responsive.isBig ? '25px' : '105px',
      marginTop: '10px',
      width: '80vw',
      maxWidth: '345px',
      minWidth: '232px',
      flexDirection: 'column',
      background: theme.colors.white,
      boxShadow: '0px 4px 4px rgba(28, 31, 32, 0.1)',
      borderRadius: '12px'
    },
    subContent: {
      paddingInline: '12px',
      textAlign: 'left',
      alignContent: 'center'
    },
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center'
    },
    icon: {
      marginRight: 10,
      padding: 5,
      width: 15,
      height: 15,
      borderRadius: 5,
      background: theme.colors.border
    },
    secondaryText: {
      color: theme.colors.textSecondary,
      fontSize: 13,
      fontWeight: 500
    },
    paramText: {
      color: theme.colors.textPrimary,
      fontSize: 13,
      fontWeight: 500,
      marginLeft:5
    },
    mainText: {
      color: theme.colors.textPrimary,
      fontSize: 15,
      fontWeight: 600,
      marginTop: 5,
      marginBottom: 10
    },
    divider: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginTop:5,
      marginBottom:5,
      width: '100%'
    }
  };
  return (
      <div style={styles.content}>
        <CloseIcon
            style={{ cursor: 'pointer', marginLeft: 'auto' }}
            fill={theme.colors.black}
            onClick={() => props.onClose()}
        />

        <div style={styles.subContent}>
          <div style={styles.row}>
            <img style={{ ...styles.icon }} src={iconType} />
            <div style={styles.secondaryText}>{type?.shortName}</div>
          </div>

          <div style={styles.mainText}>{vehicle?.id}</div>

          <div style={styles.row}>

          </div>

          <div style={styles.divider}/>

          <div style={styles.row}>

           </div>

        </div>

        <VehicleMidInfo vehicle={vehicle} />
        {type?.website ? <VehicleCollapse web={ type?.website} vehicle={vehicle} /> : null}
      </div>
  );
}
