export default class ViewModel {
    data = [];
    listeners = [];

    listen(listener) {
        let index = this.listeners.find(l => l === listener);
        if(index===undefined)
            this.listeners.push(listener);
    }

    unlisten(listener) {
        let index = this.listeners.find(l => l === listener);
        this.listeners.splice(index, 1);
    }

    notify(data,noMap) {
        // Actualiza el dato principal del ViewModel
        if(data?.length && data[0]?.id && !noMap){
            this.data={};
            for(let i = 0; i<data.length; i++){
                this.data[data[i].id]=data[i]
            }
        }
        else this.data = data;
        this.noMap=data
        // Notifica a los observadores de la vista del cambio del dato.
        this.listeners.map(listener => {
            try {
                listener?.(this.data);
            } catch (ignored) {}
        });
    return this.data
    }
}
