import Api from '../@base/Api';

export default class LinesApi extends Api {
    constructor(url) {
        super(url);
    }

    getRangeTimes(id, locale, stopId) {
        return super.get(`/agency/lines/${id}/rangeStopstime?locale=${locale}&stopId=${stopId}`);
    }

    getShape(id, tripId, stopId) {
        let trip = tripId ? `tripId=${tripId}` : `stopId=${stopId}`
        return super.get(`/agency/lines/${id}/shape?${trip}`);
    }

    get(id) {
        return super.get(`/agency/lines/${id}`);
    }

    getStopTimes(id,tripId, stopId) {
        let trip = tripId ? `tripId=${tripId}` : `stopId=${stopId}`
        return super.get(`/agency/lines/${id}/stopstime?${trip}`);
    }

    getStops(id, tripId, stopId) {
        let trip = tripId ? `tripId=${tripId}` : `stopId=${stopId}`
        return super.get(`/agency/lines/${id}/stops?${trip}`);
    }

    getLines() {
        return super.get(`/agency/lines/cache/`);
    }

    getOldLines() {
        return super.get(`/agency/lines/`);
    }

}
