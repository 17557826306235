import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';

export interface searchInformation {
    stops: Array<any>;
    pois: Array<any>;
    directions: Array<any>;
    others: Array<any>;
}

const initialState = {
    searchInformation: {
        stops: [],
        pois: [],
        directions: [],
        others: []
    },
};

export const searchSlice = createSlice({
    name: 'searchSlice',
    initialState,
    reducers: {
        updateStops: (state, action) => {
            state.searchInformation.stops = action.payload;
        },
        updatePois: (state, action) => {
            state.searchInformation.pois = action.payload;
        },
        updateDirections: (state, action) => {
            state.searchInformation.directions = action.payload;
        },
        updateOthers: (state, action) => {
            state.searchInformation.others = action.payload;
        },

        resetAll: (state) => {
            /* state.searchInformation = {
                stops: [],
                pois: [],
                directions: [],
                others: [],
            } */
            state.searchInformation.stops = [];
            state.searchInformation.others = [];

        }
    },
});

export const searchInformation = (state: RootState) => state.searchSlice.searchInformation;
