import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTheme } from '../../../../../../context/themeContext';
import { useTranslate, useLanguage } from '../../../../../../context/languageContext';
import { VM } from '../../../../../../../viewmodel/VM';
import { R } from '../../../../../../R';
import TransportButton from '../atoms/TransportButton';
import {filtersInformation, filtersSlice} from "../../../../../../../redux/slices/filtersSlice";
import {useDispatch, useSelector} from "react-redux";
import {getMotorizedModel} from "../../../../../../../viewmodel/instance/filters/FiltersModel";

export default function MotorizedFiltersGroup() {
    const t = useTranslate();
    const language = useLanguage()
    const dispatch = useDispatch()
    const [motorized, setMotorized] = useState(getMotorizedModel(language));

    useEffect(()=>{
        setMotorized(getMotorizedModel(language))
    },[language])

    const filterMotorized = useSelector(filtersInformation).motorized

    const changeSelected = value => {
        let updateFiltersMotorized = JSON.parse(JSON.stringify(filterMotorized));
        if(updateFiltersMotorized.indexOf(value)<0)
            updateFiltersMotorized.push(value)
        else updateFiltersMotorized.splice(updateFiltersMotorized.indexOf(value),1)
        dispatch(filtersSlice.actions.updateMotorized(updateFiltersMotorized));

        //VM.categoriesPoiFilters.set(categories);
    };

      const changeSelectedAll = () => {
          let updateFiltersMotorized = JSON.parse(JSON.stringify(filterMotorized));
          if(updateFiltersMotorized.length>0)
              dispatch(filtersSlice.actions.updateMotorized([]));
          else dispatch(filtersSlice.actions.updateMotorized([1,2]));
      };

    const renderButtons = useCallback(
        function () {
          let buttons = [];
          if (!motorized ||  motorized.length === 0) return buttons;
          buttons.push(
            <TransportButton
              style={{width: '45px', margin: 12}}
              key={'both'}
              name={t('visualizer_filtersBoth')}
              icon={R.drawables.general.Ic_motor}
              selected={filterMotorized.length===motorized.length}
              onClick={() => changeSelectedAll()}
            />,
          );

          for (let element in motorized) {
            buttons.push(
              <TransportButton
                style={{width: '45px', margin: 10}}
                key={motorized[element].id}
                id={motorized[element].id}
                name={motorized[element].name}
                icon={R.drawables.general[motorized[element].icon]}
                selected={filterMotorized.indexOf(motorized[element].id)>=0}
                onClick={id => changeSelected(id)}
              />,
            );
          }
          return buttons;
        },
        [changeSelected, changeSelectedAll, motorized, t],
      );

  const styles = {
      container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: "80vw",
        maxWidth: "345px",
        minWidth: "232px",
      }
  }

  return (
    <div style={styles.container}>
        {renderButtons()}
    </div>
  )
}
