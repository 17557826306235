import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authServiceApi } from '../../../../redux/login/services/authServiceApi';
import { accountInformation } from '../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { RootState } from '../../../../redux/store';
import { useTranslate } from '../../../context/languageContext';
import { useSetLoading } from '../../../context/loadingContext';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import SimpleModal from '../../commons/Modals/SimpleModal';
import TitleH1 from '../../commons/Titles/TitleH1';
import RecoveryPassModalWidget from './RecoveryPassModalWidget';
import SingUpModalWidget from './SingUpModalWidget';

export default function AnonymousAccessDeniedModalWidget(props: any) {
  const account = useSelector(accountInformation);
  const theme = useTheme();
  const Close = R.drawables.commons.close;
  const t = useTranslate();

  const styles = {
    container: {
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      maxWidth: 400,
      width: '100%',
      borderRadius: '16px'
    },
    close: {
      position: 'absolute',
      right: '4%',
      top: '4%',
      height: 10.65,
      width: 10.71
    },
    header: {
      color: theme.colors.neutralBlack,
      width: '100%',
      textAlign: 'left',
      paddingTop: 40,
      margin: '0px 24px 0px 24px'
    },
    title: {
      width: '100%',
      margin: 0,
      fontSize: 22,
      fontWeight: '700'
    },
    body: {
      margin: 24,
      width: '100%',
      textAlign: 'left'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    footer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 24,
      margin: '0px 24px 0px 24px'
    },
    overlay: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10,
      backgroundColor: '#181B1D',
      opacity: 0.2
    }
  } as const;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const setLoading = useSetLoading();
  const dispatch = useDispatch();
  const toggleVisibility = useSelector(
    (state: RootState) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );
  const [skip, setSkip] = useState(true);
  const [useLoginQuery, result, lastPromiseInfo] = authServiceApi.endpoints.login.useLazyQuery();

  async function setLogin() {
    useLoginQuery({
      email: email,
      password: password
    }).then((data: any) => {});
    if (result && result.error == null) {
    }
    setLoading(false);
  }

  const openLoginModal = () => {
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAnonymousAccessDeniedModalWidget(
        false
      )
    );
    dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true));
  };

  const openSignUpModal = () => {
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAnonymousAccessDeniedModalWidget(
        false
      )
    );
    dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilitySingUpModalWidget(true));
  };

  return (
    <>
      <div style={styles.overlay}></div>
      <SimpleModal
        style={styles.container}
        header={
          <div style={styles.header}>
            <Close
              style={styles.close}
              fill={theme.colors.neutralBlack}
              onClick={() =>
                dispatch(
                  eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAnonymousAccessDeniedModalWidget(
                    false
                  )
                )
              }
            />
            <TitleH1 style={styles.title} title={t('login2')} />
          </div>
        }
        body={
          <div style={styles.body}>
            <p>{t('loginToFavBody')}</p>
          </div>
        }
        footer={
          <div style={styles.footer}>
            <button className="btn-secondary" onClick={openSignUpModal}>
              {t('createAccount')}
            </button>
            <button className="btn-primary" onClick={openLoginModal}>
              {t('login')}
            </button>
          </div>
        }
      />

      {toggleVisibility.RecoveryPassModalWidget ? <RecoveryPassModalWidget /> : ''}

      {toggleVisibility.SingUpModalWidget ? <SingUpModalWidget /> : ''}
    </>
  );
}

// export default function AnonymousAccessDeniedModalWidget() {
//   return (
//     <div>
//       <h3>Inicia Sesión</h3>
//       <p>
//         Inicia sesión o crea una cuenta para poder acceder a todas las funcionalidades de la
//         aplicación.
//       </p>
//       <div>
//         <button>Crear cuenta</button>
//         <button>Iniciar sesión</button>
//       </div>
//     </div>
//   );
// }
