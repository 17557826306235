import React, { useEffect, useRef, useState } from 'react';
import { VM } from '../../../viewmodel/VM';
import LinesListHeader from './components/LinesListHeader';
import { useResponsive } from '../../context/responsiveContext';
import Input from '../../components/commons/Input';
import { useTranslate } from '../../context/languageContext';
import { useTheme } from '../../context/themeContext';
import LinesOperatorList from './components/LinesOperatorList';
import AgencyLinesSelector from './components/AgencyLinesSelector';

export default function LineDirectoryScreen(props: any) {
  const responsive = useResponsive();
  const inputRef = useRef();
  const theme = useTheme();
  const [searchLineText, setSearchLineText] = useState('');
  const [agencySelected, setAgencySelected] = useState<number | undefined>();

  useEffect(() => {
    VM.plannerSegments.clear();
    props.setMarkerSelected(null);
    VM.lines.setSelected(null);
  }, []);
  const t = useTranslate();

  return (
    <div className={`mapOption ${responsive.isBig ? 'big' : 'small'}`}>
      <LinesListHeader />
      <div style={{ padding: 16, backgroundColor: '#f2f2f2' }}>
        <AgencyLinesSelector
          agencySelected={agencySelected}
          setAgencySelected={setAgencySelected}
        />
        {agencySelected ? <Input
          ref={inputRef}
          background={searchLineText?.length ? theme.colors.border : theme.colors.white}
          onChange={(e: string) => {
            //search(e);
            setSearchLineText(e);
          }}
          onClear={() => {
            setSearchLineText('');
          }}
          placeholder={t('menu_lines_search')}
          notShowClear={!searchLineText.length}
        /> : null}
      </div>
      <LinesOperatorList agencySelected={agencySelected} searchLineText={searchLineText} />
    </div>
  );
}
