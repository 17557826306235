export default class TransitUtils {
  static organizeAlertsByAgencyId(alerts: Array<any>) {
    let agencies: Array<any> = [];
    
    alerts.forEach((alert: any) => {
      let index = agencies.findIndex(
        agency => agency.agencyId === alert.idAgencia,
      );
      if (index === -1) {
        let agencyAlerts: any = {
          agencyId: alert.idAgencia,
          lines: [],
          stops: [],
          transit: [],
        };

        let organizeAlert = this.organizeAlertByDateAndType(alert);

        agencyAlerts.lines = organizeAlert[0];
        agencyAlerts.stops = organizeAlert[1];
        agencyAlerts.transit = organizeAlert[2];

        agencies.push(agencyAlerts);
      } else {
        let organizeAlert = this.organizeAlertByDateAndType(alert);

        agencies[index].lines = agencies[index].lines.concat(organizeAlert[0]);
        agencies[index].stops = agencies[index].stops.concat(organizeAlert[1]);
        agencies[index].transit = agencies[index].transit.concat(
          organizeAlert[2],
        );
      }
    });

    return agencies;
  }

  static organizeAlertByDateAndType(alert: any) {
    let alertsByDate: any = [];
    if (alert?.fechas?.length === 0 || alert?.fechas === null) {
      alertsByDate.push(alert);
    }
    let alertByDate: any = JSON.parse(JSON.stringify(alert));
    alertByDate.date = alert.fechas[0];
    alertsByDate.push(alertByDate);
    /* for (let i = 0; i < alert?.fechas?.length; i++) {
      let alertByDate: any = JSON.parse(JSON.stringify(alert));
      alertByDate.date = alert.fechas[i];
      alertsByDate.push(alertByDate);
    } */

    let linesByDate = [];
    let stopsByDate = [];
    let transitByDate = [];

    for (let j = 0; j < alertsByDate.length; j++) {
      for (let k = 0; k < alertsByDate[j]?.lineas?.length; k++) {
        let lineAlert: any = JSON.parse(JSON.stringify(alertsByDate[j]));
        lineAlert.line = alertsByDate[j].lineas[k];

        linesByDate.push(lineAlert);
      }
      for (let l = 0; l < alertsByDate[j]?.paradas?.length; l++) {
        let stopAlert: any = JSON.parse(JSON.stringify(alertsByDate[j]));
        stopAlert.stop = alertsByDate[j].paradas[l];
        stopsByDate.push(stopAlert);
      }

      if (
        alertsByDate?.paradas?.length === 0 &&
        alertsByDate?.lineas?.length === 0
      ) {
        transitByDate.push(alertsByDate[j]);
      }
    }

    return [linesByDate, stopsByDate, transitByDate];
  }

  static getTransitAlertFromFavorites(alerts: any, favorites: any) {
    const favoritesStops = alerts.filter(
      (alertStop: any) =>
        favorites.findIndex(
          (favorite: any) => favorite === alertStop?.stop?.idParada,
        ) !== -1,
    );

    return favoritesStops;
  }

  static getAllDifferentStops(alertStopsAgency: Array<any>) {
    let stops: Array<any> = [];
    for (let i = 0; i < alertStopsAgency.length; i++) {
      for (let j = 0; j < alertStopsAgency[i].stops.length; j++) {
        let alertStop = alertStopsAgency[i].stops[j];
        if (stops.findIndex(stop => stop === alertStop.stop.idParada) === -1) {
          stops.push(alertStop.stop.idParada);
        }
      }
    }
    return stops;
  }

  static getAllDifferentLines(alertLinesAgency: Array<any>) {
    let lines: Array<any> = [];
    for (let i = 0; i < alertLinesAgency.length; i++) {
      for (let j = 0; j < alertLinesAgency[i].lines.length; j++) {
        let alerLine = alertLinesAgency[i].lines[j];
        if (lines.findIndex(line => line === alerLine.line.idLinea) === -1) {
          lines.push(alerLine.line.idLinea);
        }
      }
    }
    return lines;
  }

  /* static sortByDate(alerts: Array<any>) {
    return alerts.sort((alert1: any, alert2: any) => {
      return alert2.date.horaInicio - alert1.date.horaInicio
    })
  } */
}
