import React, { useState } from 'react';
import { useTranslate } from '../../../../../context/languageContext';
import { Collapse } from 'react-collapse';
import { R } from '../../../../../R';

export default function RouteDetailStopCol(props: any) {
  const t = useTranslate();
  const [opened, setOpened] = useState(false);
  const ArrowUp = R.drawables.general.Ic_ChevronUpSmall;
  const ArrowDown = R.drawables.general.Ic_ChevronDownSmall;

  const renderStops = () => {
    let res = [];
    for (let stop in props.stops) {
      res.push(
        <div className={'paddingVertical10'} key={stop}>
          {props.stops[stop].name}
        </div>
      );
    }
    return res;
  };

  return props.stops?.length ? (
    <div
      className={'columnAlign pointer'}
      style={{ alignItems: 'initial', height: '100%' }}
      onClick={() => setOpened(!opened)}>
      <div className={'row'}>
        {opened ? <ArrowUp /> : <ArrowDown />}
        <div className={'secondaryText darkGray'}>{`${props.stops.length} ${
          props.stops.length === 1 ? t('stop') : t('stops_route')
        } ·`}</div>
        <div className={'secondaryText'}>{`${props.time} ${t('minutes')}`}</div>
      </div>
      <Collapse isOpened={opened}>
        <div className={'paddingInLine20'}>{renderStops()}</div>
      </Collapse>
    </div>
  ) : (
    <div className={'columnAlign pointer'} style={{ alignItems: 'initial', height: '100%' }}>
      <div className={'row'}>
        <div className={'secondaryText'}>{`${props.time} ${props.time > 1 ? t('minutes') : t('minute')}`}</div>
      </div>
    </div>
  );
}
