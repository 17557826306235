import { useModalChild, useModalOpened, useSetModalOpened } from '../../context/modalContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';

export default function Modal(props) {
  const theme = useTheme();
  const opened = useModalOpened();
  const child = useModalChild();
  const setModalOpened = useSetModalOpened();
  const Close = R.drawables.general.Ic_close;

  const styles = {
    modal: {
      backgroundColor: theme.colors.white,
      flexDirection: 'column',
      transition: 'all 300ms linear',
      transform:
        props.open !== undefined
          ? props.open
            ? null
            : 'translateY(-20px)'
          : opened
          ? null
          : 'translateY(-20px)',
      borderRadius: '24px',
      zIndex: 5,
      minWidth: 200,
      opacity: props.open !== undefined ? (props.open ? 1 : 0) : opened ? 1 : 0
    },
    content: {
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      transition: 'all 100ms linear',
      zIndex: props.open !== undefined ? (props.open ? 6 : 0) : opened ? 50 : 0
    },
    background: {
      backgroundColor: theme.colors.black,
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      transition: 'all 300ms linear',
      zIndex: 1,
      opacity: props.open !== undefined ? (props.open ? 0.2 : 0) : opened ? 0.2 : 0
    },
    row: {
      display: 'flex',
      alignItems: 'center'
    },
    text: {
      fontSize: '16px',
      fontWeight: 700
    },
    icon: {
      cursor: 'pointer',
      display: 'flex',
      paddingInline: 20,
      paddingTop: 10,
      paddingBottom: 10
    }
  };

  return (
    <div style={styles.content}>
      <div
        style={styles.background}
        onClick={() => {
          if (props.open) {
            if (props.onClose) props.onClose();
            props.setOpen(false);
          } else setModalOpened(false);
        }}></div>
      <div style={styles.modal}>
        {props.showClose || props.showClose == undefined ? (
          <Close
            style={{ ...styles.icon, marginLeft: 'auto' }}
            fill={theme.colors.black}
            onClick={() => {
              if (props.open) {
                if (props.onClose) props.onClose();
                props.setOpen(false);
              } else setModalOpened(false);
            }}
          />
        ) : null}
        {props.child ?? child}
      </div>
    </div>
  );
}
