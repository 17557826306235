import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Apis } from '../../../../domain/Apis';
import { useGetArticleMutation } from '../../../../redux/login/news/NewsServiceApi';
import { useSearchNoraMutation } from '../../../../redux/login/search/SearchServiceApi';
import { useSharePlanMutation } from '../../../../redux/login/share/ShareServiceApi';
import { newsSlice } from '../../../../redux/slices/newsSlice';
import GeneralUtils from '../../../../utils/GeneralUtils';
import { VM } from '../../../../viewmodel/VM';
import { useLanguage, useTranslate } from '../../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../../context/modalContext';
import FavoriteScreen from '../../favoriteScreen/FavoriteScreen';
import Article from '../../newscreen/Article';
import NewsScreen from '../../newscreen/NewsScreen';
import TransitScreen from '../../transitScreen/TransitScreen';
import AddDirection from './AddDirection';
import PlannerOption from './plannerOption/PlannerOption';
import RouteDetail from './plannerOption/routeDetail/RouteDetail';
import SearcherOption from './searcherOption/SearcherOption';
import {useGetStationByIdMutation} from "../../../../redux/login/explore/GbfsServiceApi";
import FilterUtils from "../../../../utils/FilterUtils";
import LineDirectoryScreen from '../../lineDirectory/LineDirectoryScreen';
import TransitNewsScreen from '../../transitNewsScreen/TransitNewsScreen';

export default function MapOptions(props, ref) {
  const [isPlan, setIsPlan] = useState(
    Object.keys(VM.plannerSegments.data).length !== 0 ? true : false
  );
  const [screen, setScreen] = useState();
  const [planId, setPlanId] = useState();
  const [getArticle] = useGetArticleMutation();

  const [useGetStationsById] = useGetStationByIdMutation();

  const [searchNora] = useSearchNoraMutation();
  const dispatch = useDispatch();

  const language = useLanguage();
  const t = useTranslate();
  const setOpenModal = useSetModalOpened()
  const setChildModal = useSetModalChild()

  const toggleVisibility = useSelector(
    (state) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );

  useEffect(() => {
    VM.plannerSegments.listen(() => setPlanId(VM.planner.data.idPlanning));
  }, [VM.planner.data]);

  const [sharePlan] = useSharePlanMutation();

  useEffect(() => {
    VM.screenSelected.listen(() => setScreen(VM.screenSelected.data));
    VM.plannerSegments.listen(() => {
      if (Object.keys(VM.plannerSegments.data).length !== 0) setIsPlan(true);
      else setIsPlan(false);
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        let path = window.location.hash.split('/');
        path = path[path.length - 1];
        let params = path.split('?');
        let parse = GeneralUtils.convertFromHex(params[1]);
        let keys = new URLSearchParams(parse);
        //TODO: hacer la llamada al principio del componente funcional o cambiar query a una mutacion

        switch (params[0]) {
          case 'container': {
            let res=[]
            if(keys.get('type')==='stop'){
              let data = VM.stops.data
              if(!data)
                data = await VM.stops.get(language,null,null)
              res = FilterUtils.generateClusterMarker(data, 14)
            }
            else if(keys.get('type')==='poi'){
              let data = VM.pois.data
              if(!data)
                data = await VM.pois.get(language,null,null)
              res = FilterUtils.generateClusterMarker(data, 14)
            }

            res = res.filter((x) => keys.get('id')===x.id)
            props.onClickMarker(res[0]);
            break;
          }
          case 'station': {
            useGetStationsById({id:keys.get('id'), locale:language}).then((data) => {
              props.onClickMarker(data.data, null, 16.5);
            })
          }
          case 'stop': {
            if(!VM.stops.data?.length)
              await VM.stops.get(language)
            let marker = await Apis.stops.getStop(language, keys.get('id'));
            props.onClickMarker(marker);
            break;
          }
          case 'poi': {
            let marker = await Apis.pois.getPoi(language, keys.get('id'));
            props.onClickMarker(marker);
            break;
          }
          case 'direction': {
            searchNora({ locale: language, txt: keys.get('coords') }).then((data) => {
              let updateDirection = JSON.parse(JSON.stringify(data.data[0]));
              if (
                updateDirection.via &&
                updateDirection.via !== 'null' &&
                updateDirection.street &&
                updateDirection.street !== 'null'
              ) {
                updateDirection.name = `${updateDirection.via} ${updateDirection.street}`;
              } else {
                updateDirection.name = updateDirection.municipality;
              }
              if (
                updateDirection.portal !== 0 &&
                updateDirection.street &&
                updateDirection.street !== 'null'
              ) {
                updateDirection.direction = `${updateDirection.street} ${updateDirection.portal}, ${
                  updateDirection.locality ?? updateDirection.municipality
                }`;
              } else {
                updateDirection.direction =
                  updateDirection.locality ?? updateDirection.municipality;
              }

              updateDirection.typeId = 1;
              props.onClickMarker(updateDirection);
            });
            break;
          }
          case 'line': {
            await VM.lines.setSelected(keys.get('id'), keys.get('tripId'), keys.get('stopId'));
            break;
          }
          case 'news': {
            VM.screenSelected.setSelected('transit_news');
            let idArticle = keys.get('id');
            const result = await getArticle({locale: language, id: idArticle});
            if (result?.data) {
              dispatch(newsSlice.actions.updateArticle(result?.data))
              setChildModal(<Article article={result?.data}/>)
              setOpenModal(true)
            }
            break;
          }
          case 'plan': {
            let planPoints = {};
            VM.plannerSegments.clear();
            setIsPlan(true);
            VM.plannerSegments.set('origin',null)
            async function getFromApi() {
              await sharePlan({ planId: keys.get('id') }).then((data) => {
                if ('data' in data) {
                  planPoints = data?.data?.planPoints;
                }
              });
              for (let point in planPoints) {
                let ps=''
                if(planPoints[point]?.stopId) {
                  ps = await Apis.stops.getStop(language, planPoints[point]?.stopId);
                  if (ps?.error) {
                    ps = {name: planPoints[point]?.latitude.toFixed(6) + ', ' + planPoints[point]?.longitude.toFixed(6)};
                  }
                } else if(planPoints[point]?.poiId){
                  ps = await Apis.pois.getPoi(language, planPoints[point]?.poiId);
                  if (ps?.error) {
                    ps = {name: planPoints[point]?.latitude.toFixed(6) + ', ' + planPoints[point]?.longitude.toFixed(6)};
                  }
                }
                let name = planPoints[point]?.name ?? planPoints[point]?.address ? planPoints[point]?.address : planPoints[point]?.stopId
                        ? ps?.name
                        : planPoints[point]?.poiId
                            ? ps?.name
                            : planPoints[point]?.latitude.toFixed(6) +
                            ', ' +
                            planPoints[point]?.longitude.toFixed(6);

                if (parseInt(point) === 0) {
                  VM.plannerSegments.set('origin', {
                    name: name,
                    coords: {
                      latitude: planPoints[point].latitude,
                      longitude: planPoints[point].longitude
                    }
                  });
                } else if (parseInt(point) === planPoints.length - 1) {
                  VM.plannerSegments.set('destination', {
                    name: name,
                    coords: {
                      latitude: planPoints[point]?.latitude,
                      longitude: planPoints[point]?.longitude
                    }
                  });
                } else {
                  VM.plannerSegments.set(parseInt(point) - 1, {
                    name: name,
                    coords: {
                      latitude: planPoints[point]?.latitude,
                      longitude: planPoints[point]?.longitude
                    }
                  });
                }
              }
            }
            getFromApi();
            VM.screenSelected.setSelected('explore');
            break;
          }
          default:
            break;
        }
      } catch (e) {
      }
    }
    fetchData();
  }, [window.location]);

  const styles = {
    content: {
      position: 'absolute',
      width: 'auto',
      zIndex: 5
    }
  };

  function renderScreenOfMain() {
    if (screen === 'transit_news') {
      //return (<TransitScreen setMarkerSelected={props.setMarkerSelected} />);
      return (<TransitNewsScreen/>)
    } else if (screen === 'favorites') {
      return (<FavoriteScreen setMarkerSelected={props.setMarkerSelected} />);
    } else if (screen === 'news') {
      return (<TransitNewsScreen />);
    } else if (screen === 'transit') {
      return (<TransitNewsScreen />);
    } else if (isPlan) {
      if (toggleVisibility.RouteDetailModalWidget.showModal) {
        return (<RouteDetail />);
      } else {
        return (<PlannerOption
          setShowSecond={props.setShowSecond}
          setOpen={props.setOpen}
          onClickMarker={props.onClickMarker}
        />);
      }
    } else if (screen === 'lines') {
      return (<LineDirectoryScreen
        setMarkerSelected={props.setMarkerSelected}
      />)
    } else {
      return (<SearcherOption
          showSecond={props.showSecond}
          setShowSecond={props.setShowSecond}
          setOpen={props.setOpen}
          markerSelected={props.markerSelected}
          setMarkerSelected={props.setMarkerSelected}
          onClickMarker={props.onClickMarker}
        />);
    }
  }

  return (
    <div style={styles.content}>
      {toggleVisibility.AddDirectionModalWidget.showModal ? (
        <AddDirection
          setMarkerSelected={props.setMarkerSelected}
          onClickMarker={props.onClickMarker}
        />
      ) : (renderScreenOfMain())}
    </div>
  );
}
