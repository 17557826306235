import { useTheme } from '../../../context/themeContext';

export default function Toast(props: any) {
  const theme = useTheme();
  const styles = {
    toast: {
      background: props.background ? props.background : 'lightblue',
      position: 'absolute',
      bottom: 32,
      left: '50%',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      padding: 24,
      color: '#fff',
      borderRadius: 8
    },
    icon: {
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 12,
      width: 12
    },
    toastText: {
      margin: 0,
      lineHeight: '150%',
      fontSize: 16
    }
  };

  return (
    <div className="transform-translate" style={{ ...styles.toast, ...props.style }}>
      <p style={styles.icon}>{props.icon ? props.icon : 'icon'}</p>
      <p style={styles.toastText}>{props.text ? props.text : 'Toast message'}</p>
    </div>
  );
}
