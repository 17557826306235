import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface accountInformation {
  rangoEdad: string;
  idGender: number;
  email: string;
  password: string;
  password2: string;
  alias: string;
  locale: string;
  languageLocale: string;
  idUserType: number;
  typeDeviceId: number;
  tokenDevice: string;
}

const initialState = {
  accountInformation: {
    rangoEdad: null, // 'entre 0 y 19',
    idGender: null, // 1,
    email: null, // 'cesar.cardona@inetum.com',
    password: null, // 'Intranet1.',
    password2: null, // 'Intranet1.',
    languageLocale: (function token() {
      const userFromLocalStorage = JSON.parse(localStorage.getItem('accountInformation') || '{}');
      return userFromLocalStorage.languageLocale;
    })(),
    languageId: (function token() {
      const userFromLocalStorage = JSON.parse(localStorage.getItem('accountInformation') || '{}');
      return userFromLocalStorage.languageId;
    })(),
    alias: null, // 'cesar.cardona',
    locale: navigator.language.split('-')[1], // navigator.language.split('-')[1],
    idUserType: (function idUserType() {
      const userFromLocalStorage = JSON.parse(localStorage.getItem('accountInformation') || '{}');
      return userFromLocalStorage.idUserType ?? 1;
    })(), // 1 anonymous, //2 registred
    typeDeviceId: process.env.REACT_APP_typeDeviceId, // 0,
    tokenDevice: (function token() {
      const userFromLocalStorage = JSON.parse(localStorage.getItem('accountInformation') || '{}');
      return userFromLocalStorage.tokenDevice;
    })(),
    token: (function token() {
      const userFromLocalStorage = JSON.parse(localStorage.getItem('accountInformation') || '{}');
      return userFromLocalStorage.token;
    })()
  },
  useUserMeQuerySkip: true
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setCredentials: (state, action?) => {
      if (action.payload != undefined) {
        state.accountInformation = { ...state.accountInformation, ...action.payload };
        localStorage.setItem('accountInformation', JSON.stringify(state.accountInformation));
      }
    },
    setUserMe: (state, action?) => {
      if (action.payload != undefined) {
        state.accountInformation = { ...state.accountInformation, ...action.payload };
        localStorage.setItem('accountInformation', JSON.stringify(state.accountInformation));
      }
    },
    updateEmail: (state, action) => {
      state.accountInformation.email = action.payload;
    },
    updateAlias: (state, action) => {
      state.accountInformation.alias = action.payload;
    },
    updatIdUserType: (state, action) => {
      state.accountInformation.idUserType = action.payload;
      localStorage.setItem('accountInformation', JSON.stringify(state.accountInformation));
    },
    updatePassword: (state, action) => {
      state.accountInformation.password = action.payload;
    },
    updatePassword2: (state, action) => {
      state.accountInformation.password2 = action.payload;
    },
    updateRangoEdad: (state, action) => {
      state.accountInformation.rangoEdad = action.payload;
    },
    updateIdGender: (state, action) => {
      state.accountInformation.idGender = action.payload;
    },
    updateTokenDevice: (state, action) => {
      state.accountInformation.tokenDevice = action.payload;
    },
    updateUseUserMeQuerySkip: (state, action) => {
      state.useUserMeQuerySkip = action.payload;
    },
    updateLocale: (state, action) => {
      state.accountInformation.languageLocale = action.payload.languageLocale;
      state.accountInformation.languageId = action.payload.languageId;
      state.accountInformation.locale = action.payload.locale;
      localStorage.setItem('accountInformation', JSON.stringify(state.accountInformation));
    }
  }
});

export const accountInformation = (state: RootState) => state.authSlice.accountInformation;
export const useUserMeQuerySkip = (state: RootState) => state.authSlice.useUserMeQuerySkip;
