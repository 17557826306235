import ClipLoader from 'react-spinners/ClipLoader';
import TimeUtils from '../../../../../../utils/TimeUtils';
import { VM } from '../../../../../../viewmodel/VM';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useTheme } from '../../../../../context/themeContext';
import LineIcon from './LineIcon';
import { useCalculateRealTimeLinesStop } from '../../../../../../redux/hooks';

export default function NextStops(props) {
  const theme = useTheme();
  const t = useTranslate();
  const language = useLanguage();
  const { calculateTime } = useCalculateRealTimeLinesStop();

  const styles = {
    content: {
      padding: 10,
      textAlign: 'left',
      //overflow: 'scroll',
      backgroundColor: theme.colors.backgroundLight
    },
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center',
      cursor: 'pointer'
    },
    time: {
      color: theme.colors.textSecondary,
      fontSize: 14,
      fontWeight: 600,
      marginLeft: 'auto'
    },
    direction: {
      color: theme.colors.textSecondary,
      fontSize: 14,
      fontWeight: 400
    },
    realTime: {
      fontSize: 12,
      fontWeight: 'bold'
    },
    mainText: {
      color: theme.colors.textPrimary,
      fontSize: 16,
      fontWeight: 600,
      marginTop: 5,
      marginBottom: 15
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 0.5,
      width: '100%',
      marginTop: 10,
      marginBottom: 10
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      marginTop: 20,
      marginBottom: 10
    }
  };

  const getDay = (day) => {
    if (TimeUtils.getDate(day) === TimeUtils.getDate(new Date())) return t('today');
    else if (day.getDay() === new Date().getDay() + 1 || day.getDate() === new Date().getDate() + 1)
      return t('tomorrow');
    else return TimeUtils.getDate(day, language);
  };

  const renderStops = () => {
    let res = [];
    let dj = TimeUtils.convertFromJulianToDate(props.linestimes[0]?.day);
    res.push(
      <div key={'nextStops'} style={styles.mainText}>
        {t('nextStops') + ' ' + getDay(dj).toLowerCase()}
      </div>
    );
    //console.log('Resal', props.realTimes);
    let filteredByDay = props.linestimes?.filter(
      (lineTime) => lineTime?.day === props.linestimes[0]?.day
    );
    
    for (let linestime = 0; linestime < filteredByDay.length; linestime++) {
      const lineRealTimes = props.realTimes.filter(
        (realLineTime) =>
          parseInt(realLineTime.tripId) === parseInt(filteredByDay[linestime].tripId) &&
          parseInt(props.linestimes[0]?.day) === parseInt(realLineTime?.day)
      );
      let lineRealTime = lineRealTimes.sort((a, b) => {
        let timeA = a.time;
        let timeB = b.time;
        if (timeA < timeB) {
          return 1;
        }
        if (timeA > timeB) {
          return -1;
        }

        // names must be equal
        return 0;
      })[0];
      if (filteredByDay[linestime]) {
        let dj = TimeUtils.convertFromJulianToDate(filteredByDay[linestime]?.day);
        if (filteredByDay[linestime]?.time < filteredByDay[linestime - 1]?.time)
          res.push(
            <div key={'tomorrow_' + linestime} style={styles.mainText}>
              {getDay(dj)}
            </div>
          );
        res.push(
          <div
            key={'linestime_' + linestime}
            style={styles.row}
            onClick={() => {
              VM.lines.setSelected(
                filteredByDay[linestime].lineId,
                filteredByDay[linestime].tripId,
                props.stop?.id,
                filteredByDay[linestime].day
              );
            }}>
            <LineIcon
              style={{ marginRight: 5 }}
              alert={VM.lines.data[filteredByDay[linestime]?.lineId]?.alert}
              text={VM.lines.data[filteredByDay[linestime]?.lineId]?.code}
              color={
                filteredByDay[linestime]?.routeColor
                  ? '#' + filteredByDay[linestime].routeColor
                  : theme.colors.main
              }
              textColor={'#' + VM.lines.data[filteredByDay[linestime]?.lineId]?.routeTextColor}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={styles.direction}>
                {filteredByDay[linestime]?.headSign ?? filteredByDay[linestime]?.nameLine}
              </div>
              {lineRealTime ? (
                <div style={styles.realTime}>
                  {/* 2 minutos de retraso 11:02 */}
                  {/* Test 3 minutos diferencia: '15:09:40', 154 / 60 */}
                  {/* Test 2 minutos diferencia: '15:09:40', 130 / 60 */}
                  {/* Test 3 minutos diferencia: '15:09:40', 140 / 60 */}
                  {/* Test 58 minutos diferencia: '15:01:40', 3485 / 60 */}
                  {/* Test -2 minutos diferencia: '15:09:40', -130 / 60 */}
                  {/* Test -3 minutos diferencia: '15:09:40', -161 / 60 */}
                  {/* Test -50 minutos diferencia: '15:50:40', -3012 / 60 */}
                  {/* Test -5 minutos diferencia: '15:04:20', -312 / 60 */}
                  {/* Test -5 minutos diferencia: '15:04:20', -261 / 60 */}
                  {/* Test -70 minutos diferencia: '15:04:20', -4200 / 60 */}
                  {/* Test -70 minutos diferencia: '00:04:20', -4200 / 60 */}
                  {/* Test 70 minutos diferencia: '23:04:20', 4200 / 60 */}
                  {/* Test 5 minutos diferencia: '14:59:20', 281 / 60 */}
                  {/* Test 4 minutos diferencia: '14:59:20', 260 / 60 */}
                  {/* Test 70 minutos diferencia: '14:59:20', 4200 / 60 */}
                  {/* Test 125 minutos diferencia: '14:59:20', 7500 / 60 */}
                  {/* Test 12 minutos diferencia: '15:20:20', 12 */}
                  {calculateTime(filteredByDay[linestime]?.time, lineRealTime?.stopArrivalDelay / 60)}
                </div>
              ) : null 
                }
                {/* TO CHNAGE null */}
                {/* <div style={styles.realTime}>
                  {t('stop_time_real_theory')}
                </div> */}
            </div>
            <div style={styles.time}>
              {TimeUtils.adaptFormatTo24Hours(filteredByDay[linestime]?.time)}
            </div>
          </div>
        );
        res.push(<div key={'separator_' + linestime} style={styles.separator} />);
      }
    }
    res.pop();
    return res;
  };

  return props.linestimes ? (
    <div style={{ ...styles.content, ...props.style }}>{renderStops()}</div>
  ) : (
    <div key={'loader'} style={styles.loader}>
      <ClipLoader size={30} />
    </div>
  );
}
