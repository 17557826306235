import { useDispatch } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../redux/slices/eventsModalstoggleVisibilitySlice';
import {useTranslate} from "../../context/languageContext";

export default function LinkForgotPassword(props: any) {
  const dispatch = useDispatch();
  const t = useTranslate()

  const styles = {
    link: {
      color: '#FFFFFF',
      textDecoration: 'none',
      fontFamily: 'Open Sans',
      fontWeight: '600',
      fontSize: 14,
      marginLeft: 'auto',
      marginBottom: '11.3%'
    }
  } as const;

  return (
    <a
      style={styles.link}
      className="forgot-password"
      onClick={() =>
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityRecoveryPassModalWidget(true)
        )
      }>
      {t('forgotPass')}
    </a>
  );
}
