import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { StatusCodesServer } from '../../hooks';
import { eventsModalstoggleVisibilitySlice } from '../../slices/eventsModalstoggleVisibilitySlice';
import { RootState } from '../../store';
import GeneralUtils from "../../../utils/GeneralUtils";

export interface Post {
  id: number;
  name: string;
}

export const NotificationConfServiceApi = createApi({
  reducerPath: 'NotificationConfServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GeneralUtils.getEnvironment(window.location.origin),
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accountInformation.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['notifications'],
  endpoints: (builder) => ({
    putNotificationConf: builder.mutation<any, any>({
      query: (body) => ({
        url: '/notification/',
        method: 'PUT',
        body: body,
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        }
      }),
      invalidatesTags: ['notifications'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
          const { data } = await queryFulfilled;
          StatusCodesServer(dispatch, 200);
        } catch (err: any) {
          StatusCodesServer(dispatch, err.error.status);
        } finally {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        }
      }
    }),

    getNotificationConf: builder.query<any, any>({
      query: () => ({
        url: '/notification/',
        method: 'GET'
      }),
      providesTags: ['notifications'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
          const { data } = await queryFulfilled;
        } catch (err: any) {
          StatusCodesServer(dispatch, err.error.status);
        } finally {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        }
      }
    }),

    getSystemMessages: builder.mutation<any, any>({
      query: (getSystemMessages: {
        locale: string;
        pageNumber: number;
        elementsPerPage: number;
      }) => ({
        url: `/messages/?locale=${getSystemMessages.locale}&pagenumber=${getSystemMessages.pageNumber}&elementsperpage=${getSystemMessages.elementsPerPage}`,
        method: 'GET',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        }
      }),
      async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
        try {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
          await queryFulfilled;
          StatusCodesServer(dispatch, 200);
        } catch (err: any) {
          StatusCodesServer(dispatch, err.error.status);
        } finally {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        }
      }
    }),

    getConfigNotifications: builder.mutation<any, any>({
      query: () => ({
        url: '/notification/',
        method: 'GET'
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
          const { data } = await queryFulfilled;
          StatusCodesServer(dispatch, 200);
        } catch (err: any) {
          StatusCodesServer(dispatch, err.error.status);
        } finally {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        }
      }
    })
  })
});

export const {
  usePutNotificationConfMutation,
  useGetNotificationConfQuery,
  useGetSystemMessagesMutation,
  useGetConfigNotificationsMutation
} = NotificationConfServiceApi;
