import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {filtersInformation, filtersSlice} from '../../../../../../redux/slices/filtersSlice';
import FilterUtils from '../../../../../../utils/FilterUtils';
import { VM } from '../../../../../../viewmodel/VM';
import Button from '../../../../../components/commons/Button';
import { useTranslate } from '../../../../../context/languageContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import FiltersTransportGroup from './transportFilters/FiltersTransportGroup';
import { markersInformation, markersSlice } from '../../../../../../redux/slices/markersSlice';

export default function FiltersView(props) {
  const theme = useTheme();
  const t = useTranslate();
  const dispatch = useDispatch()

  const [operatorsFilters, setOperatorFilters] = useState(null);
  const [motorizedFilters, setMotorizedFilters] = useState([]);
  const [poisFilters, setPoisFilters] = useState(null);
  const markersInfo = useSelector(markersInformation);
  const loadPois = markersInfo.loadedTypes.pois;

  useEffect(() => {
    VM.categoriesPoiFilters?.listen(() =>
      setPoisFilters(
        FilterUtils.getMap(
          JSON.parse(JSON.stringify(Array.from(VM.categoriesPoiFilters?.data?.categories.values())))
        ) ?? []
      )
    );
    VM.motorized?.listen(() =>
      setMotorizedFilters(JSON.parse(JSON.stringify(VM.motorized?.data?.motorized)) ?? [])
    );
    VM.operatorsFilters?.listen(() =>
      setOperatorFilters(
        JSON.parse(JSON.stringify(Array.from(VM.operatorsFilters?.data?.agencies.values()))) ?? []
      )
    );
  }, [poisFilters, motorizedFilters, operatorsFilters]);

  useEffect(() => {
    setPoisFilters(VM.categoriesPoiFilters?.data.categories);
    setMotorizedFilters(VM.motorized?.data.motorized);
    setOperatorFilters(VM.operatorsFilters?.data.agencies);
  }, [poisFilters, motorizedFilters, operatorsFilters]);
  const filters = useSelector(filtersInformation);

  const styles = {
    content: {
      width: '100%'
    },
    buttonText: {
      color: theme.colors.primaryMedium,
      fontWeight: '600',
      marginLeft:'auto',
    },
    rows: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: '345px',
      minWidth: '232px',
      width: '80vw'
    },
    title: {
      fontWeight: '700',
      color: theme.colors.neutralBlack,
      marginBottom: 7
    },
    container: {
      maxHeight: 75
    },
    moreFilters: {
      fontSize: '14px',
      padding: 0,
      border: 'none',
      textAlign: 'right',
      fontWeight: '600',
      color: FilterUtils.checkSelectedFromMoreFilters(
        filters.operators,
        filters.motorized,
        filters.categories
      )
        ? theme.colors.primaryMedium
        : theme.colors.neutralBlack
    },
    textMore: {
      fontSize: '14px',
      textAlign: 'right',
      fontWeight: '600',
      color: theme.colors.neutralDarkGray,
      marginRight: '12.88px'
    },
    favourite: {
      color: theme.colors.primaryMedium,
      fontSize: '14px',
      border: 'none',
      padding: 0
    }
  };

  const deleteAllFilters = () => {
    dispatch(filtersSlice.actions.reset());
    dispatch(markersSlice.actions.updateLoadedTypePois(true));
    FilterUtils.filterByCategories([]);
  };

  return (
    <div style={styles.content}>
      <div style={styles.rows}>
        <label style={styles.title}>{t('visualizer_ModosdeTransporte')}</label>
        <Button
          color={
            FilterUtils.checkSelectedFromMoreFilters(
              filters.operators,
              filters.motorized,
              filters.categories
            )
              ? theme.colors.primaryMedium
              : theme.colors.neutralBlack
          }
          style={styles.moreFilters}
          iconRight={true}
          icon={R.drawables.general.Ic_ChevronRightSmall}
          onClick={() => {
            if (FilterUtils.haveValues(filters.operators, filters.motorized, filters.categories)) {
              props.setOpenedFilters();
            }
          }}
          text={`${t('visualizer_moreFilters')} ${
            FilterUtils.counAllMoreFilters(
              filters.operators,
              filters.motorized,
              filters.categories, 
              filters.modes,
              loadPois,
            ) === 0
              ? ''
              : '(' +
                FilterUtils.counAllMoreFilters(
                  filters.operators,
                  filters.motorized,
                  filters.categories,
                  filters.modes,
                  loadPois,
                ) +
                ')'
          }`}></Button>
      </div>

      <FiltersTransportGroup />

      {FilterUtils.counAllMoreFilters(filters.operators, filters.motorized, filters.categories, filters.modes, loadPois) !== 0 && (
          <Button
              style={styles.buttonText}
              onClick={() => {deleteAllFilters()}}
              text={t('button_delete')}
          />)
      }
    </div>
  );
}
