import { VM } from '../viewmodel/VM';

export default class FilterUtils {
  static filterByCategories(data) {
    let result = [];
    if (!this.isAllCategoriesSelected(data) && data.length !== 0) {
      for (let poi in VM.pois.noFiltered) {
        if (data.length >= 0 && data.indexOf(VM.pois.noFiltered[poi].categoryId) >= 0) {
          result.push(VM.pois.noFiltered[poi]);
        }
      }
      VM.pois.notify(result, true);
    } else {
      VM.pois.notify(VM.pois.noFiltered, true);
    }
  }

  static calculateRegion(lat1, lon1, lat2, lon2) {
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d;
  }

  /* Función para combinar los valores del caso de agency y dataorigin */
  static combineValues(agencies, dataOrigin) {
    return agencies.map((element: any) => {
      let agency = JSON.parse(JSON.stringify(element));
      let origin = dataOrigin.findIndex(
        (data: any) => String(data.agencyId) === String(agency.id),
      );
      if (origin !== -1) {
        agency.agencyOriginId = dataOrigin[origin].id;
        if (dataOrigin[origin].transportModes.length > 0) {
          agency.mode = dataOrigin[origin].transportModes[0].transportModeId;
          agency.icons = dataOrigin[origin].transportModes[0];
          agency.transportModes = dataOrigin[origin].transportModes;
          agency.groupName = dataOrigin[origin]?.groupTxt;
        }
      } else {
        agency.icons = null;
      }
      return agency;
    });
  }

  static async filterByTransport(modesF, operatorsF, favOperators, isFilterButton, motorized) {
    let modes = [];
    let operators = [];
    let result = [];

    if (isFilterButton) {
      operators = favOperators;
    } else {
      operators = operatorsF;
      modes = JSON.parse(JSON.stringify(modesF));  
      if (motorized?.find(element => element === 1) && motorized?.find(element => element === 2) === undefined) {
        //combutión
        [1, 2].forEach((element) => {
          if (modes.find((mode) => mode === element) === undefined) {
            modes.push(element)
          }
        })
      } else if (motorized?.find(element => element === 1) === undefined && motorized?.find(element => element === 2)) {
        //electrico
        [1, 2, 3, 7, 8, 10].forEach((element) => {
          if (modes.find((mode) => mode === element)  === undefined) {
            modes.push(element)
          }
        })
      }
      
    }

    if (operators.length) {
      for (let stop in VM.stops.noFiltered) {
        let agencyOriginId = VM.stops.noFiltered[stop].agencyOriginId;
        if (operators.indexOf(agencyOriginId) >= 0) {
          result.push(VM.stops.noFiltered[stop]);
        }
      }
      //VM.stops.notify(result, true);
    } else {
      result = VM.stops.noFiltered
    }

    let newResult = [];

    if (modes.length) {
      for (let stop in result) {
        let transportMode = result[stop].transportMode;
        if (modes.find((transport) => transport === transportMode)) {
          newResult.push(result[stop]);
        }
      }
    } else {
      newResult = result;
    }

    VM.stops.notify(newResult, true);

    
    // else VM.stops.notify(VM.stops.noFiltered, true);
  }

  /**
   * @function compareAgenciesFilters compara las listas @param list1 que es la lista con estado previo
   * y la lista @param list2 que es la que mantiene el nuevo estado, y fusiona los valores que estén seleccionados
   * de las agencias para los filtros
   */
  static compareAgenciesFilters(list1, list2) {
    const mapList1 = this.getMap(list1);
    let listModify = [];

    for (let i = 0; i < list1.length; i++) {
      let agencies = [];
      for (let j = 0; j < list1[i].agencies.length; j++) {
        let agencyString = JSON.stringify(list1[i].agencies[j]);
        let agency = JSON.parse(agencyString);
        agency.selected = false;
        agencies.push(agency);
      }
      let modeString = JSON.stringify(list1[i]);
      let mode = JSON.parse(modeString);
      mode.agencies = agencies;
      listModify.push(mode);
    }

    for (let i = 0; i < list2.length; i++) {
      /* Comprueba si está presente el mismo objeto en la primera lista de filtros  */
      const index = listModify.findIndex(
        element => JSON.stringify(element) === JSON.stringify(list2[i]),
      );

      /* Comprueba que si el elemento no se ha encontrado se ha modificado */
      if (index === -1 && mapList1.get(list2[i].id) !== undefined) {
        list2[i].agencies.forEach(agency => {
          let modeString = JSON.stringify(mapList1.get(list2[i].id));
          let modeList1 = JSON.parse(modeString);
          let indexAgency = modeList1.agencies.findIndex(
            element => element.id === agency.id,
          );
          if (indexAgency !== -1) {
            agency.selected = modeList1.agencies[indexAgency].selected;
          }
          return agency;
        });
      } else if (index !== -1) {
        list2[i] = mapList1.get(list2[i].id);
      }
    }
    return list2;
  }

  static compareAgencies(list1, list2) {
    let listModify = [];

    for (let i = 0; i < list1.length; i++) {
      let agencies = [];
      for (let j = 0; j < list1[i].agencies.length; j++) {
        let agencyString = JSON.stringify(list1[i].agencies[j]);
        let agency = JSON.parse(agencyString);
        agency.selected = false;
        agencies.push(agency);
      }
      let modeString = JSON.stringify(list1[i]);
      let mode = JSON.parse(modeString);
      mode.agencies = agencies;
      listModify.push(mode);
    }

    for (let i = 0; i < list2.length; i++) {
      /* Comprueba si está presente el mismo objeto en la primera lista de filtros  */
      const index = listModify.findIndex(
        element => JSON.stringify(element) === JSON.stringify(list2[i]),
      );

      /* Comprueba que si el elemento no se ha encontrado se ha modificado */
      let elementFromList2InList1 = list1.find(
        (element) => String(element.id) === String(list2[i].id),
      );

      if (index === -1 && elementFromList2InList1) {
        list2[i].agencies.forEach((agency) => {
          let modeString = JSON.stringify(elementFromList2InList1);
          let modeList1 = JSON.parse(modeString);
          let indexAgency = modeList1.agencies.findIndex(
            (element) => element.id === agency.id,
          );
          if (indexAgency !== -1) {
            agency.selected = modeList1.agencies[indexAgency].selected;
          }
          return agency;
        });
      } else if (index !== -1) {
        //caso de estar presente el mismo
        list2[i] = elementFromList2InList1;
      }
      //en caso de haberse borrado la agencia de origen que actuaba como principal dentro de un grupo
      // se perderá el filtro seleccionado y dejará de estar el grupo como seleccionado
    }
    return list2;
  }

  static filterByTransportStations(stations, modesF, operatorsF, favOperators, isFilterButton) {
    let modes = [];
    let operators = [];
    let result = [];
    if (isFilterButton) {
      operators = favOperators;
    } else {
      operators = operatorsF;
      modes = modesF;
    }
    if (modes.length || operators.length) {
      for (let station in stations) {
        let transportModeId = VM.dataOrigin.noMap.filter(({id})=>parseInt(id) === stations[station].agencyOriginId)
        transportModeId=transportModeId[0].transportModes[0]?.transportModeId
        let agencyOriginId = stations[station].agencyOriginId;

        if (modes.indexOf(transportModeId) >= 0 || operators.indexOf(agencyOriginId) >= 0) {
          result.push(stations[station]);
        }
      }
      return result
    } else return stations
  }

  static isAllTransportsSelected(transports) {
    for (let transport in transports) {
      if (transports[transport].selected) {
        return false;
      }
    }
    return true;
  }

  static isAllOperatorsSelected(operators) {
    for (let operator in operators) {
      for (let agency in operators[operator].agencies) {
        if (operators[operator].agencies[agency].selected) {
          return false;
        }
      }
    }
    return true;
  }

  static isAllCategoriesSelected(categories) {
    if (categories.length == Object.keys(VM.categories?.data)?.length) {
      return true;
    }
    return false;
  }

  static getMap(array) {
    const map = new Map();
    if (array)
      array.forEach((element) => {
        map.set(element.id, element);
      });

    return map;
  }

  static compareFilters(list1, list2) {
    let listModify = [];
    for (let i = 0; i < list1.length; i++) {
      const element = JSON.parse(JSON.stringify(list1[i]));
      element['selected'] = false;
      listModify.push(element);
    }
    list2.forEach((element) => (element['selected'] = false));
    const mapList1 = this.getMap(list1);
    for (let i = 0; i < list2.length; i++) {
      /* Comprueba si está presente el mismo objeto en la primera lista de filtros  */
      const index = listModify.findIndex(
        (element) => JSON.stringify(element) === JSON.stringify(list2[i])
      );
      /* Comprueba que si el elemento no se ha encontrado se ha modficado */
      if (index === -1 && mapList1.get(list2[i].id) !== undefined) {
        let elementChanged = list2[i];
        elementChanged['selected'] = mapList1.get(list2[i].id).selected;
        list2[i] = elementChanged;
      } else if (index !== -1) {
        list2[i] = mapList1.get(list2[i].id);
      }
    }
    return list2;
  }

  static compareAgenciesFilters(list1, list2) {
    const mapList1 = this.getMap(list1);
    let listModify = [];

    for (let i = 0; i < list1.length; i++) {
      let agencies = [];
      for (let j = 0; j < list1[i].agencies.length; j++) {
        let agencyString = JSON.stringify(list1[i].agencies[j]);
        let agency = JSON.parse(agencyString);
        agency.selected = false;
        agencies.push(agency);
      }
      let modeString = JSON.stringify(list1[i]);
      let mode = JSON.parse(modeString);
      mode.agencies = agencies;
      listModify.push(mode);
    }

    for (let i = 0; i < list2.length; i++) {
      /* Comprueba si está presente el mismo objeto en la primera lista de filtros  */
      const index = listModify.findIndex(
        (element) => JSON.stringify(element) === JSON.stringify(list2[i])
      );

      /* Comprueba que si el elemento no se ha encontrado se ha modificado */
      if (index === -1 && mapList1.get(list2[i].id) !== undefined) {
        list2[i].agencies.forEach((agency) => {
          let modeString = JSON.stringify(mapList1.get(list2[i].id));
          let modeList1 = JSON.parse(modeString);
          let indexAgency = modeList1.agencies.findIndex((element) => element.id === agency.id);
          if (indexAgency !== -1) {
            agency.selected = modeList1.agencies[indexAgency].selected;
          }
          return agency;
        });
      } else if (index !== -1) {
        list2[i] = mapList1.get(list2[i].id);
      }
    }
    return list2;
  }

  static countFilters(filters) {
    let count = 0;
    for (let i = 0; i < filters?.length; i++) {
      if (filters[i].selected) count++;
    }
    return count;
  }

  static haveValues(operators, motorized, pois) {
    if (operators === null || operators === undefined || motorized === null || pois === null) {
      return false;
    } else {
      return true;
    }
  }

  static countAllAgencies = (filtersOperators) => {
    return filtersOperators?.length;
  };

  static counAllMoreFilters(operators, motorized, pois, modes, showPois) {
    let countPois = 0;
    if (!showPois) {
      countPois++;
    }
    return operators?.length + motorized?.length + pois?.length + modes?.length + countPois;
  }

  static checkSelectedFromMoreFilters(filtersOperators, filtersMotorized, filtersPois) {
    if (!this.haveValues(filtersOperators, filtersMotorized, filtersPois)) return false;
    let checked = null;
    checked = filtersMotorized.find((element) => element.selected === true);
    if (checked !== undefined) return true;

    checked = Array.from(filtersPois.values()).find((element) => element.selected === true);
    if (checked !== undefined) return true;

    let operators = Array.from(filtersOperators.values());
    for (let i = 0; i < operators.length; i++) {
      let agencies;
      if (operators[i]?.agencies instanceof Map) {
        agencies = Array.from(operators[i]?.agencies.values());
      } else {
        agencies = operators[i]?.agencies;
      }
      for (let j = 0; j < agencies?.length; j++) {
        checked = agencies.find((element) => element.selected === true);
        if (checked !== undefined) return true;
      }
    }
    return false;
  }

  static checkSelectedAllFilters(
    filtersTransport,
    filtersOperators,
    filtersMotorized,
    filtersPois
  ) {
    let result = null;
    if (filtersTransport === null || filtersTransport === undefined) return false;
    result = Array.from(filtersTransport.values()).find((element) => element.selected === true);
    if (result !== undefined) return true;
    if (!this.haveValues(filtersOperators, filtersMotorized, filtersPois)) {
      return false;
    }
    return this.checkSelectedFromMoreFilters(filtersOperators, filtersMotorized, filtersPois);
  }

  static countAllFilters(filtersTransport, filtersOperators, filtersMotorized, filtersPois) {
    return (
      filtersTransport?.length +
      filtersOperators?.length +
      filtersMotorized?.length +
      filtersPois?.length
    );
  }

  static stopsByRegion(region, zoom, stops, favorites, alerts) {
    let filtered = [];
    if (zoom > 13) {
      filtered = JSON.parse(JSON.stringify(Array.from(stops))).filter(
        ({ latitude, longitude }) =>
          region._northEast.lat > latitude &&
          region._southWest.lat < latitude &&
          region._northEast.lng > longitude &&
          region._southWest.lng < longitude
      );
      for (let stop in filtered) {
        for (let alert in alerts) {
          for (let i = 0; i < alerts[alert].paradas?.length; i++) {
            if (filtered[stop].id === alerts[alert].paradas[i].idParada) {
              if (!filtered[stop].alert) filtered[stop].alert = [];
              filtered[stop].alert.push(alerts[alert]);
            }
          }
        }
        if (favorites?.stops.indexOf(filtered[stop].id) > -1) {
          filtered[stop].favorite = true;
        }
      }
    }
    return filtered;
  }

  static linesStopsByRegion(stops, favorites) {
    let filtered = stops;
    let alerts = VM.alerts.data;
    for (let stop in filtered) {
      for (let alert in alerts) {
        for (let i = 0; i < alerts[alert].paradas?.length; i++) {
          if (filtered[stop].id === alerts[alert].paradas[i].idParada) {
            if (!filtered[stop].alert) filtered[stop].alert = [];
            filtered[stop].alert.push(alerts[alert]);
          }
        }
      }
      if (favorites?.stops.indexOf(filtered[stop].id) > -1) {
        filtered[stop].favorite = true;
      }
    }

    return filtered;
  }

  static directionsByRegion(region, zoom, directions) {
    let filtered = [];
    if (zoom > 13) {
      filtered = JSON.parse(JSON.stringify(Array.from(directions))).filter(
        ({ latitude, longitude }) =>
          region._northEast.lat > latitude &&
          region._southWest.lat < latitude &&
          region._northEast.lng > longitude &&
          region._southWest.lng < longitude
      );
    }
    return filtered;
  }

  static onlyStopAlerts(region, stops, favorites, alerts) {
    let filtered = [];
    let result = [];
    if(stops)
      filtered = JSON.parse(JSON.stringify(Array.from(stops))).filter(
        ({ latitude, longitude }) =>
          region._northEast.lat > latitude &&
          region._southWest.lat < latitude &&
          region._northEast.lng > longitude &&
          region._southWest.lng < longitude
      );
    for (let stop in filtered) {
      for (let alert in alerts) {
        for (let i = 0; i < alerts[alert].paradas?.length; i++) {
          //String(filtered[stop].agencyOriginId) == String(alerts[alert].idAgencia)
          if (filtered[stop].id === alerts[alert].paradas[i].idParada) {
            if (!filtered[stop].alert) filtered[stop].alert = [];
            filtered[stop].alert.push(alerts[alert]);
            if (!result.includes(filtered[stop])) result.push(filtered[stop]);
          }
        }
      }
      if (favorites?.stops.indexOf(filtered[stop].id) > -1) {
        filtered[stop].favorite = true;
      }
    }
    return result;
  }

  static async setLineAlerts(lines, language) {
    if (VM.alerts.data.length) {
      await VM.alerts.get(language);
    }
    let alerts = VM.alerts.data;
    for (let line in lines) {
      for (let alert in alerts) {
        for (let i = 0; i < alerts[alert].lineas?.length; i++) {
          if (lines[line].id === alerts[alert].lineas[i].idLinea) {
            if (!lines[line].alert) lines[line].alert = [];
            lines[line].alert.push(alerts[alert]);
          }
        }
      }
    }
    return lines;
  }

  static poisByRegion(region, zoom, pois, favorites) {
    let filtered = [];
    if (zoom > 14) {
      filtered = JSON.parse(JSON.stringify(Array.from(pois))).filter(
        ({ latitude, longitude }) =>
          region._northEast.lat > latitude &&
          region._southWest.lat < latitude &&
          region._northEast.lng > longitude &&
          region._southWest.lng < longitude
      );
    }
    for (let poi in filtered) {
      if (favorites?.pois.indexOf(filtered[poi].id) > -1) {
        filtered[poi].favorite = true;
      }
    }
    return filtered;
  }

  static vehiclesByRegion(region, zoom, vehicles) {
    let filtered = [];
    if (zoom > 14) {
      filtered = JSON.parse(JSON.stringify(Array.from(vehicles))).filter(
        ({ latitude, longitude }) =>
          region._northEast.lat > latitude &&
          region._southWest.lat < latitude &&
          region._northEast.lng > longitude &&
          region._southWest.lng < longitude
      );
    }
    return filtered;
  }

  static checkDriverId(agency,driver) {
    let filtered = []
    if(VM.dataOrigin.noMap)
      filtered = JSON.parse(JSON.stringify(Array.from(VM.dataOrigin.noMap))).filter(
          ({id, driverId}) => id === agency && driverId === driver
      );
    return filtered.length
  }

  static stationsByRegion(region, zoom, stations, favorites) {
    let filtered = [];
    if (zoom > 14) {
      filtered = JSON.parse(JSON.stringify(Array.from(stations))).filter(
        ({ latitude, longitude }) =>
          region._northEast.lat > latitude &&
          region._southWest.lat < latitude &&
          region._northEast.lng > longitude &&
          region._southWest.lng < longitude
      );
    }

    for(let station in filtered){
      if (favorites?.stops.indexOf(filtered[station].id) > -1) {
        filtered[station].favorite = true;
      }
    }
    return filtered;
  }

  static generateClusterMarker(markers, zoom) {
    let filtered = JSON.parse(JSON.stringify(markers));
    if (zoom < 18 && filtered.length > 0) {
      //array de liminación de marcadores finales
      let differences = [];
      let newMarkers = [];
      for (let i = filtered.length - 1; i > -1 && filtered.length > 0; i--) {
        if (filtered[i]) {
          let neighboursMarkerIndexes = [];
          let neighboursMarkerI = [];
          neighboursMarkerI.push(JSON.parse(JSON.stringify(filtered[i])));
          /* Se recorren todos los marcadores filtrados desde la posición del marcador actual, para calcular solo los elementos a partir de la diagonal principal de la matriz */
          for (let j = i - 1; j > -1 && filtered.length > 0; j--) {
            /* Se calcula la distancia Euclídea para cada punto */
            let distanceLat = Math.pow(
                filtered[i]?.latitude - filtered[j]?.latitude,
                2,
            );
            let distanceLon = Math.pow(
                filtered[i]?.longitude -
                filtered[j]?.longitude,
                2,
            );
            let valueDistance = Math.pow(distanceLat + distanceLon, 0.5);
            /* En caso de ser una distancia muy pequeña se guarda el elemento en la lista de vecinos y su índice */
            if (valueDistance < 0.0002) {
              neighboursMarkerI.push(JSON.parse(JSON.stringify(filtered[j])));
              neighboursMarkerIndexes.push(j);
            }
          }

          if (neighboursMarkerI.length > 1) {
            differences.push(filtered[i]);
            //se genera un nuevo marcador contenedor de otros y se añade en el array de nuevos marcadores
            let markerContainer = {
              id: String(neighboursMarkerI[0].latitude)+String(neighboursMarkerI[0].longitude),
              markerId: 'container' + i,
              location: {
                latitude: neighboursMarkerI[0].latitude,
                longitude: neighboursMarkerI[0].longitude,
              },
              latitude: neighboursMarkerI[0].latitude,
              latitudeNear: neighboursMarkerI[0]?.latitudeNear,
              longitude: neighboursMarkerI[0].longitude,
              longitudeNear: neighboursMarkerI[0]?.longitudeNear,
              markerType: 'container',
              name: 'container',
              address: '',
              points: neighboursMarkerI,
              staticIcon: undefined,
              markerIcon: null,
            };
            newMarkers.push(markerContainer);

            //se eliminan los marcadores vecinos del array principal
            for (let k = 0; k < neighboursMarkerIndexes.length; k++) {
              filtered.splice(neighboursMarkerIndexes[k], 1);
            }
          }
        }
      }

      //se elimina el marcador principal de cada cluster
      for (let m = 0; m < differences.length; m++) {
        let indexOf = filtered.findIndex(
            (element) =>
                element.id === differences[m]?.id &&
                element.markerType === differences[m]?.markerType,
        );
        if (indexOf !== -1) {
          filtered.splice(indexOf, 1);
        }
      }
      //se añaden los nuevos marcadores containers
      filtered = filtered.concat(newMarkers);
    }

    return zoom < 18 ? filtered : markers;
  }
}
