import { useDispatch, useSelector } from 'react-redux';
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation
} from '../../../../../../redux/login/favorite/FavoriteServiceApi';
import { accountInformation } from '../../../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import {
  favoritesInformation,
  favoritesSlice
} from '../../../../../../redux/slices/favoritesSlice';
import GeneralUtils from '../../../../../../utils/GeneralUtils';
import RouteUtils from '../../../../../../utils/RouteUtils';
import { VM } from '../../../../../../viewmodel/VM';
import Button from '../../../../../components/commons/Button';
import ButtonIcon from '../../../../../components/commons/ButtonIcon';
import DefaultModal from '../../../../../components/widget/Modals/DefaultModal';
import { useTranslate } from '../../../../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../../../../context/modalContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';

export default function PoiMidInfo(props) {
  const theme = useTheme();
  const t = useTranslate();
  const dispatch = useDispatch();
  const favorites = useSelector(favoritesInformation);
  const [addFavorite] = useAddFavoriteMutation();
  const [removeFavorite] = useDeleteFavoriteMutation();
  const setChild = useSetModalChild();
  const setOpen = useSetModalOpened();
  const account = useSelector(accountInformation);

  const updateFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    if (updateFavorites.pois.indexOf(props.poi.id) < 0) {
      if (account.idUserType === 1) {
        setOpen(true);
        setChild(
          <DefaultModal
            title={t('loginToFav')}
            description={t('loginToFavBody')}
            alter={true}
            firstButton={t('createAccount')}
            secondButton={t('login')}
            onClickFirst={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                  true
                )
              );
            }}
            onClickSecond={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
              );
            }}
          />
        );
      } else {
        updateFavorites.pois.push(props.poi.id);
        addFavorite({
          stops: [],
          pois: [props.poi.id],
          plans: [],
          directions: []
        });
        dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#00763D',
            text: t('poiSaved')
          })
        );
      }
    } else {
      setOpen(true);
      setChild(
        <DefaultModal
          title={t('deleteFavConfirm')}
          onClickSecond={() => {
            let updateFavorites2 = JSON.parse(JSON.stringify(favorites));
            updateFavorites2.pois.splice(updateFavorites.pois.indexOf(props.poi.id), 1);
            removeFavorite({
              stops: [],
              pois: [props.poi.id],
              plans: [],
              directions: []
            });
            dispatch(favoritesSlice.actions.updateFavorites(updateFavorites2));
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
                {
                  showModal: true,
                  background: '#00763D',
                  text: t('poiRemoved')
                }
              )
            );
          }}
        />
      );
    }
  };
  const styles = {
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center'
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginTop: 10,
      marginBottom: 10,
      width: '100%'
    }
  };

  const onShare = () => {
    let path = window.location.href.split('/');
    path.pop();
    path = path.join('/');
    let copy = `${path[path.length - 1] === '#' ? '' : '/#'}/poi?${GeneralUtils.convertToHex(
      `id=${props.poi.id}`
    )}`;
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityShareModalWidget({
        showModal: true,
        shareLink: path + copy,
        title: props.poi.name + ', ' + props.poi.address
      })
    );
    // setTimeout(ReactTooltip.hide, 2000);
    // navigator.clipboard.writeText(path + copy);
  };

  const goThere = () => {
    let coords = { latitude: props.poi.latitude, longitude: props.poi.longitude };
    let name = props.poi.name;
    let info = RouteUtils.getTypeId(props.poi, favorites);
    let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };
    VM.plannerSegments.set('destination', res);
  };

  return (
    <div>
      <div style={styles.separator} />
      <div style={{ ...styles.row }}>
        <Button
          icon={R.drawables.map.goThere}
          text={t('goThere')}
          iconRight={true}
          textColor={theme.colors.darkGray}
          iconSize={10}
          iconColor={theme.colors.darkGray}
          borderColor={theme.colors.border}
          onClick={goThere}
        />
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          {/* <ReactTooltip event="focusin" effect={'solid'} id="main" /> */}
          <ButtonIcon
            style={{ marginLeft: 10 }}
            onClick={() => onShare()}
            icon={R.drawables.commons.share}
            iconSize={15}
            dataTip={t('copyUrl')}
            dataFor={'main'}
            iconColor={theme.colors.darkGray}
            borderColor={theme.colors.border}
            background={theme.colors.white}
          />
          <ButtonIcon
            onClick={() => updateFav()}
            iconColor={theme.colors.darkGray}
            style={{ marginLeft: 10 }}
            iconSize={15}
            icon={
              favorites.pois.indexOf(props.poi.id) < 0
                ? R.drawables.general.Ic_favorite
                : R.drawables.general.Ic_favorite_blue
            }
            background={theme.colors.white}
          />
        </div>
      </div>
    </div>
  );
}
