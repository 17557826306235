import { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse/lib/Collapse';
import TimeUtils from '../../../../../../../../utils/TimeUtils';
import { VM } from '../../../../../../../../viewmodel/VM';
import Input from '../../../../../../../components/commons/Input';
import { useLanguage, useTranslate } from '../../../../../../../context/languageContext';
import { useTheme } from '../../../../../../../context/themeContext';

export default function ExitNow(props, ref) {
  const t = useTranslate();
  const theme = useTheme();
  const language = useLanguage();
  const [selected, setSelected] = useState(0);

  const [arriveTime, setArriveTime] = useState({
    time: TimeUtils.convertTime12to24(VM.plannerTime.data.time),
    date: VM.plannerTime.data.date
  });
  const [exitTime, setExitTime] = useState({
    time: TimeUtils.convertTime12to24(VM.plannerTime.data.time),
    date: VM.plannerTime.data.date
  });

  const styles = {
    content: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingInline: 22,
      textAlign: 'center'
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 0.5,
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px'
    },
    input: {
      marginTop: 10,
      borderWidth: '0 0 1.5px 0',
      borderRadius: 0,
      marginInline: 5
    },
    option: {
      cursor: 'pointer',
      fontSize: 14
    }
  };

  useEffect(() => {
    props.onChangeSelected(selected, arriveTime, exitTime);
  }, [selected, arriveTime, exitTime, language]);

  useEffect(() => {
    if(VM.plannerTime.data.exitNow)
      setSelected(0)
    else if(VM.plannerTime.data.arriveBy) 
      setSelected(2)
    else
      setSelected(1)
  }, []);

  const setHoursMinute = (data, arriveBy) => {
    let res = data.substr(0, 2) + ':' + data.substr(3, 4);
    VM.plannerTime.setTime(res);
    VM.plannerTime.setArriveBy(arriveBy);
    if (arriveBy) setArriveTime({ time: res, date: arriveTime.date });
    else setExitTime({ time: res, date: exitTime.date });
  };

  const setDate = (data, arriveBy) => {
    let res = data.split('-').reverse().join('-');
    VM.plannerTime.setDate(res);
    VM.plannerTime.setArriveBy(arriveBy);
    if (arriveBy) setArriveTime({ time: arriveBy.time, date: res });
    else setExitTime({ time: exitTime.time, date: res });
  };

  return (
    <div style={{ ...props.style, ...styles.content }}>
      <div
        style={{ ...styles.option, fontWeight: selected === 0 ? 500 : 10 }}
        onClick={() => {
          setSelected(0);
            VM.plannerTime.setArriveBy(false);
            VM.plannerTime.setExitNow(true);
            VM.plannerTime.setTime(TimeUtils.getTime(Date.now()));
          VM.plannerTime.setDate(TimeUtils.getDate(Date.now()));
        }}>
        {t('exitNow')}
      </div>
      <div style={styles.separator} />

      <div
        style={{ ...styles.option, fontWeight: selected === 1 ? 500 : 10 }}
        onClick={() => {
          setSelected(1);
          VM.plannerTime.setArriveBy(false);
            VM.plannerTime.setTime(exitTime.time);
            VM.plannerTime.setExitNow(false);
            VM.plannerTime.setDate(exitTime.date);
        }}>
        {t('exitAt')}
      </div>
      <Collapse isOpened={selected === 1}>
        <div style={{ display: 'flex' }}>
          <Input type={'time'} initialValue={exitTime.time} onChange={(e) => setHoursMinute(e, false)} style={styles.input} />
          <Input type={'date'} initialValue={exitTime.date.split('-').reverse().join('-')} onChange={(e) => setDate(e, false)} style={styles.input} />
        </div>
      </Collapse>
      <div style={styles.separator} />

      <div
        style={{ ...styles.option, fontWeight: selected === 2 ? 500 : 10 }}
        onClick={() => {
          setSelected(2);
            VM.plannerTime.setExitNow(false);
            VM.plannerTime.setArriveBy(true);
            VM.plannerTime.setTime(arriveTime.time);
          VM.plannerTime.setDate(arriveTime.date);
        }}>
        {t('arriveAt')}
      </div>
      <Collapse isOpened={selected === 2}>
        <div style={{ display: 'flex' }}>
          <Input type={'time'} initialValue={arriveTime.time} onChange={(e) => setHoursMinute(e, true)} style={styles.input} />
          <Input type={'date'} initialValue={arriveTime.date.split('-').reverse().join('-')} onChange={(e) => setDate(e, true)} style={styles.input} />
        </div>
      </Collapse>
      <div style={styles.separator} />
    </div>
  );
}
