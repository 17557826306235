import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import GeneralUtils from '../../../utils/GeneralUtils';
import { onLoginMakeUseCase } from '../../hooks';
import { accountInformation, authSlice } from '../../slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../slices/eventsModalstoggleVisibilitySlice';

export interface LoginRequest {
  email: string;
  password: string;
}

export const authServiceApi = createApi({
  reducerPath: 'authServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GeneralUtils.getEnvironment(window.location.origin)
  }),
  endpoints: (builder) => ({
    login: builder.query<any, any>({
      query: (_arg) => ({
        url: 'user/login',
        method: 'POST',
        body: { ..._arg },
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        }
      }),
      async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
        onLoginMakeUseCase(dispatch, queryFulfilled);
        try {
          
          await queryFulfilled;
        } catch (err: any) {
        } finally {
          
        }
      }
    }),
    deleteAccount: builder.mutation<any, any>({
      query: ({ token }) => ({
        url: 'user/delete-user',
        method: 'DELETE',
        headers: {
          authorization: 'Bearer ' + token
        }
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {}
      }
    }),

    UserMe: builder.query<any, any>({
      query: (p) => ({
        url: 'user/me',
        method: 'GET',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(authSlice.actions.setUserMe(result.data));
          if (arg) {
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(false)
            );
          }
        } catch (error:any) {
          if(error?.error?.data==="Token invÃ¡lido" && error?.error?.originalStatus===403){
            localStorage.removeItem('accountInformation');
            location.reload()
          }
        }
      }
    }),
    logout: builder.query<any, any>({
      query: (p) => ({
        url: 'user/logout',
        method: 'GET',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (err) {
        } finally {
        }
      }
    }),
    createUser: builder.mutation<any, any>({
      query: (createUser: accountInformation) => ({
        url: 'user/create-user-registred',
        method: 'POST',
        body: { ...createUser, idUserType: 2 },
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        }
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          
        } catch (err) {
          
        } finally {
        }
      }
    }),
    createUserAnonimous: builder.mutation<any, any>({
      query: (createUser: accountInformation) => ({
        url: 'user/create-user-anonimous',
        method: 'POST',
        body: createUser
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          
        } catch (err) {
          
        } finally {
        }
      }
    }),
    changePass: builder.mutation<any, any>({
      query: (createUser) => ({
        url: 'user/change-pass',
        method: 'PUT',
        body: createUser,
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        }
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          
        } catch (err) {
          
        } finally {
        }
      }
    }),
    initRecoveryPwd: builder.mutation<any, any>({
      query: (createUser) => ({
        url: 'user/init-recovery-pwd',
        method: 'POST',
        body: createUser,
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        }
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          
        } catch (err) {
          
        } finally {
        }
      }
    }),
    endRecoveryPwd: builder.mutation<any, any>({
      query: (createUser) => ({
        url: 'user/end-recovery-pwd',
        method: 'POST',
        body: createUser
        // headers: {
        //   authorization:
        //     'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        // }
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          
        } catch (err) {
          
        } finally {
        }
      }
    }),
    updateUser: builder.mutation<any, any>({
      query: (updateUser) => ({
        url: 'user/',
        method: 'PUT',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        },
        body: updateUser
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          
        } catch (err) {
          
        } finally {
        }
      }
    }),
    updateLocale: builder.mutation<any, any>({
      query: (updateLocale) => ({
        url: 'user/language',
        method: 'PUT',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        },
        body: updateLocale
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          
        } catch (err) {
          
        } finally {
        }
      }
    }),
    changePasswordUser: builder.mutation<any, any>({
      query: (newCredentials) => ({
        url: 'user/change-pass',
        method: 'PUT',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        },
        body: newCredentials
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          
        } catch (err) {
          
        } finally {
        }
      }
    }),
    activateUser: builder.mutation<any, any>({
      query: (activateUser) => ({
        url: 'user/activate-user',
        method: 'POST',
        // headers: {
        //   authorization:
        //     'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        // },
        body: activateUser
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        
        try {
          const { data } = await queryFulfilled;
          
        } catch (err) {
          
        } finally {
        }
      }
    }),
    protected: builder.mutation({
      query: () => 'protected'
    })
  })
});
export const {
  useLoginQuery,
  useLogoutQuery,
  useUserMeQuery,
  useCreateUserMutation,
  useProtectedMutation,
  useInitRecoveryPwdMutation,
  useEndRecoveryPwdMutation,
  useUpdateUserMutation,
  useChangePasswordUserMutation,
  useCreateUserAnonimousMutation,
  useUpdateLocaleMutation,
  useActivateUserMutation,
  useDeleteAccountMutation
} = authServiceApi;
