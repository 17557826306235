import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';

export interface filtersInformation {
    modes: Array<any>;
    categories: Array<any>;
    motorized: Array<any>;
    operators: Array<any>;
}

const initialState = {
    filtersInformation: {
        modes: [],
        categories: [],
        motorized: [],
        operators: [],
    },
};

export const filtersSlice = createSlice({
    name: 'filtersSlice',
    initialState,
    reducers: {
        updateModes: (state, action) => {
            state.filtersInformation.modes = action.payload;
        },
        updateCategories: (state, action) => {
            state.filtersInformation.categories = action.payload;
        },
        updateMotorized: (state, action) => {
            state.filtersInformation.motorized = action.payload;
        },
        updateOperators: (state, action) => {
            state.filtersInformation.operators = action.payload;
        },
        reset: (state) => {
            state.filtersInformation = {
                modes: [],
                categories: [],
                motorized: [],
                operators: [],
            }
        },
    },
});

export const filtersInformation = (state: RootState) => state.filtersSlice.filtersInformation;
