import React, {useState, useEffect} from 'react';
import {useResponsive} from "../../../../../../context/responsiveContext";
import { useTheme } from '../../../../../../context/themeContext';
import { Collapse } from 'react-collapse';
import ButtonIcon from '../../../../../../components/commons/ButtonIcon';
import { R } from '../../../../../../R';
import { useTranslate } from '../../../../../../context/languageContext';
import OperatorsFilters from '../operatorsFilters/OperatorsFilters';
import PoisGroupFilters from '../poisFilters.js/PoisGroupFilters';
import MotorizedFiltersGroup from '../motorizedFilters/MotorizedFiltersGroup';
import { VM } from '../../../../../../../viewmodel/VM';
import FilterUtils from '../../../../../../../utils/FilterUtils';
import Button from '../../../../../../components/commons/Button';
import Separator from '../atoms/Separator';
import {useDispatch, useSelector} from "react-redux";
import {filtersInformation, filtersSlice} from "../../../../../../../redux/slices/filtersSlice";
import { markersInformation } from '../../../../../../../redux/slices/markersSlice';

export default function ConfigAllFilters(props) {
    const responsive = useResponsive();
    const theme = useTheme();

    const IconUp = R.drawables.general.Ic_ChevronUpSmall;
    const IconDown = R.drawables.general.Ic_ChevronDownSmall;
    const Close = R.drawables.general.Ic_close

    const filters = useSelector(filtersInformation)

    const [pois, setPois] = useState()
    const [motor, setMotor] = useState()
    const [operator, setOperator] = useState()
    const markersInfo = useSelector(markersInformation);
    const loadPois = markersInfo.loadedTypes.pois;
    const dispatch = useDispatch()

    const t = useTranslate()

    const deleteAllFilters = () => {
        dispatch(filtersSlice.actions.reset());
        FilterUtils.filterByCategories([]);
    };

  /* Función para obtener el mensaje de selección en el caso de operadores y pois */
  const getMessageTotal = (count, showPois) => {
    let message = '';
    let totalCount = count;
    if (showPois !== undefined && showPois === false) {
        totalCount++;
    }

    if (totalCount === 1) {
      message = `${totalCount} ${t('visualizer_filter_selected')}`;
    } else if (totalCount > 1) {
      message = `${totalCount} ${t('visualizer_filters_selected')}`;
    }

    return message;
  };

    const styles={
        content: {
            position:'absolute',
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            marginInline: !responsive.isBig ?'25px' :'105px',
            marginTop: '10px',
            width: "80vw",
            maxWidth: "345px",
            minWidth: "232px",
            background: theme.colors.white,
            boxShadow: "0px 4px 4px rgba(28, 31, 32, 0.1)",
            borderRadius: "12px",
        },

        title: {
            textAlign:'left',
            fontWeight: 700,
            fontSize: "18px",
        },
        icon: {
            fontSize: "20px",
            marginLeft:'auto',
        },
        row: {
            display: "flex",
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'center',
            width: "80vw",
            maxWidth: "345px",
            minWidth: "232px",
        },
        text: {
            fontSize: '15px',
            fontWeight: '700',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        selection: {
            fontSize: '16px',
            fontWeight: '600',
            color: theme.colors.primaryMedium,
            marginLeft: 8
        },
        buttonText: {
            color: theme.colors.primaryMedium,
            fontWeight: '600',
            marginLeft:'auto',
        },
        moreFilters: {
            overflow:'auto',
            maxHeight:"58vh",
        }
    }

  return (
    <>
            <div style={styles.row}>
                <div style={styles.title}>{t('visualizer_moreFilters')}</div>
                <Close style={{cursor:'pointer', fill: theme.colors.black}} onClick={() => props.setIsOpened()}
                    />
            </div>
            <Separator style={{marginBottom:10, marginTop:10}}/>
        <div style={styles.moreFilters}>
            <div style={styles.row}>
                <div style={styles.text}>
                  <label>{t('visualizer_operators')}</label>
                  <label style={styles.selection}>{getMessageTotal(FilterUtils.countAllAgencies(filters.operators))}</label>
                </div>
                {operator
                    ?<IconUp style={{cursor:'pointer', marginRight:15}} onClick={() => {setPois(false); setMotor(false); setOperator(!operator)}}/>
                    :<IconDown style={{cursor:'pointer', marginRight:15}} onClick={() => {setPois(false); setMotor(false); setOperator(!operator)}}/>
                }
            </div>
            <Separator style={{marginBottom:10, marginTop:10}}/>
            <Collapse isOpened={operator}>
                <OperatorsFilters/>
            </Collapse>
            {/*<div style={styles.row}>
                <div style={styles.text}>
                    <label>{t('visualizer_filters_motorized')}</label>
                    <label style={styles.selection}>{getMessageTotal(filters.motorized?.length)}</label>
                </div>
                {motor
                    ?<IconUp style={{cursor:'pointer', marginRight:15}} onClick={() => {setPois(false); setMotor(!motor); setOperator(false)}}/>
                    :<IconDown style={{cursor:'pointer', marginRight:15}} onClick={() => {setPois(false); setMotor(!motor); setOperator(false)}}/>
                }
            </div>
            <Separator style={{marginBottom:10, marginTop:10}}/>*/}
            <Collapse isOpened={motor}>
                <MotorizedFiltersGroup/>
            </Collapse>
            <div style={styles.row}>
                <div style={styles.text}>
                    <label>{t('visualizer_poi')}</label>
                    <label style={styles.selection}>{getMessageTotal(filters.categories.length, loadPois)}</label>
                </div>
                {pois
                    ?<IconUp style={{cursor:'pointer', marginRight:15}} onClick={() => {setPois(!pois); setMotor(false); setOperator(false)}}/>
                    :<IconDown style={{cursor:'pointer', marginRight:15}} onClick={() => {setPois(!pois); setMotor(false); setOperator(false)}}/>
                }
            </div>
            <Separator style={{marginBottom:10, marginTop:10}}/>
            <Collapse isOpened={pois}>
                <div style={styles.row}>
                    <PoisGroupFilters/>
                </div>
            </Collapse>
        </div>
    </>
  )
}
