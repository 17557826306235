import { useEffect, useState } from 'react';
import { VM } from '../../../../viewmodel/VM';
import TransportButton from '../../explorescreen/mapOptions/searcherOption/filters/atoms/TransportButton';
import { favoritesInformation } from '../../../../redux/slices/favoritesSlice';
import { useSelector } from 'react-redux';
import { agencyInformation } from '../../../../redux/slices/agencySlice';

export default function MyTransportGroup(props: any) {
  const [renderMyTransportGroup, setRenderMyTransportGroup] = useState(null as any);
  const agencyIds = useSelector(favoritesInformation).agencyId;
  const agencyInfo = useSelector(agencyInformation);
  const transportFavoritesFilters = [...agencyInfo.agenciesFilters];
  const [selected, setSelected] = useState(JSON.parse(JSON.stringify(agencyIds)));
  const [selectedModes, setSelectedModes] = useState<Array<any>>([]);
  let all: number[] = [];

  useEffect(() => {
    let modes: Array<any> = [];
    
    for (let i = 0; i < transportFavoritesFilters.length; i++) {
      let filtEleme: any = transportFavoritesFilters[i];
      let elementsFiltered: Array<any> = filtEleme?.agencies?.filter((element: any) =>
        selected.find((agency: any) => String(agency) === String(element?.agencyOriginId))
      );

      if (
        elementsFiltered.length === filtEleme?.agencies?.length &&
        elementsFiltered.length - 1 !== 0
      ) {
        modes.push(filtEleme?.id);
      }
    }
    setSelectedModes(modes);
  }, []);

  useEffect(() => {
    async function fetchData() {
      setRenderMyTransportGroup(await getRenderMyTransportGroup());
    }
    fetchData();
  }, [selected, props.transportSelected]);

  useEffect(() => {
    setSelected(JSON.parse(JSON.stringify(props.transportSelected)));
  }, [props.transportSelected]);

  function setSelectedFilter(mode: any, agencyId: any) {
    let copySelected = [...selected];
    let indexFind = selected.findIndex((element: any) => String(element) === String(agencyId));

    //gestión en caso de pertenecer a un grupo
    let agenciesByMode: any = transportFavoritesFilters.find(
      (element: any) => String(element.id) === String(mode)
    );

    let agencyGrouped: any = agenciesByMode?.agencies?.find(
      (element: any) =>
        String(element?.agencyOriginId) === String(agencyId) && element?.agencyIdsGroup?.length > 0
    );

    if (agencyGrouped) {
      if (indexFind === -1) {
        //añadir
        agencyGrouped.agencyIdsGroup.forEach((element: any) => {
          copySelected.push(element);
        });
      } else {
        //eliminar
        agencyGrouped.agencyIdsGroup.forEach((element: any) => {
          let indexAgencyOfGroup = copySelected.findIndex(
            (agencyGroupId: any) => String(agencyGroupId) === String(element)
          );
          if (indexAgencyOfGroup !== -1) {
            copySelected.splice(indexAgencyOfGroup, 1);
          }
        });
      }
    } else {
      if (indexFind === -1) {
        //añadir
        copySelected.push(agencyId);
      } else {
        //eliminar
        copySelected.splice(indexFind, 1);
      }
    }
    //props.setSelectedTransports(copySelected);
    props.setTransportSelected(copySelected);
  }

  function setSelectedAllMode(modeId: any, isSelected: boolean) {
    //busca el modo seleccionado
    let selectedMode: number = selectedModes.findIndex(
      (element: any) => String(element) === String(modeId)
    );
    let newModes = [...selectedModes];

    if (isSelected) {
      //Si lo encuentra lo elimina
      newModes.splice(selectedMode, 1);
      let copyFavoriteAgencies = [...selected];

      let foundMode: any = transportFavoritesFilters.find(
        (element: any) => String(element.id) === String(modeId)
      );
      let allAgencies: Array<any> = foundMode?.agencies
        ? JSON.parse(JSON.stringify(foundMode?.agencies))
        : [];
      //borrado de las agencias incluidas
      for (let i = 0; i < allAgencies.length; i++) {
        let indexAgency = copyFavoriteAgencies.findIndex(
          (element: any) => String(element) === String(allAgencies[i]?.agencyOriginId)
        );

        //gestión en caso de ser un grupo
        if (allAgencies[i]?.agencyIdsGroup?.length > 0) {
          //se recorre el array de ids de la agencia del grupo y se elimina cada uno de los que haya
          allAgencies[i]?.agencyIdsGroup.forEach((element: any) => {
            let indexInAgencyFavsGrouped = copyFavoriteAgencies.findIndex(
              (agencyIdFav: number) => agencyIdFav === element
            );
            if (indexInAgencyFavsGrouped !== -1) {
              copyFavoriteAgencies.splice(indexInAgencyFavsGrouped, 1);
            }
          });
        } else {
          if (indexAgency !== -1) {
            copyFavoriteAgencies.splice(indexAgency, 1);
          }
        }
      }

      props.setTransportSelected(copyFavoriteAgencies);
    } else {
      //si no lo encuentra obtiene todas las agencias de ese modo
      newModes.push(modeId);
      let newsAgencies = [...selected];
      let foundMode: any = transportFavoritesFilters.find(
        (element: any) => String(element.id) === String(modeId)
      );
      let allAgencies: Array<any> = foundMode?.agencies
        ? JSON.parse(JSON.stringify(foundMode?.agencies))
        : [];
      allAgencies.shift();
      for (let i = 0; i < allAgencies.length; i++) {
        //gestión en caso de ser un grupo
        if (allAgencies[i]?.agencyIdsGroup?.length > 0) {
          allAgencies[i]?.agencyIdsGroup.forEach((element: any) => {
            //en caso de no encontrarse añadido
            if (!selected.find((agencyId: any) => String(agencyId) === String(element))) {
              newsAgencies.push(element);
            }
          });
        } else {
          if (
            !selected.find(
              (agencyId: any) => String(agencyId) === String(allAgencies[i].agencyOriginId)
            )
          ) {
            newsAgencies.push(allAgencies[i]?.agencyOriginId);
          }
        }
      }
      props.setTransportSelected(newsAgencies);
    }
    setSelectedModes(newModes);
  }

  const addRemoveSelected = (agencyId: any) => {
    //TO CHANGE ALL
    let aux = JSON.parse(JSON.stringify(selected));
    let transportSelected = JSON.parse(JSON.stringify(props.transportSelected));
    if (aux.indexOf(agencyId) < 0) {
      aux.push(agencyId);
      props.setTransportSelected(
        transportSelected.concat(aux.filter((x: never) => !transportSelected.includes(x)))
      );
    } else {
      aux.splice(aux.indexOf(agencyId), 1);
      props.setTransportSelected(transportSelected.filter((x: never) => aux.includes(x)));
    }
    setSelected(aux);
  };

  const addRemoveAll = () => {
    let aux = JSON.parse(JSON.stringify(selected));
    let transportSelected = JSON.parse(JSON.stringify(props.transportSelected));

    if (JSON.stringify(aux.sort()) != JSON.stringify(all.sort())) {
      aux = all;
      props.setTransportSelected(
        transportSelected.concat(aux.filter((x: never) => !transportSelected.includes(x)))
      );
    } else {
      aux = [];
      props.setTransportSelected(transportSelected.filter((x: never) => !selected.includes(x)));
    }
    setSelected(aux);
  };

  const getRenderMyTransportGroup = async () => {
    let res = [];
    let agencies = [];
    all = [];

    let agenciesByMode: any = JSON.parse(
      JSON.stringify(
        transportFavoritesFilters.find((element: any) => element?.id === props.mode.id)
      )
    );
    let filtered = agenciesByMode?.agencies;

    for (let operator in filtered) {
      if (filtered[operator].id !== 0) {
        let agencyIcon = await VM.icons.getIcon(filtered[operator].agencyId, 'agency');
        if (!agencyIcon) agencyIcon = await VM.icons.getIcon(filtered[operator].icon);
        let isGrouped = filtered[operator]?.agencyIdsGroup?.length > 0;
        let hasSelected;

        if (isGrouped) {
          //busca si se ha seleccionado algun elemento sino lo pondrá a deseleccionado
          hasSelected =
            filtered[operator]?.agencyIdsGroup?.find((idAgencyOfGroup: any) =>
              selected?.find((agency: any) => String(agency) === String(idAgencyOfGroup))
            ) !== undefined;
        } else {
          hasSelected = true;
        }

        if (
          selected?.find(
            (agency: any) => String(agency) === String(filtered[operator].agencyOriginId)
          ) &&
          hasSelected
        ) {
        } else {
          hasSelected = false;
        }

        all.push(filtered[operator].id as never);
        agencies.push(
          <TransportButton
            key={operator}
            selected={hasSelected}
            style={{ width: '45px', marginInline: 8 }}
            icon={agencyIcon}
            onClick={() => setSelectedFilter(props.mode.id, filtered[operator].agencyOriginId)}
            name={filtered[operator]?.shortName}
          />
        );
      }
    }

    let transportIcon = await VM.icons.getIcon(props?.mode?.iconId);
    let areAllSelected = false;
    let copyAgencies = JSON.parse(JSON.stringify(agenciesByMode?.agencies));
    copyAgencies.shift();
    let elementsFiltered: Array<any> = copyAgencies?.filter((element: any) =>
        selected.find((agency: any) => String(agency) === String(element?.agencyOriginId))
    );

    if (elementsFiltered.length === copyAgencies?.length &&
        elementsFiltered.length !== 0) {
            areAllSelected = true;  
    }

    res.push(
      <div
        className={'row'}
        style={{ alignItems: 'normal', flexWrap: 'wrap' }}
        key={props?.mode?.id}>
        <TransportButton
          style={{ width: '45px', marginInline: 8 }}
          icon={transportIcon}
          selected={
            areAllSelected
          }
          onClick={() => setSelectedAllMode(props?.mode?.id, areAllSelected)}
          name={props?.mode?.label}
        />
        {agencies}
      </div>
    );

    res.push(<div key={'divider'} className={'divider'} />);
    return res;
  };

  return <div>{renderMyTransportGroup}</div>;
}
