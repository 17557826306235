export const colors = {
    main: '#1A4290',
    mainLight: '#3490FF',
    mainDark: '#004ECB',

    textPrimary: '#F8F8F8',
    textSecondary: '#EDEDED',
    textContrast: '#F8F8F8',

    background: '#161616',
    backgroundElevate: '#282828',

    elementFaded: '#303030',
    elementDivider: '#383838',

    white: '#FFFFFF',
    black: '#000000',
    transparent: '#00000000',
};
