import React, { useEffect, useRef, useState } from 'react';
import Input from '../../../../../../../components/commons/Input';
import {useDebounceTerm} from '../../../../../../explorescreen/mapOptions/searcherOption/searcherModal/useDebounce';
import { VM } from '../../../../../../../../viewmodel/VM';
import { useTranslate } from '../../../../../../../context/languageContext';

export default function MaxWalkDistanceFilter() {
  const [textDistance, setTextDistance] = useState(VM.plannerConfig.data.maxWalkDistance ?? '');
  const debouncedDistance = useDebounceTerm(textDistance, 750);
  const initFilter = useRef();
  const t = useTranslate();

  useEffect(() => {
    if (!initFilter) {
      return;
    }
    if (debouncedDistance) {
      VM.plannerConfig.setMaxWalkDistance(debouncedDistance);
    } else {
      VM.plannerConfig.setMaxWalkDistance(null);
    }
  }, [debouncedDistance]);

  return (
    <>
      <div style={{ fontSize: 14, fontWeight: 600, lineHeight: '240%', textAlign: 'left' }}>
        {t('maxWalkDistance_title')}
      </div>
      <Input
        //type={'number'}
        init={true}
        placeholder={t('maxWalkDistance_placeholder')}
        addOnClear={true}
        initialValue={textDistance}
        onClear={() => {
          initFilter.current = true;
          setTextDistance('');
        }}
        onChange={(value) => {
          setTextDistance(value + '');
        }}
        onFocus={() => {initFilter.current = true;}}
      />
    </>
  );
}
