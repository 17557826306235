import { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteSubscriptionsMutation } from '../../../../../redux/login/subscriptions/SubscriptionsServiceApi';
import {
  subscriptionsInformation,
  subscriptionsSlice
} from '../../../../../redux/slices/subscriptionsSlice';
import { VM } from '../../../../../viewmodel/VM';
import { useTranslate } from '../../../../context/languageContext';
import { useTheme } from '../../../../context/themeContext';
import { R } from '../../../../R';

export default function SubscribeNews(props: any) {
  const ArrowDown = R.drawables.general.Ic_ChevronDownSmall;
  const ArrowUp = R.drawables.general.Ic_ChevronUpSmall;
  const Remove = R.drawables.general.Ic_delete;
  const t = useTranslate();
  const [removeSubscription] = useDeleteSubscriptionsMutation();
  const subscriptions: any = useSelector(subscriptionsInformation);
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const [iconType, setIconType] = useState();
  const content = useRef(null as any);

  const theme = useTheme();

  useEffect(() => {
    async function fetchData() {
      setIconType(await VM.icons.getIcon(props.notice.id, 'agency'));
    }
    fetchData();
  }, [props.notice]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickComponent);
  }, []);

  useEffect(() => () => document.removeEventListener('mousedown', handleClickComponent), []);

  const handleClickComponent = (event: any) => {
    if (content && !content.current?.contains(event.target)) {
      setOpened(false);
    }
  };

  const removeSub = () => {
    let updateSubscriptions = JSON.parse(JSON.stringify(subscriptions));
    updateSubscriptions.agencyNotices.splice(
      updateSubscriptions.agencyNotices.indexOf(props.notice.id),
      1
    );
    removeSubscription({
      stops: [],
      lines: [],
      agencyNotices: [props.notice.id]
    });
    dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
  };

  return (
    <div
      className={'sub-station-container'}
      style={{ maxHeight: window.innerHeight - 165 }}
      ref={content}>
      <div
        className={opened ? 'favorite-station-body-c' : 'favorite-station-body-o'}
        onClick={() => setOpened(!opened)}>
        <div className={'row'}>
          <img className={'agency-icon-box'} src={iconType} />
          <div className={'iconText'}>{t('news')}</div>
          {opened ? <ArrowUp className={'close'} /> : <ArrowDown className={'close'} />}
        </div>
        <div className={'subtitle marginTop5'} style={{ textAlign: 'left', textTransform: 'capitalize' }}>
          {props.notice.groupName ? props.notice.groupName : props.notice.shortName}
        </div>
      </div>

      <Collapse isOpened={opened}>
        <div className={'favorite-station-footer row'}>
          <div
            className={'row'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              removeSub();
            }}>
            <Remove style={{ width: 20, marginRight: 5, fill: theme.colors.neutral }} />
            <div className={'primaryText'} style={{ color: theme.colors.neutral }}>
              {t('delete')}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
