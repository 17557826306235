import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { RootState } from '../../../../../redux/store';
import { useTranslate } from '../../../../context/languageContext';
import { R } from '../../../../R';

export default function AlertRemovedModalWidget(props: any) {
  const t = useTranslate();
  const Check = R.drawables.general.Ic_check;
  const dispatch = useDispatch();
  const toggleVisibility = useSelector(
    (state: RootState) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAlertRemovedModalWidget());
    }, 5000);
  }, []);

  return (
    <div className="copied-snackbar" style={{ backgroundColor: '#00763D' }}>
      <p className="copied-icon">
        <Check />
      </p>
      <p>{t('alertRemoved')}</p>
    </div>
  );
}
