import Api from '../@base/Api';

export default class AgencyApi extends Api {
    constructor(url) {
        super(url);
    }

    getAllAgencies(language) {
        return super.get(`/agency/?locale=${language}`);
    }

    getAgency(language, id) {
        return super.get(`/agency/${id}?locale=${language}`);
    }

    getAllDataOrigin(language) {
        return super.get(`/agency/dataorigin?locale=${language}`);
    }

}
