import React, {useEffect, useRef, useState} from "react";
import {useTheme} from "../../context/themeContext";
import {R} from "../../R";
import TimeUtils from "../../../utils/TimeUtils";
import ButtonIcon from "./ButtonIcon";

export default function Input(props, ref) {
    const theme = useTheme();
    const input = useRef();

    function getInitialValue() {
        if (props.init) {
            return props.initialValue ?? '';
        }
        return '';
    }

    const [textSearched, setTextSearched] = useState(
        props.type==="time" && !props.noInitialValue
            ? props.initialValue
                ? props.initialValue
                :TimeUtils.getTime(Date.now())
            : props.type==="date"
            ? props.initialValue
                ? props.initialValue
                : TimeUtils.getDate(Date.now()).split('-').reverse().join('-')
            : getInitialValue());
    const Close = R.drawables.general.Ic_close

    useEffect(() => {
        if(props.onFocusOut)
        input.current.addEventListener('focusout',  (event) => {
            props.onFocusOut()
        });
        if(props?.onStart)
            props.onStart()
    },[]);

    const styles={
        content:{
            display:'flex',
            width:'100%',
            alignItems:'center'
        },
        input:{
            width: props.width ?? '100%',
            borderRadius: props.type ? '0px' : '7px',
            borderStartEndRadius: props.type ?? '0px',
            borderEndEndRadius: props.type ?? '0px',
            fontWeight:600,
            outline:'none',
            height: props.height ?? '35.5px',
            fontSize:'14px',
            background:props.background ?? theme.colors.white,
            paddingInline: props.type ? '0px' : '10px',
            border: `1.5px solid ${theme.colors.border}`,
            borderRight: props.type ? `1.5px solid ${theme.colors.transparent}` : `1.5px solid ${theme.colors.transparent}`,
            borderLeft: props.type ? `1.5px solid ${theme.colors.transparent}` : `1.5px solid ${theme.colors.border}`,
            borderTop: props.type ? `1.5px solid ${theme.colors.transparent}` : `1.5px solid ${theme.colors.border}`
        },
        icon: {
            height: props.height ? props.height+5 :'40.5px',
            fill: theme.colors.mediumGray,
            border: props.type==='text' ? `1.5px solid ${theme.colors.transparent}` : `1.5px solid ${theme.colors.border}`,
            borderBottom: `1.5px solid ${theme.colors.border}`,
            borderEndStartRadius: '0px',
            borderStartStartRadius: '0px',
            borderRadius:props.style==='text' ? '0px 0px 0px 0px' : '0px 5px 5px 5px',
            borderLeft: `0px solid ${theme.colors.transparent}`,

        }
    }

    React.useImperativeHandle(ref, () => ({
        getValue: getValue,
        setValue: setValue,
        setDisabled: setDisabled,
        getId :getId
    }));

    const getValue = () => {
        return textSearched
    }

    const setDisabled = (disabled) => {
        return input.current.disabled=disabled
    }

    const getId = () => {
        return props.id
    }

    const setValue = (value) => {
        if(!value)
            setTextSearched('')
        else setTextSearched(value)
    }

    return (
        <div style={{...styles.content, ...props.style}}>
            <input
                type={props.type ?? 'text'}
                min={props.type === 'date'  ? TimeUtils.getDate(Date.now()).split('-').reverse().join('-') : null}
                ref={input}
                onFocus={(e)=>{
                    if(props.onFocus)
                        props.onFocus(textSearched);
                }}
                onChange={()=>{
                    setTextSearched(input.current?.value)
                    if(props.onChange)
                        props.onChange(input.current?.value)
                }}
                onKeyPress={()=>{
                    if(props.onKeyPress)
                        props.onKeyPress()
                }}
                value={textSearched}
                style={{...styles.input}}
                placeholder={props.placeholder}
            />
            {(props.type==='text' || !props.type || props.addOnClear) && !props.notShowClear
                ? <div onClick={() => {
                    setTextSearched('');
                    if (props.onClear)
                        props.onClear();
                }}>
                    <ButtonIcon
                        style={styles.icon}
                        icon={Close}
                        background={props.background ?? theme.colors.white}
                        iconColor={theme.colors.mediumGray}
                        borderColor={theme.colors.border}
                    />
                </div>
                : null
            }
        </div>
    );
}

Input = React.forwardRef(Input);
