import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { searchInformation } from '../../../../../redux/slices/searchSlice';
import ButtonIcon from '../../../../components/commons/ButtonIcon';
import { useTranslate } from '../../../../context/languageContext';
import { useTheme } from '../../../../context/themeContext';
import { R } from '../../../../R';
import Button from '../../../../components/commons/Button';
import LoadMoreOtherResults from './LoadMoreOtherResults';

export default function OthersList(props: any) {
  const theme = useTheme();
  const t = useTranslate();
  const others = useSelector(searchInformation).others as any;

  useEffect(() => {}, []);

  const styles = {
    directions: {
      display: 'flex',
      cursor: 'pointer'
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 0.5,
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px'
    },
    button: {
      marginRight: '10px'
    },
    text: {
      fontSize: '14px',
      fontWeight: 600,
      color: theme.colors.black
    },
    subText: {
      fontSize: '13px',
      color: theme.colors.mediumGray
    }
  };

  const renderOthers = () => {
    let result = [];
    let updateOthers = JSON.parse(JSON.stringify(others));
    for (let another in others) {
      let info = updateOthers[another].streetName.split(',');
      updateOthers[another].name = `${
        updateOthers[another]?.type.charAt(0).toUpperCase() + updateOthers[another]?.type.slice(1)
      }: ${info[0]}`;
      updateOthers[another].typeId = 1;
      updateOthers[another].direction = info.slice(1).join(', ');
      updateOthers[another].latitude = updateOthers[another].latitide;
      let icon = R.drawables.general.Ic_location;
      result.push(
        <div
          style={styles.directions}
          key={another}
          onClick={() => props.resultClick(updateOthers[another])}>
          <ButtonIcon
            style={styles.button}
            icon={icon ?? R.drawables.general.Ic_Circled_Question}
            iconSize={25}
            iconColor={theme.colors.black}
            background={theme.colors.border}
            size={'35px'}
          />
          <div>
            <div style={styles.text}>{updateOthers[another].name}</div>
            <div style={styles.subText}>{updateOthers[another].direction}</div>
          </div>
        </div>
      );
      result.push(<div key={`separator${another}`} style={styles.separator} />);
    }
    result.pop();
    return result;
  };
  return (
    <div style={props.style}>
      {props.searching ? (
        <div key={'title'} style={{ ...styles.text, marginBottom: 15 }}>
          {t('places')}
        </div>
      ) : others?.length ? (
        <div key={'title'} style={{ ...styles.text, marginBottom: 15 }}>
          {t('places')}
        </div>
      ) : null}
      {props.searching ? (
        <ClipLoader />
      ) : others?.length ? (
        <>
          {renderOthers()}
          <LoadMoreOtherResults
            searching={props.searching}
            text={props.inputChange}
          />
        </>
      ) : null}
    </div>
  );
}
