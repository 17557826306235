import Api from '../@base/Api';

export default class LocationApi extends Api {
    constructor(url) {
        super(url);
    }

    getPois() {
        return super.get(`/poi?locale=es`);
    }

    getStops() {
        return super.post(`/agency/stops`);
    }
}
