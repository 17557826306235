import ViewModel from '../@base/ViewModel';
import {Apis} from "../../domain/Apis";

export default class IconsViewModel extends ViewModel {
    constructor() {
        super();
    }

    async getIcon(id, type, marker, transportMode) {
        if(type){
            switch (type) {
                case "category" : return await Apis.icons.getCategoryIcon(id, marker);
                case "agency" : return await Apis.icons.getAgencyIcon(id);
                case "dataOrigin" : return await Apis.icons.getDataOriginIcon(id, marker, transportMode);
                default : return await Apis.icons.get(id);
            }
        }
        else {
            return await Apis.icons.get(id);
        }
    }
}
