import { useDispatch, useSelector } from 'react-redux';
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation
} from '../../../../../../redux/login/favorite/FavoriteServiceApi';
import {
  useAddSubscriptionsMutation,
  useDeleteSubscriptionsMutation
} from '../../../../../../redux/login/subscriptions/SubscriptionsServiceApi';
import { accountInformation } from '../../../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import {
  favoritesInformation,
  favoritesSlice
} from '../../../../../../redux/slices/favoritesSlice';
import {
  subscriptionsInformation,
  subscriptionsSlice
} from '../../../../../../redux/slices/subscriptionsSlice';
import GeneralUtils from '../../../../../../utils/GeneralUtils';
import RouteUtils from '../../../../../../utils/RouteUtils';
import { VM } from '../../../../../../viewmodel/VM';
import Button from '../../../../../components/commons/Button';
import ButtonIcon from '../../../../../components/commons/ButtonIcon';
import DefaultModal from '../../../../../components/widget/Modals/DefaultModal';
import { useTranslate } from '../../../../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../../../../context/modalContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';

export default function StopMidInfo(props) {
  const theme = useTheme();
  const t = useTranslate();
  const responsive = useResponsive();
  const dispatch = useDispatch();
  const favorites = useSelector(favoritesInformation);
  const [addFavorite] = useAddFavoriteMutation();
  const [removeFavorite] = useDeleteFavoriteMutation();
  const [addSubscription] = useAddSubscriptionsMutation();
  const [deleteSubscription] = useDeleteSubscriptionsMutation();
  const setChild = useSetModalChild();
  const setOpen = useSetModalOpened();
  const account = useSelector(accountInformation);
  const subscriptions = useSelector(subscriptionsInformation);

  const updateFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    let updateSubscriptions = JSON.parse(JSON.stringify(subscriptions));
    if (updateFavorites.stops.indexOf(props.stop.id) < 0) {
      if (account.idUserType === 1) {
        setOpen(true);
        setChild(
          <DefaultModal
            title={t('loginToFav')}
            description={t('loginToFavBody')}
            alter={true}
            firstButton={t('createAccount')}
            secondButton={t('login')}
            onClickFirst={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                  true
                )
              );
            }}
            onClickSecond={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
              );
            }}
          />
        );
      } else if (subscriptions.stops.indexOf(props.stop.id) >= 0) {
        updateFavorites.stops.push(props.stop.id);
        addFavorite({
          stops: [props.stop.id],
          pois: [],
          plans: [],
          directions: []
        });
        dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#00763D',
            text: t('stopSaved2')
          })
        );
      } else {
        setOpen(true);
        setChild(
          <DefaultModal
            preTitle={t('stopSaved')}
            title={t('saveStopAlert')}
            description={t('saveStopAlertBody')}
            firstButton={t('cancel')}
            secondButton={t('activeAlert')}
            onClickSecond={() => {
              updateSubscriptions.stops.push(props.stop.id);
              addSubscription({
                stops: [props.stop.id],
                lines: [],
                agencyNotices: []
              });
              dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
                  {
                    showModal: true,
                    background: '#00763D',
                    text: t('alertAdded')
                  }
                )
              );
            }}
          />
        );
        updateFavorites.stops.push(props.stop.id);
        addFavorite({
          stops: [props.stop.id],
          pois: [],
          plans: [],
          directions: []
        });
        dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#00763D',
            text: t('stopSaved2')
          })
        );
      }
    } else if (
      updateFavorites.stops.indexOf(props.stop.id) >= 0 &&
      subscriptions.stops.indexOf(props.stop.id) >= 0
    ) {
      setOpen(true);
      setChild(
        <DefaultModal
          title={t('deleteFavConfirm')}
          onClickSecond={() => {
            updateFavorites.stops.splice(updateFavorites.stops.indexOf(props.stop.id), 1);
            removeFavorite({
              stops: [props.stop.id],
              pois: [],
              plans: [],
              directions: []
            });
            dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
                {
                  showModal: true,
                  background: '#00763D',
                  text: t('stopRemoved')
                }
              )
            );
          }}
        />
      );
    } else {
      setOpen(true);
      setChild(
        <DefaultModal
          title={t('deleteFavConfirm')}
          onClickSecond={() => {
            updateFavorites.stops.splice(updateFavorites.stops.indexOf(props.stop.id), 1);
            removeFavorite({
              stops: [props.stop.id],
              pois: [],
              plans: [],
              directions: []
            });
            dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
            updateSubscriptions.stops.splice(updateSubscriptions.stops.indexOf(props.stop.id), 1);
            deleteSubscription({
              stops: [props.stop.id],
              lines: [],
              agencyNotices: []
            });
            dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
                {
                  showModal: true,
                  background: '#00763D',
                  text: t('stopRemoved')
                }
              )
            );
          }}
        />
      );
    }
  };

  const updateSubs = () => {
    let updateSubscriptions = JSON.parse(JSON.stringify(subscriptions));

    if (subscriptions.stops.indexOf(props.stop.id) >= 0) {
      updateSubscriptions.stops.splice(updateSubscriptions.stops.indexOf(props.stop.id), 1);
      deleteSubscription({
        stops: [props.stop.id],
        lines: [],
        agencyNotices: []
      });
      dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAlertRemovedModalWidget());
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#00763D',
          text: t('alertRemoved')
        })
      );
    } else {
      if (account.idUserType === 1) {
        setOpen(true);
        setChild(
          <DefaultModal
            title={t('loginToAlerts')}
            description={t('loginToFavBody')}
            alter={true}
            firstButton={t('createAccount')}
            secondButton={t('login')}
            onClickFirst={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                  true
                )
              );
            }}
            onClickSecond={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
              );
            }}
          />
        );
      } else {
        setOpen(true);
        setChild(
          <DefaultModal
            title={t('saveAlert')}
            description={t('saveStopAlertDescription')}
            secondButton={t('activateAlerts')}
            firstButton={t('noThanks')}
            onClickFirst={() => {}}
            onClickSecond={() => {
              updateSubscriptions.stops.push(props.stop.id);
              addSubscription({
                stops: [props.stop.id],
                lines: [],
                agencyNotices: []
              });
              dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
                  {
                    showModal: true,
                    background: '#00763D',
                    text: t('alertAdded')
                  }
                )
              );
            }}
          />
        );
      }
    }

    // dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
  };

  const styles = {
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: responsive.isMobile ? 'flex-start' : 'center',
      justifyContent: 'flex-end',
      gap: responsive.isMobile ? 8 : 0,
      flexDirection: responsive.isMobile ? 'column' : 'row'
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginTop: 16,
      marginBottom: 16,
      width: '100%'
    },
    report: {
      marginLeft: 'auto'
    }
  };

  const goThere = () => {
    let coords = { latitude: props.stop.latitude, longitude: props.stop.longitude };
    let name = props.stop.name;
    let info = RouteUtils.getTypeId(props.stop, favorites);
    let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };
    VM.plannerSegments.set('destination', res);
  };

  const openAccessDeniedModal = () => {
    setOpen(true);
    setChild(
      <DefaultModal
        title={t('loginToReport')}
        description={t('loginToFavBody')}
        alter={true}
        firstButton={t('createAccount')}
        secondButton={t('login')}
        onClickFirst={() => {
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
              true
            )
          );
        }}
        onClickSecond={() => {
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
          );
        }}
      />
    );
  };
  const goToReportStopModal = () => {
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityReportModalWidget({
        showModal: true,
        element: props.stop.id,
        type:'stop'
      })
    );
  };

  const onShare = () => {
    let path = window.location.href.split('/');
    path.pop();
    path = path.join('/');
    let copy = `${path[path.length - 1] === '#' ? '' : '/#'}/stop?${GeneralUtils.convertToHex(
      `id=${props.stop.id}`
    )}`;
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityShareModalWidget({
        showModal: true,
        shareLink: path + copy,
        title: props.stop.name
      })
    );
    // setTimeout(ReactTooltip.hide, 2000);
    // navigator.clipboard.writeText(path + copy);
  };

  return (
    <div style={props.style}>
      <div style={{ ...styles.row }}>
        <Button
          icon={R.drawables.map.goThere}
          text={t('goThere')}
          iconRight={true}
          textColor={theme.colors.darkGray}
          iconSize={10}
          iconColor={theme.colors.darkGray}
          borderColor={theme.colors.border}
          onClick={goThere}
        />
        <div style={{ marginLeft: responsive.isMobile ? 0 : 10, display: 'flex' }}>
          <ButtonIcon
            icon={R.drawables.general.Ic_Reportar}
            iconSize={15}
            iconColor={theme.colors.darkGray}
            borderColor={theme.colors.border}
            background={theme.colors.white}
            onClick={account.idUserType === 2 ? goToReportStopModal : openAccessDeniedModal}
          />
          {/* <ReactTooltip event="focusin" effect={'solid'} id="main" /> */}
          <ButtonIcon
            style={{ marginLeft: 10 }}
            onClick={() => onShare()}
            icon={R.drawables.commons.share}
            iconSize={15}
            dataTip={t('copyUrl')}
            dataFor={'main'}
            iconColor={theme.colors.darkGray}
            borderColor={theme.colors.border}
            background={theme.colors.white}
          />
          <ButtonIcon
            style={{ marginLeft: 10 }}
            icon={
              subscriptions.stops.indexOf(props.stop.id) < 0
                ? R.drawables.commons.notifications
                : R.drawables.general.Ic_AlertaBlue
            }
            iconSize={15}
            iconColor={theme.colors.darkGray}
            borderColor={theme.colors.border}
            background={theme.colors.white}
            onClick={updateSubs}
          />
          <ButtonIcon
            onClick={() => updateFav()}
            style={{ marginLeft: 10 }}
            iconColor={theme.colors.darkGray}
            iconSize={15}
            icon={
              favorites.stops.indexOf(props.stop.id) < 0
                ? R.drawables.general.Ic_favorite
                : R.drawables.general.Ic_favorite_blue
            }
            background={theme.colors.white}
          />
        </div>
      </div>
      <div style={styles.separator} />
    </div>
  );
}
