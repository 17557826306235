import React, {useEffect, useRef, useState} from "react";
import {useTheme} from "../../../../../context/themeContext";
import {useLanguage, useTranslate} from "../../../../../context/languageContext";
import {useResponsive} from "../../../../../context/responsiveContext";
import PlannerSynoptic from "./PlannerSynoptic";
import MyDirections from "../../searcher/MyDirections";
import {Collapse} from "react-collapse/lib/Collapse";
import SearchCategory from "../../searcher/SearchCategory";
import Recents from "../../searcher/Recents";
import StopsList from "../../searcher/StopsList";
import PoisList from "../../searcher/PoisList";
import {VM} from "../../../../../../viewmodel/VM";
import {R} from "../../../../../R";
import UseMyLocation from "../../searcher/UseMyLocation";
import IntermediateTime from "./IntermediateTime";
import PlannerConfig from "./plannerConfig/PlannerConfig";
import PlannerMiddle from "./PlannerMiddle";
import SecurityCollapse from "../../../../../components/commons/SecurityCollapse";
import DirectionsList from "../../searcher/DirectionsList";
import {eventsModalstoggleVisibilitySlice} from "../../../../../../redux/slices/eventsModalstoggleVisibilitySlice";
import {useDispatch, useSelector} from "react-redux";
import OthersList from "../../searcher/OthersList";
import RouteUtils from "../../../../../../utils/RouteUtils";
import {favoritesInformation} from "../../../../../../redux/slices/favoritesSlice";
import GeneralUtils from "../../../../../../utils/GeneralUtils";

export default function PlannerModal(props, ref) {
    const theme = useTheme();
    const t = useTranslate();
    const responsive = useResponsive();
    const content = useRef(null);

    const [inputChange, setInputChange] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [isPlan, setIsPlan] = useState(VM.planner.data.length!==0 && !VM.planner.data.error);
    const [searching1, setIsSearching1] = useState(false);
    const [searching2, setIsSearching2] = useState(false);
    const [searching3, setIsSearching3] = useState(false);
    const [searching4, setIsSearching4] = useState(false);
    const favorites = useSelector(favoritesInformation)

    const dispatch = useDispatch()

    const [inputSelected, setInputSelected] = useState(null)
    const CloseIcon = R.drawables.general.Ic_arrowleft

    const styles={
        content: {
            position:'relative',
            padding: "12px",
            marginLeft: !responsive.isBig ?'25px' :'105px',
            marginTop: '25px',
            maxWidth: "345px",
            minWidth: "232px",
            width: "80vw",
            background: theme.colors.white,
            boxShadow: "0px 4px 4px rgba(28, 31, 32, 0.1)",
            borderRadius: "12px",
            textAlign:'left',
            zIndex:15
        },
        search: {
            marginTop:15,
            maxHeight:window.innerHeight-200,
            overflow:'auto',
        },
        row: {
            display:'flex',
        },
        column: {
            flexDirection:'column'
        },
        icon: {
            cursor:'pointer',
            height:20
        },
        separator:{
            backgroundColor: theme.colors.border,
            height: 0.5,
            width: '100%',
            marginTop:'15px',
            marginBottom:'15px'
        },
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickComponent)
        VM.plannerSegments.listen(()=> {
            dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityRouteDetailModalWidget({showModal:false,plan:{}}))
            props.setThereIntermediates((
                Object.keys(VM.plannerSegments.data).length-
                VM.plannerSegments.data.hasOwnProperty('destination')-
                VM.plannerSegments.data.hasOwnProperty('origin')) > 0)
        });
        VM.planner.listen((data)=>
            setIsPlan(data.length!==0 && !data.error));
    },[]);

    useEffect( () => () =>document.removeEventListener('mousedown', handleClickComponent),[])

    const handleClickComponent = (event) => {
        if (content && !content.current?.contains(event.target)) {
            setIsFocused(false);
            props.setShowSecond(true)
        }
    }

    const resultClick = (place) => {
        let coords = {"latitude":place.latitude ?? place.lat,"longitude":place.longitude ?? place.lon}
        let name = place.name;
        let info = RouteUtils.getTypeId(place,favorites)
        let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };
        VM.plannerSegments.set(inputSelected.getId(),res, true)
        VM.recents.add(place)
        props.onClickMarker(place);
        setIsFocused(false)
        props.setShowSecond(true);
    }

    const useMyLocation = (coord) => {
        let coords = {"latitude":coord.latitude,"longitude":coord.longitude}
        let name = t("yourLocation");
        let info = RouteUtils.getTypeId(coord,favorites, name)
        let res = { name: name, coords: coords, typeId: info.typeId, value: info.value, location:true };
        VM.plannerSegments.set(inputSelected.getId(),res, true)
        props.onClickMarker(coord);
        setIsFocused(false)
    }


    return (
        <div ref={content} style={styles.content}>
            <CloseIcon style={styles.icon}
                       fill={theme.colors.black}
                       onClick={()=>{GeneralUtils.resetPath();VM.plannerSegments.clear()}}/>
            <PlannerSynoptic
                setOpen={props.setOpen}
                thereIntermediates={props.thereIntermediates}
                setThereIntermediates={props.setThereIntermediates}
                setNoResults={setNoResults}
                inputChange={inputChange}
                setInputSelected={setInputSelected}
                setIsSearching1={setIsSearching1}
                setIsSearching2={setIsSearching2}
                setIsSearching3={setIsSearching3}
                setIsSearching4={setIsSearching4}
                setInputChange={setInputChange}
                setIsFocused={setIsFocused}
            />
            <Collapse isOpened={isPlan}>
                <SecurityCollapse/>
                <PlannerMiddle thereIntermediates={props.thereIntermediates}
                               setThereIntermediates={props.setThereIntermediates}
                               style={{marginTop: 10}}/>
            </Collapse>

            <Collapse isOpened={isPlan}>
                <PlannerConfig/>
            </Collapse>

            {!isFocused || inputChange.length===0
                ? <div>
                    <Collapse isOpened={props.thereIntermediates}>
                        <SecurityCollapse/>
                        {/* TO CHANGE */}
                        <IntermediateTime thereIntermediates={props.thereIntermediates}
                                          setThereIntermediates={props.setThereIntermediates}/>

                    </Collapse>
                    <Collapse isOpened={isFocused && inputChange.length===0}>
                        <SecurityCollapse/>
                        <MyDirections
                            style={{marginTop: 15}}/>
                        <div style={styles.separator}/>
                        {/*<SearchCategory/>*/}
                        <Recents style={{marginTop: 15}}
                                 resultClick={resultClick}
                        />
                        <UseMyLocation style={{marginTop:15}} onClick={useMyLocation}/>
                    </Collapse>
                </div>
                : null
            }

            {isFocused && inputChange.length!==0
                ?<div style={styles.search}>
                    <OthersList searching={searching4} inputChange={inputChange} resultClick={resultClick} style={{ marginTop: 15}} />
                    <StopsList text={inputChange} searching={searching1} resultClick={resultClick}/>
                    {/*<PoisList searching={searching2} resultClick={resultClick} style={{ marginTop: 15}} />*/}
                    {/*<DirectionsList searching={searching3} resultClick={resultClick} style={{ marginTop: 15}} />*/}
                    <UseMyLocation style={{marginTop:15}} onClick={useMyLocation}/>
                </div>
                :null
            }
            {noResults && isFocused
                ?<div>
                    <div style={styles.mainText}>{t("notFound")}</div>
                    <div style={styles.secondaryText}>{t("checkSearch")}</div>
                </div>
                :null
            }
        </div>
    );
}
