import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
interface LoadedMarkerTypes {
    stops: boolean;
    pois: boolean;
    directions: boolean;
    vehicles: boolean;
}
export interface markersInformation {
    stops: Array<any> | null;
    pois: Array<any> | null;
    stations: Array<any> | null;
    vehicles: Array<any> | null;
    loadedTypes: LoadedMarkerTypes;
}

const initialState = {
    markersInformation: {
        stops: [],
        pois: [],
        stations: {filtered:[], noFiltered:[]},
        vehicles:[],
        loadedTypes: {
            stops: true,
            pois: (function getFromStorage() {
                const poiMarkerLoaded = JSON.parse(localStorage.getItem('kindMarkersPois') || '{}');
                return poiMarkerLoaded ?? false;
              })(),
            directions: true,
            vehicles: true,
        },
    },
};

export const markersSlice = createSlice({
    name: 'markersSlice',
    initialState,
    reducers: {
        updateStops: (state, action) => {
            state.markersInformation.stops = action.payload?.data;
        },
        updatePois: (state, action) => {
            state.markersInformation.pois = action.payload;
        },
        updateStations: (state, action) => {
            if(action.payload?.filter)
                state.markersInformation.stations.filtered = action.payload?.data;
            else{
                state.markersInformation.stations.noFiltered = action.payload?.data;
                state.markersInformation.stations.filtered = action.payload?.data;
            }
        },
        updateVehicles: (state, action) => {
            state.markersInformation.vehicles = action.payload;
        },
        updateLoadedTypePois: (state, action) => {
            localStorage.setItem('kindMarkersPois', JSON.stringify(action.payload))
            //AsyncStorage.setItem('kindMarkersPois', JSON.stringify(action.payload))
            state.markersInformation.loadedTypes.pois = action.payload;
        },
    },
});

export const markersInformation = (state: RootState) =>
    state.markersSlice.markersInformation;
