import { ClipLoader } from 'react-spinners';
import { useTranslate } from '../../context/languageContext';
import { useLoading } from '../../context/loadingContext';
import { useTheme } from '../../context/themeContext';

export default function Loading(props) {
  const theme = useTheme();
  const t = useTranslate();

  const loading = useLoading();

  const styles = {
    content: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999
    },
    loading: {
      display: 'flex',
      backgroundColor: theme.colors.white,
      position: 'fixed',
      padding: '15px',
      alignItems: 'center',
      borderRadius: 16,
      borderWidth: '1px',
      borderColor: theme.colors.border
    },
    text: {
      fontWeight: '600',
      fontSize: '16px',
      textAlign: 'center',
      marginLeft: '15px'
    },
    background: {
      position: 'fixed',
      width: props.width ?? '100%',
      height: props.height ?? '100%',
      backgroundColor: theme.colors.black,
      opacity: 0.5
    }
  };

  return loading ? (
    <div style={styles.content}>
      <div style={styles.background} />
      <div style={styles.loading}>
        <ClipLoader
          color={props.color ?? theme.colors.black}
          speedMultiplier={props.speed ?? 0.5}
          size={props.size ?? 30}
        />
        <label style={styles.text}>{t('component_loading')}</label>
      </div>
    </div>
  ) : null;
}
