import React, {useContext, createContext, useState, useEffect} from 'react';
import { VM } from '../../viewmodel/VM';
import { useLanguage } from './languageContext';

const FiltersContext = createContext();

export function useFilters() {
    return useContext(FiltersContext);
  }

export default function FiltersProvider({children}) {
  const [filters, setFilters] = useState(null);
  const [transportModes, setTransportModes] = useState(null);
  const [operators, setOperators] = useState(null);
  const [poisCategories, setPoisCategories] = useState(null);
  const [motorized, setMotorized] = useState(null);
  const language = useLanguage()

  useEffect(() => {
    VM.modes.get(language).then(() => setTransportModes(VM.modes.data))
    VM.operatorsFilters.get(language).then(() => setOperators(VM.operatorsFilters.data))
    VM.categoriesPoiFilters.get(language).then(() => setPoisCategories(VM.categoriesPoiFilters.data))
    VM.motorized.get(language).then(() => setMotorized(VM.motorized.data))
    setFilters({modes: transportModes?.modes, agencies: operators?.agencies, poisCategories: poisCategories?.categories, motorized: motorized?.motorized})
  }, [language])

  return (
    <FiltersContext.Provider value={transportModes}>
        {children}
    </FiltersContext.Provider>
  )
}
