import React, { useEffect, useState } from 'react';
import Button from '../../../../components/commons/Button';
import { ClipLoader } from 'react-spinners';
import { useLanguage, useTranslate } from '../../../../context/languageContext';
import { useSelector } from 'react-redux';
import { searchInformation, searchSlice } from '../../../../../redux/slices/searchSlice';
import { StopInfo } from '../../../../../types/ExploreIntefaces';
import { ILine, IStopProps } from '../../../../../types/LineInterfaces';
import { useGetLinesWithHeadsignsMutation } from '../../../../../redux/login/line/lineServiceApi';
import { useDispatch } from 'react-redux';
import { useSearchStopsMutation } from '../../../../../redux/login/search/SearchServiceApi';

interface LoadMoreStopsResultsProps {
  searching: boolean;
  text?: string;
}

export default function LoadMoreStopsResults(props: LoadMoreStopsResultsProps) {
  const [numPage, setNumPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const t = useTranslate();
  const stops = useSelector(searchInformation).stops as any;

  const [getLinesWithHeadsigns] = useGetLinesWithHeadsignsMutation();
  const [searchStops] = useSearchStopsMutation();
  const language = useLanguage();
  const dispatch = useDispatch();

  async function renderMoreResults(mounted: boolean) {
    const copyStops = JSON.parse(JSON.stringify(stops));

    searchStops({ locale: language, txt: props.text, numPage: numPage })
      .then(async (data: any) => {
        if (data?.data && data?.data?.length > 0 && !props.searching && mounted) {
          let stopsData = copyStops.concat(
            data.data?.map((stop: any) => ({
              ...stop,
              ...{ name: stop?.stopMunicipality ? `${stop?.name} (${stop?.stopMunicipality})` : stop?.name }
            }))
          );

          let stopsCheckHeadSigns = [];
          let obtainHeadSigns = false;

          //recorrido en el que se comprueba para cada parada si alguna de sus líneas pasa por alguna otra parada de las buscadas
          for (const stop of stopsData) {
            let linesRepeated = [];
            let linesNotRepeated = [];
            if (stop?.lines) {
              for (const lineOfStop of stop?.lines) {
                //comprueba si el id de la línea es el mismo que en el caso de las otras paradas
                if (
                  stopsData?.find((element: IStopProps) => {
                    if (stop?.id === element?.id) {
                      return false;
                    }

                    return element?.lines?.find((line) => line.id === lineOfStop.id);
                  })
                ) {
                  linesRepeated.push({ id: lineOfStop?.id });
                  obtainHeadSigns = true;
                } else {
                  linesNotRepeated.push({ id: lineOfStop?.id });
                }
              }
            }

            stopsCheckHeadSigns.push({
              ...stop,
              ...{ linesRepeated: linesRepeated, linesNotRepeated: linesNotRepeated }
            });
          }
          stopsData = stopsCheckHeadSigns;

          //se llama a obtener los headsigns en caso de haber líneas repetidas
          if (obtainHeadSigns) {
            for (const stop of stopsData) {
              if (stop?.linesRepeated?.length) {
                //realizar petición y modificar el array de las repetidas para que se muestre el sentido

                const stopWithHeadsign: any = await getLinesWithHeadsigns({
                  stopId: stop.id,
                  language: language
                });

                if (stopWithHeadsign?.data) {
                  //se recorren la líneas con su sentido y se comprueba aquellas que están repetidas
                  stopWithHeadsign.data.forEach((lineHeadsigns: any) => {
                    let indexRep = stop?.linesRepeated?.findIndex(
                      (lineRep: any) => lineRep.id === lineHeadsigns.id
                    );
                    if (indexRep !== -1 && lineHeadsigns?.headsigns?.length) {
                      //en caso de ser repetida comprobar el número de headsigns
                      if (lineHeadsigns?.headsigns?.length > 1) {
                        //en este caso la línea no podrá mostrar el sentido por lo que
                        //se deberá añadir a la lista de líneas no repetidas y eliminarla de
                        //las repetidas
                        let lineDeleted = { ...stop?.linesRepeated[indexRep] };
                        stop?.linesRepeated.splice(indexRep, 1);
                        stop.linesNotRepeated.push(lineDeleted);
                      } else {
                        //en este caso añadir el headsign de la línea
                        stop.linesRepeated[indexRep].headsign = lineHeadsigns?.headsigns[0];
                      }
                    }
                  });
                }
              }
            }
          }

          dispatch(searchSlice.actions.updateStops(stopsData));
        } else {
          setEndReached(true);
        }
      })
      .finally(() => {
        mounted && setLoading(false);
      });
  }

  useEffect(() => {
    let mounted = true;

    numPage > 1
      ? (function searchLoad() {
          setLoading(true);
          renderMoreResults(mounted);
        })()
      : null;

    return () => {
      mounted = false;
    };
  }, [numPage, language, props.text]);

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        justifyContent: loading ? 'center' : 'flex-end',
        alignItems: 'center',
        marginTop: 12,
        paddingRight: 16
      }}>
      {endReached || stops?.length < 5 ? null : (
        <>
          {loading ? (
            <ClipLoader />
          ) : (
            <Button
              text={t('searchMoreResults')}
              icon={null}
              onClick={() => setNumPage(numPage + 1)}
            />
          )}
        </>
      )}
    </div>
  );
}
