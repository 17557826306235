import ViewModel from '../@base/ViewModel';
import {Apis} from "../../domain/Apis";
import FilterUtils from "../../utils/FilterUtils";
import {VM} from "../VM";
import { lineSlice } from '../../redux/slices/lineSlice';
import { connect } from 'react-redux';
import { store } from '../../redux/store';

export default class LinesViewModel extends ViewModel {
    constructor() {
        super();
        this.selected=null
    }

    async get(language) {
        const responseLines = await FilterUtils.setLineAlerts(await Apis.lines.getLines(language), language);
        store.dispatch(lineSlice.actions.updateAllLines(responseLines));
        super.notify(responseLines);
    }

    async setSelected(line, tripId, stopId, day) {
        let res=null;
        if (line) {
            let shape = await Apis.lines.getShape(line, tripId, stopId)
            let details = await Apis.lines.get(line)
            for (let coord in shape) {
                shape[coord].lat = parseFloat(shape[coord].lat)
                shape[coord].lng = parseFloat(shape[coord].lon)
            }
            let color = details.routeColor ? '#' + details.routeColor : "#2979FF"
            details['routeColor']=color;
            details['coords']=shape;
            details['alert']=VM.lines.data[line]?.alert;
            details['tripId']=tripId
            details['stopId']=stopId
            details['day']=day
            res=details
        }
        this.selected = res
        super.notify(this.data)
    }
}

/* const mapDispatchToProps = (dispatch) => {
    return {
        setAllLines: (value) => dispatch(lineSlice.actions.updateAllLines(value))
    }
}; */

//export default connect(null, mapDispatchToProps)(LinesViewModel);