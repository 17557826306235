import { useDispatch, useSelector } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useTranslate } from '../../../../../context/languageContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { R } from '../../../../../R';
import RouteSynoptic from '../routeResults/routeCard/RouteSynoptic';
import RouteDetailSynoptic from './RouteDetailSynoptic';
import ReactTooltip from "react-tooltip";
import React from "react";

export default function RouteDetail(props: any) {
  const responsive = useResponsive();
  const t = useTranslate();
  const dispatch = useDispatch();
  const Close = R.drawables.general.Ic_close;
  const WheelChair = R.drawables.general.Ic_silladeruedas;
  const Euro = R.drawables.general.Ic_Precio;

  const plan = useSelector(
    (state: any) => state.eventsModalstoggleVisibilitySlice.ModalWidget.RouteDetailModalWidget.plan
  );

  return (
    <div className={`mapOption ${responsive.isBig ? 'big' : 'small'}`}>
      <div className={'planDetail'}>
        <Close
          className={'close'}
          onClick={() =>
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityRouteDetailModalWidget({
                showModal: false,
                plan: {}
              })
            )
          }
        />
        <div className={'paddingInLine20 row'}>
          {plan?.duration / 60 < 1 ? null : (
            <div className={'secondaryText bold'}>{Math.floor(plan?.duration / 60)}</div>
          )}
          {plan?.duration / 60 < 1 ? null : <div className={'secondaryText bold'}>{t('h')}</div>}
          <div className={'subtitle'}>{plan?.duration % 60}</div>
          <div className={'secondaryText bold'}>{t('min')}</div>
          <div className={'secondaryText bold close'}>{plan?.startTime + '-' + plan?.endTime}</div>
        </div>
        <div className={'paddingInLine20'}>
          <RouteSynoptic lineSynoptic={plan?.lineSynoptic} />
          <div className={'divider'} />

          <div className={'row'}>
            <ReactTooltip effect={'solid'} id="main" />
            <div className={'row'} data-tip={t('simpleTicket')} data-for={'main'}>
              <Euro className={'darkGray margin5'} style={{ width: 18 }} />
              <div className={'secondaryText darkGray marginRight5'}>{plan?.price}</div>
            </div>
            {plan?.accessible
                ? <div className={'row'}>
                  <WheelChair className={'darkGray margin5'} style={{width: 18}}/>
                  <div className={'secondaryText darkGray marginRight5'}>{t('accessible')}</div>
                </div>
                : null
            }
          </div>
        </div>
        {/* <RouteDetailMidInfo /> */}
        <RouteDetailSynoptic />
      </div>
    </div>
  );
}
