import { useDispatch } from "react-redux";
import { useLazyGetFileQuery, usePostFileMutation } from "../redux/login/services/FileServiceApi";
import { useSetModalChild, useSetModalOpened } from "../view/context/modalContext";
import { eventsModalstoggleVisibilitySlice } from "../redux/slices/eventsModalstoggleVisibilitySlice";
import useWindowSize from "../view/context/useWindowSize";

export function useDownloadFiles() {
    const [postFile] = usePostFileMutation();
    const [getFile] = useLazyGetFileQuery();
    const setModalOpen = useSetModalOpened();
    const setModalContent = useSetModalChild();
    const {screenSize} = useWindowSize();
    const dispatch = useDispatch();

    async function downloadFileData(fileId: string, fileExtension: string, fileName: string) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true)
      );
      const result: any = await getFile(fileId);
      const validFileDocument = [
        'application/pdf',
        'application/doc',
        'application/docx',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
        '.docx',
      ]
      if (validFileDocument.includes(fileExtension)) {
        const link = document.createElement('a');
        const url = result?.data;
        link.href = url;
        link.setAttribute('download', fileName); // Nombre del archivo que se descargará
        document.body.appendChild(link);
        link.click();
    
        
        link?.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        setModalContent(<img alt={'editImage'} src={result?.data} style={{ maxWidth: screenSize.width - 64, maxHeight: screenSize.height * 0.8 }} />);
        setModalOpen(true);
      }
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false)
      );
    }

    async function uploadFile(uploadDataFile: any) {
      const formdata = new FormData();
      formdata.append('file', uploadDataFile);
      return await postFile(formdata);
    }
  
    return {downloadFileData, uploadFile};
}