import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import FilterUtils from '../../utils/FilterUtils';
export interface AgencyInformation {
  agenciesTransport: Array<any> | null;
  transportModes: Array<any> | null;
  motorizedFilter: Array<any> | null;
  dataOrigin: Array<any> | null;
  agenciesFilters: Array<any> | null;
  selectedOrigin: Array<number>;
}

const initialState = {
  agencyInformation: {
    agenciesTransport: [],
    transportModes: [],
    motorizedFilter: [],
    dataOrigin: [],
    agenciesFilters: [],
    selectedOrigin: [],
  },
};

export const agencySlice = createSlice({
  name: 'agencySlice',
  initialState,
  reducers: {
    updateAgenciesTransport: (state, action) => {
      state.agencyInformation.agenciesTransport = action.payload;
    },
    updateTransportModes: (state, action) => {
      state.agencyInformation.transportModes = action.payload;
    },
    updateSelectedValuesTransportModes: (state, action) => {
      state.agencyInformation.transportModes = action.payload;
    },
    updateSelectedOrigin: (state, action) => {
      state.agencyInformation.selectedOrigin = action.payload;
    },
    updateSelectedOriginStorage: (state, action) => {
      state.agencyInformation.selectedOrigin = action.payload;
    },
    initTransportModesFilters: (state, action) => {
      state.agencyInformation.transportModes = action.payload;
    },
    updateMotorizedFilters: (state, action) => {
      let motorizedMerged = FilterUtils.compareFilters(
        state.agencyInformation.motorizedFilter,
        action.payload,
      );
      state.agencyInformation.motorizedFilter = motorizedMerged;
    },
    updateMotorizedSelectedValues: (state, action) => {
      state.agencyInformation.motorizedFilter = action.payload;
    },
    initMotorizedFilters: (state, action) => {
      state.agencyInformation.motorizedFilter = action.payload;
    },
    updateDataOrigin: (state, action) => {
      state.agencyInformation.dataOrigin = action.payload;
    },
    initDataOrigin: (state, action) => {
      state.agencyInformation.dataOrigin = action.payload;
    },
    updateAgenciesFilters: (state, action) => {
      if (state.agencyInformation.agenciesFilters?.length > 0) {
        let comparedValues = FilterUtils.compareAgencies(
          state.agencyInformation.agenciesFilters,
          action.payload,
        );

        state.agencyInformation.agenciesFilters = comparedValues;
      } else {
        state.agencyInformation.agenciesFilters = action.payload;
      }
    },
    updateAgenciesFiltersSelectedValues: (state, action) => {
      state.agencyInformation.agenciesFilters = action.payload;
    },
    initAgenciesFilters: (state, action) => {
      state.agencyInformation.agenciesFilters = action.payload;
    },
    updateAgenciesTransportSelectedValues: (state, action) => {
      state.agencyInformation.agenciesTransport = action.payload;
    },
  },
});

export const agencyInformation = (state: RootState) =>
  state.agencySlice.agencyInformation;
