import {useTheme} from "../../../../../context/themeContext";
import {R} from "../../../../../R";

export default function LineIcon(props, ref) {
    const theme = useTheme();
    const Alert = R.drawables.commons.exclaimation
    const styles={
        content: {
            color:props.textColor ?? theme.colors.black,
            textShadow: `0 0 1px ${'black'}`,
            fontSize:props.size ? props.size-3 : 12,
            height:props.size ? props.size-3 : 12,
            display:'flex',
            alignItems:'center',
            width:'auto',
            justifyContent:'center',
            backgroundColor:props.color ?? theme.colors.white,
            border: props.icon && !props.text ? `0.1px solid ${theme.colors.border}` : 'transparent',
            padding:5,
            borderRadius:5,
            cursor:'pointer'
        },
        icon: {
            width:props.size ? props.size-3 : 15,
            height:props.size ? props.size-3 : 15,
        },
        textIcon:{
            display:'flex',
            alignItems:'center',
        },
        alert: {
            position:'relative',
            marginTop:-20,
            marginRight:-15,
            left:-5
        }
    }
    return (
        props.icon && props.text
            ?<div style={{...styles.textIcon,...props.style}} onClick={()=>{if(props.onClick)props.onClick()}}>
                <img style={{...styles.icon, ...{marginRight:1}}}
                     src={props.icon}/>
                <div style={{...styles.content,...props.style}}>{props.text}</div>
            </div>

            : props.icon
                ? <div style={{...styles.content,...props.style}} onClick={()=>{if(props.onClick)props.onClick()}}>
                    <img style={styles.icon}
                       src={props.icon}/>
                </div>
                : <div style={{...styles.content,...props.style}} onClick={()=>{if(props.onClick)props.onClick()}}>
                    {props.text}
                    {props.alert
                    ?<Alert style={styles.alert}/>
                    :null}
                </div>

    );
}
