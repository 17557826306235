import { useDispatch, useSelector } from 'react-redux';
import {
  favoriteServiceApi,
  useAddFavoriteMutation,
  useDeleteFavoriteMutation
} from '../../../../../../redux/login/favorite/FavoriteServiceApi';
import { accountInformation } from '../../../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import {
  favoritesInformation,
  favoritesSlice
} from '../../../../../../redux/slices/favoritesSlice';
import GeneralUtils from '../../../../../../utils/GeneralUtils';
import PlanUtils from '../../../../../../utils/PlanUtils';
// import TimeUtils from '../../../../../../utils/TimeUtils';
import { VM } from '../../../../../../viewmodel/VM';
import ButtonIcon from '../../../../../components/commons/ButtonIcon';
import DefaultModal from '../../../../../components/widget/Modals/DefaultModal';
import { useTranslate } from '../../../../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../../../../context/modalContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';

export default function PlannerMiddle(props, ref) {
  const theme = useTheme();
  const Report = R.drawables.general.Ic_Reportar;
  const t = useTranslate();
  const favorites = useSelector(favoritesInformation);
  const [addFavorite] = useAddFavoriteMutation();
  const [useGetFavoritesQuery, result1, lastPromiseInfo1] =
    favoriteServiceApi.endpoints.getFavorites.useLazyQuery();
  const [removeFavorite] = useDeleteFavoriteMutation();
  const setChild = useSetModalChild();
  const setOpen = useSetModalOpened();
  const dispatch = useDispatch();
  const account = useSelector(accountInformation);
  // const { data: sharePlanData, error: sharePlanError } = useSharePlanQuery(
  //   VM.planner.data.idPlanning ?? null
  // );

  const styles = {
    content: {},
    row: {
      display: 'flex'
    },
    icon: {
      cursor: 'pointer',
      fill: theme.colors.black
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      width: '100%',
      marginTop: 10,
      marginBottom: 5
    }
  };
  const goToReportPlanningModal = () => {
      dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityReportModalWidget({
              showModal: true,
              element: VM.planner?.data?.idPlanning,
              type:'plan'
          })
      );
  };

  const openAccessDeniedModal = () => {
    setOpen(true);
    setChild(
      <DefaultModal
        title={t('loginToReport')}
        description={t('loginToFavBody')}
        alter={true}
        firstButton={t('createAccount')}
        secondButton={t('login')}
        onClickFirst={() => {
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
              true
            )
          );
        }}
        onClickSecond={() => {
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
          );
        }}
      />
    );
  };

  const getPosId = () => {
    for (let favPlan in favorites.plans) {
      if (favorites.plans[favPlan].id === VM.planner.data.idPlanning) return favPlan;
    }
    return -1;
  };

  const updateFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    let pos = getPosId();

    if (pos < 0) {
      if (account.idUserType === 1) {
        setOpen(true);
        setChild(
          <DefaultModal
            title={t('loginToFav')}
            description={t('loginToFavBody')}
            alter={true}
            firstButton={t('createAccount')}
            secondButton={t('login')}
            onClickFirst={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                  true
                )
              );
            }}
            onClickSecond={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
              );
            }}
          />
        );
      } else {
        //updateFavorites.plans.push(VM.planner.data);
        addFavorite({
          stops: [],
          pois: [],
          plans: [VM.planner.data?.idPlanning],
          directions: []
        });
        useGetFavoritesQuery().then((data) => {
          if (!data.error) dispatch(favoritesSlice.actions.updateFavorites(data.data));
        });
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#00763D',
            text: t('planSaved')
          })
        );
        //dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
      }
    } else {
      setOpen(true);
      setChild(
        <DefaultModal
          title={t('deleteFavConfirm')}
          onClickSecond={() => {
            //let updateFavorites2 = JSON.parse(JSON.stringify(favorites));
            //updateFavorites2.plans.splice(updateFavorites.plans.indexOf(VM.planner.data), 1);
            removeFavorite({
              stops: [],
              pois: [],
              plans: [VM.planner.data?.idPlanning],
              directions: []
            });
            useGetFavoritesQuery().then((data) => {
              if (!data.error) dispatch(favoritesSlice.actions.updateFavorites(data.data));
            });
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
                {
                  showModal: true,
                  background: '#00763D',
                  text: t('planRemoved')
                }
              )
            );
            //dispatch(favoritesSlice.actions.updateFavorites(updateFavorites2));
          }}
        />
      );
    }
  };

  const onShare = () => {

    let path = window.location.href.split('/');
    path.pop();
    path = path.join('/');
    let intermediateOption = '';
    if (PlanUtils.getIntermediates())
      intermediateOption = `&intermediatePlaces=${PlanUtils.getIntermediates()}`;
    let copy = `${path[path.length - 1] === '#' ? '' : '/#'}/plan?${GeneralUtils.convertToHex(
        `id=${VM.planner.data?.idPlanning}`
    )}`;
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityShareModalWidget({
        showModal: true,
        shareLink: path + copy,
        title: t('sharePlanning')
      })
    );

    // setTimeout(ReactTooltip.hide, 2000);
    // navigator.clipboard.writeText(path + copy);
  };

  return (
    <div style={{ ...props.style, ...styles.content }}>
      <div style={styles.row}>
        <div style={{ ...styles.row, ...{ cursor: 'pointer', alignItems: 'center' } }}>
          <Report style={styles.icon} />
          <div
            style={{ fontWeight: 600, fontSize: 13 }}
            onClick={account.idUserType === 2 ? goToReportPlanningModal : openAccessDeniedModal}>
            {t('report')}
          </div>
        </div>

        <div style={{ ...styles.row, marginLeft: 'auto' }}>
          {/* <ReactTooltip event="focusin" effect={'solid'} id="main" /> */}
          <ButtonIcon
            icon={R.drawables.general.Ic_SocialShare}
            // dataTip={t('copyUrl')}
            dataFor={'main'}
            onClick={() => onShare()}
            background={theme.colors.white}
            borderColor={theme.colors.border}
            iconColor={theme.colors.darkGray}
            size={'35px'}
          />
          {/* TO CHANGE */}
          <ButtonIcon
            style={{ marginLeft: 5 }}
            icon={R.drawables.general.Ic_clock}
            background={theme.colors.white}
            borderColor={theme.colors.border}
            onClick={() => {
              props.setThereIntermediates(!props.thereIntermediates);
              VM.planner.clear();
            }}
            iconColor={theme.colors.darkGray}
            size={'35px'}
          />
          <ButtonIcon
            style={{ marginLeft: 5 }}
            icon={
              getPosId() < 0
                ? R.drawables.general.Ic_favorite
                : R.drawables.general.Ic_favorite_blue
            }
            iconColor={theme.colors.darkGray}
            background={theme.colors.white}
            onClick={updateFav}
            size={'35px'}
          />
        </div>
      </div>
      <div style={styles.separator} />
    </div>
  );
}
