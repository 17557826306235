import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useChangePasswordUserMutation } from '../../../../redux/login/services/authServiceApi';
import { accountInformation } from '../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useTranslate } from '../../../context/languageContext';
import { useSetLoading } from '../../../context/loadingContext';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import ButtonNext from '../../commons/buttons/ButtonNext';
import SimpleModal from '../../commons/Modals/SimpleModal';
import TitleH1 from '../../commons/Titles/TitleH1';
import InputPassword from '../../inputs/InputPassword';

export default function ChangePassModalWidget(props: any) {
  const theme = useTheme();
  const Close = R.drawables.commons.close;
  const t = useTranslate();
  const styles = {
    header: {
      width: '100%',
      textAlign: 'left',
      marginBottom: '7%'
    },
    close: {
      marginLeft: 'auto',
      marginRight: '20px',
      marginTop: '20px',
      position: 'absolute',
      right: 0,
      top: 0,
      height: 10.65,
      width: 10.71
    },
    title: {
      fontSize: '22px',
      fontWeight: 700,
      color: '#1C1F20',
      marginBottom: '1.3%',
      marginTop: '10.2%'
    },
    p: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: ' 140%',
      color: '#1C1F20',
      paddingTop: 16,
      textAlign: 'left',
      margin: 0
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    label: {
      textAlign: 'left',
      // marginTop: '10px',
      // marginBottom: '20px',
      color: '#a4a8aa',
      paddingLeft: '25px',
      fontSize: 12,
      marginTop: 8,
      marginBottom: 16
    },
    overlay: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10,
      backgroundColor: '#181B1D',
      opacity: 0.2
    }
  } as const;

  const dispatch = useDispatch();
  const account = useSelector(accountInformation);
  const [changePasswordUser, { error }] = useChangePasswordUserMutation();

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const setLoading = useSetLoading();

  const [minLength, setminLength] = useState({
    key: '.*.{6,30}$',
    minLength: false
  });
  const [almostOneCapitalLetter, setalmostOneCapitalLetter] = useState({
    key: '[A-Z]',
    almostOneCapitalLetter: false
  });
  const [almostOnelowerLetter, setalmostOnelowerLetter] = useState({
    key: '[a-z]',
    almostOnelowerLetter: false
  });
  const [almostOneNumber, setalmostOneNumber] = useState({
    key: '[0-9]',
    almostOneNumber: false
  });

  function handleSetPassword(value: string) {
    setPassword(value);
    setminLength({ ...minLength, minLength: new RegExp(minLength.key).test(value) });
    setalmostOneCapitalLetter({
      ...almostOneCapitalLetter,
      almostOneCapitalLetter: new RegExp(almostOneCapitalLetter.key).test(value)
    });
    setalmostOnelowerLetter({
      ...almostOnelowerLetter,
      almostOnelowerLetter: new RegExp(almostOnelowerLetter.key).test(value)
    });
    setalmostOneNumber({
      ...almostOneNumber,
      almostOneNumber: new RegExp(almostOneNumber.key).test(value)
    });
  }

  async function sendUpdatePassword() {
    setLoading(true);
    const result: any = await changePasswordUser({
      email: account.email,
      oldPassword: oldPassword,
      password: password,
      password2: password
    });
    if (result && !result.error) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityChangePassModalWidget(false)
      );
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityStatusCodesModalWidget({
          code: 200,
          boolean: true
        })
      );
    }
    if (result.error && result.error.status) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityStatusCodesModalWidget({
          code: result.error.status,
          boolean: true
        })
      );
    }
    setLoading(false);
  }

  return (
    <>
      <div style={styles.overlay}></div>
      <SimpleModal
        style={{
          width: '85%',
          maxWidth: 400,
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 15
        }}
        header={
          <div style={styles.header}>
            <Close
              style={styles.close}
              fill={theme.colors.black}
              onClick={() => {
                dispatch(
                  eventsModalstoggleVisibilitySlice.actions.toggleVisibilityChangePassModalWidget(
                    false
                  )
                );
              }}
            />
            <TitleH1 style={styles.title} title={t('changePass')} />
            <p style={styles.p}>{t('passwordConditions')}</p>
          </div>
        }
        body={
          <form
            style={styles.form}
            onSubmit={async (e) => {
              e.preventDefault();
              sendUpdatePassword();
            }}>
            <InputPassword
              className="modal-input-white"
              label={t('oldPass')}
              labelColor="#A4A8AA"
              placeHolder={t('oldPass')}
              password={oldPassword}
              setPassword={setOldPassword}
            />
            <InputPassword
              className="modal-input-white"
              password={password}
              placeHolder={t('passNew')}
              labelColor="#A4A8AA"
              label={t('passNew')}
              setPassword={handleSetPassword}
            />
            <label>
              <ul className={'check'} style={{ color: '#A4A8AA' }}>
                <li>
                  <i className={minLength.minLength ? 'valid' : 'invalid'}></i>
                  <p>{t('charMaxAndMin')}</p>
                </li>
                <li>
                  <i
                    className={
                      almostOnelowerLetter.almostOnelowerLetter &&
                      almostOneCapitalLetter.almostOneCapitalLetter
                        ? 'valid'
                        : 'invalid'
                    }></i>
                  <p>{t('minMayusMin')}</p>
                </li>
                <li>
                  <i className={almostOneNumber.almostOneNumber ? 'valid' : 'invalid'}></i>
                  <p>{t('minNum')}</p>
                </li>
              </ul>
            </label>
            <InputPassword
              className="modal-input-white"
              password={repeatPassword}
              placeHolder={t('repeatPassNew')}
              labelColor="#A4A8AA"
              label={t('repeatPassNew')}
              setPassword={setRepeatPassword}
            />
            <label
              style={styles.label}
              className={`warning ${password != repeatPassword ? '' : 'visibilityHidden'}`}>
              {t('noMatch')}
            </label>
            <ButtonNext
              title={t('save')}
              disabled={
                !minLength.minLength ||
                !almostOneCapitalLetter.almostOneCapitalLetter ||
                !almostOnelowerLetter.almostOnelowerLetter ||
                !almostOneNumber.almostOneNumber ||
                password !== repeatPassword ||
                !!!oldPassword
              }
              // className="Botonera_dialogue margin-top-20"
              className="btn-primary"
            />
          </form>
        }
      />
    </>
  );
}
