import ViewModel from '../@base/ViewModel';
import {VM} from "../VM";

export default class PlannerSegmentsViewModel extends ViewModel {
    constructor() {
        super();
        this.toPlaceId='';
        this.fromPlaceId='';
        this.intermediatePlacesId='';
    }

    init(origin, destination)
    {
        this.data = []
        this.set(0, origin);
        this.set(1, destination);
        this.updateIds(this.data)
        this.notify(this.data);
    }

    set(index, stop, overwrite)
    {
        if(this.data[index] && !overwrite) {
            for (let i = index; i < this.data.length; i++) {
                this.data[parseInt(index)+1]=this.data[index]
            }
        }
        else this.data[index] = stop;
        this.updateIds(this.data)
        this.notify(this.data);
    }

    swap()
    {
        let lOrigin = this.data["origin"];
        this.data["origin"] = this.data["destination"];
        this.data["destination"] = lOrigin;
        this.updateIds(this.data)
        this.notify(this.data);
    }

    clear()
    {
        this.data = [];
        this.updateIds(this.data)
        this.notify(this.data);
        VM.planner.clear()
    }

    add(data)
    {
        let stop = JSON.parse(JSON.stringify(data));
        if(!stop['coords'])
            stop['coords'] = {latitude:stop.latitude, longitude:stop.longitude}
        if(!this.data['origin']) {
            this.data['origin'] = stop
            this.updateIds(this.data)
            this.notify(this.data);
        }
        else if(!this.data['destination']) {
            this.data['destination'] = stop
            this.updateIds(this.data)
            this.notify(this.data);
        }
        else {
            for(let element in this.data){
                if(!this.data[element]) {
                    this.data[element] = stop
                    this.updateIds(this.data)
                    this.notify(this.data);
                    break;
                }
            }
        }
    }

    delete(index)
    {
        if(index=='origin' || index=='destination')
            delete this.data[index]
        else this.data.splice(index,1)
        this.updateIds(this.data)
        this.notify(this.data);
        VM.planner.clear()
    }

    updateIds(data)
    {
        this.toPlaceId='';
        this.fromPlaceId='';
        this.intermediatePlacesId='';
        for(let point in data)
        {
            switch (point){
                case 'origin':
                    this.fromPlaceId = JSON.stringify({'typeId':data[point]?.typeId, 'value':data[point]?.value})
                        .replace(/["']/g, "")
                        .replace("typeId", "'typeId'")
                        .replace(",value", ";'value'");
                    break;
                case 'destination':
                    this.toPlaceId = JSON.stringify({'typeId':data[point]?.typeId, 'value':data[point]?.value})
                        .replace(/["']/g, "")
                        .replace("typeId", "'typeId'")
                        .replace(",value", ";'value'");
                    break;
                default:
                    if(this.intermediatePlacesId.length)
                        this.intermediatePlacesId += '_'+JSON.stringify({'typeId':data[point]?.typeId, 'value':data[point]?.value})
                            .replace(/["']/g, "")
                            .replace("typeId", "'typeId'")
                            .replace(",value", ";'value'");
                    else
                        this.intermediatePlacesId += JSON.stringify({'typeId':data[point]?.typeId, 'value':data[point]?.value})
                            .replace(/["']/g, "")
                            .replace("typeId", "'typeId'")
                            .replace(",value", ";'value'");
                    break;

            }
        }
    }
}
