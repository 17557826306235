import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetNotificationConfQuery,
  usePutNotificationConfMutation
} from '../../../../redux/login/services/NotificationConfServiceApi';
import { accountInformation } from '../../../../redux/slices/authSlice';
import SwitchComponent from '../../../components/commons/Switch/SwitchComponent';
import LinkCommonComponent from '../../../components/links/LinkCommonComponent';
import { useTranslate } from '../../../context/languageContext';
import { R } from '../../../R';
import SystemNotificationsScreen from './SystemNotificationsScreen';
import TrafficAlertsScreen from "./TrafficAlertsScreen";

export default function ManageNotificationsScreen(props: any) {
  const account = useSelector(accountInformation);
  const { data, isLoading, error, refetch } = useGetNotificationConfQuery('', {
    refetchOnMountOrArgChange: true
  });
  const [PutNotificationConf] = usePutNotificationConfMutation();

  const t = useTranslate();
  const Ic_Notificación_Alert = R.drawables.general.Ic_Notificación_Alert;
  const Ic_Notification_News = R.drawables.general.Ic_NotificacionNoticias;
  const Ic_Notification_Guided = R.drawables.general.Ic_Notificacion1;
  const Ic_Notification_TrafficAlerts = R.drawables.general.Ic_Alerta;

  const [platform, setPlatform] = useState('');

  const onChangePlatform = () => {
    PutNotificationConf({
      platform: data.platform === 0 ? 1 : 0,
      alerts: data?.alerts,
      news: data?.news,
      guide: data?.guide,
      updatedAt: new Date().toUTCString()
    })
      .unwrap()
      .then((payload) => {
      })
      .catch((error) => console.error('rejected', error));
  };

  const onChangeNews = () => {
    PutNotificationConf({
      platform: data?.platform,
      alerts: data?.alerts,
      news: data.news === 0 ? 1 : 0,
      guide: data?.guide,
      updatedAt: new Date().toUTCString()
    })
      .unwrap()
      .then((payload) => {
      })
      .catch((error) => console.error('rejected', error));
  };

  const onChangeGuide = () => {
    PutNotificationConf({
      platform: data?.platform,
      alerts: data?.alerts,
      news: data?.news,
      guide: data.guide === 0 ? 1 : 0,
      updatedAt: new Date().toUTCString()
    })
      .unwrap()
      .then((payload) => {
      })
      .catch((error) => console.error('rejected', error));
  };
  const onChangeAlerts = () => {
    PutNotificationConf({
      platform: data?.platform,
      alerts: data.alerts === 0 ? 1 : 0,
      news: data?.news,
      guide: data?.guide,
      updatedAt: new Date().toUTCString()
    })
      .unwrap()
      .then((payload) => {
      })
      .catch((error) => console.error('rejected', error));
  };

  return (
    <div className="account-child content">
      <div className="titleAccount">{t('manageNotifications')}</div>
      <>
        <div className="content-notifications">
          <div className="account-notifications">
            <div className="account-notifications-top">
              <Ic_Notificación_Alert onClick={() => {}} />
              <h4>{t('systemNotifications')}</h4>
              <SwitchComponent
                onCheck={onChangePlatform}
                idKey="platform"
                isChecked={data?.platform}
              />
            </div>
            <div className="account-notifications-bottom">
              <p>{t('systemNotificationsNotice')}</p>
              <LinkCommonComponent
                onClick={() => props.setChild(<SystemNotificationsScreen />)}
                title={t('seeAll')}
              />
            </div>
          </div>

          <div className="account-notifications">
            <div className="account-notifications-top">
              <Ic_Notification_Guided onClick={() => {}} />
              <h4>{t('guided')}</h4>
              <SwitchComponent onCheck={onChangeGuide} idKey="guide" isChecked={data?.guide} />
            </div>
            <div className="account-notifications-bottom">
              <p>{t('guidedNotificationsNotice')}</p>
            </div>
          </div>
          <div className="account-notifications">
            <div className="account-notifications-top">
              <Ic_Notification_TrafficAlerts onClick={() => {}} />
              <h4>{t('trafficAlerts')}</h4>
              <SwitchComponent onCheck={onChangeAlerts} idKey="alerts" isChecked={data?.alerts} />
            </div>
            <div className="account-notifications-bottom">
              <p>{t('systemNotificationsAlerts')}</p>
              <LinkCommonComponent
                  onClick={() => props.setChild(<TrafficAlertsScreen />)}
                  title={t('seeAll')}
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
