import React, {useRef} from "react";
import {useTheme} from "../../../../context/themeContext";
import {useTranslate} from "../../../../context/languageContext";
import {R} from "../../../../R";

export default function UseMyLocation(props, ref) {
    const theme = useTheme();
    const t = useTranslate();
    const content = useRef(null)

    const Location = R.drawables.map.gps

    const styles={
        content: {
            position:'relative',
            display:'flex',
            padding:5,
            cursor: 'pointer'
        },
        text: {
            fontSize:'14px',
            fontWeight:600,
            color:theme.colors.black,
        },
        icon: {
            fill:theme.colors.black,
            marginRight:10
        }
    }

    return (
        <div ref={content} style={{...styles.content, ...props.style}}
             onClick={()=> {
                 navigator.geolocation.getCurrentPosition(pos =>
                     props.onClick(pos.coords))
             }}>
            <Location style={styles.icon}/>
            <div style={styles.text}>{t("useMyLocation")}</div>
        </div>
    );
}
