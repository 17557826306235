import React, { useRef, useState } from 'react';
import Button from '../../../../components/commons/Button';
import { usePostChatResponseMutation } from '../../../../../redux/login/services/MessagesServiceApi';
import ButtonIcon from '../../../../components/commons/ButtonIcon';
import { useTheme } from '../../../../context/themeContext';
import { R } from '../../../../R';
import AddFileSelector from './AddFileSelector';
import FileEntrySending from './FileEntrySending';
import { useTranslate } from '../../../../context/languageContext';
import { useDownloadFiles } from '../../../../../utils/FileUtils';

interface InputChatCommentProps {
  messageId: number;
  setMessagesOfChat: Function;
}

export default function InputChatComment(props: InputChatCommentProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [postMessage] = usePostChatResponseMutation();
  const theme = useTheme();
  const t = useTranslate();
  const [message, setMessage] = useState('');
  const fileSelectorRef: any = useRef(null);
  const [fileTypeData, setFileTypeData] = useState('');
  const [fileData, setFileData] = useState('');
  const [localeInputData, setLocaleInputData] = useState();
  const [uploadDataFile, setUploadDataFile] = useState<any>();
  const {uploadFile} = useDownloadFiles();

  async function sendMessage(textMessage: string) {
    let fileId: any = null;
    if (uploadDataFile) {
      fileId = await uploadFile(uploadDataFile);
      if (fileId?.data) {
        fileId = fileId?.data;
      }
    }
    
    const body = {
      messageId: props.messageId,
      messageBody: textMessage,
      fileId: fileId,
    };

    await postMessage(body).then(async (result: any) => {
      if (result?.data) {
        let copy = JSON.parse(JSON.stringify(result?.data));
        copy.shift();
        props.setMessagesOfChat(copy.reverse());
      }
    });
    setLocaleInputData(undefined);
    setFileTypeData('');
    setFileData('');
    setUploadDataFile(undefined);
  }

  return (
    <>
      <div
        className="add-message-chat"
        style={isFocused ? { borderColor: 'black', borderWidth: 1.5 } : {}}>
        {localeInputData ? (
          <FileEntrySending
            fileName={fileData}
            fileType={fileTypeData}
            localeBlob={localeInputData}
            setTypeFile={(value: any) => setFileTypeData(value)}
            setLocaleInputData={(value: any) => {setLocaleInputData(value); setUploadDataFile(null);}}
            setFileData={(value: any) => setFileData(value)}
            style={{marginLeft: 0, borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: theme.colors.border}}
          />
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}>
          <input
            placeholder={t('input_chat_placeholder')}
            onFocus={(e) => {
              setIsFocused(true);
            }}
            onBlur={(e) => {
              setIsFocused(false);
            }}
            onChange={(e) => setMessage(e.currentTarget.value)}
            value={message}
            maxLength={200}
          />
          <AddFileSelector
            setTypeFile={(value: any) => setFileTypeData(value)}
            setLocaleInputData={(value: any) => setLocaleInputData(value)}
            setFileData={(value: any) => setFileData(value)}
            toUploadFile={(value: any) => setUploadDataFile(value)}
            localeInputData={localeInputData}
            style={{padding: 8}}
          />
          <ButtonIcon
            style={{
              backgroundColor:
                !localeInputData && !message
                  ? theme.colors.neutralBackground
                  : theme.colors.primaryMedium,
              borderRadius: 100,
              flexShrink: 1
            }}
            icon={R.drawables.general.Ic_send}
            stylesIcon={{ transform: 'rotate(90deg)' }}
            disabled={!localeInputData && !message}
            onClick={() => {
              sendMessage(message);
              setMessage('');
            }}
          />
        </div>
      </div>
    </>
  );
}
