import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { RootState } from '../../../../../redux/store';
import { useTranslate } from '../../../../context/languageContext';
import { R } from '../../../../R';

export default function CopiedModalWidget(props: any) {
  const t = useTranslate();
  const Circled_Info = R.drawables.general.Ic_Circled_Info;
  const dispatch = useDispatch();
  const toggleVisibility = useSelector(
    (state: RootState) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCopiedModalWidget());
    }, 5000);
  }, []);

  return (
    <div className="copied-snackbar">
      <p className="copied-icon">
        <Circled_Info />
      </p>
      <p>{t('copiedToClipboard')}</p>
    </div>
  );
}
