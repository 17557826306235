import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useSearchNoraMutation } from '../../../../../../redux/login/search/SearchServiceApi';
import { searchSlice } from '../../../../../../redux/slices/searchSlice';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import DirectionMidInfo from './DirectionMidInfo';
import GeneralUtils from "../../../../../../utils/GeneralUtils";
import LinesNearMarker from '../linesNear/LinesNearMarker';
import StopsLinesBar from '../stopsLinesNear/StopsLinesBar';

export default function DirectionDetail(props) {
  const theme = useTheme();
  const CloseIcon = R.drawables.general.Ic_close;
  const responsive = useResponsive();
  const t = useTranslate();
  const Icon =
    props.direction.typeId === 3
      ? R.drawables.general.Ic_WorkSmall
      : props.direction.typeId === 2
      ? R.drawables.general.Ic_home
      : R.drawables.general.Ic_location;
  const language = useLanguage();
  const dispatch = useDispatch();
  const [direction, setDirection] = useState();

  const [searchNora] = useSearchNoraMutation();

  const styles = {
    content: {
      position: 'absolute',
      display: 'flex',
      padding: 12,
      marginInline: !responsive.isBig ? '25px' : '105px',
      marginTop: '10px',
      width: '80vw',
      maxWidth: '345px',
      minWidth: '232px',
      flexDirection: 'column',
      background: theme.colors.white,
      boxShadow: '0px 4px 4px rgba(28, 31, 32, 0.1)',
      borderRadius: '12px'
    },
    subContent: {
      paddingInline: '12px',
      textAlign: 'left',
      alignContent: 'center'
    },
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center'
    },
    icon: {
      marginRight: 10,
      padding: 5,
      width: 15,
      height: 15,
      borderRadius: 5,
      fill: theme.colors.black,
      background: theme.colors.border
    },
    secondaryText: {
      color: theme.colors.textSecondary,
      fontSize: 13,
      fontWeight: 500
    },
    mainText: {
      color: theme.colors.textPrimary,
      fontSize: 15,
      fontWeight: 600,
      marginTop: 5
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginBottom: 10,
      marginTop: 10,
      width: '100%'
    }
  };

  useEffect(() => {
    if (props.direction.double) {
      setDirection(null);
      search(props.direction?.name);
    } else setDirection(props.direction);
    let copy = `${GeneralUtils.getBaseUrl()}/#/direction?${GeneralUtils.convertToHex(
        `coords=${props.direction.latitude},${props.direction.longitude}`
    )}`;
    window.history.pushState('a','a', copy)
  }, [props.direction]);

  const search = (text) => {
    searchNora({ locale: language, txt: text })
      .then((data) => {
        getDataStreet(data.data[0]);
        dispatch(searchSlice.actions.updateDirections(data.data));
      })
      .catch(() => {
        let aux = props.direction;
        aux.direction = aux.name;
        setDirection(aux);
        props.setMarkerSelected(aux);
      });
  };

  const getDataStreet = (data) => {
    let direction = JSON.parse(JSON.stringify(data));
    if (
      direction.via &&
      direction.via !== 'null' &&
      direction.street &&
      direction.street !== 'null'
    ) {
      direction.name = `${direction.via} ${direction.street}`;
    } else {
      direction.name = direction.municipality;
    }
    if (direction.portal !== 0 && direction.street && direction.street !== 'null') {
      direction.direction = `${direction.street} ${direction.portal}, ${
        direction.locality ?? direction.municipality
      }${direction?.postalCode ? ', ' + direction?.postalCode : null}`;
    } else {
      direction.direction =
        direction.locality ??
        direction.municipality + `${direction?.postalCode ? ', ' + direction?.postalCode : null}`;
    }
    direction.typeId = 1;
    setDirection(direction);
    props.setMarkerSelected(direction);
  };

  const onDelete = (marker) => {
    search(marker.latitude + ',' + marker.longitude);
  };

  return (
    <div style={styles.content}>
      <CloseIcon
        style={{ cursor: 'pointer', marginLeft: 'auto' }}
        fill={theme.colors.black}
        onClick={() => props.onClose()}
      />

      <div style={styles.subContent}>
        <div style={styles.row}>
          <Icon style={{ ...styles.icon }} />
          <div style={styles.secondaryText}>{t('direction')}</div>
        </div>
        {direction ? (
          <div>
            <div style={styles.mainText}>
              {props.direction.typeId === 3
                  ? t('work')
                  : props.direction.typeId === 2
                      ? t('home')
                      : direction?.alias ? direction?.alias : direction?.name}
            </div>
            <div style={{ ...styles.secondaryText }}>{direction?.direction}</div>
          </div>
        ) : (
          <div className={'margin5'}>
            <ClipLoader speedMultiplier={0.5} size={20} />
          </div>
        )}
      </div>

      <DirectionMidInfo direction={direction} onDelete={onDelete} />
      <div style={styles.separator} />
      <div style={{flex: 1, overflowY: 'scroll', marginTop: 8}}>
        <StopsLinesBar
          latitude={props.direction.latitude}
          longitude={props.direction.longitude}
          setMarkerSelected={props.setMarkerSelected}
        />
      </div>
    </div>
  );
}
