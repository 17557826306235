import { getFingerprint } from 'react-fingerprint';
import { useDispatch, useSelector } from 'react-redux';
import {
  authServiceApi,
  useCreateUserAnonimousMutation,
  useDeleteAccountMutation
} from '../../../../redux/login/services/authServiceApi';
import { accountInformation, authSlice } from '../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { VM } from '../../../../viewmodel/VM';
import { useLanguage, useTranslate } from '../../../context/languageContext';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import Button from '../../commons/Button';
import ButtonNext from '../../commons/buttons/ButtonNext';
import SimpleModal from '../../commons/Modals/SimpleModal';
import TitleH1 from '../../commons/Titles/TitleH1';
import { dateNewsState, newsSlice } from '../../../../redux/slices/newsSlice';
import { useUpdateDateNewsMutation } from '../../../../redux/login/news/NewsServiceApi';

export default function RemoveAccountModalWidget(props: any) {
  const theme = useTheme();
  const t = useTranslate();
  const Close = R.drawables.commons.close;
  const [editNewsDate] = useUpdateDateNewsMutation();

  const styles = {
    header: {
      padding: '6% 6% 0 6%'
    },
    body: {
      padding: '0 6% 6% 6%',
      width: '100%'
    },

    close: {
      marginLeft: 'auto',
      marginRight: '20px',
      marginTop: '20px',
      cursor: 'pointer',
      position: 'absolute',
      right: 0,
      top: 0,
      height: 10.65,
      width: 10.71
    },
    p: {
      fontFamily: 'Open Sans',
      textAlign: 'left',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: ' 140%',
      color: '#1C1F20'
    }
  } as const;

  const dispatch = useDispatch();
  const token = useSelector(accountInformation).token;
  const [deleteAccount] = useDeleteAccountMutation();
  const [createUserAnonimous] = useCreateUserAnonimousMutation();
  const [useLogoutQuery, result, lastPromiseInfo] = authServiceApi.endpoints.logout.useLazyQuery();
  const newsDate = useSelector(dateNewsState);

  const language = useLanguage();
  const waitFingerprint = async () => {
    return await getFingerprint();
  };

  async function onConfirm() {
    const fingerPrint = await waitFingerprint();
    await deleteAccount({ token })
      .then(async () => {
        const temp = {
          alias: null,
          idGender: null,
          rangoEdad: null,
          password: null,
          password2: null,
          idUserType: 1,
          locale: language,
          typeDeviceId: process.env.REACT_APP_typeDeviceId,
          tokenDevice: fingerPrint
        };

        localStorage.removeItem('accountInformation');
        const result = await createUserAnonimous(temp);
        if (result && 'data' in result) {
          const anonUser = { ...temp, ...result.data };
          dispatch(authSlice.actions.setUserMe(anonUser));
          await editNewsDate({}).then(() => {
            dispatch(newsSlice.actions.updateDateNews(newsDate));
          });
        }

        if (result) {
          dispatch(authSlice.actions.setUserMe(temp));
        }
      })
      .catch((e) => {});
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityRemoveAccountModalWidget(false)
    );
    VM.screenSelected.setSelected('explore');
  }

  return (
    <SimpleModal
      background="#ffffff"
      style={{
        maxWidth: 400,
        display: 'flex',
        width: '100%'
      }}
      header={
        <div style={styles.header}>
          <Close
            style={styles.close}
            fill={theme.colors.black}
            onClick={() =>
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityRemoveAccountModalWidget(
                  false
                )
              )
            }
          />
          <TitleH1 title={t('removeAccount')} color="#1C1F20" />
        </div>
      }
      body={
        <div style={styles.body}>
          <p style={styles.p}>{t('removeAccountNotice')}</p>
        </div>
      }
      footer={
        <div
          style={{
            display: 'flex',
            margin: '0 6% 6% 6%',
            width: '100%',
            justifyContent: 'flex-start',
            gap: '6%'
          }}>
          <ButtonNext
            className="btn-primary btn-height-40"
            dispatch={() => onConfirm()}
            title={t('confirm')}
          />
          <Button
            text={t('cancel')}
            className="mg-left"
            textColor="#61686B"
            fontSize="14px"
            fontWeight="600"
            onClick={() =>
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityRemoveAccountModalWidget(
                  false
                )
              )
            }
          />
        </div>
      }
    />
  );
}
