import { getFingerprint } from 'react-fingerprint';
import { useDispatch, useSelector } from 'react-redux';
import {
  authServiceApi,
  useCreateUserAnonimousMutation
} from '../../../redux/login/services/authServiceApi';
import { accountInformation, authSlice } from '../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { favoritesSlice } from '../../../redux/slices/favoritesSlice';
import { subscriptionsSlice } from '../../../redux/slices/subscriptionsSlice';
import { useLanguage, useTranslate } from '../../context/languageContext';
import { useResponsive } from '../../context/responsiveContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';
import { useUpdateDateNewsMutation } from '../../../redux/login/news/NewsServiceApi';
import { dateNewsState, newsSlice } from '../../../redux/slices/newsSlice';

export default function LoginRegister(props, { style }) {
  const theme = useTheme();
  const t = useTranslate();
  const language = useLanguage();
  const responsive = useResponsive();
  const account = useSelector(accountInformation);
  const [useLogoutQuery, result, lastPromiseInfo] = authServiceApi.endpoints.logout.useLazyQuery();
  const [createUserAnonimous] = useCreateUserAnonimousMutation();
  const [editNewsDate] = useUpdateDateNewsMutation();
  const newsDate = useSelector(dateNewsState);

  const dispatch = useDispatch();
  const Login = R.drawables.general.Ic_login;
  const Register = R.drawables.general.Ic_Acount;
  const Logout = R.drawables.general.Ic_logout;

  const createAnonymousUser = async (userFromLocalStorage) => {
    const fingerPrint = await waitFingerprint();
    const tempAccount = {
      alias: null,
      idGender: null,
      rangoEdad: null,
      idUserType: 1,
      locale: language,
      typeDeviceId: process.env.REACT_APP_typeDeviceId,
      tokenDevice: fingerPrint
    };
    const result = await createUserAnonimous(tempAccount);
    if (result && result.data) {
      const anonUser = { ...tempAccount, ...result.data };
      dispatch(authSlice.actions.setUserMe(anonUser));
    }
  };
  const waitFingerprint = async () => {
    return await getFingerprint();
  };

  async function logout() {
    useLogoutQuery().then(async (data) => {
      localStorage.removeItem('accountInformation');
      if (data && !data.error) {
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#00763D',
            text: t('logoutSuccess')
          })
        );
        dispatch(favoritesSlice.actions.updateFilterNewsAlerts(false));
        dispatch(favoritesSlice.actions.resetFavorites());
      } else {
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#A31610',
            text: t('serviceError'),
            status: 'error'
          })
        );
      }
      dispatch(favoritesSlice.actions.resetFavorites());
      dispatch(subscriptionsSlice.actions.resetSubscriptions());
      await createAnonymousUser().then(async () => {
        await editNewsDate({}).then(() => {
          dispatch(newsSlice.actions.updateDateNews(newsDate));
        });
      });
    });
    if (result && result.error == null) {
    }
  }

  const styles = {
    textBar: {
      textAlign: 'left ',
      fontStyle: 'normal',
      width: '100px',
      paddingInline: 15,
      fontWeight: 600,
      color: theme.colors.lightShort,
      cursor: 'pointer',
      transition: 'all 300ms linear',
      fontSize: '12px',
      opacity: 1
    },

    separator: {
      backgroundColor: theme.colors.mediumDark,
      height: 2,
      width: '100%'
    },

    icon: {
      height: '18px',
      width: '18px',
      fill: theme.colors.lightShort,
      transition: 'opacity 50ms linear'
    }
  };
  return account.idUserType === 1 ? (
    <div style={style}>
      {!responsive.isBig || props.mouseOver ? (
        <h1
          style={styles.textBar}
          onClick={() => {
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
            );
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilitySingUpModalWidget(false)
            );
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                false
              )
            );
          }}>
          {t('login')}
        </h1>
      ) : (
        <Login style={styles.icon} />
      )}
      <div style={styles.separator} />
      {!responsive.isBig || props.mouseOver ? (
        <h1
          style={styles.textBar}
          onClick={() => {
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                true
              )
            );
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(false)
            );
          }}>
          {t('register')}
        </h1>
      ) : (
        <Register style={styles.icon} />
      )}
    </div>
  ) : (
    <div style={style}>
      {!responsive.isBig || props.mouseOver ? (
        <h1
          style={styles.textBar}
          onClick={() => {
            logout();
          }}>
          {t('logout')}
        </h1>
      ) : (
        <Logout style={styles.icon} />
      )}
    </div>
  );
}
