import React, {useEffect, useRef, useState} from 'react';
import {useTheme} from "../../context/themeContext";
import NewsItem from "./NewsItem";
import {VM} from "../../../viewmodel/VM";
import {useLanguage, useTranslate} from "../../context/languageContext";
import {ClipLoader} from "react-spinners";
import {useDispatch, useSelector} from "react-redux";
import {newsInformation, newsSlice} from "../../../redux/slices/newsSlice";
import {useGetAllNewsByPaginationMutation} from "../../../redux/login/news/NewsServiceApi";
import { agencyInformation } from '../../../redux/slices/agencySlice';

export default function NewsGroup(props, ref) {
    const theme = useTheme();
    const [renderNewsGroup, setRenderNewsGroup] = useState()
    const [elementPageNumber, setElementPageNumber] = useState(10);
    const news = useSelector(newsInformation).news;
    const [getAllNewsByPagination] = useGetAllNewsByPaginationMutation();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const language = useLanguage()
    const t = useTranslate()
    const listInnerRef = useRef();
    const agencyInfo = useSelector(agencyInformation);

    useEffect(() => {
        async function fetchData() {
            if (!news?.length) {
                setLoading(true)
                await getAllNewsByPagination({locale:language, transportmode:props.modeId, agency:props.agencyId, platform:props.platform, pagenumber:1, elementsperpage:elementPageNumber}).then((data)=>
                    dispatch(newsSlice.actions.updateNews(data.data))
                )
                setLoading(false)
            }
        }
        fetchData()
    },[elementPageNumber, props.modeId, props.mode, props.agencyId]);

    useEffect(() => {
        async function fetchData() {
            setRenderNewsGroup(await getRenderNewsGroup())
        }
        fetchData()
    },[news,loading]);

    const styles={
        content: {
            backgroundColor: theme.colors.background,
            padding:20,
            maxHeight:window.innerHeight - 245,
            overflowY:'scroll',
            borderRadius:'0px 0px 10px 10px',
        },
        newGroup: {
            justifyContent:'center',
            display:'flex'
        },
    }

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (Math.abs(Math.round(scrollTop + clientHeight) - scrollHeight)<10) {
                setElementPageNumber(elementPageNumber + 1);
            }
        }
    };

    const getRenderNewsGroup = async () => {
        let res = []
        for (let article in news?.data) {
            if(VM.agencies.data?.length==0){
                await VM.agencies.get()
            }
            if(VM.dataOrigin.data?.length==0){
                await VM.dataOrigin.get(language)
            }

            const origin = agencyInfo.dataOrigin.find((element) => element.id === news?.data[article].agencyOriginId);
            const agency = agencyInfo.agenciesTransport.find((element) => element.id === origin?.agencyId);
            let icon = await VM.icons.getIcon(origin?.agencyId, 'agency')

            res.push(<NewsItem
                key={article}
                style={{marginBottom:10}}
                article={news?.data[article]}
                agency={agency?.groupName ? agency?.groupName : agency?.shortName}
                icon={icon}
            />)
        }
        if (!news?.data?.length && !loading)
            res.push(<div key={'noNews'} className={'secondaryText'}>{t("noNews")}</div>)
        return res
    }

    return (
        <div style={styles.content} ref={listInnerRef} onScroll={onScroll}>
                {renderNewsGroup}
            <div style={styles.newGroup}>
                <ClipLoader loading={loading}/>
            </div>
        </div>
    )
}
