import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  useActivateUserMutation,
  useChangePasswordUserMutation,
  useEndRecoveryPwdMutation
} from '../../../../redux/login/services/authServiceApi';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useSetLoading } from '../../../context/loadingContext';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import ButtonNext from '../../commons/buttons/ButtonNext';
import SimpleModal from '../../commons/Modals/SimpleModal';
import TitleH1 from '../../commons/Titles/TitleH1';
import InputPassword from '../../inputs/InputPassword';
import {useTranslate} from "../../../context/languageContext";
import { useNavigate } from "react-router-dom";
import Button from "../../commons/Button";
import {ClipLoader} from "react-spinners";
export default function ActivateAccountModalWidget(props: any) {
  let [params] = useSearchParams();
  let [isError, setIsError] = useState(false)
  let [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const result: any = await activateUser({
        email:params.get('email'),
        activationToken:params.get('token')
      });
      if(result?.error)
        setIsError(true)
      setIsLoading(false)
    }
    fetchData()
  },[]);

  const [activateUser, { error }] = useActivateUserMutation();

  const theme = useTheme();
  const t = useTranslate()
  const navigate = useNavigate();
  const Close = R.drawables.commons.close;
  const styles = {
    header: {
      width: '100%',
      textAlign: 'center',
    },
    close: {
      marginLeft: 'auto',
      marginRight: '20px',
      marginTop: '20px',
      position: 'absolute',
      right: 0,
      top: 0,
      height: 10.65,
      width: 10.71
    },
    title: {
      fontSize: '22px',
      color: '#ffffff',
      marginBottom: '1.3%',
      marginTop: '10.2%'
    },
    p: {
      fontWeight: '400',
      fontSize: '14px',
      color: ' #FFFFFF',
      textAlign: 'center',
    },
    overlay: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10,
      backgroundColor: '#181B1D',
      opacity: 0.2
    }
  } as const;

  const sendData = async () =>{
    navigate("/");
  }

  return (
      <>
        <div style={styles.overlay}></div>
        <SimpleModal
            style={{
              width: '65%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: 15
            }}
            header={
              <div style={styles.header}>
                <Close
                    style={styles.close}
                    fill={theme.colors.white}
                    onClick={() => {
                    }}
                />
                <TitleH1 style={styles.title} title={isError ? t('accountNoActivated') : t('accountActivated')} />
                {isLoading
                    ? <ClipLoader color={'white'}/>
                    : <p style={styles.p}>{isError ? t('accountNoActivatedDes') : t('accountActivatedDes')}</p>
                }
              </div>
            }
            body={
              <Button text={t('confirm')} selected={true} disabled={isLoading} onClick={sendData}/>
            }
        />
      </>
  );
}
