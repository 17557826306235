import { useDispatch, useSelector } from 'react-redux';
import {
  favoritesInformation,
} from '../../../../../../redux/slices/favoritesSlice';
import RouteUtils from '../../../../../../utils/RouteUtils';
import { VM } from '../../../../../../viewmodel/VM';
import Button from '../../../../../components/commons/Button';
import { useTranslate } from '../../../../../context/languageContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';

export default function VehicleMidInfo(props) {
  const theme = useTheme();
  const t = useTranslate();

  const favorites = useSelector(favoritesInformation);

  const styles = {
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center'
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginTop: 10,
      marginBottom: 10,
      width: '100%'
    }
  };

  const goThere = () => {
    let coords = { latitude: props.vehicle.latitude, longitude: props.vehicle.longitude };
    let name = props.vehicle?.alias ?? props.vehicle?.name;
    let info = RouteUtils.getTypeId(props.vehicle, favorites);
    let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };
    VM.plannerSegments.set('destination', res);
  };

  return (
    <div>
      <div style={styles.separator} />
      <div style={{ ...styles.row }}>
        <Button
          icon={R.drawables.map.goThere}
          text={t('goThere')}
          iconRight={true}
          textColor={theme.colors.darkGray}
          iconSize={10}
          iconColor={theme.colors.darkGray}
          borderColor={theme.colors.border}
          onClick={goThere}
        />
      </div>
    </div>
  );
}
