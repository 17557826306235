import {useTheme} from "../../context/themeContext";
import {R} from "../../R";
import {useEffect, useState} from "react";

export default function Switch(props, ref) {
    const theme = useTheme();
    const [active, setActive]=useState(props.active ?? false)
    useEffect(()=>{
        if(props.onSwitch)
            props.onSwitch(active)
    },[active])
    const styles={
        switch: {
            borderRadius:12,
            backgroundColor:theme.colors.border,
            width:35,
            height:15,
            display:'flex',
            alignItems:'center',
            cursor:'pointer',
            transition:'all 250ms linear'
        },
        content:{
            alignItems:'center',
            display:'flex',
        },
        circle: {
            minWidth:20,
            minHeight:20,
            backgroundColor:active ? R.colors.mediumDark : R.colors.white,
            boxShadow: "0px 4px 4px rgba(28, 31, 32, 0.1)",
            border: `1px solid ${theme.colors.border}`,
            borderRadius: 50,
            marginLeft:active ? '42%' : 0,
            transition:'all 250ms linear'
        }
    }

    return (
        <div style={{...props.style, ...styles.content}}
             onClick={()=>setActive(!active)}>
            <div style={styles.switch}>
                <div style={styles.circle}/>
            </div>
        </div>
    );
}
