import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';

export interface newsInformation {
    news: Array<any>;
    article: any;
}

const initialState = {
    newsInformation: {
        news: [],
        article: {},
        transportsWithNews:[]
    },
    filterAgencies: [],
    dateNews: false,
};

export const newsSlice = createSlice({
    name: 'newsSlice',
    initialState,
    reducers: {
        updateNews: (state, action) => {
            state.newsInformation.news = action.payload;
        },
        updateArticle: (state, action) => {
            state.newsInformation.article = action.payload;
        },
        updateTransportsWithNews: (state, action) => {
            state.newsInformation.transportsWithNews = action.payload;
        },
        updateFilterAgenciesNewsAlerts: (state, action) => {
            state.filterAgencies = action.payload;
        },
        updateDateNews: (state, action) => {
            state.dateNews = action.payload;
        },
        reset: (state) => {
            state.newsInformation = {
                news: [],
                article: {},
                transportsWithNews:[]
            }
        }
    },
});

export const newsInformation = (state: RootState) => state.newsSlice.newsInformation;

export const filtersNewsAlertsState = (state: RootState) => state.newsSlice.filterAgencies;

export const dateNewsState = (state: RootState) => state.newsSlice.dateNews;