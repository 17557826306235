import React, {createContext, useState, useContext} from 'react';

const ModalContext = createContext();
const SetModalContext = createContext();

const ModalContentContext = createContext();
const SetModalContentContext = createContext();

export function ModalProvider({children}) {

    const [open, setOpened] = useState(false);
    const [child, setChild] = useState(null);


    return (
        <ModalContext.Provider value={open}>
            <SetModalContext.Provider value={setOpened}>
                <ModalContentContext.Provider value={child}>
                    <SetModalContentContext.Provider value={setChild}>
                        {children}
                    </SetModalContentContext.Provider>
                </ModalContentContext.Provider>
            </SetModalContext.Provider>
        </ModalContext.Provider>
    );
}

export function useModalOpened() {
    return useContext(ModalContext);
}

export function useSetModalOpened() {
    return useContext(SetModalContext);
}

export function useModalChild() {
    return useContext(ModalContentContext);
}

export function useSetModalChild() {
    return useContext(SetModalContentContext);
}
