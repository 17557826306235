import { useDownloadFiles } from '../../../../utils/FileUtils';
import TimeHookUtils from '../../../../utils/TimeHookUtils';
import TimeUtils from '../../../../utils/TimeUtils';
import { useTranslate } from '../../../context/languageContext';
import { R } from '../../../R';
import FileEntrySending from './components/FileEntrySending';

interface CommentStateElementProps {
  id: number;
  title: string;
  type: string;
  sentAt: number;
  messageBody?: string;
  reply: boolean;
  replyAt?: number;
  seeResponse?: boolean;
  setRenderChatMessages: Function;
  style?: React.CSSProperties;
  fileId?: string;
  fileName?: string;
  fileExtension?: string;
}

export default function CommentStateElement(props: CommentStateElementProps) {
  const t = useTranslate();
  const Ic_Check = R.drawables.general.Ic_Check_Circle;
  const Ic_Answererd = R.drawables.general.Ic_Answered;
  const { getTimeMessage } = TimeHookUtils();

  const { downloadFileData } = useDownloadFiles();

  return (
    <div className={'account-system-element'} style={props.style}>
      <div className={'cornerText'}>{props.type}</div>
      <div className={'row'}>
        <div className={'subtitle'}>{props.title}</div>
        <div className={'cornerText'} style={{ marginLeft: 'auto' }}>
          {getTimeMessage(props.sentAt)}
        </div>
      </div>
      <div className={'description'} style={{ marginTop: 10 }}>
        {props.messageBody}
      </div>

      {props.fileId && props.fileExtension && props.fileName ? (
        <FileEntrySending
          fileName={props.fileName}
          fileType={props.fileExtension}
          notClose={true}
          localeBlob={''}
          setFileData={() => {}}
          setTypeFile={() => {}}
          onClick={async () => {
            props.fileId &&
              props.fileExtension &&
              props.fileName &&
              (await downloadFileData(props.fileId, props.fileExtension, props.fileName));
          }}
        />
      ) : null}

      {props.seeResponse ? (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            props.setRenderChatMessages(props.id);
          }}>
          <div className={'divider'} />
          <div className={'row'}>
            <Ic_Answererd style={{ marginRight: 5 }} />
            <div className={'primaryText neutral'}>{t('answer')}</div>
            {props.reply ? <div style={{backgroundColor: 'red', width: 8, height: 8, borderRadius: 4, marginTop: -10, marginLeft: 4,}}/>  : null}
            <div className={'cornerText'} style={{ marginLeft: 'auto' }}>
              {getTimeMessage(props.replyAt)}
            </div>
          </div>
          <div className={'description'} style={{ marginTop: 10 }}>
            {props.reply}
          </div>
        </div>
      ) : null}
    </div>
  );
}
