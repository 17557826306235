import { useEffect, useState } from 'react';
import { Apis } from '../../../../../../domain/Apis';
import { VM } from '../../../../../../viewmodel/VM';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import PoiCollapse from './PoiCollapse';
import PoiMidInfo from './PoiMidInfo';
import GeneralUtils from '../../../../../../utils/GeneralUtils';
import LinesNearMarker from '../linesNear/LinesNearMarker';
import StopsLinesBar from '../stopsLinesNear/StopsLinesBar';

export default function PoiDetail(props) {
  const theme = useTheme();
  const language = useLanguage();
  const CloseIcon = R.drawables.general.Ic_close;
  const responsive = useResponsive();
  const t = useTranslate();

  const [iconType, setIconType] = useState();
  const [type, setType] = useState();

  useEffect(() => {
    async function fetchData() {
      setType(await Apis.categories.getCategory(language, props.poi.categoryId));
      setIconType(await VM.icons.getIcon(props.poi.categoryId, 'category', 'markIconId'));
    }
    fetchData();
    let copy = `${GeneralUtils.getBaseUrl()}/#/poi?${GeneralUtils.convertToHex(
      `id=${props.poi.id}`
    )}`;
    window.history.pushState('a', 'a', copy);
  }, [props.poi, language]);

  const styles = {
    content: {
      position: 'absolute',
      display: 'flex',
      padding: 12,
      marginInline: !responsive.isBig ? '25px' : '105px',
      marginTop: '10px',
      width: '80vw',
      maxWidth: '345px',
      minWidth: '232px',
      flexDirection: 'column',
      background: theme.colors.white,
      boxShadow: '0px 4px 4px rgba(28, 31, 32, 0.1)',
      borderRadius: '12px'
    },
    subContent: {
      paddingInline: '12px',
      textAlign: 'left',
      alignContent: 'center'
    },
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center'
    },
    icon: {
      marginRight: 10,
      width: 27.5,
      borderRadius: 5
    },
    secondaryText: {
      color: theme.colors.textSecondary,
      fontSize: 13,
      fontWeight: 500
    },
    mainText: {
      color: theme.colors.textPrimary,
      fontSize: 15,
      fontWeight: 600,
      marginTop: 5
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginBottom: 10,
      width: '100%'
    }
  };
  let name = props.poi.name;
  return (
    <div style={styles.content}>
      <CloseIcon
        style={{ cursor: 'pointer', marginLeft: 'auto' }}
        fill={theme.colors.black}
        onClick={() => props.onClose()}
      />

      <div style={styles.subContent}>
        <div style={styles.row}>
          <img style={{ ...styles.icon }} src={iconType} />
          <div style={styles.secondaryText}>{type?.name}</div>
        </div>

        <div style={styles.mainText}>{!name || name === 'null' ? '' : name}</div>
        <div className={'secondaryText'} dangerouslySetInnerHTML={{ __html: props.poi?.address }} />
      </div>

      <PoiMidInfo poi={props.poi} />
      <div
        style={{ flex: 1, overflow: 'auto', marginTop: 15, maxHeight: window.innerHeight - 420 }}>
        <div
          dangerouslySetInnerHTML={{ __html: props.poi?.description }}
          style={{
            ...styles.secondaryText,
            marginBottom: 8
            //maxHeight: window.innerHeight - 420,
            //overflow: 'auto'
          }}
        />
        <div style={styles.separator} />
        <StopsLinesBar
          latitude={props.poi.latitude}
          longitude={props.poi.longitude}
          setMarkerSelected={props.setMarkerSelected}
        />
      </div>
      {props.poi.phone || props.poi.web ? <PoiCollapse poi={props.poi} /> : null}
    </div>
  );
}
