import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../components/commons/Button';
import { useLanguage, useTranslate } from '../../../context/languageContext';
import { useTheme } from '../../../context/themeContext';
import NewsLinksSections from './NewsLinksSections';
import { VM } from '../../../../viewmodel/VM';
import TransitBody from '../../transitScreen/TransitBody';
import NewsGroup from '../../newscreen/NewsGroup';
import FilterUtils from '../../../../utils/FilterUtils';
import SubscribeNews from '../../newscreen/SubscribeNews';
import { useSelector } from 'react-redux';
import { accountInformation } from '../../../../redux/slices/authSlice';
import { R } from '../../../R';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useDispatch } from 'react-redux';
import DefaultModal from '../../../components/widget/Modals/DefaultModal';
import { useSetModalChild, useSetModalOpened } from '../../../context/modalContext';
import { filtersNewsAlertsState } from '../../../../redux/slices/newsSlice';
import GeneralUtils from '../../../../utils/GeneralUtils';
import TransitInfoModal from '../../transitScreen/TransitInfoModal';
import TransitUtils from '../../../../utils/TransitUtils';
//import {alertsMock} from './alerts';

interface ITransitNewsSection {
  id: number;
  label: string;
  path: string;
}

export default function TransitNewsSections() {
  const scroll = useRef(null);
  const t = useTranslate();
  const language = useLanguage();
  const theme = useTheme();
  const [sectionSelected, setSectionSelected] = useState(0);
  const [alerts, setAlerts] = useState([] as any);
  const account = useSelector(accountInformation);
  const filtersSelectedAgencies: Array<number> = useSelector(filtersNewsAlertsState);
  const NotificationIcon = R.drawables.general.Ic_Alerta;
  const dispatch = useDispatch();
  const setOpened = useSetModalOpened();
  const setChild = useSetModalChild();
  //const setChildModal = useSetModalChild()
  //const setOpenModal = useSetModalOpened()

  useEffect(() => {
    try {
      let path = GeneralUtils.getLastSectionPath();
      switch (path) {
        case 'transitLine': {
          setSectionSelected(1);
          break;
        }
        case 'transitStop': {
          setSectionSelected(2);
          break;
        }
        case 'linksOperators': {
          setSectionSelected(3);
          break;
        }
        default:
          setSectionSelected(0);
          break;
      }
    } catch (e) {}
  }, [window.location]);

  useEffect(() => {
    async function fetchData() {
      setAlerts(await VM.alerts.get(language));
    }
    fetchData();
  }, []);

  const joinAgencies = (data: any) => {
    let res: Array<any> = [];
    for (let alert in data) {
      let agencyId: number = data[alert]?.idAgencia;
      if (!res[agencyId]) {
        if (
          (filtersSelectedAgencies?.length && filtersSelectedAgencies.includes(agencyId)) ||
          !filtersSelectedAgencies?.length
        ) {
          let filtered = JSON.parse(JSON.stringify(Array.from(data))).filter(
            ({ idAgencia }: any) => idAgencia === data[alert].idAgencia
          );
          res[data[alert].idAgencia] = filtered;
        }
      }
    }
    return res;
  };

  const styles = {
    content: {
      display: 'flex',
      paddingInline: 10
    },
    container: {
      width: '100%',
      zIndex: 10
    },
    tags: {
      borderRadius: 0,
      borderColor: theme.colors.primaryMedium,
      justifyContent: 'center',
      backgroundColor: theme.colors.white,
      transition: 'all 100ms linear',
      paddingInline: '14px',
      fontSize: '13px',
      paddingBottom: 2
    }
  };
  const sections: Array<ITransitNewsSection> = [
    {
      id: 1,
      label: t('news'),
      path: 'news'
    },
    {
      id: 2,
      label: t('incidence_line'),
      path: 'transitLine'
    },
    {
      id: 3,
      label: t('incidence_stop'),
      path: 'transitStop'
    },
    {
      id: 4,
      label: t('links'),
      path: 'linksOperators'
    }
  ];

  /* 
  <Button
          key={section.id}
          text={section.label}
          style={{
            ...styles.tags,
            ...{width: 100, flex: 1, wordBreak: 'break-word', wordWrap: 'break-word',},
            ...(sectionSelected === index
              ? { fontWeight: 600, borderWidth: '0px 0px 2px 0px' }
              : { borderWidth: '0px 0px 0px 0px' })
          }}
          onClick={() => {
            if (sectionSelected === index) {
            } else {
              setSectionSelected(index);
              VM.screenSelected.setSection(index);
              GeneralUtils.setLastSectionPath(section.path, 1)
            }
          }}
        /> */
  function renderHeaderSections() {
    return sections.map((section, index) => {
      /* return (
        <div
          key={section.id}
          onClick={() => {
            if (sectionSelected === index) {
            } else {
              setSectionSelected(index);
              VM.screenSelected.setSection(index);
              GeneralUtils.setLastSectionPath(section.path, 1);
            }
          }}
          className='transit-option'
          style={{
            ...styles.tags,
            ...{ width: 120, flex: 1, wordBreak: 'break-word', justifyContent: 'center', textAlign: 'center', cursor: 'pointer' },
            ...(sectionSelected === index
              ? { fontWeight: 600, borderWidth: '0px 0px 2px 0px',  borderBottomStyle: 'solid',}
              : { borderWidth: '0px 0px 0px 0px',  })
          }}>
          {section.label}
        </div>
      ); */
      return (
        <div
          key={section.id}
          onClick={() => {
            if (sectionSelected === index) {
            } else {
              setSectionSelected(index);
              VM.screenSelected.setSection(index);
              GeneralUtils.setLastSectionPath(section.path, 1);
            }
          }}
          className="transit-option-sectionNews"
          style={{
            ...{
              width: 120,
              marginTop: 0,
              flex: 1,
              wordBreak: 'break-word',
              justifyContent: 'center',
              textAlign: 'center',
              cursor: 'pointer'
            },
            ...(sectionSelected === index ? { borderWidth: '0px 0px 2px 0px',  borderBottomStyle: 'solid', borderBottomColor: '#0078b2' } : {})
          }}>
          <div
            className={'subtitle'}
            style={{
              ...(sectionSelected !== index ? { opacity: 0.5 } : { opacity: 1 })
            }}>
            {section.label}
          </div>
          {/* <div
            className={'divider-select b-mediumDark'}
            style={{ ...(sectionSelected !== index ? { opacity: 0 } : { opacity: 1 }) }}
          /> */}
        </div>
      );
    });
  }

  async function renderAlertDetail(alert: any) {
    if (alert) {
      let data = await VM.alerts.getDetail(language, alert.idAlerta);
      setChild(<TransitInfoModal alert={{ ...alert }} />);
      setOpened(true);
    }
  }

  function renderContentSection() {
    let content;
    switch (sectionSelected) {
      case 1:
        content = (
          <TransitBody
            data={TransitUtils.organizeAlertsByAgencyId(
              filtersSelectedAgencies.length
                ? alerts.filter((alert: any) => filtersSelectedAgencies.includes(alert?.idAgencia))
                : alerts
            )}
            selected={0}
            setAlert={renderAlertDetail}
          />
        );
        break;
      case 2:
        content = (
          <TransitBody
            data={TransitUtils.organizeAlertsByAgencyId(
              filtersSelectedAgencies.length
                ? alerts.filter((alert: any) => filtersSelectedAgencies.includes(alert?.idAgencia))
                : alerts
            )}
            selected={1}
            setAlert={renderAlertDetail}
          />
        );
        break;
      case 3:
        content = <NewsLinksSections />;
        break;

      default:
        content = (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                padding: '8px 16px 8px 16px'
              }}>
              <NotificationIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (account.idUserType === 1) {
                    setOpened(true);
                    setChild(
                      <DefaultModal
                        title={t('loginToAlerts')}
                        description={t('loginToFavBody')}
                        alter={true}
                        firstButton={t('createAccount')}
                        secondButton={t('login')}
                        onClickFirst={() => {
                          dispatch(
                            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                              true
                            )
                          );
                        }}
                        onClickSecond={() => {
                          dispatch(
                            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(
                              true
                            )
                          );
                        }}
                      />
                    );
                  } else {
                    setChild(<SubscribeNews />);
                    setOpened(true);
                  }
                }}
              />
            </div>
            <NewsGroup
              mode={t('visualizer_filtersAll')}
              modeId={''}
              agencyId={filtersSelectedAgencies.toString()}
            />
          </>
        );
        break;
    }

    return content;
  }

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div
          ref={scroll}
          style={{
            overflowX: 'scroll',
            display: 'flex',
            marginRight: 10,
            overflowY: 'hidden'
          }}>
          {renderHeaderSections()}
        </div>
      </div>
      {/* contenido de cada listado */}
      {renderContentSection()}
    </div>
  );
}
