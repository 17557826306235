import { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteFavoriteMutation } from '../../../redux/login/favorite/FavoriteServiceApi';
import { accountInformation } from '../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { favoritesInformation, favoritesSlice } from '../../../redux/slices/favoritesSlice';
import DefaultModal from '../../components/widget/Modals/DefaultModal';
import { useTranslate } from '../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../context/modalContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';

export default function FavoriteDirection(props: any) {
  const ArrowDown = R.drawables.general.Ic_ChevronDownSmall;
  const ArrowUp = R.drawables.general.Ic_ChevronUpSmall;
  const Remove = R.drawables.general.Ic_delete;
  const Edit = R.drawables.general.Ic_edit;
  const Add = R.drawables.general.Ic_add;
  const Direction = R.drawables.general.Ic_location;
  const Home = R.drawables.general.Ic_home;
  const Work = R.drawables.general.Ic_WorkSmall;

  const [removeFavorite] = useDeleteFavoriteMutation();
  const favorites = useSelector(favoritesInformation);
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const content = useRef(null as any);

  const t = useTranslate();
  const theme = useTheme();

  const setChild = useSetModalChild();
  const setOpen = useSetModalOpened();
  const account = useSelector(accountInformation);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickComponent);
  }, []);

  useEffect(() => () => document.removeEventListener('mousedown', handleClickComponent), []);

  const handleClickComponent = (event: any) => {
    if (content && !content.current?.contains(event.target)) {
      setOpened(false);
    }
  };

  const addDirection = () => {
    if (account.idUserType === 1) {
      setOpen(true);
      setChild(
        <DefaultModal
          title={t('loginToFav')}
          description={t('loginToFavBody')}
          alter={true}
          firstButton={t('createAccount')}
          secondButton={t('login')}
          onClickFirst={() => {
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                true
              )
            );
          }}
          onClickSecond={() => {
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
            );
          }}
        />
      );
    } else {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAddDirectionModalWidget({
          showModal: true,
          direction: {},
          type: props.typeId,
          edit: false
        })
      );
    }
  };

  const removeFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    for (let direction in updateFavorites.directions) {
      if (updateFavorites.directions[direction].id === props.direction.id) {
        updateFavorites.directions.splice(
          updateFavorites.directions.indexOf(updateFavorites.directions[direction]),
          1
        );
      }
    }

    removeFavorite({
      stops: [],
      pois: [],
      plans: [],
      directions: [props.direction.id]
    });
    dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
        showModal: true,
        background: '#00763D',
        text: t('directionRemoved')
      })
    );
  };

  return props.direction ? (
    <div
      className={'favorite-station-container'}
      style={{ maxHeight: window.innerHeight - 165 }}
      ref={content}>
      <div
        className={opened ? 'favorite-station-body-c' : 'favorite-station-body-o'}
        onClick={() => setOpened(!opened)}
        onDoubleClick={() => {
          dispatch(favoritesSlice.actions.setClickFav({ id: props.direction, type: 'direction' }));
        }}>
        <div className={'row'}>
          <div
            className={'agency-icon-box poiIcon'}
            style={{ backgroundColor: theme.colors.mediumDark }}>
            {props.direction.typeId == 3 ? (
              <Work style={{ fill: 'white' }} />
            ) : props.direction.typeId == 2 ? (
              <Home style={{ fill: 'white' }} />
            ) : (
              <Direction style={{ fill: 'white' }} />
            )}
          </div>
          <div className={'subtitle'}>{props.direction?.alias}</div>

          {opened ? <ArrowUp className={'close'} /> : <ArrowDown className={'close'} />}
        </div>
        <div className={'cornerText marginTop5'}>{props.direction?.direction}</div>
      </div>

      <Collapse isOpened={opened}>
        <div className={'favorite-station-footer row'}>
          <div
            className={'primaryText row'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAddDirectionModalWidget({
                  showModal: true,
                  direction: props.direction,
                  type: props.typeId,
                  edit: true
                })
              );
            }}>
            <Edit style={{ width: 20, marginRight: 5, fill: theme.colors.neutral }} />
            <div className={'primaryText'} style={{ color: theme.colors.neutral }}>
              {t('edit')}
            </div>
          </div>

          <div
            className={'row'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              removeFav();
            }}>
            <Remove
              style={{ width: 20, marginRight: 5, marginLeft: 20, fill: theme.colors.neutral }}
            />
            <div className={'primaryText'} style={{ color: theme.colors.neutral }}>
              {t('delete')}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  ) : (
    <div className={'favorite-station-container'} style={{ maxHeight: window.innerHeight - 165 }}>
      <div
        className={opened ? 'favorite-station-body-c' : 'favorite-station-body-o'}
        onClick={() => {
          addDirection();
        }}>
        <div className={'row'}>
          <div className={'agency-icon-box poiIcon'}>
            {props.typeId == 3 ? (
              <Work style={{ fill: 'black' }} />
            ) : props.typeId == 2 ? (
              <Home style={{ fill: 'black' }} />
            ) : (
              <Direction style={{ fill: 'black' }} />
            )}
          </div>
          <div className={'subtitle'}>
            {props.direction?.alias
              ? props.direction.alias
              : props.typeId == 3
              ? t('addWork')
              : props.typeId == 2
              ? t('addHome')
              : t('addDirection')}
          </div>

          <Add className={'close'} />
        </div>
      </div>
    </div>
  );
}
