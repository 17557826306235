import React, { useEffect, useRef, useState } from 'react';
import { VM } from '../../../viewmodel/VM';
import MapOptions from './mapOptions/MapOptions';
import { MapLeafLetContainer } from '../../maps/MapLeafLetContainer';
import ButtonIcon from '../../components/commons/ButtonIcon';
import { R } from '../../R';
import RouteUtils from '../../../utils/RouteUtils';
import { clickFav, favoritesInformation } from '../../../redux/slices/favoritesSlice';
import { useSelector } from 'react-redux';
import { useLanguage } from '../../context/languageContext';
import { lineSlice } from '../../../redux/slices/lineSlice';
import { useDispatch } from 'react-redux';
import LanguageSelector from '../accountscreen/language/LanguageSelector';
import { useResponsive } from '../../context/responsiveContext';

export default function ExploreScreen(props) {
  const map = useRef();
  const [markerSelected, setMarkerSelected] = useState(null);
  const [showSecond, setShowSecond] = useState(true);
  const favorites = useSelector(favoritesInformation);
  const language = useLanguage();
  const elementClickFav = useSelector(clickFav);
  const dispatch = useDispatch();
  const responsive = useResponsive();

  useEffect(() => {
    if (elementClickFav?.type == 'poi') onClickMarker(VM.pois.map[elementClickFav?.id]);
    else if (elementClickFav?.type == 'stop') onClickMarker(VM.stops.map[elementClickFav?.id]);
    else if (elementClickFav?.type == 'direction') onClickMarker(elementClickFav.id);
    else if (elementClickFav?.type == 'station') onClickMarker(elementClickFav.id);
    else if (elementClickFav?.type == 'lineSel') onClickLineSel(elementClickFav);
  }, [elementClickFav]);

  /* useEffect(() => {
    setMarkerSelected(null);
  }, [language]); */

  const onClick = (e) => {
    let coords = { latitude: e.latlng.lat, longitude: e.latlng.lng };
    let name = e?.latlng.lat.toFixed(6) + ', ' + e.latlng.lng.toFixed(6);
    let info = RouteUtils.getTypeId(e, favorites, name);
    let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };

    if (!VM.plannerSegments.data['destination']) {
      VM.plannerSegments.set('destination', res);
    } else if (!VM.plannerSegments.data['origin']) {
      VM.plannerSegments.set('origin', res);
    } else {
      VM.plannerSegments.add(res);
    }
  };

  const onDblClick = (e, map) => {
    let res = {
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
      double: true,
      name: e.latlng.lat.toFixed(6) + ', ' + e.latlng.lng.toFixed(6)
    };
    map.flyTo(e.latlng);
    setMarkerSelected(res);
    VM.lines.setSelected(null);
  };

  const onClickMarker = (markRead, mapRef, zoom) => {
    let marker = JSON.parse(JSON.stringify(markRead));
    VM.screenSelected.setSelected('explore');
    if (marker?.agencyOriginId)
      marker.agencyOriginId = VM.stops.map[marker?.id]?.agencyOriginId ?? marker?.agencyOriginId;
    map.current.flyTo({ lat: marker.latitude, lng: marker.longitude }, zoom ? zoom : 14.5);
    setTimeout(
      () =>
        map.current.flyTo({ lat: marker.latitude, lng: marker.longitude }, zoom ? zoom + 0.5 : 15),
      1000
    );
    setMarkerSelected(marker);
    VM.lines.setSelected(null);
  };

  const onClickLineSel = (markerStop) => {
    map.current.flyTo({ lat: markerStop.latitude, lng: markerStop.longitude }, 14.5);
  };

  return (
    <div>
      <MapOptions
        showSecond={showSecond}
        setShowSecond={setShowSecond}
        setOpen={props.setOpen}
        markerSelected={markerSelected}
        setMarkerSelected={setMarkerSelected}
        onClickMarker={onClickMarker}
      />
      {responsive.isMobile ? null : <LanguageSelector />}
      <MapLeafLetContainer
        ref={map}
        markerSelected={markerSelected}
        onClickMarker={(params, map, zoom) => {
          onClickMarker(params, map, zoom);
          dispatch(lineSlice.actions.updateFilteredLine(null));
        }}
        onDblClick={onDblClick}
        onClick={onClick}
      />
      <div className={'leaflet-control-layer'}>
        <ButtonIcon
          icon={R.drawables.general.Ic_map}
          size={50}
          onClick={() => {
            let newMapType =
              localStorage.getItem('mapType') === null
                ? 0
                : parseInt(localStorage.getItem('mapType'));
            localStorage.setItem('mapType', newMapType ? 0 : 1);
            window.location.reload(false);
          }}
        />
      </div>
    </div>
  );
}
export const MemoExploreScreen = React.memo(ExploreScreen);
