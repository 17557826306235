import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';

export interface favoritesInformation {
    stops: Array<any>;
    pois: Array<any>;
    plans: Array<any>;
    directions: Array<any>;
    agencyId: Array<any>;
}

export interface clickFav {
    id: any;
    type: any;
}

const initialState = {
    favoritesInformation: {
        stops: [],
        pois: [],
        plans: [],
        directions: [],
        agencyId:[],
    },
    filterButton:false,
    selectAllFilterNewsAlerts: false,
    filterNewsAlerts: function getFilterSaved() {
        return false; //JSON.parse(localStorage.getItem('favFilterNewsAlerts') || 'false');
    }(),
    favSubsNews: function getFilterSaved() {
        return false; //JSON.parse(localStorage.getItem('favSubsNews') || 'false');
    }(),
    clickFav:{
        id:null,
        type:null
    }
};

export const favoritesSlice = createSlice({
    name: 'favoritesSlice',
    initialState,
    reducers: {
        updateFavorites: (state, action) => {
            state.favoritesInformation = action.payload;
        },
        resetFavorites: (state) => {
            state.favoritesInformation = {
                stops: [],
                pois: [],
                plans: [],
                directions: [],
                agencyId:[]
            }
        },
        setFilterButton: (state,action) => {
            state.filterButton = action.payload
        },
        setClickFav: (state,action) => {
            state.clickFav = action.payload
        },
        updateFilterNewsAlerts: (state,action) => {
            state.filterNewsAlerts = action.payload;
        },
        updateSelectAllFilterNewsAlerts: (state,action) => {
            state.selectAllFilterNewsAlerts = action.payload;
        },
        updateFavSubsNews: (state,action) => {
            state.favSubsNews = action.payload;
            //localStorage.setItem('favFilterNewsAlerts', JSON.stringify(action.payload));
        },
    },
});

export const favoritesInformation = (state: RootState) => state.favoritesSlice.favoritesInformation;
export const filterButton = (state: RootState) => state.favoritesSlice.filterButton;
export const selectAllFilterNewsAlertsState = (state: RootState) => state.favoritesSlice.selectAllFilterNewsAlerts;
export const favFilterNewsAlertsState = (state: RootState) => state.favoritesSlice.filterNewsAlerts;
export const favSubsNewsState = (state: RootState) => state.favoritesSlice.favSubsNews;
export const clickFav = (state: RootState) => state.favoritesSlice.clickFav;
