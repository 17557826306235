import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useEndRecoveryPwdMutation } from '../../../../redux/login/services/authServiceApi';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useSetLoading } from '../../../context/loadingContext';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import ButtonNext from '../../commons/buttons/ButtonNext';
import SimpleModal from '../../commons/Modals/SimpleModal';
import TitleH1 from '../../commons/Titles/TitleH1';
import InputPassword from '../../inputs/InputPassword';
import {useTranslate} from "../../../context/languageContext";
import { useNavigate } from "react-router-dom";
export default function RecoveryPassTokenModalWidget(props: any) {
  let [params] = useSearchParams();

  useEffect(() => {}, []);

  const [EndRecoveryPwdMutation, { error }] = useEndRecoveryPwdMutation();

  const theme = useTheme();
  const t = useTranslate()
  const navigate = useNavigate();
  const Close = R.drawables.commons.close;
  const styles = {
    header: {
      width: '100%',
      textAlign: 'left',
      marginBottom: '7%'
    },
    close: {
      marginLeft: 'auto',
      marginRight: '20px',
      marginTop: '20px',
      position: 'absolute',
      right: 0,
      top: 0,
      height: 10.65,
      width: 10.71
    },
    title: {
      fontSize: '22px',
      color: '#ffffff',
      marginBottom: '1.3%',
      marginTop: '10.2%'
    },
    p: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: ' 140%',
      color: ' #FFFFFF',
      textAlign: 'left',
      margin: 0
    },
    form: {
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      textAlign: 'left',
      // marginTop: '10px',
      // marginBottom: '20px',
      color: '#FFFFFF',
      paddingLeft: '25px'
    },
    overlay: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10,
      backgroundColor: '#181B1D',
      opacity: 0.2
    }
  } as const;

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const setLoading = useSetLoading();

  const dispatch = useDispatch();

  const [minLength, setminLength] = useState({
    key: '.*.{6,30}$',
    minLength: false
  });
  const [almostOneCapitalLetter, setalmostOneCapitalLetter] = useState({
    key: '[A-Z]',
    almostOneCapitalLetter: false
  });
  const [almostOnelowerLetter, setalmostOnelowerLetter] = useState({
    key: '[a-z]',
    almostOnelowerLetter: false
  });
  const [almostOneNumber, setalmostOneNumber] = useState({
    key: '[0-9]',
    almostOneNumber: false
  });

  function handleSetPassword(value: string) {
    setPassword(value);
    if (new RegExp(minLength.key).test(value)) {
      setminLength({ ...minLength, minLength: true });
    } else {
      setminLength({ ...minLength, minLength: false });
    }
    if (new RegExp(almostOneCapitalLetter.key).test(value)) {
      setalmostOneCapitalLetter({ ...almostOneCapitalLetter, almostOneCapitalLetter: true });
    } else {
      setalmostOneCapitalLetter({ ...almostOneCapitalLetter, almostOneCapitalLetter: false });
    }
    if (new RegExp(almostOnelowerLetter.key).test(value)) {
      setalmostOnelowerLetter({ ...almostOnelowerLetter, almostOnelowerLetter: true });
    } else {
      setalmostOneCapitalLetter({ ...almostOneCapitalLetter, almostOneCapitalLetter: false });
    }
    if (new RegExp(almostOneNumber.key).test(value)) {
      setalmostOneNumber({ ...almostOneNumber, almostOneNumber: true });
    } else {
      setalmostOneNumber({ ...almostOneNumber, almostOneNumber: false });
    }
  }

  function handleSetPassword2(value: string) {
    setRepeatPassword(value);
  }

  const sendData = async () =>{
    setLoading(true);
    const result: any = await EndRecoveryPwdMutation({
      password: password,
      password2: repeatPassword,
      recoveryToken: params.get('token')
    });
    if (result && result.data && !result.error) {
      // dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilitySingUpModalWidget(false));
      // const accountUser = { ...account, ...result.data };
      // accountUser.password = null;
      // accountUser.password2 = null;
      // accountUser.token = null;
      // dispatch(authSlice.actions.setUserMe(accountUser));
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityStatusCodesModalWidget({
          code: 200,
          boolean: true
        })
      );
    }
    if (result.error && result.error.status) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityStatusCodesModalWidget({
          code: result.error.status,
          boolean: true
        })
      );
    }
    setLoading(false);

    navigate("/");
  }

  return (
    <>
      <div style={styles.overlay}></div>
      <SimpleModal
        style={{
          width: '65%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 15
        }}
        header={
          <div style={styles.header}>
            <Close
              style={styles.close}
              fill={theme.colors.white}
              onClick={() => {
                // dispatch(
                //   eventsModalstoggleVisibilitySlice.actions.toggleVisibilitySingUpModalWidget(null)
                // );
              }}
            />
            <TitleH1 style={styles.title} title={t('dataAccount')} />
            <p style={styles.p}>{t('fillFields')}</p>
          </div>
        }
        body={
          <form
            style={styles.form}
            onSubmit={async (e) => {
              e.preventDefault();

              sendData();
            }}>
            <InputPassword password={password} setPassword={handleSetPassword} />
            <label className={`${password.length > 0 ? '' : 'displayNone'}`}>
              <ul className={'check'}>
                <li>
                  <i className={minLength.minLength ? 'valid' : 'invalid'}></i>
                  <p>{t('charMaxAndMin')}</p>
                </li>
                <li>
                  <i
                    className={
                      almostOnelowerLetter.almostOnelowerLetter &&
                      almostOneCapitalLetter.almostOneCapitalLetter
                        ? 'valid'
                        : 'invalid'
                    }></i>
                  <p>{t('minMayusMin')}</p>
                </li>
                <li>
                  <i className={almostOneNumber.almostOneNumber ? 'valid' : 'invalid'}></i>
                  <p>{t('minNum')}</p>
                </li>
              </ul>
            </label>

            <InputPassword password={repeatPassword} setPassword={setRepeatPassword} />
            <label
              style={styles.label}
              className={`warning ${password != repeatPassword ? '' : 'visibilityHidden'}`}>
              {t('noMatch')}
            </label>

            <ButtonNext
              disabled={
                !minLength.minLength ||
                !almostOneCapitalLetter.almostOneCapitalLetter ||
                !almostOnelowerLetter.almostOnelowerLetter ||
                !almostOneNumber.almostOneNumber ||
                password !== repeatPassword
              }
              className="Botonera_dialogue"
            />
          </form>
        }
      />
    </>
  );
}
