import React, { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import '../../../../styles/dropdown/dropdown.scss';
import { R } from '../../../R';
import { useTheme } from '../../../context/themeContext';
import { VM } from '../../../../viewmodel/VM';

export interface IDropdown {
  ref?: any;
  placeholder?: string;
  colorText?: string;
  items?: object | any;
  children?: any;
  onChange?: any;
  firstDisabled?: any;
  iconSize?: number;
  styleCollapse?: any;
  alt?: string;
}

export default function Dropdown(props: IDropdown, ref: any) {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(0) as any;
  const content = useRef(null as any);
  const theme = useTheme();

  const Up = R.drawables.commons.up;
  const Down = R.drawables.commons.down;

  React.useImperativeHandle(ref, () => ({
    setValue: setValue,
    getValue: getValue,
    setOpened: setOpened
  }));

  const setValue = (value: any) => {
    if (!value) setSelected(0);
    else setSelected(value);
  };

  const getValue = () => {
    return selected;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickComponent);
  }, []);

  useEffect(() => () => document.removeEventListener('mousedown', handleClickComponent), []);

  const handleClickComponent = (event: any) => {
    if (content && !content.current?.contains(event.target)) {
      setOpened(false);
    }
  };

  const renderItems = () => {
    let res = [] as any;
    if (props?.items) {
      //Primera opción disabled
      if (props.firstDisabled) {
        props.items[0] = props.firstDisabled;
      }
    }

    for (let item in props?.items) {
      let Icon;
      if (props?.items[item]?.iconId) {
        Icon = VM.icons.data[props?.items[item]?.iconId];
      }
      res.push(
        <div
          className={'content-item'}
          style={props.styleCollapse}
          key={item}
          onClick={() => {
            setOpened(false);
            if (selected !== props?.items[item]?.value) {
              setSelected(props?.items[item]?.value);
              props?.items[item]?.onChange(props?.items[item]?.value);
            }
          }}>
          <div className={'item'}>
            {Icon ? (
              <img
                alt={props.alt}
                style={{ width: props.iconSize ?? 20, height: props.iconSize ?? 20 }}
                src={Icon}
              />
            ) : null}
            {props?.items[item]?.label ? props?.items[item]?.label : props?.items[item]}
          </div>
          {/* <div className={'divider'} /> */}
        </div>
      );
    }
    return res;
  };

  let selectedItem = props.items.find((element: any) => element?.value === selected);
  let IconSel;
  if (selected && selectedItem && selectedItem?.iconId) {
    IconSel = VM.icons.data[selectedItem?.iconId];
  }

  return (
    <div className={'dropdown'} ref={content}>
      <div
        className={'dropdown-title'}
        style={props.colorText ? { color: props.colorText } : opened ? { color: 'black' } : {}}
        onClick={() => setOpened(!opened)}>
        <div style={{ flexDirection: 'row', columnGap: 8, alignItems: 'center', display: 'flex' }}>
          {IconSel ? (
            <img alt={props.alt} style={{ width: props.iconSize ?? 20, height: props.iconSize ?? 20 }} src={IconSel} />
          ) : null}
          {selectedItem ? selectedItem?.label : props.placeholder}
        </div>

        {opened ? (
          <Up style={{ fill: theme.colors.black, marginLeft: 'auto', paddingLeft: 5, width: 40 }} />
        ) : (
          <Down
            style={{ fill: theme.colors.black, marginLeft: 'auto', paddingLeft: 5, width: 40 }}
          />
        )}
      </div>
      <div className={'dropdown-content'}>
        <Collapse isOpened={opened}>
          {props?.children ? <div className={'children'}>{props?.children}</div> : renderItems()}
        </Collapse>
      </div>
    </div>
  );
}

// @ts-ignore
Dropdown = React.forwardRef(Dropdown);
