import ViewModel from '../@base/ViewModel';

export default class ScreenSelectedViewModel extends ViewModel {
    constructor() {
        super();
        this.section=null
        this.data='explore'
    }

    setSelected(screen) {
        this.data=screen
        this.section=null;
        super.notify(this.data)
    }

    setSection(section) {
        this.section=section
        super.notify(this.data)
    }
}

