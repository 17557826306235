import React, {useEffect, useState} from "react";
import PlannerModal from "./plannerModal/PlannerModal";
import RouteResults from "./routeResults/RouteResults";
import {VM} from "../../../../../viewmodel/VM";
import {Collapse} from "react-collapse/lib/Collapse";
import SecurityCollapse from "../../../../components/commons/SecurityCollapse";
import GeneralUtils from "../../../../../utils/GeneralUtils";

export default function PlannerOption(props, ref) {

    const [cards, setCards] = useState([])
    const [thereIntermediates, setThereIntermediates] = useState(VM.plannerSegments.data.length>0);

    useEffect(()=>{
        setIntermediates()
        VM.planner.listen((data)=>{
            setIntermediates()
            //else {setCards([]);setThereIntermediates(false)}
        })
        let copy = `${GeneralUtils.getBaseUrl()}/#/plan`;
        window.history.pushState('a','a', copy)
        return () => {
            if(VM.planner.data?.length==0)
                VM.plannerTime.clearTimes()
        }
    },[])

    const setIntermediates = () => {
        if(VM.planner.data.error!=null) {
            setThereIntermediates(true)
        }
        else if(!VM.planner.data.error && Object.keys(VM.planner.data)?.length) {
            setThereIntermediates(false)
        }
        setCards(VM.planner.data)
    }

    return (
        <div style={props.style}>
            <PlannerModal
                thereIntermediates={thereIntermediates}
                setThereIntermediates={setThereIntermediates}
                setShowSecond={props.setShowSecond}
                setOpen={props.setOpen}
                onClickMarker={props.onClickMarker}
            />
            <Collapse isOpened={cards.length !== 0 && !thereIntermediates}>
                {cards.length !== 0 && !thereIntermediates
                    ? <div>
                        <SecurityCollapse/>
                        <RouteResults cards={cards}/>
                    </div>
                    : null
                }
            </Collapse>
        </div>
    );
}
