import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { subscriptionsInformation } from '../../../../redux/slices/subscriptionsSlice';
import { VM } from '../../../../viewmodel/VM';
import { useLanguage, useTranslate } from '../../../context/languageContext';
import SubscribeLine from './manageNotifications/SubscribeLine';
import SubscribeNews from './manageNotifications/SubscribeNews';
import SubscribeStation from './manageNotifications/SubscribeStation';
import { agencyInformation } from '../../../../redux/slices/agencySlice';
import { linesState } from '../../../../redux/slices/lineSlice';

export default function TrafficAlertsScreen(props: any) {
  const [selected, setSelected] = useState(0);
  const agencyInfo = useSelector(agencyInformation);
  const language = useLanguage();
  const t = useTranslate();
  const allLines = useSelector(linesState);

  const subscriptions = useSelector(subscriptionsInformation);

  useEffect(() => {
    async function fetchData() {}
    fetchData();
  }, []);

  const renderSelected = () => {
    switch (selected) {
      case 0:
        return renderStations();
      case 1:
        return renderLines();
      case 2:
        return renderNews();
      default:
        return renderStations();
    }
  };

  const renderStations = () => {
    let res = [];
    for (let stopId in subscriptions.stops) {
      let stop = VM.stops.map[subscriptions.stops[stopId]];
      if(stop)
        res.push(<SubscribeStation key={stop?.id} stop={stop} />);
    }
    if (!res.length)
      return (
        <div className={'description padding10'} style={{ textAlign: 'left' }}>
          {t('subscriptions_empty_stopsMessage')}
        </div>
      );
    return res;
  };

  const renderLines = () => {
    let res = [];
    for (let lineId in subscriptions.lines) {
      let line: any = allLines?.find((element: any) => element?.id === subscriptions.lines[lineId]);
      if (line) {
        res.push(<SubscribeLine key={line.id} line={line} />);
      }
    }
    if (!res.length)
      return (
        <div className={'description padding10'} style={{ textAlign: 'left' }}>
          {t('subscriptions_empty_linesMessage')}
        </div>
      );
    return res;
  };

  const renderNews = () => {
    let res = [];
    for (let noticeId in subscriptions.agencyNotices) {
      if (subscriptions.agencyNotices.indexOf(subscriptions.agencyNotices[noticeId] as never) >= 0) {
        let notice: any = agencyInfo.agenciesTransport[subscriptions.agencyNotices[noticeId]];
        
        if(notice)
          res.push(<SubscribeNews key={notice?.id} notice={notice} />);
      }
    }
    if (!res.length)
      return (
        <div className={'description padding10'} style={{ textAlign: 'left' }}>
          {t('subscriptions_empty_newsMessage')}
        </div>
      );
    return res;
  };

  return (
    <div className={'account-child-header'} style={{ width: '100%' }}>
      <div className={'account-support-header'}>
        <div className={'title'}>{t('trafficAlerts')}</div>
        <div className={'row'} style={{ overflowX: 'scroll' }}>
          <div
            className={selected == 0 ? 'tabSelected' : 'tabSelector'}
            onClick={() => setSelected(0)}>
            {t('stops')}
          </div>
          <div
            className={selected == 1 ? 'tabSelected' : 'tabSelector'}
            onClick={() => setSelected(1)}>
            {t('lines')}
          </div>
          <div
            className={selected == 2 ? 'tabSelected' : 'tabSelector'}
            onClick={() => setSelected(2)}>
            {t('news')}
          </div>
        </div>
      </div>
      <div className={'account-child'} style={{ maxHeight: 414, minHeight: 414 }}>
        {renderSelected()}
      </div>
    </div>
  );
}
