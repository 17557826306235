import { useEffect, useRef, useState } from 'react';
import { useGetMessagesMutation } from '../../../../redux/login/services/MessagesServiceApi';
import { useLanguage, useTranslate } from '../../../context/languageContext';
import CommentStateElement from './CommentStateElement';
import ChatContentScreen from './ChatContentScreen';
import { IMessageChat } from '../../../../types/AccountInterfaces';
import { R } from '../../../R';

export default function CommentStateScreen(props: any) {
  const [elementsPerPage, setElementsPerPage] = useState(4);
  const [messages, setMessages] = useState([] as any);
  const [selected, setSelected] = useState(0);
  //campo que indica que se va a renderizar la información de un chat (tendrá el valor del id del chat)
  const [renderChatMessages, setRenderChatMessages] = useState<number>(0);
  const CloseIcon = R.drawables.general.Ic_arrowleft;

  const language = useLanguage();
  const t = useTranslate();
  const listInnerRef = useRef(null);

  const [getMessages] = useGetMessagesMutation();

  useEffect(() => {
    async function fetchData() {
      if (elementsPerPage - 3 <= messages?.data?.pagination?.total || !messages.data)
        setMessages(
          await getMessages({ locale: language, pageNumber: 1, elementsPerPage: elementsPerPage })
        );
    }
    fetchData();
  }, [elementsPerPage]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.round(scrollTop + 1 + clientHeight) >= scrollHeight) {
        setElementsPerPage(elementsPerPage + 4);
      }
    }
  };

  const getType = (element: { planningId: any; stopId: any; lineId: any }) => {
    if (element.planningId) {
      return t('planificationReport');
    } else if (element.stopId) {
      return t('stopReport');
    }
    //TODO: add line
    else if (element.lineId) {
      return t('lineReport');
    } else {
      return t('suggestion');
    }
  };

  const renderAlerts = () => {
    let res = [];
    const infoMessages: Array<IMessageChat> = messages?.data?.chats;
    //setMessagesOfUser(infoMessages);
    for (let element in infoMessages)
      if (
        (selected === 2 && infoMessages[element].numMessages > 1) ||
        (selected === 1 && infoMessages[element].numMessages <= 1 ||
        selected === 0)
      )
        res.push(
          <CommentStateElement
            key={infoMessages[element].id}
            id={infoMessages[element].id}
            title={infoMessages[element].title}
            type={infoMessages[element].categoryText}
            sentAt={infoMessages[element].lastMessageDate}
            messageBody={infoMessages[element].messageBody}
            reply={infoMessages[element].numMessages > 1 && infoMessages[element].unreaded > 0}
            seeResponse={infoMessages[element].numMessages > 1}
            replyAt={infoMessages[element]?.replyAt}
            setRenderChatMessages={setRenderChatMessages}
            fileId={infoMessages[element]?.fileId}
            fileExtension={infoMessages[element]?.fileExtension}
            fileName={infoMessages[element]?.fileName}
          />
        );
    return res;
  };

  return (
    <div className={'account-child-header'} style={{ width: '100%' }}>
      <div className={'account-support-header'} style={renderChatMessages ? {paddingLeft: 12} : {}}>
        <div className={renderChatMessages ? 'title titleComments' : 'title'}>
          {renderChatMessages ?<CloseIcon
            style={{cursor: 'pointer'}}
            onClick={async () => {
              setMessages(
                await getMessages({ locale: language, pageNumber: 1, elementsPerPage: elementsPerPage })
              );
              setRenderChatMessages(0);
            }}
          /> : null}
          {renderChatMessages ? 'Conversación' : t('messageState')}
        </div>
        {!renderChatMessages ? <div className={'row'} style={{ overflowX: 'scroll' }}>
          <div
            className={selected == 0 ? 'tabSelected' : 'tabSelector'}
            onClick={() => setSelected(0)}>
            {t('all')}
          </div>
          <div
            className={selected == 1 ? 'tabSelected' : 'tabSelector'}
            onClick={() => setSelected(1)}>
            {t('inProcess')}
          </div>
          <div
            className={selected == 2 ? 'tabSelected' : 'tabSelector'}
            onClick={() => setSelected(2)}>
            {t('answered')}
          </div>
        </div> : null}
      </div>
      {renderChatMessages ? (
        <ChatContentScreen
          messageId={renderChatMessages}
          setRenderChatMessages={() => setRenderChatMessages(0)}
          messagesUser={messages?.data?.chats}
        />
      ) : (
        <div
          className={'account-child'}
          style={{ maxHeight: 414, minHeight: 414 }}
          onScroll={onScroll}
          ref={listInnerRef}>
          {renderAlerts()}
        </div>
      )}
    </div>
  );
}
