import React, { useState } from 'react';
import StopsLinesNearMarker from './StopsLinesNearMarker';
import { Collapse } from 'react-collapse';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import { useDispatch } from 'react-redux';
import { useLazyGetLineDirectionsQuery } from '../../../../../../redux/login/line/lineServiceApi';
import { VM } from '../../../../../../viewmodel/VM';
import { eventsModalstoggleVisibilitySlice } from '../../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { lineSlice } from '../../../../../../redux/slices/lineSlice';
import { useTranslate } from '../../../../../context/languageContext';

interface StopsLinesBarProps {
  latitude: number;
  longitude: number;
  banStop?: any;
  setMarkerSelected: Function;
}

export default function StopsLinesBar(props: StopsLinesBarProps) {
  const [opened, setOpened] = useState(false);
  const theme = useTheme();
  const ArrowUp = R.drawables.general.Ic_arrowup;
  const ArrowDown = R.drawables.general.Ic_arrowdown;
  const dispatch = useDispatch();
  const [getLineDirections] = useLazyGetLineDirectionsQuery();
  const t = useTranslate();

  async function renderLineDetails(lineId: number, stopId: number) {
    dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
    const result: any = await getLineDirections(lineId);
    if (result?.data?.length) {
      let tripId = result?.data[0]?.tripId;
      VM.lines.setSelected(lineId, tripId, stopId);
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityReportModalWidget({
          element: lineId
        })
      );
      dispatch(lineSlice.actions.updateFilteredLine(lineId));
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
    } else {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#A31610',
          text: t('serviceError'),
          status: 'error'
        })
      );
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
    }
  }

  async function renderNearStop(stop: any) {
    setOpened(false);
    props.setMarkerSelected(stop);
  }

  return (
    <>
      <div
        onClick={() => setOpened(!opened)}
        style={{
          backgroundColor: theme.colors.neutralBackground,
          padding: 8,
          borderRadius: 4,
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          color: theme.colors.neutralDarkGray,
          fontSize: 14,
          fontWeight: 600,
          marginBottom: 8,
        }}>
        {t('visualizer_closestStopsLines')}
        {opened ? <ArrowDown style={{fill: theme.colors.neutralDarkGray, width: 20, height: 20}}/> : <ArrowUp style={{fill: theme.colors.neutralDarkGray, width: 20, height: 20}}/>}
      </div>
      <div className={'dropdown-content'}>
        <Collapse isOpened={opened}>
          <StopsLinesNearMarker
            latitude={props.latitude}
            longitude={props.longitude}
            banStop={props.banStop}
            onClickLine={renderLineDetails}
            onClickStop={renderNearStop}
          />
        </Collapse>
      </div>
    </>
  );
}
