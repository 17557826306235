import React, { useState } from 'react';
import { R } from '../../../../../R';
import { Collapse } from 'react-collapse';
import { useTheme } from '../../../../../context/themeContext';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import DateStopOptions from './DateStopOptions';

export default function SelectorDateStop({
  exitDate,
  setExitDate
}: {
  exitDate: string | undefined;
  setExitDate: Function;
}) {
  const [collapsed, setCollapsed] = useState(false);

  const theme = useTheme();
  const t = useTranslate();
  const language = useLanguage();

  const styles = {
    collapse: {
      width: '85.7vw',
      maxWidth: '350px',
      minWidth: '255px',
      background: theme.colors.white,
      zIndex: 1,
      //marginLeft: -22,
      boxShadow: '0px 4px 4px rgba(28, 31, 32, 0.1)',
      borderRadius: '0 0 12px 12px',
      backgroundColor: theme.colors.white
    },
    row: {
      display: 'flex'
    },
    option: {
      display: 'flex',
      cursor: 'pointer'
    },
    exitNow: {
      fontSize: 14,
      fontWeight: 600
    }
  };

  const getFormattedDate = (date: string) => {
    let day;
    let month;
    let data = new Date(date);
    switch (data.getDay()) {
      case 1:
        day = t('monday');
        break;
      case 2:
        day = t('tuesday');
        break;
      case 3:
        day = t('wednesday');
        break;
      case 4:
        day = t('thursday');
        break;
      case 5:
        day = t('friday');
        break;
      case 6:
        day = t('saturday');
        break;
      case 0:
        day = t('sunday');
        break;
      default:
        day = t('-');
        break;
    }
    switch (data.getMonth()) {
      case 0:
        month = t('january');
        break;
      case 1:
        month = t('february');
        break;
      case 2:
        month = t('march');
        break;
      case 3:
        month = t('april');
        break;
      case 4:
        month = t('may');
        break;
      case 5:
        month = t('june');
        break;
      case 6:
        month = t('july');
        break;
      case 7:
        month = t('august');
        break;
      case 8:
        month = t('september');
        break;
      case 9:
        month = t('october');
        break;
      case 10:
        month = t('november');
        break;
      case 11:
        month = t('december');
        break;
      default:
        month = t('-');
        break;
    }
    let res =
      language === 'eu'
        ? `${day}, ${month} ${data.getDate()}`
        : `${day}, ${data.getDate()}  ${month}`;
    return res;
  };

  function getTitleExit() {
    if (exitDate) {
      return `${t('exitAtText')} ${getFormattedDate(exitDate)}`;
    }
    return t('exitNow');
  }

  const Up = R.drawables.general.Ic_ChevronUpSmall;

  const Down = R.drawables.general.Ic_ChevronDownSmall;

  return (
    <>
      <div
        style={styles.option}
        onClick={() => {
          setCollapsed(!collapsed);
        }}>
        <div style={styles.exitNow}>{getTitleExit()}</div>
        {collapsed ? <Up /> : <Down />}
      </div>
      <div style={{ ...styles.collapse /* ...{position: 'fixed'} */ }}>
        <Collapse isOpened={collapsed}>
          <DateStopOptions exitDate={exitDate} setExitDate={setExitDate} />
        </Collapse>
      </div>
    </>
  );
}
