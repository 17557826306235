import React, { useEffect } from 'react'
import { useGetMessagesCheckReadedQuery } from '../../redux/login/services/MessagesServiceApi';
import { useLanguage } from './languageContext';
import { useDispatch } from 'react-redux';
import { notificationSlice } from '../../redux/slices/notificationSlice';

export default function AccountCalls() {
    const language = useLanguage();
    const dispatch = useDispatch();
    
    const { data: messageChatData } = useGetMessagesCheckReadedQuery(
        { locale: language, pageNumber: 1, elementsPerPage: 10 },
        {
          pollingInterval: 30000
        }
    );

    useEffect(() => {
        if (messageChatData?.chats?.length) {
            let chats = messageChatData?.chats;
            let checked = false;
            let i = 0;
            while(!checked && i < chats?.length ) {
                //infoMessages[element].numMessages > 1 && infoMessages[element].unreaded > 0
                if (chats[i]?.numMessages > 1 && chats[i]?.unreaded > 0) {
                    checked = true;
                }
                i++;
            }
            dispatch(notificationSlice.actions.updateCheckMessages(checked));
        }
    }, [messageChatData])

  return (
    <>
    </>
  )
}
