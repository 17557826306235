import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import Toast from '../../commons/toasts/Toast';

export default function ServerNotAvailableModalWidget(props: any) {
  const theme = useTheme();
  const Close = R.drawables.commons.close;
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityServerNotAvailableModalWidget(
          false
        )
      );
    }, 5000);
  });

  return (
    <Toast
      icon={<Close fill={theme.colors.white} />}
      text={'Server not available'}
      background={'#A31610'}
    />
  );
}
