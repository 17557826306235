import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import GeneralUtils from '../../../utils/GeneralUtils';
import { RootState } from '../../store';

export const ShareServiceApi = createApi({
  reducerPath: 'ShareServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GeneralUtils.getEnvironment(window.location.origin),
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accountInformation.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    // sharePlan: builder.query<any, any>({
    //   query: (planId) => `/plan/share?planId=${planId}`
    // }),
    sharePlan: builder.mutation<any, any>({
      query: ({ planId }) => ({
        url: `/plan/share?planId=${planId}`,
        method: 'GET'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {}
      }
    })
  })
});

export const { useSharePlanMutation } = ShareServiceApi;
