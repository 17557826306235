import { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useDispatch, useSelector } from 'react-redux';
import { Apis } from '../../../../../domain/Apis';
import { useDeleteSubscriptionsMutation } from '../../../../../redux/login/subscriptions/SubscriptionsServiceApi';
import {
  subscriptionsInformation,
  subscriptionsSlice
} from '../../../../../redux/slices/subscriptionsSlice';
import { VM } from '../../../../../viewmodel/VM';
import { useLanguage, useTranslate } from '../../../../context/languageContext';
import { useTheme } from '../../../../context/themeContext';
import { R } from '../../../../R';
import LineIcon from '../../../explorescreen/mapOptions/searcherOption/stopDetail/LineIcon';
import { agencyInformation } from '../../../../../redux/slices/agencySlice';

export default function SubscribeStation(props: any) {
  const ArrowDown = R.drawables.general.Ic_ChevronDownSmall;
  const ArrowUp = R.drawables.general.Ic_ChevronUpSmall;
  const Remove = R.drawables.general.Ic_delete;
  const t = useTranslate();
  const [removeSubscription] = useDeleteSubscriptionsMutation();
  const subscriptions: any = useSelector(subscriptionsInformation);
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const [lines, setLines] = useState([]);
  const [transportMode, setTransportMode] = useState('');
  const [iconType, setIconType] = useState();
  const content = useRef(null as any);
  const language = useLanguage();
  const transportModes = useSelector(agencyInformation).transportModes;
  const dataOrigin = useSelector(agencyInformation).dataOrigin;

  const theme = useTheme();

  useEffect(() => {
    let foundTransportMode: any = transportModes.find((element: any) => element.id === props.stop?.transportMode);
    if (foundTransportMode) {
      setTransportMode(foundTransportMode?.label);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      let dataOri: any = dataOrigin.find(
        (element: any) => element.id === props.stop?.agencyOriginId
      );
      if (!(dataOri?.label && dataOri?.label?.toLowerCase()?.includes('renfe media distancia'))) {
        setLines(await Apis.stops.getStopLines(language, props.stop.id));
      }
      setIconType(await VM.icons.getIcon(props.stop.agencyOriginId, 'agency'));
    }
    fetchData();
  }, [props.stop, dataOrigin]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickComponent);
  }, []);

  useEffect(() => () => document.removeEventListener('mousedown', handleClickComponent), []);

  const handleClickComponent = (event: any) => {
    if (content && !content.current?.contains(event.target)) {
      setOpened(false);
    }
  };

  const renderLines = () => {
    let res = [];
    for (let line in lines) {
      res.push(
        <LineIcon
          key={line}
          style={{ margin: 2 }}
          textColor={'#' + VM.lines.data[lines[line]['id']]?.routeTextColor}
          text={VM.lines.data[lines[line]['id']]?.code}
          color={lines[line]['routeColor'] ? '#' + lines[line]['routeColor'] : theme.colors.main}
        />
      );
    }
    return <div className="row">{res}</div>;
  };

  const removeSub = () => {
    let updateSubscriptions = JSON.parse(JSON.stringify(subscriptions));
    updateSubscriptions.stops.splice(updateSubscriptions.stops.indexOf(props.stop.id), 1);
    removeSubscription({
      stops: [props.stop.id],
      lines: [],
      agencyNotices: []
    });
    dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
  };

  return (
    <div
      className={'sub-station-container'}
      style={{ maxHeight: window.innerHeight - 165 }}
      ref={content}>
      <div
        className={opened ? 'favorite-station-body-c' : 'favorite-station-body-o'}
        onClick={() => setOpened(!opened)}>
        <div className={'row'}>
          <img className={'agency-icon-box'} src={iconType} />
          <div className={'iconText'}>{transportMode}</div>
          {opened ? <ArrowUp className={'close'} /> : <ArrowDown className={'close'} />}
        </div>
        <div className={'subtitle marginTop5'} style={{ textAlign: 'left' }}>
          {props.stop?.name}
        </div>
        {renderLines()}
      </div>

      <Collapse isOpened={opened}>
        <div className={'favorite-station-footer row'}>
          <div
            className={'row'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              removeSub();
            }}>
            <Remove style={{ width: 20, marginRight: 5, fill: theme.colors.neutral }} />
            <div className={'primaryText'} style={{ color: theme.colors.neutral }}>
              {t('delete')}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
