import React, {useState, useEffect} from 'react';
import { VM } from '../../../../../../../viewmodel/VM';
import {useLanguage, useTranslate} from '../../../../../../context/languageContext'
import OperatorsGroupFilters from './OperatorsGroupFilters';

export default function OperatorsFilters() {

    const t = useTranslate();
    const locale = useLanguage();
    const [renderMyTransport, setRenderMyTransport] = useState(null)

    useEffect(() => {
        async function fetchData() {
            setRenderMyTransport(await getRenderMyTransport())
        }
        fetchData()
    },[]);

    const getRenderMyTransport = async () => {
        let res = []
        if (!Object.keys(VM.dataOrigin.data)?.length)
            await VM.dataOrigin.get(locale)
        if (!Object.keys(VM.agencies.data)?.length)
            await VM.agencies.get(locale)
        let transports = VM.modes?.data
        for (let mode in transports) {
            res.push(<OperatorsGroupFilters key={mode}
                                       mode={transports[mode]}/>)
        }
        return res
    }

    return (
        <div style={{overflowY:'scroll', height:window.innerHeight-600}}>
            {renderMyTransport}
        </div>
    );
}
