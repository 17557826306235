import React, { useEffect, useState } from 'react';
import { useLanguage, useTranslate } from '../../../context/languageContext';
import { VM } from '../../../../viewmodel/VM';
import { R } from '../../../R';
import { useTheme } from '../../../context/themeContext';
import { useSelector } from 'react-redux';
import { filtersNewsAlertsState } from '../../../../redux/slices/newsSlice';

export default function NewsLinksSections() {
  const language = useLanguage();
  const t = useTranslate();
  const [renderedOperators, setRenderedOperators] = useState<Array<any>>([]);
  const Link = R.drawables.general.Ic_Link;
  const theme = useTheme();
  const filtersSelectedAgencies = useSelector(filtersNewsAlertsState);

  const operators = [
    {
      id: 1,

      agencyOriginId: 25,

      name: 'AlavaBus',

      link: 'https://alavabus.eus/es/avisos',

      linkEu: 'https://alavabus.eus/eu/abisuak'
    },

    {
      id: 2,

      agencyOriginId: 21,

      name: 'BilboBus',

      link: 'https://www.bilbao.eus/cs/Satellite/bilbobus/es/ultima-hora',

      linkEu: 'https://www.bilbao.eus/cs/Satellite/bilbobus/eu/ultima-hora'
    },

    {
      id: 3,

      agencyOriginId: 20,

      name: 'BizkaiBus',

      link: 'https://www.bizkaia.eus/es/web/bizkaibus/avisos',

      linkEu: 'https://www.bizkaia.eus/eu/web/bizkaibus/oharrak'
    },

    {
      id: 4,

      agencyOriginId: 19,

      name: 'DBus',

      link: 'https://www.dbus.eus/es/usuarios/avisos/',

      linkEn: 'https://www.dbus.eus/en/comunication/news/',

      linkFr: 'https://www.dbus.eus/fr/communication/actualites/',

      linkEu: 'https://www.dbus.eus/dbus-en-mugitzea/oharrak/'
    },

    {
      id: 5,

      agencyOriginId: 27,

      name: 'EuskoTren (Línea General)',

      link: 'https://www.euskotren.eus/es/tren/noticias',

      linkFr: 'https://www.euskotren.eus/fr/tren/noticias',

      linkEn: 'https://www.euskotren.eus/en/tren/noticias',

      linkEu: 'https://www.euskotren.eus/eu/tren/noticias'
    },

    {
      id: 6,

      agencyOriginId: 27,

      name: 'EuskoTren (Metro L3)',

      link: 'https://www.euskotren.eus/es/l3/noticias',

      linkFr: 'https://www.euskotren.eus/fr/l3/noticias',

      linkEn: 'https://www.euskotren.eus/en/l3/noticias',

      linkEu: 'https://www.euskotren.eus/eu/l3/noticias'
    },

    {
      id: 7,

      agencyOriginId: 27,

      name: 'EuskoTren (Tranvía)',

      link: 'https://www.euskotren.eus/es/tranvia/noticias',

      linkFr: 'https://www.euskotren.eus/fr/tranvia/noticias',

      linkEn: 'https://www.euskotren.eus/en/tranvia/noticias',

      linkEu: 'https://www.euskotren.eus/eu/tranvia/noticias'
    },

    {
      id: 8,

      agencyOriginId: 27,

      name: 'Funicular Lareineta',

      link: 'https://www.euskotren.eus/es/funicular/noticias',

      linkFr: 'https://www.euskotren.eus/fr/funicular/noticias',

      linkEn: 'https://www.euskotren.eus/en/funicular/noticias',

      linkEu: 'https://www.euskotren.eus/eu/funicular/noticias'
    },

    {
      id: 9,

      agencyOriginId: 3,

      name: 'Irunbus',

      link: 'https://auif.es/noticias/',

      linkEu: 'https://auif.es/eu/albisteak/'
    },

    {
      id: 10,

      agencyOriginId: 31,

      name: 'Kbus (Barakaldo)',

      link: 'https://www.kbus.eus/',

      linkEu: 'https://www.kbus.eus/'
    },

    {
      id: 11,

      agencyOriginId: 30,

      name: 'La Unión',

      link: 'https://www.autobuseslaunion.com/alertas/',

      linkFr: 'https://www.autobuseslaunion.com/en/alerts/',

      linkEn: 'https://www.autobuseslaunion.com/en/alerts/',

      linkEu: 'https://www.autobuseslaunion.com/eu/alertak/'
    },

    {
      id: 12,

      agencyOriginId: 5,

      name: 'Lurraldebus',

      link: 'https://www.lurraldebus.eus/news/news',

      linkEn: 'https://www.lurraldebus.eus/news/news',

      linkFr: 'https://www.lurraldebus.eus/news/news',

      linkEu: 'https://www.lurraldebus.eus/news/news'
    },

    {
      id: 13,

      agencyOriginId: 22,

      name: 'Metro Bilbao',

      link: 'https://www.metrobilbao.eus/es/noticias',

      linkEn: 'https://www.metrobilbao.eus/en/news',

      linkFr: 'https://www.metrobilbao.eus/en/news',

      linkEu: 'https://www.metrobilbao.eus/eu/albisteak'
    },

    {
      id: 14,

      agencyOriginId: 24,

      name: 'Puente Colgante',

      link: 'https://puente-colgante.com/noticias/',

      linkEu: 'https://puente-colgante.com/noticias/'
    },

    {
      id: 15,

      agencyOriginId: 48,

      name: 'RENFE',

      linkFr:
        'https://www.renfe.com/es/fr/groupe-renfe/communication/renfe-au-jour-le-jour/avertissements',

      link: 'https://www.renfe.com/es/es/grupo-renfe/comunicacion/renfe-al-dia/avisos',

      linkEn: 'https://www.renfe.com/es/en/renfe-group/communication/renfe-today/alerts',

      linkEu: 'https://www.renfe.com/es/eu/renfe-taldea/komunikazioa/renfe-egunez-egunez/oharrak'
    },

    {
      id: 16,

      agencyOriginId: 26,

      name: 'Tuvisa',

      linkFr:
        'https://www.vitoria-gasteiz.org/we001/was/we001Action.do?lang=fr&locale=fr&idioma=fr&claveTema=2&claveArea=1&accion=cuadroMando&accionWe001=ficha',

      link: 'https://www.vitoria-gasteiz.org/we001/was/we001Action.do?accionWe001=ficha&idioma=es&accion=cuadroMando&claveArea=1&claveTema=2',

      linkEn:
        'https://www.vitoria-gasteiz.org/we001/was/we001Action.do?lang=en&locale=en&idioma=en&claveArea=1&claveTema=2&accion=cuadroMando&accionWe001=ficha',

      linkEu:
        'https://www.vitoria-gasteiz.org/we001/was/we001Action.do?lang=eu&locale=eu&idioma=eu&claveTema=2&claveArea=1&accion=cuadroMando&accionWe001=ficha'
    },

    {
      id: 17,

      agencyOriginId: 4,

      name: 'Udalbus (Eibar)',

      link: 'https://www.udalbuseibar.eus/noticias/',

      linkEu: 'https://www.udalbuseibar.eus/eu/berriak/'
    }
  ];

  const styles = {
    content: {
      display: 'flex',
      paddingInline: 10
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 0.5,
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px'
    },
    tags: {
      border: null,
      borderRadius: 0,
      borderColor: theme.colors.primaryMedium,
      justifyContent: 'center',
      backgroundColor: theme.colors.white,
      transition: 'all 100ms linear'
    },
    text: {
      fontSize: '14px',
      fontWeight: 600,
      color: theme.colors.black
    },
    row: {
      display: 'flex',
      marginRight: 10
    },
    rowOperators: {
      display: 'flex',
      marginRight: 10,
      backgroundColor: 'white',
      borderRadius: 10,
      padding: 10,
      alignItems: 'center'
    },
    name: {
      flex: 1,
      fontSize: 14,
      fontWeight: 500
    },
    container: {
      width: '100%',
      zIndex: 10
    }
  };

  const getOperators = async () => {
    let res = [];
    let filteredOperators = filtersSelectedAgencies?.length ? operators.filter((element: any) =>
      filtersSelectedAgencies.find((agency: number) => agency === element.agencyOriginId)
    ) : operators;
    for (let operator in filteredOperators) {
      let icon = await VM.icons.getIcon(filteredOperators[operator]?.agencyOriginId, 'dataOrigin');
      res.push(
        <div key={filteredOperators[operator]?.id}>
          <div style={styles.rowOperators}>
            <img style={{ width: 20, marginRight: 10 }} src={icon} />
            <div style={styles.name}>{filteredOperators[operator]?.name}</div>
            <div style={styles.row}>
              <Link style={{ width: 20, marginRight: 10 }} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={styles.name}>{t('website')}</div>
                <a
                  style={{ fontSize: 14, flex: 1, wordBreak: 'break-all' }}
                  href={
                    language === 'eu'
                      ? filteredOperators[operator]?.linkEu
                      : language === 'en'
                      ? filteredOperators[operator]?.linkEn
                      : language === 'fr'
                      ? filteredOperators[operator]?.linkFr
                      : filteredOperators[operator]?.link
                  }
                  target="_blank">
                  {t('visit')}
                </a>
              </div>
            </div>
          </div>
          <div style={styles.separator} />
        </div>
      );
    }
    return res;
  };

  useEffect(() => {
    async function fetchData() {
      const result: Array<any> = await getOperators();
      result && setRenderedOperators(result);
    }
    fetchData();
  }, [filtersSelectedAgencies]);

  return (
    <div
      style={{
        backgroundColor: theme.colors.background,
        padding: 20,
        maxHeight: window.innerHeight - 205,
        overflowY: 'scroll',
        borderRadius: '0px 0px 10px 10px'
      }}>
      {renderedOperators}
    </div>
  );
}
