import UserApi from './apis/UserApi';
import LocationApi from "./apis/LocationApi";
import StopsApi from "./apis/StopsApi";
import PoisApi from "./apis/PoisApi";
import CategoriesApi from "./apis/CategoriesApi";
import SearchApi from "./apis/SearchApi";
import AgencyApi from './apis/AgencyApi';
import TransportModesApi from './apis/TransportModesApi';
import PlannerApi from "./apis/PlannerApi";
import LinesApi from "./apis/LinesApi";
import IconsApi from "./apis/IconsApi";
import NewsApi from "./apis/NewsApi";
import AlertApi from "./apis/AlertApi";

export const Apis = {
    stops: new StopsApi(),

    initialize() {
        this.env = 'prod';
        this.build(this.env);
    },

    setEnvironment(env) {
        this.env = env;
        this.build(this.env);
    },

    build(env) {
        this.user = new UserApi(env);
        this.alert = new AlertApi(env)
        this.location = new LocationApi(env);
        this.stops = new StopsApi(env);
        this.pois = new PoisApi(env);
        this.lines = new LinesApi(env);
        this.search = new SearchApi(env)
        this.news = new NewsApi(env)
        this.categories = new CategoriesApi(env);
        this.agency = new AgencyApi(env);
        this.transportModes = new TransportModesApi(env);
        //this.auth = new AuthApi(env);
        //this.resources = new ResourcesApi(env);
        this.planner = new PlannerApi(env);
        this.icons = new IconsApi(env);
        //this.searcherOption = new SearchApi(env);
    },
};
