import React from 'react'
import { Apis } from '../../../domain/Apis';
import ViewModel from '../../@base/ViewModel';
import FilterUtils from '../../../utils/FilterUtils';
import { getMotorizedModel } from './FiltersModel';

export default class MotorizedFiltersViewModel extends ViewModel {
    async get(locale) {
        let merged = null;
        /* Se intenta obtener de la api */
        let categories = getMotorizedModel(locale);
        /* Se obtiene de la cache */
        const stored = localStorage.getItem('motorizedFilters');
        /* Si se puede comparar ambos se compara */
        if (stored && categories && categories.length > 0) {
          merged = FilterUtils.compareFilters(JSON.parse(stored), categories);
        } else if (categories  && categories.length > 0) {
          merged = categories;
        } else if (stored) {
          merged = JSON.parse(stored);
        }
        return super.notify({motorized: merged})
      }
    
      set(values) {
        localStorage.setItem('motorizedFilters', JSON.stringify(values));
        return super.notify({motorized: values});
      }
}

