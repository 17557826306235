import {R} from "../../R";
import {useTranslate} from "../../context/languageContext";
import {useEffect} from "react";
import {VM} from "../../../viewmodel/VM";
import GeneralUtils from "../../../utils/GeneralUtils";

export default function FavoriteHeader(props: any) {

    const Close = R.drawables.general.Ic_close
    const t = useTranslate();

    useEffect(() => {
        try {
            let path = GeneralUtils.getLastSectionPath()
            switch (path) {
                case 'stops': {
                    props.setSelected(0);
                    break;
                }
                case 'routes': {
                    props.setSelected(1);
                    break;
                }
                case 'locations': {
                    props.setSelected(2);
                    break;
                }
                case 'operators': {
                    props.setSelected(3);
                    break;
                }
                default:
                    props.setSelected(0);
                    break;
            }

        } catch (e) {
            
        }
    }, [window.location]);

    return (
        <div /* className={"transit"} */ style={{borderRadius: '12px 12px 0px 0px', marginTop: 25}}>
            <div className={'row'} style={{backgroundColor: '#0078b2', padding: 12, borderRadius: '12px 12px 0px 0px',}}>
                <div className={"title white"}>
                    {t('favorites')}
                </div>
                <Close className={'close-white'} onClick={()=>{VM.screenSelected.setSelected('explore')}}/>
            </div>

            <div className={'scrollable-container'} style={{overflowX: 'auto', padding: 12}}>
                <div className={'transit-option column'} onClick={()=>{
                    GeneralUtils.setLastSectionPath('stops',1)
                    props.setSelected(0)
                }}>
                    <div className={'subtitle'}
                         style={{marginBottom:5, minWidth:  70, ...props.selected ? {opacity:0.5} : {opacity:1}}}>
                        {t('stations')}
                    </div>
                    <div className={"divider-select b-mediumDark"}
                         style={{...props.selected ? {opacity:0} : {opacity:1}}}/>
                </div>

                <div className={'transit-option column'} onClick={()=>{
                    GeneralUtils.setLastSectionPath('routes',1)
                    props.setSelected(1)
                }}>
                    <div className={'subtitle'}
                         style={{marginBottom:5, minWidth:  70, ...props.selected!=1 ? {opacity:0.5} : {opacity:1}}}>
                        {t('routes')}
                    </div>
                    <div className={"divider-select b-mediumDark"}
                         style={{...props.selected!=1 ? {opacity:0} : {opacity:1}}}/>
                </div>

                <div className={'transit-option column'} onClick={()=>{
                    GeneralUtils.setLastSectionPath('locations',1)
                    props.setSelected(2)
                }}>
                    <div className={'subtitle'}
                         style={{marginBottom:5, minWidth:  100, ...props.selected!=2 ? {opacity:0.5} : {opacity:1}}}>
                        {t('ubis')}
                    </div>
                    <div className={"divider-select b-mediumDark"}
                         style={{...props.selected!=2 ? {opacity:0} : {opacity:1}}}/>
                </div>

                <div className={'transit-option column'} onClick={()=>{
                    GeneralUtils.setLastSectionPath('operators',1)
                    props.setSelected(3)
                }}>
                    <div className={'subtitle'}
                         style={{marginBottom:5, minWidth:  100, ...props.selected != 3 ? {opacity:0.5} : {opacity:1}}}>
                        {t('operators')}
                    </div>
                    <div className={"divider-select b-mediumDark"}
                         style={{...props.selected != 3 ? {opacity:0} : {opacity:1}}}/>
                </div>

            </div>

        </div>
    );
}
