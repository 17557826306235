import React, {createContext, useContext} from 'react';
import { useMediaQuery } from 'react-responsive'

/**
 * Contexto para manejar los responsive en la aplicación.
 */

const ResponsiveContext = createContext();

export function ResponsiveProvider({children}) {

    const isBig = useMediaQuery({ minWidth: 1024})
    const isTablet = useMediaQuery({ minWidth: 432, maxWidth: 1024 })
    const isMobile = useMediaQuery({ maxWidth: 432 })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    const responsive = [{"isBig": isBig,
        "isTablet": isTablet,
        "isMobile": isMobile,
        "isPortrait": isPortrait}];

    return (
        <ResponsiveContext.Provider value={responsive[0]}>
            {children}
        </ResponsiveContext.Provider>
    );
}

export function useResponsive() {
    return useContext(ResponsiveContext);
}
