export default class GeneralUtils {
    static getEnvironment(origen) {
        //return process.env.REACT_APP_ENDPOINT_PROD
        if(process.env.REACT_APP_PRU.includes(origen) || process.env.REACT_APP_PRU_IP.includes(origen))
            return process.env.REACT_APP_ENDPOINT_PRU;
        else if(process.env.REACT_APP_PROD.includes(origen))
            return process.env.REACT_APP_ENDPOINT_PROD;
        else if(process.env.REACT_APP_PROD2.includes(origen))
            return process.env.REACT_APP_ENDPOINT_PROD2;
        else if(process.env.REACT_APP_LOCAL.includes(origen))
            return process.env.REACT_APP_ENDPOINT_LOCAL;
        else if(process.env.REACT_APP_DES2.includes(origen))
            return process.env.REACT_APP_ENDPOINT_DES;
        else if(process.env.REACT_APP_DES.includes(origen))
            return process.env.REACT_APP_ENDPOINT_DES;
        else
            return process.env.REACT_APP_ENDPOINT_PROD;
    }

    static convertFromHex(hex) {
        var hex = hex?.toString();//force conversion
        var str = '';
        for (var i = 0; i < hex?.length-1; i += 2)
            str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
        return str;
    }

    static convertToHex(str) {
        var hex = '';
        for(var i=0;i<str?.length;i++) {
            hex += ''+str.charCodeAt(i)?.toString(16);
        }
        return hex;
    }

    static getBaseUrl() {
        let url = window.location.href.split('/');
        if (window.location.href.includes('portal')) {
            if (!url[url.length-1].length) {
                url.pop()
            }
            for (let i = url.length - 1; i > 0; i--) {
                if(url[i]!=='portal') {
                    url.pop();
                } else break;
            }
            url = url.join('/')
            return url
        } else {
            if (!url[url.length-1].length) {
                url.pop()
            }
            for (let i = url.length - 1; i > 0; i--) {
                if(url[i] !== 'es') {
                    url.pop();
                } else break;
            }
            url = url.join('/')
            return url
        }   
    }

    static getSectionPath(){
        let url = window.location.href.split('/');
        if (window.location.href.includes('portal')) {
            if(!url[url.length-1].length)
                url.pop()
            let path=undefined
            for(let i = 0; i<url.length;i++){
                if(url[i]==='portal' && i+1<url.length) {
                    path = url[i + 1]==='#' ? url[i + 2] : url[i + 1];
                    break;
                }
            }
            if(path===undefined) {
                if(url[3]!=='#')
                    path = url[3]
                else path = url[4]
            }
            if(path!==undefined && path.indexOf('?')>=0)
                return path.substring(0, path.indexOf('?'))
            else
            return path
        } else {
            if(!url[url.length-1].length)
                url.pop()
            let path=undefined
            for(let i = 0; i<url.length;i++){
                if(url[i]==='es' && i+1<url.length) {
                    path = url[i + 1]==='#' ? url[i + 2] : url[i + 1];
                    break;
                }
            }
            if(path===undefined) {
                if(url[3]!=='#')
                    path = url[3]
                else path = url[4]
            }
            if(path!==undefined && path.indexOf('?')>=0)
                return path.substring(0, path.indexOf('?'))
            else
            return path
        }
    }

    static getParams(section){
        let url = window.location.href.split('/');

        if (window.location.href.includes('portal')) {
            if(!url[url.length-1].length)
                url.pop()
            let path=undefined
            for(let i = 0; i<url.length;i++){
                if(url[i]==='portal' && i+1<url.length) {
                    path = url[i + 1]==='#' ? url[i + 2] : url[i + 1];
                    break;
                }
            }
            if(path===undefined) {
                if(url[3]!=='#')
                    path = url[3]
                else path = url[4]
            }
            if(path!==undefined && path.indexOf('?')>=0)
                if(this.getSectionPath()===section)
                    return path.substring(path.indexOf('?'),path.length)
                else return ''
            else
                return ''
        } else {
            if(!url[url.length-1].length)
                url.pop()
            let path=undefined
            for(let i = 0; i<url.length;i++){
                if(url[i]==='es' && i+1<url.length) {
                    path = url[i + 1]==='#' ? url[i + 2] : url[i + 1];
                    break;
                }
            }
            if(path===undefined) {
                if(url[3]!=='#')
                    path = url[3]
                else path = url[4]
            }
            if(path!==undefined && path.indexOf('?')>=0)
                if(this.getSectionPath()===section)
                    return path.substring(path.indexOf('?'),path.length)
                else return ''
            else
                return '' 
        }
        
    }

    static setLastSectionPath(section,pos) {
        if (window.location.href.includes('portal')) {
            if(pos!==undefined){
                let path=undefined
                let url = window.location.href.split('/')
                if(!url[url.length-1].length)
                    url.pop()
                for(let i = 0; i<url.length;i++){
                    if(url[i]==='portal' && i+1<url.length) {
                        path = url[i + 1]==='#' ? i + 2 : i + 1;
                        break;
                    }
                }
                if(path==undefined) {
                    if(url[3]!=='#')
                        path = 3
                    else path = 4
                }
                url[path+pos]=section
                window.history.pushState('url', 'url', url.join('/'));
    
            }
            else {
                let url = window.location.href + '/'+ section
                window.history.pushState('url', 'url', url);
            }
        } else {
            if(pos!==undefined){
                let path=undefined
                let url = window.location.href.split('/')
                if(!url[url.length-1].length)
                    url.pop()
                for(let i = 0; i<url.length;i++){
                    if(url[i]==='es' && i+1<url.length) {
                        path = url[i + 1]==='#' ? i + 2 : i + 1;
                        break;
                    }
                }
                if(path==undefined) {
                    if(url[3]!=='#')
                        path = 3
                    else path = 4
                }
                url[path+pos]=section
                window.history.pushState('url', 'url', url.join('/'));
    
            }
            else {
                let url = window.location.href + '/'+ section
                window.history.pushState('url', 'url', url);
            }
        }

    }

    static getLastSectionPath() {
        let url = window.location.href.split('/')
        if(!url[url.length-1].length)
            url.pop()
        if(url[url.length-1].indexOf('?')>=0)
            return url[url.length-1].substring(path.indexOf('?'),path.length)
        else
            return url[url.length-1]
    }

    static getParamKeys(){
        let path = window.location.hash.split('/');
        path = path[path.length - 1];
        let params = path.split('?');
        let parse = GeneralUtils.convertFromHex(params[1]);
        let keys = new URLSearchParams(parse);
        return keys
    }

    static getPathWithoutSection(section){
        let actSection = this.getSectionPath()
        let url = window.location.href
        if(actSection===section) {
            url = url.substring(url.indexOf(actSection) + actSection.length, url.length)
            if(url.indexOf('?')>=0)
                return url.substring(0,url.indexOf('?'))
            else
                return url
        }
        else return ''

    }

    static resetPathUntilSec(){
        let copy = `${GeneralUtils.getBaseUrl()}/#/${GeneralUtils.getSectionPath()}/`
        window.history.pushState('a','a', copy)
    }

    static resetPath(){
        let copy = `${GeneralUtils.getBaseUrl()}/`
        window.history.pushState('a','a', copy)
    }

    static selectPrivacyPolicy(lang) {
        let policy = 'https://www.moveuskadi.euskadi.eus/politica-de-privacidad-moveuskadi/webmove00-content/es/';
        switch (lang) {
            case 'eu':
                policy = 'https://www.moveuskadi.euskadi.eus/pribatutasun-politika-moveuskadi/webmove00-content/eu/';
                break;
            case 'en':
                policy = 'https://www.moveuskadi.euskadi.eus/privacy-policy-moveuskadi/webmove00-content/en/';
                break;
            case 'fr':
                policy = 'https://www.moveuskadi.euskadi.eus/politique-de-confidentialite-moveuskadi/webmove00-content/fr/';
                break;
            default:
                break;
        }

        return policy;
    }
}

