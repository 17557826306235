import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  alerts: [],
};

export const alertsSlice = createSlice({
  name: 'alertsSlice',
  initialState,
  reducers: {
    updateAlertsTransit: (state, action) => {
      state.alerts = action.payload;
    },
  }
});

export const alertsState = (state: RootState) => state.alertsSlice.alerts;