import Api from '../@base/Api';

export default class UserApi extends Api {
    constructor(url) {
        super(url);
    }

    getProfile() {
        return super.get('/profile');
    }

    updateProfile(profile) {
        return super.post('/details', profile);
    }
}
