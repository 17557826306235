import LineIcon from '../explorescreen/mapOptions/searcherOption/stopDetail/LineIcon';
import { VM } from '../../../viewmodel/VM';
import { useLanguage, useTranslate } from '../../context/languageContext';

type Props = {
  time: any;
  cause: string;
  effect: string;
  alert: any;
  element: any;
  setAlert: any;
};

export default function TransitAlert(props: Props) {
  const t = useTranslate();
  const language = useLanguage();
  const getTime = () => {
    if (props.time !== undefined) {
      let actualDate = new Date();
      let ini;
      let fin;
      let iniTime;
      let iniDate;
      let finTime;
      let finDate;
      let index = 0;
      for (let i = 0; i < props.time.length; i++) {
        if (
          props.time[i].horaInicio < actualDate.getTime() &&
          props.time[i].horaFin > actualDate.getTime()
        ) {
          index = i;
        }
      }
      if (props?.time[index]?.horaInicio) {
        ini = new Date(props?.time[index]?.horaInicio);
        iniDate =
          String(ini.getDate()).padStart(2, '0') + '/' + String(ini.getMonth() + 1).padStart(2, '0');
        iniTime =
          String(ini.getHours()).padStart(2, '0') + ':' + String(ini.getMinutes()).padStart(2, '0');
      }

      if (props?.time[index]?.horaFin) {
        fin = new Date(props?.time[index]?.horaFin);
        finDate =
          String(fin.getDate()).padStart(2, '0') + '/' + String(fin.getMonth() + 1).padStart(2, '0');
        finTime =
          String(fin.getHours()).padStart(2, '0') + ':' + String(fin.getMinutes()).padStart(2, '0');
      } else {
        if (ini?.toDateString() === actualDate.toDateString()) {
          if (language == 'eu') return `${iniTime} ${t('from')}`;
          else return `${t('from')} ${iniTime}`;
        } else {
          if (language == 'eu') return `${iniDate} ${t('from')} ${t('at')} ${iniTime}`;
          else return `${t('from')} ${iniDate} ${t('at')} ${iniTime}`;
        }
      }

      if (!ini) {
        if (fin?.toDateString() === actualDate.toDateString()) {
          if (language == 'eu') return `${finTime} ${t('untilThe')}`;
          else return `${t('untilThe')} ${finTime}`;
        } else {
          if (language == 'eu') return `${finDate} ${t('from')} ${finTime} ${t('until')}`;
          else return `${t('from')} ${finDate} ${t('until')} ${finTime}`;
        }
      }

      if (fin.toDateString() === ini.toDateString()) {
        if (language == 'eu') return `${iniTime} ${t('from')} ${finTime} ${t('until')}`;
        else return `${t('from')} ${iniTime} ${t('until')} ${finTime}`;
      } else {
        if (language == 'eu')
          return `${t('from')} ${iniDate} ${t('at')} ${iniTime} ${t('until')} ${finDate} ${t(
            'at'
          )} ${finTime}`;
        else
          return `${iniDate} ${t('from')} ${t('at')} ${iniTime} ${finDate} ${t('until')} ${t(
            'at'
          )} ${finTime}`;
      }
    } else return '';
  };
  
  let titleAlertName = props.element?.idNombre ? props.element?.idNombre : props.element.nombreLinea;
  titleAlertName = titleAlertName ? titleAlertName : '';
  return (
    <div
      className={'transit transit-alert'}
      onClick={() =>
        props.setAlert({
          ...props.alert,
          element: props.element?.idNombre ? props.element.idNombre : props.element.nombreLinea
        })
      }>
      <div className={'row'} style={{ alignItems: 'baseline' }}>
        <div className={'cornerText'}>{getTime()}</div>
        <div
          className={'primaryText'}
          style={{ marginLeft: 'auto', fontSize: 12, textAlign: 'right' }}>
          {/*props.effect*/}
        </div>
      </div>
      <div className={'row'} style={{ alignItems: 'baseline' }}>
        {props.element.idLinea && VM.lines.data[props.element.idLinea]?.code ? (
          <LineIcon
            style={{ marginRight: 5 }}
            textColor={'#' + VM.lines.data[props.element.idLinea]?.routeTextColor}
            color={
              VM.lines.data[props.element.idLinea]?.routeColor
                ? '#' + VM.lines.data[props.element.idLinea]?.routeColor
                : null
            }
            text={VM.lines.data[props.element.idLinea]?.code ?? ''}
          />
        ) : null}
        <div
          className={'paramText'}
          style={{ wordBreak: titleAlertName?.includes(' ') ? 'break-word' : 'break-all', paddingInline: 10, fontWeight: 600 }}>
          {titleAlertName}
        </div>
        <div className={'paramText'} style={{ marginLeft: 'auto', fontWeight: 600 }}>
          {props.cause}
        </div>
      </div>
    </div>
  );
}
