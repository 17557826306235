import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  favoriteServiceApi,
  useAddFavoriteMutation,
  useEditFavoriteMutation
} from '../../../../redux/login/favorite/FavoriteServiceApi';
import { useSearchNominatimMutation, useSearchNoraMutation } from '../../../../redux/login/search/SearchServiceApi';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { favoritesInformation, favoritesSlice } from '../../../../redux/slices/favoritesSlice';
import { searchSlice } from '../../../../redux/slices/searchSlice';
import { RootState } from '../../../../redux/store';
import { VM } from '../../../../viewmodel/VM';
import Button from '../../../components/commons/Button';
import Input from '../../../components/commons/Input';
import { useLanguage, useTranslate } from '../../../context/languageContext';
import { useResponsive } from '../../../context/responsiveContext';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import DirectionsList from './searcher/DirectionsList';
import OthersList from './searcher/OthersList';
import { useDebounceTerm } from './searcherOption/searcherModal/useDebounce';
import EmptySearch from './searcherOption/searcherModal/EmptySearch';
import { debounce } from 'lodash';

export default function AddDirection(props: any) {
  const responsive = useResponsive();
  const input = useRef(null as any);
  const inputAlias = useRef(null as any);
  const content = useRef(null as any);
  const t = useTranslate();
  const language = useLanguage();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [searching4, setSearching4] = useState(false);

  const toggleVisibility = useSelector(
    (state: RootState) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );

  const AliasIcon =
    toggleVisibility.AddDirectionModalWidget.type === 2
      ? R.drawables.general.Ic_home
      : toggleVisibility.AddDirectionModalWidget.type === 3
      ? R.drawables.general.Ic_WorkSmall
      : R.drawables.general.Ic_location;

  const Direction = R.drawables.general.Ic_location;
  const Close = R.drawables.general.Ic_close;

  const [isFocused, setIsFocused] = useState(false);
  const [inputChange, setInputChange] = useState('');
  const [aliasInput, setAliasInput] = useState(
    toggleVisibility.AddDirectionModalWidget.direction?.alias
  );
  const [direction, setDirection] = useState(
    toggleVisibility.AddDirectionModalWidget.direction ?? {}
  );
  const favorites = useSelector(favoritesInformation);
  const [addFavorite] = useAddFavoriteMutation();
  const [editFavorite] = useEditFavoriteMutation();
  const [searchNora] = useSearchNoraMutation();
  const [searchOthers] = useSearchNominatimMutation();

  const [useGetFavoritesQuery, result1, lastPromiseInfo1] =
    favoriteServiceApi.endpoints.getFavorites.useLazyQuery();

  useEffect(() => {
    inputAlias?.current?.setValue(aliasInput);
    if (toggleVisibility.AddDirectionModalWidget.type == 1 && toggleVisibility.AddDirectionModalWidget?.direction?.name) {
      setAliasInput(toggleVisibility.AddDirectionModalWidget?.direction?.name);
    }
    if (toggleVisibility.AddDirectionModalWidget.type == 3) {
      inputAlias.current?.setDisabled(true);
      inputAlias.current?.setValue(t('work'));
      setAliasInput(t('work'));
    }
    if (toggleVisibility.AddDirectionModalWidget.type == 2) {
      inputAlias.current?.setDisabled(true);
      inputAlias.current?.setValue(t('home'));
      setAliasInput(t('home'));
    }
  }, [props.direction, isFocused]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickComponent);
  }, []);

  useEffect(() => () => document.removeEventListener('mousedown', handleClickComponent), []);

  const handleClickComponent = (event: { target: any }) => {
    if (content && !content.current?.contains(event.target)) {
      setIsFocused(false);
    }
  };

  const debouncedSearch = debounce(async (text) => {
    setSearching4(true);
    searchOthers({ locale: language, txt: text }).then((data: any) => {
      setSearching4(false);
      dispatch(searchSlice.actions.updateOthers(data.data));
    });
  }, 1500);

  async function search(text: string) {
    if (text && text?.length > 2) {
        setSearching4(true);
        //searchOthers({ locale: language, txt: text }).then((data: any) =>
        //dispatch(searchSlice.actions.updateOthers(data.data))
      //);
      debouncedSearch(text);
    } else {
      setSearching4(false);
      dispatch(searchSlice.actions.updateOthers([]));
    }
  }

  const addDirection = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    let directionAdd = JSON.parse(JSON.stringify(direction));
    directionAdd.alias = aliasInput;
    updateFavorites.directions.push(directionAdd);
    addFavorite({
      stops: [],
      pois: [],
      plans: [],
      directions: [directionAdd]
    }).then(() =>
      useGetFavoritesQuery().then((data) => {
        if (!data.error) dispatch(favoritesSlice.actions.updateFavorites(data.data));
        for (let dir in data.data.directions) {
          if (data.data.directions[dir].direction == directionAdd.direction) {
            props.setMarkerSelected(data.data.directions[dir]);
          }
        }
      })
    );

    dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAddDirectionModalWidget({
        showModal: false,
        direction: {},
        type: toggleVisibility.AddDirectionModalWidget.type,
        edit: false
      })
    );
  };

  const editDirection = () => {
    let directionEdit = JSON.parse(JSON.stringify(direction));
    directionEdit.alias = aliasInput;
    editFavorite({
      stops: [],
      pois: [],
      plans: [],
      directions: [directionEdit]
    }).then(() =>
      useGetFavoritesQuery().then((data) => {
        if (!data.error) dispatch(favoritesSlice.actions.updateFavorites(data.data));
        dispatch(favoritesSlice.actions.updateFavorites(data.data));
        for (let dir in data.data.directions) {
          if (data.data.directions[dir].id == directionEdit.id) {
            props.setMarkerSelected(data.data.directions[dir]);
          }
        }
      })
    );

    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAddDirectionModalWidget({
        showModal: false,
        direction: {},
        type: toggleVisibility.AddDirectionModalWidget.type,
        edit: false
      })
    );
  };

  const resultClick = (direction: any) => {
    if (direction.lat || direction.lon) {
      direction.latitude = direction.lat;
      direction.longitude = direction.lon;
    }
    direction.typeId = toggleVisibility.AddDirectionModalWidget.type;
    if (toggleVisibility.AddDirectionModalWidget.direction?.id)
      direction.id = toggleVisibility.AddDirectionModalWidget.direction?.id;
    if (toggleVisibility.AddDirectionModalWidget.type == 1) {
      setAliasInput(direction?.name);
    }
    VM.recents.add(direction);
    setDirection(direction);
    setIsFocused(false);
    input.current.setValue(direction.name);

    props.onClickMarker(direction);
  };

  function checkAliasValid() {
    if (toggleVisibility.AddDirectionModalWidget.type == 1) {
      return aliasInput !== t('home') && aliasInput !== t('work');
    }

    return true;
  }

  const debouncedSearchTerm = useDebounceTerm(inputChange, 500);

  useEffect(() => {
    search(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <div ref={content} className={`mapOption ${responsive.isBig ? 'big' : 'small'}`}>
      <div className={'addDirection row'}>
        <div className={'subtitle'}>{t('setDirection')}</div>
        <Close
          className={'close'}
          onClick={() => {
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAddDirectionModalWidget({
                showModal: false,
                direction: {},
                type: toggleVisibility.AddDirectionModalWidget.type,
                edit: false
              })
            );
          }}
        />
      </div>
      {!Object.keys(toggleVisibility.AddDirectionModalWidget.direction)?.length ||
      toggleVisibility.AddDirectionModalWidget.edit ? (
        <Input
          style={{ paddingInline: 20, width: '90%' }}
          ref={input}
          onFocus={(e: any) => {
            setInputChange(e);
            setIsFocused(true);
          }}
          onChange={(e: any) => {
            setInputChange(e);
          }}
          onClear={() => {
            setInputChange('');
            setIsFocused(false);
            dispatch(searchSlice.actions.updateOthers([]));
          }}
          placeholder={t('searchADirection')}
        />
      ) : null}

      {inputChange?.length !== 0 && isFocused ? (
        <div className={'paddingInLine20 paddingVertical10'} style={{maxHeight: window.innerHeight - 130, overflow: 'auto'}}>
          <OthersList
              searching={searching4}
              resultClick={resultClick}
              inputChange={inputChange}
              style={{ marginTop: 15, marginRight: 10 }}
          />
          {!searching4 && debouncedSearchTerm ? (<EmptySearch/>) : null}
        </div>
      ) : (
        <div>
          <div className={'paddingInLine20 margin10'}>
            <div className={'row top'}>
              <div className={'agency-icon-box poiIcon'}>
                <Direction style={{ fill: theme.colors.mediumGray }} />
              </div>
              <div>
                <div className={'primaryText'}>
                  {direction.alias ? direction.alias : direction.name ? direction.name : t('none')}
                </div>
                <div className={'description'}>{direction.direction}</div>
              </div>
            </div>
          </div>

          <div className={'paddingInLine20 margin10'}>
            <div className={'row top'}>
              <div className={'agency-icon-box poiIcon b-mediumDark'}>
                <AliasIcon style={{ fill: theme.colors.white }} />
              </div>
              <div className={'column'}>
                <div className={'primaryText'}>{t('name')}</div>
                <Input
                  type={1}
                  ref={inputAlias}
                  height={'auto'}
                  onChange={(e: any) => {
                    setAliasInput(e.replace(/'/g, "''"));
                  }}
                  placeholder={t('setAlias')}
                />
              </div>
            </div>
          </div>
          <div className={'addDirection row'} style={{ justifyContent: 'right' }}>
            <Button
              className={'marginRight5'}
              text={t('cancel')}
              onClick={() => {
                dispatch(
                  eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAddDirectionModalWidget(
                    {
                      showModal: false,
                      direction: {},
                      edit: false
                    }
                  )
                );
              }}
            />
            <Button
              textColor={theme.colors.white}
              background={theme.colors.mediumDark}
              text={t('save')}
              disabled={!Object.keys(direction)?.length || !aliasInput?.length || !checkAliasValid()}
              onClick={() => {
                toggleVisibility.AddDirectionModalWidget.edit ? editDirection() : addDirection();
                dispatch(
                  eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
                    {
                      showModal: true,
                      background: '#00763D',
                      text: t('directionSaved')
                    }
                  )
                );
              }}
            />
          </div>
        </div>
      )}
      <div />
    </div>
  );
}
