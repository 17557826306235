import {useDispatch, useSelector} from 'react-redux';
import { favoritesInformation } from '../../../redux/slices/favoritesSlice';
import { VM } from '../../../viewmodel/VM';
import {useLanguage, useTranslate} from '../../context/languageContext';
import FavoriteDirection from './FavoriteDirection';
import FavoritePlan from './FavoritePlan';
import FavoritePoi from './FavoritePoi';
import FavoriteStop from './FavoriteStop';
import {useEffect, useState} from "react";
import {markersInformation, markersSlice} from "../../../redux/slices/markersSlice";
import {useGetStationsMutation} from "../../../redux/login/explore/GbfsServiceApi";
import FavoriteStation from "./FavoriteStation";
import MyTransportScreen from '../accountscreen/AccountFeaturesSections/MyTransportScreen';

export default function FavoriteBody(props: any) {
  const favorites = useSelector(favoritesInformation);
  const [stops, setStops] = useState(VM.stops.map)
  const [pois, setPois] = useState(VM.pois.map)
  const markers = useSelector(markersInformation);
  const [useGetStations] = useGetStationsMutation();
  const dispatch = useDispatch()
  const language = useLanguage()
  const t = useTranslate();

  const renderSelected = () => {
    switch (props.selected) {
      case 0:
        return renderStations();
      case 1:
        return renderItineraries();
      case 2:
        return renderPlaces();
      case 3: 
        return (<MyTransportScreen/>);
      default:
        return renderStations();
    }
  };

  useEffect(()=>{
    const fetchData = async () => {
      if (!VM.stops.data?.length) {
        await VM.stops.get('es', null, null).then(() => {
          setStops(VM.stops.map)
          setPois(VM.pois.map)
        })
      }

      if (!VM.pois.data?.length) {
        await VM.pois.get('es', null, null).then(() => {
          setPois(VM.pois.map)
          setStops(VM.stops.map)

        })
      }

      useGetStations({locale:language}).then((data:any) => {
        dispatch(markersSlice.actions.updateStations(data?.data))
      })
    }
    fetchData()
  },[])

  const renderStations = () => {
    let res = [];
    let actFavTest=null as any
    for (let stopId in favorites.stops) {
      actFavTest=null
      actFavTest=markers?.stations?.filtered?.find(({id})=>{return id===favorites.stops[stopId]})
      let stop = stops[favorites.stops[stopId]];
      if (stop !== undefined) {
        res.push(<FavoriteStop key={stop.id} stop={stop} />);
      }
      else if(actFavTest){
        res.push(<FavoriteStation key={actFavTest?.id} stop={actFavTest}/>)
      }
    }
    if (!res.length) return <div className={'description'}>{t('noStationsSaved')}</div>;
    return res;
  };

  const renderItineraries = () => {
    let res = [];
    for (let planId in favorites.plans) {
      res.push(<FavoritePlan key={planId} plan={favorites.plans[planId]} />);
    }
    if (!res.length) return <div className={'description'}>{t('favorites_empty_plansMessage')}</div>;
    return res;
  };

  const renderPlaces = () => {
    let res = [];
    let directions = [];
    let home;
    let work;
    for (let poiId in favorites.pois) {
      // if (favorites.pois.indexOf(poiId as never) >= 0) {
      let poi = pois ? pois[favorites.pois[poiId]] : undefined;
      if (poi !== undefined) {
        res.push(<FavoritePoi key={poi?.id} poi={poi} />);
        res
          .sort(function (a: any, b: any) {
            if (a.props.poi.name.toLowerCase() < b.props.poi.name.toLowerCase()) return -1;
            if (a.props.poi.name.toLowerCase() > b.props.poi.name.toLowerCase()) return 1;
            return 0;
          })
          .map((item, i) => <FavoritePoi key={i} poi={poi} data={item} />);
      }
    }
    res.unshift(
      <div key={'titlePoi'} className={'primaryText marginBottom5'}>
        {t('visualizer_poi')}
      </div>
    );

    if (res.length == 1)
      res.push(
        <div key={'noDir'} className={'description'}>
          {t('favorites_empty_poisMessage')}
        </div>
      );

    for (let direction in favorites.directions) {
      if (favorites.directions[direction]['typeId'] == 3) work = favorites.directions[direction];
      else if (favorites.directions[direction]['typeId'] == 2)
        home = favorites.directions[direction];
      else
        directions.push(
          <FavoriteDirection
            key={direction}
            direction={favorites.directions[direction]}
            typeId={1}
          />
        );
    }
    directions.unshift(<FavoriteDirection key={'work'} direction={work} typeId={3} />);
    directions.unshift(<FavoriteDirection key={'home'} direction={home} typeId={2} />);
    directions.unshift(
      <div key={'titleDir'} className={'primaryText marginBottom5'}>
        {t('places')}
      </div>
    );
    directions.push(<FavoriteDirection key={'direction'} typeId={1} />);
    return (
      <div>
        {directions}
        {res}
      </div>
    );
  };

  return (
    <div className={'transit transit-body'} style={{ maxHeight: window.innerHeight - 165 }}>
      {renderSelected()}
    </div>
  );
}
