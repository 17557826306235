import GeneralUtils from "../../utils/GeneralUtils";

export default class Api {
    token = null;

    constructor(url) {
        this.url = GeneralUtils.getEnvironment(window.location.origin);
    }

    get(endpoint, options = {}) {
        let id = endpoint.split('/')
        id = id[id.length-1]
        let iconData = JSON.parse(localStorage.getItem('iconsData'));
        if(!iconData)
            iconData=[]
        let filter = iconData.find((icon) => parseInt(icon.id) === parseInt(id));
        if(filter && endpoint.split('/').indexOf('icons')>=0)
            return {id:filter?.id, contentFile:filter?.contentFile}
        return this.call('GET', endpoint, options)
    }

    post(endpoint, body, options = {}) {
        options.body = JSON.stringify(body);
        return this.call('POST', endpoint, options);
    }

    put(endpoint, body, options = {}) {
        options.body = JSON.stringify(body);
        return this.call('PUT', endpoint, options);
    }

    delete(endpoint, body, options = {}) {
        options.body = JSON.stringify(body);
        return this.call('DELETE', endpoint, options);
    }

    call(method, endpoint, options = {}) {
        // Especifica el método de la llamada HTTP al recurso.
        options.method = method;
        // Completa la información de las cabeceras con cabeceras comunes por llamada a API.
        options.headers = {};
        options.headers['Content-Type'] = 'application/json';
        options.headers['authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token;
        /*options.headers["-?"] = "-?"*/

        // Realiza la llamada a la API con las cabeceras y la URL base de la llamada.
        let call = fetch(this.url + endpoint, options)
        // Transforma los datos a JSON y devuelve el resultado (o el error en su caso).
        return call.then(async res => {
            try {
                if (res.status === 200)
                    return await res.json()
                else {
                    return {error: await res.json()}
                }
                ;
            }
            catch (e) {
                return {error:res.toString()}
            }
        }).catch(async res => {error:res.toString()});
    }
}
