import Api from '../@base/Api';
import {VM} from "../../viewmodel/VM";

export default class IconsApi extends Api {
    constructor(url) {
        super(url);
    }

    async getCategoryIcon(id, marker) {
        if(!Object.keys(VM.categories.data).length) {
            await VM.categories.get('es')
        }
        let data = VM.categories.data
        for (let category in data) {
            if (data[category].id == id) {
                if(marker){
                    if(VM.icons.data[data[category][marker]]){
                        return VM.icons.data[data[category][marker]]
                    }
                    else {
                        let icon = await super.get(`/icons/${data[category][marker]}`)
                        let iconData = JSON.parse(localStorage.getItem('iconsData'));
                        if(!iconData)
                            iconData=[]
                            let filter = iconData.find((icon) => parseInt(icon.id) === parseInt(data[category][marker]));
                            if(!filter){
                                iconData.push({id:icon?.id, contentFile:icon?.contentFile})
                                localStorage.setItem('iconsData', JSON.stringify(iconData))
                            }
                        VM.icons.data[data[category][marker]]=icon?.contentFile
                        return icon?.contentFile;
                    }
                }
                else {
                    if (VM.icons.data[data[category].iconId]) {
                        return VM.icons.data[data[category].iconId]
                    } else {
                        let icon = await super.get(`/icons/${data[category].iconId}`)
                        let iconData = JSON.parse(localStorage.getItem('iconsData'));
                        if(!iconData)
                            iconData=[]
                            let filter = iconData.find((icon) => parseInt(icon.id) === parseInt(data[category].iconId));
                            if(!filter){
                                iconData.push({id:icon?.id, contentFile:icon?.contentFile})
                                localStorage.setItem('iconsData', JSON.stringify(iconData))
                            }
                        VM.icons.data[data[category].iconId] = icon?.contentFile
                        return icon?.contentFile;
                    }
                }
            }
        }
    }

    async getAgencyIcon(id) {
        if(!Object.keys(VM.agencies.data).length) {
            await VM.agencies.get('es')
        }
        let data = VM.agencies.data
        for (let agency in data) {
            if (data[agency].id == id) {
                if(VM.icons.data[data[agency].icon]){
                    return VM.icons.data[data[agency].icon]
                }
                else if(data[agency].icon) {
                    let icon = await super.get(`/icons/${data[agency].icon}`)
                    let iconData = JSON.parse(localStorage.getItem('iconsData'));
                    if(!iconData)
                        iconData=[]
                    let filter = iconData.find((icon) => parseInt(icon.id) === parseInt(data[agency].icon));
                    if(!filter){
                        iconData.push({id:icon?.id, contentFile:icon?.contentFile})
                        localStorage.setItem('iconsData', JSON.stringify(iconData))
                    }
                    VM.icons.data[data[agency].icon]=icon?.contentFile
                    return icon?.contentFile;
                }
            }
        }
    }

    async getDataOriginIcon(id, marker, transportMode) {
        if(VM.dataOrigin?.data?.length && !Object.keys(VM.dataOrigin?.data)?.length) {
            await VM.dataOrigin.get('es')
        }
        let data = VM.dataOrigin.data

        for (let origin in data) {
            if ((data[origin].id == id  && data[origin]?.transportModes?.length) ||
                (data[origin]?.group && data[origin]?.transportModes?.length && data[origin]?.group.indexOf(parseInt(id))>=0)) {
                let position = 0;
                if(transportMode)
                {
                    for (let element in data[origin]?.transportModes) {
                        if (data[origin]?.transportModes[element]?.transportModeId === transportMode)
                            position = element
                    }
                }
                else {
                    for (let element in data[origin]?.transportModes) {
                        if (data[origin]?.transportModes[element]?.originTransportModeId === id)
                            position = element
                    }
                }
                if(marker) {
                    if (VM.icons.data[data[origin].transportModes[position][marker]]) {
                        return VM.icons.data[data[origin].transportModes[position][marker]]
                    } else {
                        let icon = await super.get(`/icons/${data[origin].transportModes[position][marker]}`)
                        let iconData = JSON.parse(localStorage.getItem('iconsData'));
                        if(!iconData)
                            iconData=[]
                            let filter = iconData.find((icon) => parseInt(icon.id) === parseInt(data[origin].transportModes[position][marker]));
                            if(!filter){
                                iconData.push({id:icon?.id, contentFile:icon?.contentFile})
                                localStorage.setItem('iconsData', JSON.stringify(iconData))
                            }
                        VM.icons.data[data[origin].transportModes[position][marker]] = icon?.contentFile
                        return icon?.contentFile;
                    }
                }
                else{
                    if (VM.icons.data[data[origin].transportModes[position].iconId]) {
                        return VM.icons.data[data[origin].transportModes[position].iconId]
                    } else {
                        let icon = await super.get(`/icons/${data[origin].transportModes[position].iconId}`)
                        let iconData = JSON.parse(localStorage.getItem('iconsData'));
                        if(!iconData)
                            iconData=[]
                            let filter = iconData.find((icon) => parseInt(icon.id) === parseInt(data[origin].transportModes[position].iconId));
                            if(!filter){
                                iconData.push({id:icon?.id, contentFile:icon?.contentFile})
                                localStorage.setItem('iconsData', JSON.stringify(iconData))
                            }
                            VM.icons.data[data[origin].transportModes[position].iconId] = icon?.contentFile
                        return icon?.contentFile;
                    }
                }
            }
        }
    }

    async get(id) {
        if (VM.icons.data[id]) {
            return VM.icons.data[id]
        }
        else {
            let icon = await super.get(`/icons/${id}`);
            let iconData = JSON.parse(localStorage.getItem('iconsData'));
            if(!iconData)
                iconData=[]
            let filter = iconData.find((icon) => parseInt(icon.id) === parseInt(id));
            if(!filter){
                iconData.push({id:icon?.id, contentFile:icon?.contentFile})
                localStorage.setItem('iconsData', JSON.stringify(iconData))
            }
            VM.icons.data[id]=icon?.contentFile
            return icon?.contentFile;
        }
        return null
    }
}
