import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateUserMutation } from '../../../../redux/login/services/authServiceApi';
import { accountInformation, authSlice } from '../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import {useLanguage, useTranslate} from '../../../context/languageContext';
import { useSetLoading } from '../../../context/loadingContext';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import ButtonNext from '../../commons/buttons/ButtonNext';
import SimpleModal from '../../commons/Modals/SimpleModal';
import TitleH1 from '../../commons/Titles/TitleH1';
import InputEmail from '../../inputs/InputEmail';
import InputPassword from '../../inputs/InputPassword';
import GeneralUtils from '../../../../utils/GeneralUtils';

export default function SingUpModalWidget(props: any) {
  const account = useSelector(accountInformation);
  const t = useTranslate();
  useEffect(() => {
    if (!account.alias || !account.rangoEdad || !account.idGender) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
          true
        )
      );
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilitySingUpModalWidget(false));
    } else {
      dispatch(authSlice.actions.updateEmail(null));
      dispatch(authSlice.actions.updatePassword(null));
      dispatch(authSlice.actions.updatePassword2(null));
    }
  }, []);

  const theme = useTheme();
  const Close = R.drawables.commons.close;
  const styles = {
    header: {
      width: '100%',
      textAlign: 'left',
      marginBottom: '7%'
    },
    close: {
      marginLeft: 'auto',
      marginRight: '20px',
      marginTop: '20px',
      position: 'absolute',
      right: 0,
      top: 0,
      height: 10.65,
      cursor: 'pointer',
      width: 10.71
    },
    title: {
      fontSize: '22px',
      color: '#ffffff',
      marginBottom: '1.3%',
      marginTop: '10.2%'
    },
    p: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: ' 140%',
      color: ' #FFFFFF',
      textAlign: 'left',
      margin: 0
    },
    form: {
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      textAlign: 'left',
      // marginTop: '10px',
      // marginBottom: '20px',
      color: '#FFFFFF',
      paddingLeft: '25px'
    },
    overlay: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10,
      backgroundColor: '#181B1D',
      opacity: 0.2
    }
  } as const;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [acceptConditions, setAcceptConditions] = useState(false);

  const [createUser, { error }] = useCreateUserMutation();
  const setLoading = useSetLoading();

  const dispatch = useDispatch();

  const [minLength, setminLength] = useState({
    key: '.*.{6,30}$',
    minLength: false
  });
  const [almostOneCapitalLetter, setalmostOneCapitalLetter] = useState({
    key: '[A-Z]',
    almostOneCapitalLetter: false
  });
  const [almostOnelowerLetter, setalmostOnelowerLetter] = useState({
    key: '[a-z]',
    almostOnelowerLetter: false
  });
  const [almostOneNumber, setalmostOneNumber] = useState({
    key: '[0-9]',
    almostOneNumber: false
  });

  function handleSetPassword(value: string) {
    setPassword(value);
    dispatch(authSlice.actions.updatePassword(value));
    if (new RegExp(minLength.key).test(value)) {
      setminLength({ ...minLength, minLength: true });
    } else {
      setminLength({ ...minLength, minLength: false });
    }
    if (new RegExp(almostOneCapitalLetter.key).test(value)) {
      setalmostOneCapitalLetter({ ...almostOneCapitalLetter, almostOneCapitalLetter: true });
    } else {
      setalmostOneCapitalLetter({ ...almostOneCapitalLetter, almostOneCapitalLetter: false });
    }
    if (new RegExp(almostOnelowerLetter.key).test(value)) {
      setalmostOnelowerLetter({ ...almostOnelowerLetter, almostOnelowerLetter: true });
    } else {
      setalmostOneCapitalLetter({ ...almostOneCapitalLetter, almostOneCapitalLetter: false });
    }
    if (new RegExp(almostOneNumber.key).test(value)) {
      setalmostOneNumber({ ...almostOneNumber, almostOneNumber: true });
    } else {
      setalmostOneNumber({ ...almostOneNumber, almostOneNumber: false });
    }
  }

  function handleSetPassword2(value: string) {
    dispatch(authSlice.actions.updatePassword2(value));
    setRepeatPassword(value);
  }

  async function sendData() {
    setLoading(true);
    const result: any = await createUser(account);
    if (result && !result.error) {
      const accountUser = { ...account, ...result.data };
      accountUser.password = null;
      accountUser.password2 = null;
      accountUser.token = null;
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#00763D',
          text: t('accountCreated')
        })
      );
    } else if (
      result.error &&
      result.error.data ==
        'No se puede reenviar el token porque este usuario ya esta activo o ha sido baneado en el sistema'
    ) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#00763D',
          text: t('userExists')
        })
      );
    } else if (
      result.error &&
      result.error.data == 'El usuario ya existe en la base de datos pero su cuenta no'
    ) {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#00763D',
          text: t('activateYourAccount')
        })
      );
    } else {
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#A31610',
          text: t('serviceError')
        })
      );
    }
    dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilitySingUpModalWidget(false));
    setLoading(false);
  }

  return (
    <>
      <div
        style={styles.overlay}
        onClick={() => {
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilitySingUpModalWidget(null)
          );
          dispatch(authSlice.actions.updateRangoEdad(null));
          dispatch(authSlice.actions.updateIdGender(null));
          dispatch(authSlice.actions.updateEmail(null));
          dispatch(authSlice.actions.updateAlias(null));
          dispatch(authSlice.actions.updatePassword(null));
          dispatch(authSlice.actions.updatePassword2(null));
        }}
      />
      <SimpleModal
        style={{
          width: '65%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 15
        }}
        header={
          <div style={styles.header}>
            <Close
              style={styles.close}
              fill={theme.colors.white}
              onClick={() => {
                dispatch(
                  eventsModalstoggleVisibilitySlice.actions.toggleVisibilitySingUpModalWidget(null)
                );
                dispatch(authSlice.actions.updateRangoEdad(null));
                dispatch(authSlice.actions.updateIdGender(null));
                dispatch(authSlice.actions.updateEmail(null));
                dispatch(authSlice.actions.updateAlias(null));
                dispatch(authSlice.actions.updatePassword(null));
                dispatch(authSlice.actions.updatePassword2(null));
              }}
            />
            <TitleH1 style={styles.title} title={t('dataAccount')} />
            <p style={styles.p}>{t('fillFields')}</p>
          </div>
        }
        body={
          <form
            style={styles.form}
            onSubmit={async (e) => {
              e.preventDefault();

              sendData();
            }}>
            <InputEmail
              className="modal-input"
              email={email}
              setEmail={(e: any) => {
                setEmail(e);
                dispatch(authSlice.actions.updateEmail(e));
              }}
            />
            <InputPassword password={account.password} setPassword={handleSetPassword} />
            <label className={`${password.length > 0 ? '' : 'displayNone'}`}>
              <ul className={'check'}>
                <li>
                  <i className={minLength.minLength ? 'valid' : 'invalid'}></i>
                  <p>{t('charMaxAndMin')}</p>
                </li>
                <li>
                  <i
                    className={
                      almostOnelowerLetter.almostOnelowerLetter &&
                      almostOneCapitalLetter.almostOneCapitalLetter
                        ? 'valid'
                        : 'invalid'
                    }></i>
                  <p>{t('minMayusMin')}</p>
                </li>
                <li>
                  <i className={almostOneNumber.almostOneNumber ? 'valid' : 'invalid'}></i>
                  <p>{t('minNum')}</p>
                </li>
              </ul>
            </label>

            <InputPassword password={account.password2} setPassword={handleSetPassword2} />
            <label
              style={{
                textAlign: 'left',
                fontSize: 12,
                color: '#FFFFFF',
                paddingLeft: '25px',
                paddingBottom: 8,
                paddingTop: 8
              }}
              className={`warning ${
                account.password != account.password2 ? '' : 'visibilityHidden'
              }`}>
              {t('noMatch')}
            </label>
            <div className="checkbox-container">
              <input
                // className="checkbox-conditions"
                type="checkbox"
                id="acceptConditions"
                value="acceptConditions"
                onChange={(e) => {
                  setAcceptConditions(e.target.checked);
                }}
              />
              <label>
                <div>
                  {t('accept')}{' '}
                  <a
                    href={useLanguage()==='eu' ? "https://www.euskadi.eus/informazioa/-/lege-informazioa/" : 'https://www.euskadi.eus/informacion/-/informacion-legal/'}
                    target="_blank">
                    {t('useConditions')}
                  </a>{' '}
                  {t('and')}{' '}
                  <a
                    href={GeneralUtils.selectPrivacyPolicy(useLanguage())}
                    target="_blank">
                    {t('privacy')}
                  </a>
                </div>
              </label>
            </div>
            <ButtonNext
              disabled={
                email.length <= 1 ||
                !minLength.minLength ||
                !almostOneCapitalLetter.almostOneCapitalLetter ||
                !almostOnelowerLetter.almostOnelowerLetter ||
                !almostOneNumber.almostOneNumber ||
                password !== repeatPassword ||
                !acceptConditions
              }
              className="btn-secondary"
            />
          </form>
        }
      />
    </>
  );
}
