/* Modelos estáticos de filtros que no se acceden desde la api */

export function getMotorizedModel(locale) {
  let model = [
    {id: 1, name: 'Combustion', icon: 'Ic_Gasolinera', selected: false},
    {id: 2, name: 'Electric', icon: 'Ic_Electrolinera2', selected: false},
  ];

  switch (locale) {
    case 'es':
      model = [
        {id: 1, name: 'Combustión', icon: 'Ic_Gasolinera', selected: false},
        {id: 2, name: 'Electrico', icon: 'Ic_Electrolinera2', selected: false},
      ];
      break;
    case 'eu':
      model = [
        {id: 1, name: 'Errekuntza', icon: 'Ic_Gasolinera', selected: false},
        {id: 2, name: 'Elektriko', icon: 'Ic_Electrolinera2', selected: false},
      ];
      break;
    case 'fr':
      model = [
        {id: 1, name: 'Combustion', icon: 'Ic_Gasolinera', selected: false},
        {id: 2, name: 'Électrique', icon: 'Ic_Electrolinera2', selected: false},
      ];
      break;
    default:
      break;
  }

  return model;
}
