import SearcherModal from './searcherModal/SearcherModal';
import LineDetail from './lineDetail/LineDetail';
import PoiDetail from './poiDetail/PoiDetail';
import StopDetail from './stopDetail/StopDetail';
import Filters from './filters/Filters';
import DirectionDetail from './directionDetail/DirectionDetail';
import { useEffect, useState } from 'react';
import { VM } from '../../../../../viewmodel/VM';
import FilterButton from './filters/FilterButton';
import { useResponsive } from '../../../../context/responsiveContext';
import StationDetail from './stationDetail/StationDetail';
import VehicleDetail from './vehicleDetail/VehicleDetail';
import ClusterDetail from './clusterDetail/ClusterDetail';
import GeneralUtils from '../../../../../utils/GeneralUtils';
import FilterUtils from '../../../../../utils/FilterUtils';

export default function SearcherOption(props, ref) {
  const [lineSelected, setLineSelected] = useState();
  const responsive = useResponsive();

  useEffect(() => {
    VM.lines.listen(() => setLineSelected(VM.lines.selected));
  }, []);

  const styles = {
    filterButton: {
      marginInline: !responsive.isBig ? '25px' : '105px',
      position: 'absolute',
      marginTop: 10
    }
  };

  return (
    <div style={props.style}>
      {!lineSelected ? (
        <SearcherModal
          setMarkerSelected={props.setMarkerSelected}
          markerSelected={props.markerSelected}
          onClickMarker={props.onClickMarker}
          setShowSecond={props.setShowSecond}
          setOpen={props.setOpen}
        />
      ) : null}
      {props.showSecond ? (
        lineSelected ? (
          <LineDetail
            lineSelected={lineSelected}
            onClickMarker={props.onClickMarker}
            markerSelected={props.markerSelected}
            setMarkerSelected={props.setMarkerSelected}
            stop={props.markerSelected}
            onClose={() => {
              GeneralUtils.resetPath();
              VM.lines.setSelected(null);
            }}
          />
        ) : props.markerSelected ? (
          props.markerSelected?.poiOriginId ? (
            <PoiDetail
              onClose={() => {
                GeneralUtils.resetPath();
                props.setMarkerSelected(null);
              }}
              setMarkerSelected={props.setMarkerSelected}
              poi={props.markerSelected}
            />
          ) : props.markerSelected.hasOwnProperty('bikesAvailable') ||
            FilterUtils.checkDriverId(props.markerSelected?.agencyOriginId, 3) ? (
            <StationDetail
              onClose={() => {
                GeneralUtils.resetPath();
                props.setMarkerSelected(null);
              }}
              setMarkerSelected={props.setMarkerSelected}
              station={props.markerSelected}
            />
          ) : props.markerSelected.hasOwnProperty('equipment') ? (
            <VehicleDetail
              onClose={() => props.setMarkerSelected(null)}
              setMarkerSelected={props.setMarkerSelected}
              vehicle={props.markerSelected}
            />
          ) : props.markerSelected?.id && props.markerSelected?.agencyOriginId ? (
            <StopDetail
              onClose={() => {
                GeneralUtils.resetPath();
                props.setMarkerSelected(null);
              }}
              stop={props.markerSelected}
              setMarkerSelected={props.setMarkerSelected}
            />
          ) : props.markerSelected?.id && props.markerSelected?.markerType == 'container' ? (
            <ClusterDetail
              onClose={() => {
                GeneralUtils.resetPath();
                props.setMarkerSelected(null);
              }}
              setMarkerSelected={props.setMarkerSelected}
              cluster={props.markerSelected}
            />
          ) : (
            <DirectionDetail
              onClose={() => {
                GeneralUtils.resetPath();
                props.setMarkerSelected(null);
              }}
              setMarkerSelected={props.setMarkerSelected}
              direction={props.markerSelected}
            />
          )
        ) : (
          <div style={{ position: 'absolute' }}>
            <Filters />
            <div style={styles.filterButton}>
              <FilterButton />
            </div>
          </div>
        )
      ) : null}
    </div>
  );
}
