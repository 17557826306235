import React, {useEffect, useRef, useState} from "react";
import {useLanguage, useTranslate} from "../../../context/languageContext";
import SystemNotificationElement from "./SystemNotificationElement";
import {useGetSystemMessagesMutation} from "../../../../redux/login/services/MessagesServiceApi";

export default function SystemNotificationsScreen(props: any) {

    const [elementsPerPage, setElementsPerPage] = useState(4);
    const [systemMessages, setSystemMessages] = useState([] as any);
    const language = useLanguage()
    const t = useTranslate()
    const listInnerRef = useRef(null)

    const [getSystemMessages] = useGetSystemMessagesMutation();

    useEffect(()=>{
        async function fetchData() {
            if(elementsPerPage-3<=systemMessages?.data?.pagination?.total || !systemMessages.data)
            setSystemMessages(await getSystemMessages({locale:language, pageNumber:1, elementsPerPage:elementsPerPage}))
        }
        fetchData()
    },[elementsPerPage])

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (Math.round(scrollTop+1 + clientHeight) >= scrollHeight) {
                setElementsPerPage(elementsPerPage + 4);
            }
        }
    };

    const renderAlerts = () => {
        let res = [];
        for(let element in systemMessages?.data?.data)
            res.push(
                <SystemNotificationElement
                    key={systemMessages['data']['data'][element].id}
                    title={systemMessages['data']['data'][element].title}
                    sentAt={systemMessages['data']['data'][element].sentAt}
                    messageBody={systemMessages['data']['data'][element].messageBody}
                />
            )
        return res
    }

    return (
        <div style={{width:'100%'}}>
            <div className={'account-system-header'}>
                <div className={'cornerText'}>{t('manageNotifications')}</div>
                <div className={'title'}>{t('systemNotifications')}</div>
            </div>
        <div className={'account-child'} style={{maxHeight:417, minHeight:417}} onScroll={onScroll} ref={listInnerRef}>
            {renderAlerts()}
        </div>
        </div>
    )
}
