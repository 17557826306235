import { useEffect, useState } from 'react';
import { getFingerprint } from 'react-fingerprint';
import { useDispatch, useSelector } from 'react-redux';
import { favoriteServiceApi } from '../../redux/login/favorite/FavoriteServiceApi';
import {
  useCreateUserAnonimousMutation,
  useCreateUserMutation,
  useUserMeQuery
} from '../../redux/login/services/authServiceApi';
import { SubscriptionsServiceApi } from '../../redux/login/subscriptions/SubscriptionsServiceApi';
import { accountInformation, authSlice, useUserMeQuerySkip } from '../../redux/slices/authSlice';
import { favoritesSlice } from '../../redux/slices/favoritesSlice';
import { subscriptionsSlice } from '../../redux/slices/subscriptionsSlice';
import { RootState } from '../../redux/store';
import Loading from '../components/commons/Loading';
import Loading2 from '../components/commons/Loading2';
import Modal from '../components/commons/Modal';
import ShareModalWidget from '../components/widget/Modals/ShareModalWidget';
import AlertRemovedModalWidget from '../components/widget/Modals/StatusCodesModalWidgets/AlertRemovedModalWidget';
import CopiedModalWidget from '../components/widget/Modals/StatusCodesModalWidgets/CopiedModalWidget';
import FiltersProvider from '../context/filtersContext';
import { useSetLanguage, useTranslate } from '../context/languageContext';
import LoginScreen from './LoginScreen/LoginScreen';
import MenuBar from './menuBar/MenuBar';

export default function MainScreen() {
  const t = useTranslate();

  useEffect(() => {
    document.title = t('appName');
  }, []);
  const [createUser, { error }] = useCreateUserMutation();
  const account = useSelector(accountInformation);
  const skip: boolean = useSelector(useUserMeQuerySkip);

  const dispatch = useDispatch();
  const [useGetFavoritesQuery, result1, lastPromiseInfo1] =
    favoriteServiceApi.endpoints.getFavorites.useLazyQuery();
  const [useGetSubscriptionQuery, result2, lastPromiseInfo2] =
    SubscriptionsServiceApi.endpoints.getSubscriptions.useLazyQuery();

  const setLanguage = useSetLanguage();
  const [createUserAnonimous] = useCreateUserAnonimousMutation();
  const [isReady, setIsReady] = useState(false);

  useUserMeQuery('', {
    refetchOnMountOrArgChange: true,
    pollingInterval: 300000,
    skip
  });

  useEffect(() => {
    if (account.languageLocale) setLanguage(account.languageLocale);
  }, [account.languageLocale]);

  const toggleVisibility = useSelector(
    (state: RootState) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );

  const getRegisteredUser = async (userFromLocalStorage: any) => {
    dispatch(
      authSlice.actions.updateUseUserMeQuerySkip(
        !(userFromLocalStorage.alias && userFromLocalStorage.token)
      )
    );
    useGetFavoritesQuery().then((data) => {
      if (!data.error) dispatch(favoritesSlice.actions.updateFavorites(data.data));
    });
    useGetSubscriptionQuery().then((data) => {
      if (!data.error) dispatch(subscriptionsSlice.actions.updateSubscriptions(data.data));
    });
    if (account.languageLocale) {
      setLanguage(account.languageLocale);
    }
  };

  useEffect(() => {
    async function obtainSubs() {
      await useGetSubscriptionQuery().then((data) => {
        if (!data.error) dispatch(subscriptionsSlice.actions.updateSubscriptions(data.data));
      });
    }

    if (account.token && account.alias) {
      obtainSubs();
    }
  }, [account.token, account.alias]);

  const createAnonymousUser = async (userFromLocalStorage: accountInformation) => {
    if (Object.keys(userFromLocalStorage).length == 0) {
      const fingerPrint: string = await waitFingerprint();
      const tempAccount: any = {
        idUserType: 1,
        locale: 'eu',
        typeDeviceId: process.env.REACT_APP_typeDeviceId,
        tokenDevice: fingerPrint
      };
      const result: any = await createUserAnonimous(tempAccount);
      if (result && result.data) {
        const anonUser = { ...tempAccount, ...result.data };
        dispatch(authSlice.actions.setUserMe(anonUser));
      }
    }
  };

  const waitFingerprint = async () => {
    return await getFingerprint();
  };

  useEffect(() => {
    const userFromLocalStorage = JSON.parse(localStorage.getItem('accountInformation') || '{}');
    if (Object.keys(userFromLocalStorage).length)
      getRegisteredUser(userFromLocalStorage).then(() => {
        dispatch(authSlice.actions.updateUseUserMeQuerySkip(false));
        setIsReady(true);
      });
    else
      createAnonymousUser(userFromLocalStorage).then(() => {
        dispatch(authSlice.actions.updateUseUserMeQuerySkip(false));
        setIsReady(true);
      });
  }, []);

  return isReady ? (
    <>
      <div>
        {toggleVisibility.LoadingWidget ? <Loading2 /> : ''}
        <Loading />
        <FiltersProvider>
          <Modal showClose={false} />
          <MenuBar />
        </FiltersProvider>
        {toggleVisibility.CopiedModalWidget ? <CopiedModalWidget /> : ''}
        {toggleVisibility.AlertRemovedModalWidget ? <AlertRemovedModalWidget /> : ''}
        {toggleVisibility.ShareModalWidget.showModal ? <ShareModalWidget /> : ''}
      </div>
      <LoginScreen />
    </>
  ) : null;
}
