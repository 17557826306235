import React, { Component } from 'react';
import { Apis } from '../../../domain/Apis';
import ViewModel from '../../@base/ViewModel';
import FilterUtils from '../../../utils/FilterUtils';

export default class PoisFiltersViewModel extends ViewModel {
  async get(locale) {
    let merged = null;
    /* Se intenta obtener de la api */
    let categories = await Apis.categories.getCategories(locale).catch(e=>[]);
    /* Se obtiene de la cache */
    const stored = localStorage.getItem('poisFilters');
    /* Si se puede comparar ambos se compara */
    if (stored && categories && categories.length > 0) {
      merged = FilterUtils.compareFilters(JSON.parse(stored), categories);
    } else if (categories  && categories.length > 0) {
      merged = categories;
    } else if (stored) {
      merged = JSON.parse(stored);
    }
    const map = FilterUtils.getMap(merged);
    if (merged) this.set(map)
    return super.notify({categories: map})
  }

  set(values) {
    let array = Array.from(values.values());
    localStorage.setItem('poisFilters', JSON.stringify(array));
    FilterUtils.filterByCategories(Object.fromEntries(values))
    return super.notify({categories: values});
  }
}
