import ViewModel from '../@base/ViewModel';
import {R} from '../../view/R';

export default class ThemeViewModel extends ViewModel {
  constructor() {
    super();

    this.data = 'light';
    R.setColorScheme(this.data);

  }
}
