export const colors = {
    main: '#2979FF',
    mainLight: '#3490FF',
    mainDark:'#005891',
    mediumDark:'#0078B2',
    corporate:'#00ABE7',
    lightMedium: '#99E0F9',
    lightShort: '#A3D4EA',
    light:'#E6F7FD',

    darkGray:'#61686B',
    mediumGray:'#A4A8AA',

    neutralBlack:'#1C1F20',

    border:'#E9EAEA',
    background:'#F2F2F2',
    backgroundLight:'#F9F9F9',

    textPrimary: '#252525',
    textSecondary: '#606060',
    textContrast: '#F9F9FA',

    white: '#FFFFFF',
    black: '#000000',
    transparent: '#00000000',

    primaryMedium: '#0078B2',
    primaryDark: '#005891',
    primaryCorporate: '#00ABE7',
    primaryMediumLight: '#99E0F9',
    primaryLight: '#E6F7FD',

    backgroundElevate: '#FFFFFF',

    elementFaded: '#E9E9EA',
    elementDivider: '#D0D0E0',
    neutralBorder: '#E9EAEA',

    borderColor: '#F2F2F2',

    neutral: '#61686B',
    neutralMediumGray: '#A4A8AA',
    neutralBackground: '#F2F2F2',
    neutralBackgroundLight: '#F9F9F9',
    neutralDarkGray: '#61686B',

    contextualError: '#A31610',
    contextualErrorLight: '#FF8883',
    contextualSuccess: '#00763D',
    contextualSuccessLight: '#4BBE87',

    ubiActual: '#214BE1',
    ubiActualLight: '#8FA8FF',
    contrast: '#F46A06',

    contextualFavDark: '#BEAF25',
    contextualFav: '#E7D427',

    contextualWarningDark: '#BA4F14',
    contextualWarning: '#FF650F',
    contextualWarningLight: '#F9E8CE',

    optionMenuIcon: '#0D0D0D',

    mostBlack: '#333940',
};
