import ViewModel from '../@base/ViewModel';
import {Apis} from '../../domain/Apis';

export default class UserViewModel extends ViewModel {
  constructor() {
    super();
  }

  async get() {
    if (this.data) {
      return await this.data;
    } else {
      return super.notify(await Apis.user.getProfile());
    }
  }

  async update(profile) {
    return await Apis.user
      .updateProfile(profile)
      .then(res => super.notify(profile));
  }
}
