import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import GeneralUtils from '../../../utils/GeneralUtils';
import { RootState } from '../../store';

// export interface Post {
//   email: string;
//   password: string;
// }

export const lineServiceApi = createApi({
  reducerPath: 'lineServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GeneralUtils.getEnvironment(window.location.origin),
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accountInformation.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getLineDirections: builder.query<any, any>({
      query: (lineId) => `/agency/lines/${lineId}/directions`
    }),
    getStopsLine: builder.mutation<any, any>({
      query: ({id, tripId, stopId}) => ({
        url: `agency/lines/${id}/stops?${tripId}${stopId}`,
        method: 'GET',
      }),
    }),
    getLinesNear: builder.query<any, any>({
      query: ({latitude, longitude}) => ({
        url: `agency/lines/near-stop?latitude=${latitude}&longitude=${longitude}`,
        method: 'GET',
      }),
    }),
    getLinesWithHeadsigns: builder.mutation<any, any>({
      query: ({stopId, language}) => ({
        url: `agency/stops/${stopId}/lineswithheadsigns?language=${language}`,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (err) {
        } finally {
        }
      }
    }), 
  })
});

export const { useGetLineDirectionsQuery, useLazyGetLineDirectionsQuery, useGetLinesWithHeadsignsMutation, useGetStopsLineMutation, useLazyGetLinesNearQuery } = lineServiceApi;
