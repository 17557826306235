import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ActivateAccountModalWidget from "../../components/widget/Modals/ActivateAccountModalWidget";

export default function ActivateAccountScreen() {
  let [params] = useSearchParams();

  useEffect(() => {
    if (params.get('token')) {
    }
  });
  return <ActivateAccountModalWidget />;
}
