import UserViewModel from './instance/UserViewModel';
import NetworkViewModel from './device/NetworkViewModel';
import ThemeViewModel from './device/ThemeViewModel';
import NavigationViewModel from './device/NavigationViewModel';
import LocationViewModel from "./device/LocationViewModel";
import PoisViewModel from "./instance/PoisViewModel";
import StopsViewModel from "./instance/StopsViewModel";
import CategoriesViewModel from "./instance/CategoriesViewModel";
import SearchViewModel from "./instance/SearchViewModel";
import RecentsViewModel from "./instance/RecentsViewModel";
import TransportFiltersViewModel from './instance/filters/TransportFiltersViewModel';
import OperatorsFiltersViewModel from './instance/filters/OperatorsFiltersViewModel';
import PoisFiltersViewModel from './instance/filters/PoisFiltersViewModel';
import MotorizedFiltersViewModel from './instance/filters/MotorizedFiltersViewModel';
import PlannerSegmentsViewModel from "./instance/PlannerSegmentsViewModel";
import PlannerViewModel from "./instance/PlannerViewModel";
import PlannerTimeViewModel from "./instance/PlannerTimeViewModel";
import PlannerConfigViewModel from "./instance/PlannerConfigViewModel";
import AgenciesViewModel from "./instance/AgenciesViewModel";
import IconsViewModel from "./instance/IconsViewModel";
import DataOriginViewModel from "./instance/DataOriginViewModel";
import LinesViewModel from "./instance/LinesViewModel";
import AlertsViewModel from "./instance/AlertsViewModel";
import ScreenSelectedViewModel from "./instance/ScreenSelectedViewModel";

export const VM = {
    screenSelected : new ScreenSelectedViewModel(),
    icons: new IconsViewModel(),
    agencies : new AgenciesViewModel(),
    lines: new LinesViewModel(),
    dataOrigin : new DataOriginViewModel(),
    planner: new PlannerViewModel(),
    plannerSegments: new PlannerSegmentsViewModel(),
    alerts : new AlertsViewModel(),
    stops: new StopsViewModel(),
    pois: new PoisViewModel(),
    recents: new RecentsViewModel(),
    modes: new TransportFiltersViewModel(),


    initialize() {
        this.build();
    },

    build() {
        this.screenSelected = new ScreenSelectedViewModel()
        this.alerts = new AlertsViewModel()
        this.user = new UserViewModel();
        this.stops = new StopsViewModel();
        this.pois = new PoisViewModel();
        this.search = new SearchViewModel();
        this.categories = new CategoriesViewModel();
        this.recents = new RecentsViewModel();
        this.location = new LocationViewModel();
        this.network = new NetworkViewModel();
        this.plannerSegments = new PlannerSegmentsViewModel()
        this.plannerConfig = new PlannerConfigViewModel()
        this.plannerTime = new PlannerTimeViewModel()
        this.planner = new PlannerViewModel()
        this.lines = new LinesViewModel()
        this.agencies = new AgenciesViewModel()
        this.dataOrigin = new DataOriginViewModel()
        this.icons = new IconsViewModel()
        this.theme = new ThemeViewModel();
        this.navigation = new NavigationViewModel();
        this.modes = new TransportFiltersViewModel();
        this.operatorsFilters = new OperatorsFiltersViewModel();
        this.categoriesPoiFilters = new PoisFiltersViewModel();
        this.motorized = new MotorizedFiltersViewModel();
    },
};
