import Api from '../@base/Api';

export default class PoisApi extends Api {
    constructor(url) {
        super(url);
    }

    getPois(language, latitude, longitude) {
        return super.get(`/poi/cache/?locale=${language}&latitude=${latitude}&longitude=${longitude}&radious=15000`);
    }

    getOldPois(language, latitude, longitude) {
        return super.get(`/poi?locale=${language}&latitude=${latitude}&longitude=${longitude}&radious=15000`);
    }

    getPoi(language, id) {
        return super.get(`/poi/${id}?locale=${language}`);
    }

}
