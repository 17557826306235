import { useSelector } from "react-redux";
import { IMarkerInfo, StopInfo } from "../types/ExploreIntefaces";
import { agencyInformation } from "../redux/slices/agencySlice";

export default function useStopsUtilities() {
    const agencyInfo = useSelector(agencyInformation);
    const transportModes = agencyInfo.transportModes;
  
    function sortNearStops(stops: Array<StopInfo>) {
      return stops.sort((a: StopInfo, b: StopInfo) => {
        if (a.transportMode === b.transportMode) {
          //Comprobar por agencia
          const transportFilterA: any = agencyInfo.agenciesFilters.find(
            (element: any) => String(element?.id) === String(a.transportMode),
          );
  
          if (transportFilterA) {
            const agencyA = transportFilterA?.agencies.find((agency: any) => {
              if (agency?.groupId) {
                //agencyIdsGroup
                let foundAgencyIsInGroup = agency?.agencyIdsGroup.find(
                  (agOfGroup: number) =>
                    String(agOfGroup) === String(a?.agencyOriginId),
                );
                return foundAgencyIsInGroup;
              } else {
                return (
                  String(agency?.agencyOriginId) === String(a?.agencyOriginId)
                );
              }
            });
  
            if (!agencyA) {
              return 0;
            }
  
            //Pasamos a obtener la agencia de la B
            const transportFilterB: any = agencyInfo.agenciesFilters.find(
              (element: any) => String(element.id) === String(b.transportMode),
            );
  
            if (transportFilterB) {
              const agencyB = transportFilterB?.agencies.find((agency: any) => {
                if (agency?.groupId) {
                  let foundAgencyIsInGroup = agency?.agencyIdsGroup.find(
                    (agOfGroup: number) =>
                      String(agOfGroup) === String(b?.agencyOriginId),
                  );
                  return foundAgencyIsInGroup;
                } else {
                  return (
                    String(agency?.agencyOriginId) === String(b?.agencyOriginId)
                  );
                }
              });
  
              if (!agencyB) {
                return 0;
              }
              //caso comparar agencias
              if (
                String(agencyA.agencyOriginId) === String(agencyB.agencyOriginId)
              ) {
                let nameA = a.name?.toLowerCase() ?? '';
                let nameB = b.name?.toLowerCase() ?? '';
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
  
                // names must be equal
                return 0;
              } else {
                let agencyNameA = agencyA?.longName?.toLowerCase() ?? '';
                let agencyNameB = agencyB?.longName?.toLowerCase() ?? '';
                if (agencyNameA < agencyNameB) {
                  return -1;
                }
                if (agencyNameA > agencyNameB) {
                  return 1;
                }
  
                // names must be equal
                return 0;
              }
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        } else {
          const transportA: any = transportModes.find(
            (element: any) => String(element.id) === String(a.transportMode),
          );
          const transportB: any = transportModes.find(
            (element: any) => String(element.id) === String(b.transportMode),
          );
          if (!transportA || !transportB) {
            return 0;
          }
  
          return transportA?.orderDisplay - transportB?.orderDisplay;
        }
  
        /* function extractNumber(value: string) {
          // Extraerlos dígitos de la cadena del final
          let match = value.match(/^([^\d]+)(\d+)$/);
  
          return match
            ? {char: match[1], code: parseInt(match[match?.length - 1])}
            : {char: 'error', code: NaN};
        } */
      });
    }
  
    return {sortNearStops};
  }
  