import ViewModel from '../@base/ViewModel';
import {Apis} from "../../domain/Apis";

export default class DataOriginViewModel extends ViewModel {
    noGroup=[]
    constructor() {
        super();
    }

    async get(language) {
        let dataOrigin=await Apis.agency.getAllDataOrigin(language);
        this.noGroup=dataOrigin;
        let filteredFilt = dataOrigin
        let groupAdded = []
        for (let operator in dataOrigin) {
            if(dataOrigin[operator].groupId && groupAdded.indexOf(dataOrigin[operator].groupId)<0){
                let group2=[]
                groupAdded.push(dataOrigin[operator].groupId)
                let group = JSON.parse(JSON.stringify(Array.from(dataOrigin))).filter(
                    ({ groupId }) => dataOrigin[operator].groupId === groupId
                );
                for(let id in group) {
                    group2.push(group[id].agencyId)
                }
                filteredFilt = JSON.parse(JSON.stringify(Array.from(dataOrigin))).filter(
                    ({ groupId }) => dataOrigin[operator].groupId !== groupId
                );
                filteredFilt.push({groupIcon:dataOrigin[operator].groupIcon,
                    groupTxt:dataOrigin[operator].groupTxt,
                    id:dataOrigin[operator].groupId,
                    agencyId:dataOrigin[operator].groupId,
                    icon:dataOrigin[operator].groupIcon,
                    driverId:1,
                    transportModes:dataOrigin[operator].transportModes,
                    description:dataOrigin[operator].description,
                    group:group2})
            }
            /*"groupId": null,
            "groupTxt": null,
            "groupIcon": null*/
        }

        return super.notify(filteredFilt)
    }
}
