import FavoriteModal from './FavoriteModal';
import {useLanguage} from "../../context/languageContext";


export default function FavoriteScreen(props: any) {

    const language = useLanguage()

    return (
        <>
            <FavoriteModal/>
        </>
    );
}
