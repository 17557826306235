import {useTheme} from "../../context/themeContext";
import React from 'react';

export default function RadioButton(props, ref) {
    const theme = useTheme();

    const styles={
        circleIn: {
            borderRadius:30,
            minHeight:10,
            minWidth:10,
            maxHeight:10,
            maxWidth:10,
            backgroundColor:props.selected ? theme.colors.mediumDark : theme.colors.transparent,
        },
        circleOut: {
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            borderRadius:30,
            border: props.selected ? `2px solid ${theme.colors.mediumDark}` : `2px solid ${theme.colors.mediumGray}`,
            minHeight:18,
            minWidth:18,
            maxHeight:18,
            maxWidth:18,
            cursor:'pointer',
        }
    }

    return (
        <div style={{...props.style}} onClick={props.onClick}>
            <div style={styles.circleOut}>
                <div style={styles.circleIn}/>
            </div>
        </div>
    );
}
