import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import AlertToSaveModalWidget from '../../components/widget/Modals/AlertToSaveModalWidget';
import AnonymousAccessDeniedModalWidget from '../../components/widget/Modals/AnonymousAccessDeniedModalWidget';
import ChangePassModalWidget from '../../components/widget/Modals/ChangePassModalWidget';
import LoginModalWidget from '../../components/widget/Modals/LoginModalWidget';
import PersonalInformationModalWidget from '../../components/widget/Modals/PersonalInformationModalWidget';
import RecoveryPassModalWidget from '../../components/widget/Modals/RecoveryPassModalWidget';
import RemoveAccountModalWidget from '../../components/widget/Modals/RemoveAccountModalWidget';
import ServerNotAvailableModalWidget from '../../components/widget/Modals/ServerNotAvailableModalWidget';
import SingUpModalWidget from '../../components/widget/Modals/SingUpModalWidget';
import CustomizedSnackbarModalWidget from '../../components/widget/Modals/StatusCodesModalWidgets/CustomizedSnackbarModalWidget';
import { useTranslate } from '../../context/languageContext';
import ReportModalWidget from "../ReportIssue/ReportModalWidget";

export default function LoginScreen() {
  const t = useTranslate();
  // const dispatch = useAppDispatch();
  const toggleVisibility = useSelector(
    (state: RootState) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );

  return (
    <>
      {toggleVisibility.LoginModalWidget ? <LoginModalWidget /> : ''}
      {toggleVisibility.PersonalInformationModalWidget ? <PersonalInformationModalWidget /> : ''}
      {toggleVisibility.RecoveryPassModalWidget ? <RecoveryPassModalWidget /> : ''}
      {toggleVisibility.RemoveAccountModalWidget ? <RemoveAccountModalWidget /> : ''}
      {toggleVisibility.SingUpModalWidget ? <SingUpModalWidget /> : ''}
      {toggleVisibility.AlertToSaveModalWidget ? <AlertToSaveModalWidget /> : ''}
      {/* {toggleVisibility.SuccessModalWidget ? <SuccessModalWidget text={t('success')} /> : ''} */}
      {/* {toggleVisibility.RecoveryEmailSentModalWidget ? (
        <RecoveryEmailSentModalWidget text={t('recoveryEmailSent')} />
      ) : (
        ''
      )} */}
      {/* {toggleVisibility.AccessDeniedModalWidget ? <AccessDeniedModalWidget /> : ''} */}
      {toggleVisibility.ServerNotAvailableModalWidget ? <ServerNotAvailableModalWidget /> : ''}
      {toggleVisibility.ChangePassModalWidget ? <ChangePassModalWidget /> : ''}
      {toggleVisibility.ReportModalWidget.showModal ? <ReportModalWidget /> : ''}
      {toggleVisibility.CustomizedSnackbarModalWidget.showModal ? (
        <CustomizedSnackbarModalWidget />
      ) : (
        ''
      )}
      {/* {toggleVisibility.ReportRouteModalWidget ? <ReportRouteModalWidget /> : ''} */}
      {toggleVisibility.AnonymousAccessDeniedModalWidget ? (
        <AnonymousAccessDeniedModalWidget />
      ) : (
        ''
      )}
    </>
  );
}
