import React from 'react';
import { useLanguage, useSetLanguage, useTranslate } from '../../../context/languageContext';
import { useTheme } from '../../../context/themeContext';
import Dropdown from '../../../components/commons/dropdown/Dropdown';
import DropdownStyled, { IDropdownItem } from '../../../components/commons/dropdown/DropdownStyled';

interface LanguageSelectorProps {
    style?: any;
}

export default function LanguageSelector(props: LanguageSelectorProps) {
  const t = useTranslate();
  const theme = useTheme();
  const languageSelected = useLanguage();
  const setLanguage = useSetLanguage();

  const languages: Array<IDropdownItem> = [
    { label: 'ES', value: 'es' },
    { label: 'EN', value: 'en' },
    { label: 'EU', value: 'eu' },
    { label: 'FR', value: 'fr' }
  ];

  return (
    <div style={!props.style ? { position: 'absolute', right: 10, top: 25, zIndex: 5 } : props.style}>
      <div
        style={{
          width: 55,
          backgroundColor: theme.colors.primaryMedium,
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'row'
        }}>
        <DropdownStyled
          styleCollapse={{ backgroundColor: theme.colors.primaryMedium, width: 55, }}
          items={languages}
          selectedItem={{ label: languageSelected, value: languageSelected }}
          onChange={(value: string) => {
            setLanguage(value);
          }}
        />
      </div>
    </div>
  );
}
