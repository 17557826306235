import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import GeneralUtils from "../../../utils/GeneralUtils";

export interface Post {
  id: number;
  name: string;
}

export const vehiclesServiceApi = createApi({
  reducerPath: 'vehiclesServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GeneralUtils.getEnvironment(window.location.origin),
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accountInformation.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  // tagTypes: ['GetRoute'],
  endpoints: (builder) => ({
    getLineVehicles: builder.query<any, any>({
      query: ({line,tripId}) => `/vehicle/?routeId=${line}&tripId=${tripId}`
      // providesTags: ['GetRoute']
    })
  })
});

export const { useGetLineVehiclesQuery } = vehiclesServiceApi;
