import { useTheme } from '../../../context/themeContext';

import RadioButton from '../../../components/commons/RadioButton';
import { useLanguage, useSetLanguage, useTranslate } from '../../../context/languageContext';

export default function LanguageModal() {
  const t = useTranslate();
  const theme = useTheme();
  const languageSelected = useLanguage();
  const setLanguage = useSetLanguage();

  const languages = ['es', 'eu', 'en', 'fr'];

  const styles = {
    content: {
      textAlign: 'start',
      backgroundColor: theme.colors.white,
      minHeight: 500,
      borderRadius: '0 0 24px 0'
    },
    title: {
      fontSize: 18,
      fontWeight: 700,
      paddingLeft: '13%',
      paddingTop: '4%',
      paddingBottom: '2.6%',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.colors.border
    },
    option: {
      display: 'flex',
      //   paddingTop: '20px',
      cursor: 'pointer',
      marginLeft: '13.7%',
      marginRight: '13.7%',
      paddingTop: '2.6%',
      paddingBottom: '3%',
      borderBottomColor: theme.colors.border,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1
    },
    label: {
      fontSize: '14px',
      fontWeight: 600
    },
    icon: {
      marginLeft: 'auto'
    }
  };

  return (
    <div className={'account-child'} style={styles.content}>
      <div style={styles.title}>{t('language')}</div>

      {languages.map((language) => (
        <div
          key={language}
          style={languageSelected === language ? styles.selected : styles.text}
          onClick={() => {
            setLanguage(language);
          }}>
          <div style={styles.option}>
            <div style={styles.label}>{t(language)}</div>
            <RadioButton style={styles.icon} selected={languageSelected === language} />
          </div>
        </div>
      ))}
    </div>
  );
}
