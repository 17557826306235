import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  useAddSubscriptionsMutation,
  useDeleteSubscriptionsMutation
} from '../../../redux/login/subscriptions/SubscriptionsServiceApi';
import { accountInformation } from '../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../redux/slices/eventsModalstoggleVisibilitySlice';
import {
  subscriptionsInformation,
  subscriptionsSlice
} from '../../../redux/slices/subscriptionsSlice';
import TimeUtils from '../../../utils/TimeUtils';
import { VM } from '../../../viewmodel/VM';
import Button from '../../components/commons/Button';
import ButtonIcon from '../../components/commons/ButtonIcon';
import DefaultModal from '../../components/widget/Modals/DefaultModal';
import { useLanguage, useTranslate } from '../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../context/modalContext';
import { R } from '../../R';
import { useTheme } from '../../context/themeContext';
import LineIcon from '../explorescreen/mapOptions/searcherOption/stopDetail/LineIcon';
import { lineSlice, linesState } from '../../../redux/slices/lineSlice';
import { ILine } from '../../../types/LineInterfaces';
import { ITransitAlertLine, ITransitAlertStop } from '../../../types/ExploreIntefaces';
import { favoritesSlice } from '../../../redux/slices/favoritesSlice';
import { useGetStopsLineMutation, useLazyGetLineDirectionsQuery } from '../../../redux/login/line/lineServiceApi';

type Props = {
  alert: any;
};

export default function TransitInfoModal(props: Props) {
  const t = useTranslate();
  const [renderHeader, setRenderHeader] = useState(<div />);
  const WebIcon = R.drawables.general.Ic_Link;
  const ShareIcon = R.drawables.general.Ic_SocialShare;
  const subscriptions = useSelector(subscriptionsInformation);
  const [addSubscription] = useAddSubscriptionsMutation();
  const [deleteSubscription] = useDeleteSubscriptionsMutation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const account = useSelector(accountInformation);
  const setOpen = useSetModalOpened();
  const setChild = useSetModalChild();
  const language = useLanguage();
  const allLines = useSelector(linesState);
  const allStops = VM.stops.noMap;
  const [iconAgency, setIcon] = useState();
  const [othersAffected, setOthersAffected] = useState(null);
  const [getLineDirections] = useLazyGetLineDirectionsQuery();
  const [getLineStops] = useGetStopsLineMutation();

  useEffect(() => {
    async function fetchData() {
      if (props.alert) setRenderHeader(await getRenderHeader());
    }
    fetchData();
  }, [props.alert]);

  const getTimeMessage = (time: any) => {
    let date = new Date(time);
    const difference = Math.floor(new Date().getTime() - new Date(date).getTime());
    let minutesDifference = Math.floor(difference / 60000);
    let message = TimeUtils.getDate(new Date(time));

    if (language === 'en') {
      if (minutesDifference === 0) {
        message = `1 ${t('date_minute')} ${t('date_difference')}`;
      } else if (minutesDifference < 60) {
        message = `${minutesDifference} ${t('date_minute')} ${t('date_difference')}`;
      } else if (minutesDifference < 1440) {
        message = `${Math.floor(minutesDifference / 60)} ${t('date_hours')} ${t(
          'date_difference'
        )}`;
      } else {
        let daysDifference = Math.round(difference / (1000 * 60 * 60 * 24));
        if (daysDifference === 1) {
          message = `${t('date_day')} ${t('date_difference')}`;
        } else if (daysDifference < 7) {
          message = `${daysDifference} ${t('date_days')} ${t('date_difference')}`;
        } else if (daysDifference < 14) {
          message = `${t('date_week')} ${t('date_difference')}`;
        } else if (daysDifference < 30) {
          message = `${Math.floor(daysDifference / 7)} ${t('date_weeks')} ${t('date_difference')}`;
        } else if (daysDifference < 60) {
          message = `${t('date_month')} ${t('date_difference')}`;
        }
      }
    } else {
      if (minutesDifference === 0) {
        message = `${t('date_difference')} 1 ${t('date_minute')}`;
      } else if (minutesDifference < 60) {
        message = `${t('date_difference')} ${minutesDifference} ${t('date_minute')}`;
      } else if (minutesDifference < 1440) {
        message = `${t('date_difference')} ${Math.floor(minutesDifference / 60)} ${t(
          'date_hours'
        )}`;
      } else {
        let daysDifference = Math.round(difference / (1000 * 60 * 60 * 24));
        if (daysDifference === 1) {
          message = `${t('date_difference')} ${t('date_day')}`;
        } else if (daysDifference < 7) {
          message = `${t('date_difference')} ${daysDifference} ${t('date_days')}`;
        } else if (daysDifference < 14) {
          message = `${t('date_difference')} ${t('date_week')}`;
        } else if (daysDifference < 30) {
          message = `${t('date_difference')} ${Math.floor(daysDifference / 7)} ${t('date_weeks')}`;
        } else if (daysDifference < 60) {
          message = `${t('date_difference')} ${t('date_month')}`;
        }
      }
    }
    return message;
  };

  const getRenderHeader = async () => {
    if (VM.agencies.data?.length == 0) {
      await VM.agencies.get();
    }
    if (VM.dataOrigin.data?.length == 0) {
      await VM.dataOrigin.get(useLanguage());
    }
    let agencyId = VM.dataOrigin.data[props.alert.idAgencia]?.agencyId;
    let icon = await VM.icons.getIcon(agencyId, 'agency');
    setIcon(icon);
    let nameAlert = props.alert?.element ? props.alert?.element : '';
    let lineSearched: any = props.alert?.line
      ? allLines.find((line: ILine) => String(line.id) === String(props.alert?.line?.idLinea))
      : undefined;
    return (
      <div className={'row'} style={{ paddingInline: 20, marginBottom: 10 }} key={'header'}>
        {props.alert?.line ? (
          <LineIcon
            onClick={() => {showDetailStopOrLine(undefined, props.alert?.line)}}
            style={{ marginRight: 5 }}
            textColor={'#' + lineSearched?.routeTextColor}
            color={lineSearched?.routeColor ? '#' + lineSearched?.routeColor : null}
            text={lineSearched?.code ?? ''}
          />
        ) : (
          <ButtonIcon
            style={{ marginRight: 5, cursor: 'pointer' }}
            iconSize={20}
            size={25}
            icon={icon}
            onClick={() => {
              showDetailStopOrLine(props.alert?.stop, undefined)
            }}
          />
        )}
        <div className={'row'} style={{ width: '100%', alignItems: 'baseline', textAlign: 'left' }}>
          <div
            className={'cornerText bold'}
            style={{
              wordBreak: nameAlert.includes(' ') ? 'break-word' : 'break-all',
              marginRight: 10
            }}>
            {nameAlert}
          </div>
          <div className={'cornerText bold'} style={{ marginLeft: 'auto' }}>
            {props.alert.causa}
          </div>
        </div>
      </div>
    );
  };

  async function showDetailStopOrLine(alertStop?: ITransitAlertStop, alertLine?: ITransitAlertLine) {
    if (alertStop) {
      const stopSelected = allStops.find((element: any) => element.id == alertStop.idParada);
      if (stopSelected) {
        VM.screenSelected.setSelected('explore');
        dispatch(favoritesSlice.actions.setClickFav({ id: stopSelected.id, type: 'stop' }));
        VM.lines.setSelected(null);
        setOpen(false);
        setChild(null);
      } else {
        //TODO
      }
    } else if (alertLine) {
    let selectedLine = alertLine.idLinea;
    dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
    const result: any = await getLineDirections(alertLine.idLinea);

    if (result?.data?.length) {
      let tripId = result?.data[0]?.tripId;

      const resultStops: any = await getLineStops({
        id: alertLine.idLinea,
        tripId: `tripId=${result?.data[0]?.tripId}`,
        stopId: ''
      });
      if (resultStops?.data?.length) {
        let markerStop = JSON.parse(JSON.stringify(resultStops?.data[0]));
        VM.lines.setSelected(selectedLine, tripId, resultStops?.data[0]?.id);
        VM.screenSelected.setSelected('explore');
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityReportModalWidget({
            element: selectedLine
          })
        );
        dispatch(favoritesSlice.actions.setClickFav({ ...markerStop, ...{ type: 'lineSel' } }));
        dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        dispatch(lineSlice.actions.updateFilteredLine(selectedLine));
        setOpen(false);
        setChild(null);
      } else {
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#A31610',
            text: t('serviceError'),
            status: 'error'
          })
        );
        dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
      }
    } else {
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#A31610',
          text: t('serviceError'),
          status: 'error'
        })
      );
    }
    }
  }

  const updateAlert = () => {
    let updateSubscriptions = JSON.parse(JSON.stringify(subscriptions));
    if (account.idUserType === 1) {
      setOpen(true);
      setChild(
        <DefaultModal
          title={t('loginToFav')}
          description={t('loginToFavBody')}
          alter={true}
          firstButton={t('createAccount')}
          secondButton={t('login')}
          onClickFirst={() => {
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityPersonalInformationModalWidget(
                true
              )
            );
          }}
          onClickSecond={() => {
            dispatch(
              eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
            );
          }}
        />
      );
    } else {
      if (!props.alert.lineas.length && props.alert.paradas.length) {
        if (subscriptions.stops.indexOf(props.alert.paradas[0].idParada as never) >= 0) {
          updateSubscriptions.stops.splice(
            updateSubscriptions.stops.indexOf(props.alert.paradas[0].idParada),
            1
          );
          deleteSubscription({
            stops: [props.alert.paradas[0].idParada],
            lines: [],
            agencyNotices: []
          });
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
              {
                showModal: true,
                background: '#00763D',
                text: t('alertRemoved')
              }
            )
          );
        } else {
          updateSubscriptions.stops.push(props.alert.paradas[0].idParada);
          addSubscription({
            stops: [props.alert.paradas[0].idParada],
            lines: [],
            agencyNotices: []
          });
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
              {
                showModal: true,
                background: '#00763D',
                text: t('alertAdded')
              }
            )
          );
        }
      } else {
        if (subscriptions.lines.indexOf(props.alert.lineas[0].idLinea as never) >= 0) {
          updateSubscriptions.lines.splice(
            updateSubscriptions.lines.indexOf(props.alert.lineas[0].idLinea),
            1
          );
          deleteSubscription({
            stops: [],
            lines: [props.alert.lineas[0].idLinea],
            agencyNotices: []
          });
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
              {
                showModal: true,
                background: '#00763D',
                text: t('alertRemoved')
              }
            )
          );
        } else {
          updateSubscriptions.lines.push(props.alert.lineas[0].idLinea);
          addSubscription({
            stops: [],
            lines: [props.alert.lineas[0].idLinea],
            agencyNotices: []
          });
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget(
              {
                showModal: true,
                background: '#00763D',
                text: t('alertAdded')
              }
            )
          );
        }
      }
      dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
    }
  };

  const isSub = () => {
    if (!props?.alert.lineas?.length && props.alert?.paradas?.length) {
      if (subscriptions.stops.indexOf(props.alert.paradas[0]?.idParada as never) >= 0) {
        return true;
      } else if (subscriptions.lines.indexOf(props.alert.lineas[0]?.idLinea as never) >= 0) {
        return true;
      }
      return false;
    }
    return false;
  };

  function renderOthersAffected() {
    if (props.alert?.stop) {
      return props.alert?.lineas?.map((linea: ITransitAlertLine) => {
        const searchLine: any = allLines.find(
          (element: ILine) => String(element.id) === String(linea.idLinea)
        );
        if (!searchLine) {
          return null;
        }
        
        return (
          <div
            key={linea.idLinea}
            onClick={() => {
              showDetailStopOrLine(undefined, linea)
            }}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              columnGap: 8,
              marginBottom: 8,
              display: 'flex',
              cursor: 'pointer',
            }}>
            <LineIcon
              item={searchLine}
              onClick={() => showDetailStopOrLine(undefined, linea)}
              text={searchLine?.code}
                  textColor={searchLine?.routeTextColor ? '#' + searchLine?.routeTextColor : undefined}
                  color={searchLine?.routeColor ? '#' + searchLine?.routeColor : theme.colors.main}
              //style={styleItem}
            />
            {searchLine?.name ? <div className={'description'}>{searchLine.name}</div> : null}
          </div>
        );
      });
    } else if (props.alert?.line) {
      return props.alert?.paradas?.map((stop: ITransitAlertStop) => {
        return (
          <div
            key={stop.idParada}
            onClick={() => {
              showDetailStopOrLine(stop, undefined)
            }}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              columnGap: 8,
              marginBottom: 8,
              display: 'flex',
              cursor: 'pointer',
            }}>
            <ButtonIcon
              style={{ marginRight: 5, cursor: 'pointer' }}
              iconSize={20}
              size={25}
              icon={iconAgency}
            />
            {stop?.idNombre ? <div className={'description'}>{stop?.idNombre}</div> : null}
          </div>
        );
      });
    } else {
      return null;
    }
  }

  function renderOthersHeader() {
    if (props.alert?.stop && props.alert?.lineas?.length) {
      return (<div className={'primaryText'} style={{marginBottom: 8}}>{t('affected_lines')}</div>);
    } else if (props.alert?.line && props.alert?.paradas?.length) {
      return (<div className={'primaryText'} style={{marginBottom: 8}}>{t('affected_stops')}</div>);
    }

    return null;
  }

  useEffect(() => {
    if (props.alert) {
      setOthersAffected(renderOthersAffected());
    }
  }, [allLines, props.alert, iconAgency]);

  return props.alert ? (
    <div className={'modalContent'}>
      <div className={'transit-modal-content'}>
        <div className={'row'} style={{ padding: 20 }}>
          <div className={'title'}>{t('alertDetail')}</div>
          {props.alert?.link ? (
            <ShareIcon
              data-for="main"
              data-tip={t('copyUrl')}
              data-iscapture="true"
              className={'icon'}
              style={{ marginLeft: 'auto', cursor: 'pointer' }}
              onClick={() => {
                setTimeout(ReactTooltip.hide, 2000);
                navigator.clipboard.writeText(props.alert?.link);
              }}
            />
          ) : null}
          <ReactTooltip id="main" place={'top'} event={'click'} multiline={true} />
        </div>
        {renderHeader}
        <div className={'transit-modal-body'}>
          <div className={'column'}>
            <div className={'transit-modal-info'}>
              <div className={'row marginBottom10'} style={{ alignItems: 'baseline' }}>
                <div className={'primaryText'} style={{ width: 200 }}>
                  {props.alert?.titulo}
                </div>
                <div className={'cornerText'} style={{ marginLeft: 'auto' }}>
                  {getTimeMessage(props.alert?.updatedAt)}
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: props.alert?.descripcion }}
                className={'description'}
              />
              <div style={{ marginTop: 8, display: 'flex', flexDirection: 'column' }}>
                {renderOthersHeader()}
                {othersAffected}
              </div>
            </div>

            {props.alert?.link ? (
              <>
                <div className={'primaryText'} style={{ textAlign: 'center' }}>
                  {t('moreInf')}
                </div>
                <div className={'row'} style={{ marginTop: 5, paddingBottom: 10 }}>
                  <WebIcon className={'icon'} />
                  <div className={'paramText'} style={{ lineHeight: '200%', marginLeft: 5 }}>
                    {t('website')}
                  </div>
                  <a
                    className={'url'}
                    style={{ lineHeight: '200%', marginLeft: 'auto' }}
                    href={props.alert?.link}
                    target="_blank">
                    {t('visit')}
                  </a>
                </div>
              </>
            ) : null}
            <div style={props.alert?.link ? { marginTop: 0 } : { marginTop: 60 }}>
              <Button
                textColor={theme.colors.white}
                background={theme.colors.mediumDark}
                style={{ width: '100%' }}
                text={isSub() ? t('removeAlert') : t('activeAlert')}
                onClick={updateAlert}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
