import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Circle,
  LayerGroup,
  Marker,
  Pane,
  Polyline,
  Popup,
  Tooltip,
  useMap,
  useMapEvents
} from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Apis } from '../../domain/Apis';
import {
  favoritesInformation,
  favoritesSlice,
  filterButton
} from '../../redux/slices/favoritesSlice';
import { planInformation } from '../../redux/slices/shareSlice';
import Ic_BusUrbano from '../../res/values/drawables/transport/Ic_BusUrbano.svg';
import FilterUtils from '../../utils/FilterUtils';
import PlanUtils from '../../utils/PlanUtils';
import RouteUtils from '../../utils/RouteUtils';
import { VM } from '../../viewmodel/VM';
import { useLanguage, useTranslate } from '../context/languageContext';
import { useSetLoading } from '../context/loadingContext';
import { useSetModalChild, useSetModalOpened } from '../context/modalContext';
import { useTheme } from '../context/themeContext';
import { R } from '../R';
import LeafLetMarker from './LeafLetMarker';
import {
  useGetStationsMutation,
  useGetVehiclesMutation
} from '../../redux/login/explore/GbfsServiceApi';
import { markersInformation, markersSlice } from '../../redux/slices/markersSlice';
import { vehiclesInformation } from '../../redux/slices/vehicleSlice';
import { CircleMarker } from 'react-leaflet';
import { filtersInformation } from '../../redux/slices/filtersSlice';
import { alertsState } from '../../redux/slices/alertsSlice';
import { stopsInLineState } from '../../redux/slices/lineSlice';

export default function LeafLetMap(props, ref) {
  const t = useTranslate();
  const map = useMap();
  const theme = useTheme();
  const [center, setCenter] = useState({ lat: 43.047286, lng: -2.32345 });
  const language = useLanguage();
  const dispatch = useDispatch();
  const [useGetVehicles] = useGetVehiclesMutation();
  const [useGetStations] = useGetStationsMutation();
  const favorites = useSelector(favoritesInformation);
  const markers = useSelector(markersInformation);
  const loadedPoiMarkers = markers.loadedTypes.pois;
  const filters = useSelector(filtersInformation);
  const activeFilterButton = useSelector(filterButton);

  const toggleVisibility = useSelector(
    (state) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );

  const iconVehicles = new L.icon({
    iconUrl: Ic_BusUrbano,
    iconRetinaUrl: Ic_BusUrbano,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(15, 15),
    className: 'vehicle-position-icon'
  });

  const createIcon = (icon) => {
    return new L.icon({
      iconUrl: icon,
      zIndex: props.zIndex,
      options: {
        objectFit: 'cover'
      },
      iconSize: new L.Point(32, 36),
      iconAnchor: props.iconAnchor ?? [10, 30]
    });
  };

  const mapEvents = useMapEvents({
    move() {
      let centerChanged = map.getCenter();
      setCenter(centerChanged);
      localStorage.setItem('mapData', JSON.stringify({ coords: map.getCenter(), zoom: map._zoom }));
    },
    zoomend() {
      renderDirectionsRegion(favorites.directions);
      renderGBFSStationsRegion(markers.stations.filtered);
      renderGBFSVehiclesRegion(markers.vehicles);
      renderStopsRegion(VM.stops?.data);
      renderPoisRegion(VM.pois?.data);
    },
    dragend() {
      renderGBFSStationsRegion(markers.stations.filtered);
      renderGBFSVehiclesRegion(markers.vehicles);
      renderDirectionsRegion(favorites.directions);
      renderStopsRegion(VM.stops?.data);
      renderPoisRegion(VM.pois?.data);
    },
    dblclick(e) {
      props.onDblClick(e, map);
    },
    click(e) {
      if (isPlan) {
        props?.onClick(e);
      }
    }
  });

  React.useImperativeHandle(ref, () => ({
    flyTo
  }));

  const setLoading = useSetLoading();
  const setChildModal = useSetModalChild();
  const setOpenModal = useSetModalOpened();
  const [stops, setStops] = useState([]);
  const [pois, setPois] = useState([]);
  const [vehiclesGBFS, setVehicles] = useState([]);
  const [stationsGBFS, setStations] = useState([]);
  const [directions, setDirections] = useState([]);
  const [coords, setCoords] = useState([]);
  const [screenSelected, setScreenSelected] = useState();
  const vehicles = useSelector(vehiclesInformation);
  const [lineSelected, setLineSelected] = useState();
  const [isPlan, setIsPlan] = useState(Object.keys(VM.plannerSegments.data).length !== 0);
  const [renderStops, setRenderStops] = useState();
  const [renderContainers, setRenderContainers] = useState();
  const [renderLineStops, setRenderLineStops] = useState();
  const [renderGBFSVehicles, setRenderGBFSVehicles] = useState();
  const [renderGBFSStations, setRenderGBFSStations] = useState();
  const [renderPois, setRenderPois] = useState();
  const lineStopsList = useSelector(stopsInLineState);
  const alerts = useSelector(alertsState);

  /* useEffect(() => {
    async function fetchStopsList() {
      if (lineSelected) {
        setLineStopsList([]);
        await Apis.lines
          .getStops(lineSelected.id, lineSelected.tripId, lineSelected.stopId)
          .then((r) => renderLinesStopsRegion(r));
      }
    }
    fetchStopsList();
  }, [lineSelected]); */

  const flyTo = (location, zoom) => {
    if (location) {
      if (zoom) map.setZoom(zoom);
      else map.setZoom(15);
      map.flyTo(location);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (!stops?.error) setRenderStops(await getRenderStops());
      if (!lineStopsList?.error) setRenderLineStops(await getRenderLineStops());
      if (!pois?.error) setRenderPois(await getRenderPois());
      if (!pois?.error && !stops?.error) setRenderContainers(await getRenderContainers());
      if (!vehiclesGBFS?.error) setRenderGBFSVehicles(await getRenderGBFSVehicles());
      if (!stationsGBFS?.error) setRenderGBFSStations(await getRenderGBFSStations());
    }
    fetchData();
  }, [pois, stops, lineStopsList, lineSelected, props.markerSelected, vehiclesGBFS, stationsGBFS, loadedPoiMarkers]);

  const onPlannerSegmentChange = useCallback(() => {
    let plannerSegments = VM.plannerSegments.data;
    if (
      plannerSegments['origin'] &&
      plannerSegments['destination'] &&
      Object.keys(plannerSegments).length >= 2 &&
      plannerSegments.indexOf(undefined) < 0
    ) {
      setLoading(true);
      VM.planner
        .plan(VM.plannerConfig.data.favoritePlan)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      setIsPlan(true);
    } else if (Object.keys(plannerSegments).length !== 0 && VM.planner.data?.idPlanning) {
      setIsPlan(true);
    } else if (Object.keys(plannerSegments).length !== 0) {
      VM.planner.clear();
      setIsPlan(true);
    } else {
      VM.planner.clear();
      setIsPlan(false);
    }
  }, [setLoading, favorites, VM.plannerConfig.data.favoritePlan]);

  useEffect(() => {
    if (favorites.agencyId && favorites.agencyId?.length > 0) {
      VM.plannerConfig.setFavoritePlan(true);
    } else {
      VM.plannerConfig.setFavoritePlan(false);
    }
  }, [favorites.agencyId])

  const onPlannerChange = useCallback(async () => {
    let coords = [];
    if (Object.keys(VM.planner.data).length && !VM.planner.data.error) {
      setOpenModal(false);
      if ((RouteUtils.getLength(VM.planner.data) <= VM.planner.selected) || isNaN(VM.planner.selected)) {
        coords = await RouteUtils.getItiCoords(VM.planner.data, 0, language);
        VM.planner.setSelected(0);
      } else coords = await RouteUtils.getItiCoords(VM.planner.data, VM.planner.selected, language);
    } else if (VM.planner.data.error) {
      setOpenModal(true);
      setChildModal(
        <div style={{ padding: 20, maxWidth: 400 }}>
          {VM.planner.data.error.msg ?? 'An error has occured'}
        </div>
      );
    }
    setCoords(coords);
    if (VM.plannerSegments.data.destination?.coords && VM.plannerSegments.data.origin?.coords)
      setTimeout(
        () =>
          map.flyTo(
            PlanUtils.getMidPoint(
              VM.plannerSegments.data?.destination?.coords,
              VM.plannerSegments.data?.origin?.coords
            ),
            PlanUtils.getMidZoom(
              VM.plannerSegments.data?.destination?.coords,
              VM.plannerSegments.data?.origin?.coords
            )
          ),
        1300
      );
  }, [setChildModal, setOpenModal, favorites]);

  const renderPoisRegion = (e) => {
    if (e) {
      let filtered = FilterUtils.poisByRegion(map.getBounds(), map.getZoom(), e, favorites);
      /* if (screenSelected?.screen === 'favorites') {
        let favoritePoisMarkers = [];
        favorites.pois.forEach((element) => {
          let isFavorite = filtered.find((marker) => marker?.id === element);
          if (isFavorite) {
            favoritePoisMarkers.push(isFavorite);
          }
        });
        setPois(favoritePoisMarkers);
        return;
      } */
      if (JSON.stringify(filtered) !== JSON.stringify(pois)) setPois(filtered);
      else setPois(pois);
    }
  };

  const renderDirectionsRegion = (e) => {
    if (e) {
      let filtered = FilterUtils.directionsByRegion(map.getBounds(), map.getZoom(), e);
      if (JSON.stringify(filtered) !== JSON.stringify(directions)) setDirections(filtered);
      else setDirections(directions);
    }
  };

  const renderGBFSVehiclesRegion = (e) => {
    if (e) {
      let filtered = FilterUtils.vehiclesByRegion(map.getBounds(), map.getZoom(), e);
      if (JSON.stringify(filtered) !== JSON.stringify(markers?.vehicles)) setVehicles(filtered);
      else setVehicles(markers?.vehicles);
    }
  };

  const renderGBFSStationsRegion = (e) => {
    if (e) {
      let filtered = FilterUtils.stationsByRegion(map.getBounds(), map.getZoom(), e, favorites);
      /* if (screenSelected?.screen === 'favorites') {
        let favoriteStationsMarkers = [];
        favorites.stops.forEach((element) => {
          let isFavorite = filtered.find((marker) => marker?.id === element);
          if (isFavorite) {
            favoriteStationsMarkers.push(isFavorite);
          }
        });
        setStations(favoriteStationsMarkers);
        return;
      } */
      if (JSON.stringify(filtered) !== JSON.stringify(markers?.stations?.filtered))
        setStations(filtered);
      else setStations(markers?.stations?.filtered);
    }
  };

  const renderStopsRegion = (e) => {
    if ((screenSelected?.screen === 'transit_news' || screenSelected?.screen === 'news') && screenSelected?.section === 2) {
      let filtered = FilterUtils.onlyStopAlerts(map.getBounds(), e, favorites, alerts);
      if (JSON.stringify(filtered) !== JSON.stringify(stops)) setStops(filtered);
      else setStops(stops);
    } /* else if (screenSelected?.screen === 'favorites') {
      let filteredFavoriteStops = FilterUtils.stopsByRegion(map.getBounds(), map.getZoom(), e, favorites, alerts);
      let favoriteStopMarkers = [];
      favorites.stops.forEach((element) => {
        let isFavorite = filteredFavoriteStops.find((marker) => marker?.id === element);
        if (isFavorite) {
          favoriteStopMarkers.push(isFavorite);
        }
      });
      setStops(favoriteStopMarkers);
    }  */else if (e) {
      let filtered = FilterUtils.stopsByRegion(map.getBounds(), map.getZoom(), e, favorites, alerts);
      if (JSON.stringify(filtered) !== JSON.stringify(stops)) setStops(filtered);
      else setStops(stops);
    }
  };

  const renderLinesStopsRegion = (e) => {
    if (e) {
      let filtered = FilterUtils.linesStopsByRegion(e, favorites);
      if (JSON.stringify(filtered) !== JSON.stringify(lineStopsList)) setLineStopsList(filtered);
      else setLineStopsList(lineStopsList);
    }
  };

  useEffect(() => {
    VM.alerts.get(language);
    VM.pois.get(language, center.lat, center.lng).then((data) => {
      dispatch(markersSlice.actions.updatePois({ data: data?.data }));
    });
    VM.stops.get(language, center.lat, center.lng).then((data) => {
      dispatch(markersSlice.actions.updateStops({ data: data?.data }));
    });
    useGetVehicles({ locale: language }).then((data) => {
      dispatch(markersSlice.actions.updateVehicles(data?.data));
      renderGBFSVehiclesRegion(data.data);
    });
    useGetStations({ locale: language }).then((data) => {
      dispatch(markersSlice.actions.updateStations({ data: data?.data }));
      renderGBFSStationsRegion(data.data);
    });
    VM.lines.get(language);

    VM.pois.listen((e) => {
      renderPoisRegion(e);
    });
    VM.stops.listen((e) => {
      renderStopsRegion(e);
    });

    VM.plannerSegments.listen(() => onPlannerSegmentChange());
    VM.plannerConfig.listen(() => onPlannerSegmentChange());
    VM.plannerTime.listen(() => onPlannerSegmentChange());
    VM.planner.listen(() => onPlannerChange());
    VM.lines.listen(() => setLineSelected(VM.lines.selected));
    VM.screenSelected.listen(() =>
      setScreenSelected({ screen: VM.screenSelected.data, section: VM.screenSelected.section })
    );

    renderDirectionsRegion(favorites.directions);
  }, [language]);

  useEffect(() => {
    FilterUtils.filterByCategories(filters.categories);
    FilterUtils.filterByTransport(
      filters.modes,
      filters.operators,
      favorites.agencyId,
      activeFilterButton,
      filters.motorized
    );
    let stationsFiltered = FilterUtils.filterByTransportStations(
      markers.stations.noFiltered,
      filters.modes,
      filters.operators,
      favorites.agencyId,
      activeFilterButton
    );
    dispatch(markersSlice.actions.updateStations({ data: stationsFiltered, filter: true }));
  }, [filters, VM.stops.noFiltered, VM.pois.noFiltered]);

  useEffect(() => {
    VM.pois.unlisten((e) => {
      renderPoisRegion(e);
    });
    VM.stops.unlisten((e) => {
      renderPoisRegion(e);
    });
    VM.pois.listen((e) => {
      renderPoisRegion(e);
    });
    VM.stops.listen((e) => {
      renderStopsRegion(e);
    });
    renderStopsRegion(VM.stops.data);
    renderGBFSStationsRegion(markers.stations.filtered);
    renderPoisRegion(VM.pois.data);
    renderDirectionsRegion(favorites.directions);
  }, [screenSelected, favorites, markers.stations]);

  const getRenderPois = async () => {
    let result = [];
    let filteredPois = FilterUtils.generateClusterMarker(pois.concat(stops), map.getZoom());

    if (loadedPoiMarkers) {
      for (let poi in filteredPois) {
        if (!filteredPois[poi]?.points && filteredPois[poi]?.categoryId) {
          let icon = filteredPois[poi].favorite
            ? filteredPois[poi].id == props.markerSelected?.id
              ? (await VM.icons.getIcon(
                  filteredPois[poi].categoryId,
                  'category',
                  'iconSelectedFavId'
                )) ?? R.drawables.map.markers.pin_general
              : (await VM.icons.getIcon(
                  filteredPois[poi].categoryId,
                  'category',
                  'markFavoriteIconId'
                )) ?? R.drawables.map.markers.pin_general
            : filteredPois[poi].id == props.markerSelected?.id
            ? (await VM.icons.getIcon(
                filteredPois[poi].categoryId,
                'category',
                'iconSelectedId'
              )) ?? R.drawables.map.markers.pin_general
            : (await VM.icons.getIcon(filteredPois[poi].categoryId, 'category', 'markIconId')) ??
              R.drawables.map.markers.pin_general;
          let catName = '';
          if (VM.categories?.data) {
            const category = VM.categories?.data && VM.categories?.data[filteredPois[poi]?.categoryId];
            catName = category ? category?.name : '';
          }
          result.push(
            <LeafLetMarker
              key={'poi_' + filteredPois[poi].id}
              position={{
                lat: filteredPois[poi]?.latitudeNear ?? filteredPois[poi].latitude,
                lng: filteredPois[poi].longitudeNear ?? filteredPois[poi].longitude,
              }}
              title={filteredPois[poi]?.name === 'null' || !filteredPois[poi]?.name ? catName : filteredPois[poi]?.name}
              selected={filteredPois[poi].id == props.markerSelected?.id}
              description={filteredPois[poi].description}
              url={icon}
              x={filteredPois[poi].points ? null : 32}
              y={filteredPois[poi].points ? null : 38}
              onDragEnd={(e) => {}}
              onClick={() => props.onClickMarker(filteredPois[poi], map, 17.5)}></LeafLetMarker>
          );
        }
      }
    }

    return <div>{result}</div>;
  };

  const getRenderGBFSVehicles = async () => {
    let result = [];
    for (let vehicle in vehiclesGBFS) {
      let icon = vehiclesGBFS[vehicle].favorite
        ? vehiclesGBFS[vehicle].id == props.markerSelected?.id
          ? (await VM.icons.getIcon(
              vehiclesGBFS[vehicle].agencyOriginId,
              'dataOrigin',
              'iconMarkSelectedTransportModeFavoriteId'
            )) ?? R.drawables.map.markers.pin_general
          : (await VM.icons.getIcon(
              vehiclesGBFS[vehicle].agencyOriginId,
              'dataOrigin',
              'iconMarkFavTransportId'
            )) ?? R.drawables.map.markers.pin_general
        : vehiclesGBFS[vehicle].id == props.markerSelected?.id
        ? (await VM.icons.getIcon(
            vehiclesGBFS[vehicle].agencyOriginId,
            'dataOrigin',
            'iconMarkSelectedTransportModeId'
          )) ?? R.drawables.map.markers.pin_general
        : (await VM.icons.getIcon(
            vehiclesGBFS[vehicle].agencyOriginId,
            'dataOrigin',
            'iconMarkTransportId'
          )) ?? R.drawables.map.markers.pin_general;
      result.push(
        <LeafLetMarker
          key={vehiclesGBFS[vehicle].id}
          position={{
            lat: vehiclesGBFS[vehicle].latitude,
            lng: vehiclesGBFS[vehicle].longitude
          }}
          title={vehiclesGBFS[vehicle].name}
          description={vehiclesGBFS[vehicle].description}
          url={icon}
          x={32}
          y={38}
          onDragEnd={(e) => {}}
          onClick={() => props.onClickMarker(vehiclesGBFS[vehicle], map, 16.5)}></LeafLetMarker>
      );
    }
    return <div>{result}</div>;
  };

  const getRenderGBFSStations = async () => {
    let result = [];
    for (let station in stationsGBFS) {
      let icon = stationsGBFS[station].favorite
        ? stationsGBFS[station].id == props.markerSelected?.id
          ? (await VM.icons.getIcon(
              stationsGBFS[station].agencyOriginId,
              'dataOrigin',
              'iconMarkSelectedTransportModeFavoriteId'
            )) ?? R.drawables.map.markers.pin_general
          : (await VM.icons.getIcon(
              stationsGBFS[station].agencyOriginId,
              'dataOrigin',
              'iconMarkFavTransportId'
            )) ?? R.drawables.map.markers.pin_general
        : stationsGBFS[station].id == props.markerSelected?.id
        ? (await VM.icons.getIcon(
            stationsGBFS[station].agencyOriginId,
            'dataOrigin',
            'iconMarkSelectedTransportModeId'
          )) ?? R.drawables.map.markers.pin_general
        : (await VM.icons.getIcon(
            stationsGBFS[station].agencyOriginId,
            'dataOrigin',
            'iconMarkTransportId'
          )) ?? R.drawables.map.markers.pin_general;
      result.push(
        <LeafLetMarker
          key={stationsGBFS[station].id}
          position={{
            lat: stationsGBFS[station].latitude,
            lng: stationsGBFS[station].longitude
          }}
          title={stationsGBFS[station].name}
          description={stationsGBFS[station].description}
          url={icon}
          x={32}
          y={38}
          onDragEnd={(e) => {}}
          onClick={() => props.onClickMarker(stationsGBFS[station], map, 17.5)}></LeafLetMarker>
      );
    }
    return <div>{result}</div>;
  };

  const renderDirections = () => {
    let result = [];
    for (let direction in directions) {
      result.push(
        <LeafLetMarker
          key={directions[direction].id + directions[direction].direction}
          position={{
            lat: directions[direction].latitude,
            lng: directions[direction].longitude
          }}
          title={directions[direction].name}
          description={directions[direction].description}
          url={
            directions[direction].typeId == 1
              ? directions[direction]?.latitude == props.markerSelected?.latitude &&
                directions[direction]?.longitude == props.markerSelected?.longitude
                ? R.drawables.map.markers.DirectionMarkerSel
                : R.drawables.map.markers.DirectionMarker
              : directions[direction].typeId == 2
              ? directions[direction]?.latitude == props.markerSelected?.latitude &&
                directions[direction]?.longitude == props.markerSelected?.longitude
                ? R.drawables.map.markers.HomeMarkerSel
                : R.drawables.map.markers.HomeMarker
              : directions[direction]?.latitude == props.markerSelected?.latitude &&
                directions[direction]?.longitude == props.markerSelected?.longitude
              ? R.drawables.map.markers.WorkMarkerSel
              : R.drawables.map.markers.WorkMarker
          }
          x={30}
          y={45}
          onDragEnd={(e) => {}}
          onClick={() => props.onClickMarker(directions[direction], map, 17.5)}></LeafLetMarker>
      );
    }
    return <div>{result}</div>;
  };

  const getRenderContainers = async () => {
    let result = [];
    let filtered = FilterUtils.generateClusterMarker(loadedPoiMarkers ? pois.concat(stops) : stops, map.getZoom());

    for (let element in filtered) {
      if (filtered[element]?.points) {
        let thereStops = false;
        let therePois = false;
        for (let marker in filtered[element]?.points) {
          if (filtered[element]?.points[marker]?.agencyOriginId) thereStops = true;
          else therePois = true;
        }
        filtered[element].points.content =
          thereStops && therePois
            ? t('container')
            : thereStops
            ? t('stopContainer')
            : t('poiContainer');
      }
    }

    for (let element in filtered) {
      if (filtered[element]?.points) {
        result.push(
          <LeafLetMarker
            key={'container' + filtered[element].id}
            position={{
              lat: filtered[element].latitude,
              lng: filtered[element].longitude
            }}
            title={filtered[element]?.points?.content ?? filtered[element].name}
            description={filtered[element].description}
            url={
              filtered[element]?.points?.length > 7
                ? R.drawables.map.containers['more']
                : R.drawables.map.containers[filtered[element].points.length]
            }
            selected={filtered[element].id === props.markerSelected?.id}
            x={filtered[element].points ? null : 32}
            y={filtered[element].points ? null : 36}
            onDragEnd={(e) => {}}
            onClick={() => {
              props.onClickMarker(filtered[element], map, 17.5);
            }}></LeafLetMarker>
        );
      }
    }

    return <div>{result}</div>;
  };

  const getRenderStops = async () => {
    let result = [];
    let filteredStops = FilterUtils.generateClusterMarker(loadedPoiMarkers ? pois.concat(stops) : stops, map.getZoom());
    
    for (let stop in filteredStops) {
      if (!filteredStops[stop]?.points && filteredStops[stop]?.agencyOriginId) {
        let icon = filteredStops[stop].alert?.length
          ? filteredStops[stop].favorite
            ? filteredStops[stop].id === props.markerSelected?.id
              ? (await VM.icons.getIcon(
                  filteredStops[stop].agencyOriginId,
                  'dataOrigin',
                  'iconMarkSelectedTransportModeFavoriteAlertId',
                  filteredStops[stop]?.transportMode
                )) ?? R.drawables.map.markers.pin_general
              : (await VM.icons.getIcon(
                  filteredStops[stop].agencyOriginId,
                  'dataOrigin',
                  'iconMarkFavAlertId',
                  filteredStops[stop]?.transportMode
                )) ?? R.drawables.map.markers.pin_general
            : filteredStops[stop].id === props.markerSelected?.id
            ? (await VM.icons.getIcon(
                filteredStops[stop].agencyOriginId,
                'dataOrigin',
                'iconMarkSelectedTransportModeAlertId',
                filteredStops[stop]?.transportMode
              )) ?? R.drawables.map.markers.pin_general
            : (await VM.icons.getIcon(
                filteredStops[stop].agencyOriginId,
                'dataOrigin',
                'iconMarkAlertId'
              )) ?? R.drawables.map.markers.pin_general
          : filteredStops[stop].favorite
          ? filteredStops[stop].id === props.markerSelected?.id
            ? (await VM.icons.getIcon(
                filteredStops[stop].agencyOriginId,
                'dataOrigin',
                'iconMarkSelectedTransportModeFavoriteId',
                filteredStops[stop]?.transportMode
              )) ?? R.drawables.map.markers.pin_general
            : (await VM.icons.getIcon(
                filteredStops[stop].agencyOriginId,
                'dataOrigin',
                'iconMarkFavTransportId',
                filteredStops[stop]?.transportMode
              )) ?? R.drawables.map.markers.pin_general
          : filteredStops[stop].id === props.markerSelected?.id
          ? (await VM.icons.getIcon(
              filteredStops[stop].agencyOriginId,
              'dataOrigin',
              'iconMarkSelectedTransportModeId',
              filteredStops[stop]?.transportMode
            )) ?? R.drawables.map.markers.pin_general
          : (await VM.icons.getIcon(
              filteredStops[stop].agencyOriginId,
              'dataOrigin',
              'iconMarkTransportId',
              filteredStops[stop]?.transportMode
            )) ?? R.drawables.map.markers.pin_general;

        result.push(
          <LeafLetMarker
            key={'stop_' + filteredStops[stop].id}
            position={{
              lat: filteredStops[stop].latitudeNear,
              lng: filteredStops[stop].longitudeNear
            }}
            title={filteredStops[stop].name}
            description={filteredStops[stop].description}
            url={icon}
            selected={filteredStops[stop].id === props.markerSelected?.id}
            x={32}
            y={36}
            onDragEnd={(e) => {}}
            onClick={() => {
              props.onClickMarker(filteredStops[stop], map, 17.5);
            }}></LeafLetMarker>
        );
      }
    }

    return <div>{result}</div>;
  };

  const renderPolyline = () => {
    return (
      <div>
        <Polyline
              positions={lineSelected.coords}
              key={"blackLine"}
              color={'black'}
              zIndex={1}
              weight={8}
            />
        <Polyline
          key={lineSelected.coords}
          positions={lineSelected.coords}
          color={lineSelected.routeColor}
          weight={5}></Polyline>
      </div>
    );
  };

  const renderPlannerSegments = () => {
    let markers = [];
    let polyline = [];

    for (let intermediate in VM.plannerSegments.data) {
      let icon = intermediate;
      if (
        VM.plannerSegments.data[intermediate]?.location &&
        (intermediate == 'origin' || intermediate == 'destination')
      )
        icon = 'my_location';
      markers.push(
        <LeafLetMarker
          key={'intermediate_' + intermediate}
          draggable={true}
          onDragEnd={(e) => {
            VM.planner.clear();
            let coords = { latitude: e.target._latlng.lat, longitude: e.target._latlng.lng };
            let name = e.target._latlng.lat.toFixed(6) + ', ' + e.target._latlng.lng.toFixed(6);
            let info = RouteUtils.getTypeId(e, favorites, name);
            let res = { name: name, coords: coords, typeId: info.typeId, value: info.value };
            VM.plannerSegments.set(intermediate.toString(), res, true);
          }}
          url={R.drawables.map.markers[icon]}
          x={30}
          y={30}
          onClick={() => {}}
          position={{
            lat: parseFloat(VM.plannerSegments.data[intermediate]?.coords?.latitude),
            lng: parseFloat(VM.plannerSegments.data[intermediate]?.coords?.longitude)
          }}></LeafLetMarker>
      );
    }

    coords?.length !== 0
      ? coords?.map((feature, index) => {
        polyline.push(
            <Polyline
              positions={feature.coords}
              key={"c"+index + feature.color}
              dashArray={feature?.mode === 'WALK' ? '5, 20' : ''}
              color={'black'}
              zIndex={1}
              weight={11}
            />
          );
          polyline.push(
            <Polyline
              positions={feature.coords}
              key={index + feature.color}
              dashArray={feature?.mode === 'WALK' ? '5, 20' : ''}
              color={feature.color}
              zIndex={10}
              weight={8}
            />
          );
        })
      : polyline.push(
          <Polyline
            positions={PlanUtils.getPreviewCoords()}
            key={'d'}
            dashArray={'20 20'}
            color={theme.colors.darkGray}
            dashOffset={1}
          />
        );
    return (
      <>
        <Pane style={{ zIndex: 600 }} name={'markers'}>
          {markers}
        </Pane>
        <Pane style={{ zIndex: 200 }} name={'polyline'}>
          {polyline}
        </Pane>
      </>
    );
  };

  const renderMarkerSelected = () => {
    let res = [];
    if (props.markerSelected?.direction && !props.markerSelected?.alias)
      res.push(
        <LeafLetMarker
          key={'selected'}
          url={
            toggleVisibility.AddDirectionModalWidget.type === 3
              ? R.drawables.map.markers['WorkMarkerSel']
              : toggleVisibility.AddDirectionModalWidget.type === 2
              ? R.drawables.map.markers['HomeMarkerSel']
              : R.drawables.map.markers['DirectionMarkerSel']
          }
          x={30}
          y={45}
          onClick={() => {}}
          onDragEnd={(e) => {}}
          position={{
            lat: parseFloat(props.markerSelected.latitude),
            lng: parseFloat(props.markerSelected.longitude)
          }}></LeafLetMarker>
      );
    return (
      <Pane style={{ zIndex: 1000 }} name={'selectedPin'}>
        {res}
      </Pane>
    );
  };

  const renderIntermediates = () => {
    let result = [];
    if (coords?.length) {
      coords?.map((feature, index) => {
        for (let element in feature.intermediateStops) {
          result.push(
            <CircleMarker
              key={
                'circle_' +
                element +
                index +
                feature.intermediateStops[element].lat +
                feature.intermediateStops[element].lon
              }
              radius={5}
              center={RouteUtils.getClosestToStop(
                {
                  lat: feature.intermediateStops[element].lat,
                  lng: feature.intermediateStops[element].lon
                },
                feature.coords
              )}
              color={feature?.color === '#FFFFFF' ? '#000000' : feature?.color}
              fillColor={theme.colors.white}
              fillOpacity={1}
              eventHandlers={{
                mouseover: (event) => event.target.openPopup(),
                mouseout: (event) => event.target.closePopup()
              }}>
              <Tooltip direction="top" offset={[0, -10]}>
                {feature.intermediateStops[element].name}
              </Tooltip>
            </CircleMarker>
          );
        }
      });
    }

    return result;
  };
  const renderPlanStops = () => {
    let result = [];
    if (coords?.length) {
      coords?.map((feature, index) => {
        if (feature.mode != 'WALK') {
          if ( feature?.coords && feature?.coords?.length && (index - 1) >= 0 && index != 0 && coords[index - 1].mode == 'WALK')
          {
            result.push(
              <LeafLetMarker
                key={feature?.from?.lat + feature?.from?.lon + Math.random()}
                position={{
                  lat: feature?.coords[0]?.lat,
                  lng: feature?.coords[0]?.lng
                }}
                title={feature?.from?.name}
                url={feature?.icon}
                x={25}
                y={30}
              />
            );
          result.push(
            <LeafLetMarker
              key={feature?.to?.lat + feature?.to?.lon + Math.random()}
              position={{
                lat: feature?.coords[feature?.coords?.length - 1]?.lat,
                lng: feature?.coords[feature?.coords?.length - 1]?.lng
              }}
              title={feature?.to?.name}
              url={feature?.icon}
              x={25}
              y={30}
            />
          );
            }
        }
      });
    }

    return result;
  };

  const renderVehicles = () => {
    const selectedLineVehicles = vehicles.vehicles;
    const result = [];

    if (vehicles) {
      selectedLineVehicles.forEach((vehicle) => {
        result.push(
          <Marker
            key={vehicle.vehicleId}
            icon={iconVehicles}
            position={{
              lat: JSON.stringify(vehicle.lat),
              lng: JSON.stringify(vehicle.lon)
            }}></Marker>
        );
      });
      return <div>{result}</div>;
    }
  };

  const getRenderLineStops = async () => {
    let result = [];
    for (let stop in lineStopsList) {
      let transportMode = VM.stops?.noMap?.find(({ id }) => id == lineStopsList[stop].id);
      transportMode = transportMode?.transportMode;
      let icon = lineStopsList[stop].alert?.length
        ? lineStopsList[stop].favorite
          ? (await VM.icons.getIcon(
              lineStopsList[stop].agencyOriginId,
              'dataOrigin',
              'iconMarkFavAlertId',
              transportMode
            )) ?? R.drawables.map.markers.pin_general
          : (await VM.icons.getIcon(
              lineStopsList[stop].agencyOriginId,
              'dataOrigin',
              'iconMarkAlertId',
              transportMode
            )) ?? R.drawables.map.markers.pin_general
        : lineStopsList[stop].favorite
        ? (await VM.icons.getIcon(
            lineStopsList[stop].agencyOriginId,
            'dataOrigin',
            'iconMarkFavTransportId',
            transportMode
          )) ?? R.drawables.map.markers.pin_general
        : (await VM.icons.getIcon(
            lineStopsList[stop].agencyOriginId,
            'dataOrigin',
            'iconMarkTransportId',
            transportMode
          )) ?? R.drawables.map.markers.pin_general;

      result.push(
        <Marker
          icon={createIcon(icon)}
          key={lineStopsList[stop].id + Math.random()}
          position={{
            lat: JSON.stringify(lineStopsList[stop].latitude),
            lng: JSON.stringify(lineStopsList[stop].longitude)
          }}>
          {lineStopsList[stop].name ? (
            <Popup offset={[0, -20]} closeButton={false}>
              {lineStopsList[stop].name}
            </Popup>
          ) : null}

          {lineStopsList[stop].stopOrder === 1 ? (
            <Tooltip
              opacity={1}
              direction="top"
              offset={[0, -30]}
              position={{
                // lat: JSON.stringify(lineStopsList[0].latitude),
                lat: JSON.stringify(lineStopsList[stop].latitude),
                // lng: JSON.stringify(lineStopsList[0].longitude)
                lng: JSON.stringify(lineStopsList[stop].longitude)
              }}
              permanent>
              {t('start')}
              {lineStopsList[stop].name}
            </Tooltip>
          ) : null}

          {lineStopsList[stop].stopOrder === lineStopsList.length ? (
            <Tooltip
              opacity={1}
              direction="bottom"
              position={{
                lat: JSON.stringify(lineStopsList[lineStopsList.length - 1].latitude),
                lng: JSON.stringify(lineStopsList[lineStopsList.length - 1].longitude)
              }}
              permanent>
              {t('end')}
              {lineStopsList[lineStopsList.length - 1].name}
            </Tooltip>
          ) : null}
        </Marker>
      );
    }
    return <div>{result}</div>;
  };

  console.log('Screen', screenSelected?.screen);
  

  return (
    <LayerGroup>
      {!isPlan && props.markerSelected && renderMarkerSelected()}
      {!isPlan && !lineSelected && pois && screenSelected?.screen !== 'transit_news' && screenSelected?.screen !== 'news' && renderPois}
      {!isPlan && !lineSelected && stops && renderStops}
      {!isPlan && !lineSelected && pois && pois && renderContainers}
      {!isPlan && !lineSelected && vehiclesGBFS && renderGBFSVehicles}
      {!isPlan && !lineSelected && stationsGBFS && renderGBFSStations}
      {!isPlan && lineSelected && lineStopsList && renderLineStops}
      {!isPlan && !lineSelected && directions && renderDirections()}
      {!isPlan && lineSelected && renderPolyline()}
      {!isPlan && lineSelected && renderVehicles()}
      {VM.plannerSegments.data && renderIntermediates()}
      {VM.plannerSegments.data && renderPlanStops()}
      {VM.plannerSegments.data && renderPlannerSegments()}
    </LayerGroup>
  );
}
LeafLetMap = React.forwardRef(LeafLetMap);
