import { VM } from '../viewmodel/VM';
import TimeUtils from './TimeUtils';

export default class RouteUtils {

  static checkFavAgencyPlan(planFav) {
    let res = JSON.parse(JSON.stringify(planFav));
    res.plan.itineraries.forEach((itinerary) => {
      itinerary.favorite = true;
    })
    return res;
  }

  static joinPlans(first, second) {
    let res = JSON.parse(JSON.stringify(first));
    for (let iti in second?.plan?.itineraries) {
      res.plan.itineraries.push(second.plan.itineraries[iti])
    }
    return res;
  }

  static joinPlan(data) {
    let res = JSON.parse(JSON.stringify(data[0]));
    let add = [];

    //Se recorre el total de planificaciones y se añaden los itinerarios
    for (const planification of data) {
      if (planification?.plan?.itineraries?.length > add.length) {
        add = [];
        for (let z = 0; z < planification?.plan?.itineraries?.length; z++) {
          add.push(z);
        }
      }
    }
    //inicialización de indices de itinerarios a eliminar
    let indexesToDelete = [];
    for (let i = 1; i < data.length; i++) {
      for (let iti in data[0].plan.itineraries) {
        let selectedItinerary = data[i]?.plan?.itineraries[iti];
        let previousItinerary = data[i - 1]?.plan?.itineraries[iti];

        if (!res.plan.itineraries[iti] || !selectedItinerary || add.indexOf(parseInt(iti)) < 0) {
          selectedItinerary = data[i]?.plan?.itineraries[0];
          previousItinerary =
            data[i - 1]?.plan?.itineraries[iti] ?? data[i - 1]?.plan?.itineraries[0];
        }

        if (
          selectedItinerary?.startTime >= previousItinerary?.endTime &&
          this.checkIsBefore(previousItinerary, VM.plannerTime.data.times[i - 1])
        ) {
          let itineraryOfLastPlan =
            data[data.length - 1]?.plan?.itineraries[iti] ??
            data[data.length - 1]?.plan?.itineraries[0];
          let itineraryOfFirstPlan =
            data[0]?.plan?.itineraries[iti] ?? data[0]?.plan?.itineraries[0];
          let endTimeDate = new Date(itineraryOfLastPlan?.endTime);
          let startTimeDate = new Date(itineraryOfFirstPlan?.startTime);
          res.plan.itineraries[iti].duration = (endTimeDate - startTimeDate) / 1000;

          res.plan.itineraries[iti].endTime = selectedItinerary.endTime;
          res.plan.itineraries[iti].walkDistance += selectedItinerary.walkDistance;
          res.plan.itineraries[iti].walkTime += selectedItinerary.walkTime;
          for (let leg in selectedItinerary.legs) {
            res.plan.itineraries[iti].legs.push(
              JSON.parse(JSON.stringify(selectedItinerary.legs[leg]))
            );
          }
        } else {
          //se debería eliminar a partir del último
          //res.plan.itineraries.splice(parseInt(iti), 1);
          if (!indexesToDelete.find((indexToDelete) => indexToDelete === parseInt(iti))) {
            indexesToDelete.push(parseInt(iti));
          }
        }
      }
    }
    //eliminamos aquellos itinerarios que se hayan señalado como incorrectos
    this.cleanJoinPlan(res, indexesToDelete);
    return res;
  }

  static checkIsBefore(one, two) {
    return (
      TimeUtils.timeToMil(TimeUtils.convertTime12to24(two)) / 1000 >
      TimeUtils.timeToMil(TimeUtils.getTime(one.endTime)) / 1000
    );
  }

  static cleanJoinPlan(res, indexesToDelete) {
    indexesToDelete.sort(function (a, b) {
      return a - b;
    });

    for (let i = indexesToDelete.length - 1; i >= 0; i--) {
      res.plan.itineraries.splice(indexesToDelete[i], 1);
    }
    return res;
  }

  static isPublicTransportMode(mode) {
    if (
      mode === 'BUS' ||
      mode === 'SUBWAY' ||
      mode === 'TRANSIT' ||
      mode === 'RAIL' ||
      mode === 'SUBWAY' ||
      mode === 'TRAM' ||
      mode === 'FUNICULAR' ||
      mode === 'CABLE_CAR'
    ) {
      return true;
    } else {
      return false
    }
  }

  static async getItiCoords(data, position, locale) {
    let coords = [];
    let lastCoords;
    let polyline = require('@mapbox/polyline');
    for (let i = 0; i < data.plan.itineraries[position]?.legs.length; i++) {
      let arrayCoords = polyline.decode(data.plan.itineraries[position].legs[i].legGeometry.points);
      let jsonIn = {};
      let mode = data.plan.itineraries[position].legs[i].mode;
      jsonIn['mode'] = mode;
      if (this.isPublicTransportMode(mode)) {
        jsonIn['intermediateStops'] = data.plan.itineraries[position].legs[i].intermediateStops;
      }

      if(!Object.keys(VM.dataOrigin.data)?.length)
        await VM.dataOrigin.get(locale ?? 'en')

      if(!VM.stops.data?.length)
        await VM.stops.get(locale ?? 'en')

      let agencyId = data.plan.itineraries[position].legs[i]?.agencyId;
      let dataOri =  VM.dataOrigin.noGroup.find(({gtfsAgency})=> {
        return gtfsAgency?.find((gtfsAgen) => gtfsAgen.id === parseInt(agencyId)) !== undefined;
      });
      if(!dataOri?.agencyId)
        dataOri = data.plan.itineraries[position].legs[i]
      agencyId = dataOri?.id;
      let transportMode = VM.stops.noMap.find(({agencyOriginId, name})=> agencyId==agencyOriginId && data.plan.itineraries[position].legs[i]?.from?.name==name)
      transportMode=transportMode?.transportMode
      jsonIn['coords'] = this.parseCoords(arrayCoords);
      jsonIn['from'] = data.plan.itineraries[position].legs[i].from;
      jsonIn['to'] = data.plan.itineraries[position].legs[i].to;
      jsonIn['icon'] = await VM.icons.getIcon(agencyId, 'dataOrigin', 'iconMarkSelectedTransportModeId', transportMode);
      if (i !== 0 && Object.keys(VM.plannerSegments.data).length == 2)
        jsonIn['coords'].unshift(lastCoords);
      jsonIn['color'] = data.plan.itineraries[position].legs[i].routeColor
        ? '#' + data.plan.itineraries[position].legs[i].routeColor
        : '#0078B2';
      if (mode === 'WALK') {
        jsonIn['color'] =  localStorage.getItem('mapType')==='1' ? '#5269BA' : '#666666';
      }
      coords.push(jsonIn);
      lastCoords = jsonIn['coords'][jsonIn['coords'].length - 1];
    }

    return coords;
  }

  static getLength(data) {
    return data.plan.itineraries.length;
  }

  static async getRoutesInfo(data, allStops, dataOrigin) {
    let suggested = [];
    let otherSuggestions = [];
    let group = {};
    for (let i = 0; i < data.plan?.itineraries.length; i++) {
      let colors = [];
      let jsonIn = {};
      if (data.plan.itineraries[i].fare && data.plan.itineraries[i].fare.fare.regular.cents > 0)
        jsonIn['price'] = data.plan.itineraries[i].fare.fare.regular.cents / 100 + '€';
      else jsonIn['price'] = '- €';
      jsonIn['id'] = data.plan.itineraries[i]?.selection;
      jsonIn['lineSynoptic'] = [];

      jsonIn['startTime'] = TimeUtils.getTime(data.plan.itineraries[i].startTime);
      jsonIn['endTime'] = TimeUtils.getTime(data.plan.itineraries[i].endTime);
      jsonIn['walkTimeTrunc'] = 0.0; 
      jsonIn['duration'] = Math.round((data.plan.itineraries[i].duration / 3600) * 60);
      if (jsonIn['duration'] === 0) jsonIn['duration'] = 1;

      for (let j = 0; j < data.plan.itineraries[i].legs.length; j++) {
        if (data.plan.itineraries[i].legs[j].routeColor !== undefined) {
          colors.push('#' + data.plan.itineraries[i].legs[j].routeColor);
        }
        if (data.plan.itineraries[i].legs[j].mode === 'WALK') {
          colors.push('#9B9B9B');
          jsonIn['walkTimeTrunc'] += Math.ceil((data.plan.itineraries[i].legs[j].endTime - data.plan.itineraries[i].legs[j].startTime) / 60000)
        }
        if (data.plan.itineraries[i].legs[j].mode === 'BICYCLE') {
          colors.push('#27BBF5');
        }
        if (data.plan.itineraries[i].legs[j].mode === 'CAR') {
          colors.push('#f6ff00');
        }

        
        let from = data.plan.itineraries[i].legs[j].from;
        //asignar nombre municipio
        if (data.plan.itineraries[i].legs[j].from?.stopId) {
          if (data.plan.itineraries[i].legs[j].agencyId) {
            const originFrom = dataOrigin?.find((element) => {
              return element.gtfsAgency?.find((gtfsAgen) => gtfsAgen.id === parseInt(data.plan.itineraries[i].legs[j].agencyId)) !== undefined;
            });
            if (originFrom) {
              const foundStop = allStops.find((stop)=> originFrom?.id === stop?.agencyOriginId && data.plan.itineraries[i].legs[j]?.from?.name === stop?.name);
              if (foundStop) {
                from = foundStop?.stopMunicipality ? {...from, ...{name: `${from.name} (${foundStop?.stopMunicipality})`}} : from;
              }
            }
          } else if (j - 1 >= 0 && data.plan.itineraries[i].legs[j - 1]?.agencyId) {
            const originFrom = dataOrigin?.find((element) => {
              return element.gtfsAgency?.find((gtfsAgen) => gtfsAgen.id === parseInt(data.plan.itineraries[i].legs[j -1].agencyId)) !== undefined;
            });
            if (originFrom) {
              const foundStop = allStops.find((stop)=> originFrom?.id === stop?.agencyOriginId && data.plan.itineraries[i].legs[j]?.from?.name === stop?.name);
              if (foundStop) {
                from = foundStop?.stopMunicipality ? {...from, ...{name: `${from.name} (${foundStop?.stopMunicipality})`}} : from;
              }
            }
          }
          
        } 
        let to = data.plan.itineraries[i].legs[j].to;
        //asignar nombre municipio
        if (data.plan.itineraries[i].legs[j].to?.stopId && data.plan.itineraries[i].legs[j].agencyId) {
          const origin = dataOrigin?.find((element) => {
            return element.gtfsAgency?.find((gtfsAgen) => gtfsAgen.id === parseInt(data.plan.itineraries[i].legs[j].agencyId)) !== undefined;
          });
          if (origin) {
            const foundStop = allStops.find((stop)=> origin?.id === stop?.agencyOriginId && data.plan.itineraries[i].legs[j]?.to?.name === stop?.name);
            if (foundStop) {
              to = foundStop?.stopMunicipality ? {...to, ...{name: `${to.name} (${foundStop?.stopMunicipality})`}} : to;
            }
          }
        }

        let intermediateStops = data.plan.itineraries[i].legs[j].intermediateStops;

        if (data.plan.itineraries[i].legs[j].agencyId) {
          const origin = dataOrigin?.find((element) => {
            return element.gtfsAgency?.find((gtfsAgen) => gtfsAgen.id === parseInt(data.plan.itineraries[i].legs[j].agencyId)) !== undefined;
          });
          if (origin) {
            intermediateStops = intermediateStops.map((intermediate) => {
              const foundStop = allStops.find((stop)=> origin?.id === stop?.agencyOriginId && intermediate?.name === stop?.name);
              let intemediateStop = intermediate;
              if (foundStop) {
                intemediateStop = foundStop?.stopMunicipality ? {...intemediateStop, ...{name: `${intemediateStop.name} (${foundStop?.stopMunicipality})`}} : intemediateStop;
              }
              return intemediateStop;
            });
          }
        }

        let distance = Math.round(parseInt(data.plan.itineraries[i].legs[j].distance));
        let startTime = TimeUtils.getTime(data.plan.itineraries[i].legs[j].startTime);
        let endTime = TimeUtils.getTime(data.plan.itineraries[i].legs[j].endTime);

        let mode = data.plan.itineraries[i].legs[j].mode;
        let routeColor = data.plan.itineraries[i].legs[j].routeColor ?? '0078B2';
        let routeTextColor = data.plan.itineraries[i].legs[j].routeTextColor ?? 'black';
        let tripShortName =
          data.plan.itineraries[i].legs[j].route ??
          data.plan.itineraries[i].legs[j].routeShortName ??
          '??';
        let direction = data.plan.itineraries[i].legs[j].tripShortName;
        let headSign = data.plan.itineraries[i].legs[j].headsign;
        let time =
          data.plan.itineraries[i].legs[j].endTime - data.plan.itineraries[i].legs[j].startTime;

        if(!Object.keys(VM.dataOrigin.data).length)
          await VM.dataOrigin.get('en')

        let icon = '';
        let agencyId = data.plan.itineraries[i].legs[j]?.agencyId;

        if (mode === 'WALK') {
          jsonIn['lineSynoptic'].push({
            mode: mode,
            time: Math.ceil(time / 60000),
            from: from,
            to: to,
            distance: distance + ' m',
            startTime: startTime,
            endTime: endTime
          });
        } else {
        let dataOrigin = VM.dataOrigin.noGroup.find((element) => {
          return element.gtfsAgency.find(
            (agency) => parseInt(agency.id) === parseInt(agencyId),
          );
        });

        let agency = dataOrigin?.gtfsAgency?.find(
          (element) => parseInt(element.id) === parseInt(agencyId),
        );

        if (dataOrigin?.agencyId && agency) {
          icon = await VM.icons.getIcon(agency?.iconId);
        }


          jsonIn['lineSynoptic'].push({
            mode: mode,
            time: Math.ceil(time / 60000),
            routeColor: '#' + routeColor,
            routeTextColor: '#' + routeTextColor,
            tripShortName: tripShortName,
            icon: icon,
            agencyId: agencyId,
            telf: agency?.phone,
            web: agency?.url,
            fareUrl: agency?.fareUrl,
            reserva_message: agency?.message,
            reserva_link: agency?.link,
            intermediateStops: intermediateStops,
            headSign: headSign,
            from: from,
            to: to,
            name: agency?.name,
            distance: distance + ' m',
            startTime: startTime,
            endTime: endTime,
            direction: direction
          });
        }
      }

      jsonIn['colors'] = colors;

      if (i === 0) suggested.push(jsonIn);
      else otherSuggestions.push(jsonIn);
    }
    group['suggested'] = suggested;
    group['otherSuggestions'] = otherSuggestions;
    return group;
  }

  static getCircleZoom(zoom) {
    switch (zoom) {
      case 8:
        return 500;
      case 9:
        return 500;
      case 10:
        return 400;
      case 11:
        return 300;
      case 12:
        return 200;
      case 13:
        return 100;
      case 14:
        return 50;
      case 15:
        return 25;
      case 16:
        return 10;
      case 17:
        return 5;
      default:
        return 5;
    }
  }

  static parseCoords(coords) {
    let parsedCoords = [];
    for (let i = 0; i < coords.length; i++) {
      parsedCoords.push({
        lat: coords[i][0],
        lng: coords[i][1]
      });
    }

    return parsedCoords;
  }

  static getWalkItineraries(data) {
    let results = JSON.parse(JSON.stringify(data));
    results.plan.itineraries = [];
    for (let i = 0; i < data.plan.itineraries.length; i++) {
      data.plan.itineraries[i].selection=i;
      let isWalk = true;
      let j = 0;
      while (j < data.plan.itineraries[i].legs.length && isWalk) {
        isWalk = data.plan.itineraries[i].legs[j].mode === 'WALK';
        j++;
      }
      if (isWalk) results.plan.itineraries.push(data.plan.itineraries[i]);
    }
    return results;
  }

  static getNoWalkItineraries(data) {
    if(Object.keys(data).length) {
      let results = JSON.parse(JSON.stringify(data));
      results.plan.itineraries = [];
      for (let i = 0; i < data.plan.itineraries.length; i++) {
        data.plan.itineraries[i].selection=i;
        let isWalk = true;
        let j = 0;
        while (j < data.plan.itineraries[i].legs.length && isWalk) {
          isWalk = data.plan.itineraries[i].legs[j].mode === 'WALK';
          if (data.plan.itineraries[i]?.favorite) {
            isWalk = true;
          }
          j++;
        }
        if (!isWalk) results.plan.itineraries.push(data.plan.itineraries[i]);
      }
      return results;
    }
    else return []
  }

  static getTypeId(point, favorites, name) {
    let typeId;
    let value = '';
    if (point?.agencyOriginId) {
      typeId = 0;
      value = point?.id;
    } else if (point?.poiOriginId) {
      typeId = 1;
      value = point?.id;
    } else {
      typeId = 3;
      value = name ?? point?.alias ?? `${point?.name}. ${point?.direction}`;
      for (let direction in favorites.directions) {
        if (
          favorites.directions[direction].latitude == point?.latitude &&
          favorites.directions[direction].longitude == point?.longitude
        ) {
          typeId = 2;
          value = favorites.directions[direction].id;
        }
      }
    }
    return { value: value, typeId: typeId };
  }

  static getModeItineraries(data, mode) {
    let results = JSON.parse(JSON.stringify(data));
    results.plan.itineraries = [];
    for (let i = 0; i < data.plan.itineraries.length; i++) {
      let foundIsMode = false;
      let j = 0;
      while (j < data.plan.itineraries[i].legs.length && !foundIsMode) {
        if (!foundIsMode) foundIsMode = data.plan.itineraries[i].legs[j].mode === mode;
        j++;
      }
      if (foundIsMode) results.plan.itineraries.push(data.plan.itineraries[i]);
    }
    return results;
  }

  static getFavoriteItineraries(data, favs) {
    let results = JSON.parse(JSON.stringify(data));
    results.plan.itineraries = [];
    for (let i = 0; i < data.plan.itineraries.length; i++) {
      data.plan.itineraries[i].selection=i;
      let foundMode = false;
      let j = 0;
      foundMode = data.plan.itineraries[i]?.favorite;
      if (foundMode) results.plan.itineraries.push(data.plan.itineraries[i]);
    }
    return results;
  }

  static getClosestToStop(position, coords) {
    let closest = coords[0];
    let shortestDistance = this.getDistSquared(position, coords[0]);
    for (let i = 0; i < coords.length; i++) {
      let d = this.getDistSquared(position, coords[i]);
      if (d < shortestDistance) {
        closest = coords[i];
        shortestDistance = d;
      }
    }
    return closest;
  }

  static getDistSquared(point1, point2) {
    let differenceX = point1.lat - point2.lat;
    let differenceY = point1.lng - point2.lng;
    return differenceX * differenceX + differenceY * differenceY;
  }

  static sortRoutes(data, key, order) {
    const changeFormatData = JSON.parse(JSON.stringify(data));
    if (key === 'walkTimeTrunc') {
      changeFormatData.plan.itineraries = changeFormatData.plan.itineraries.map((itinerary) => {
        let walkTimeTrunc = 0.0;
        for (const leg of itinerary.legs) {
          if (leg?.mode === 'WALK') {
            walkTimeTrunc += Math.ceil((leg.endTime - leg.startTime) / 60000)
          }
        } 
        return {...itinerary, ...{walkTimeTrunc: walkTimeTrunc}};
      });
    }
    return changeFormatData.plan.itineraries.sort(function (a, b) {
      let x = a[key],
        y = b[key];
      switch (key) {
        case 'fare':
          if (a[key]) {
            x = a[key].fare.regular.cents;
          } else {
            x = 0;
          }

          if (b[key]) {
            y = b[key].fare.regular.cents;
          } else {
            y = 0;
          }
          break;
        case 'duration':
          if (a['endTime']) {
            x = a['endTime'];
          } else {
            x = 0;
          }

          if (b['endTime']) {
            y = b['endTime'];
          } else {
            y = 0;
          }
          break;
        case 'walkTimeTrunc':
          if (a[key]) {
            x = a[key];
          } else {
            x = 0;
          }

          if (b[key]) {
            y = b[key];
          } else {
            y = 0;
          }
          break;
        default:
          break;
      }

      if (order === 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      } else if (order === 'desc') {
        return x > y ? -1 : x < y ? 1 : 0;
      } else {
        if (order === 'asc') {
          return x < y ? -1 : x > y ? 1 : 0;
        } else if (order === 'desc') {
          return x > y ? -1 : x < y ? 1 : 0;
        }
      }
      return data;
    });
  }
}
