export default function LinkCommonComponent(props: any) {
  const styles = {
    p: {
      color: '#FFFFFF',
      fontFamily: 'Open Sans',
      fontSize: 12
    },
    link: {
      color: '#FFFFFF',
      textDecoration: 'none',
      fontWeight: '600'
    }
  } as const;

  return (
    <div className="link-common-container">
      <a
        onClick={() => props.onClick()}>
        {props.title ? props.title : 'Common'}
      </a>
    </div>
  );
}
