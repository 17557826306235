import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { favoriteServiceApi } from '../../../../redux/login/favorite/FavoriteServiceApi';
import { authServiceApi } from '../../../../redux/login/services/authServiceApi';
import { accountInformation } from '../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { favoritesSlice } from '../../../../redux/slices/favoritesSlice';
import { RootState } from '../../../../redux/store';
import { useSetLanguage, useTranslate } from '../../../context/languageContext';
import { useSetLoading } from '../../../context/loadingContext';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import ButtonNext from '../../commons/buttons/ButtonNext';
import SimpleModal from '../../commons/Modals/SimpleModal';
import TitleH1 from '../../commons/Titles/TitleH1';
import InputEmail from '../../inputs/InputEmail';
import InputPassword from '../../inputs/InputPassword';
import LinkForgotPassword from '../../links/LinkForgotPassword';
import LinkSingUp from '../../links/LinkSingUp';
import RecoveryPassModalWidget from './RecoveryPassModalWidget';
import SingUpModalWidget from './SingUpModalWidget';

export default function LoginModalWidget(props: any) {
  const account = useSelector(accountInformation);
  const theme = useTheme();
  const Close = R.drawables.commons.close;
  const t = useTranslate();

  const styles = {
    container: {
      backgroundColor: '#005891',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '65%'
    },
    close: {
      position: 'absolute',
      right: '4%',
      top: '4%',
      cursor: 'pointer',
      height: 10.65,
      width: 10.71
    },
    header: {
      marginTop: '7.2%',
      paddingTop: '4.4%',
      paddingBottom: '4.4%',
      color: '#fff',
      width: '100%',
      textAlign: 'left'
    },
    title: {
      margin: 0,
      fontSize: 22
    },
    body: {
      width: '100%'
    },
    form: {
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      textAlign: 'left',
      color: '#FFFFFF',
      paddingLeft: '20px',
      display: 'flex',
      justifyContent: 'flex-start'
    },
    footer: {
      width: '100%',
      marginBottom: '10.9%',
      marginTop: '1.5%'
    },
    overlay: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10,
      backgroundColor: '#181B1D',
      opacity: 0.2
    }
  } as const;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const setLoading = useSetLoading();
  const dispatch = useDispatch();
  const toggleVisibility = useSelector(
    (state: RootState) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );

  const [useLoginQuery, result, lastPromiseInfo] = authServiceApi.endpoints.login.useLazyQuery();
  const [useGetFavoritesQuery, result1, lastPromiseInfo1] =
    favoriteServiceApi.endpoints.getFavorites.useLazyQuery();
  const setLanguage = useSetLanguage();

  async function setLogin() {
    await useLoginQuery({
      email: email,
      password: password,
      typeDeviceId: process.env.REACT_APP_typeDeviceId,
      tokenDevice: account.tokenDevice
    }).then(async (data: any) => {
      if (data && !data.error) {
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#00763D',
            text: t('loginSuccess')
          })
        );
      } else if (data.error.status == 401 || data.error.status == 403) {
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#A31610',
            text: t('reviewLoginOrPass'),
            status: 'error'
          })
        );
      } else {
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#A31610',
            text: t('serviceError'),
            status: 'error'
          })
        );
      }
      useGetFavoritesQuery().then((data) => {
        dispatch(favoritesSlice.actions.updateFavorites(data.data));
      });
      if (data?.data?.languageLocale) setLanguage(data.data.languageLocale);
    });

    setLoading(false);
  }

  return (
    <>
      <div
        style={styles.overlay}
        onClick={() =>
          dispatch(
            eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(false)
          )
        }
      />
      <SimpleModal
        style={styles.container}
        header={
          <div style={styles.header}>
            <Close
              style={styles.close}
              fill={theme.colors.white}
              onClick={() =>
                dispatch(
                  eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(false)
                )
              }
            />
            <TitleH1 style={styles.title} title={t('youAreBack')} />
          </div>
        }
        body={
          <div style={styles.body}>
            <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
              <InputEmail email={email} setEmail={setEmail} className={'modal-input'} />
              <InputPassword password={password} setPassword={setPassword} />
              {/* <label
                style={styles.label}
                className={`warning ${result.isError ? '' : 'visibilityHidden'}`}>
                {t('errorLogin')}
              </label> */}
              <div style={{ paddingTop: 25, paddingBottom: 62, textAlign: 'end' }}>
                <LinkForgotPassword />
              </div>
              <ButtonNext
                disabled={email.length >= 1 && password.length >= 1 ? false : true}
                dispatch={setLogin}
              />
            </form>
          </div>
        }
        footer={<LinkSingUp style={styles.footer} />}
      />

      {toggleVisibility.RecoveryPassModalWidget ? <RecoveryPassModalWidget /> : ''}

      {toggleVisibility.SingUpModalWidget ? <SingUpModalWidget /> : ''}
    </>
  );
}
