import ViewModel from '../@base/ViewModel';
import TimeUtils from "../../utils/TimeUtils";

export default class PlannerTimeViewModel extends ViewModel {
    constructor() {
        super();
        this.data= {
            time: TimeUtils.getTimePM_AM(TimeUtils.getTime(Date.now())),
            date:TimeUtils.getDate(Date.now()),
            times: {},
            arriveBy:false,
            exitNow:true,
        }
    }

    setTime(time, calculated){
        if(!calculated)
            this.data.time=TimeUtils.getTimePM_AM(time);
        else this.data.time=time
        this.notify(this.data)
    }

    clearTimes(){
        this.data.times={}
        this.notify(this.data)
    }

    getTimes(){
        let res = ''
        for(let time in this.data.times) {
            if(!this.data.times[time].length)
                return ''
            res += this.data.times[time] + ','
        }
        if(res.length)
            res=res.substring(0, res.length - 1);
        return res
    }

    setDate(date){
        this.data.date=date;
        this.notify(this.data)
    }

    setArriveBy(bool){
        this.data.arriveBy=bool;
        this.notify(this.data)
    }

    setExitNow(bool){
        this.data.exitNow=bool;
        this.notify(this.data)
    }

    setTimes(t, index){
        this.data.times[index]=t.length ? TimeUtils.getTimePM_AM(t) : ''
        this.notify(this.data)
    }

    deleteTimes(index){
        if(this.data.times[index.toString()]!==undefined) {
            delete this.data.times[index.toString()]
            this.notify(this.data)

        }
    }

}
