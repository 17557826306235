import { useDispatch } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useTranslate } from '../../../context/languageContext';

import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import ButtonNext from '../../commons/buttons/ButtonNext';
import SimpleModal from '../../commons/Modals/SimpleModal';
import TitleH1 from '../../commons/Titles/TitleH1';

export default function AlertToSaveModalWidget(props: any) {
  const theme = useTheme();
  const t = useTranslate();
  const Close = R.drawables.commons.close;
  const styles = {
    h1: {
      color: '#1C1F20',
      fontFamily: 'Open Sans',
      marginRight: 'auto',
      fontWeight: '700',
      fontSize: '22px'
    },

    div: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly'
    },
    close: {
      marginLeft: 'auto',
      marginRight: '20px',
      marginTop: '20px',
      position: 'absolute',
      right: 0,
      top: 0
    },
    p: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: ' 140%',
      color: ' #61686B'
    },
    label: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: ' 150%',
      display: 'flex',
      alignItems: ' flex-end',
      color: '#A4A8AA'
    }
  } as const;

  const dispatch = useDispatch();

  return (
    <SimpleModal
      background="#fff"
      style={{
        maxWidth: '575px',
        minWidth: '576px',
        minHeight: '52%'
      }}
      header={
        <>
          <Close
            style={styles.close}
            fill={theme.colors.black}
            onClick={() =>
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAlertToSaveModalWidget()
              )
            }
          />
          <TitleH1 title={t('loginToFavs')} color="#1C1F20" />
          <p style={styles.p}>{t('loginToFavBody')}</p>
        </>
      }
      body={
        <div style={styles.div}>
          <ButtonNext
            className="Botonera_dialogue bg-white"
            title={t('createAccount')}
            dispatch={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilitySingUpModalWidget(true)
              );
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAlertToSaveModalWidget()
              );
            }}
          />

          <ButtonNext
            className="Botonera_dialogue"
            title={t('login')}
            dispatch={() => {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoginModalWidget(true)
              );
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAlertToSaveModalWidget()
              );
            }}
          />
        </div>
      }
    />
  );
}
