import { Apis } from '../../../domain/Apis';
import { VM } from '../../../viewmodel/VM';
import { R } from '../../R';
import LoginScreen from '../LoginScreen/LoginScreen';
import ActivateAccountScreen from './ActivateAccountScreen';

function AppInitActiveAccount() {
  R.initialize();
  Apis.initialize();
  VM.initialize();
  return (
    <div className="App">
      <ActivateAccountScreen />
      <LoginScreen />
    </div>
  );
}

export default AppInitActiveAccount;
