import { useDispatch, useSelector } from 'react-redux';
import { accountInformation } from '../../../../redux/slices/authSlice';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { RootState } from '../../../../redux/store';
import { useTranslate } from '../../../context/languageContext';
import { R } from '../../../R';
import CommentStateScreen from './CommentStateScreen';
import SuggestionsScreen from './SuggestionsScreen';

export default function SupportScreen(props: any) {
  const account = useSelector(accountInformation);
  const dispatch = useDispatch();
  const toggleVisibility = useSelector(
    (state: RootState) => state.eventsModalstoggleVisibilitySlice.ModalWidget
  );

  const t = useTranslate();

  const Ic_Arrow = R.drawables.general.Ic_ChevronRightSmall;
  const Ic_Comments = R.drawables.general.Ic_Chat1;
  const Ic_Suggestion = R.drawables.general.Ic_Mensajes;

  const goToSuggestionScreen = () => {
    props.setChild(<SuggestionsScreen setChild={props.setChild} />);
  };

  const goToCommentStateScreen = () => {
    props.setChild(<CommentStateScreen />);
  };

  const openAccessDeniedModal = () => {
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityAnonymousAccessDeniedModalWidget(
        true
      )
    );
  };
  return (
    <div className="account-child content">
      <div className="titleAccount">{t('support')}</div>
      <>
        <div className="">
          <div
            className="content-support"
            onClick={account.idUserType === 2 ? goToSuggestionScreen : openAccessDeniedModal}>
            <Ic_Suggestion className={'icon'} style={{ marginRight: 10 }} />
            <div className={'subtitle'}>{t('leaveSuggestion')}</div>
            <Ic_Arrow style={{ marginLeft: 'auto' }} onClick={() => {}} />
          </div>

          <div
            className="content-support"
            onClick={account.idUserType === 2 ? goToCommentStateScreen : openAccessDeniedModal}>
            <div style={{ width: '100%' }}>
              <div className={'row'}>
                <Ic_Comments className={'icon'} style={{ marginRight: 10 }} />
                <div className={'subtitle'}>{t('messageState')}</div>
                <Ic_Arrow
                  style={{ marginLeft: 'auto', justifyContent: 'right' }}
                  onClick={() => {}}
                />
              </div>
              <div className={'description'}>{t('commentStateNotice')}</div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
