import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from '../../store';
import GeneralUtils from "../../../utils/GeneralUtils";

export const GbfsServiceApi = createApi({
    reducerPath: 'gbfsServiceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: GeneralUtils.getEnvironment(window.location.origin),
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).authSlice.accountInformation.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: builder => ({
        getStationById: builder.mutation<any, any>({
            query: ({id, locale}) => ({
                url: `stations/${id}?locale=${locale}`,
                method: 'GET',
            }),
            async onQueryStarted(id, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;
                } catch (err) {}
            },
        }),
        getStations: builder.mutation<any, any>({
            query: ({locale}) => ({
                url: `stations/?locale=${locale}`,
                method: 'GET',
            }),
            async onQueryStarted(id, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;
                } catch (err) {}
            },
        }),
        getVehicles: builder.mutation<any, any>({
            query: ({locale}) => ({
                url: `vehicles/?locale=${locale}`,
                method: 'GET',
            }),
            async onQueryStarted(id, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;
                } catch (err) {}
            },
        }),
        getVehicleById: builder.mutation<any, any>({
            query: ({id, locale}) => ({
                url: `vehicles/${id}?locale=${locale}`,
                method: 'GET',
            }),
            async onQueryStarted(id, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;
                } catch (err) {}
            },
        }),
    }),
});

export const {
    useGetStationByIdMutation,
    useGetStationsMutation,
    useGetVehiclesMutation,
    useGetVehicleByIdMutation,
} = GbfsServiceApi;
