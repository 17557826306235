import { useEffect, useState } from 'react';
import { VM } from '../../../../../../../../viewmodel/VM';
import RadioButton from '../../../../../../../components/commons/RadioButton';
import Switch from '../../../../../../../components/commons/Switch';
import { useTranslate } from '../../../../../../../context/languageContext';
import { useTheme } from '../../../../../../../context/themeContext';
import { R } from '../../../../../../../R';
import TransportsFilters from "./TransportsFilters";
import MaxWalkDistanceFilter from './MaxWalkDistanceFilter';

export default function Preferences(props, ref) {
  const theme = useTheme();
  const t = useTranslate();
  const WheelChair = R.drawables.general.Ic_silladeruedas;

  const [routeType, setRouteType] = useState(0);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (routeType === 1) {
      VM.plannerConfig.setOrder('fare');
    } else if (routeType === 0) {
      VM.plannerConfig.setOrder('duration');
    } else {
      VM.plannerConfig.setOrder('walkTimeTrunc');
    }
  }, [routeType]);

  const styles = {
    content: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingInline: 22,
      alignItems: 'center',
      maxHeight: window.innerHeight - 300,
      overflow: 'scroll'
    },
    icon: {
      fill: theme.colors.black,
      width: 20,
      marginRight: 5,
      marginTop: 5
    },
    mainText: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '240%',
      textAlign: 'left'
    },
    subText: {
      fontSize: 14,
      lineHeight: '240%',
      paddingInline: 12,
      textAlign: 'left'
    },
    preference: {
      display: 'flex',
      width: '100%',
      alignItems: 'center'
    }
  };

  return (
    <div style={{ ...props.style, ...styles.content }}>
      <div style={styles.preference}>
        <WheelChair style={styles.icon} />
        <div style={styles.mainText}>{t('accessible_routes')}</div>
        <Switch
          onSwitch={(bool) => VM.plannerConfig.setAccessible(bool)}
          style={{ marginLeft: 'auto' }}
        />
      </div>

      {/* <FavoritesFilter opened={selected===0} setOpened={()=>{
                if(selected===0)
                    setSelected(null)
                else setSelected(0)
            }}/> */}

      <div style={styles.mainText}>{t('route_types')}</div>
      <div style={styles.preference}>
        <div style={styles.subText}>{t('fast_routes')}</div>
        <RadioButton
          selected={routeType === 0}
          onClick={() => setRouteType(0)}
          style={{ marginLeft: 'auto' }}
        />
      </div>
      <div style={styles.preference}>
        <div style={styles.subText}>{t('eco_routes')}</div>
        <RadioButton
          selected={routeType === 1}
          onClick={() => setRouteType(1)}
          style={{ marginLeft: 'auto' }}
        />
      </div>

      <div style={styles.preference}>
        <div style={styles.subText}>{t('walk_routes')}</div>
        <RadioButton
          selected={routeType === 2}
          onClick={() => setRouteType(2)}
          style={{ marginLeft: 'auto' }}
        />
      </div>

            <TransportsFilters opened={selected===1} setOpened={()=>{
                if(selected===1)
                    setSelected(null)
                else setSelected(1)
            }}/>
      
      <MaxWalkDistanceFilter/>

    </div>
  );
}
