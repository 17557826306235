import React from 'react';
import { Apis } from '../../../domain/Apis';
import FilterUtils from '../../../utils/FilterUtils';
import ViewModel from '../../@base/ViewModel';
import {VM} from "../../VM";

export default class OperatorsFiltersViewModel extends ViewModel {

  async getOperators(locale) {
    let modes = await Apis.transportModes.getAllTransportModes(locale).catch(e=>null);
    let dataOrigin = await Apis.agency.getAllDataOrigin(locale).catch(e=>null);
    let agencies = await Apis.agency.getAllAgencies(locale).catch(e=>null);
    let operators = null;
    if (modes && dataOrigin && agencies) {
      const mapModes = FilterUtils.getMap(modes);
      for (let i = 0; i < modes.length; i++) {
        let modeChanged = modes[i];
        let agenciesMode = [];
        agenciesMode.push({
          id: 0,
          mode: modeChanged.label,
          modeId: modeChanged.id,
          selected: false,
          icon: modeChanged.iconId,
          shortName: modeChanged.label,
        })
        for (let j = 0; j < dataOrigin.length; j++) {
          let index = dataOrigin[j].transportModes.findIndex(
            mode => mode.transportModeId === modes[i].id,
          );
          
          let agency = agencies.find((element) => element.id === dataOrigin[j]?.agencyId);
          if (index !== -1 && agency) {
            agency['selected'] = false;
            agency['mode'] = modes[i].label;
            agency['modeId'] = modes[i].id;
            agenciesMode.push(agency);
          }
        }
        let mapAgencies = FilterUtils.getMap(agenciesMode)
        modeChanged['agencies'] = mapAgencies;
        mapModes.set(modes[i].id, modeChanged);
      }
      operators = mapModes;
    }
    return operators;
  }

  async get(locale) {
    let merged = null;
    let agencies = await this.getOperators(locale);

    /* Se obtiene de la cache */
    const stored = localStorage.getItem('operatorsFilters');
    /* Si se puede comparar ambos se compara */
    if (stored && agencies) {
      const filtersChanged = FilterUtils.compareAgenciesFilters(JSON.parse(stored), Array.from(agencies.values()));
      /* Se recorre el valor obtenido de la store para transformarlo en un hash map */
      filtersChanged.forEach(element => {
        element.agencies = FilterUtils.getMap(element.agencies);
      });
      merged = FilterUtils.getMap(filtersChanged);
    } else if (agencies) {
      merged = agencies;
    } else if (stored) {
      merged = JSON.parse(stored);
      merged = FilterUtils.getMap(merged);
    }
    if (merged) this.set(merged)
    return super.notify({agencies: merged})
  }

  set(values) {
    const valuesOfMap = Array.from(values.values());
    valuesOfMap.forEach(mode => {
      mode.agencies = Array.from(mode.agencies.values())
    });
    localStorage.setItem('operatorsFilters', JSON.stringify(valuesOfMap));
    //FilterUtils.filterByTransport(Object.fromEntries(VM.modes.data.modes),Object.fromEntries(values))
    return super.notify({agencies: values});
  }

}
