import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';

export interface subscriptionsInformation {
    stops: Array<any>;
    lines: Array<any>;
    agencyNotices: Array<any>;
}

const initialState = {
    subscriptionsInformation: {
        stops: [],
        lines: [],
        agencyNotices: [],
    },
};

export const subscriptionsSlice = createSlice({
    name: 'subscriptionsSlice',
    initialState,
    reducers: {
        updateSubscriptions: (state, action) => {
            state.subscriptionsInformation = action.payload;
        },
        resetSubscriptions: (state) => {
            state.subscriptionsInformation = {
                stops: [],
                lines: [],
                agencyNotices: [],
            }
        },
    },
});

export const subscriptionsInformation = (state: RootState) => state.subscriptionsSlice.subscriptionsInformation;
