import { useState } from 'react';
import { usePostMessagesMutation } from '../../../../redux/login/services/MessagesServiceApi';
import { useTranslate } from '../../../context/languageContext';
import SupportScreen from './SupportScreen';
import AddFileSelector from './components/AddFileSelector';
import FileEntrySending from './components/FileEntrySending';
import { useDownloadFiles } from '../../../../utils/FileUtils';

export default function SuggestionsScreen(props: any) {
  const t = useTranslate();
  const [comment, setComment] = useState('');
  const [title, setTitle] = useState('');
  const [selected, setSelected] = useState(false);
  const [PostMessages] = usePostMessagesMutation();
  const [countSymbols, setCountSymbols] = useState(0);
  const [countSymbolsTitle, setCountSymbolsTitle] = useState(0);
  const [fileType, setFileType] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileInputData, setFileInputData] = useState<any>();
  const [fileUploadData, setFileUploadData] = useState<any>();
  const { uploadFile } = useDownloadFiles();

  const [suggestion, setSuggestion] = useState({
    12: false,
    13: false,
    14: false,
    15: false,
    16: false,
    17: false
  });

  const onChangeCheck = (e: any) => {
    const { name, checked } = e.target;
    setSelected(false);
    setSuggestion({
      12: false,
      13: false,
      14: false,
      15: false,
      16: false,
      17: false
    });
    setSuggestion((state) => ({
      ...state,
      [name]: checked
    }));
    setSelected(checked);
  };

  const onChangeComment = (e: any) => {
    setComment(e.target.value);
    setCountSymbols(e.target.value.length);
  };

  const onChangeTitle = (e: any) => {
    setTitle(e.target.value);
    setCountSymbolsTitle(e.target.value.length);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    let categoryId = '';
    Object.entries(suggestion).forEach((el) => {
      if (el[1] === true) {
        return (categoryId = el[0]);
      }
    });
    const locale = localStorage.getItem('language');
    if (categoryId !== '') {
      let fileId: any = null;
      if (fileUploadData) {
        fileId = await uploadFile(fileUploadData);
        if (fileId?.data) {
          fileId = fileId?.data;
        }
      }
      PostMessages({
        categoryId: categoryId,
        body: comment,
        title: title,
        locale: locale,
        fileId: fileId
      })
        .unwrap()
        .then((payload) => {
          onCancel();
          props.setChild(<SupportScreen setChild={props.setChild} />);
        })
        .catch((error) => console.error('rejected', error));
    }
  };

  const onCancel = () => {
    setSelected(false);
    setComment('');
    setSuggestion({
      12: false,
      13: false,
      14: false,
      15: false,
      16: false,
      17: false
    });
  };

  return (
    <div className={'account-child content'}>
      <form className="content" onSubmit={onSubmit}>
        <div className="titleAccount">{t('leaveSuggestion')}</div>
        <div className="support-container">
          <p className="support-notice">{t('suggestionNotice')}</p>

          <label className="support-widget">
            {t('stopIncidence')}
            <input type="checkbox" onChange={onChangeCheck} name="12" checked={suggestion[12]} />
            <span></span>
          </label>

          <label className="support-widget">
            {t('lineIncidence')}
            <input type="checkbox" onChange={onChangeCheck} name="13" checked={suggestion[13]} />
            <span></span>
          </label>

          <label className="support-widget">
            {t('planIncidence')}
            <input type="checkbox" onChange={onChangeCheck} name="14" checked={suggestion[14]} />
            <span></span>
          </label>

          <label className="support-widget">
            {t('operatorIncidence')}
            <input type="checkbox" onChange={onChangeCheck} name="15" checked={suggestion[15]} />
            <span></span>
          </label>

          <label className="support-widget">
            {t('systemIncidence')}
            <input type="checkbox" onChange={onChangeCheck} name="16" checked={suggestion[16]} />
            <span></span>
          </label>

          <label className="support-widget">
            {t('other')}
            <input type="checkbox" onChange={onChangeCheck} name="17" checked={suggestion[17]} />
            <span></span>
          </label>

          <div className="support-title-container">
            <label className="support-title">
              <textarea
                maxLength={75}
                onChange={onChangeTitle}
                name="title"
                value={title}
                placeholder={t('leaveTitle')}
                required={true}
              />
              <span>{countSymbolsTitle}/75</span>
            </label>
          </div>

          <div className="support-comment-container">
            <label className="support-comment">
              <textarea
                maxLength={2048}
                onChange={onChangeComment}
                name="comment"
                value={comment}
                placeholder={t('leaveComment')}
                required={true}
              />
              <span>{countSymbols}/2048</span>
            </label>
          </div>

          <div className="support-title-container" style={{ marginBottom: 8 }}>
            <label className="label" style={{ fontSize: 14, marginBottom: 8 }}>
              {fileInputData ? t('modify_file') : t('add_file')}
            </label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                rowGap: 4
              }}>
              <AddFileSelector
                setTypeFile={setFileType}
                setFileData={setFileName}
                localeInputData={fileInputData}
                setLocaleInputData={setFileInputData}
                toUploadFile={setFileUploadData}
                style={{ padding: 8 }}
              />
              {fileInputData ? (
                <FileEntrySending
                  fileName={fileName}
                  fileType={fileType}
                  localeBlob={fileInputData}
                  setFileData={setFileName}
                  setTypeFile={setFileType}
                  setLocaleInputData={(value: any) => {
                    setFileInputData(value);
                    setFileUploadData(value);
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className="support-buttons">
            <button className="btn-primary" disabled={!selected} onClick={() => {}}>
              {t('sendComment')}
            </button>

            {/* <button className="btn-secondary" onClick={onCancel}>
              Cancelar
            </button> */}
          </div>
        </div>
        {/* {selected && ( */}

        {/* )} */}
      </form>
    </div>
  );
}
