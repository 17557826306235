import { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useDispatch, useSelector } from 'react-redux';
import { Apis } from '../../../domain/Apis';
import { useDeleteFavoriteMutation } from '../../../redux/login/favorite/FavoriteServiceApi';
import {
  useAddSubscriptionsMutation,
  useDeleteSubscriptionsMutation
} from '../../../redux/login/subscriptions/SubscriptionsServiceApi';
import { eventsModalstoggleVisibilitySlice } from '../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { favoritesInformation, favoritesSlice } from '../../../redux/slices/favoritesSlice';
import {
  subscriptionsInformation,
  subscriptionsSlice
} from '../../../redux/slices/subscriptionsSlice';
import { VM } from '../../../viewmodel/VM';
import DefaultModal from '../../components/widget/Modals/DefaultModal';
import { useLanguage, useTranslate } from '../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../context/modalContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';
import LineIcon from '../explorescreen/mapOptions/searcherOption/stopDetail/LineIcon';

export default function FavoriteStation(props: any) {
  const ArrowDown = R.drawables.general.Ic_ChevronDownSmall;
  const ArrowUp = R.drawables.general.Ic_ChevronUpSmall;
  const Remove = R.drawables.general.Ic_delete;
  const Alert = R.drawables.general.Ic_Alerta;

  const [removeFavorite] = useDeleteFavoriteMutation();
  const [removeSubscription] = useDeleteSubscriptionsMutation();
  const [addSubscription] = useAddSubscriptionsMutation();
  const favorites = useSelector(favoritesInformation);
  const subscriptions: any = useSelector(subscriptionsInformation);
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const setChild = useSetModalChild();
  const setOpen = useSetModalOpened();
  const [lines, setLines] = useState([]);
  const [iconType, setIconType] = useState();
  const content = useRef(null as any);
  const language = useLanguage();
  const t = useTranslate();

  const theme = useTheme();

  const [transportModes, setTransportModes] = useState<any[]>([]);

  useEffect(() => {
    async function fetchData() {
      let dataOrigin = VM.dataOrigin.data
      if(!dataOrigin.length)
        dataOrigin = await VM.dataOrigin.get(language)
      setLines(await Apis.stops.getStopLines(language, props.stop.id));
      setIconType(await VM.icons.getIcon(dataOrigin[props.stop.agencyOriginId].agencyId, 'agency'));
    }
    fetchData();
  }, [props.stop]);

  useEffect(() => {
    if (VM.modes.data) setTransportModes(VM.modes?.data as any);
  }, [language]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickComponent);
  }, []);

  useEffect(() => () => document.removeEventListener('mousedown', handleClickComponent), []);

  const handleClickComponent = (event: any) => {
    if (content && !content.current?.contains(event.target)) {
      setOpened(false);
    }
  };

  const removeFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    updateFavorites.stops.splice(updateFavorites.stops.indexOf(props.stop.id), 1);
    removeFavorite({
      stops: [props.stop.id],
      pois: [],
      plans: [],
      directions: []
    });
    dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
        showModal: true,
        background: '#00763D',
        text: t('stopRemoved')
      })
    );
  };

  return (
    <div
      className={'favorite-station-container'}
      style={{ maxHeight: window.innerHeight - 165 }}
      ref={content}>
      <div
        className={opened ? 'favorite-station-body-c' : 'favorite-station-body-o'}
        onClick={() => {
          setOpened(!opened);
        }}
        onDoubleClick={() => {
          dispatch(favoritesSlice.actions.setClickFav({ id: props.stop, type: 'station' }));
        }}>
        <div className={'row'}>
          <img className={'agency-icon-box'} src={iconType} />
          <div className={'iconText'}>{t('bikeStation')}</div>
          {opened ? <ArrowUp className={'close'} /> : <ArrowDown className={'close'} />}
        </div>
        <div className={'subtitle marginTop5'}>{props.stop.name}</div>
      </div>

      <Collapse isOpened={opened}>
        <div className={'favorite-station-footer row'}>
          <div
            className={'row'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              removeFav();
            }}>
            <Remove style={{ width: 20, marginRight: 5, fill: theme.colors.neutral }} />
            <div className={'primaryText'} style={{ color: theme.colors.neutral }}>
              {t('delete')}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
