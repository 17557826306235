import { ILine } from "../types/LineInterfaces";

export function sortLines(lines: Array<ILine>) {
    return lines.sort((a, b) => {
        const nameA = a.code?.toUpperCase() ?? ''; 
        const nameB = b.code?.toUpperCase() ?? '';
        let numberA = parseInt(nameA);
        let numberB = parseInt(nameB);
        if (!isNaN(numberA) && !isNaN(numberB)) {
          return numberA - numberB;
        }

        function extractNumber(value: string) {
          // Extraerlos dígitos de la cadena del final
          let match = value.match(/^([^\d]+)(\d+)$/);
          
          return match ? {char: match[1], code: parseInt(match[match?.length - 1])} : {char: 'error', code: NaN};
        }

        let numA = extractNumber(nameA);
        let numB = extractNumber(nameB);
  
        // Si ambos son números, comparar numéricamente
        if (!isNaN(numA.code) && !isNaN(numB.code) && numA.char === numB.char) {
          return numA.code - numB.code;
        }

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
}