import ViewModel from '../@base/ViewModel';

export default class NavigationViewModel extends ViewModel {
  constructor() {
    super();
  }

  initialize(navigator) {
    this.data = navigator;
  }
}
