import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
  useAddSubscriptionsMutation,
  useDeleteSubscriptionsMutation
} from '../../../redux/login/subscriptions/SubscriptionsServiceApi';
import {
  subscriptionsInformation,
  subscriptionsSlice
} from '../../../redux/slices/subscriptionsSlice';
import { VM } from '../../../viewmodel/VM';
import Button from '../../components/commons/Button';
import ButtonIcon from '../../components/commons/ButtonIcon';
import CheckBox from '../../components/commons/CheckBox';
import { useTranslate } from '../../context/languageContext';
import { useSetModalChild, useSetModalOpened } from '../../context/modalContext';
import { R } from '../../R';
import { useTheme } from '../../context/themeContext';
import { agencyInformation } from '../../../redux/slices/agencySlice';
import { favFilterNewsAlertsState, favSubsNewsState, favoritesInformation, favoritesSlice } from '../../../redux/slices/favoritesSlice';

export default function SubscribeNews(props: any) {
  const Close = R.drawables.general.Ic_close;
  const [selected, setSelected] = useState([] as any);
  const t = useTranslate();
  const subscriptions = useSelector(subscriptionsInformation);
  const [renderOperators, setRenderOperators] = useState(null as any);
  const setOpened = useSetModalOpened();
  const setChild = useSetModalChild();
  const theme = useTheme();
  const agencyState = useSelector(agencyInformation);
  const favSubsNews = useSelector(favSubsNewsState);
  const [favFilterSelector, setFavFilterSelector] = useState(favSubsNews);
  const favAgencies = useSelector(favoritesInformation).agencyId;

  const dispatch = useDispatch();
  const [addSubscription] = useAddSubscriptionsMutation();
  const [deleteSubscription] = useDeleteSubscriptionsMutation();

  let copyAgenciesFilters = JSON.parse(JSON.stringify(agencyState.agenciesFilters));
  let agenciesTransport: Array<any> = [];
  copyAgenciesFilters.forEach((transportModeAg: any) => {
    if (transportModeAg.id !== 5) {
      transportModeAg.agencies.shift();
      let filterDuplicates: Array<number> = [];

      agenciesTransport.forEach((agency: any) => {
        let indexFound = transportModeAg.agencies.findIndex(
          (transAg: any) => transAg.id === agency.id
        );
        if (indexFound !== -1) {
          filterDuplicates.push(indexFound);
        }
      });
      if (filterDuplicates?.length) {
        filterDuplicates.forEach((indexToDel: number) => {
          transportModeAg.agencies.splice(indexToDel, 1);
        });
      }

      let filteredAgenciesWithNewsOrAlerts = transportModeAg.agencies.filter((agency: any) =>
        agencyState.dataOrigin?.find(
          (ori: any) => agency?.id === ori?.agencyId && (ori?.driverId === 4 || ori?.driverId === 6)
        )
      );

      agenciesTransport = agenciesTransport.concat(filteredAgenciesWithNewsOrAlerts);
    }
  });
  agenciesTransport.sort(function (a, b) {
    let nameA = a?.longName?.toLowerCase();
    let nameB = b?.longName?.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    async function fetchData() {
      setRenderOperators(await getRenderOperators());
    }
    if (!selected.length) setSelected(getCheckbox());
    else fetchData();
  }, [selected, subscriptions]);

  const getCheckbox = () => {
    const res = [];
    for (let agency in agenciesTransport) {
      res[agenciesTransport[agency].id] = {
        agency: agenciesTransport[agency].id,
        selected: subscriptions.agencyNotices.includes(agenciesTransport[agency].id as never)
      };
    }
    return res;
  };

  const changeAllCheckbox = (value: boolean) => {
    const res = [];
    for (let agency in agenciesTransport) {
      res[agenciesTransport[agency].id] = {
        agency: agenciesTransport[agency].id,
        selected: value
      };
    }
    return res;
  };

  const isActive = () => {
    let toRemove: any[] = [];
    let toAdd = [];
    let updateSubscriptions = JSON.parse(JSON.stringify(subscriptions));
    for (let agency in selected) {
      if (
        updateSubscriptions.agencyNotices.includes(selected[agency]?.agency) &&
        !selected[agency]?.selected
      ) {
        toRemove.push(selected[agency]?.agency);
      } else if (
        !updateSubscriptions.agencyNotices.includes(selected[agency]?.agency) &&
        selected[agency]?.selected
      ) {
        toAdd.push(selected[agency]?.agency);
      }
    }
    updateSubscriptions.agencyNotices = updateSubscriptions.agencyNotices.concat(toAdd);
    updateSubscriptions.agencyNotices = updateSubscriptions.agencyNotices.filter(
      (x: any) => !toRemove.includes(x)
    );
    return subscriptions.agencyNotices.toString() === updateSubscriptions.agencyNotices.toString();
  };

  const updateSubs = () => {
    let toRemove: any[] = [];
    let toAdd = [];
    let updateSubscriptions = JSON.parse(JSON.stringify(subscriptions));
    for (let agency in selected) {
      let filtered = JSON.parse(JSON.stringify(Array.from(VM.dataOrigin.noMap))).find(
        ({ agencyId }: any) => agencyId === VM.agencies.data[selected[agency]?.agency]?.id
      );
      if (
        updateSubscriptions.agencyNotices.includes(selected[agency]?.agency) &&
        !selected[agency]?.selected
      ) {
        if (filtered) toRemove.push(filtered.agencyId);
      } else if (
        !updateSubscriptions.agencyNotices.includes(selected[agency]?.agency) &&
        selected[agency]?.selected
      ) {
        if (filtered) toAdd.push(filtered.agencyId);
      }
    }
    updateSubscriptions.agencyNotices = updateSubscriptions.agencyNotices.concat(toAdd);
    updateSubscriptions.agencyNotices = updateSubscriptions.agencyNotices.filter(
      (x: any) => !toRemove.includes(x)
    );
    addSubscription({
      stops: [],
      lines: [],
      agencyNotices: toAdd
    });
    deleteSubscription({
      stops: [],
      lines: [],
      agencyNotices: toRemove
    });
    dispatch(subscriptionsSlice.actions.updateSubscriptions(updateSubscriptions));
    if (favFilterSelector) {
      dispatch(favoritesSlice.actions.updateFavSubsNews(true));
    } else {
      dispatch(favoritesSlice.actions.updateFavSubsNews(false));
    }
    setOpened(false);
    setChild(null);
  };

  const getRenderOperators = async () => {
    let res = [];

    const agenciesSort: Array<any> = JSON.parse(JSON.stringify(agenciesTransport));

    for (let agency in agenciesSort) {
      let icon = await VM.icons.getIcon(agenciesSort[agency].id, 'agency');

      let filtered = JSON.parse(JSON.stringify(Array.from(VM.dataOrigin.noMap))).filter(
        ({ agencyId, driverId }: any) =>
          agencyId === agenciesSort[agency].id && (driverId === 4 || driverId === 6)
      );
      if (filtered.length) {
        let elementSubscribedIndex = selected?.findIndex(
          (element: any) => element?.agency === agenciesSort[agency].id
        );
        res.push(
          <div key={agency}>
            <div className={'row'}>
              <ButtonIcon style={{ marginRight: 5 }} iconSize={20} size={30} icon={icon} />
              <div className={'primaryText'} style={{ textTransform: 'capitalize' }}>
                {agenciesSort[agency]?.groupName
                  ? agenciesSort[agency]?.groupName
                  : agenciesSort[agency].shortName}
              </div>

              <div className={'close'} style={{ cursor: 'auto' }}>
                <CheckBox
                  onSelected={() => {
                    let aux = JSON.parse(JSON.stringify(selected));
                    aux[elementSubscribedIndex].selected = true;
                    setSelected(aux);
                  }}
                  onUnselected={() => {
                    let aux = JSON.parse(JSON.stringify(selected));
                    aux[elementSubscribedIndex].selected = false;
                    setSelected(aux);
                  }}
                  checked={selected[elementSubscribedIndex]?.selected}
                />
              </div>
            </div>
            <div className={'divider'} />
          </div>
        );
      }
    }
    return res;
  };

  const changeFavCheckbox = (value: boolean) => {
    let res: Array<number> = [];

    const agencyIdsFav: Array<number> = [];
    
    favAgencies.forEach((originId: number) => {
      const agency: any = agencyState.dataOrigin.find((element: any) => element.id === originId);
      if (agency) {
        const agencyIsAdded = agencyIdsFav.find((element: number) => element === agency?.agencyId);

        const existsAgency = agenciesTransport.find((element) => element.id === agency?.agencyId);

        if (!agencyIsAdded && existsAgency) {
          agencyIdsFav.push(agency?.agencyId);
        }
      }
    });
    
    const copySel = JSON.parse(JSON.stringify((selected)));
    agencyIdsFav.forEach((sel: number) => {
      let isAddedIndex = selected?.findIndex(
        (element: any) => element?.agency === sel
      );
      
      if (isAddedIndex !== -1) {
        copySel[isAddedIndex].selected = value;
      }
    });

    res = copySel;

    return res;
  };

  useEffect(() => {
    if (favFilterSelector) {
      setSelected(changeFavCheckbox(true));
    }
  }, [favFilterSelector]);

  return (
    <div className={'subs-news'}>
      <div className={'subs-news-header'}>
        <Close
          className={'close'}
          onClick={() => {
            setOpened(false);
            setChild(null);
          }}
        />
        <div className={'title marginBottom10'}>{t('subscribeNews')}</div>
        <div className={'description'}>{t('selectOpNews')}</div>
      </div>
      <div className={'divider'} />
      <div className={'subs-news-body'}>
        <div className={'row'}>
          <div className={'primaryText'}>{t('selectAllFavorites')}</div>
          <div className={'close'} style={{ cursor: 'auto' }}>
            <CheckBox
              onSelected={() => {
                setFavFilterSelector(true);
              }}
              onUnselected={() => {
                setSelected(changeFavCheckbox(false));
                setFavFilterSelector(false);
              }}
              checked={favFilterSelector}
            />
          </div>
        </div>
        <div className={'divider'} />{' '}
        <div className={'row'}>
          <div className={'primaryText'}>{t('selectAll')}</div>
          <div className={'close'} style={{ cursor: 'auto' }}>
            <CheckBox
              onSelected={() => setSelected(changeAllCheckbox(true))}
              onUnselected={() => setSelected(changeAllCheckbox(false))}
            />
          </div>
        </div>
        <div className={'divider'} />
        {renderOperators ? (
          renderOperators
        ) : (
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <ClipLoader />
          </div>
        )}
      </div>
      <Button
        className={'paddingVertical10 paddingInLine20'}
        textColor={theme.colors.white}
        background={theme.colors.mediumDark}
        onClick={() => updateSubs()}
        disabled={isActive()}
        style={{ width: '94%' }}
        text={t('save')}
      />
    </div>
  );
}
