import Api from '../@base/Api';

export default class AlertApi extends Api {
    constructor(url) {
        super(url);
    }

    get(locale) {
        return super.get(`/alert/?locale=${locale}`);
    }

    getDetail(locale, id) {
        return super.get(`/alert/${id}?locale=${locale}`);
    }

}
