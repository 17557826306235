import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface notificationsInformation {
  platform: number; //notificaciones del sistema
  news: number; //notificaciones de las noticias
  guide: number; //notificaciones del guiado
  alerts: number; //notificaciones de las alertas de tráfico
  updatedAt: string;
  id?: number | undefined;
  userId?: number | undefined;
}

const initialState = {
  notificationsInformation: {
    platform: 1,
    news: 1,
    guide: 1,
    alerts: 1,
    updatedAt: new Date().toUTCString(),
    id: null,
    userId: null
  },
  checkMessages: false,
};

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    updateSystem: (state, action) => {
      //subscribeToTopic or unsuscribe
      state.notificationsInformation.platform = action.payload;
    },
    updateNews: (state, action) => {
      //subscribeToTopic or unsuscribe
      state.notificationsInformation.news = action.payload;
    },
    updateGuided: (state, action) => {
      //subscribeToTopic or unsuscribe
      state.notificationsInformation.guide = action.payload;
    },
    updateTrafficAlerts: (state, action) => {
      //subscribeToTopic or unsuscribe
      state.notificationsInformation.alerts = action.payload;
    },
    updateDate: (state, action) => {
      //subscribeToTopic or unsuscribe
      state.notificationsInformation.updatedAt = action.payload;
    },
    updateCheckMessages: (state, action) => {
      state.checkMessages = action.payload;
    },
    updateNotifications: (state, action) => {
      state.notificationsInformation = action.payload;
    }
  }
});

export const notificationsInformation = (state: RootState) =>
  state.notificationSlice.notificationsInformation;

export const notificationsCheckMessagesInformation = (state: RootState) =>
  state.notificationSlice.checkMessages;
