import { useDispatch, useSelector } from 'react-redux';
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useTranslate } from '../../../context/languageContext';
import { useTheme } from '../../../context/themeContext';
import { R } from '../../../R';
import TitleH1 from '../../commons/Titles/TitleH1';

export default function ShareModalWidget(props: any) {
  const t = useTranslate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const Close = R.drawables.commons.close;
  // const backupLink =
  //   'https://www.euskadi.ejiepru.eus/appcont/ad73dStatics/WebContent/ad73d/portal/';
  const linkToCopy = useSelector(
    (state: any) => state.eventsModalstoggleVisibilitySlice.ModalWidget.ShareModalWidget.shareLink
  );
  const title = useSelector(
    (state: any) => state.eventsModalstoggleVisibilitySlice.ModalWidget.ShareModalWidget.title
  );

  const copyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(linkToCopy);
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCopiedModalWidget());
    } else {
      console.log('Your browser is not supported');
    }
  };
  const closeModal = () => {
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityShareModalWidget({
        showModal: false
      })
    );
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 10,
          backgroundColor: '#181B1D',
          opacity: 0.2
        }}></div>
      <div className="share-modal">
        <div
          style={{
            color: theme.colors.neutralBlack,
            width: '100%',
            padding: '40px 24px 8px 24px'
          }}>
          <Close
            style={{
              position: 'absolute',
              right: '4%',
              top: '4%',
              height: 10.65,
              width: 10.71
            }}
            fill={theme.colors.neutralBlack}
            onClick={closeModal}
          />
          <TitleH1
            style={{
              width: '100%',
              margin: 0,
              fontSize: 22,
              fontWeight: '700',
              textAlign: 'left'
            }}
            title={t('share')}
          />
        </div>
        <div className="account-info-container">
          <div
            className="account-info-section"
            style={{
              textAlign: 'left',
              padding: 0,
              marginLeft: 24,
              marginRight: 33,
              borderBottom: 'none'
            }}>
            <div className="account-info-email">
              <label>{t('linkToShare')}</label>
              <input value={linkToCopy} disabled></input>
            </div>
            <button
              className="btn-primary"
              onClick={copyLink}
              style={{
                width: 148,
                fontSize: 14,
                // marginRight: 33,
                paddingLeft: 0,
                paddingRight: 0,
                alignSelf: 'flex-end'
              }}>
              {t('copyLink')}
            </button>
          </div>
        </div>
        <div
          className="download-app"
          style={{ paddingBottom: 40, marginLeft: 24, marginRight: 33 }}>
          <h3 style={{ textAlign: 'left' }}>{t('shareVia')}</h3>
          <div className="social-share">
            <FacebookShareButton url={linkToCopy} title={title}>
              <FacebookIcon size={48} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={linkToCopy} title={title}>
              <TwitterIcon size={48} round={true} />
            </TwitterShareButton>
            <TelegramShareButton url={linkToCopy} title={title}>
              <TelegramIcon size={48} round={true} />
            </TelegramShareButton>
            <WhatsappShareButton url={linkToCopy} title={title}>
              <WhatsappIcon size={48} round={true} />
            </WhatsappShareButton>
          </div>
        </div>
      </div>
    </>
  );
}
