import { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteFavoriteMutation } from '../../../redux/login/favorite/FavoriteServiceApi';
import { eventsModalstoggleVisibilitySlice } from '../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { favoritesInformation, favoritesSlice } from '../../../redux/slices/favoritesSlice';
import { VM } from '../../../viewmodel/VM';
import { useTranslate } from '../../context/languageContext';
import { useResponsive } from '../../context/responsiveContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';

export default function FavoritePoi(props: any) {
  const t = useTranslate();
  const ArrowDown = R.drawables.general.Ic_ChevronDownSmall;
  const ArrowUp = R.drawables.general.Ic_ChevronUpSmall;
  const Remove = R.drawables.general.Ic_delete;

  const [removeFavorite] = useDeleteFavoriteMutation();
  const favorites = useSelector(favoritesInformation);
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const [iconType, setIconType] = useState();
  const content = useRef(null as any);
  const responsive = useResponsive();

  function truncate(str: string) {
    if (str !== undefined && str !== null) {
      return str.length > (responsive.isBig ? 40 : 20)
        ? str.substring(0, responsive.isBig ? 57 : 17) + '...'
        : str;
    }
  }

  const theme = useTheme();
  useEffect(() => {
    async function fetchData() {
      setIconType(await VM.icons.getIcon(props.poi.categoryId, 'category', 'markIconId'));
    }
    fetchData();
  }, [props.poi]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickComponent);
  }, []);

  useEffect(() => () => document.removeEventListener('mousedown', handleClickComponent), []);

  const handleClickComponent = (event: any) => {
    if (content && !content.current?.contains(event.target)) {
      setOpened(false);
    }
  };

  const removeFav = () => {
    let updateFavorites = JSON.parse(JSON.stringify(favorites));
    updateFavorites.pois.splice(updateFavorites.pois.indexOf(props.poi.id), 1);
    removeFavorite({
      stops: [],
      pois: [props.poi.id],
      plans: [],
      directions: []
    });
    dispatch(favoritesSlice.actions.updateFavorites(updateFavorites));
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
        showModal: true,
        background: '#00763D',
        text: t('poiRemoved')
      })
    );
  };

  return (
    <div
      className={'favorite-station-container'}
      style={{ maxHeight: window.innerHeight - 165 }}
      ref={content}>
      <div
        className={opened ? 'favorite-station-body-c' : 'favorite-station-body-o'}
        onClick={() => setOpened(!opened)}
        onDoubleClick={() => {
          dispatch(favoritesSlice.actions.setClickFav({ id: props.poi.id, type: 'poi' }));
        }}>
        <div className={'row'}>
          <img className={'poiIcon'} src={iconType} />
          <div className={'subtitle'}>{props.poi.name}</div>

          {opened ? <ArrowUp className={'close'} /> : <ArrowDown className={'close'} />}
        </div>
        <div className={'cornerText marginTop5'}>{props.poi.address}</div>
        <div className={'cornerText marginTop5'}>{truncate(props.poi.description)}</div>
      </div>

      <Collapse isOpened={opened}>
        <div className={'favorite-station-footer row'}>
          <div
            className={'row'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              removeFav();
            }}>
            <Remove style={{ width: 20, marginRight: 5, fill: theme.colors.neutral }} />
            <div className={'primaryText'} style={{ color: theme.colors.neutral }}>
              {t('delete')}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
