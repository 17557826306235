export const strings = {
  filters: 'Iragazkiak',
  appName: 'Moveuskadi',
  button_delete:  'Ezabatu hautaketa',
  remove: 'Ezabatu  ',
  explore: 'Esploratu   ',
  saves:  'Gogokoak',
  openData: 'Open Data',
  downloadApp:  'Mugikorrerako Moveuskadi aplikazioa deskargatu',
  circulation: 'Zirkulazioa',
  account:  'Kontua',
  login: 'Saioa hasi',
  login2: 'Saioa hasi',
  incidence: 'Oharrak',
  transbord: 'Garraiobide-aldaketa',
  stopSaved: 'Geltoki hau gorde duzu,',
  saveStopAlert: 'Alerta bat aktibatu nahi duzu? ',
  saveAlert: 'Alerta bat aktibatu nahi duzu?',
  activateAlerts:  'Alertak aktibatu',
  noThanks: 'Ez, eskerrik asko',
  saveStopAlertDescription: 'Aktibatu alerta bat geltokian gorabeheraren bat gertatuz gero, zuri berri emateko.',
  saveLineAlertDescription:
    'Aktibatu alerta bat linean gorabeheraren bat gertatuz gero, zuri berri emateko.',
  saveStopAlertBody:
    'Aukeratu zerbitzu-alertak jaso nahi baldin badituzu gordetako geltokietako gorabeherei buruz',
  loginToFav: 'Hasi saioa gogoko bezala gordetzeko',
  loginToReport:  'Saioa hasi akats bati buruz berri emateko',
  loginToFavs: 'Hasi saioa gogoko bezala gordetzeko',
  loginToAlerts: 'Saioa hasi alerta bat konfiguratzeko',
  loginToFavBody: 'Erregistratu edo saioa hasi funtzionalitate guztietara sartu ahal izateko',
  deleteFavConfirm: 'Faborito hau ezabatu nahi duzu?',
  register: 'Erregistratu',
  myAccount: 'Nire kontua',
  infoPersonal: 'Informazio pertsonala',
  whereDoYouGo: 'Nora joan nahi duzu?',
  simpleTicket: 'Txartel arrunta',
  activeAlert:  'Alertak aktibatu',
  removeAlert: 'Alerta ezabatu',
  alertAdded: 'Alerta gehitu da',
  alertRemoved: 'Alerta ezabatu da',
  password: 'Pasahitza',
  passwordConditions: 'Pasahitza aldatzeko, gutxienez 6 digitu, letra larri bat, letra xehe bat eta zenbaki bat behar dituzu',
  man: 'Gizona',
  woman: 'Emakumea',
  today: 'Gaur',
  exitTo: 'Irteerak',
  another: 'Beste bat',
  setAlias:  'Izen bat ezarri',
  save: 'Gorde',
  share: 'Partekatu',
  shareVia: ' Partekatu',
  linkToShare: 'Partekatzeko esteka',
  copyLink: 'Esteka kopiatu',
  copiedToClipboard:  'Arbelean kopiatu da',
  downloadMobileApp:  'Mugikorrerako aplikazioa deskargatu',
  goToAppleStore:  'Apple Store-ra joan',
  goToAccount: 'Kontura joan',
  goToGooglePlay:  'Google Play Store-ra joan',
  noBinary: ' Ez-bitarra',
  ageUnder19: '0-19 urte artean',
  age20to45: '20-45 urte artean',
  age45to65: '45-65 urte artean',
  ageOver65: '>65',
  copyUrl: 'URLa kopiatu da',
  changePass: 'Pasahitza aldatu',
  change: 'Aldatu',
  manageAccount: 'Kontua kudeatu',
  language: 'Hizkuntza',
  help:  'Laguntza',
  support: 'Laguntza',
  manageNotifications: 'Jakinarazpenen kudeaketa',
  news: 'Albisteak',
  guided: 'Jarraibideak',
  guidedNotificationsNotice:
    'Aukera hau aktibatzen baduzu, Moveuskadik gidatze-prozesuan zehar hurrengo urratsei eta aldaketei buruzko jakinarazpenak bidaliko dizkizu.',
  trafficAlerts: 'Albiste eta abisuen alertak',
  trafficAlertsNotificationsNotice: '',
  general: 'Orokorra',
  privacy: 'Pribatutasun-politika',
  useConditions:  'Erabilera baldintzak',
  terms:  'Zerbitzuaren baldintzak',
  shareApp: 'Aplikazioa partekatu',
  reviewApp:  'Iritzia eman',
  dataAccount: 'Kontuaren datuak',
  charMaxAndMin: '6 karaktere gutxienez eta 30 gehienez',
  minNum: 'Gutxienez zenbaki bat',
  and: 'eta',
  accept: 'Onartzen dut',
  minMayusMin: 'Gutxienez letra larri bat eta letra xehe bat',
  noMatch: 'Ez datoz bat',
  fillFields: 'Bete itzazu eremu hauek',
  personalInfo: 'Datu pertsonalak',
  createAccount: 'Kontua sortu',
  noStationsSaved:'Badirudi gogokoetan ez duzula geltokirik gorde; aukeratu gehien erabiltzen dituzunak eta gorde azkar sartu ahal izateko.',
  meetABit: 'Elkar apur bat ezagutzeko',
  noTransportSaved: 'Ez duzu gogoko garraiobiderik',
  noTransportSavedBody: 'Zure gogoko garraiobideak konfiguratzeko, zure kontuan gorde beharko dituzu, gogoko garraiobideen atalean.',
  nameComa: 'Izena:',
  name: 'Izena',
  email: 'Posta elektronikoa',
  emailLong: 'Helbide elektronikoa',
  userName: 'Erabiltzailearen izena',
  needANewPass: 'Pasahitz berria behar duzu?',
  introduceYourEmail: 'Sartu zure kontua sortzean erabili zenuen helbide elektronikoa.',
  gender: 'Generoa',
  ageComa: 'Adina:',
  age: 'Adina',
  phone: 'Telefonoa',
  website: 'Web-orria',
  direction: 'Helbidea',
  seeAll: 'Guztiak ikusi',
  selectAll: 'Guztiak hautatu',
  selectAllFavorites: 'Aukeratu gogokoak',
  subscribeNews: 'Albisteetara harpidetu',
  selectOpNews:
    'Aukeratu zein operadorei buruz jaso nahi dituzun jakinarazpenak albiste berri bat dagoenean',
  lessInfo: 'Informazio gutxiago ikusi',
  moreInfo: 'Informazio gehiago ikusi',
  goThere: 'Haraino heldu',
  notFound: 'Ez da ezer aurkitu',
  messageState: 'Nire iruzkinen egoera',
  all: 'Guztiak',
  inProcess: 'Prozesuan',
  answered: 'Erantzundakoak',
  checkSearch: 'Egiaztatu bilaketa ondo idatzita dagoela. Probatu hiri, estatu edo posta-kode bat gehitzen.',
  myDirections: 'Nire helbideak',
  directions: 'Helbideak',
  searchByCategory:  'Kategorien araberako bilaketa',
  recents: 'Duela gutxikoak',
  useMyLocation: 'Nire kokapena',
  es: 'Gaztelania',
  eu: 'Euskara',
  en: 'Ingelesa',
  fr: 'Frantsesa',
  visualizer_filters: 'Iragazkiak',
  visualizer_filtersAll: 'Guztiak',
  visualizer_filtersBoth: 'Biak',
  visualizer_moreFilters: 'Iragazki gehiago',
  visualizer_message_moreFilters:
    'Aukeratu gehien erabiltzen dituzun operadoreak, eta horiek bakarrik ikusiko dituzu mapan.',
  visualizer_filters_empty: 'Ezin izan da iragazkirik kargatu, berrikusi konexioa.',
  visualizer_filters_motorized: 'Motorizazioa',
  visualizer_filters_onlySelected: 'Bakarra',
  visualizer_filters_other: 'Beste batzuk',
  visualizer_filters_selected: 'hautatuak',
  visualizer_filter_selected: 'hautatuak',
  visualizer_poi: 'Interesguneak',
  visualizer_ModosdeTransporte: 'Garraiobideak',
  visualizer_operators: 'Operadoreak',
  systemNotifications: 'Sistemaren jakinarazpenak',
  systemNotificationsNews:
    'Aukera hau aktibatzen baduzu, Moveuskadik harpidetutako albistei buruzko jakinarazpenak bidaliko dizkizu.',
  systemNotificationsAlerts:   'Aukera hau aktibatzen baduzu, Moveuskadik jakinarazpenak bidaliko dizkizu harpidetutako lineak, geltokiak eta berriak garraio-alertei edo gorabeherei buruz.',
  systemNotificationsNotice: 'Aukera hau aktibatzen baduzu, Moveuskadik sisteman izandako aldaketa garrantzitsuei eta akatsei buruzko jakinarazpenak bidaliko dizkizu. Aktibo mantentzea gomendatzen dizugu.',
  h: 'h',
  min: 'min',
  accessible: 'Irisgarria',
  exitNow: 'Orain irten',
  exitAt: 'Irteera',
  exitAtText: 'Irteera',
  accountNoActivated: 'Kontua aktibatu gabe',
  seeRoutes: 'Ibilbideak ikusi',
  stopTime: 'Zein ordutan aterako zara?',
  accountActivated: 'Kontua aktibatu da',
  accountActivatedDes: 'Zure kontua ondo sortu eta aktibatu da!',
  accountNoActivatedDes:
    'Arazo bat egon da kontua sortu eta aktibatzean. Mesedez, saiatu berriro geroago.',
  arriveAt: 'Helmuga',
  preferences: 'Lehentasunak',
  other: 'Beste bat',
  confirm: 'Baieztatu',
  leaveSuggestion:  'Iradokizun bat idatzi',
  suggestionNotice:
    'Zuen esperientzia hobetzen ahalegintzen gara etengabe. Horregatik, jakin nahiko genuke zer funtzio gustatzen zaizkizuen gehien eta zer funtzio hobetu ditzakegun',
  commentStateNotice: 'Iradokizun eta berriemate guztien egoera ikusi ahal izango duzu.',
  report:  'Berri eman',
  timeSoonThanBefore:  'Aurreko geltokiaren ordua baino lehenagokoa',
  timeSoonThanBeforeBody:
    'Aukeratutako ordua aurreko geltoki batena baino lehenagokoa da. Mesedez, hautatu ondorengo ordu bat.',
  reportProblem: 'Gorabehera baten berri eman',
  reportSystemError:  'Sistemaren akats baten berri eman',
  reportOperator:  'Operadoreari buruz berri eman',
  reportAnError:  'Akats baten berri eman',
  reportStop: 'Geltoki bati buruz berri eman',
  reportStopNotice:  'Aukeratu zergatik eman nahi duzun geltoki honen berri',
  reportWrongAddress: 'Helbidea ez da zuzena',
  reportStopData:  'Geltoki-datuak okerrak dira',
  reportStopState: 'Geltokiaren egoera',
  reportLine: 'Lineari buruz berri eman ',
  reportLineNotice:  'Aukeratu zergatik eman nahi duzun linea honen berri',
  reportDataError: 'Datu okerrak',
  reportTimeError:  'Akatsa igarotze-denboretan',
  reportPlanning: 'Plangintzari buruz berri eman',
  reportPlanningNotice:  'Aukeratu zergatik eman nahi duzun plangintza honen berri',
  reportDoesntComplyWithPreferences: 'Ez ditu lehentasunak betetzen',
  reportNoResults: 'Ez dago emaitzarik  ',
  reportAlternativeRoutes: 'Ibilbide alternatiboak daude',
  reportRoute: 'Ibilbideari buruz berri eman',
  reportRouteNotice: 'Aukeratu zergatik eman nahi duzun plangintzaren berri',
  reportIndicationsError:  'Akatsa jarraibidetan',
  sharePlanning: 'Kontsultatu hurrengo ibilbidearen informazioa',
  leaveComment:  'Iruzkin bat idatzi',
  sendComment: 'Iruzkina bidali',
  send: 'Bidali',
  destination: ' Helmuga',
  origin: 'Jatorria',
  tomorrow: 'Bihar',
  nextStops:  'Hurrengo irteerak:',
  searchADirection: 'Helbide bat bilatu',
  suggestion: 'Iradokizuna',
  planificationReport: 'Plangintzari buruz berri eman',
  stopReport: 'Geltokiari buruz berri eman ',
  answer: 'Erantzuna',
  filterNoResult:
    'Hautatu beste iragazki azkar bat edo birkalkulatu plangintza konfigurazio berri batekin.',
  firstExit: 'Lehenengo irteera',
  lastExit: 'Azken irteera',
  component_loading: 'Kargatzen',
  none: 'Bat ere ez',
  favs: 'Gogokoak',
  allAlerts: 'Ohar guztiak',
  lines: 'linea',
  transit_lines: 'Lineak',
  transit_stops: 'Geltokiak',
  start: 'Hasiera: ',
  end: 'Amaiera: ',
  cantAddStops: 'Ezin da geltoki gehiago gehitu',
  maximumNumberStops: 'Gehieneko geltoki-kopurura heldu da',
  stationsQuant:  ' geltoki',
  yourLocation: 'Zure kokapena',
  links:'Estekak',
  accessible_routes: 'Ibilbide irisgarriak',
  fav_transports: 'Gogoko garraiobideak',
  fast_routes:  'Ibilbide azkarrena',
  eco_routes:  'Ibilbide ekonomikoena',
  transport_modes: 'Garraiobideak',
  monitor_types:  'Motorizazio-mota',
  operators: 'Operadoreak',
  route_types: 'Ibilbide motak',
  fastest_route: 'IBILBIDE AZKARRENA',
  eco_route: 'IBILBIDE EKONOMIKOENA',
  walk_routes: 'Ibilbide gutxiago ibili',
  walk_route: 'IBILERA GUTXIAGO',
  routes: 'Ibilbideak',
  places: 'Lekuak',
  ubis: 'Kokapenak',
  favorites: 'Gogokoak',
  stations: 'Geltokiak',
  stops: 'Geltoki',
  stops_route: 'Geltoki',
  stop: 'Geltoki',
  stopSaved2:  'Geltoki gorde da',
  stopRemovedFromFav: 'Gogokoetatik ezabatutako geltoki',
  stopsAndStations: 'Geltokiak',
  cancel: 'Ezeztatu',
  next: ' Hurrengo',
  myTransport: 'Nire garraiobidea',
  descriptionMyTransport:
    'Aukeratu gehien erabiltzen dituzun garraiobideak mapan azkarrago aurkitzeko',
  forgotPass: 'Nire pasahitza ahaztu dut',
  configAlert:  'Alerta konfiguratu ',
  pois: 'Interesguneak',
  logout: 'Saioa itxi',
  close: 'Itxi',
  alertDetail: 'Oharraren xehetasuna',
  youAreBack: 'Bueltan zaude!',
  alreadyHaveAccount: 'Badaukat kontu bat',
  errorLogin:  'Akatsa saioa hastean',
  notAccount: ' Ez daukat konturik.',
  myFavTransp: 'Nire gogoko garraiobideak',
  date_day: 'egun bat',
  date_days: 'egun',
  date_difference: 'Duela',
  date_weeks: 'aste',
  date_hours: 'ordu',
  date_minute: 'min',
  minute: 'minutu',
  minutes: 'minutu',
  date_month: 'hilabete',
  date_week: 'aste bat',
  zero_min: '0 min',
  newsDetails: 'Albistearen xehetasuna',
  moreInf: 'Informazio gehiago',
  seeMore: 'Gehiago ikusi',
  visit: 'Bisitatu',
  noNews: 'Ez da berririk aurkitu',
  edit: 'Editatu',
  otherPois:  'Beste interesgune batzuk',
  delete: 'Ezabatu',
  newPass: 'Pasahitz berria',
  oldPass: 'Pasahitz zaharra',
  passNew: 'Pasahitz berria',
  repeatPassNew: 'Errepikatu pasahitz berria',
  home: 'Etxea',
  work: 'Lana',
  add: 'Gehitu',
  addHome: 'Gehitu etxea',
  addWork: 'Gehitu lana',
  addDirection: 'Gehitu helbidea',
  update: 'Eguneratu',
  setDirection: 'Helbidea ezarri',
  createAlert: 'Alerta sortu',
  trainStation: 'Tren-geltoki',
  monday: 'al.',
  tuesday: 'as.',
  wednesday: 'az.',
  thursday: 'og.',
  friday: 'or.',
  saturday:  'lr.',
  sunday: 'ig.',
  january: 'urt.',
  february: 'ots.',
  march: 'mar.',
  april: 'api.',
  may: 'mai.',
  june: 'eka.',
  july: 'uzt.',
  august: 'abu.',
  september: 'Ira.',
  october: 'urr.',
  november: 'aza.',
  december: 'abe.',
  from: '(e)tik',
  until:  '(e)ra',
  untilThe:  'Noiz arte:',
  at:  'Ordua:',
  success: 'Success',
  recoveryEmailSent:
    'E-mail hau sartuta duen erabiltzailerik badago, mezu elektroniko bat bidaliko diogu pasahitza berreskuratzeko',
  resetData: 'Kontuaren datuak berrezarri dira',
  accountDataSaved:  'Kontuaren datuak eguneratu dira',
  accountNotRegistered: 'Zure posta elektronikoa ez dago erregistratuta',
  userExists: 'Badago dagoeneko erabiltzaile bat mezu elektroniko honekin',
  activateYourAccount: 'Aktibatu zure kontua erabiltzaile gisa sartu ahal izateko',
  loginSuccess:  'Saioa ondo hasi duzu',
  logoutSuccess:  'Saioa ondo itxi duzu',
  intermediateStop:  'Tarteko geltoki',
  noLineData:  'Ez dugu linea honi buruzko daturik.',
  stationOf: 'Geltoki: ',
  lineReport: 'Lineari buruz berri eman',
  stopOf:  'Geltokia:',
  removeAccount: 'Ezabatu kontua',
  removeAccountNotice:
    'Ziur zaude Moveuskadiren kontua ezabatu nahi duzula? Baja baieztatuz gero, sisteman gordetako informazio guztia galduko duzu (gogokoak, alertak, mezuak, etab.), eta ezingo da berreskuratu.',
  undo: 'Desegin',
  alias:  'Erabiltzaile-izena',
  aliasColon:  'Erabiltzaile-izena',
  stopRemoved:  'Geltoki ezabatu da',
  directionSaved:  'Helbidea gorde da',
  directionRemoved:  'Helbidea ezabatu da',
  poiSaved:  'Interesgunea gorde da',
  poiRemoved:  'Interesgunea ezabatu da',
  planSaved:  'Ibilbidea gorde da',
  planRemoved:  'Ibilbidea ezabatu da',
  accountCreated: 'Kontua sortu da. ontua aktibatzeko berrikusi zure posta.',
  noInternetConnection: 'Ez dago Interneteko konexiorik',
  serviceError:  'Akatsa zerbitzuan',
  reviewLoginOrPass: 'Egiaztatu zure posta elektronikoa edo pasahitza',
  walk: 'Oinez',
  bus: 'Autobusak',
  subway: 'Metroa',
  funicular:  'Funikularra',
  tranvia: 'Tranbia',
  rentalBike:  'Alokairuko bizikleta',
  bike: 'Bizikleta',
  train: 'Trena',
  bikes: 'Bizikletak',
  capacity: 'Lekuak',
  markersGroup: 'Markatzaile taldea',
  leaveTitle: 'Gaia idatzi',
  reportIncidence:  'Akats baten berri eman',
  stopIncidence:  'Geltoki-gorabehera',
  lineIncidence:  'Linea-gorabehera',
  planIncidence:  'Plangintza-gorabehera',
  operatorIncidence:  'Operadore-gorabehera',
  systemIncidence:  'Sistema/aplikazio-gorabehera',
  bikeStation:  'Bizikleta geltoka',
  poiContainer: 'Interesgune-multzoa',
  stopContainer: 'Geltoki-multzoa',
  container:'Markatzaile-multzoa',
  moveInformative: 'Moveuskadi-ren edukia esklusiboki informatiboa da. Datuen hornitzailea dagokion garraio-operadorea da. Eusko Jaurlaritzak ez du erantzungo informazio hori erabiltzeak eragin ditzakeen ondorio, kalte edo galeren aurrean.',
  visualizer_filter_markers_pois: 'Interesgunea ezkutatzea',
  stopSchedule: 'Geltokien ordutegia',
  searchEmptyResults: 'Ez da emaitzarik aurkitu',
  searchMoreResults: 'Gehiago kargatu',
  maxWalkDistance_title: 'Oinez ibiltzeko gehieneko denbora',
  maxWalkDistance_placeholder: 'Denbora minututan',
  menu_lines_search: 'Linea-bilaketa',
  menu_line_filter_empty: 'Ez da lerrorik aurkitu.',
  menu_line_selector_empty: 'Ez da operadorerik hautatu.',
  select_operator_placeholder: 'Operadore bat hautatu',
  menu_lines: 'Linea zerrenda',
  transit_news: 'Albisteak eta oharrak',
  incidence_line: 'Lineak Oharrak',
  incidence_stop: 'Geltokiak Oharrak',
  filters_newsTransit_operators: 'Operadoreak iragazi',
  filters_newsTransit_operators_desc: 'Hautatu zein operadorerentzat ikusi nahi dituzun albisteak eta oharrak.',
  stop_time_real_late: 'minutuko atzerapena',
  stop_time_real_ahead: 'minutuko aurrerapena',
  stop_time_real_late_min: 'minutuko atzerapena',
  stop_time_real_ahead_min: 'minutuko aurrerapena',
  stop_time_real_theory: 'Programatutako',
  favorites_empty_plansMessage:
    'Badirudi gogokoetan ez duzula ibilbiderik gorde; aukeratu gehien erabiltzen dituzunak eta gorde azkar sartu ahal izateko.',
  favorites_empty_poisMessage:
    'Badirudi gogokoetan ez duzula interesgunerik gorde; aukeratu gehien erabiltzen dituzunak eta gorde azkar sartu ahal izateko.',
  goToFav: 'Joan gogokoetara',
  subscriptions_empty_linesMessage:
    'Badirudi ez duzula garraiobide-linearik alerta aktibatuekin, aktiba itzazu gehien interesatzen zaizkizun garraiobide-lineen alertak.',
  subscriptions_empty_newsMessage:
    'Badirudi ez duzula albiste-agentziarik alerta aktibatuekin, aktiba itzazu gehien interesatzen zaizkizun albiste-agentzien alertak.',
  subscriptions_empty_stopsMessage:
    'Badirudi ez duzula geltokirik alerta aktibatuekin, aktiba itzazu gehien interesatzen zaizkizun geltokien alertak.',
  affected_lines: 'Eragindako lineak',
  affected_stops: 'Eragindako geldialdiak',
  fares: 'Tarifak',

  lastUpdatePrivacy: 'Última actualización. 18/03/2023',
  firstParagraphPrivacy: 'MOVEUSKADIn aplikatutako pribatutasun-politikari buruzko adierazpen honen helburu nagusia hurrengoa da: erabiltzaileei ulertzen laguntzea zer konpromiso hartu ditugun aplikazioak eskaintzen dituen eta erabiltzaileak 	borondatez erabili nahi dituen funtzionaltasunen eta zerbitzuen ondorioz eska dakizkiokeen behar-beharrezko datu sentikorrak babesteko, tratatzeko, atxikitzeko eta erabiltzeko. ',
  secondParagraphPrivacy: '¿Usamos código de terceros?',
  thirdParagraphPrivacy: 'Gure pribatutasun-politikari buruzko edozein zalantza edo kontsulta egiteko, honako helbide honetara jo dezakezu: ',
  fourthParagraphPrivacy: 'Irakurri arretaz gure Pribatutasun-politika MOVEUSKADI aplikazioa erabili aurretik. Pribatutasun-politika honetan deskribatutako zehaztapenekin ados ez bazaude, beti aukera dezakezu datu eta baimen gehigarriak behar dituzten MOVEUSKADI aplikazioaren funtzio eta zerbitzu espezifikoak ez erabiltzea, edo aplikazioa erabat erabiltzeari uztea, baita modu anonimoan ere. ',
  firstSubtitle:'Aplikazioaren helburuak',
  fiveParagraph: 'MOVEUSKADI garraio publikoaren informazio-sistema Eusko Jaurlaritzak garatu du, garraio publikoaren eskaintzari buruzko informazio-edukia eskaintzeko eta haren erabilera sustatzeko. ',
  sixParagraph: 'Aplikazioaren funtzio nagusietako batzuk hurrengo hauek dira: ',
  firstPointList: ['Garraio publikoaren ordutegiak kontsultatzea.',
  'Garraio publikoko geltokiak kontsultatzea.',
  'Garraio publikoaren bidezko ibilbideak planifikatzea, jatorria eta helmuga adierazita, erabiltzailearen kokapena bera izan daitekeena.',
  'Ibilbide bat planifikatu ondoren, gidaritza-zerbitzu bat gauzatzeko aukera, erabiltzaileari bere ibilbidea osatzeko zer urrats eman behar dituen jakiten laguntzeko. ',
  'Gogoko helbideak edo interes-puntuak gordetzeko aukera.',
  'Garraio publikoarekin lotutako jakinarazpenak eta alertak jasotzeko aukera.'
  ],
  secondSubtitle: 'MOVEUSKADIk zer informazio biltzen eta erabiltzen du',
  sevenParagraph:'MOVEUSKADIk eskaintzen dituen eta erabiltzaileak erabili nahi dituen funtzionalitate eta zerbitzu gehigarriak erabiltzeko, aplikazioak aldez aurretik abisu-mezu bat aurkeztuko du. Bertan, argi eta garbi jakinaraziko zaio MOVEUSKADIk eskuratu behar dituen gailuaren datuak eta zerbitzuak biltzeko errekerimendua, eta berariazko baimena eskatuko zaio datuak biltzeko eta erabiltzeko. Baimen horri uko egiten bazaio, informazio hori ez da bilduko edo erabiliko, eta, beraz, ezin izango da eskatutako funtzionaltasuna eta zerbitzua erabili.',
  eightParagraph: 'Hala ere, MOVEUSKADIk berariazko baimena badu ere, edozein unetan ezeztatu ahal izango da bai bildutako datu pertsonal eta sentikorretarako sarbidea, bai baimena eman zaien gailuaren zerbitzuen erabilera.',
  thirdSubtitle: 'Helbide elektronikoa',
  nineParagraph:'MOVEUSKADIk kontu pertsonal bakarra sortzeko aukera ematen du, erabiltzaileak ibilbide, helbide edo garraiobide gogokoenei buruz duen informazioa gorde ahal izateko. MOVEUSKADIn kontu pertsonal bat konfiguratzean, zure helbide elektronikoa baino ez duzu gurekin partekatuko.',
  tenParagraph:'Kontu pertsonal bat sortuz gero, MOVEUSKADIk adinari eta generoari buruzko datuak ere bilduko ditu, soilik helburu estatistiketarako.',
  elevenParagraph:'MOVEUSKADIren zerbitzua erabiltzaile guztientzat eskuragarri dagoen arren, erregistratutako kontu pertsonal bat konfiguratzeak aplikazioak eskaintzen dituen funtzio eta zerbitzu hauetara sartzeko aukera ematen du:',
  secondPointList:['Gogokoak gordetzea: geltokiak, interesguneak, kokapenak eta ibilbideak',
      'Akatsak jakinarazteko mezuak bidaltzea edo',
      'Operadoreen alertetara harpidetzea '],
  fourthSubtitle:'Kokapen geografikoari buruzko informazioa',
  twelveParagraph:'MOVEUSKADIk kokapenari (geolokalizazioa) eta planifikatutako ibilbideari edo bidaiari buruzko informazioa bil dezake, funtzionalitate eta zerbitzu osagarrietarako sarbidea eskaintzeko. Informazio hau jasotzen dugu:',
  thirdPointList:['Kokapena: Informazio honen helburua da hurbileko garraio-aukerak erakustea eta abiapuntua edo helmuga duten ibilbideak planifikatzea, gailuaren kokapenaren arabera. Funtzionaltasun hori eskuratzeko, erabiltzaileak borondatez "geolokalizazioa" botoia sakatu behar du.','Kokapena bigarren planoan: Informazio honen helburua da planifikatutako ibilbide batean gidaritza-funtzioak gaitu ahal izatea. Gailua bidean zehar non kokatzen den egiaztatzen da, eta erabiltzaileari jakinarazten zaio noiz egin behar duen ekintza bat ibilbidean (hurbileko helmuga-geltokiaren abisua), aplikazioa itxita eta erabili gabe egon arren. MOVEUSKADIk kokapen-datuak biltzen ditu bigarren planoan, baita aplikazioa erabiltzen ez denean ere, esperientzia maximizatzeko eta gidaritza-zerbitzu horiek eskaintzeko (hurbileko helmuga-geltokiaren abisua). Funtzionaltasun hori soilik aktibatzen da erabiltzaileak bere borondatez "Jarraibideak" botoia sakatzen badu.'],
  thirteenParagraph:'Kokapena lortzeko, kokapen zehatza biltzen dugu, baita GPS seinaleak eta gailuaren sentsorearen beste datu batzuk ere, hala nola giroskopioak/osziloskopioak, zerbitzua instalatuta eta aktibatuta dagoen gailu mugikorrak bidaliak.',
  fourteenParagraph:'Kokapen-zerbitzuak edozein unetan desaktibatzea aukera dezakezu, eta aplikazioa erabiltzen jarraitu zerbitzu horiek eskatzen ez duten funtzioekin.',
  fiveSubtitle:'Hirugarrenen kodea ',
  fiveteenParagraph:'Enviar actualizaciones, avisos, anuncios e información adicional relacionada con los servicios de transporte.',
  sixSubtitle: 'Que información comparte MOVEUSKADI',
  sixteenParagraph:'Moveuskadi utilizará los datos recopilados con los siguientes propósitos:',
  fourthPointList:['Ofrecer los servicios indicados en el apartado “Objetivo de la aplicación”.',
  'Personalizar el contenido y personalizar la experiencia.',
  'Permitir que las personas usuarias envíen mensajes sobre errores o mejoras de la aplicación.',
  'Dar servicio de soporte y responder a las consultas realizadas.',
  'Enviar actualizaciones, avisos, anuncios e información adicional relacionada con los servicios de transporte.' +
  'Uso de datos anónimos para fines estadísticos, de mantenimiento, de desarrollo y de mejora de los servicios que ofrece MOVEUSKADI. Las rutas y viajes planificados configurados por las personas usuarias se almacenan de manera anónima, no estando por tanto asociadas en ningún caso a la persona usuaria registrada que lo configuró.'
  ],
  sevenSubtitle:'Seguridad de los datos',
  seventeenParagraph:'La aplicación MOVEUSKADI cumple estrictamente con la normativa europea, estatal y autonómica sobre protección de datos personales.',
  eighteenParagraph:'En cumplimiento del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, se informa que los datos de carácter personal que se recopilen serán tratados e incorporados a la actividad de tratamiento denominada: Sistema de información de transporte público: MOVEUSKADI. ',
  nineteenParagraph:'Las personas usuarias de la aplicación MOVEUSKADI tiene plenamente garantizados los siguientes derechos:',
  fivePointList:['Retirar su consentimiento: Puede revocar en cualquier momento el consentimiento que ha otorgado, sin que esto afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.',
  'Acceso: Puede solicitar el acceso a sus datos personales y a obtener información sobre sus datos de carácter personal sometidos a tratamiento.',
  'Rectificación: Permite corregir errores y modificar los datos que resulten ser inexactos o incompletos.',
  'Supresión (derecho al olvido): Solicitar que los datos sean suprimidos, salvo que exista obligación legal de conservarlos y/o no prevalezcan otros motivos legítimos para su tratamiento. Para ello, podrá seleccionando la opción “Borrar cuenta” dentro de la sección “Gestionar Cuenta” del apartado “Cuenta de la aplicación”.',
  'Limitación: Bajo las condiciones establecida legalmente, podrá solicitar que el tratamiento se paralice, de tal manera que se evite su tratamiento por el responsable quien únicamente los conservará para el ejercicio o la defensa de reclamaciones.',
  'Portabilidad: Podrá recibir sus datos personales que sean de su incumbencia, y transmitirlos directamente a otro responsable en un formato estructura, de uso común y lectura mecánica.',
  'Oposición: En determinadas circunstancias y por motivos relacionados con su situación particular, podrán oponerse al tratamiento de sus datos. En tal caso, el responsable dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.'
  ],
  twentyParagraph:'Los datos personales y sensibles de las personas usuarias recopilados por MOVEUSKADI se mantendrán en el sistema el tiempo estrictamente necesario para cumplir con la finalidad para la que se recabaron y para determinar las posibles responsabilidades que se deriven.',
  firstInfoList:['Dirección de Planificación del Transporte. Departamento de Planificación Territorial, Vivienda y Transportes del Gobierno Vasco',
  'Donostia-San Sebastián, 01010, Vitoria-Gasteiz, Álava',
  'Teléfono +(34) 945 018 000',
  'Página web: '],
  secondInfoList:['Delegada de protección de datos del Gobierno Vasco',
  'Donostia-San Sebastián 1, 01010, Vitoria-Gasteiz, Álava',
  'Teléfono: +(34) 945 018 680',
  'Página web: '],
  twentyoneParagraph:'Asimismo, cuando considere que no ha obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Agencia Vasca de Protección de Datos, cuyos datos de contacto son los siguientes:',
  thirdInfoList:['Agencia Vasca de Protección de Datos',
  'C/ Beato Tomás de Zumárraga, 71, 3ª Planta',
  '01008 Vitoria – Gasteiz',
  'Tel. + (34) 945 016 230',
  'Fax. + (34) 945 016 231',
  'e-Mail: '
  ],
  twentytwoParagraph:'Puede obtener información detallada y extensa sobre los aspectos relativos a la seguridad de los datos y a la protección de datos personales aplicados en MOVEUSKADI en las siguientes páginas:',
  forthInfoList:['Normativa sobre protección de datos personales:',
    'Protección de datos personales del Sistema de información de transporte público MOVESUKADI:',
    'Actividad de tratamiento “Sistema de información de transporte público: MOVEUSKADI”: ',
  ],
  forthInfoListLinks:['https://www.euskadi.eus/normativa-proteccion-de-datos-personales/web01-a2datuba/es/ ',
    'https://www.euskadi.eus/clausulas-informativas/web01-sedepd/es/transparencia/158400-capa2-es.shtml',
    'https://www.euskadi.eus/clausulas-informativas/web01-sedepd/es/transparencia/158400-capa3-es.shtm',
  ],
  lines_near_stop: 'Geltoki honetatik gertu dauden lineak',
  lines_near_marker: 'Hurbileko lineak',
  modify_file: 'Aldatu eranskina',
  add_file: 'Erantsi erantsita',
  error_file_support: 'Artxiboa ez da onartzen',
  input_chat_placeholder: 'Gehitu mezua',
  stop_time_real_in_time: 'Aurreikusitako orduaren arabera',
  visualizer_closestStopsLines: 'Hurbileko geltoki eta lineak',
};
