import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import { Apis } from '../../../../../../domain/Apis';
import { useGetLineVehiclesQuery } from '../../../../../../redux/login/services/vehiclesServiceApi';
import { vehicleSlice } from '../../../../../../redux/slices/vehicleSlice';
import TimeUtils from '../../../../../../utils/TimeUtils';
import { VM } from '../../../../../../viewmodel/VM';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import LineIcon from '../stopDetail/LineIcon';
import { lineSlice } from '../../../../../../redux/slices/lineSlice';

export default function LineSynoptic(props) {
  const theme = useTheme();
  const Time = R.drawables.commons.time;
  const language = useLanguage();
  const dispatch = useDispatch();
  const t = useTranslate();
  const [renderSynoptic, setRenderSynoptic] = useState(<ClipLoader size={20} />);
  const { data: vehicleData, error: vehicleError } = useGetLineVehiclesQuery(
    {line:props.line.id ? JSON.stringify(props.line.id) : undefined,tripId:VM.lines.selected?.tripId},
    {
      pollingInterval: 60000
    }
  );

  useEffect(() => {
    let mounted = true;
    async function getFromApi() {
      dispatch(vehicleSlice.actions.updateLineVehicles([]));

      if (vehicleData && mounted) {
        dispatch(vehicleSlice.actions.updateLineVehicles(vehicleData));
      }
      if (vehicleError && mounted) {
        dispatch(vehicleSlice.actions.updateLineVehicles([]));
      }
    }

    getFromApi();

    return () => {
      mounted = false;
    };
  }, [dispatch, vehicleError, props.line.id, vehicleData]);

  useEffect(() => {
    setRenderSynoptic(<ClipLoader size={20} />);
    async function fetchData() {
      if (props.line && props.stops) setRenderSynoptic(await getRenderSynoptic());
    }
    fetchData();
  }, [props.stops]);

  const styles = {
    content: {
      padding: 10,
      backgroundColor: theme.colors.backgroundLight,
      maxHeight: window.innerHeight - 300,
      overflow: 'scroll'
    },
    row: {
      display: 'flex',
      marginBottom: 5,
      marginTop: 5,
      flexWrap: 'wrap',
      justifyContent: 'right',
      alignContent: 'right',
      textAlign: 'right'
    },
    column: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    },
    text: {
      color: theme.colors.textPrimary,
      fontSize: 14,
      wordBreak: 'break-word',
      fontWeight: 600,
      cursor: 'pointer'
    },
    synoptic: {
      padding: 10,
      backgroundColor: theme.colors.white,
      minHeight: 30
    },
    circle: {
      backgroundColor: props?.line?.routeColor === '#FFFFFF' ? `#${props?.line?.routeTextColor ?? '000000'}` : props?.line?.routeColor,
      border: `3px solid ${props?.line?.routeColor === '#FFFFFF' ? `#${props?.line?.routeTextColor ?? '000000'}` : props?.line?.routeColor}`,
      width: 4,
      height: 4,
      marginLeft: -2.5,
      marginTop: 6,
      borderRadius: 100
    },
    line: {
      width: 5,
      height: '120%',
      backgroundColor: props?.line?.routeColor === '#FFFFFF' ? `#${props?.line?.routeTextColor ?? '000000'}` : props?.line?.routeColor,
    },
    iconTime: {
      marginTop: 2,
      fill: theme.colors.black,
      marginRight: 5
    }
  };
  const lineClick = async (line) => {
    let found = false;
    let linestimes = await Apis.stops.getStopLinestimes(language, line.agencyStopId);
    if (linestimes?.error) {
      return;
    }
    let lines = await Apis.stops.getStopLines(language, line.agencyStopId);
    for (let linestime in linestimes) {
      if (linestimes[linestime].lineId === line.id) {
        found = true;
        let day = props.line?.day ?? linestimes[linestime].day;
        await VM.lines.setSelected(line.id, linestimes[linestime].tripId, line.agencyStopId, day);
        break;
      }
    }
    if (!found) await VM.lines.setSelected(line.id, null, line.agencyStopId);
  };

  const stopClick = async (stop) => {
    let stopSelected = await Apis.stops.getStop(language, stop);
    dispatch(lineSlice.actions.updateFilteredLine(props.line.id));
    props.onClickMarker(stopSelected, null, 17.5);
  };

  const renderIconLines = async (stops) => {
    let res = [];
    let iconOperator;
    let lines = getLines(stops);
    for (let line in lines) {
      let operator = [];
      iconOperator = await VM.icons.getIcon(line, 'agency');
      operator.push(
        <LineIcon
          key={line}
          icon={iconOperator ?? R.drawables.general.Ic_Reportar}
          style={{ margin: 1 }}
        />
      );
      for (let element in lines[line]) {
        if (props.line.id !== lines[line][element].id) {
          if (VM.lines.data[lines[line][element].id]?.code)
            operator.push(
              <LineIcon
                text={VM.lines.data[lines[line][element].id].code}
                onClick={() => {
                  lineClick(lines[line][element]);
                }}
                key={lines[line][element].id + lines[line][element].agencyStopId}
                style={{ margin: 1 }}
                color={
                  lines[line][element].routeColor
                    ? '#' + lines[line][element].routeColor
                    : theme.colors.main
                }
                textColor={'#' + VM.lines.data[lines[line][element].id].routeTextColor}
              />
            );
        }
      }

      if (operator.length === 1) operator.pop();
      res = res.concat(operator);
    }
    return res;
  };

  const getLines = (lines) => {
    let element = {};
    for (let line in lines) {
      if (!element[VM.lines.data[lines[line].id]?.agencyOriginId]) {
        element[VM.lines.data[lines[line].id]?.agencyOriginId] = [];
      }
      element[VM.lines.data[lines[line].id]?.agencyOriginId].push(lines[line]);
    }
    return element;
  };

  const getRenderSynoptic = async () => {
    let res = [];
    if (props.stops == undefined || props.stops == null || props.stops.length == 0) {
      res.push(<div>{t('noLineData')}</div>);
    } else {
      for (let stop in props.stopTimes) {
        if (
          props.stopTimes[stop] === 0 ||
          props.stopTimes[stop]?.day !== props.stopTimes[stop - 1]?.day
        ) {
          let dj = TimeUtils.convertFromJulianToDate(props.line?.day ?? props.stopTimes[stop].day);
          let today = new Date();
          today.setHours(14, 0, 0);
          res.push(
            <div
              className={'subtitle marginBottom20'}
              style={{ textAlign: 'left' }}
              key={props.stopTimes[stop]?.day + stop}>
                {t('stopSchedule')}
                {dj.toDateString() === today.toDateString()
                    ? ` ${t('today').toLowerCase()}`
                    : ` ${TimeUtils.getDate(dj, language)}`}
            </div>
          );
        }
        let iconOperator = await VM.icons.getIcon(props.stopTimes[stop]?.agencyOriginId, 'agency');
        let stopInfo = [];
        for (let stop2 in props.stops) {
          if (props.stops[stop2].id === props.stopTimes[stop].stopId) {
            stopInfo = props.stops[stop2].lines;
          }
        }
        res.push(
          <div
            style={{
              ...styles.row,
              ...(props.stop?.id === props.stopTimes[stop]?.stopId
                ? { backgroundColor: theme.colors.border }
                : null)
            }}
            key={stop}>
            <div style={{ ...styles.column, ...{ flex: 2, alignItems: 'end' } }}>
              <div style={styles.text} onClick={() => stopClick(props.stopTimes[stop].stopId)}>
                {props.stopTimes[stop]?.nameStop}
              </div>
              <div style={{ ...styles.row }}>{await renderIconLines(stopInfo, iconOperator)}</div>
            </div>

            <div style={{ ...styles.column, ...{ flex: 1 } }}>
              <div style={{ height: '100%' }}>
                <div
                  style={
                    stop != Object.keys(props.stopTimes).length - 1
                      ? { ...styles.line }
                      : { marginLeft: 2.5 }
                  }>
                  <div
                    style={
                      stop === 0
                        ? { ...styles.circle, ...{ backgroundColor: theme.colors.white } }
                        : styles.circle
                    }
                  />
                </div>
              </div>
            </div>

            <div style={{ ...styles.column, ...{ flex: 2, alignItems: 'start' } }}>
              <div style={styles.row}>
                <Time style={styles.iconTime} />
                <div style={styles.text}>{props.stopTimes[stop]?.time}</div>
              </div>
            </div>
          </div>
        );
      }
    }

    return res;
  };
  return (
    <div style={{ ...styles.content, ...props.style }}>
      <div style={styles.synoptic}>{renderSynoptic}</div>
    </div>
  );
}
