import { Apis } from '../../../domain/Apis';
import { VM } from '../../../viewmodel/VM';
import { LanguageProvider } from '../../context/languageContext';
import { LoadingProvider } from '../../context/loadingContext';
import { ModalProvider } from '../../context/modalContext';
import { ResponsiveProvider } from '../../context/responsiveContext';
import { ThemeProvider } from '../../context/themeContext';
import { R } from '../../R';
import LoginScreen from '../LoginScreen/LoginScreen';
import RecoveryScreen from './RecoveryScreen';

function AppInitRecoveryPwd() {
  R.initialize();
  Apis.initialize();
  VM.initialize();
  return (
    <div className="App">
      <RecoveryScreen />
      <LoginScreen />
    </div>
  );
}

export default AppInitRecoveryPwd;
