import { useState } from 'react';
import { useTranslate } from '../../context/languageContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';
import ButtonIcon from '../commons/ButtonIcon';

export default function InputPassword(props: any) {
  const theme = useTheme();
  const Report = R.drawables.general.Ic_Reportar;
  const Ic_eye = R.drawables.general.Ic_eye;
  const Ic_eye_off = R.drawables.general.Ic_eyeoff;
  const t = useTranslate();
  const [passwordEye, setPasswordEye] = useState(false);

  const styles = {
    input: {
      backgroundColor: '#005891',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '150%',
      border: '0',
      borderBottom: '1px solid #0078B2',
      padding: '1.5% 0',
      margin: '0px 0px 20px',
      // marginBottom: '4.5%',
      color: '#fff'
    },
    label: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: ' 150%',
      display: 'flex',
      alignItems: ' flex-end',
      color: props.labelColor ?? '#A3D4EA'
    },
    ButtonIcon: {
      marginLeft: 'auto',
      marginTop: '-55px',
      border: '0px',
      background: 'transparent'
    }
  } as const;

  return (
    <>
      <label style={styles.label} className={props.password?.length ? '' : 'visibilityHidden'}>
        {props.label ?? t('password')}
      </label>
      <input
        className={props.className ?? 'modal-input'}
        style={styles.input}
        type={passwordEye ? 'text' : 'password'}
        name="password"
        placeholder={props.placeHolder ?? t('password')}
        value={props.password ? props.password : ''}
        maxLength={30}
        onChange={(e) => props.setPassword(e.target.value)}
        required
      />
      <ButtonIcon
        icon={passwordEye ? Ic_eye : Ic_eye_off}
        background={theme.colors.primaryDark}
        borderColor={theme.colors.primaryDark}
        style={styles.ButtonIcon}
        onClick={(e: { preventDefault: () => void }) => {
          e.preventDefault();
          setPasswordEye(!passwordEye);
        }}
        size={'35px'}
      />
    </>
  );
}
