import { useTranslate } from '../../context/languageContext';

export default function InputName({
  value,
  setValue,
  background,
  placeholder,
  className
}: {
  value: string | null | undefined;
  setValue: any;
  background?: string;
  placeholder?: string;
  className?: string;
}) {
  const styles = {
    input: {
      background: background ? background : '#005891',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '150%',
      border: '0',
      borderBottom: '1px solid #0078B2',
      margin: '0px 0px 20px',
      color: '#A3D4EA'
    },
    label: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: ' 150%',
      display: 'flex',
      alignItems: ' flex-end',
      color: '#A3D4EA'
    }
  } as const;

  const t = useTranslate();

  return (
    <>
      <label className={value?.length ? '' : 'visibilityHidden'} style={styles.label}>
        {placeholder ? placeholder : t('userName')}
      </label>
      <input
        className={className}
        style={styles.input}
        type="string"
        name="name"
        id="name"
        placeholder={placeholder ? placeholder : t('userName')}
        value={value ? value : ''}
        onChange={(e) => setValue(e.target.value)}
        required
      />
    </>
  );
}
