import React from "react";
import {useTheme} from "../../../../../../context/themeContext";
import LineIcon from "../../../searcherOption/stopDetail/LineIcon";
import {R} from "../../../../../../R";
import {VM} from "../../../../../../../viewmodel/VM";

export default function RouteSynoptic(props, ref) {

    const theme = useTheme();
    const Walking = R.drawables.general.Ic_walking

    const styles={
        content: {
            borderRadius: "12px",
            cursor: 'pointer',
            display:'flex',
            width:'100%',
            overflow:'hidden',
            flexWrap:'wrap',
            marginBottom:5
        },
        row:{
            marginTop:5,
            display:'flex',
            alignItems:'center',
        },
        inter:{
            color:theme.colors.white,
            display:'flex',
            padding:1,
            paddingInline:6,
            fontSize:14,
            alignItems:'center',
            backgroundColor: theme.colors.contextualError,
            borderRadius:50
        },
        icon: {
            fill: theme.colors.darkGray,
            width:18,
            marginRight:5,
            marginTop:5
        },
        time:{
            fontSize:12,
            marginTop:10,
            fontWeight:600,
        },
        circle:{
            borderRadius: 50,
            height:5,
            width:5,
            marginInline:10,
            backgroundColor:theme.colors.mediumGray
        }
    }

    const renderSynoptic = () => {
        let res = [];
        let inter=0;
        for(let i = 0; i<props.lineSynoptic?.length; i++){
            if(props.lineSynoptic[i].mode==='WALK')
                res.push(<div style={styles.row} key={"line_synoptic"+i}>
                    <Walking/>
                    <div style={styles.time}>{props.lineSynoptic[i].time}</div>
                    {i+1 !== props.lineSynoptic.length
                        ? <div style={styles.circle}/>
                        : null
                    }
                </div>)
            else
            res.push(<div style={styles.row} key={"line_synoptic"+i}>
                <LineIcon icon={props.lineSynoptic[i].icon}
                          style={{marginRight:2}}/>
                <LineIcon color={props.lineSynoptic[i].routeColor}
                          textColor={props.lineSynoptic[i].routeTextColor}
                          text={props.lineSynoptic[i].tripShortName}/>
                {i+1 !== props.lineSynoptic.length
                    ? <div style={styles.circle}/>
                    : null
                }
            </div>)
            if(i<props.lineSynoptic?.length-1&&
                props.lineSynoptic[i+1]?.mode==='WALK' && props.lineSynoptic[i]?.mode==='WALK'
                || props.lineSynoptic[i]?.to.lat.toFixed(5)===VM.plannerSegments.data[inter]?.coords.latitude.toFixed(5) && props.lineSynoptic[i]?.to.lon.toFixed(5)===VM.plannerSegments.data[inter]?.coords.longitude.toFixed(5)){
                res.push(<div style={styles.row} key={"inter"+i}>
                    <div style={styles.inter}>{inter+1}</div>
                    {i+1 !== props.lineSynoptic.length
                        ? <div style={styles.circle}/>
                        : null
                    }
                </div>)
                inter++
            }
        }
        return res;
    }

    return (
        <div style={props.style}>
            <div style={styles.content}>
                {renderSynoptic()}
            </div>
        </div>
    );
}
