export interface Environment {
    scheme: string;
    lang: string;
    screen: {width: number, height: number};
}

const resources = {
    colors: {
        light: require('../res/values/colors/light').colors,
        dark: require('../res/values/colors/dark').colors,
    },

    strings: {
        es: require('../res/values/strings/es').strings,
        en: require('../res/values/strings/en').strings,
        eu: require('../res/values/strings/eu').strings,
        fr: require('../res/values/strings/fr').strings,
    },

    constants: require('../res/values/constants').constants,
    styles: require('../res/values/styles').styles,
    drawables: require('../res/values/drawables').drawables,
};

export const R = {
    initialize() {
        this.onChangeListeners = [];
        this.env = {
            scheme: 'light',
            lang: 'es',
            screen: {width: 0, height: 0},
        };

        this.build(this.env);
    },
    drawables: resources.drawables,

    /**
     * Modifica el idioma en el que se muestra la aplicación y carga en memoria los recursos dependientes
     * del idioma seleccionado.
     *
     * @param lang El idioma a mostrar los textos.
     */
    setLanguage(lang: string) {
        this.env.lang = lang;
        this.build(this.env);
    },

    /**
     * Modifica el esquema de color (modo día / modo noche) en el que se muestra la aplicación y carga
     * en memoria los recursos dependientes del esquema seleccionado.
     *
     * @param colorScheme El esquema de color de la aplicación.
     */
    setColorScheme(colorScheme: string) {
        this.env.scheme = colorScheme;
        this.build(this.env);
    },

    /**
     * Modifica los límites de la pantalla (ancho y alto) en el que se muestra la aplicación y carga
     * en memoria los recursos dependientes de los límites seleccionados.
     *
     * @param screenBounds Los límtes de la pantalla del dispositivo
     */
    setScreenBounds(screenBounds: {width: number, height: number}) {
        this.env.screen = screenBounds;
        this.build(this.env);
    },

    /**
     * Carga los recursos en memoria en función de las variables de entorno del sistema.
     *
     * @param env Las variables de entorno del sistema.
     */
    build(env) {
        this.colors = resources.colors[env.scheme];
        this.strings = resources.strings[env.lang];
        this.constants = resources.constants;
        this.styles = resources.styles;
        this.drawables = resources.drawables;
        this.raw = resources.raw;
        //this.sounds = resources.sounds;
        this.notifyChange(env);
    },

    /**
     * Añade un evento escucha para cada cambio que haya habido en las variables de entorno del sistema y
     * propaga el cambio al escuchador.
     *
     * @param onChangeListener La función a ejecutar cuando cambia.
     */
    onChange(onChangeListener: () => Environment) {
        this.onChangeListeners.push(onChangeListener);
    },

    notifyChange(env) {
        for (let listener of this.onChangeListeners) {
            try {
                listener?.(env);
            } catch (ignored) {}
        }
    },
};
