import {R} from "../../../../../../../R";
import {useTranslate} from "../../../../../../../context/languageContext";
import {VM} from "../../../../../../../../viewmodel/VM";
import {Collapse} from "react-collapse/lib/Collapse";
import Button from "../../../../../../../components/commons/Button";
import {useTheme} from "../../../../../../../context/themeContext";

export default function TransportsFilters(props, ref) {
    const t = useTranslate()
    const theme = useTheme()
    const Down = R.drawables.general.Ic_ChevronDownSmall
    const Up = R.drawables.general.Ic_ChevronUpSmall

    const transports = [
        {
            txt:'walk',
            icon:R.drawables.general.Ic_walking,
            id:'WALK'
        },
        {
            txt:'bus',
            icon:R.drawables.transport.Ic_Bus,
            id:'BUS'
        },
        {
            txt:'subway',
            icon:R.drawables.transport.Ic_metro_m,
            id:'SUBWAY'
        },
        {
            txt:'train',
            icon:R.drawables.transport.Ic_Tren,
            id:'RAIL'
        },
        {
            txt:'tranvia',
            icon:R.drawables.transport.Ic_Tranvia,
            id:'TRAM'
        },
        /*{
            txt:'bike',
            icon:R.drawables.transport.Ic_Bike,
            id:'BICYCLE'
        },
        {
            txt:'rentalBike',
            icon:R.drawables.transport.Ic_Bike,
            id:'BICYCLE_RENT'
        },*/
        {
            txt:'funicular',
            icon:R.drawables.transport.Ic_Funicular,
            id:'FUNICULAR'
        },
    ]

    const styles={
        mainText:{
            fontSize:14,
            fontWeight:600,
            lineHeight:'240%',
            textAlign: 'left'
        },
        preference:{
            cursor:'pointer',
            display:'flex',
            alignItems: 'center'
        },
        tags: {
            margin: '1.5px'
        },
    }

    const renderTransports = () => {
        let res = [];
        for (let transport in transports) {
            res.push(<Button           key={transport}
                                       selected={VM.plannerConfig.data.modes.indexOf(transports[transport].id)>=0}
                                       style={styles.tags}
                                       text={t(transports[transport].txt)}
                                       icon={transports[transport].icon}
                                       iconColor={theme.colors.black}
                                       textColor={theme.colors.black}
                                       borderColor={theme.colors.border}
                                       onClick={() => {
                                           VM.plannerConfig.updateModes(transports[transport].id)
                                       }}
                                       size={'37.5px'}
                                       iconSize={'15px'}/>)
        }
        return res;
    }

    return (
        <div style={{...props.style}}>
            <div style={styles.preference} onClick={()=>props.setOpened()}>
                <div style={styles.mainText}>{t("transport_modes")}</div>
                {props.opened
                    ? <Up style={{marginLeft: 'auto'}}/>
                    : <Down style={{marginLeft: 'auto'}}/>
                }
            </div>
            <Collapse isOpened={props.opened}>
                <div style={{display:'flex', flexWrap:'wrap'}}>
                    {renderTransports()}
                </div>
            </Collapse>
        </div>
    );
}
