import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useSearchNoraMutation } from '../../../../../../redux/login/search/SearchServiceApi';
import { searchSlice } from '../../../../../../redux/slices/searchSlice';
import { useLanguage, useTranslate } from '../../../../../context/languageContext';
import { useResponsive } from '../../../../../context/responsiveContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import StationMidInfo from './StationMidInfo';
import { Apis } from '../../../../../../domain/Apis';
import { VM } from '../../../../../../viewmodel/VM';
import PoiMidInfo from '../poiDetail/PoiMidInfo';
import PoiCollapse from '../poiDetail/PoiCollapse';
import {
  useGetStationByIdMutation,
  useGetStationsMutation,
  useGetVehiclesMutation
} from '../../../../../../redux/login/explore/GbfsServiceApi';
import { markersSlice } from '../../../../../../redux/slices/markersSlice';
import StationCollapse from './StationCollapse';
import GeneralUtils from '../../../../../../utils/GeneralUtils';

export default function StationDetail(props) {
  const theme = useTheme();
  const CloseIcon = R.drawables.general.Ic_close;
  const responsive = useResponsive();
  const language = useLanguage();
  const t = useTranslate();
  const [iconType, setIconType] = useState();
  const [station, setStation] = useState();
  const [type, setType] = useState();
  const [useGetStationsById] = useGetStationByIdMutation();

  const Bikes = R.drawables.transport.Ic_Bike;

  useEffect(() => {
    async function fetchData() {
      let dataOrigin = VM.dataOrigin.data;
      if (!dataOrigin.length) dataOrigin = await VM.dataOrigin.get(language);

      setType(
        await Apis.agency?.getAgency(language, dataOrigin[props.station.agencyOriginId].agencyId)
      );
      useGetStationsById({ id: props.station.id, locale: language }).then((data) => {
        setStation(data.data);
      });

      setIconType(
        await VM.icons.getIcon(dataOrigin[props.station.agencyOriginId].agencyId, 'agency')
      );
    }
    fetchData();

    let copy = `${GeneralUtils.getBaseUrl()}/#/station?${GeneralUtils.convertToHex(
      `id=${props.station.id}`
    )}`;
    window.history.pushState('a', 'a', copy);
  }, [props.station, language]);
  const styles = {
    content: {
      position: 'absolute',
      display: 'flex',
      padding: 12,
      marginInline: !responsive.isBig ? '25px' : '105px',
      marginTop: '10px',
      width: '80vw',
      maxWidth: '345px',
      minWidth: '232px',
      flexDirection: 'column',
      background: theme.colors.white,
      boxShadow: '0px 4px 4px rgba(28, 31, 32, 0.1)',
      borderRadius: '12px'
    },
    subContent: {
      paddingInline: '12px',
      textAlign: 'left',
      alignContent: 'center'
    },
    row: {
      display: 'flex',
      transition: 'all 300ms linear',
      alignItems: 'center',
      alignContent: 'center'
    },
    icon: {
      marginRight: 10,
      padding: 5,
      width: 15,
      height: 15,
      borderRadius: 5,
      background: theme.colors.border
    },
    secondaryText: {
      color: theme.colors.textSecondary,
      fontSize: 13,
      fontWeight: 500
    },
    paramText: {
      color: theme.colors.textPrimary,
      fontSize: 13,
      fontWeight: 500,
      marginLeft: 5
    },
    mainText: {
      color: theme.colors.textPrimary,
      fontSize: 15,
      fontWeight: 600,
      marginTop: 5,
      marginBottom: 10
    },
    divider: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginTop: 5,
      marginBottom: 5,
      width: '100%'
    }
  };
  return (
    <div style={styles.content}>
      <CloseIcon
        style={{ cursor: 'pointer', marginLeft: 'auto' }}
        fill={theme.colors.black}
        onClick={() => props.onClose()}
      />

      <div style={styles.subContent}>
        <div style={styles.row}>
          <img style={{ ...styles.icon }} src={iconType} />
          <div style={styles.secondaryText}>{type?.shortName}</div>
        </div>

        <div style={styles.mainText}>{props.station.name}</div>

        <div style={styles.row}>
          <Bikes />
          <div style={styles.paramText}>{t('bikes')}</div>
          <div style={{ ...styles.paramText, ...{ marginLeft: 'auto' } }}>
            {station?.bikesAvailable ?? '-'}
          </div>
        </div>

        <div style={styles.divider} />

        <div style={styles.row}>
          <img width={25} src={R.drawables.general.Ic_parking_square} />
          <div style={styles.paramText}>{t('capacity')}</div>
          <div style={{ ...styles.paramText, ...{ marginLeft: 'auto' } }}>
            {station?.capacity ?? '-'}
          </div>
        </div>
      </div>

      <StationMidInfo station={station} />
      {station?.contactPhone || type?.website ? (
        <StationCollapse web={type?.website} station={station} />
      ) : null}
    </div>
  );
}
