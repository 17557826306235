import React from "react";
import {useTheme} from "../../../../../../context/themeContext";
import {useTranslate} from "../../../../../../context/languageContext";
import {R} from "../../../../../../R";
import RouteSynoptic from "./RouteSynoptic";
import ReactTooltip from "react-tooltip";

export default function RouteCard(props, ref) {

    const theme = useTheme();
    const t = useTranslate();
    const WheelChair = R.drawables.general.Ic_silladeruedas
    const Euro = R.drawables.general.Ic_Precio

    const styles={
        content: {
            background: theme.colors.white,
            borderRadius: "12px",
            padding:12,
            textAlign:'left',
            cursor: 'pointer',

        },
        row: {
            display:'flex',
        },
        column: {
            display:'flex',
            width:'100%',
            flexDirection:'column'
        },
        selector: {
            borderColor:'white',
            border: props.selected
                ?`2px solid ${theme.colors.primaryMedium}`
                : `0px solid ${theme.colors.white}`,
            backgroundColor:props.selected
                ? theme.colors.primaryMedium
                : theme.colors.white,
            width:0,
            marginRight:props.selected ? 15 : 0,
            transition: "all 300ms linear",
        },
        separator: {
            backgroundColor: theme.colors.border,
            height: 1,
            marginBottom:5,
            width: '100%'
        },
        time:{
            fontSize:12,
            fontWeight:600,
            marginLeft:'auto',
            lineHeight:'240%',
        },
        duration: {
            fontSize:18,
            fontWeight:700,
            marginRight:2
        },
        duration2: {
            fontSize:15,
            lineHeight:'180%',
            fontWeight:600,
            marginRight:3
        },
        icon: {
            fill: theme.colors.darkGray,
            width:18,
            marginRight:5,
            marginTop:5
        },
        mainText:{
            marginBottom:10,
            fontSize:14,
            fontWeight:600,
        },
        subText:{
            fontSize:14,
            fontWeight:600,
            lineHeight:'240%',
        }
    }

    return (
        <div style={props.style} onClick={props.onClick}>
            <div style={styles.content}>
                <div style={styles.row}>
                    <div style={styles.selector}/>
                    <div style={styles.column}>

                        {props.title
                            ? <div style={styles.mainText}>{props.title}</div>
                            : null
                        }

                        <div style={styles.row}>
                            {props.info.duration / 60 < 1
                                ? null
                                : <div style={styles.duration}>{
                                    Math.floor(props.info.duration / 60)}
                                </div>
                            }
                            {props.info.duration / 60 < 1
                                ? null
                                : <div style={styles.duration2}>
                                    {t("h")}
                                </div>
                            }
                            <div style={styles.duration}>{props.info.duration%60}</div>
                            <div style={styles.duration2}>{t("min")}</div>
                            <div style={styles.time}>{props.info.startTime+'-'+props.info.endTime}</div>
                        </div>

                        <RouteSynoptic lineSynoptic={props.info.lineSynoptic}/>


                        <div style={styles.separator}/>

                        <div style={styles.row}>
                            <ReactTooltip effect={'solid'} id="main" />
                            <div style={{...styles.row,marginRight:20}} data-tip={t('simpleTicket')} data-for={'main'}>
                                <Euro style={styles.icon}/>
                                <div style={styles.subText}>{props.info.price}</div>
                            </div>
                            {props.info.accessible
                                ? <div style={styles.row}>
                                    <WheelChair style={styles.icon}/>
                                    <div style={styles.subText}>{t("accessible")}</div>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
