import React, { useRef } from 'react';
import { useTheme } from '../../../../context/themeContext';
import { useDispatch } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useTranslate } from '../../../../context/languageContext';
import { R } from '../../../../R';

interface AddFileSelectorProps {
  style?: React.CSSProperties;
  fileData?: any; //nombre del archivo
  setFileData: Function; //modificar nombre archivo
  setTypeFile: Function;
  typeFile?: any;
  localeInputData?: any; //info del archivo
  setLocaleInputData?: any; //modificar info del archivo
  toUploadFile?: any; //modifica el contenido a enviar al back
}

export default function AddFileSelector(props: AddFileSelectorProps) {
  const fileSelectorRef: any = useRef(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const t = useTranslate();

  const Clip = R.drawables.general.Ic_clip;

  function validateFileType(fileType?: string) {
    
    if (!fileType) {
      return false;
    }

    const validFileTypes = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/webp',
      'image/svg',
      'image/bmp',
      'image/gif',
      'image/svg+xml',
      'application/pdf',
      'application/doc',
      'application/docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    return fileType && validFileTypes.includes(fileType);
  }

  function formatNameFile(nameFile: string) {
    if (nameFile) {
      const formatedName = nameFile.split('\\');
      return formatedName[formatedName.length - 1];
    }
  }

  return (
    <button
      type='button'
      style={{...{
        flexShrink: 1,
        //borderRadius: 10,
        borderRadius: 24,
        background: theme.colors.primaryMedium,
        //border: `0.1px solid ${theme.colors.border}`,
        border: 'none',
        justifyContent: 'center',
        height: 40,
        width: 40,
      }, ...props.style}}
      onClick={() => {
        fileSelectorRef.current.click();
      }}>
        <Clip style={{color: 'white'}}/>
      {/* {props.localeInputData ? t('modify_file') :  t('add_file')} */}
      <input
        ref={fileSelectorRef}
        type="file"
        id="fileUpload"
        style={{ display: 'none' }}
        accept=".doc,.docx,.pdf,image/*"
        onChange={(e) => {
          console.log(e &&
            e?.target &&
            e?.target?.files && e?.target?.files[0]);
          
            if (
              e &&
              e?.target &&
              e?.target?.files &&
              e?.target?.files[0] &&
              validateFileType(e?.target?.files[0]?.type)
            ) {
              props.setFileData(formatNameFile(e.target.value));
              props.toUploadFile(e?.target?.files[0]);
              props.setLocaleInputData(URL.createObjectURL(e?.target?.files[0]));
              props.setTypeFile(e?.target?.files[0]?.type);
            } else {
              dispatch(
                eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
                  background: '#A31610',
                  showModal: true,
                  text: t('error_file_support'),
                  status: 'error',
                })
              );
            }
          }}
      />
    </button>
  );
}
