import { useLanguage, useTranslate } from '../../context/languageContext';
import { useResponsive } from '../../context/responsiveContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';
import AccountInfo from './AccountFeaturesSections/AccountInfoScreen';
import ManageNotificationsScreen from './AccountFeaturesSections/ManageNotificationsScreen';
import MyTransportScreen from './AccountFeaturesSections/MyTransportScreen';
import ShareScreen from './AccountFeaturesSections/ShareScreen';
import AccountItem from './AccountItem';
import LanguageModal from './language/LanguageModal';
import SupportScreen from './support/SupportScreen';
import {useEffect} from "react";
import GeneralUtils from "../../../utils/GeneralUtils";
import {Apis} from "../../../domain/Apis";
import {VM} from "../../../viewmodel/VM";
import {newsSlice} from "../../../redux/slices/newsSlice";
import Article from "../newscreen/Article";
import PrivacyModal from "./privacy/PrivacyModal";
import { useSelector } from 'react-redux';
import { notificationsCheckMessagesInformation } from '../../../redux/slices/notificationSlice';

export default function AccountSection(props: any, ref: any) {
  const theme = useTheme();
  const t = useTranslate();
  const responsive = useResponsive();
  const language = useLanguage();
  const checkNotifications = useSelector(notificationsCheckMessagesInformation);

  useEffect(() => {
    try {
      let path = GeneralUtils.getLastSectionPath()
      switch (path) {
        case 'manageAccount': {
          props.setChild(<AccountInfo />);
          break;
        }
        case 'language': {
          props.setChild(<LanguageModal />);
          break;
        }
        case 'support': {
          props.setChild(<SupportScreen setChild={props.setChild} />);
          break;
        }
        case 'manageNotifications' : {
          props.setChild(<ManageNotificationsScreen setChild={props.setChild} />);
          break;
        }
        case 'shareApp': {
          props.setChild(<ShareScreen />);
          break;
        }
      }

      } catch (e) {
        
      }
  }, [window.location]);

  const renderItems = () => {
    let res = [];
    const items = [
      {
        txt: t('account')
      },
      {
        txt: t('manageAccount'),
        icon: R.drawables.general.Ic_Acount,
        onClick: () => {
          GeneralUtils.setLastSectionPath('manageAccount',1)
          props.setChild(<AccountInfo />);
        }
      },
      {
        txt: t('language'),
        icon: R.drawables.general.Ic_idioma,
        onClick: () => {
          GeneralUtils.setLastSectionPath('language',1)
          props.setChild(<LanguageModal />);
        }
      },
      {
        txt: t('support'),
        icon: R.drawables.general.Ic_Circled_Question,
        showNoti: checkNotifications,
        onClick: () => {
          GeneralUtils.setLastSectionPath('support',1)
          props.setChild(<SupportScreen setChild={props.setChild} />);
        }
      },
      {
        txt: t('manageNotifications'),
        icon: R.drawables.general.Ic_NotificacionComentarios,
        onClick: () => {
          GeneralUtils.setLastSectionPath('manageNotifications',1)
          props.setChild(<ManageNotificationsScreen setChild={props.setChild} />);
        }
      },
      {
        txt: t('general')
      },
      {
        txt: t('privacy'),
        icon: R.drawables.general.Ic_Privacy,
        onClick: () => {
          window.open(GeneralUtils.selectPrivacyPolicy(language));
        }
      },
      {
        txt: t('terms'),
        icon: R.drawables.general.Ic_Circled_Info,
        onClick: () => {
          window.open(language==='eu' ? "https://www.euskadi.eus/informazioa/-/lege-informazioa/" : 'https://www.euskadi.eus/informacion/-/informacion-legal/','_blank');
        }
      },
      {
        txt: t('shareApp'),
        icon: R.drawables.general.Ic_SocialShare,
        onClick: () => {
          GeneralUtils.setLastSectionPath('shareApp',1)
          props.setChild(<ShareScreen />);
        }
      }
    ];
    for (let element in items) {
      if (items[element].icon)
        res.push(
          <AccountItem
            key={items[element].txt}
            txt={items[element].txt}
            icon={items[element].icon}
            onClick={items[element].onClick}
            showNoti={items[element]?.showNoti}
          />
        );
      else {
        res.push(
          <div key={items[element].txt} style={styles.title}>
            {responsive.isBig ? items[element].txt : ''}
          </div>
        );
      }
    }
    return res;
  };

  const styles = {
    content: {
      //   padding: '15px',
      //   zIndex: 3,
      width: responsive.isBig ? '20vw' : '10vw',
      //   borderRadius: '0px 0px 0px 10px',
      //   maxHeight: 400,
      //   minWidth: 80,
      //   maxWidth: 250,
      //   overflowY: 'scroll',
      //   textAlign: 'left',
      // background: theme.colors.border,
      //   background: 'red',
      //   width: '100%',
      transition: 'all 100ms linear'
      // height: '100%'
    },
    title: {
      fontSize: '14px',
      color: theme.colors.darkGray,
      padding: 10,
      paddingTop: 15,
      fontWeight: 600,
      lineHeight: '140%'
    }
  };

  return (
    <div style={{ ...styles.content, ...props.style }} className="account-body-menu">
      {renderItems()}
    </div>
  );
}
