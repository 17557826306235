import { useTranslate } from '../../../context/languageContext';
import { useSetModalOpened } from '../../../context/modalContext';
import { R } from '../../../R';
import Button from '../../commons/Button';
import {useTheme} from "../../../context/themeContext";

export default function DefaultModal(props: any) {
  const t = useTranslate();
  const Close = R.drawables.general.Ic_close;
  const setOpened = useSetModalOpened();
  const theme = useTheme(

  )
  return (
    <div
      style={{
        padding: 15,
        maxWidth: 300,
        textAlign: 'left',
        background: '#fff',
        borderRadius: '16px'
      }}>
      <Close className={'close'} onClick={() => setOpened(false)} />
      {props.preTitle ?? <div>{props.preTitle}</div>}
      <div className={'title'}>{props.title}</div>
      <div className={'description'} style={{ marginTop: 15 }}>
        {props.description}
      </div>
      <div
        className={props.alter ? 'column' : 'row'}
        style={{ justifyContent: 'center', marginTop: 15 }}>
        <Button
          style={props.alter ? { width: '100%', marginTop: 5 } : { width: '100%', marginLeft: 5 }}
          textColor={theme.colors.white}
          background={theme.colors.mediumDark}
          text={props.secondButton ?? t('confirm')}
          onClick={() => {
            setOpened(false);
            props?.onClickSecond();
          }}
        />
          {!props?.oneButton
              ? <Button
                  style={props.alter ? {width: '100%', marginTop: 5} : {width: '100%', marginLeft: 5}}
                  text={props.firstButton ?? t('cancel')}
                  onClick={() => {
                      setOpened(false);
                      if(props?.onClickFirst)
                          props?.onClickFirst();
                  }}
              />
              : null
          }
      </div>
    </div>
  );
}
