import React from 'react';
import { useResponsive } from '../../../context/responsiveContext';
import { useTranslate } from '../../../context/languageContext';
import { R } from '../../../R';
import { VM } from '../../../../viewmodel/VM';

export default function LinesListHeader() {
  const t = useTranslate();
  const Close = R.drawables.general.Ic_close;

  return (
    <div className={'transit transit-header'}>
      <div className={'row'}>
        <div className={'title white'}>{t('menu_lines')}</div>
        <Close
          className={'close-white'}
          onClick={() => {
            VM.screenSelected.setSelected('explore');
          }}
        />
      </div>
    </div>
  );
}
