import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import GeneralUtils from '../../../utils/GeneralUtils';
import { StatusCodesServer } from '../../hooks';
import { eventsModalstoggleVisibilitySlice } from '../../slices/eventsModalstoggleVisibilitySlice';
import { RootState } from '../../store';

export const FileServiceApi = createApi({
  reducerPath: 'FileServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GeneralUtils.getEnvironment(window.location.origin),
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authSlice.accountInformation.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),

  endpoints: (builder) => ({
    getFile: builder.query<any, any>({
      query: (fileId: string) => ({
        url: `/messages/chat/downloadFile?id=${fileId}`,
        method: 'GET',
        headers: {
          authorization:
            'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token
        },
        responseHandler: async (response:any) => {
            if (response.ok) {
              const url = window.URL.createObjectURL(await response.blob());         
              return url;
            }
            return;
        },
      }),
    }),

    postFile: builder.mutation<any, any>({
      query: (fileData) => ({
        url: '/messages/chat/uploadFile',
        method: 'POST',
        prepareHeaders: (headers: any) => {
            headers.set('Content-Type', 'multipart/form-data');
            headers.set('authorization', 'Bearer ' + JSON.parse(localStorage.getItem('accountInformation') || '{}').token)
            return headers;
        },
        
        body: fileData,
        //formData:true,
      }),

      async onQueryStarted(_undefined, { dispatch, queryFulfilled }) {
        try {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
          await queryFulfilled;
          StatusCodesServer(dispatch, 200);
        } catch (err: any) {
          StatusCodesServer(dispatch, err.error.status);
        } finally {
          dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        }
      }
    }),
  })
});

export const {
 useLazyGetFileQuery,
 usePostFileMutation,
} = FileServiceApi;