import { useSelector } from 'react-redux';
import { favoritesInformation } from '../redux/slices/favoritesSlice';
import { agencyInformation } from '../redux/slices/agencySlice';
import { filtersNewsAlertsState } from '../redux/slices/newsSlice';

export function useFavoriteUtils() {
  const favAgencies = useSelector(favoritesInformation).agencyId;
  const agencyState = useSelector(agencyInformation);
  const agencyFilters = agencyState.agenciesFilters;
  const filterAgenciesNewsAlerts = useSelector(filtersNewsAlertsState);

  let copyAgenciesFilters = JSON.parse(JSON.stringify(agencyState.agenciesFilters));
  let agenciesTransport: Array<any> = [];
  copyAgenciesFilters.forEach((transportModeAg: any) => {
    if (transportModeAg.id !== 5) {
      transportModeAg.agencies.shift();
      let filterDuplicates: Array<number> = [];

      agenciesTransport.forEach((agency: any) => {
        let indexFound = transportModeAg.agencies.findIndex(
          (transAg: any) => transAg.id === agency.id
        );
        if (indexFound !== -1) {
          filterDuplicates.push(indexFound);
        }
      });
      if (filterDuplicates?.length) {
        filterDuplicates.forEach((indexToDel: number) => {
          transportModeAg.agencies.splice(indexToDel, 1);
        });
      }

      let filteredAgenciesWithNewsOrAlerts = transportModeAg.agencies.filter((agency: any) =>
        agencyState.dataOrigin?.find(
          (ori: any) =>
            agency?.id === ori?.agencyId &&
            (ori?.driverId === 2 || ori?.driverId === 4 || ori?.driverId === 6)
        )
      );

      agenciesTransport = agenciesTransport.concat(filteredAgenciesWithNewsOrAlerts);
    }
  });
  agenciesTransport.sort(function (a, b) {
    let nameA = a?.longName?.toLowerCase();
    let nameB = b?.longName?.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  function selectAgenciesToFilterByFav(value: boolean) {
    let res: Array<number> = [];

    const agencyIdsFav: Array<number> = [];
    favAgencies.forEach((originId: number) => {
      const agency: any = agencyState.dataOrigin.find((element: any) => element.id === originId);
      
      if (agency && !agency?.groupId) {
        const agencyIsAdded = agencyIdsFav.find((element: number) => element === agency?.agencyId);

        const existsAgency = agenciesTransport.find((element) => element.id === agency?.agencyId);

        if (!agencyIsAdded && existsAgency) {
          agencyIdsFav.push(agency?.agencyId);
        }
      } else if (agency && agency?.groupId) {
        let mode: any = agency.transportModes[0]?.transportModeId;
        //seleccionamos el modo
        if (mode) {
          let modeAgenciesFiltered: any = agencyFilters.find((element: any) => element.id === mode);
          
          if (modeAgenciesFiltered) {
            let foundAgency = modeAgenciesFiltered?.agencies?.find((element: any) => {
              if (element?.agencyIdsGroup?.length ) {
                return element?.agencyIdsGroup?.indexOf(originId);
              } else {
                return null;
              }
            });

            if (foundAgency) {
              const agencyIsAdded = agencyIdsFav.indexOf(foundAgency?.agencyOriginId);
              
              if (agencyIsAdded === -1) {
                agencyIdsFav.push(foundAgency?.agencyOriginId);
              }
            }
          }
        }
      }
    });
    
    const copySel: Array<number> = JSON.parse(JSON.stringify(filterAgenciesNewsAlerts));
    agencyIdsFav.forEach((sel: number) => {
      let isAddedIndex = copySel.findIndex((element) => element === sel);
      if (isAddedIndex === -1 && value) {
        copySel.push(sel);
      }

      if (isAddedIndex !== -1 && !value) {
        copySel.splice(isAddedIndex, 1);
      }
    });

    res = copySel;

    return res;
  }

  return {
    selectAgenciesToFilterByFav
  };
}
