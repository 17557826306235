import React from 'react';
import { useSelector } from 'react-redux';
import { agencyInformation } from '../../../../redux/slices/agencySlice';
import { ILine } from '../../../../types/LineInterfaces';
import { filter } from 'lodash';
import LineIcon from '../../explorescreen/mapOptions/searcherOption/stopDetail/LineIcon';
import { useTheme } from '../../../context/themeContext';
import { lineSlice, linesState } from '../../../../redux/slices/lineSlice';
import { VM } from '../../../../viewmodel/VM';
import { eventsModalstoggleVisibilitySlice } from '../../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { useDispatch } from 'react-redux';
import {
  useGetStopsLineMutation,
  useLazyGetLineDirectionsQuery
} from '../../../../redux/login/line/lineServiceApi';
import { favoritesSlice } from '../../../../redux/slices/favoritesSlice';
import { useTranslate } from '../../../context/languageContext';

export default function LinesOperatorList({
  agencySelected,
  searchLineText
}: {
  agencySelected?: number;
  searchLineText: string;
}) {
  const allLines = useSelector(linesState);
  const agencyState = useSelector(agencyInformation);
  const theme = useTheme();
  const t = useTranslate();
  const dispatch = useDispatch();
  const [getLineDirections] = useLazyGetLineDirectionsQuery();
  const [getLineStops] = useGetStopsLineMutation();

  let copyAgenciesFilters = JSON.parse(JSON.stringify(agencyState.agenciesFilters));
  let agenciesTransport: Array<any> = [];
  copyAgenciesFilters.forEach((transportModeAg: any) => {
    transportModeAg.agencies.shift();
    agenciesTransport = agenciesTransport.concat(transportModeAg.agencies);
  });
  let selectedAgencies: Array<number> = [];
  agenciesTransport.forEach((agency: any) => {
    if (agencySelected && agency?.agencyOriginId === agencySelected) {
      if (agency?.agencyIdsGroup && agency?.agencyIdsGroup?.length) {
        selectedAgencies = agency?.agencyIdsGroup;
      } else {
        selectedAgencies = [agency?.agencyOriginId];
      }
    }
  });

  async function renderLineDetails(id: number, code?: string | null) {
    let selectedLine = id;
    dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(true));
    const result: any = await getLineDirections(id);

    if (result?.data?.length) {
      let tripId = result?.data[0]?.tripId;

      const resultStops: any = await getLineStops({
        id: id,
        tripId: `tripId=${result?.data[0]?.tripId}`,
        stopId: ''
      });
      if (resultStops?.data?.length) {
        let markerStop = JSON.parse(JSON.stringify(resultStops?.data[0]));
        VM.lines.setSelected(id, tripId, resultStops?.data[0]?.id);
        VM.screenSelected.setSelected('explore');
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityReportModalWidget({
            element: selectedLine
          })
        );
        dispatch(favoritesSlice.actions.setClickFav({ ...markerStop, ...{ type: 'lineSel' } }));
        dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
        dispatch(lineSlice.actions.updateFilteredLine(id));
      } else {
        dispatch(
          eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
            showModal: true,
            background: '#A31610',
            text: t('serviceError'),
            status: 'error'
          })
        );
        dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
      }
    } else {
      dispatch(eventsModalstoggleVisibilitySlice.actions.toggleVisibilityLoadingWidget(false));
      dispatch(
        eventsModalstoggleVisibilitySlice.actions.toggleVisibilityCustomizedSnackbarModalWidget({
          showModal: true,
          background: '#A31610',
          text: t('serviceError'),
          status: 'error'
        })
      );
    }
  }

  function renderListLines(listLines: Array<ILine>) {
    let filterLines = searchLineText
      ? listLines.filter(
          (line: ILine) =>
            line.code?.toLowerCase().includes(searchLineText.toLowerCase()) ||
            line.name?.toLowerCase().includes(searchLineText.toLowerCase())
        )
      : listLines;

    if (!filterLines?.length) {
      return <label>{t('menu_line_filter_empty')}</label>;
    }

    return filterLines
      .sort((a, b) => {
        const nameA = a.code?.toUpperCase() ?? ''; 
        const nameB = b.code?.toUpperCase() ?? '';
        let numberA = parseInt(nameA);
        let numberB = parseInt(nameB);
        if (!isNaN(numberA) && !isNaN(numberB)) {
          return numberA - numberB;
        }

        function extractNumber(value: string) {
          // Extraerlos dígitos de la cadena del final
          let match = value.match(/^([^\d]+)(\d+)$/);
          
          return match ? {char: match[1], code: parseInt(match[match?.length - 1])} : {char: 'error', code: NaN};
        }

        let numA = extractNumber(nameA);
        let numB = extractNumber(nameB);
  
        // Si ambos son números, comparar numéricamente
        if (!isNaN(numA.code) && !isNaN(numB.code) && numA.char === numB.char) {
          return numA.code - numB.code;
        }

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })
      .map((line: ILine) => {
        return (
          <div
            key={`${line.id} - ${line?.code}`}
            style={{ cursor: 'pointer' }}
            onClick={(event) => renderLineDetails(line.id, line?.code)}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: 8,
                marginTop: 8,
                paddingBottom: 8,
                borderBottomWidth: 1,
                borderBottomColor: theme.colors.neutralBorder,
                borderBottomStyle: 'solid',
                cursor: 'pointer'
              }}>
              <LineIcon
                style={{ padding: 5, minWidth: 50 }}
                text={line?.code}
                textColor={'#' + line?.routeTextColor}
                onClick={() => {}}
                color={line?.routeColor ? '#' + line?.routeColor : theme.colors.main}
              />
              <label style={{ cursor: 'pointer', wordBreak: 'break-word', wordWrap: 'break-word' }}>
                {line?.name}
              </label>
            </div>
          </div>
        );
      });
  }

  let filteredLinesByAgency = agencySelected
    ? allLines.filter((line: ILine) => selectedAgencies.includes(parseInt(String(line.agencyOriginId))))
    : [];

  return (
    <div className={'transit transit-body'} style={{ maxHeight: window.innerHeight - 220 }}>
      {filteredLinesByAgency?.length ? (
        <>{renderListLines(filteredLinesByAgency)}</>
      ) : (
        <div>{t('menu_line_selector_empty')}</div>
      )}
    </div>
  );
}
