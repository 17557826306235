export default class TimeUtils {
    static getTime(duration){
        let date = new Date(duration);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if(hours<10)
            hours = '0'+hours
        if(minutes<10)
            minutes = '0'+minutes
        return hours + ':' + minutes;
    }

    static convertFromJulianToDate(jd) {
        let millis = (jd - 2440587.5) * 86400000;
        let dateLocal = new Date(millis);
        return dateLocal
    }

    static timeToMil(t) {
        return Number(t.split(':')[0]) * 60 * 60 * 1000 + Number(t.split(':')[1]) * 60 * 1000;
    }

    static convertTime12to24(time) {
        var hours = parseInt(time.substr(0, 2));
        if(time.indexOf('am') != -1 && hours == 12) {
            time = time.replace('12', '00');
        }
        if(time.indexOf('pm')  != -1 && hours < 12) {
            time = time.replace(time.substr(0, 2), (hours + 12));
        }
        return time.replace(/(am|pm)/, '');
    }

    static adaptFormatTo24Hours(time) {
        let result = time;
        let splitTime = time.split(':');
        splitTime.pop();
        result = splitTime[0] + ':' + splitTime[1];
    
        if (parseInt(splitTime[0]) > 24) {
          let hourCorrect = parseInt(splitTime[0]) - 24;
          if (hourCorrect < 10) {
            hourCorrect = `0${hourCorrect}`;
          }
          result = `${hourCorrect}:${splitTime[1]}`;
        }
    
        return result;
      }

    static getTimePM_AM(time){
        let hours = parseInt(time.substr(0,2))
        let minutes = parseInt(time.substr(3,4))
        let pm_am = 'am';
        if(hours/12>=1) {
            pm_am = 'pm'
            if(hours/12!=1)
                hours = '0'+hours%12
        }
        if(minutes<10) {
            minutes = '0'+minutes
        }
        return hours+':'+minutes+pm_am
    }

    static getDate(date, language){
        date=new Date(date)
        let day= date.getDate()
        let month= date.getMonth()+1
        let year= date.getFullYear()
        if(day<10)
            day='0'+day
        if(month<10)
            month='0'+month

        if(language==='eu')
            return year+'-'+month+'-'+day
        else return day+'-'+month+'-'+year
    }

    static getPlanDate(date) {
        let split = date.split('-')
        return split[1]+'-'+split[0]+'-'+split[2]
    }
}
