import {useTranslate} from "../../../context/languageContext";

export default function InputNext(props: any) {
  const t = useTranslate()

  return (
    <button
      className={'InputNext ' + props?.className}
      name="next"
      disabled={props.disabled}
      onClick={() => {
        props?.dispatch && props?.dispatch();
      }}>
      {props.title ? props.title : t('next')}
    </button>
  );
}
