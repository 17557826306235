import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { eventsModalstoggleVisibilitySlice } from '../../../redux/slices/eventsModalstoggleVisibilitySlice';
import { newsSlice } from '../../../redux/slices/newsSlice';
import GeneralUtils from '../../../utils/GeneralUtils';
import TimeUtils from '../../../utils/TimeUtils';
import { VM } from '../../../viewmodel/VM';
import ButtonIcon from '../../components/commons/ButtonIcon';
import {useLanguage, useTranslate} from '../../context/languageContext';
import { useSetModalOpened } from '../../context/modalContext';
import { useTheme } from '../../context/themeContext';
import { R } from '../../R';
import { useSelector } from 'react-redux';
import { agencyInformation } from '../../../redux/slices/agencySlice';

export default function Article(props, ref) {
  const t = useTranslate();
  const language = useLanguage();
  const [renderHeader, setRenderHeader] = useState();
  const theme = useTheme();
  const dispatch = useDispatch();
  const setOpened = useSetModalOpened();
  const BackIcon = R.drawables.general.Ic_arrowleft;
  const WebIcon = R.drawables.general.Ic_Link;
  const agencyInfo = useSelector(agencyInformation);

  useEffect(() => {
    async function fetchData() {
      setRenderHeader(await getRenderHeader());
    }
    let copy = `${GeneralUtils.getBaseUrl()}/#/transit_news/news?${GeneralUtils.convertToHex(
        `id=${props.article.id}`
    )}`;
    window.history.pushState('a','a', copy)
    fetchData();
  }, [props.article]);

  const styles = {
    content: {
      textAlign: 'left',
      display: 'flex'
    },
    header: {
      display: 'flex',
      paddingInline: 20,
      marginBottom: 20,
      alignItems: 'center',
      backgroundColor: theme.colors.white
    },
    body: {
      display: 'flex',
      paddingInline: 20,
      backgroundColor: theme.colors.background,
      minHeight: 440,
      maxHeight: 440,
      overflowY: 'scroll',
      borderRadius: '0 0 10px 10px'
    },
    agency: {
      fontSize: 13,
      fontWeight: 500
    },
    time: {
      fontSize: 12,
      color: theme.colors.darkGray
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      marginTop: 10,
      color: theme.colors.black
    },
    description: {
      fontSize: 12,
      marginTop: 10,
      textLength: 10,
      textOverflow: 'ellipsis',
      color: theme.colors.darkGray
    },
    article: {
      backgroundColor: 'white',
      width: 340,
      marginTop: -20,
      borderRadius: 10,
      zIndex: 2
    },
    detail: {
      fontSize: 18,
      fontWeight: 500,
      paddingLeft: 10
    },
    column: {
      flexDirection: 'column'
    },
    row: {
      display: 'flex',
      paddingTop: 10,
      alignItems: 'center'
    },
    icon: {
      fill: theme.colors.black,
      width: 20,
      paddingLeft: 20,
      cursor: 'pointer'
    },
    web: {
      marginLeft: 'auto',
      fontSize: 12,
      lineHeight: '200%',
      color: theme.colors.primaryDark,
      fontWeight: 500,
      textLength: 10,
      textOverflow: 'ellipsis'
    },
    textWeb: {
      lineHeight: '200%',
      fontSize: 12,
      marginLeft: 5,
      fontWeight: 500
    }
  };

  const getTimeMessage = (time) => {
    const difference = Math.floor(new Date().getTime() - new Date(time).getTime());
    let minutesDifference = Math.floor(difference / 60000);
    let message = TimeUtils.getDate(new Date(time));

    if (language === 'en') {
      if (minutesDifference === 0) {
        message = `1 ${t('date_minute')} ${t('date_difference')}`;
      } else if (minutesDifference < 60) {
        message = `${minutesDifference} ${t('date_minute')} ${t('date_difference')}`;
      } else if (minutesDifference < 1440) {
        message = `${Math.floor(minutesDifference / 60)} ${t('date_hours')} ${t(
          'date_difference'
        )}`;
      } else {
        let daysDifference = Math.round(difference / (1000 * 60 * 60 * 24));
        if (daysDifference === 1) {
          message = `${t('date_day')} ${t('date_difference')}`;
        } else if (daysDifference < 7) {
          message = `${daysDifference} ${t('date_days')} ${t('date_difference')}`;
        } else if (daysDifference < 14) {
          message = `${t('date_week')} ${t('date_difference')}`;
        } else if (daysDifference < 30) {
          message = `${Math.floor(daysDifference / 7)} ${t('date_weeks')} ${t(
            'date_difference'
          )}`;
        } else if (daysDifference < 60) {
          message = `${t('date_month')} ${t('date_difference')}`;
        }
      }
    } else {
      if (minutesDifference === 0) {
        message = `${t('date_difference')} 1 ${t('date_minute')}`;
      } else if (minutesDifference < 60) {
        message = `${t('date_difference')} ${minutesDifference} ${t('date_minute')}`;
      } else if (minutesDifference < 1440) {
        message = `${t('date_difference')} ${Math.floor(minutesDifference / 60)} ${t('date_hours')}`;
      } else {
        let daysDifference = Math.round(difference / (1000 * 60 * 60 * 24));
        if (daysDifference === 1) {
          message = `${t('date_difference')} ${t('date_day')}`;
        } else if (daysDifference < 7) {
          message = `${t('date_difference')} ${daysDifference} ${t('date_days')}`;
        } else if (daysDifference < 14) {
          message = `${t('date_difference')} ${t('date_week')}`;
        } else if (daysDifference < 30) {
          message = `${t('date_difference')} ${Math.floor(daysDifference / 7)} ${t('date_weeks')}`;
        } else if (daysDifference < 60) {
          message = `${t('date_difference')} ${t('date_month')}`;
        }
      }
    }

    return message;
  };

  const getRenderHeader = async () => {
    let res = [];
    if (VM.agencies.data?.length == 0) {
      await VM.agencies.get();
    }
    if (VM.dataOrigin.data?.length == 0) {
      await VM.dataOrigin.get(useLanguage());
    }
    const origin = agencyInfo.dataOrigin.find((element) => element.id === props.article.agencyOriginId);
    const agency = agencyInfo.agenciesTransport.find((element) => element.id === origin?.agencyId);
    
    let icon = await VM.icons.getIcon(origin?.agencyId, 'agency');
    res.push(
      <div style={styles.header} key={'header'}>
        <ButtonIcon style={{ marginRight: 5 }} iconSize={20} size={30} icon={icon} />
        <div style={styles.column}>
          <div style={{...styles.agency, ...{textTransform: 'capitalize'}}}>{agency?.groupName ? agency?.groupName : agency?.shortName}</div>
          <div style={styles.time}>{getTimeMessage(props.article.publishedAt)}</div>
        </div>
      </div>
    );

    return res;
  };

  const onShare = () => {
    let path = window.location.href.split('/');
    path.pop();
    path = path.join('/');
    let copy = `/news?${GeneralUtils.convertToHex(
      `id=${props.article.id}`
    )}`;
    dispatch(
      eventsModalstoggleVisibilitySlice.actions.toggleVisibilityShareModalWidget({
        showModal: true,
        shareLink: path + copy,
        title: props.article.title
      })
    );

    // setTimeout(ReactTooltip.hide, 2000);
    // navigator.clipboard.writeText(path+copy)
  };

  function obtainAgencyLink() {
    const origin = agencyInfo.dataOrigin.find((element) => element.id === props.article.agencyOriginId);

    if (!origin) {
      return '';
    } else {
      const agency = agencyInfo.agenciesTransport.find((element) => element.id === origin.agencyId);
      if (!agency) {
        return '';
      }
      return agency.website;
    }
  }

  return (
    <div style={styles.content}>
      <div style={styles.article}>
        <div style={styles.row}>
          <BackIcon
            style={styles.icon}
            onClick={() => {
              GeneralUtils.resetPathUntilSec()
              dispatch(newsSlice.actions.updateArticle({}));
              setOpened(false);
            }}
          />
          <div style={styles.detail}>{t('newsDetails')}</div>
          {/* <ReactTooltip event="focusin" effect={'solid'} id="main"/> */}
          <ButtonIcon
            style={{ marginLeft: 'auto' }}
            onClick={onShare}
            icon={R.drawables.commons.share}
            iconSize={15}
            dataTip={t('copyUrl')}
            dataFor={'main'}
            iconColor={theme.colors.darkGray}
            borderColor={theme.colors.white}
            background={theme.colors.white}
          />
        </div>
        {renderHeader}
        <div style={styles.body}>
          <div style={styles.column}>
            <div style={{ minHeight: 350 }}>
              <div style={styles.title} dangerouslySetInnerHTML={{ __html: props.article?.title }}/>
              <div
                dangerouslySetInnerHTML={{ __html: props.article?.description }}
                style={styles.description}
              />
            </div>

            <div style={styles.title}>{t('moreInf')}</div>
            <div style={{ ...styles.row, marginTop: 5, paddingBottom: 10 }}>
              <WebIcon style={{ width: 20 }} />
              <div style={styles.textWeb}>{t('website')}</div>
              <a style={styles.web} href={props.article.link ? props.article.link : obtainAgencyLink()} target="_blank">
                {t('visit')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
