import React, {useEffect, useRef, useState} from 'react';
import { VM } from '../../../../../../viewmodel/VM';
import Button from '../../../../../components/commons/Button';
import Input from '../../../../../components/commons/Input';
import { useTranslate } from '../../../../../context/languageContext';
import { useSetLoading } from '../../../../../context/loadingContext';
import { useTheme } from '../../../../../context/themeContext';
import { R } from '../../../../../R';
import TimeUtils from "../../../../../../utils/TimeUtils";
import {useSetModalChild, useSetModalOpened} from "../../../../../context/modalContext";
import NoMoreStopsModal from "./NoMoreStopsModal";
import {eventsModalstoggleVisibilitySlice} from "../../../../../../redux/slices/eventsModalstoggleVisibilitySlice";
import DefaultModal from "../../../../../components/widget/Modals/DefaultModal";

export default function IntermediateTime(props) {
  const t = useTranslate();
  const [segments, setSegments] = useState([VM.plannerSegments.data]);
  const [time, setTime] = useState([VM.plannerTime.data]);
  const [disabled, setDisabled]=useState(false)

  const theme = useTheme();
  const setLoading = useSetLoading();
  const setModalChild = useSetModalChild()
  const setModalOpened = useSetModalOpened()
  const Circled_Info = R.drawables.general.Ic_Circled_Info;
  let inputRef = useRef({});

  useEffect(() => {
    VM.plannerSegments.listen(() => setSegments([VM.plannerSegments.data]));
    VM.plannerTime.listen(() => {setTime([VM.plannerTime.data])});
    setTime([VM.plannerTime.data])
    setSegments([VM.plannerSegments.data])
  }, []);

  useEffect(()=>{
    for(let ref in inputRef.current){
      if(!inputRef.current[ref])
        delete inputRef.current[ref]
    }
    setDisabled(!checkTimes())
  },[time])

  const styles = {
    circle: {
      backgroundColor: '#A31610',
      width: 18,
      height: 18,
      maxHeight: 18,
      minWidth: 18,
      marginRight: 10,
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    row: {
      display: 'flex',
      margin: 15,
      alignItems: 'center'
    },
    input: {
      borderWidth: '0 0 1.5px 0',
      borderRadius: 0
    },
    text: {
      fontWeight: 600,
      fontSize: 14,
      flex: 2,
      marginRight: 5
    },
    separator: {
      backgroundColor: theme.colors.border,
      height: 1,
      marginTop: 10,
      marginBottom: 10,
      width: '100%'
    },
    icon: {
      fill: theme.colors.darkGray,
      cursor: 'pointer',
      width: 20
    }
  };

  const checkTimes = () => {
    let empty=true
    let fill=true
    for(let ref in inputRef.current){
      if(inputRef.current[ref]?.getValue()?.length)
        empty=false
      else fill=false
      if(empty || fill){
        if(fill)
          if(ref==0) {
            if (TimeUtils.timeToMil(TimeUtils.convertTime12to24(VM.plannerTime.data.time)) - TimeUtils.timeToMil(inputRef.current[ref]?.getValue()) > 0) {
              return false
            }
          }
          else if(TimeUtils.timeToMil(inputRef.current[ref-1]?.getValue())-TimeUtils.timeToMil(inputRef.current[ref]?.getValue())>0){
              return false
          }
      }
      else return false
    }
    return true
  }

  const renderInputs = () => {
    let res = [];
    for (let intermediate in segments[0]) {
      if (
        intermediate !== 'origin' &&
        intermediate !== 'destination' &&
        segments[0][intermediate]?.name
      ) {
        res.push(
          <div key={'intermediate_' + intermediate} style={styles.row}>
            <div style={styles.circle}>
              <div style={{ color: theme.colors.white }}>{parseInt(intermediate) + 1}</div>
            </div>
            <div style={styles.text}>{segments[0][intermediate]?.name}</div>
            <div style={{ flex: 1.5 }}>
              <Input type={"time"}
                     id={intermediate}
                     noInitialValue={true}
                     ref = {ref => inputRef.current[intermediate] = ref}
                     onChange={(e)=>{
                       let condition=TimeUtils.timeToMil(e)-TimeUtils.timeToMil(TimeUtils.convertTime12to24(VM.plannerTime.data.time))>0
                       if(intermediate!=0)
                         condition= TimeUtils.timeToMil(e)-TimeUtils.timeToMil(inputRef.current[intermediate-1]?.getValue())>0
                       if(condition)
                          VM.plannerTime.setTimes(e,intermediate)
                       else {
                         VM.plannerTime.setTimes(e,intermediate)
                         setModalOpened(true);
                         setModalChild(<DefaultModal
                             title={t('timeSoonThanBefore')}
                             oneButton={true}
                             description={t('timeSoonThanBeforeBody')}
                             alter={true}
                         />)
                       }
                     }}
                     onStart={()=>{
                       VM.plannerTime.setTimes('',intermediate)
                       setDisabled(!checkTimes())
                     }}
                     style={styles.input}/>
            </div>
          </div>
        );
      }
    }
    if (res.length !== 0)
      res.unshift(
        <div key={'intermediate_f'}>
          <div style={styles.separator} />
          <div style={styles.row}>
            <div style={{ ...styles.text }}>{t('stopTime')}</div>
            <Circled_Info style={styles.icon} />
          </div>
        </div>
      );
    return res;
  };

  return (
    <div>
      <div style={{ marginBottom: 10 }}>{renderInputs()}</div>

      <Button
        style={{ width: '100%' }}
        textColor={theme.colors.white}
        background={theme.colors.mediumDark}
        text={t('seeRoutes')}
        disabled={
          (!VM.plannerSegments.data['origin'] || !VM.plannerSegments.data['destination'])
            || (Object.keys(time[0]?.times)?.length!==VM.plannerSegments.data?.length) || disabled
        }
        onClick={() => {
          setLoading(true);
          VM.planner.plan().then(() => setLoading(false)).catch((e)=>{setLoading(false)});
          props.setThereIntermediates(!props.thereIntermediates);
        }}
      />
    </div>
  );
}
