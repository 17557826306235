import React from 'react';
import { useTheme } from '../../context/themeContext';
import ButtonIcon from '../../components/commons/ButtonIcon';
import { R } from '../../R';
import { useLanguage, useTranslate } from '../../context/languageContext';
import TruncHtml from 'trunc-html';
import TimeUtils from '../../../utils/TimeUtils';
import { VM } from '../../../viewmodel/VM';
import { newsSlice } from '../../../redux/slices/newsSlice';
import { useDispatch } from 'react-redux';
import { useSetModalChild, useSetModalOpened } from '../../context/modalContext';
import Article from './Article';

export default function NewsItem(props, ref) {
  const theme = useTheme();
  const TruncHtml = require('trunc-html');
  const t = useTranslate();
  const language = useLanguage();
  const dispatch = useDispatch();
  const setOpenModal = useSetModalOpened();
  const setChildModal = useSetModalChild();

  const styles = {
    content: {
      backgroundColor: theme.colors.white,
      padding: 20,
      borderRadius: 10,
      textAlign: 'left'
    },
    row: {
      display: 'flex',
      alignItems: 'center'
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },
    agency: {
      fontSize: 13,
      fontWeight: 500
    },
    time: {
      fontSize: 12,
      color: theme.colors.darkGray
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      marginTop: 10,
      color: theme.colors.black
    },
    description: {
      fontSize: 12,
      marginTop: 10,
      textLength: 10,
      textOverflow: 'ellipsis',
      color: theme.colors.darkGray
    },
    seeMore: {
      display: 'flex',
      fontSize: 13,
      marginTop: 10,
      fontWeight: 500,
      justifyContent: 'right',
      cursor: 'pointer'
    }
  };

  const getTimeMessage = (time) => {
    const difference = Math.floor(new Date().getTime() - new Date(time).getTime());
    let minutesDifference = Math.floor(difference / 60000);
    let message = TimeUtils.getDate(new Date(time), language);

    if (language === 'en') {
      if (minutesDifference === 0) {
        message = `1 ${t('date_minute')} ${t('date_difference')}`;
      } else if (minutesDifference < 60) {
        message = `${minutesDifference} ${t('date_minute')} ${t('date_difference')}`;
      } else if (minutesDifference < 1440) {
        message = `${Math.floor(minutesDifference / 60)} ${t('date_hours')} ${t(
          'date_difference'
        )}`;
      } else {
        let daysDifference = Math.round(difference / (1000 * 60 * 60 * 24));
        if (daysDifference === 1) {
          message = `${t('date_day')} ${t('date_difference')}`;
        } else if (daysDifference < 7) {
          message = `${daysDifference} ${t('date_days')} ${t('date_difference')}`;
        } else if (daysDifference < 14) {
          message = `${t('date_week')} ${t('date_difference')}`;
        } else if (daysDifference < 30) {
          message = `${Math.floor(daysDifference / 7)} ${t('date_weeks')} ${t('date_difference')}`;
        } else if (daysDifference < 60) {
          message = `${t('date_month')} ${t('date_difference')}`;
        }
      }
    } else {
      if (minutesDifference === 0) {
        message = `${t('date_difference')} 1 ${t('date_minute')}`;
      } else if (minutesDifference < 60) {
        message = `${t('date_difference')} ${minutesDifference} ${t('date_minute')}`;
      } else if (minutesDifference < 1440) {
        message = `${t('date_difference')} ${Math.floor(minutesDifference / 60)} ${t(
          'date_hours'
        )}`;
      } else {
        let daysDifference = Math.round(difference / (1000 * 60 * 60 * 24));
        if (daysDifference === 1) {
          message = `${t('date_difference')} ${t('date_day')}`;
        } else if (daysDifference < 7) {
          message = `${t('date_difference')} ${daysDifference} ${t('date_days')}`;
        } else if (daysDifference < 14) {
          message = `${t('date_difference')} ${t('date_week')}`;
        } else if (daysDifference < 30) {
          message = `${t('date_difference')} ${Math.floor(daysDifference / 7)} ${t('date_weeks')}`;
        } else if (daysDifference < 60) {
          message = `${t('date_difference')} ${t('date_month')}`;
        }
      }
    }

    return message;
  };

  const itemClick = async () => {
    dispatch(newsSlice.actions.updateArticle(props.article));
    setChildModal(<Article article={props.article} />);
    setOpenModal(true);
  };

  return (
    <div style={{ ...styles.content, ...props.style }}>
      <div style={styles.row}>
        <ButtonIcon style={{ marginRight: 5 }} iconSize={20} size={30} icon={props.icon} />
        <div style={styles.column}>
          <div style={{ ...styles.agency, ...{ textTransform: 'capitalize' } }}>{props.agency}</div>
          <div style={styles.time}>{getTimeMessage(props.article.publishedAt)}</div>
        </div>
      </div>

      <div style={styles.title} dangerouslySetInnerHTML={{ __html: props.article?.title }} />
      <div
        dangerouslySetInnerHTML={{ __html: TruncHtml(props.article.description, 150).html }}
        style={styles.description}
      />

      <div style={styles.seeMore} onClick={itemClick}>
        {t('seeMore')}
      </div>
    </div>
  );
}
