import React, {useState} from 'react';
import {useTheme} from '../../../../../../context/themeContext';
import ButtonIcon from '../../../../../../components/commons/ButtonIcon';
import {R} from '../../../../../../R'

export default function TransportButton(props) {
  const theme = useTheme();
  const [selected, setSelected] = useState(props.selected);

  const styles = {
    label: {
      fontSize: '10px',
      marginTop: 5,
      color: theme.colors.neutralBlack,
      textAlign: 'center',
      fontWeight: '600',
      alignSelf: 'center'
    },
    box: {
      backgroundColor: theme.colors.white,
      borderRadius: 8,
      alignItems: 'center',
      justifyContent:'center',
    },
    labelButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    img: {
      width: 22,
      height: 22
    }
  };

  return (
    <div style={{...props.style, justifyContent:'center'}}>
      <ButtonIcon
        icon={props.icon ?? R.drawables.commons.threePoints}
        iconSize={25}
        style={{
          ...styles.box,
          ...{
            border: 'solid',
            borderWidth: props.selected ?'2px' :'2px',
            borderColor: props.selected
            ? theme.colors.primaryMedium
            : theme.colors.neutralBorder
          }
        }}
        onClick={() => {
          setSelected(!selected);
          if (props.onClick && (props.agencyId || props.agencyId === 0)) {
            props.onClick(props.modeId, props.agencyId);
          } else {
            props.onClick(props.id)
          }
        }}

      >
      </ButtonIcon>
      <div style={styles.labelButton}>
        <label
          style={{
            ...styles.label,
            ...{
              color: props.selected
                ? theme.colors.primaryDark
                : theme.colors.neutralBlack,
            }
          }}>
          {props.name}
        </label>
      </div>
    </div>
  );
}
