import Api from '../@base/Api';

export default class StopsApi extends Api {
    constructor(url) {
        super(url);
    }

    getStops(language, latitude, longitude) {
        return super.get(`/agency/stops/cache`);
    }
    oldGetStops(language, latitude, longitude) {
        return super.get(`/agency/stops`);
    }

    getStop(language, id) {
        return super.get(`/agency/stops/${id}?locale=${language}`);
    }

    getStopLines(language, id) {
        return super.get(`/agency/stops/${id}/lines?language=${language}`);
    }

    getStopLinestimes(language, id, date) {
        let dateParam = date ? `&date=${date}` : '';
        return super.get(`/agency/stops/${id}/linestimes?language=${language}${dateParam}`);
    }

}
